import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { renterInviteCodeIntialValues } from 'constants/authConstants';
import { updateAgentProfileHelper } from 'helpers/agentHelper';
import { handleServerResponseError, parseResponseErrors } from 'helpers/helper';
import { clearAllInvitesDetails } from 'helpers/publicHelper';
import * as agentService from 'services/agentService';
import * as authservice from 'services/authService';
import * as organizationService from 'services/organizationService';
import * as renterService from 'services/renterService';
import { renterServiceRoutes } from 'services/renterService';
import { Notification } from 'shared/Notification/Notification';
import { AgentProfileInformationProps } from 'shared/types/agentTypes';
import {
  AcceptedInviteInitialValues,
  AcceptInvitationProps,
  AdultInviteDetails,
  AdultInviteInitialValues,
  AuthValues,
  ForgotPasswordValues,
  GoogleAuthValues,
  GuarantorInviteDetails,
  GuarantorInviteInitialValues,
  InviteInformationProps,
  InviteInitialValues,
  RenterInviteDetailsProps,
  RenterInviteParams,
  ResetPassworValues,
  SecurityQuestionsProps,
  SecurityQuestionsPropsRenter,
} from 'shared/types/authType';
import { AgentOrganizationInvitesProps } from 'shared/types/organizationTypes';

import { setProfileInformation } from './agentSlice';
import { setIsRenterLoading } from './renterSlice';
interface Auth {
  isAuthenticated: boolean;
  ischeck: boolean;
  isLoading: boolean;
  isAcceptingInvite: boolean;
  userType: string;
  profileInformation: AgentProfileInformationProps;
  renterId: number;
  applicationId: number;
  inviteDetails: InviteInformationProps;
  adultInviteDetails: AdultInviteDetails;
  guarantorInviteDetails: GuarantorInviteDetails;
  inviteType: string;
  inviteCode: string;
  inviteSubType: string;
  acceptedInviteDetails: AcceptInvitationProps;
  serverError?: number;
  renterInviteCode: RenterInviteParams;
  agentOrganizationInviteDetails: AgentOrganizationInvitesProps;
  securityQuestions: SecurityQuestionsProps[];
  securityQuestionsRenter: SecurityQuestionsPropsRenter[];
}
export const initialState: Auth = {
  isAuthenticated: false,
  isLoading: false,
  ischeck: true,
  isAcceptingInvite: false,
  userType: '',
  profileInformation: {} as AgentProfileInformationProps,
  renterId: 0,
  applicationId: 0,
  inviteDetails: InviteInitialValues,
  adultInviteDetails: AdultInviteInitialValues,
  guarantorInviteDetails: GuarantorInviteInitialValues,
  inviteType: '',
  inviteCode: '',
  inviteSubType: '',
  acceptedInviteDetails: AcceptedInviteInitialValues,
  renterInviteCode: renterInviteCodeIntialValues,
  agentOrganizationInviteDetails: {} as AgentOrganizationInvitesProps,
  securityQuestions: {} as SecurityQuestionsProps[],
  securityQuestionsRenter: {} as SecurityQuestionsPropsRenter[],
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setRenterId: (state, action) => {
      state.renterId = action.payload;
    },
    setApplicationId: (state, action) => {
      state.applicationId = action.payload;
    },
    setInviteType: (state, action) => {
      state.inviteType = action.payload;
    },
    setServerError: (state, action) => {
      state.serverError = action.payload;
    },
    setInviteCode: (state, action) => {
      state.inviteCode = action.payload;
    },
    setInviteSubType: (state, action) => {
      state.inviteSubType = action.payload;
    },
    setInviteDetails: (state, action) => {
      state.inviteDetails = action.payload;
    },
    setAdultInviteDetails: (state, action) => {
      state.adultInviteDetails = action.payload;
    },
    setGuarantorInviteDetails: (state, action) => {
      state.guarantorInviteDetails = action.payload;
    },
    setRenterInviteCode: (state, action) => {
      state.renterInviteCode = action.payload;
    },
    setSecurityQuestions: (state, { payload }) => {
      state.securityQuestions = payload;
    },
    setSecurityQuestionsRenter: (state, { payload }) => {
      state.securityQuestionsRenter = payload;
    },
    setAgentOrganizationInviteDetails: (state, action) => {
      state.agentOrganizationInviteDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signup.fulfilled, (state, { payload: { userType } }) => {
        state.userType = userType;
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(signup.rejected, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, { payload: { userType } }) => {
        state.userType = userType;
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false;
      })
      .addCase(googleAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(googleAuth.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(googleAuth.rejected, (state) => {
        state.isLoading = false;
        state.isAuthenticated = false;
      })
      .addCase(getAgentProfileInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAgentProfileInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profileInformation = updateAgentProfileHelper(action.payload);
      })
      .addCase(getAgentProfileInformation.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getInviteDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInviteDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.inviteDetails = action.payload;
      })
      .addCase(getInviteDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(acceptInvite.pending, (state) => {
        state.isAcceptingInvite = true;
      })
      .addCase(acceptInvite.fulfilled, (state, action) => {
        state.isAcceptingInvite = false;
        state.acceptedInviteDetails = action.payload;
      })
      .addCase(acceptInvite.rejected, (state) => {
        state.isAcceptingInvite = false;
      })
      .addCase(acceptRenterInvite.pending, (state) => {
        state.isAcceptingInvite = true;
      })
      .addCase(acceptRenterInvite.fulfilled, (state) => {
        state.isAcceptingInvite = false;
      })
      .addCase(acceptRenterInvite.rejected, (state) => {
        state.isAcceptingInvite = false;
      })
      .addCase(getAgentOrganizationInvites.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAgentOrganizationInvites.fulfilled, (state, { payload }) => {
        state.agentOrganizationInviteDetails = payload;
        state.isLoading = false;
      })
      .addCase(getAgentOrganizationInvites.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAgentOrganizationInvitesDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAgentOrganizationInvitesDetails.fulfilled, (state, { payload }) => {
        state.agentOrganizationInviteDetails = payload;
        state.isLoading = false;
      })
      .addCase(getAgentOrganizationInvitesDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(requestChangePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestChangePassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(requestChangePassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAdultInviteDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdultInviteDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.adultInviteDetails = action.payload;
      })
      .addCase(getAdultInviteDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getGuarantorInviteDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGuarantorInviteDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.guarantorInviteDetails = action.payload;
      })
      .addCase(getGuarantorInviteDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addDefaultCase((state) => state);
  },
});

type SignupParams = {
  values: AuthValues;
};

type GoogleAuthParams = {
  values: GoogleAuthValues;
  onError: (error: string) => void;
};

// API call for signup that takes userName password and returns OK status if user is created.
// Additionaly we can pass onSuccess function as parameter from our component
export const signup = createAsyncThunk('auth/signup', async ({ values }: SignupParams, { rejectWithValue }) => {
  try {
    const {
      data: { payload },
    }: AxiosResponse = await authservice.signup(values);

    return payload;
  } catch (e) {
    if (e instanceof AxiosError && e.response) {
      return rejectWithValue(e.response.data.errors[0]);
    }

    return Promise.reject();
  }
});
// API call for signup that takes userName password and returns OK status if user is loggedin else throws error.
// Additionaly we can pass onSuccess and onError functions as parameter from our component
export const login = createAsyncThunk('auth/login', async ({ values }: SignupParams, { rejectWithValue }) => {
  try {
    const {
      data: { payload },
    }: AxiosResponse = await authservice.login(values);

    return payload;
  } catch (e) {
    if (e instanceof AxiosError && e.response) {
      return rejectWithValue(e.response.data.errors[0]);
    }

    return Promise.reject();
  }
});

export const requestChangePassword = createAsyncThunk(
  '/forgot-password',
  async ({ email }: ForgotPasswordValues, { dispatch }) => {
    try {
      return await authservice.requestChangePassword(email);
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);

export const resetPassword = createAsyncThunk('/reset-password', async (values: ResetPassworValues, { dispatch }) => {
  try {
    const response = await authservice.resetPassword(values);

    return response;
  } catch (error) {
    handleServerResponseError({ error, dispatch });

    if (error instanceof AxiosError && error.response) {
      Notification({ message: parseResponseErrors(error) });

      return Promise.reject();
    }
  }
});

// API call for Login with google that redirects user to dashboard after successful login
// Additionaly we can pass onSuccess and onError functions as parameter from our component
export const googleAuth = createAsyncThunk('auth/googleAuth', async ({ values }: GoogleAuthParams) => {
  try {
    const response = await authservice.googleAuth(values);

    return response;
  } catch (e) {
    return Promise.reject();
  }
});

export const getAgentProfileInformation = createAsyncThunk('auth/getProfileInformation', async (_, { dispatch }) => {
  try {
    const {
      data: { payload },
    }: AxiosResponse = await agentService.getAgentProfileInformation();

    dispatch(setProfileInformation(updateAgentProfileHelper(payload)));

    return payload;
  } catch (e) {
    if (e instanceof AxiosError && e.response) {
      Notification(e.response.data.errors[0].errorMessage);

      return Promise.reject();
    }
  }
});

/**
 * Retrieves invite details based on the invite code.
 *
 * @returns {Promise} - A promise that resolves to the invite details.
 */

export const getInviteDetails = createAsyncThunk(
  'invite/details',
  async ({ inviteCode, inviteType }: RenterInviteDetailsProps, { dispatch }) => {
    try {
      clearAllInvitesDetails({ dispatch: dispatch });
      dispatch(setInviteCode(inviteCode)); //persisting the invite code to authSlice
      dispatch(setInviteType(inviteType));
      const {
        data: { payload },
      }: AxiosResponse = await agentService.getRenterInviteDetails(inviteCode);

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        Notification(e.response.data.errors[0].errorMessage);

        return Promise.reject();
      }
    }
  }
);
/**
 * Retrieves adult invite details based on the invite code.
 *
 * @returns {Promise} - A promise that resolves to the invite details.
 */

export const getAdultInviteDetails = createAsyncThunk(
  'adultInvite/details',
  async ({ inviteCode }: { inviteCode: string }, { dispatch }) => {
    try {
      clearAllInvitesDetails({ dispatch: dispatch });
      dispatch(
        setRenterInviteCode({
          inviteCode,
          url: renterServiceRoutes.acceptAdultInvite,
        })
      );
      const {
        data: { payload },
      }: AxiosResponse = await renterService.getAdultInviteDetails(inviteCode);

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        Notification(e.response.data.errors[0].errorMessage);

        return Promise.reject();
      }
    }
  }
);

/**
 * Retrieves adult invite details based on the invite code.
 *
 * @returns {Promise} - A promise that resolves to the invite details.
 */

export const getGuarantorInviteDetails = createAsyncThunk(
  'guarantorInvite/details',
  async ({ inviteCode }: { inviteCode: string }, { dispatch }) => {
    try {
      clearAllInvitesDetails({ dispatch: dispatch });
      dispatch(
        setRenterInviteCode({
          inviteCode,
          url: renterServiceRoutes.acceptGuarantorInvite,
        })
      );
      const {
        data: { payload },
      }: AxiosResponse = await renterService.getGuarantorInviteDetails(inviteCode);

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        Notification(e.response.data.errors[0].errorMessage);

        return Promise.reject();
      }
    }
  }
);

/**
 * Accepts an invitation based on the invite code.
 *
 * @returns {Promise} - A promise that resolves to the accepted invitation payload.
 */

export const acceptInvite = createAsyncThunk('invite/accept', async (inviteCode: string, { dispatch }) => {
  try {
    dispatch(setIsRenterLoading(true));

    const {
      data: { payload },
    }: AxiosResponse = await agentService.acceptInvitation(inviteCode);

    return payload;
  } catch (e) {
    if (e instanceof AxiosError && e.response) {
      Notification(e.response.data.errors[0].errorMessage);

      return Promise.reject();
    }
  } finally {
    dispatch(setIsRenterLoading(false));
  }
});

export const acceptRenterInvite = createAsyncThunk(
  'renter/acceptRenterInvite',
  async ({ url, inviteCode }: RenterInviteParams, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await renterService.acceptRenterInvite(url, inviteCode);

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        Notification({ message: e.response.data.errors[0].errorMessage });

        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
      }

      return Promise.reject();
    } finally {
      dispatch(setRenterInviteCode(renterInviteCodeIntialValues));
    }
  }
);

export const getAgentOrganizationInvites = createAsyncThunk(
  'agent/getAgentOrganizationInvite',
  async (inviteCode: string, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await organizationService.getAgentOrganizationInviteDetails(inviteCode);

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        Notification({ message: e.response.data.errors[0].errorMessage });

        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
      }
    }
  }
);

export const getAgentOrganizationInvitesDetails = createAsyncThunk(
  'agent/getAgentOrganizationInvitationDetails',
  async (organizationId: number, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await organizationService.getAgentOrganizationInvitationDetails({
        organizationId: organizationId,
      });

      return payload;
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        Notification({ message: e.response.data.errors[0].errorMessage });

        const status = (e as AxiosError)?.response?.status;

        dispatch(setServerError(status));
      }
    }
  }
);
export const {
  logoutSuccess,
  setUserType,
  setRenterId,
  setServerError,
  setApplicationId,
  setInviteType,
  setInviteSubType,
  setInviteCode,
  setInviteDetails,
  setRenterInviteCode,
  setSecurityQuestions,
  setSecurityQuestionsRenter,
  setAdultInviteDetails,
  setGuarantorInviteDetails,
  setAgentOrganizationInviteDetails,
} = slice.actions;
export const { reducer } = slice;
