/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { t } from 'i18next';
import { isLoading, renterAnimals } from 'redux/selectors/renterSelector';
import {
  deleteFurryFriendsDocument,
  deleteFurryFriendsImage,
  reorderRenterFurryFriendsImages,
  uploadFurryFriendsDocuments,
  uploadFurryFriendsImages,
} from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import ImageIcon from 'assets/images/ImageIcon.png';
import RenterContainer from 'components/Renter/components/RenterContainer';
import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import RCButton from 'components/shared/Button/Button';
import MinimizedDropzone, { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import { AnimalType, NavigationKeys, RenterAnimalSteps } from 'constants/renterConstants';
import { imageUrl } from 'helpers/renterHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';

import AnimalDetailsInformationCard from '../../../shared/AnimalDetailsInformationCard/AnimalDetailsInformationCard';

import styles from './AnimalDocumentation.module.scss';

const AnimalDocumentation = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const animalInformation = useSelector(renterAnimals);
  const isLoadingPage = useSelector(isLoading);
  const lastAnimalInformation = useMemo(() => animalInformation[animalInformation?.length - 1], [animalInformation]);
  const [isDropzoneOpen, setIsDropzoneOpen] = useState(false);
  const [acceptedImages, setAcceptedImages] = useState<PreviewFile[]>([]);
  const [acceptedDocuments, setAcceptedDocuments] = useState<PreviewFile[]>([]);
  const navigateNext = searchParams.get(NavigationKeys.NEXT);
  const navigateSection = searchParams.get(NavigationKeys.SECTION);
  const navigate = useNavigate();
  const maxHeight = useDeviceHeight(220);

  useEffect(() => {
    if (!acceptedImages?.length && lastAnimalInformation?.images) {
      setAcceptedImages(lastAnimalInformation?.images);
    }

    if (!acceptedDocuments?.length && lastAnimalInformation?.documents) {
      setAcceptedDocuments(lastAnimalInformation?.documents);
    }
  }, [acceptedDocuments, acceptedImages, lastAnimalInformation]);
  const isDisabled = useCallback(
    () =>
      !lastAnimalInformation?.images?.length ||
      (lastAnimalInformation?.furryFriendType !== AnimalType.PETS && !lastAnimalInformation?.documents?.length),
    [lastAnimalInformation]
  );
  const onContinueHandler = useCallback(() => {
    if (navigateNext) {
      navigate(`${navigateNext}?section=${decodeURIComponent(navigateSection ?? '')}`);
    } else if (lastAnimalInformation && !isLoadingPage) {
      lastAnimalInformation.furryFriendType && setSearchParams({ step: RenterAnimalSteps.CONFIRMATION });
    }
  }, [isLoadingPage, lastAnimalInformation, navigate, navigateNext, navigateSection, setSearchParams]);
  const uploadImagesApi = async (previewFile: PreviewFile): Promise<any> => {
    try {
      return await dispatch(
        uploadFurryFriendsImages({ document: previewFile, furryFriendId: lastAnimalInformation?.id })
      )
        .unwrap()
        .then((response) => response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const uploadImageFromAvatarApi = async (previewFile: PreviewFile): Promise<any> => {
    try {
      return await dispatch(
        uploadFurryFriendsImages({ document: previewFile, furryFriendId: lastAnimalInformation?.id })
      )
        .unwrap()
        .then((response) => {
          if (response?.images?.length > 1) {
            const renterImagesIds = response.images.map((el: PreviewFile) => el.id);

            renterImagesIds.unshift(renterImagesIds.pop());

            return reorderImagesApi(renterImagesIds);
          }
        });
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const uploadDocumentApi = async (previewFile: PreviewFile): Promise<any> => {
    try {
      return await dispatch(
        uploadFurryFriendsDocuments({ document: previewFile, furryFriendId: lastAnimalInformation?.id })
      )
        .unwrap()
        .then((response) => response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const deleteImageApi = async (previewFile: PreviewFile): Promise<any> => {
    const currentImage = lastAnimalInformation?.images?.find(
      (image) => image.fileName === previewFile.path || image.fileName === previewFile.fileName
    );

    if (currentImage && lastAnimalInformation.id) {
      try {
        return await dispatch(
          deleteFurryFriendsImage({ furryFriendId: lastAnimalInformation?.id, documentId: currentImage.id })
        )
          .unwrap()
          .then((response) => response);
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(new Error(t('renter.incomeInformation.documentType.error.notFound')));
    }
  };
  const deleteDocumentApi = async (previewFile: PreviewFile): Promise<any> => {
    const currentDocument = lastAnimalInformation?.documents?.find(
      (obj) => obj.fileName === previewFile.path || obj.fileName === previewFile.fileName
    );

    if (currentDocument && lastAnimalInformation.id) {
      try {
        return await dispatch(
          deleteFurryFriendsDocument({ furryFriendId: lastAnimalInformation?.id, documentId: currentDocument.id })
        )
          .unwrap()
          .then((response) => response);
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(new Error(t('renter.incomeInformation.documentType.error.notFound')));
    }
  };
  const reorderImagesApi = async (imageIds: (number | undefined)[]): Promise<any> => {
    if (imageIds && lastAnimalInformation.id) {
      try {
        return await dispatch(
          reorderRenterFurryFriendsImages({ furryFriendId: lastAnimalInformation?.id, imageIds: imageIds })
        )
          .unwrap()
          .then((response) => response);
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(new Error(t('renter.incomeInformation.documentType.error.notFound')));
    }
  };

  return (
    <RenterContainer>
      {lastAnimalInformation && (
        <section className={styles.renterBody}>
          <div
            className={styles.container}
            style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
          >
            <RenterIconInfoContainer />
            <div className={styles.headingLabel}>
              {lastAnimalInformation.furryFriendType === AnimalType.PETS
                ? t('renter.petsInformation.documentationPetLabel')
                : t('renter.petsInformation.documentationAssistiveLabel')}
            </div>
            {lastAnimalInformation && (
              <AnimalDetailsInformationCard
                setExplorerOpen={setIsDropzoneOpen}
                animalInformation={lastAnimalInformation}
                url={imageUrl(lastAnimalInformation) ?? ImageIcon}
                shouldShowEditIcon={false}
              />
            )}
            <div className={styles.dropdownContainer}>
              <MinimizedDropzone
                setOpenExplorer={setIsDropzoneOpen}
                openExplorer={isDropzoneOpen}
                uploadApiCall={isDropzoneOpen ? uploadImageFromAvatarApi : uploadImagesApi}
                deleteApiCall={deleteImageApi}
                title={t('renter.photoLabel')}
                setAcceptedFiles={setAcceptedImages}
                acceptedFiles={acceptedImages}
                canReorder={lastAnimalInformation?.images?.length > 1}
                reorderImagesApiCall={reorderImagesApi}
                enabled
              />
              {lastAnimalInformation.furryFriendType !== AnimalType.PETS && (
                <MinimizedDropzone
                  uploadApiCall={uploadDocumentApi}
                  deleteApiCall={deleteDocumentApi}
                  title={t('renter.documentationLabel')}
                  setAcceptedFiles={setAcceptedDocuments}
                  acceptedFiles={acceptedDocuments}
                  enabled
                />
              )}
            </div>
            <div className={styles.renterButtonContainer}>
              <RCButton
                onClick={onContinueHandler}
                disabled={isDisabled()}
                className={styles.renterButton}
                variant="outline"
              >
                {t('renter.noCreditHistory.SaveAndContinue')}
              </RCButton>
            </div>
          </div>
        </section>
      )}
    </RenterContainer>
  );
};

export default AnimalDocumentation;
