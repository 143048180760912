import { encryptData } from 'helpers/encrypt';
import { AuthValues, GoogleAuthValues, ResetPassworValues } from 'shared/types/authType';
import { AxiosPromise } from 'shared/types/axiosPromise';

import baseService from './baseService';

export const signup = (values: AuthValues): AxiosPromise =>
  baseService.post<unknown>('/user/sign-up', {
    ...values,
    password: encryptData(values.password),
  });

export const login = (values: AuthValues): AxiosPromise =>
  baseService.post<unknown>('/user/log-in', {
    ...values,
    password: encryptData(values.password),
  });
export const logout = (): AxiosPromise => baseService.post<string>('/user/log-out');

export const requestChangePassword = (email: string): AxiosPromise =>
  baseService.post<unknown>('/user/forget-password', { email });

export const resetPassword = (values: ResetPassworValues): AxiosPromise =>
  baseService.put('/user/reset-password', {
    ...values,
    password: encryptData(values.password),
  });

export const googleAuth = (values: GoogleAuthValues): AxiosPromise =>
  baseService.post<unknown>('/user/google-oauth', values);

export const getPosts = (): AxiosPromise => baseService.get<unknown>('https://jsonplaceholder.typicode.com/posts');
