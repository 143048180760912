import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isLoading, isSaving } from 'redux/selectors/renterSelector';

import styles from './RenterLoader.module.scss';

const RenterLoader = (): JSX.Element => {
  const isSavingInformation = useSelector(isSaving);
  const isLoadingInformation = useSelector(isLoading);

  if (isSavingInformation || isLoadingInformation) {
    return (
      <div className={styles.container}>
        <BootstrapSpinner animation="border" className="spinner-border text-dark" />
      </div>
    );
  }

  return <div />;
};

export default RenterLoader;
