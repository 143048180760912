import { useMemo } from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { formatAmountToUsdString } from 'helpers/helper';
import { AddPropertyParams } from 'shared/types/propertyType';
import { FinancialData } from 'shared/types/reportTypes';

import { InformationItemPDF } from '../shared/InformationItemPDF/InformationItemPDF';
const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    padding: '4px 8px',
    flexDirection: 'row',
    gap: '8px',
    borderRadius: '4px',
    border: '1px solid #E6EFF0',
  },
  LeftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    flex: 2,
  },
  InformationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '12px',
    width: '100%',
  },

  EstContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  EstLabel: {
    color: '#4B4F52',
    textAlign: 'center',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
  TotalEst: {
    display: 'flex',
    padding: '0px 5px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '2px',
  },
  Ratio: {
    display: 'flex',
    paddingLeft: '3px',
    alignItems: 'flex-start',
    gap: '10px',
    color: '#1496A2',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
    borderRadius: '2px',
    backgroundColor: '#E6F8FA',
  },
  RatioDanger: {
    backgroundColor: '#fcdbe3',
    color: '#ef476f',
    display: 'flex',
    paddingLeft: '3px',
    alignItems: 'flex-start',
    gap: '10px',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
    borderRadius: '2px',
  },
});

export const IncomeReportBoxPDF = ({
  incomeTransactions,
  propertyInformation,
}: {
  incomeTransactions: FinancialData;
  propertyInformation: AddPropertyParams;
}): JSX.Element => {
  const { incomeSummary } = incomeTransactions;
  const calculateEstMonthlyAvrg = useMemo((): number => {
    if (propertyInformation?.isCustomAddress) {
      return incomeSummary?.averageMonthly / 3;
    }

    return incomeSummary?.averageMonthly / (propertyInformation?.monthlyRentAmount ?? 1);
  }, [incomeSummary?.averageMonthly, propertyInformation?.isCustomAddress, propertyInformation?.monthlyRentAmount]);

  return (
    <View style={styles.Container} wrap={false}>
      <View style={styles.LeftBox}>
        <View style={styles.InformationRow}>
          <InformationItemPDF label="Past 3 Months" value={formatAmountToUsdString(incomeSummary?.pastThreeMonths)} />
          <InformationItemPDF label="Average Monthly" value={formatAmountToUsdString(incomeSummary?.averageMonthly)} />
        </View>
        <View style={styles.InformationRow}>
          <InformationItemPDF label="Past 6 Months" value={formatAmountToUsdString(incomeSummary?.pastSixMonths)} />
          <InformationItemPDF
            label="Projected 12 Months"
            value={formatAmountToUsdString(incomeSummary?.projectedTwelveMonthly)}
          />
        </View>
      </View>
      <View style={styles.EstContainer}>
        <Text style={styles.EstLabel}>Est Monthly Avg</Text>
        <Text style={styles.TotalEst}>{calculateEstMonthlyAvrg.toFixed(2)} X</Text>
        {calculateEstMonthlyAvrg >= 2.5 && <Text style={styles.Ratio}>rent to income ratio</Text>}
        {calculateEstMonthlyAvrg < 2.5 && <Text style={styles.RatioDanger}>rent to income ratio</Text>}
      </View>
    </View>
  );
};
