import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { applicantRenterRole } from 'redux/selectors/renterSelector';

import AddressEditModal from 'components/Renter/components/AddressEditModal/AddressEditModal';
import AddressVerifierEditModal from 'components/Renter/components/AddressVerifierEditModal/AddressVerifierEditModal';
import { PendingInformationBox } from 'components/shared/PendingInformationBox/PendingInformationBox';
import { renterRestrictions } from 'constants/restrictionConstants';
import { AddressInformation, CollapsibleBadgeComponentsProps } from 'shared/types/renterTypes';

import { PresentAddress, PriorAddress } from './components';

const AddressHistory = ({ next, isPending }: CollapsibleBadgeComponentsProps): JSX.Element => {
  const [isEditVerifierModalOpen, setIsEditVerifierModalOpen] = useState(false);
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const renterRole = useSelector(applicantRenterRole);
  const { t } = useTranslation();
  const handleEditVerifier = useCallback((address: AddressInformation) => {
    setSelectedAddress(address);
    setIsEditVerifierModalOpen(true);
  }, []);
  const handleEditAddress = useCallback((address: AddressInformation) => {
    setSelectedAddress(address);
    setIsEditAddressModalOpen(true);
  }, []);

  return (
    <>
      {isPending && <PendingInformationBox informationalText={t('renter.applicationInfoBox.addressHistory.pending')} />}
      <PresentAddress next={next} handleEditVerifier={handleEditVerifier} handleEditAddress={handleEditAddress} />
      {!renterRestrictions[renterRole].priorAddress && (
        <PriorAddress next={next} handleEditVerifier={handleEditVerifier} handleEditAddress={handleEditAddress} />
      )}
      {isEditVerifierModalOpen && (
        <AddressVerifierEditModal
          onHide={() => setIsEditVerifierModalOpen(false)}
          shouldShowModal={isEditVerifierModalOpen}
          address={selectedAddress}
        />
      )}
      {isEditAddressModalOpen && (
        <Formik onSubmit={() => console.log('Form')} initialValues={selectedAddress}>
          <AddressEditModal
            address={selectedAddress}
            isModalOpen={isEditAddressModalOpen}
            setIsModalOpen={() => setIsEditAddressModalOpen(false)}
          />
        </Formik>
      )}
    </>
  );
};

export default AddressHistory;
