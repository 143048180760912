import { useTranslation } from 'react-i18next';

import { ReactComponent as PersonIcon } from 'assets/svgs/MeetingPersonIcon.svg';

import styles from './IdvSuccessPage.module.scss';

export const IdvSuccessPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.Heading}>{t('agent.identityVerification.yourDone!')}</div>
      <PersonIcon className={styles.PersonIcon} />
      <div className={styles.Description}>{t('agent.identityVerification.pleaseContactYourAgent')}</div>
    </div>
  );
};
