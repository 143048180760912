import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ArrowDown } from 'assets/svgs/ArrowDownWithBackgroundColor.svg';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { VerifierQuestionStatus, VerifierQuestionStatusTypes } from 'constants/agentConstants';
import { ViewResidencyQuestionProps } from 'shared/types/agentTypes';

import { ViewResidencyAnswer } from './ViewResidencyAnswer';

import styles from './ResidencyQuestions.module.scss';

export const ViewResidencyQuestion = ({ singleQuestion, index }: ViewResidencyQuestionProps): JSX.Element => {
  const { question, status } = singleQuestion;
  const { t } = useTranslation();

  return (
    <div className={styles.QuestionContainer}>
      <div className={styles.HeadingContainer}>
        <div className={styles.QuestionHeading}>{`${t('renter.residencyQuestion.q&A')} ${index + 1}`}</div>
        {status ? (
          <div
            className={classNames(styles.SelectContainer, {
              [styles.Acceptable]: status === VerifierQuestionStatusTypes.ACCEPTABLE,
              [styles.Unacceptable]: status === VerifierQuestionStatusTypes.UNACCEPTABLE,
            })}
          >
            <ReactSelect
              hasFloatingLabel={false}
              isSearchable={false}
              options={VerifierQuestionStatus}
              placeholder=""
              value={{ label: status, value: status }}
              dropDownIcon={<ArrowDown />}
              isDisabled /**Disabled until update API integration */
            />
          </div>
        ) : null}
      </div>
      <div className={styles.QuestionAnswer}>
        <div className={styles.Question}>{question}</div>
        <div className={styles.AnswersContainer}>
          <ViewResidencyAnswer
            answer={singleQuestion.answer}
            dateRange={singleQuestion.dateRange}
            extraInformation={singleQuestion.extraInformation}
          />
        </div>
      </div>
    </div>
  );
};
