import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Form, useFormikContext } from 'formik';
import { t } from 'i18next';
import { addFurryFriendToRenter } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import { FurryFriendParamTypes, NavigationKeys, RenterAnimalSteps } from 'constants/renterConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { AnimalInformationProps } from 'shared/types/renterTypes';

import RenterContainer from '../components/RenterContainer';

import AnimalDetailsCard from './AnimalDetailsCard/AnimalDetailsCard';

import styles from './AnimalIndex/AnimalIndex.module.scss';
const AnimalDetailInputCard = (): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [params, setSearchParams] = useSearchParams();
  const {
    values,
    isValid,
    setErrors,
    isSubmitting,
    setSubmitting,
    resetForm,
    setFieldValue,
    dirty: isDirty,
  } = useFormikContext<AnimalInformationProps>();
  const animalType = params.get(FurryFriendParamTypes.ANIMAL_TYPE);
  const furryFriendType = params.get(FurryFriendParamTypes.FURRY_FRIEND_TYPE);
  const assistiveAnimalType = params.get(FurryFriendParamTypes.ASSISTIVE_ANIMAL_TYPE);
  const navigateSection = params.get(NavigationKeys.SECTION);
  const navigateNext = params.get(NavigationKeys.NEXT);
  const maxHeight = useDeviceHeight(220);

  useEffect(() => {
    if (furryFriendType) {
      setFieldValue('furryFriendType', furryFriendType);
    }

    if (assistiveAnimalType) {
      setFieldValue('assistiveAnimalType', assistiveAnimalType);
    }
  }, [assistiveAnimalType, furryFriendType, setFieldValue]);
  const onContinueHandler = useCallback(() => {
    dispatch(addFurryFriendToRenter(values))
      .unwrap()
      .then(() => {
        resetForm();
        setSubmitting(false);
        animalType &&
          setSearchParams({
            step: RenterAnimalSteps.SUMMARY,
            section: navigateSection ?? '',
            next: navigateNext ?? '',
          });
      })
      .catch((errorObject) => {
        setErrors(errorObject);
        setSubmitting(false);
      });
  }, [
    animalType,
    dispatch,
    navigateNext,
    navigateSection,
    resetForm,
    setErrors,
    setSearchParams,
    setSubmitting,
    values,
  ]);

  return (
    <RenterContainer>
      <section className={styles.renterBody}>
        <Form style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
          <FormCloseWarning isDirty={isDirty} />
          <div className={styles.container}>
            <div className={styles.headingLabel}>
              {t('renter.petsInformation.petDetailsLabel')} <span className={styles.renterSpan}>{animalType}.</span>
            </div>
            <AnimalDetailsCard isEditModal={false} />
            <div className={styles.renterButtonContainer}>
              <RCButton
                disabled={!isValid || isSubmitting}
                className={styles.renterButton}
                variant="outline"
                onClick={() => {
                  setSubmitting(true);
                  onContinueHandler();
                }}
              >
                {t('renter.noCreditHistory.SaveAndContinue')}
              </RCButton>
            </div>
          </div>
        </Form>
      </section>
    </RenterContainer>
  );
};

export default AnimalDetailInputCard;
