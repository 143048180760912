import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { currentInviteId, isSaving, selectedOrganization } from 'redux/selectors/organizationSelector';
import { resetInviteId } from 'redux/slices/organizationSlice';
import { AppThunkDispatch } from 'redux/store';
import { agentInviteSchema } from 'schema/agentSchema';

import { ReactComponent as SendIcon } from 'assets/svgs/SendIcon.svg';
import Button from 'components/shared/Button/Button';
import FormikField from 'components/shared/TextField/FormikTextField';
import { isPMCUser } from 'helpers/agentHelper';
import {
  AgentInviteInitialValues,
  AgentUserRoles,
  dispatchAgentInvite,
  generateLink,
  InviteAgentModalProps,
  visibleOptions,
} from 'shared/types/agentInvite';

import AdminPermissions from '../AdminPermissions/AdminPermissions';
import AgentRoleDropdown from '../AgentRoleDropdown/AgentRoleDropdown';
import ShareLink from '../ShareLink/ShareLink';

import styles from '../AgentInviteModalBody.module.scss';

const InviteForm = ({ setInviteSent }: InviteAgentModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const currentOrganization = useSelector(selectedOrganization);
  const isLinkGenerating = useSelector(isSaving);
  const inviteId = useSelector(currentInviteId);
  const currentAgentRole = currentOrganization.roleName || AgentUserRoles.AGENT;
  const agentRoleOptions = useMemo(
    () =>
      visibleOptions(
        currentAgentRole,
        isPMCUser(currentOrganization.housingProviderCategory) || currentOrganization.roleName !== AgentUserRoles.OWNER
      ),
    [currentOrganization.housingProviderCategory, currentAgentRole, currentOrganization.roleName]
  );
  const isDisabled = isEmpty(agentRoleOptions); // Check if agentRoleOptions is empty
  const handleAgentInviteEmail = useCallback(
    async (values: typeof AgentInviteInitialValues): Promise<void> => {
      const params = {
        organizationId: currentOrganization.id,
        agentEmail: values.emailAddress,
        invitedRole: values.roleName,
        permissions: values.permissions,
        inviteId: inviteId ?? null,
        inviteLink: values.inviteLink,
      };

      dispatchAgentInvite(params, dispatch, setIsDataLoading, setInviteSent);
    },
    [currentOrganization, dispatch, inviteId, setInviteSent]
  );

  useEffect(() => {
    dispatch(resetInviteId());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <Formik
        initialValues={AgentInviteInitialValues}
        validationSchema={agentInviteSchema}
        onSubmit={handleAgentInviteEmail}
      >
        {({ values, handleSubmit, isValid, dirty: isDirty, setFieldValue }) => {
          const handleChange = (): void => {
            if (values.inviteLink.length) {
              setFieldValue('inviteLink', '');
            }
          };

          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              {/* select role dropdown */}
              {!isDisabled && (
                <div className={styles.body}>
                  <span className={styles.fieldLabel}>{t('organizations.inviteAgent.modal.inviteWithEmail')}</span>
                  <FormikField
                    name="emailAddress"
                    placeholder={t('organizations.inviteAgent.modal.field.emailAddress.placeholder')}
                    label={t('organizations.inviteAgent.modal.field.emailAddress.placeholder')}
                    type="email"
                    disabled={isDataLoading}
                    className={styles.EmailAddressField}
                  />
                  <AgentRoleDropdown isLoading={isDataLoading} />
                </div>
              )}

              {/* permissions checkbox for agent role admin */}
              {values.roleName === AgentUserRoles.ADMIN && <AdminPermissions />}

              {/* send button */}
              <div className={styles.body}>
                <div className={styles.buttonContainer}>
                  <Button
                    variant="primary"
                    type="submit"
                    className={styles.sendButton}
                    disabled={!isValid || !isDirty || isDataLoading}
                  >
                    <SendIcon /> {t('organizations.inviteAgent.modal.submitButton')}
                  </Button>
                </div>
              </div>

              {/* share link field */}
              <ShareLink text={values.inviteLink} />
              <br />
              <div className={styles.buttonContainer}>
                <Button
                  variant="primary"
                  type="button"
                  className={styles.sendButton}
                  disabled={
                    !isValid ||
                    !isDirty ||
                    isLinkGenerating ||
                    isDataLoading ||
                    (!!inviteId && !!values.inviteLink.length)
                  }
                  onClick={() => generateLink(dispatch, values, setFieldValue, currentOrganization.id, inviteId)}
                >
                  {t('organizations.inviteAgent.generateLink')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InviteForm;
