import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { ReactComponent as GreyDropDownArrow } from 'assets/svgs/GreyDropDownArrowIcon.svg';
import { ReactComponent as WarningIcon } from 'assets/svgs/YellowWarningIcon.svg';
import styles from 'components/Renter/components/AddressEditForm/AddressEditDetailsForm.module.scss';
import CustomRangeDatePicker from 'components/shared/CustomRangeDatePicker/CustomRangeDatePicker';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import StateSelectField from 'components/shared/StateSelectField/StateSelectField';
import FormikField from 'components/shared/TextField/FormikTextField';
import { RenterAddressSteps } from 'constants/renterConstants';
import { AddressEditFormProps, PRESENT_ADDRESS_FROM_DATE_START, PRIOR_ADDRESS_START_DATE } from 'helpers/address';
import { US_STATES } from 'shared/data/usStates';
import { AddressInitialValuesProps } from 'shared/types/renterTypes';

const AddressEditDetailsForm = ({
  apartmentName,
  stateName,
  streetName,
  cityName,
  zipCodeName,
  toDateName,
  fromDateName,
  moveInDateName,
  isPrior,
  rentName,
  hasContactRent,
  isRenting,
}: AddressEditFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { dirty: isDirty, values } = useFormikContext<AddressInitialValuesProps>();
  const [searchParams] = useSearchParams();
  const stepName = searchParams.get('step') || 'presentAddressConfirmation';

  return (
    <div className={styles.addressEditDetailsContainer}>
      <FormCloseWarning isDirty={isDirty} />
      <div className={styles.textFieldContainer}>
        <span className={styles.textFieldLabel}>{t('addressEditForm.street')}</span>
        <FormikField name={streetName} />
      </div>
      <div className={styles.textFieldContainer}>
        <span className={styles.textFieldLabel}>{t('addressEditForm.apt')}</span>
        <FormikField name={apartmentName} />
      </div>
      <div className={styles.stateCityContainer}>
        <div className={classNames(styles.textFieldContainer, styles.cityFieldContainer)}>
          <span className={styles.textFieldLabel}>{t('addressEditForm.city')}</span>
          <FormikField name={cityName} />
        </div>
        <div className={classNames(styles.textFieldContainer, styles.stateFieldContainer)}>
          <span className={styles.textFieldLabel}>{t('addressEditForm.selectState')}</span>
          <StateSelectField
            name={stateName}
            backgroundColor="#F7FAFA"
            statesArray={US_STATES}
            dropDownIcon={<GreyDropDownArrow />}
          />
        </div>
      </div>
      <div className={styles.textFieldContainer}>
        <span className={styles.textFieldLabel}>{t('addressEditForm.zip')}</span>
        <FormikField name={zipCodeName} />
      </div>
      <div className={styles.DateContainer}>
        {!isPrior && (!!values.moveInDate || !!values.renterAddress?.moveInDate) && (
          <CustomRangeDatePicker
            data-testid="presentAddressDateRange"
            leftCalendarName={moveInDateName ?? ''}
            leftCalenderStartDate={PRESENT_ADDRESS_FROM_DATE_START}
            rightCalendarName={`renterAddress.toDate`}
            isToPresent={true}
          />
        )}
        {isPrior && !!values.toDate && !!values.fromDate && (
          <CustomRangeDatePicker
            leftCalendarName={fromDateName ?? ''}
            rightCalendarName={toDateName ?? ''}
            leftCalenderStartDate={PRIOR_ADDRESS_START_DATE}
            rightCalenderStartDate={PRIOR_ADDRESS_START_DATE}
          />
        )}
      </div>
      {hasContactRent && (
        <div
          className={classNames(styles.textFieldContainer, {
            [styles.YellowBorder]: !isRenting && stepName === RenterAddressSteps.ADDRESS_HISTORY,
          })}
        >
          <span className={styles.textFieldLabel}>{t('renter.applicationInfoBox.rent')}</span>
          <FormikField name={rentName} />
        </div>
      )}
      {hasContactRent && !isRenting && stepName === RenterAddressSteps.ADDRESS_HISTORY && (
        <div className={styles.WarningInformation}>
          <WarningIcon className={styles.WarningIcon} />
          <span className={styles.WarningText}>
            {t('address.verifierEditModal.warningText1')}
            <span className={styles.WarningTextItalic}>{t('renter.ConfirmPresentAddress.iDoNotRent')}</span>
            <span className={styles.WarningText}>{t('address.verifierEditModal.warningText2')}</span>
          </span>
        </div>
      )}
    </div>
  );
};

export default AddressEditDetailsForm;
