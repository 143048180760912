import { ReactComponent as InfoIcon } from 'assets/svgs/YellowInfoIcon.svg';

import styles from './PendingInformationBox.module.scss';
export interface PendingInformationBoxProps {
  informationalText: string;
}
export const PendingInformationBox = ({ informationalText }: PendingInformationBoxProps): JSX.Element => (
  <div className={styles.Container}>
    <InfoIcon className={styles.InfoIcon} height={20} width={20} />
    <div className={styles.Text}>{informationalText}</div>
  </div>
);
