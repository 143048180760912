import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ThreeDotIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import CopyIconButton from 'components/shared/CopyIconButton/CopyIconButton';
import { InviteTypes } from 'constants/organizationConstants';
import { formatDateTime } from 'helpers/agentHelper';
import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { Invite } from 'shared/types/organizationTypes';
import { InviteType } from 'shared/types/renterApplication';

import styles from './GeneralInvitedApplicant.module.scss';

interface Props {
  invite: Invite;
  applicationStatus: PropertyApplicationStatus;
}

export const GeneralInvitedApplicantInformation = ({ invite, applicationStatus }: Props): JSX.Element => {
  const { t } = useTranslation();
  const fullName = (invite.renterFirstName ?? '____') + ' ' + (invite.renterLastName ?? '');

  return (
    <div className={styles.InvitedContainer}>
      <div className={styles.Header}>
        <div className={styles.PillHeader}>
          <div className={styles.PillRow}>
            <AgentPills
              pillContainerClassName={styles.StatusPillContainer}
              pillsData={[{ isItalic: true, heading: t('application.idvPending'), type: PillTypes.WARNING_DARK }]}
            />
            {applicationStatus === PropertyApplicationStatus.NOT_STARTED && (
              <AgentPills
                pillContainerClassName={styles.StatusPillContainer}
                pillsData={[{ isItalic: true, heading: applicationStatus, type: PillTypes.WARM_GREY }]}
              />
            )}
            {applicationStatus === PropertyApplicationStatus.IN_PROGRESS && (
              <AgentPills
                pillContainerClassName={styles.StatusPillContainer}
                pillsData={[{ isItalic: true, heading: applicationStatus, type: PillTypes.WARNING }]}
              />
            )}
          </div>
          <div className={styles.UpdatedDate}>{formatDateTime(invite.createdAt)}</div>
        </div>
        <div className={styles.MenuRow}>
          {invite.type === InviteType.PROPERTY_SPECIFIC && <p>{t('application.request.applicationAndReports')}</p>}
          <ThreeDotIcon className={styles.ThreeDotIcon} />
        </div>
      </div>
      <div className={styles.ApplicantInfoRow}>
        <div className={classNames(styles.RowItem, styles.Name)}>{fullName}</div>
        <div className={classNames(styles.RowItem, styles.EmailRow)}>
          <p>{invite.renterEmail}</p>
          <CopyIconButton
            textToCopy={invite.renterEmail}
            className={styles.ClipBoard}
            svgClassName={styles.ClipBoardSVG}
          />
          {invite.renterPhoneNumber && <p>{invite.renterPhoneNumber}</p>}
        </div>
        {invite.type === InviteTypes.AGENT_SPECIFIC && (
          <div className={classNames(styles.RowItem, styles.RequestRow)}>
            <Button className={styles.SendRequestBtn}>{t('agent.applicants.inviteApplicant')}</Button>
          </div>
        )}
      </div>
    </div>
  );
};
