import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmploymentHistory } from '../EmploymentHistory/EmploymentHistory';
import { VerificationPage } from '../VerificationPage/VerificationPage';

export const EmploymentVerification = (): JSX.Element => {
  const { t } = useTranslation();
  const [isVerifiedUser, setIsVerifiedUser] = useState(false);

  return (
    <div>
      {isVerifiedUser ? (
        <EmploymentHistory />
      ) : (
        <VerificationPage
          heading={t('employment.historyVerification.validateToContinue')}
          subHeading={t('employment.historyVerification.providedByEmployee')}
          setIsUserVerified={setIsVerifiedUser}
        />
      )}
    </div>
  );
};
