import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Stripe } from '@stripe/stripe-js';
import { applicationProperties, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as IDIcon } from 'assets/svgs/GovernmentCardIcon.svg';
import { ReactComponent as StripIcon } from 'assets/svgs/StripeIcon.svg';
import Button from 'components/shared/Button/Button';
import Spinner from 'components/shared/Spinner/Spinner';
import { RenterProfileSteps } from 'constants/renterConstants';
import { handleStripeButtonClick, intializeStripeObject } from 'helpers/stripeHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { stripeSupportLink } from 'shared/types/stripeType';

import RenterContainer from '../../RenterContainer';
import RenterIconInfoContainer from '../../RenterIconInfoContainer';
import StripeWaiting from '../StripeWaiting/StripeWaiting';

import { AcceptedIdType } from './AcceptedIdType';

import indexStyles from '../../../RenterIndex.module.scss';
import styles from './StripeDocumentUpload.module.scss';

export type StripeCallbackType = {
  successCallBack: () => void;
  handleLoading: (isLoading: boolean) => void;
};

const StripeDocumentUpload = (): JSX.Element => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const property = useSelector(applicationProperties);
  const stripeSuccessCallBack = useCallback(() => setIsWaiting(true), [setIsWaiting]);
  const { t } = useTranslation();
  const maxHeight = useDeviceHeight(220);
  const stripeLoadingCallback = useCallback((shouldLoad: boolean) => setIsLoading(shouldLoad), []);
  const handleClick = useCallback(
    ({ successCallBack, handleLoading }: StripeCallbackType) => {
      if (!stripe) {
        return;
      }

      if (property.id) {
        handleStripeButtonClick({
          handleLoading,
          stripe,
          successCallBack,
          dispatch,
          propertyId: property.id,
          organizationId: null,
        });
      }
    },
    [dispatch, property.id, stripe]
  );

  useEffect(() => intializeStripeObject({ setStripe }), []);

  useEffect(() => {
    if (renterDetails.stripeVerified) {
      navigate(renterRoutes.generatePersonalInformationUrl(RenterProfileSteps.BASICINFO, Number(applicationId)));
    }
  }, [applicationId, navigate, renterDetails]);

  if (isWaiting) {
    return (
      <StripeWaiting
        setWaitingFalse={() => {
          console.log('');
        }}
        handleClick={handleClick}
      />
    );
  }

  return (
    <>
      <Helmet>
        <script src="https://js.stripe.com/v3" async></script>
      </Helmet>
      <RenterContainer>
        <section className={indexStyles.renterBody}>
          <div
            className={styles.container}
            style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
          >
            <RenterIconInfoContainer />
            <div className={styles.heading} data-testid="heading">
              {t('renter.PhotoID.makeThisQuick')} <br />
              {t('renter.PhotoID.scanning')} <br />
              <span>{t('renter.PhotoID.governmentID')}</span>
            </div>
            <AcceptedIdType />
            <div className={styles.uploadInformation}>
              <div>
                <IDIcon className={styles.icon} />
              </div>
              <div className={styles.button}>
                <Button
                  onClick={() =>
                    handleClick({ successCallBack: stripeSuccessCallBack, handleLoading: stripeLoadingCallback })
                  }
                  disabled={isLoading}
                >
                  <div className={styles.buttonText}>
                    <span>{t('renter.PhotoID.letsGo')}</span>
                    {isLoading && (
                      <span className={styles.loader}>
                        <Spinner />
                      </span>
                    )}
                  </div>
                </Button>
              </div>
            </div>
            <div className={styles.information}>
              <div>
                <StripIcon />
              </div>
              <div className={styles.informationText} data-testid="stripeDetail">
                {t('renter.PhotoID.information1')}
                <span>{t('renter.PhotoID.info')}</span>
                {t('renter.PhotoID.and')}
                <span>{t('renter.PhotoID.address')}</span>
                {t('renter.PhotoID.information2')}
                <Link className={styles.linkButton} to={stripeSupportLink} target="_blank" rel="noreferrer">
                  {t('renter.PhotoID.information3')}
                </Link>
              </div>
            </div>
          </div>
        </section>
      </RenterContainer>
    </>
  );
};

export default StripeDocumentUpload;
