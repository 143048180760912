/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Col } from 'react-bootstrap';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useField } from 'formik';
import heic2any from 'heic2any';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';

import DocumentIcon from 'assets/images/documentIcon.png';
import RCButton from 'components/shared/Button/Button';
import { ImageDimensions } from 'components/shared/Cropper/Cropper';
import { Notification } from 'shared/Notification/Notification';
import { FileTypes } from 'shared/types/renterIncomeInformation';

import ImageProgressBox from '../../ImageProgressBox/ImageProgressBox';

import styles from './MinimizedDropzone.module.scss';
export interface PreviewFile extends FileWithPath {
  preview: string;
  dimension?: ImageDimensions;
  url?: string;
  isLoading: boolean;
  fileLink?: string;
  documentId?: number;
  isApiMade?: boolean;
  fileName?: string;
  id?: number;
  fileType: string;
  documentSubType?: string;
  validaStatus?: string;
}
export interface IDropZone {
  title: string;
  uploadApiCall?: (previewFile: PreviewFile) => Promise<any>;
  canReorder?: boolean;
  deleteApiCall?: (previewFile: PreviewFile) => Promise<any>;
  acceptedFiles: PreviewFile[];
  setAcceptedFiles: Dispatch<React.SetStateAction<PreviewFile[]>>;
  reorderImagesApiCall?: (imageIds: (number | undefined)[]) => Promise<any>;
  openExplorer?: boolean;
  setOpenExplorer?: Dispatch<SetStateAction<boolean>>;
  parentId?: number;
  setParentId?: Dispatch<SetStateAction<number | undefined>>;
  enabled: boolean;
}
const MinimizedDropzone = ({
  title,
  uploadApiCall,
  acceptedFiles,
  setAcceptedFiles,
  canReorder,
  deleteApiCall,
  reorderImagesApiCall,
  openExplorer,
  setOpenExplorer,
  parentId,
  setParentId,
  enabled,
}: IDropZone): JSX.Element => {
  const [, , { setValue }] = useField('documents');
  const ref = useRef<HTMLDivElement>(null);
  const [isFileDeleting, setIsFileDeleting] = useState(false);
  const isAnyImageStillInProgress = useMemo(
    () => (!!acceptedFiles && acceptedFiles.some((el) => el.isLoading)) || false || isFileDeleting,
    [acceptedFiles, isFileDeleting]
  );
  const removeFile = useCallback(
    (file: PreviewFile, acceptedFile: PreviewFile[]): void => {
      if (parentId && setParentId) {
        setParentId(parentId);
      }

      setAcceptedFiles(acceptedFile.filter((f) => f.id !== file.id));
      setValue(acceptedFile.filter((f) => f.id !== file.id));

      // means that file is still not saved in db
      if (file.isLoading) {
        return;
      }

      setIsFileDeleting(true);
      deleteApiCall &&
        deleteApiCall(file)
          .then((response) => {
            if (response.images && response.images.length) {
              setAcceptedFiles(response.images);
            } else if (response.documents && response.documents.length) {
              setAcceptedFiles(response.documents);
            } else if (isEmpty(response.images) || isEmpty(response.documents)) {
              setAcceptedFiles([]);
            }
          })
          .finally(() => {
            setIsFileDeleting(false);
          });
    },
    [deleteApiCall, parentId, setAcceptedFiles, setParentId, setValue]
  );
  const isImageDimensionsValid = useCallback((file: PreviewFile): boolean => {
    if (file.dimension) {
      if (file.dimension?.height < 220 || file.dimension?.width < 330) {
        Notification({ message: t('renter.minimizedDropzone.images.sizeValidation') });

        return false;
      }
    }

    return true;
  }, []);
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(acceptedFiles);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);
    setAcceptedFiles(items);
    setValue(items);
    const renterImagesIds = items.map((el) => el.id);

    if (canReorder && reorderImagesApiCall) {
      reorderImagesApiCall(renterImagesIds);
    }
  };
  const { getRootProps, open, getInputProps } = useDropzone({
    maxSize: 26214400,
    noClick: true,
    noKeyboard: true,
    // type of file
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'image/HEIC': ['.HEIC'],
      'application/pdf': ['.pdf'],
    },
    // create the list of file, if the file type is HEIC execute if block, other case else
    onDrop: (acceptedFiles1) => {
      const tempFiles = acceptedFiles1.filter((f) => !acceptedFiles.some((cItem) => cItem.name === f.name));
      const filterImages = tempFiles.map(
        (f) =>
          new Promise((resolve, reject) => {
            const tempFile = f;
            const apiCall = async (previewFile: PreviewFile): Promise<void> => {
              const newFile: PreviewFile = {
                ...previewFile,
                isLoading: true,
                isApiMade: true,
                type: previewFile.type,
              };

              setAcceptedFiles((prevAcceptedFiles) => [...prevAcceptedFiles, uploadApiCall ? newFile : previewFile]);

              uploadApiCall &&
                (await uploadApiCall(previewFile)
                  .then((response) => {
                    // Set isLoading to false and isApiMade to true on successful uploadApiCall
                    const updatedPreviewFile: PreviewFile = {
                      ...previewFile,
                      isLoading: false,
                      isApiMade: true,
                    };

                    if (response.images && response.images.length) {
                      const lastItem = response.images.slice(-1)[0];

                      updatedPreviewFile.id = lastItem.id;
                    }

                    if (response.documents && response.documents.length) {
                      const lastItem = response.documents.slice(-1)[0];

                      updatedPreviewFile.id = lastItem.id;
                    }

                    resolve(updatedPreviewFile);
                  })
                  .catch(() => {
                    reject();
                  }));
            };

            if (tempFile.type === 'image/heic') {
              heic2any({ blob: tempFile, toType: 'image/jpeg' }).then((jpgBlob) => {
                const reader = new FileReader();

                reader.readAsDataURL(jpgBlob instanceof Array ? jpgBlob[0] : jpgBlob);
                reader.onload = function () {
                  const img = new Image();

                  img.src = reader.result as string;
                  img.onload = function () {
                    const previewFile: PreviewFile = Object.assign(f, {
                      preview: URL.createObjectURL(jpgBlob instanceof Array ? jpgBlob[0] : jpgBlob),
                      dimension: {
                        height: img.height,
                        width: img.width,
                      },
                      isLoading: true,
                      isApiMade: false,
                      url: img.src,
                      fileType: FileTypes.HEIC,
                    });

                    if (isImageDimensionsValid(previewFile)) {
                      apiCall(previewFile);
                    }
                  };
                };
              });
            }

            if (tempFile.type === 'application/pdf') {
              const reader = new FileReader();

              reader.readAsArrayBuffer(f);
              reader.onload = function () {
                const pdf = new Uint8Array(reader.result as ArrayBuffer);
                const pdfUrl = URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' }));
                const previewFile: PreviewFile = Object.assign(f, {
                  preview: pdfUrl,
                  dimension: undefined,
                  url: pdfUrl,
                  isLoading: true,
                  fileType: FileTypes.PDF,
                });

                apiCall(previewFile);
              };
            } else {
              const reader = new FileReader();

              reader.readAsDataURL(f);
              reader.onload = function () {
                const img = new Image();

                img.src = reader.result as string;
                // get the height and width of image
                img.onload = function () {
                  const previewFile: PreviewFile = Object.assign(f, {
                    preview: URL.createObjectURL(f),
                    dimension: {
                      height: img.height,
                      width: img.width,
                    },
                    isLoading: true,
                    isApiMade: false,
                    url: img.src,
                    fileType: FileTypes.JPEG,
                  });

                  if (isImageDimensionsValid(previewFile)) {
                    console.log('I am coming jerer');
                    apiCall(previewFile);
                  } else {
                    reject();
                  }
                };
              };
            }
          })
      );

      Promise.all(filterImages.map((p) => Promise.resolve(p).catch(() => undefined))).then((results) => {
        const settledPromises = results.filter((p) => p !== undefined);

        setAcceptedFiles([...acceptedFiles, ...(settledPromises as PreviewFile[])]);
        setValue([...acceptedFiles, ...(settledPromises as PreviewFile[])]);
      });
    },
  });
  const RenderFiles = ({ file, index }: { file: PreviewFile; index: number }): JSX.Element => {
    const { preview } = file;
    const imgSource = useMemo(() => {
      if (file.fileType === FileTypes.PDF) {
        return DocumentIcon;
      }

      return file.fileLink ? file.fileLink : preview;
    }, [file, preview]);

    return (
      <div className={styles.previewContainer}>
        <ImageProgressBox
          key={index}
          fileType={file.fileType}
          imgSrc={imgSource}
          fileName={(file.path || file.fileName) ?? file.name}
          label={'Uploading'}
          onRemoveHandler={() => deleteApiCall && removeFile(file, acceptedFiles)}
          isImageUploaded={file.isLoading} //isLoading is set to false when image is successfully uploaded
          isRemovable={enabled}
        />
      </div>
    );
  };

  useEffect(() => {
    if (openExplorer && setOpenExplorer) {
      open();
      setOpenExplorer(false);
    }
  }, [open, openExplorer, setOpenExplorer]);

  return (
    <section {...getRootProps()} className={styles.minimizedDropzone}>
      <div className={'dropzone'}>
        <input {...getInputProps({ multiple: true })} />
        <div className={styles.dropzoneContentContainer}>
          <Col>
            <div ref={ref} className={styles.previewList}>
              <span className={styles.title}>{title}</span>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-image" direction="vertical">
                  {(provided) => (
                    <ul className={styles.imagesListContainer} {...provided.droppableProps} ref={provided.innerRef}>
                      {acceptedFiles.map((file, index) =>
                        canReorder ? (
                          <Draggable
                            key={index}
                            draggableId={`draggable-${index}`}
                            index={index}
                            isDragDisabled={isAnyImageStillInProgress}
                          >
                            {(currentImageProvided) => (
                              <li
                                ref={currentImageProvided.innerRef}
                                {...currentImageProvided.draggableProps}
                                {...currentImageProvided.dragHandleProps}
                                style={{ ...currentImageProvided.draggableProps.style }}
                                className={styles.imageUploadList}
                              >
                                <div className={styles.fileContainer} key={index}>
                                  <RenderFiles file={file} index={index} />
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ) : (
                          <li key={`${file}-${index}`} className={styles.imageUploadList}>
                            <div className={styles.fileContainer} key={index}>
                              <RenderFiles file={file} index={index} />
                            </div>
                          </li>
                        )
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Col>
        </div>
        {acceptedFiles.length === 0 && (
          <div className={styles.noFileSelected}>
            <span className={styles.text}>{t('renter.minimizedDropzone.noFiles')}</span>
          </div>
        )}
        <div className={styles.footer}>
          <span className={styles.title}>{t('renter.minimizedDropzone.acceptedFormats')}</span>
        </div>
        {enabled && (
          <div className={styles.buttonsContainer}>
            <RCButton variant="outline" onClick={open} className={styles.selectButton}>
              {t('renter.minimizedDropzone.selectFile')}
            </RCButton>
          </div>
        )}
      </div>
    </section>
  );
};

export default MinimizedDropzone;
