import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import {
  amenitiesFeaturePropertyList,
  amenitiesFeatureUnitList,
  propertyLaundryAmenities,
  propertyParkingOption,
} from 'redux/selectors/propertySelector';

import { ReactComponent as AddIcon } from 'assets/svgs/AddIcon.svg';
import { ReactComponent as DropDownFillArrow } from 'assets/svgs/dropDownFillArrow.svg';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import { allowedPetsValues } from 'constants/propertyConstants';
import { AddPropertyParams, PropertyProps } from 'shared/types/propertyType';

import AddCustomFeature from './Amenities/AddCustomFeature';
import AmenitiesFeature from './Amenities/AmenitiesFeature';
import CustomChip from './Amenities/FeatureTag';

import styles from './AddProperty.module.scss';
import amenitiesStyles from './Amenities/AmenitiesFeature.module.scss';

export type AmentitesProps = {
  handleBlur: (params: AddPropertyParams) => void;
};

const Amenities = ({ handleBlur }: AmentitesProps): JSX.Element => {
  const [isFeatureClicked, setFeatureClicked] = useState(false);
  const [isCustomFeatureClicked, setIsCustomFeatureClicked] = useState(false);
  const propertyParkingOptionList = useSelector(propertyParkingOption);
  const propertyLaundryAmenitiesList = useSelector(propertyLaundryAmenities);
  const customUnitFeature = useSelector(amenitiesFeatureUnitList);
  const customPropertyFeature = useSelector(amenitiesFeaturePropertyList);
  const { values, setFieldValue } = useFormikContext<PropertyProps>();
  const { t } = useTranslation();
  const handleNoPetsChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked, value } = event.target;
    let updatedAllowedPets: string[] = [];

    if (value === 'No pets') {
      updatedAllowedPets = checked ? ['No pets'] : [];
    } else {
      if (checked) {
        updatedAllowedPets = values.allowedPets
          ? values.allowedPets.filter((pet) => pet !== 'No pets').concat(value)
          : [value];
      } else {
        updatedAllowedPets = values.allowedPets ? values.allowedPets.filter((pet) => pet !== value) : [];
      }
    }

    setFieldValue('allowedPets', updatedAllowedPets);
  };
  const toggleShowFeature = (isShowFeature: boolean): void => {
    setFeatureClicked(isShowFeature);
  };
  const toggleCustomShowFeature = (): void => {
    setIsCustomFeatureClicked(!isCustomFeatureClicked);
  };

  return (
    <div className={styles.amenities}>
      <Row>
        <h1 className={styles.formHeading}>Amenities</h1>
      </Row>
      <Row>
        <p className={styles.amenitiesDescription}>{t('agent.addProperty.amenitiesDescription')}</p>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <FormikSelectField
            hasFloatingLabel={true}
            floatingLabel="Laundry"
            placeholder="Select"
            name="propertyLaundryAmenity"
            options={propertyLaundryAmenitiesList}
            dropDownIconContainer={styles.dropDownFillArrow}
            dropDownIcon={<DropDownFillArrow />}
            handleBlur={handleBlur}
          />
        </Col>
        <Col md={6} sm={12} className={styles.propertyName}>
          <FormikSelectField
            hasFloatingLabel={true}
            floatingLabel="Parking"
            placeholder="Select"
            name="propertyParkingOption"
            options={propertyParkingOptionList}
            dropDownIconContainer={styles.dropDownFillArrow}
            dropDownIcon={<DropDownFillArrow />}
            handleBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row>
        <p className={styles.petsLabel}>Pets</p>
      </Row>
      <Row>
        {allowedPetsValues.map((pet, index) => (
          <Col md={3} sm={12} key={index}>
            <Field
              as={Checkbox}
              className={styles.checkboxLabel}
              name="allowedPets"
              label={pet.label}
              value={pet.value}
              onChange={handleNoPetsChecked}
              onBlur={() => handleBlur && handleBlur({ allowedPets: values.allowedPets })}
              checked={values.allowedPets?.includes(pet.value)}
            />
          </Col>
        ))}
      </Row>

      <Row className={amenitiesStyles.amenitiesDiv}>
        <Col>
          <h2 className={amenitiesStyles.amenitiesLabel}>Additional</h2>
          <p className={amenitiesStyles.amenitiesParagraph}>{t('agent.addProperty.featuresLabel')}</p>
          <AmenitiesFeature
            handleBlur={handleBlur}
            toggleShowFeature={toggleShowFeature}
            isFeatureClicked={isFeatureClicked}
          />
        </Col>
      </Row>
      <Row className={amenitiesStyles.amenitiesPadding}>
        <Col>
          <Row>
            <Col>
              <div
                aria-hidden="true"
                onClick={toggleCustomShowFeature}
                className={amenitiesStyles.customAmenitiesButton}
              >
                <span>
                  <AddIcon className={styles.AddButton} />
                </span>
                Add Custom
              </div>
            </Col>
          </Row>
          {isCustomFeatureClicked && (
            <Row>
              <Col>
                <AddCustomFeature handleBlur={handleBlur} onClick={toggleCustomShowFeature} name="customFeatureFiled" />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <CustomChip handleBlur={handleBlur} data={customUnitFeature} title="for Unit" type="unit" />
              <CustomChip handleBlur={handleBlur} data={customPropertyFeature} title="for Property" type="property" />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Amenities;
