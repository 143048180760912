import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { selectedAgent } from 'redux/selectors/renterSelector';
import { getAgentDetails } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { AddressFormSchema } from 'schema/renterSchema';

import { ReactComponent as BackArrow } from 'assets/svgs/ArrowLeftDirected.svg';
import RenterContainer from 'components/Renter/components/RenterContainer';
import indexStyles from 'components/Renter/RenterIndex.module.scss';
import { StepRouteType } from 'helpers/address';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { createCustomAddresses, CustomPropertySteps, InviteDataKeys } from 'shared/types/renterApplication';
import { RenterCustomAddressInitialValues } from 'shared/types/renterTypes';
import { formatDateForBackend } from 'shared/utils/formatDate';

import { RenterConfirmCustomAddress } from '../RenterConfirmCustomAddress/RenterConfirmCustomAddress';

import RenterCustomAddress from './RenterCustomAddress';

import styles from './CustomAddress.module.scss';
export const CustomAddress = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [param] = useSearchParams();
  const stepName = param.get('stepName') || CustomPropertySteps.CREATE_PROPERTY;
  const inviteCode = param.get(InviteDataKeys.INVITE_CODE) ?? '';
  const customPropertyComponents: StepRouteType = {
    [CustomPropertySteps.CREATE_PROPERTY]: <RenterCustomAddress />,
    [CustomPropertySteps.CONFIRM_PROPERTY]: <RenterConfirmCustomAddress />,
  };
  const inviteAgent = useSelector(selectedAgent);
  const maxHeight = useDeviceHeight(230);

  useEffect(() => {
    if (inviteCode && isEmpty(inviteAgent)) {
      dispatch(getAgentDetails({ inviteCode }));
    }
  }, [dispatch, inviteAgent, inviteCode]);

  return (
    <Formik
      initialValues={RenterCustomAddressInitialValues}
      validationSchema={AddressFormSchema.at(3)}
      onSubmit={(values, { setErrors }) => {
        const customAddress = {
          streetAddress1: values.streetAddress1.split(',')[0],
          zipCode: values.zipCode,
          city: values.city,
          inviteCode: values.inviteCode,
          desiredMoveInDate: formatDateForBackend(values.desiredMoveInDate),
          state: values.state,
        };

        createCustomAddresses({ values: customAddress, setErrors, navigate, dispatch });
      }}
    >
      <RenterContainer>
        <section className={indexStyles.renterBody}>
          <div className={styles.BackIcon}>
            <BackArrow height={50} onClick={() => navigate(-1)} />
          </div>
          <div style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto', overflow: 'visible' }}>
            {customPropertyComponents[stepName]}
          </div>
        </section>
      </RenterContainer>
    </Formik>
  );
};
