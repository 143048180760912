import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import { format } from 'date-fns';
import { isEmpty, omitBy } from 'lodash-es';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { dateFormatBackend } from 'constants/calendarConstants';
import { formatSSN } from 'helpers/renterHelper';
import { DobValues, PersonalProfileProps, PersonalProfileValues } from 'shared/types/renterTypes';

import { PersonalProfileView } from './components/index';

const PersonalProfile = ({ isReportPage = false }: PersonalProfileProps): JSX.Element => {
  const [isGuarantorModalOpen, setIsGuarantorModalOpen] = useState(false);
  const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);
  const [isPhoneEditModalOpen, setIsPhoneEditModalOpen] = useState(false);
  const [isSSNEditModalOpen, setIsSSNEditModalOpen] = useState(false);
  const [shouldShowPersonalInfoModal, setShouldShowPersonalInfoModal] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const renterDetails = useSelector(selectRenterProfileInformation);
  const renterPhoneNumber = useMemo((): string => {
    if (renterDetails.renterPhoneNumbers?.length) {
      return renterDetails.renterPhoneNumbers[0].phoneNumber ?? '';
    }

    return '';
  }, [renterDetails]);
  const renterSSN = useMemo(
    (): string => (renterDetails.ssnTailDigits ? formatSSN(renterDetails.ssnTailDigits.toString(), true) : '---'),
    [renterDetails]
  );
  const formInitialValues = useMemo(
    (): PersonalProfileValues => ({
      firstName: renterDetails.firstName ?? '',
      lastName: renterDetails.lastName ?? '',
      middleName: renterDetails.middleName ?? '',
    }),
    [renterDetails]
  );
  const dobFormInitialValues = useMemo(
    (): DobValues => ({
      birthDate: renterDetails?.birthDate && new Date(parseISO(renterDetails.birthDate)),
    }),
    [renterDetails]
  );
  const handleProfileUpdate = useCallback(
    (values: PersonalProfileValues): void => {
      const newValues = omitBy(values, isEmpty);

      dispatch(addRenterProfileInformation({ values: { ...newValues, id: renterDetails.userId } }))
        .unwrap()
        .then(() => setIsProfileEditModalOpen(false));
    },
    [dispatch, renterDetails]
  );
  const handleDobUpdate = useCallback(
    (values: DobValues): void => {
      const newValues = {
        ...values,
        birthDate: values.birthDate && format(new Date(values.birthDate), dateFormatBackend),
      };

      dispatch(addRenterProfileInformation({ values: { ...omitBy(newValues, isEmpty), id: renterDetails.userId } }))
        .unwrap()
        .then(() => setShouldShowPersonalInfoModal(false));
    },
    [dispatch, renterDetails]
  );

  return (
    <PersonalProfileView
      renterDetails={renterDetails}
      renterPhoneNumber={renterPhoneNumber}
      renterSSN={renterSSN}
      isGuarantorModalOpen={isGuarantorModalOpen}
      isProfileEditModalOpen={isProfileEditModalOpen}
      isPhoneEditModalOpen={isPhoneEditModalOpen}
      isSSNEditModalOpen={isSSNEditModalOpen}
      formInitialValues={formInitialValues}
      handleEditGuarantor={(isValue) => setIsGuarantorModalOpen(isValue)}
      handleEditInfo={(isValue) => setIsProfileEditModalOpen(isValue)}
      handleEditPhone={(isValue) => setIsPhoneEditModalOpen(isValue)}
      handleEditSSN={(isValue) => setIsSSNEditModalOpen(isValue)}
      handleProfileUpdate={handleProfileUpdate}
      isReportPage={isReportPage}
      handleDobUpdate={handleDobUpdate}
      setShouldShowPersonalInfoModal={setShouldShowPersonalInfoModal}
      shouldShowPersonalInfoModal={shouldShowPersonalInfoModal}
      dobFormInitialValues={dobFormInitialValues}
    />
  );
};

export default PersonalProfile;
