import { useCallback, useMemo } from 'react';
import { Modal as BModal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Form, Formik, FormikErrors } from 'formik';
import { isEmpty } from 'lodash-es';
import { updateRenterGuarantorInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { GuarantorFormSchema } from 'schema/renterSchema';

import RCButton from 'components/shared/Button/Button';
import { RenterGuarantorSteps } from 'constants/renterConstants';
import {
  EditGuarantorDetailsProps,
  GuarantorDispatchProps,
  GuarantorInitialValuesProps,
} from 'shared/types/renterTypes';

import RenterModal from '../../RenterModal/RenterModal';
import GuarantorDetailsForm from '../GuarantorDetailsForm/GuarantorDetailsForm';

import styles from './GuarantorEditModal.module.scss';

const GuarantorEditModal = ({
  guarantorInformation,
  isModalVisible,
  setIsModalVisible,
  onHideHandler,
}: EditGuarantorDetailsProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t } = useTranslation();
  const { applicationId, appId } = useParams();
  const [, setSearchParams] = useSearchParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const onUpdateGuarantor = useCallback(
    ({
      guarantorDispatchInformation,
      id,
      setErrors,
    }: {
      guarantorDispatchInformation: GuarantorDispatchProps;
      id: number;
      setErrors: (errors: FormikErrors<GuarantorInitialValuesProps>) => void;
    }) => {
      dispatch(
        updateRenterGuarantorInformation({
          applicationId: Number(currentApplicationId),
          values: guarantorDispatchInformation,
          id: id,
        })
      )
        .unwrap()
        .then(() => {
          setSearchParams({ step: RenterGuarantorSteps.SUMMARY, fromVerification: RenterGuarantorSteps.SUMMARY });
          onHideHandler();
        })
        .catch((errorObject) => {
          setErrors(errorObject);
        });
    },
    [dispatch, currentApplicationId, setSearchParams, onHideHandler]
  );
  const handleSubmit = useCallback(
    (values: GuarantorInitialValuesProps, setErrors: (errors: FormikErrors<GuarantorInitialValuesProps>) => void) => {
      const guarantorDispatchInformation: GuarantorDispatchProps = {
        relationship: values.relationship ?? '',
        phoneNumber: values.phoneNumber?.includes('+') ? values.phoneNumber : '+' + values.phoneNumber,
        emailAddress: values.emailAddress ?? '',
      };

      if (values.relation.id) {
        onUpdateGuarantor({
          guarantorDispatchInformation: guarantorDispatchInformation,
          id: values.relation.id,
          setErrors: setErrors,
        });
      }

      setIsModalVisible(false);
    },
    [onUpdateGuarantor, setIsModalVisible]
  );

  return (
    <Formik
      initialValues={{
        relationship: guarantorInformation.relationship,
        emailAddress: guarantorInformation.emailAddress,
        phoneNumber: guarantorInformation.phoneNumber,
        relation: guarantorInformation,
      }}
      validationSchema={GuarantorFormSchema}
      onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
      enableReinitialize
    >
      {({ isValid, errors, dirty: isDirty }) => (
        <RenterModal show={isModalVisible} onHide={onHideHandler} isDirty={isDirty}>
          <BModal.Body>
            <Form>
              <div className={styles.heading}>{t('renter.PhotoIdInformation.editDetails')}</div>
              <div className={styles.subHeading}>
                {t('renter.PhotoIdInformation.makeChanges')}&nbsp;
                <span className={styles.spanColor}>{t('renter.guarantorInformationForm.guarantor')}</span>&nbsp;
                {t('renter.PhotoIdInformation.information')}
              </div>
              <GuarantorDetailsForm
                emailAddressName="emailAddress"
                relationshipName="relationship"
                phoneNumberName="phoneNumber"
              />
              <div className={styles.renterButtonContainer}>
                <RCButton
                  className={styles.renterButton}
                  disabled={!isEmpty(errors) || !isDirty || !isValid}
                  variant="outline"
                  type="submit"
                >
                  {t('renter.PhotoIdInformation.savechanges')}
                </RCButton>
              </div>
            </Form>
          </BModal.Body>
        </RenterModal>
      )}
    </Formik>
  );
};

export default GuarantorEditModal;
