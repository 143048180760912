import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { GuarantorViewPDFProps } from 'shared/types/reportTypes';

import { FieldViewPDF } from '../shared/FieldViewPDF/FieldViewPDF';

const styles = StyleSheet.create({
  Container: {
    padding: '4px 16px',
    borderRadius: '4px',
    border: '1px solid #E1EAEB',
  },
  GuarantorInformation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
  },
  NotFound: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
  },
  HorizontalSpacing: {
    flex: 1,
  },
});

export const GuarantorViewPDF = ({ email, relationship }: GuarantorViewPDFProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View style={styles.Container} wrap={false}>
      {!!email && !!relationship ? (
        <View style={styles.GuarantorInformation}>
          <View style={styles.HorizontalSpacing}>
            <FieldViewPDF label={t('agent.applicants.renterResume.sourceOfIncome.verifier.email')} value={email} />
          </View>
          <View style={styles.HorizontalSpacing}>
            <FieldViewPDF label={t('renter.relationship.relationshipLabel')} value={relationship} />
          </View>
        </View>
      ) : (
        <Text style={styles.NotFound}>{t('renter.guarantorInformationForm.noGuarantor')}</Text>
      )}
    </View>
  );
};
