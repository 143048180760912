import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Formik } from 'formik';
import { applicantRenterRole } from 'redux/selectors/renterSelector';
import { renterProfileSchema } from 'schema/renterSchema';

import { ReactComponent as PersonIcon } from 'assets/svgs/PersonNoBorderIcon.svg';
import EditPhoneNumberModal from 'components/Renter/components/EditPhoneNumberModal/EditPhoneNumberModal';
import EditSSNModal from 'components/Renter/components/EditSSNModal/EditSSNModal';
import GuarantorEditModal from 'components/Renter/components/Guarantor/GuarantorEditModal/GuarantorEditModal';
import RenterInfoDetailsModal from 'components/Renter/components/RenterInfoDetailsModal/RenterInfoDetailsModal';
import { RenterPersonalInfoModal } from 'components/Renter/components/RenterPersonalInfoModal/RenterPersonalInfoModal';
import styles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import { Ladder } from 'components/Renter/IdentityVerification/RenterDetails/shared/Ladder/Ladder';
import Title from 'components/Renter/IdentityVerification/RenterDetails/shared/Title/Title';
import { renterRestrictions } from 'constants/restrictionConstants';
import { findApplication } from 'helpers/agentHelper';
import { formatRenterName } from 'helpers/renterHelper';
import { formattedDateString } from 'helpers/user';
import { DobValues, PersonalProfileValues, RenterProfileInformationProps } from 'shared/types/renterTypes';

import {
  PersonalProfileToolTip,
  RenterContactInformation,
  RenterGuarantor,
  RenterInformation,
  RenterSSN,
} from '../../components';

type PersonalProfileViewProps = {
  renterDetails: RenterProfileInformationProps;
  renterPhoneNumber: string;
  renterSSN: string;
  isGuarantorModalOpen: boolean;
  isProfileEditModalOpen: boolean;
  isPhoneEditModalOpen: boolean;
  isSSNEditModalOpen: boolean;
  formInitialValues: PersonalProfileValues;
  handleEditGuarantor: (value: boolean) => void;
  handleEditInfo: (value: boolean) => void;
  handleEditPhone: (value: boolean) => void;
  handleEditSSN: (value: boolean) => void;
  handleProfileUpdate: (values: PersonalProfileValues) => void;
  isReportPage: boolean;
  handleDobUpdate: (value: DobValues) => void;
  setShouldShowPersonalInfoModal: (value: boolean) => void;
  shouldShowPersonalInfoModal: boolean;
  dobFormInitialValues: DobValues;
};

const PersonalProfileView = ({
  renterDetails,
  renterSSN,
  isGuarantorModalOpen,
  isPhoneEditModalOpen,
  isSSNEditModalOpen,
  isProfileEditModalOpen,
  formInitialValues,
  handleEditPhone,
  handleEditGuarantor,
  handleEditInfo,
  handleProfileUpdate,
  handleEditSSN,
  isReportPage,
  handleDobUpdate,
  setShouldShowPersonalInfoModal,
  shouldShowPersonalInfoModal,
  dobFormInitialValues,
}: PersonalProfileViewProps): JSX.Element => {
  const renterRole = useSelector(applicantRenterRole);
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const currentApplication = useMemo(
    () => findApplication(renterDetails?.applications, Number(currentApplicationId)),
    [currentApplicationId, renterDetails]
  );
  const handleStripeInfoModal = (): void => {
    setShouldShowPersonalInfoModal(true);
    handleEditInfo(false);
  };
  const [selectedPhoneNum, setSelectedPhoneNum] = useState(0);

  return (
    <>
      <Title
        mainTitle={formatRenterName(renterDetails)}
        iconElement={<PersonIcon />}
        shouldShowOptions
        optionElement={
          isReportPage ? undefined : (
            <PersonalProfileToolTip
              handleEditGuarantor={() => handleEditGuarantor(true)}
              handleEditInfo={() => handleEditInfo(true)}
              handleEditPhone={() => handleEditPhone(true)}
              handleEditSSN={() => handleEditSSN(true)}
              setSelectedPhoneNum={setSelectedPhoneNum}
            />
          )
        }
      />
      {!isReportPage && (
        <div className={styles.borderContainer}>
          <RenterInformation
            dateOfBirth={formattedDateString(renterDetails.birthDate)}
            idNumber="0000000"
            idType="Passport"
          />
        </div>
      )}
      <div className={styles.personalInformationContainer}>
        <div className={styles.fieldContainer}>
          <Ladder isBottomPadding={false} />
          <div className={classNames(styles.borderContainer, styles.noTopBorder)}>
            <RenterContactInformation
              email={renterDetails.email ?? '---'}
              phoneNumbers={renterDetails?.renterPhoneNumbers}
            />
          </div>
        </div>

        <div className={styles.fieldContainer}>
          <Ladder isBottomPadding={false} />
          <div className={classNames(styles.borderContainer, styles.noTopBorder)}>
            <RenterSSN ssn={renterSSN} />
          </div>
        </div>
        {!renterRestrictions[renterRole].guarantor && !isReportPage && (
          <div className={styles.fieldContainer}>
            <Ladder isBottomPadding />
            <div className={classNames(styles.borderContainer, styles.noTopBorder)}>
              <RenterGuarantor guarantor={currentApplication?.guarantor} />
            </div>
          </div>
        )}
      </div>

      {isGuarantorModalOpen && (
        <GuarantorEditModal
          guarantorInformation={currentApplication?.guarantor ?? {}}
          setIsModalVisible={() => handleEditGuarantor(true)}
          isModalVisible={isGuarantorModalOpen}
          onHideHandler={() => handleEditGuarantor(false)}
        />
      )}
      {isPhoneEditModalOpen && (
        <EditPhoneNumberModal
          onHide={() => handleEditPhone(false)}
          shouldShowModal={isPhoneEditModalOpen}
          index={selectedPhoneNum}
        />
      )}
      {isSSNEditModalOpen && <EditSSNModal onHide={() => handleEditSSN(false)} shouldShowModal={isSSNEditModalOpen} />}
      {isProfileEditModalOpen && (
        <Formik
          onSubmit={handleProfileUpdate}
          initialValues={formInitialValues}
          enableReinitialize
          validationSchema={renterProfileSchema['basicInfo']}
        >
          <div>
            <RenterInfoDetailsModal
              shouldShowModal={isProfileEditModalOpen}
              onCloseModal={() => handleEditInfo(false)}
              onSubmit={() => console.log('form')}
              dateOfBirth={formattedDateString(renterDetails.birthDate)}
              documentNumber="0000000"
              documentType="Passport"
              handleStripeInfoModal={handleStripeInfoModal}
            />
          </div>
        </Formik>
      )}
      {shouldShowPersonalInfoModal && (
        <Formik onSubmit={handleDobUpdate} initialValues={dobFormInitialValues} enableReinitialize>
          <div>
            <RenterPersonalInfoModal
              shouldShowModal={shouldShowPersonalInfoModal}
              onCloseModal={() => {
                setShouldShowPersonalInfoModal(false);
                handleEditInfo(true);
              }}
            />
          </div>
        </Formik>
      )}
    </>
  );
};

export default PersonalProfileView;
