import classNames from 'classnames';

import { PropertySpecificationProps } from 'shared/types/propertyType';

import styles from './PropertySpecification.module.scss';
export const PropertySpecification = ({
  value,
  label,
  valueClassName,
  labelClassName,
}: PropertySpecificationProps): JSX.Element => {
  const specificationValue = value.length ? value : '-';

  return (
    <div className={styles.Specification}>
      <div className={classNames(styles.SpecificationValue, valueClassName)}>{specificationValue}</div>
      <div className={classNames(styles.SpecificationLabel, labelClassName)}>{label}</div>
    </div>
  );
};
