import { useTranslation } from 'react-i18next';

import { ReactComponent as PeopleIcon } from 'assets/svgs/PeopleIcon.svg';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { RenterInformationHeaderProps } from 'shared/types/reportTypes';

import styles from './RenterInformationHeader.module.scss';

export const RenterInformationHeader = ({
  renterName,
  status,
  totalOccupants,
  propertyAddress,
}: RenterInformationHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const getPillType = (): PillTypes => {
    if (status === PropertyApplicationStatus.COMPLETED) {
      return PillTypes.SUCCESS;
    } else if (status === PropertyApplicationStatus.IN_PROGRESS) {
      return PillTypes.WARNING_DARK;
    }

    return PillTypes.SECONDARY;
  };

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.RenterInformation}>
          <div className={styles.RenterName}>{renterName}</div>
          <AgentPills
            pillContainerClassName={styles.StatusPillContainer}
            pillsData={[{ isItalic: true, heading: status, type: getPillType() }]}
          />
        </div>
        <div className={styles.OccupantInformation}>
          <div className={styles.OccupantCount}>{`1 of ${totalOccupants}`}</div>
          <div className={styles.OccupantIcon}>
            <PeopleIcon className={styles.PeopleIcon} />
            <div className={styles.IconText}>{t('incomeReport.occupants')}</div>
          </div>
        </div>
      </div>
      {propertyAddress && (
        <div className={styles.Footer}>
          <div className={styles.AddressTitle}>{t('incomeReport.applyingFor')}</div>
          <div className={styles.Address}>{propertyAddress}</div>
        </div>
      )}
    </div>
  );
};
