import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import classNames from 'classnames';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { removePropertyApplication, updateApplicationFileStatus } from 'redux/slices/agentSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as CheckIcon } from 'assets/svgs/CheckIconLightGrey.svg';
import { ReactComponent as MenuIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { ApplicationActionOptions } from 'constants/dropdownOptions';
import { IsMobileApplicantsPage } from 'helpers/IsMobile';
import { ApplicationActions } from 'shared/types/agentTypes';
import { ApplicationHeaderProps } from 'shared/types/applicantsType';

import { ActiveStatus } from '../ActiveStatus/ActiveStatus';
import { AnimalCapsule } from '../PropertyCapsules/AnimalCapsule/AnimalCapsule';
import { BudgetCapsule } from '../PropertyCapsules/BudgetCapsule/BudgetCapsule';
import { MoveInDateCapsule } from '../PropertyCapsules/MoveInDateCapsule/MoveInDateCapsule';
import { OccupantsCapsule } from '../PropertyCapsules/OccupantsCapsule/OccupantsCapsule';

import styles from './ApplicationHeader.module.scss';

export const ApplicationHeader = ({
  moveInDate,
  activeDate,
  propertyRent,
  minorAddedByText,
  occupantCount,
  minorCount,
  petCount,
  petAddedByText,
  budgetAmount,
  isShowActive = true,
  applicationFileStatus,
  applicationId,
  propertyId,
}: ApplicationHeaderProps): JSX.Element => {
  const isMobileView = IsMobileApplicantsPage();
  const dispatch = useDispatch<AppThunkDispatch>();
  const currentOrganization = useSelector(selectedOrganization);
  // const [isShowingOverlay, setIsShowingOverlay] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOptionChange = (option: any): void => {
    const { value } = option;

    if (value === ApplicationActions.EXPORT) {
      return;
    }

    const body = {
      applicationId: Number(applicationId),
      propertyId: Number(propertyId),
      status: value,
    };

    dispatch(updateApplicationFileStatus({ organizationId: Number(currentOrganization.id), values: { ...body } }))
      .unwrap()
      .then(() => {
        dispatch(removePropertyApplication({ propertyId, applicationId }));
      });
  };
  const CustomOption = (props: any): JSX.Element => {
    const { data, label } = props;

    if (data.isDummy) {
      return <div className={styles.DropdownDummyOption}>{label}</div>;
    }

    if ((label as string).toLowerCase() === ApplicationActions.EXPORT) {
      return <div></div>;
    }

    if ((label as string).toLowerCase() === applicationFileStatus?.toLowerCase()) {
      return (
        <div className={styles.OptionDisabledContainer}>
          <div className={classNames(styles.OptionDisabled, styles.DropdownOptionLabel)}>{label}</div>
          <CheckIcon className={styles.CheckIcon} />
        </div>
      );
    }

    return (
      <components.Option {...props}>
        <span className={classNames(styles.DropdownOptionLabel)}>{label}</span>
      </components.Option>
    );
  };

  return (
    <div className={styles.Container}>
      {isShowActive && (
        <div className={styles.Header}>
          <div className={styles.DashContainer}>{!isMobileView && <div className={styles.Dash} />}</div>
          <ActiveStatus activeDate={activeDate} status={applicationFileStatus as string} />
        </div>
      )}
      <div className={styles.InformationContainer}>
        <div className={classNames(styles.CapsulesContainer, styles.MoveInDateCapsule)}>
          <MoveInDateCapsule moveInDate={moveInDate} />
        </div>
        <div className={classNames(styles.CapsulesContainer, styles.OccupantCapsule)}>
          <OccupantsCapsule
            minorsCount={minorCount}
            occupantsCount={occupantCount}
            minorAddedByText={minorAddedByText}
          />
        </div>
        <div className={classNames(styles.CapsulesContainer, styles.AnimalCapsule)}>
          <AnimalCapsule petsAddedText={petAddedByText} petsCount={petCount} />
        </div>
        <div className={classNames(styles.CapsulesContainer, styles.BudgetCapsule)}>
          <BudgetCapsule propertyRent={propertyRent} budgetAmount={budgetAmount} />
        </div>
        <div className={styles.DropdownContainer}>
          {applicationFileStatus && (
            <ReactSelect
              className={styles.reactSelect}
              isSearchable={false}
              placeholder={<MenuIcon />}
              options={ApplicationActionOptions}
              value={null}
              customOption={CustomOption}
              onChange={handleOptionChange}
            />
          )}
        </div>
        {/* {!isMobileView && (
          <div className={styles.OptionsContainer}>
            <PersonIcon width={16} height={16} className={styles.PersonIcon} />
            <RCButton onBlur={() => setIsShowingOverlay(false)} className={styles.IconButton}>
              <ApplicationTooltip
                isShowingOverlay={isShowingOverlay}
                setIsShowingOverlay={setIsShowingOverlay}
                onRenterResumeSelect={onRenterResumeSelect}
                isShowRenterResume={true}
              />
            </RCButton>
          </div>
        )} */}
      </div>
    </div>
  );
};
