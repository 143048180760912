import { StyleSheet, Text, View } from '@react-pdf/renderer';

interface Props {
  label: string;
  value: string;
}

const styles = StyleSheet.create({
  Information: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '4px',
  },
  Label: {
    color: '#333',
    fontSize: '11px',
    textAlign: 'left',
  },
  Value: {
    color: '#333',
    textAlign: 'right',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
});

export const InformationItemPDF = ({ label, value }: Props): JSX.Element => (
  <View style={styles.Information}>
    <Text style={styles.Label}>{label}</Text>
    <Text style={styles.Value}>{value}</Text>
  </View>
);
