import React from 'react';
import { FormCheckProps } from 'react-bootstrap';

import styles from './RadioButton.module.scss';

export const RadioButton: React.FC<FormCheckProps> = ({ label, name, className, ...props }) => (
  <div className={styles.Container}>
    <div className={styles.OuterContainer}>
      <input {...props} type="radio" name={name} className={styles.RadioContainer} />
    </div>
    <span className={className}>{label}</span>
  </div>
);
