import { useTranslation } from 'react-i18next';

import { isEven } from 'helpers/reportsHelper';
import { InquiryReportItem } from 'shared/types/reportTypes';

import { ReportHeading } from '../ReportHeading/ReportHeading';
import { EmptyReportRecord } from '../SharedReports/EmptyReportRecord/EmptyReportRecord';
import { InquiryReport } from '../SharedReports/InquiryReport/InquiryReport';

import styles from './InquiryInformation.module.scss';

export const InquiryInformation = ({
  inquiryInformation,
}: {
  inquiryInformation: InquiryReportItem[] | null;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <ReportHeading
        heading={t('agent.creditReport.inquiry')}
        hasCounter={!!inquiryInformation?.length}
        counter={inquiryInformation?.length ?? 0}
      />
      {!!inquiryInformation?.length ? (
        <div className={styles.ScrollableInformation}>
          <div className={styles.InquiryHeader}>
            <div className={styles.LeftHeadings}>
              <div className={styles.Heading}>{t('creditReport.dateLabel')}</div>
              <div className={styles.Heading}>{t('renter.CollectedInformation.name')}</div>
            </div>
            <div className={styles.RightHeadings}>
              <div className={styles.Heading}>{t('creditReport.kindOfBusinesss')}</div>
            </div>
          </div>
          <div className={styles.InquiryReports}>
            {inquiryInformation?.map((inquiryReport, index) => (
              <InquiryReport
                key={`inquiry-report-${inquiryReport}-${index}`}
                inquiryReport={inquiryReport}
                hasGreyBackground={isEven(index)}
              />
            ))}
          </div>
        </div>
      ) : (
        <EmptyReportRecord />
      )}
    </div>
  );
};
