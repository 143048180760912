import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectedRenterDetails } from 'redux/selectors/agentSelector';

import { ReactComponent as EmailIcon } from 'assets/svgs/EmailGrey.svg';
import { ReactComponent as PhoneIcon } from 'assets/svgs/PhoneGrey.svg';
import { formatPhoneNumber } from 'helpers/renterHelper';
import { StripeDocument } from 'shared/types/renterTypes';

import { StripeDocumentDetails } from '../StripeDocumentDetails/StripeDocumentDetails';
// import Button from 'components/shared/Button/Button';
import { ContactDetails, SSNDetails } from '../';

import styles from '../RenterPersonalProfile.module.scss';

const RenterDetails = (): JSX.Element => {
  const renterDetails = useSelector(selectedRenterDetails);
  const { t } = useTranslation();

  if (!renterDetails.firstName) {
    return <div></div>;
  }

  return (
    <div className={styles.Container}>
      <div className={styles.RenterContainer}>
        <div className={styles.InfoContainer}>
          <div className={styles.DobContainer}>
            <span className={styles.Heading}>DOB</span>
            <span className={styles.Data}>
              {new Date(renterDetails.birthDate).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              })}
            </span>
          </div>
          <SSNDetails data={renterDetails?.ssnTailDigits ? String(renterDetails?.ssnTailDigits) : ''} />
          {renterDetails?.stripeDocument?.documentType && (
            <StripeDocumentDetails stripeDocument={renterDetails?.stripeDocument ?? ({} as StripeDocument)} />
          )}
        </div>
        <div className={styles.ContactContainer}>
          <div className={styles.ContactHeading}>
            <div>{t('agent.inviteApplicant.Contact')}</div>
            {/* <div>
              <Button variant="outline" className={styles.Button}>
                {t('application.message')}
              </Button>
            </div> */}
          </div>
          <ContactDetails heading={'Email'} data={renterDetails.email} icon={<EmailIcon />} shouldShowCopy />
          <ContactDetails
            heading={t('renter.Ownerinformation.phone')}
            data={
              renterDetails?.renterPhoneNumbers?.[0]?.phoneNumber
                ? formatPhoneNumber(renterDetails?.renterPhoneNumbers?.[0]?.phoneNumber)
                : '-'
            }
            icon={<PhoneIcon />}
            shouldShowCopy
          />
        </div>
      </div>
    </div>
  );
};

export default RenterDetails;
