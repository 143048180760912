import classNames from 'classnames';
import { t } from 'i18next';

import { ReactComponent as GreyDropDownArrow } from 'assets/svgs/GreyDropDownArrowIcon.svg';
import StateSelectField from 'components/shared/StateSelectField/StateSelectField';
import FormikField from 'components/shared/TextField/FormikTextField';
import { US_STATES } from 'shared/data/usStates';

import styles from '../VehiclesForm.module.scss';

const VehicleInformationInputFields = (): JSX.Element => (
  <div>
    <div className={styles.lisenceStateContainer}>
      <div className={classNames(styles.textFieldContainer, styles.lisenceFieldContainer)}>
        <span className={styles.textFieldLabel}>{t('renter.vehiclesInformation.lisencePlate')}</span>
        <FormikField name={'licensePlate'} placeholder={t('renter.vehiclesInformation.form.inputFields.placeholder')} />
      </div>
      <div className={classNames(styles.textFieldContainer, styles.stateFieldContainer)}>
        <span className={styles.textFieldLabel}>{t('renter.vehiclesInformation.state')}</span>
        <StateSelectField
          name={'stateIssued'}
          backgroundColor="#F7FAFA"
          statesArray={US_STATES}
          dropDownIcon={<GreyDropDownArrow />}
          placeholder={t('renter.vehiclesInformation.vehicleType.placeholder')}
        />
      </div>
    </div>
    <div className={styles.formikField}>
      <span className={styles.userLabel}>{t('renter.vehiclesInformation.make')}</span>
      <FormikField
        name="make"
        placeholder={t('renter.vehiclesInformation.form.inputFields.placeholder')}
        className={styles.inputBackgroundColor}
      />
    </div>
    <div className={styles.formikField}>
      <span className={styles.userLabel}>{t('renter.vehiclesInformation.model')}</span>
      <FormikField
        name="model"
        placeholder={t('renter.vehiclesInformation.form.inputFields.placeholder')}
        className={styles.inputBackgroundColor}
      />
    </div>
    <div className={styles.formikField}>
      <span className={styles.userLabel}>{t('renter.vehiclesInformation.year')}</span>
      <FormikField
        name="yearOfVehicle"
        placeholder={t('renter.vehiclesInformation.form.inputFields.placeholder')}
        className={styles.inputBackgroundColor}
        onWheel={(event) => event.currentTarget.blur()}
      />
    </div>
    <div className={styles.formikField}>
      <span className={styles.userLabel}>{t('renter.vehiclesInformation.color')}</span>
      <FormikField
        name="color"
        placeholder={t('renter.vehiclesInformation.form.inputFields.placeholder')}
        className={styles.inputBackgroundColor}
      />
    </div>
  </div>
);

export default VehicleInformationInputFields;
