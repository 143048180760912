import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { addProperty, updatePropertyDetails } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';
import { PropertyFormSchema } from 'schema/propertySchema';

import Description from 'components/Agent/Properties/AddProperty/Description';

import { PropertyEditRow } from './PropertyEditRow';
import { PropertySaveRow } from './PropertySaveRow';

import styles from '../PropertyDetails.module.scss';

type Props = {
  description: string;
  isEditable: boolean;
};

export const PropertyDescriptionForm = ({ description, isEditable }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { propertyId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isDescriptionEditable, setIsDescriptionEditable] = useState(isEditable);
  const activeOrganization = useSelector(selectedOrganization);

  return (
    <Formik
      initialValues={{ description, propertyId: Number(propertyId) }}
      validationSchema={PropertyFormSchema[3]}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        dispatch(addProperty({ values, organizationId: activeOrganization.id }))
          .unwrap()
          .then((updatedProperty) => {
            setIsDescriptionEditable(false);
            setSubmitting(false);
            dispatch(updatePropertyDetails(updatedProperty));
          });
      }}
    >
      {({ handleSubmit, isSubmitting, resetForm }) => (
        <Form>
          <PropertyEditRow
            title={t('agent.properties.property.description')}
            handleClick={() => setIsDescriptionEditable(true)}
            disabled={isDescriptionEditable}
          />
          {isDescriptionEditable ? (
            <>
              <Description isShowTitle={false} isReadOnly={!isDescriptionEditable} />

              <PropertySaveRow
                handleSaveClick={handleSubmit}
                handleCancelClick={() => {
                  resetForm();
                  setIsDescriptionEditable(false);
                }}
                isSubmitting={isSubmitting}
              />
            </>
          ) : (
            // eslint-disable-next-line @typescript-eslint/naming-convention
            <div className={styles.DescriptionContainer} dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </Form>
      )}
    </Formik>
  );
};
