import { Image, View } from '@react-pdf/renderer';

import CheckedBoxImage from 'assets/images/CheckBoxChecked.png';
import CheckBoxEmpty from 'assets/images/CheckBoxEmpty.png';

interface Props {
  isChecked: boolean;
}

const styles = {
  empty: { width: '15px', height: '15px' },
  checked: { width: '12', height: '12' },
};

export const PDFCheckBox = ({ isChecked }: Props): JSX.Element => (
  <View>
    {isChecked ? (
      <Image source={CheckedBoxImage} style={styles.checked} />
    ) : (
      <Image source={CheckBoxEmpty} style={styles.empty} />
    )}
  </View>
);
