import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { selectAgentProfileInformation, selectRenterApplication } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { selectPropertyAdditionalInfo } from 'redux/selectors/renterSelector';
import { setSelectedProperty } from 'redux/slices/agentSlice';
import { setIsPropertyLoading } from 'redux/slices/propertySlice';
import { getAdditionalApplicationInfo } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ApplicationHeader } from 'components/Agent/Applications/ApplicationsList/components/ApplicationHeader/ApplicationHeader';
import { PropertyHeader } from 'components/Agent/Applications/ApplicationsList/components/PropertyHeader/PropertyHeader';
import { PreviewFile } from 'components/shared/DropZone/Dropzone';
import { handleServerResponseError, parseResponseErrors } from 'helpers/helper';
import { getProperty } from 'services/propertyService';
import { Notification } from 'shared/Notification/Notification';
import { AddPropertyParams } from 'shared/types/propertyType';

import AgentHeading from './AgentHeading/AgentHeading';

import styles from './AgentDetailsForResume.module.scss';

export const AgentDetailsForResume = (): JSX.Element => {
  const { propertyId, applicationId } = useParams();
  const [propertyDetails, setPropertyDetails] = useState<AddPropertyParams>();
  const dispatch = useDispatch<AppThunkDispatch>();
  const activeOrganization = useSelector(selectedOrganization);
  const agentProfileInformation = useSelector(selectAgentProfileInformation);
  const application = useSelector(selectRenterApplication);
  const additionalInfo = useSelector(selectPropertyAdditionalInfo);

  useEffect(() => {
    if (!propertyId || propertyDetails) {
      return;
    }

    setIsPropertyLoading(true);
    getProperty({ propertyId: Number(propertyId), organizationId: activeOrganization.id })
      .then((res: AxiosResponse) => {
        setPropertyDetails(res.data.payload);
        dispatch(setSelectedProperty(res.data.payload));
      })
      .catch((error) => {
        handleServerResponseError({ error, dispatch });

        if (error instanceof AxiosError && error.response) {
          Notification({ message: parseResponseErrors(error) });

          return Promise.reject();
        }
      })
      .finally(() => setIsPropertyLoading(false));
  }, [activeOrganization.id, dispatch, propertyDetails, propertyId]);

  useEffect(() => {
    if (!propertyId) {
      return;
    }

    dispatch(
      getAdditionalApplicationInfo({
        propertyId: Number(propertyId),
        applicationId: Number(applicationId),
        organizationId: activeOrganization.id,
      })
    );
  }, [activeOrganization.id, applicationId, dispatch, propertyId]);

  return (
    <div className={styles.container}>
      <AgentHeading />
      {propertyDetails && (
        <PropertyHeader
          streetAddress1={propertyDetails.streetAddress1?.split(',')[0] ?? ''}
          streetAddress2={`${propertyDetails.city} ${propertyDetails.state} ${propertyDetails.zipCode}`}
          availableDate={new Date().toDateString() ?? propertyDetails.dateAvailable}
          beds={Number(propertyDetails.bedroomsCount)}
          baths={Number(propertyDetails.bathroomsCount)}
          deposit={propertyDetails.depositAmount}
          rent={propertyDetails.monthlyRentAmount}
          ownerName={`${agentProfileInformation.firstName} ${agentProfileInformation.lastName}`}
          ownerOrganization={activeOrganization.name ?? ''}
          propertyType={propertyDetails.propertyType ?? ''}
          sqFtSize={propertyDetails.squareFootage ?? 0}
          totalActive={0}
          totalUnits={propertyDetails.totalUnits ?? ''}
          totalHouseHolds={0}
          isShowDropdown={false}
          agentName={`${agentProfileInformation.firstName} ${agentProfileInformation.lastName}`}
          isCustomAddress={propertyDetails.isCustomAddress}
          propertyImages={propertyDetails.propertyImages as PreviewFile[]}
        />
      )}
      {propertyDetails && application.id && (
        <ApplicationHeader
          moveInDate={additionalInfo?.desiredMoveInDate}
          propertyRent={propertyDetails?.monthlyRentAmount}
          activeDate={application?.fileApplication?.updatedAt}
          minorCount={application?.minorsCount}
          minorAddedByText={''}
          occupantCount={(Number(additionalInfo?.occupantsCount) || 0) + 1}
          petCount={Number(additionalInfo?.animalsCount)}
          petAddedByText={''}
          budgetAmount={Number(additionalInfo?.groupIncome)}
          isShowActive={false}
        />
      )}
    </div>
  );
};
