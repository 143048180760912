import { AxiosResponse } from 'axios';

import { PreviewFile } from 'components/shared/DropZone/Dropzone';
import { encryptData } from 'helpers/encrypt';
import { ApplicationTypeFlag, JSCPayloadType } from 'shared/types/applicantsType';
import {
  AdultInviteDetailsResponse,
  GuarantorInviteDetailsResponse,
  MessageResponse,
  SecurityAnswer,
} from 'shared/types/authType';
import { AxiosPromise } from 'shared/types/axiosPromise';
import {
  AgentDetailsResponse,
  ApplicationCreationBodyParams,
  PropertiesOfAcceptedInvite,
  RenterAcceptedInvitationsResponse,
} from 'shared/types/renterApplication';
import { ApplicationPropertyLinksResponse, SendAdultCooccupantsInviteParams } from 'shared/types/renterCooccupants';
import {
  EmploymentDocumentUploadApi,
  PresentEmploymentDocumentDelete,
  PresentEmploymentDocumentUpload,
} from 'shared/types/renterIncomeInformation';
import {
  AddressInformation,
  AnimalInformationProps,
  BackgroundInformation,
  BackgroundQuestionnaireResponse,
  CreateCustomAddressApiPayloadProps,
  FurryFriendDeleteApi,
  FurryFriendUploadDocumentApi,
  FurryFriendUploadImageProps,
  FurryFriendUploadProps,
  GuarantorDispatchProps,
  GuarantorDispatchResponse,
  GuarantorInviteParams,
  PhoneNumber,
  RenterAddressInformationProps,
  RenterAdultProps,
  RenterApplicationProps,
  RenterCustomAddressRequestType,
  RenterMinorProps,
  RenterPresentEmploymentProps,
  RenterPresentEmployments,
  RenterProfileProps,
  ReorderImagesProps,
  StripePaymentResponse,
  SubmitVerifierResponseType,
  UpdateBackgroundInfo,
  UserDetailsOnVerificationPageResponse,
  VerificationQuestionnaireResponse,
  VerifierDetailsOnVerification,
} from 'shared/types/renterTypes';
import { RenterVehicleProps, VehiclesInformationType } from 'shared/types/renterVehicleInformation';

import baseService from './baseService';

export const renterServiceRoutes = {
  acceptAdultInvite: '/renter/accept-adult-invite',
  acceptGuarantorInvite: '/renter/accept-guarantor-invite',
  getSelectedProperty: (id: number) => `/property/properties/${id}`,
  confirmRenterApplication: (applicationId: number) => `/renter/applications/${applicationId}/confirm`,
  updateReportConsent: (applicationId: number) => `/renter/applications/${applicationId}/consent`,
  getRenterExperianReport: () => '/renter/report',
  getRenterNovaReport: () => '/renter/nova-report',
};

export const createProfile = (values: RenterProfileProps): AxiosPromise => {
  if (values.socialSecurityNumber) {
    values.socialSecurityNumber = encryptData(String(values.socialSecurityNumber));
  }

  return baseService.post<AxiosResponse<RenterProfileProps>>('/renter/create-renter-profile', values);
};

export const updateProfile = (values: RenterProfileProps): AxiosPromise => {
  if (values.socialSecurityNumber) {
    values.socialSecurityNumber = encryptData(String(values.socialSecurityNumber));
  }

  return baseService.put<AxiosResponse<RenterProfileProps>>('/renter/update-renter-profile', values);
};
export const getRenterProfileInformation = (): AxiosPromise =>
  baseService.get<AxiosResponse<RenterProfileProps>>('renter/view-renter-profile');

export const createPresentEmployment = (values: RenterPresentEmployments): AxiosPromise =>
  baseService.post<AxiosResponse<RenterPresentEmploymentProps>>('/renter/present-employments', values);

export const updatePresentEmployment = (values: RenterPresentEmployments, id: number | string): AxiosPromise =>
  baseService.put<AxiosResponse<RenterPresentEmploymentProps>>(`/renter/present-employments/${id}`, values);

export const createPriorEmployment = (values: RenterPresentEmployments): AxiosPromise =>
  baseService.post<AxiosResponse<RenterPresentEmploymentProps>>('/renter/prior-employments', values);

export const updatePriorEmployment = (values: RenterPresentEmployments, id: number | string): AxiosPromise =>
  baseService.put<AxiosResponse<RenterPresentEmploymentProps>>(`/renter/prior-employments/${id}`, values);

export const createRenterPhoneNumber = (values: PhoneNumber): AxiosPromise =>
  baseService.post<AxiosResponse<PhoneNumber>>('/renter/phone-numbers', values);

export const updateRenterPhoneNumber = (values: PhoneNumber): AxiosPromise =>
  baseService.put<AxiosResponse<PhoneNumber>>(`/renter/phone-numbers/${values.id}`, values);

export const deleteRenterPhoneNumber = (id: number): AxiosPromise =>
  baseService.delete<AxiosResponse<PhoneNumber>>(`/renter/phone-numbers/${id}`);

export const createRenterAddress = (address: RenterAddressInformationProps): AxiosPromise =>
  baseService.post<AxiosResponse<AddressInformation>>('renter/addresses', address);

export const updateRenterAddress = (id: string | number, address: RenterAddressInformationProps): AxiosPromise =>
  baseService.put<AxiosResponse<AddressInformation>>(`renter/addresses/${id}`, address);

export const deleteRenterAddress = (id: string | number): AxiosPromise =>
  baseService.delete<AxiosResponse<AddressInformation>>(`renter/addresses/${id}`);

export const createAdult = (applicationId: number, values: RenterAdultProps): AxiosPromise =>
  baseService.post<AxiosResponse<RenterAdultProps>>(`renter/applications/${applicationId}/adults`, values);

export const updateAdult = (
  applicationId: number,
  adultId: number | undefined,
  values: RenterAdultProps
): AxiosPromise =>
  baseService.put<AxiosResponse<RenterAdultProps>>(`/renter/applications/${applicationId}/adults/${adultId}`, values);

export const createMinor = (applicationId: number, values: RenterMinorProps): AxiosPromise =>
  baseService.post<AxiosResponse<RenterMinorProps>>(`/renter/applications/${applicationId}/minors`, values);

export const updateMinor = (
  applicationId: number,
  minorId: number | undefined,
  values: RenterMinorProps
): AxiosPromise =>
  baseService.put<AxiosResponse<RenterMinorProps>>(`/renter/applications/${applicationId}/minors/${minorId}`, values);

export const createVehicle = (values: VehiclesInformationType): AxiosPromise =>
  baseService.post<AxiosResponse<RenterVehicleProps>>('/renter/vehicles', values);

export const updateVehicle = (values: VehiclesInformationType): AxiosPromise =>
  baseService.put<AxiosResponse<RenterVehicleProps>>(`/renter/vehicles/${values.id}`, values);
export const createFurryFriends = (values: AnimalInformationProps): AxiosPromise =>
  baseService.post<AxiosResponse<AnimalInformationProps>>('renter/furry-friends', values);
export const updateFurryFriends = (id: number | undefined, values: AnimalInformationProps): AxiosPromise =>
  baseService.put<AxiosResponse<AnimalInformationProps>>(`renter/furry-friends/${id}`, values);

export const createRenterPayment = ({
  amount,
  propertyAddress,
  organizationName,
  applicationId,
}: {
  amount: number;
  propertyAddress: string;
  organizationName: string;
  applicationId: number;
}): AxiosPromise =>
  baseService.post<AxiosResponse<StripePaymentResponse>>(`/stripe/payment/stripe-session?amount=${amount}`, {
    propertyAddress,
    organizationName,
    applicationId,
  });

export const presentEmploymentDocumentUpload = ({
  document,
  presentEmploymentId,
  documentType,
  documentSubType,
}: PresentEmploymentDocumentUpload): AxiosPromise =>
  baseService.post<AxiosResponse<EmploymentDocumentUploadApi>>(
    `/renter/present-employments/${presentEmploymentId}/documents`,
    { document, documentType, documentSubType },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const uploadVisaDocuments = ({ document }: { document: PreviewFile }): AxiosPromise =>
  baseService.post<AxiosResponse<EmploymentDocumentUploadApi>>(
    `renter/visa-document`,
    { document },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const deleteVisaDocuments = (id: number): AxiosPromise =>
  baseService.delete<AxiosResponse<EmploymentDocumentUploadApi>>(`renter/visa-document/${id}`);

export const deletePresentEmploymentDocument = ({
  documentId,
  presentEmploymentId,
}: PresentEmploymentDocumentDelete): AxiosPromise =>
  baseService.delete<AxiosResponse<EmploymentDocumentUploadApi>>(
    `/renter/present-employments/${presentEmploymentId}/documents/${documentId}`
  );
export const createRenterGuarantor = (applicationId: number, values: GuarantorDispatchProps): AxiosPromise =>
  baseService.post<AxiosResponse<GuarantorDispatchResponse>>(`/renter/applications/${applicationId}/guarantor`, values);
export const updateRenterGuarantor = (
  id: number | string,
  applicationId: number,
  values: GuarantorDispatchProps
): AxiosPromise =>
  baseService.put<AxiosResponse<GuarantorDispatchResponse>>(
    `/renter/applications/${applicationId}/guarantor/${id}`,
    values
  );
export const furryFriendImagesUpload = ({ furryFriendId, document: image }: FurryFriendUploadProps): AxiosPromise =>
  baseService.post<AxiosResponse<FurryFriendUploadDocumentApi>>(
    `/renter/furry-friends/${furryFriendId}/images`,
    { image },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const deleteFurryFriendImage = ({ furryFriendId, documentId }: FurryFriendDeleteApi): AxiosPromise =>
  baseService.delete<AxiosResponse<FurryFriendDeleteApi>>(
    `/renter/furry-friends/${furryFriendId}/images/${documentId}`
  );
export const furryFriendDocumentUpload = ({ furryFriendId, document }: FurryFriendUploadProps): AxiosPromise =>
  baseService.post<AxiosResponse<FurryFriendUploadDocumentApi>>(
    `/renter/furry-friends/${furryFriendId}/documents`,
    { document },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
    }
  );
export const deleteFurryFriendDocument = ({ furryFriendId, documentId }: FurryFriendDeleteApi): AxiosPromise =>
  baseService.delete<AxiosResponse<FurryFriendDeleteApi>>(
    `/renter/furry-friends/${furryFriendId}/documents/${documentId}`
  );
export const deleteFurryFriend = ({ furryFriendId }: { furryFriendId: number }): AxiosPromise =>
  baseService.delete<AxiosResponse<AnimalInformationProps>>(`renter/furry-friends/${furryFriendId}`);
export const renterReorderAnimalImages = ({ furryFriendId, imageIds }: ReorderImagesProps): AxiosPromise =>
  baseService.put<AxiosResponse<FurryFriendUploadImageProps>>(`/renter/furry-friends/${furryFriendId}/images/reorder`, {
    imageIds,
  });

export const getApplicationPropertyLinks = (id: number): AxiosPromise =>
  baseService.get<AxiosResponse<ApplicationPropertyLinksResponse>>(`/renter/applications/${id}`);

export const getAddressHistoryQuestionnaire = (token: string): AxiosPromise =>
  baseService.get<AxiosResponse<VerificationQuestionnaireResponse>>(`renter/verifiers/${token}/questions`);
export const getUserDetailsOnVerification = (token: string): AxiosPromise =>
  baseService.get<AxiosResponse<UserDetailsOnVerificationPageResponse>>(
    `/renter/verifiers/${token}/rental-information`
  );
export const updateVerifierDetailsVerification = (token: string, values: VerifierDetailsOnVerification): AxiosPromise =>
  baseService.post<AxiosResponse>(`/renter/verifiers/${token}/reject-ownership`, values);
export const getBackgroundQuestionnaire = (): AxiosPromise =>
  baseService.get<AxiosResponse<BackgroundQuestionnaireResponse>>(`renter/background-questions`);

export const addBackgroundInformation = (
  applicationId: number,
  submitBackgroundInformation: BackgroundInformation[]
): AxiosPromise =>
  baseService.post<AxiosResponse<string>>(`renter/applications/${applicationId}/background-information`, {
    submitBackgroundInformation,
  });

export const updateBackgroundInformation = (
  applicationId: number,
  backgroundInfoId: number,
  backgroundInfo: UpdateBackgroundInfo
): AxiosPromise =>
  baseService.put<AxiosResponse<string>>(
    `renter/applications/${applicationId}/background-information/${backgroundInfoId}`,
    backgroundInfo
  );

export const createVerificationInformation = (
  token: string,
  values: SubmitVerifierResponseType,
  acceptedFiles: PreviewFile[]
): AxiosPromise =>
  baseService.post<AxiosResponse<string>>(
    `renter/verification/${token}/responses`,
    { document: acceptedFiles, request: JSON.stringify(values) },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const sendAdultCooccupantsInvite = ({
  applicationId,
  propertyLinkId,
  adultId,
  type,
}: SendAdultCooccupantsInviteParams): AxiosPromise =>
  baseService.post<AxiosResponse<MessageResponse>>(
    `renter/applications/${applicationId}/properties/${propertyLinkId}/adults/${adultId}/invite?type=${type}`
  );

export const renterGuarantorInvite = ({
  applicationId,
  propertyId,
  inviteType,
  guarantorId,
}: GuarantorInviteParams): AxiosPromise =>
  baseService.post<AxiosResponse>(
    `/renter/applications/${applicationId}/properties/${propertyId}/guarantor/${guarantorId}/invite?type=${inviteType}`
  );

export const getAcceptedInvitations = (): AxiosPromise =>
  baseService.get<AxiosResponse<RenterAcceptedInvitationsResponse>>('/renter/accepted-invitations');

export const getPropertyAgainstAcceptedInvitation = (inviteCode: string): AxiosPromise =>
  baseService.get<AxiosResponse<PropertiesOfAcceptedInvite>>(`/renter/accepted-invitations/${inviteCode}/properties`);

export const signTermsOfUse = (): AxiosPromise => baseService.put<AxiosResponse>('/renter/sign-terms-of-use');

export const createApplicationPropertyLink = (applicationData: ApplicationCreationBodyParams): AxiosPromise =>
  baseService.post('/renter/application-property-link', { ...applicationData });
export const acceptRenterInvite = (url: string, inviteCode: string): AxiosPromise =>
  baseService.post<AxiosResponse>(url, { inviteCode });
export const createCustomAddress = (values: RenterCustomAddressRequestType): AxiosPromise =>
  baseService.post<AxiosResponse<CreateCustomAddressApiPayloadProps>>('/renter/custom-addresses', values);

export const getSelectedProperty = (propertyId: number): AxiosPromise =>
  baseService.get(renterServiceRoutes.getSelectedProperty(propertyId));

export const confirmRenterApplication = (applicationId: number, body: JSCPayloadType): AxiosPromise =>
  baseService.put(renterServiceRoutes.confirmRenterApplication(applicationId), body);

export const getAgentDetails = (applicationId?: number, inviteCode?: string): AxiosPromise =>
  baseService.get<AxiosResponse<AgentDetailsResponse>>(
    `renter/agent-details?applicationId=${applicationId ?? ''}&inviteCode=${inviteCode ?? ''}`
  );

export const getAdditionalApplicationInfo = (
  organizationId: number,
  propertyId: number,
  applicationId: number
): AxiosPromise =>
  baseService.get<AxiosResponse<AgentDetailsResponse>>(
    `renter/organizations/${organizationId}/properties/${propertyId}/applications/${applicationId}`
  );
export const submitRenterSecurityQuestion = (values: SecurityAnswer, applicationId?: number): AxiosPromise =>
  baseService.post<AxiosResponse<MessageResponse>>(`/renter/applications/${applicationId}/answers`, values);
export const submitRenterOtp = (code: string, applicationId: number): AxiosPromise =>
  baseService.post<AxiosResponse>(`/renter/applications/${applicationId}/otp`, { code });

export const updateRenterReportConsent = (applicationId: number, isConsented: boolean): AxiosPromise =>
  baseService.put(renterServiceRoutes.updateReportConsent(applicationId), isConsented);

export const getRenterExperianReport = (): AxiosPromise =>
  baseService.get(renterServiceRoutes.getRenterExperianReport());

export const getRenterNovaReport = (): AxiosPromise => baseService.get(renterServiceRoutes.getRenterNovaReport());

export const submitVerificationCode = (verificationCode: string, token: string): AxiosPromise =>
  baseService.post<AxiosResponse>('/renter/verifiers/verify', { token: token, accessCode: verificationCode });
//To Resend Experian OTP
export const resendRenterOtp = (): AxiosPromise => baseService.get<AxiosResponse<MessageResponse>>('/renter/otp');
export const updateApplication = (values: ApplicationTypeFlag, applicationId?: number): AxiosPromise =>
  baseService.put<AxiosResponse<RenterApplicationProps>>(`renter/applications/${applicationId}`, values);

export const getOrganizationName = (propertyId: number): AxiosPromise =>
  baseService.get<AxiosResponse<{ organizationName: string }>>(`/property/properties/${propertyId}/organization-name`);

export const getAdultInviteDetails = (inviteCode: string): AxiosPromise =>
  baseService.get<AxiosResponse<AdultInviteDetailsResponse>>(`/renter/adult-invite?inviteCode=${inviteCode}`);

export const getGuarantorInviteDetails = (inviteCode: string): AxiosPromise =>
  baseService.get<AxiosResponse<GuarantorInviteDetailsResponse>>(`/renter/guarantor-invite?inviteCode=${inviteCode}`);

export const getIsValidForPayment = (): AxiosPromise =>
  baseService.get<AxiosResponse<GuarantorInviteDetailsResponse>>(`/renter/validation`);
