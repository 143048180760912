// import { useSelector } from 'react-redux';
// import { applicantRenterRole } from 'redux/selectors/renterSelector';

// import { renterRestrictions } from 'constants/restrictionConstants';
import { NextRouteProp } from 'shared/types/renterTypes';

import { PresentIncome } from './components/index';

const SourceOfIncome = ({ next }: NextRouteProp): JSX.Element => (
  // const renterRole = useSelector(applicantRenterRole);

  <div>
    <PresentIncome next={next} />
    {/* {!renterRestrictions[renterRole].priorAddress && <PriorIncome next={next} />} */}
  </div>
);

export default SourceOfIncome;
