import { Dispatch, SetStateAction, useState } from 'react';
import { t } from 'i18next';

import { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import { RenterPresentEmployments } from 'shared/types/renterTypes';
export type DocumentTypesInformation = {
  [key: string]: {
    primaryDocument: string;
  };
};
export type RenterIncomeDocumentTypeButtons = {
  customOnClick: (documentType: string, uploaded?: boolean) => void;
  employmentType: string;
  documentState: Record<string, boolean>;
};
export type RenterIncomeDocumentType = {
  employmentType: string;
};

export interface EmploymentDocument {
  fileLink: string;
  fileName: string;
  fileType: string;
  height: number;
  id: number;
  position: number;
  size: number;
  width: number;
  documentType: string;
  documentSubType: string;
}

export interface EmploymentDocumentUploadApi {
  presentEmploymentId: number;
  documents: EmploymentDocument[];
}

export interface PresentEmploymentDocumentUpload {
  document: PreviewFile;
  presentEmploymentId: number;
  documentType?: string;
  documentSubType?: string;
  onUploadDocument?: (file: EmploymentDocumentUploadApi) => void;
}

export interface PresentEmploymentDocumentDelete {
  documentId: number;
  presentEmploymentId: number;
}

export interface DocumentTypesProps {
  data: RenterPresentEmployments;
  showModal: Dispatch<SetStateAction<boolean>>;
}
export interface EmploymentDocumentUploadProps {
  customOnClick: (documentType: string, uploaded?: boolean) => void;
}
export const initialDocumentState: Record<string, boolean> = {};

export const employedDocTypes = {
  primaryDocument: 'Current pay stubs (last 2 months)',
};
export const selfEmployedDocTypes = {
  primaryDocument: 'Tax Returns (last 2 years)',
};
export const otherEmployedDocTypes = {
  primaryDocument: 'Bank Statements (Last 3 months)',
};

export const incomeDocumentUploadText = {
  [t('renter.incomeInformation.employed.currentPayStubs')]: t(
    'renter.incomeInformation.employed.documentText.currentPayStubs'
  ),
  [t('renter.incomeInformation.employed.bankStatements')]: t(
    'renter.incomeInformation.employed.documentText.bankStatements'
  ),
  [t('renter.incomeInformation.employed.officialOfferLetter')]: t(
    'renter.incomeInformation.employed.documentText.officialOfferLetter'
  ),

  [t('renter.incomeInformation.selfEmployed.1099s')]: t('renter.incomeInformation.selfEmployed.documentText.1099s'),
  [t('renter.incomeInformation.selfEmployed.bankStatements')]: t(
    'renter.incomeInformation.selfEmployed.documentText.bankStatements'
  ),
  [t('renter.incomeInformation.selfEmployed.incomeTaxReturns')]: t(
    'renter.incomeInformation.selfEmployed.documentText.incomeTaxReturns'
  ),
  [t('renter.incomeInformation.selfEmployed.other')]: t('renter.incomeInformation.selfEmployed.documentText.other'),
};

export const useAcceptedFiles = (): {
  acceptedFiles: PreviewFile[];
  setAcceptedFiles: Dispatch<SetStateAction<PreviewFile[]>>;
} => {
  const [acceptedFiles, setAcceptedFiles] = useState<PreviewFile[]>([]);

  return { acceptedFiles, setAcceptedFiles };
};
export const documentTypesInformation: DocumentTypesInformation = {
  currentEmployed: employedDocTypes,
  selfEmployed: selfEmployedDocTypes,
  others: otherEmployedDocTypes,
};

export enum EmploymentType {
  SELF_EMPLOYED = 'selfEmployed',
  EMPLOYED = 'currentEmployed',
  OTHERS = 'others',
}

export enum IncomeDocumentTypes {
  OTHER = 'other',
}

export enum FileTypes {
  PDF = 'pdf',
  HEIC = 'heic',
  JPEG = 'jpeg',
}
