import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';
import { dummyDate } from 'constants/renterConstants';
import { formatDateForVerifierPage } from 'helpers/renterHelper';

export const RequestVerificationDate: FC<{ requestedAt?: string }> = ({ requestedAt }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <span>
      <span className={styles.StatusContainer}>{t('rental.historyInformation.requested')}</span>
      <span className={styles.RequestDetails}>{requestedAt ? formatDateForVerifierPage(requestedAt) : dummyDate}</span>
    </span>
  );
};
