import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import { IncomeReportModal } from 'components/Agent/Applications/ApplicationDetails/components/RenterIncomeReports/IncomeReportModal/IncomeReportModal';
import {
  calculateTotalAnimals,
  calculateTotalMinors,
  flattenApplicationList,
  getAnimalsAddedByText,
  getMinorAddedByText,
  sortApplicationsByRole,
} from 'helpers/applicantsHelper';
import { IsMobileApplicantsPage } from 'helpers/IsMobile';
import { routes } from 'shared/routes';
import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { ApplicationViewProps } from 'shared/types/applicantsType';
import { RenterRoles } from 'shared/types/renterTypes';

import { ApplicantInformation } from '../ApplicantInformation/ApplicantInformation';
import { ApplicationHeader } from '../ApplicationHeader/ApplicationHeader';

import styles from './ApplicationView.module.scss';

export const ApplicationView = ({
  application,
  desiredMoveInDate,
  propertyId,
  propertyRent,
  isCustomAddress,
  propertyAddress,
}: ApplicationViewProps): JSX.Element => {
  let occupantCount = 0;
  const flattenedApplicationsList = flattenApplicationList([application]);
  const isMobileView = IsMobileApplicantsPage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [incomeRenterId, setIncomeRenterId] = useState(-1);
  const [selectedApplicationId, setSelectedApplicationId] = useState(-1);
  const [selectedApplicationStatus, setSelectedApplicationStatus] = useState<PropertyApplicationStatus>(
    PropertyApplicationStatus.NOT_STARTED
  );
  const selectedOrganizationInformation = useSelector(selectedOrganization);
  const navigate = useNavigate();
  const onRenterResumeSelect = (): void => {
    navigate(routes.generateRenterResume(Number(application.id), propertyId));
  };
  const getApplicationStatus = (confirmedFlag: boolean): PropertyApplicationStatus =>
    confirmedFlag ? PropertyApplicationStatus.COMPLETED : PropertyApplicationStatus.IN_PROGRESS;
  const onIncomeClickHandler = (renterId: number, status: PropertyApplicationStatus, applicationId: number): void => {
    setIsModalOpen(true);
    setIncomeRenterId(renterId);
    setSelectedApplicationId(applicationId);
    setSelectedApplicationStatus(status);
  };
  const totalOccupants = useMemo(
    () => (application?.adultsCount ?? 0) + (application?.minorsCount ?? 0),
    [application?.adultsCount, application?.minorsCount]
  );

  return (
    <div className={styles.Container}>
      <ApplicationHeader
        moveInDate={desiredMoveInDate}
        propertyRent={propertyRent}
        activeDate={application?.fileApplication?.updatedAt as string}
        minorCount={calculateTotalMinors(flattenedApplicationsList)}
        minorAddedByText={getMinorAddedByText(flattenedApplicationsList)}
        occupantCount={(application?.adultsCount ?? 0) + (application.minorsCount ?? 0)}
        petCount={calculateTotalAnimals(flattenedApplicationsList)}
        petAddedByText={getAnimalsAddedByText(flattenedApplicationsList)}
        budgetAmount={application.groupIncome}
        onRenterResumeSelect={onRenterResumeSelect}
        applicationFileStatus={application?.fileApplication?.status}
        applicationId={application?.id}
        propertyId={propertyId}
      />
      {application &&
        sortApplicationsByRole(flattenedApplicationsList).map((applicationItem, index) => {
          if (applicationItem.renterRole === RenterRoles.OCCUPANTS) {
            occupantCount = occupantCount + 1;
          }

          return (
            <React.Fragment key={applicationItem.id}>
              {applicationItem.renterRole !== RenterRoles.GUARANTOR && (
                <>
                  <ApplicantInformation
                    onIncomeClickHandler={onIncomeClickHandler}
                    applicationInformation={applicationItem}
                    isLast={index === flattenApplicationList([application]).length - 1}
                    occupantNumber={occupantCount}
                    applicationStatus={getApplicationStatus(applicationItem.isConfirmed)}
                    key={`Application-${index}-${applicationItem.id}`}
                    propertyId={propertyId}
                  />
                  {!!applicationItem.associatedGuarantorApplication && (
                    <div
                      className={styles.AssociatedApplication}
                      key={`Application-${index}-${applicationItem.associatedGuarantorApplication.id}`}
                    >
                      {!isMobileView && <div className={styles.VerticalLine} />}
                      <div className={styles.Application}>
                        <ApplicantInformation
                          applicationStatus={getApplicationStatus(
                            applicationItem.associatedGuarantorApplication.isConfirmed
                          )}
                          applicationInformation={applicationItem.associatedGuarantorApplication}
                          propertyId={propertyId}
                          onIncomeClickHandler={onIncomeClickHandler}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {applicationItem.renterRole === RenterRoles.GUARANTOR && (
                <div className={styles.AssociatedApplication} key={`Application-${index}-${applicationItem.id}`}>
                  {!isMobileView && <div className={styles.VerticalLine} />}
                  <div className={styles.Application}>
                    <ApplicantInformation
                      applicationStatus={getApplicationStatus(applicationItem.isConfirmed)}
                      applicationInformation={applicationItem}
                      propertyId={propertyId}
                      onIncomeClickHandler={onIncomeClickHandler}
                    />
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      {isModalOpen && (
        <IncomeReportModal
          applicationId={selectedApplicationId}
          renterId={incomeRenterId}
          organizationId={selectedOrganizationInformation.id}
          setIsModalOpen={setIsModalOpen}
          propertyRent={propertyRent}
          isCustomProperty={isCustomAddress}
          totalOccpants={totalOccupants}
          propertyAddress={propertyAddress || ''}
          status={selectedApplicationStatus}
        />
      )}
    </div>
  );
};
