import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from 'assets/svgs/CalenderIconGrey.svg';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import { formatDateToShortFormat } from 'helpers/applicantsHelper';

import styles from './MoveInDateCapsule.module.scss';
export const MoveInDateCapsule = ({ moveInDate }: { moveInDate: string }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.CapsuleContainer}>
      <div className={styles.MoveInDate}>{formatDateToShortFormat(new Date(moveInDate).toDateString())}</div>
      <div className={styles.LabelContainer}>
        <CalendarIcon />
        <div className={styles.CapsuleLabel}>{t('application.propertyHeader.moveInDate')}</div>
        {new Date().toLocaleDateString() === new Date(moveInDate).toLocaleDateString() && (
          <AgentPills
            pillContainerClassName={styles.WarningContainer}
            pillsData={[{ heading: t('application.today'), type: PillTypes.DANGER_LIGHT }]}
          />
        )}
      </div>
    </div>
  );
};
