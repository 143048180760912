import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { isAgentLoading } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import { ReactComponent as LinkIcon } from 'assets/svgs/ArrowInBoxIcon.svg';
import TextCopyButton from 'components/Agent/components/shared/TextCopyButton/TextCopyButton';
import { publicRoutes } from 'shared/routes';

import styles from './ShareLink.module.scss';

type ShareLinkProps = {
  inviteLink: string;
  isIDVLink?: boolean;
};

const ShareLink = ({ inviteLink, isIDVLink = false }: ShareLinkProps): JSX.Element => {
  const isLoading = useSelector(isAgentLoading);
  const currentOrganization = useSelector(selectedOrganization);

  return (
    <div className={styles.container}>
      <div className={styles.HeadingContainer}>
        <div className={styles.heading}>{t('agent.inviteApplicant.shareLink')}</div>
        {isIDVLink && (
          <Link
            to={publicRoutes.generateIdentityVerification(currentOrganization.id)}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.IDVButton}
          >
            <LinkIcon /> {t('agent.inviteApplicant.openIdv')}
          </Link>
        )}
      </div>
      <div className={styles.subHeading}>{t('agent.inviteApplicant.copyLink')}</div>
      <TextCopyButton text={inviteLink} isLoading={isLoading} buttonText="" />
    </div>
  );
};

export default ShareLink;
