import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';

import { NavigationKeys, RenterProfileSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { formatSSN } from 'helpers/renterHelper';
import { renterRoutes } from 'shared/routes';
import { RenterInformationEditProps, RenterProfileInformationProps } from 'shared/types/renterTypes';

import RenterIconInfoContainer from './RenterIconInfoContainer';
import RenterSsnInputDetails from './RenterSSNInputDetails';

import styles from './RenterSSN.module.scss';

const RenterSSN = ({ handleNext }: RenterInformationEditProps): JSX.Element => {
  const { applicationId } = useParams();
  const { ssnTailDigits } = useSelector(selectRenterProfileInformation);
  const { setErrors } = useFormikContext<RenterProfileInformationProps>();
  const { t } = useTranslation();
  const [ssn, setSSN] = useState(formatSSN(ssnTailDigits?.toString()) || '');
  const [isDirty, setIsDirty] = useState(false);
  const navigate = useNavigate();
  const renterRole = useSelector(applicantRenterRole);
  const [searchParams] = useSearchParams();
  const navigateNext = searchParams.get(NavigationKeys.NEXT);

  useEffect(() => {
    setSSN(formatSSN(ssnTailDigits?.toString(), true) || '');
  }, [ssnTailDigits]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setSSN(formatSSN(event.target.value));
  }, []);
  const handleNavigation = useCallback(() => {
    if (navigateNext) {
      navigate(navigateNext);
    } else {
      const defaultRoute = renterRestrictions[renterRole].guarantor
        ? renterRoutes.renterRelation.replace(':applicationId', `${applicationId}`)
        : renterRoutes.generatePersonalInformationUrl(RenterProfileSteps.PROFILESUMMARY, Number(applicationId));

      navigate(defaultRoute);
    }
  }, [applicationId, navigate, navigateNext, renterRole]);
  const onCustomClick = useCallback(() => {
    if (handleNext && isDirty) {
      handleNext({
        params: {
          ssnTailDigits: parseInt(ssn?.replaceAll('-', '')),
          hasNoSSN: false,
        },
        nextStep: '',
        setErrors,
      });
    }

    handleNavigation();
  }, [handleNext, isDirty, handleNavigation, ssn, setErrors]);

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <RenterIconInfoContainer />
        {t('renter.ssn.questionLabel')}
      </div>
      <RenterSsnInputDetails
        ssn={ssn}
        setIsDirty={setIsDirty}
        handleChange={handleChange}
        updateSSNNumber={onCustomClick}
      />
    </div>
  );
};

export default RenterSSN;
