import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import RCButton from 'components/shared/Button/Button';
import Spinner from 'components/shared/Spinner/Spinner';

import styles from '../PropertyDetails.module.scss';

type Props = {
  handleSaveClick: () => void;
  handleCancelClick: () => void;
  isSubmitting: boolean;
  isValid?: boolean;
};

export const PropertySaveRow = ({
  handleSaveClick,
  handleCancelClick,
  isSubmitting,
  isValid = true,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.PropertySpecsEditRow}>
      <div className={styles.ButtonsRow}>
        <RCButton className={styles.EditButton} variant="outline" onClick={handleCancelClick}>
          {t('application.cancel')}
        </RCButton>
        <RCButton
          className={classNames(styles.EditButton, styles.SaveButton)}
          variant="outline"
          onClick={handleSaveClick}
          disabled={isSubmitting || !isValid}
        >
          {t('application.save')}
          {isSubmitting && <Spinner className={styles.Spinner} />}
        </RCButton>
      </div>
    </div>
  );
};
