import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Form, Formik, FormikState } from 'formik';
import { t } from 'i18next';
import { capitalize } from 'lodash-es';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addMinorsToApplication } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterMinorSchema } from 'schema/renterSchema';

import RCButton from 'components/shared/Button/Button';
import CustomDatePicker from 'components/shared/CustomDatePicker/CustomDatePicker';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikField from 'components/shared/TextField/FormikTextField';
import { dateFormatBackend } from 'constants/calendarConstants';
import { NavigationKeys } from 'constants/renterConstants';
import {
  defaultDate,
  endDate,
  RenterCoOccupantButtonType,
  RenterOccupantType,
  startDate,
} from 'constants/renterConstants';
import { IsMobile } from 'helpers/IsMobile';
import { splitFullName } from 'helpers/user';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { MinorDispatchProps, RenterMinorInitialValues, RenterMinorProps } from 'shared/types/renterTypes';

import RenterContainer from '../../RenterContainer';
import RenterIconInfoContainer from '../../RenterIconInfoContainer';

import styles from '../../../RenterIndex.module.scss';
import adultStyles from '..//RenterAdult/RenterAdult.module.scss';

const MinorPage = (): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const [searchParams] = useSearchParams();
  const navigateNext = searchParams.get(NavigationKeys.NEXT);
  const navigateSection = searchParams.get(NavigationKeys.SECTION);
  const renterInfo = useSelector(selectRenterProfileInformation);
  const minorLength = renterInfo?.applications?.find((app) => app.id === Number(applicationId))?.minors?.length ?? 0;
  const continueButtonOnClick = useCallback(
    ({
      values,
      buttonType = RenterCoOccupantButtonType.ANOTHERBUTTON,
      setErrors,
      resetForm,
      setSubmitting,
    }: MinorDispatchProps) => {
      const { firstName, lastName } = splitFullName(values.fullName);
      const isFormEmpty = Object.values(values).every((value) => value === '');

      if (isFormEmpty) {
        return navigate(renterRoutes.renterRelationMinorConfirmation);
      }

      dispatch(
        addMinorsToApplication({
          applicationId: Number(applicationId),
          minors: {
            ...values,
            firstName: firstName,
            lastName: lastName,
            relationship: capitalize(values.relationship),
          },
        })
      )
        .unwrap()
        .then(() => {
          if (resetForm && buttonType !== RenterCoOccupantButtonType.CONTINUE) {
            resetForm();
          } else {
            navigateNext
              ? navigate(`${navigateNext}?section=${navigateSection}`)
              : navigate(renterRoutes.generateRenterRelationMinorConfirmationUrl(applicationId));
          }
        })
        .catch((errorObject) => {
          if (setErrors) {
            if (!!errorObject?.null) {
              setErrors({ fullName: errorObject?.null });
            } else if (errorObject?.lastName) {
              setErrors({ fullName: `${t('renter.relationship.fullNamePlaceHolder')} ${errorObject?.lastName}` });
            } else if (errorObject?.firstName) {
              setErrors({ fullName: `${t('renter.relationship.fullNamePlaceHolder')} ${errorObject?.firstName}` });
            } else {
              setErrors(errorObject);
            }
          }

          if (setSubmitting) {
            setSubmitting(false);
          }
        });
    },
    [dispatch, applicationId, navigate, navigateNext, navigateSection]
  );

  return (
    <Formik
      initialValues={RenterMinorInitialValues}
      validationSchema={renterMinorSchema}
      validateOnBlur
      enableReinitialize
      onSubmit={(values, { resetForm, setErrors, setSubmitting }) => {
        const payload = {
          ...values,
          birthDate: values.birthDate && format(new Date(values.birthDate), dateFormatBackend),
        };

        continueButtonOnClick({
          values: payload,
          setErrors,
          resetForm: resetForm as (nextState?: Partial<FormikState<RenterMinorProps>> | undefined) => void,
          setSubmitting,
        });
      }}
    >
      {({ values, isValid, setErrors, isSubmitting, setSubmitting, dirty: isDirty }) => (
        <Form>
          <FormCloseWarning isDirty={isDirty} />
          <div className={adultStyles.maritalContainer}>
            <FormikField
              className={adultStyles.inputBackgroundColor}
              name="fullName"
              placeholder={t('renter.relationship.fullNamePlaceHolder')}
              label={`${RenterOccupantType.MINOR} ${minorLength + 1}`}
            />
            <CustomDatePicker
              name="birthDate"
              className={adultStyles.datePicker}
              floatingLabel={t('renter.relationship.dateOfBirth')}
              startDate={startDate}
              endDate={endDate}
              placeholder="MM/DD/YYYY"
              defaultSelected={defaultDate}
            />
            <FormikField
              placeholder={t('renter.relationship.enterRelationshipLabel')}
              name="relationship"
              label={t('renter.relationship.relationshipLabel')}
              className={adultStyles.inputBackgroundColor}
            />
          </div>
          <div className={adultStyles.footer}>
            <RCButton
              className={classNames(styles.renterAddButton, styles.continueBtn, adultStyles.button)}
              variant="outline"
              type="submit"
              disabled={!isValid || isSubmitting || !isDirty}
            >
              {t('renter.relationship.addAnotherMinor')}
            </RCButton>
            <RCButton
              disabled={!isValid || isSubmitting || !isDirty}
              className={classNames(styles.continueBtn, adultStyles.button)}
              onClick={() => {
                setSubmitting(true);
                const payload = {
                  ...values,
                  birthDate: values.birthDate && format(new Date(values.birthDate), dateFormatBackend),
                };

                continueButtonOnClick({
                  values: payload,
                  buttonType: RenterCoOccupantButtonType.CONTINUE,
                  setErrors,
                  setSubmitting,
                });
              }}
              variant="outline"
            >
              {t('renter.phone.continueButtonText')}
            </RCButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
const RenterMinor = (): JSX.Element => {
  const maxHeight = useDeviceHeight(220);

  return (
    <RenterContainer>
      <section className={styles.renterInvitePropertyContainer}>
        <div
          style={{
            paddingRight: '7px',
            maxHeight: IsMobile() ? maxHeight : 'auto',
            paddingBottom: '20px',
            overflow: IsMobile() ? 'auto' : 'visible',
          }}
        >
          <RenterIconInfoContainer />
          <h2 className={styles.renterInvitePropertyH2}>
            {t('renter.relationship.descriptionBasicText')}&nbsp;
            <span className={styles.renterSpan}>{t('renter.relationship.descriptionMinorText')}</span>&nbsp;
            {t('renter.relationship.descriptionInfoText')}
          </h2>
          <MinorPage />
        </div>
      </section>
    </RenterContainer>
  );
};

export default RenterMinor;
