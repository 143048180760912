import { Dispatch, SetStateAction, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EditIcon } from 'assets/svgs/Edit.svg';
import ReadMore from 'components/shared/ReadMore/ReadMore';

import styles from './BackgroundAnswerCard.module.scss';

interface BackgroundAnswerCardProps {
  answer: string;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}
const BackgroundAnswerCard = ({ answer, setIsModalVisible }: BackgroundAnswerCardProps): JSX.Element => {
  const { t } = useTranslation();
  const onEditClickHandler = useCallback(() => {
    setIsModalVisible(true);
  }, [setIsModalVisible]);

  return (
    <div>
      <div className={styles.answerContainer}>
        <Row>
          <Col xs={11} sm={11} md={11} className={styles.labelStyle}>
            {t('application.explaination')}
          </Col>
          <Col xs={1} sm={1} md={1}>
            <EditIcon color="#A4B1B3" width={18} height={18} onClick={onEditClickHandler} />
          </Col>
        </Row>
        <ReadMore text={answer} />
      </div>
    </div>
  );
};

export default BackgroundAnswerCard;
