import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RCButton from 'components/shared/Button/Button';
import TextField from 'components/shared/TextField/TextField';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import { MoreInformation } from './MoreInformation';

import styles from '../NoCreditHistory/NoCreditHistory.module.scss';
import indexStyles from './../RenterIndex.module.scss';
export const VisaUpload = (): JSX.Element => {
  const navigate = useNavigate();
  const { visaTypeDescription, userId, visaDocuments } = useSelector(selectRenterProfileInformation);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [explanation, setExplanation] = useState<string | null | undefined>(visaTypeDescription);
  const { t } = useTranslation();
  const maxHeight = useDeviceHeight(220);
  const { path, applicationId } = useParams();
  const handleVisaDecriptionSave = (): void => {
    const params = { visaTypeDescription: explanation, id: userId };

    dispatch(addRenterProfileInformation({ values: params }));
    navigate(renterRoutes.generateGuarantorInformation(applicationId));
  };
  const othernavigation = (): void => {
    const params = { visaTypeDescription: explanation, id: userId };

    dispatch(addRenterProfileInformation({ values: params }));
  };

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        {/* icons list */}
        <div className={indexStyles.contentContainer}>
          <div
            className={styles.scrollContainer}
            style={{ paddingRight: '12px', maxHeight: maxHeight, overflowY: 'auto' }}
          >
            <RenterIconInfoContainer />
            <div className={indexStyles.renterContentContainer}>
              {path === 'other' && !visaTypeDescription ? (
                <>
                  <span className={indexStyles.renterH2}>{t('renter.creditHistory.nocredit.other.information')}</span>
                  <span className={classNames(indexStyles.renterH2, indexStyles.highlightedText)}>
                    {t('renter.noCreditHistory.other')}
                  </span>
                  <span className={indexStyles.renterH2}>{t('renter.creditHistory.nocredit.other.status')}</span>
                  <div className={styles.otherstextContainer}>
                    <TextField
                      value={explanation as string}
                      onChange={(e) => setExplanation(e.target.value)}
                      label={t('renter.OtherInformation.typeResponse')}
                      isTextArea="textarea"
                      placeholder={t('renter.BackgroundInformation.typeResponse')}
                      className={styles.textField}
                    />
                  </div>
                  <div className={indexStyles.renterButtonContainer}>
                    <RCButton
                      className={classNames(indexStyles.renterButton, styles.renterCreditButton)}
                      variant="outline"
                      onClick={othernavigation}
                      disabled={!explanation}
                    >
                      {t('renter.noCreditHistory.SaveAndContinue')}
                    </RCButton>
                  </div>
                </>
              ) : (
                <>
                  <MoreInformation />
                  <div className={indexStyles.renterButtonContainer}>
                    <RCButton
                      className={classNames(indexStyles.renterButton, styles.renterCreditButton)}
                      variant="outline"
                      onClick={handleVisaDecriptionSave}
                      disabled={isEmpty(visaDocuments)}
                    >
                      {t('renter.noCreditHistory.SaveAndContinue')}
                    </RCButton>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};
