import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { renterAnimals } from 'redux/selectors/renterSelector';

import AnimalDisplayCard from 'components/Renter/PetsInformation/AnimalDisplayCard';
import { AnimalHeader } from 'components/Renter/PetsInformation/AnimalHeader/AnimalHeader';
import { FurryFriendTypes } from 'constants/renterConstants';
import { AnimalInformationProps } from 'shared/types/renterTypes';

import animalStyles from '../AnimalInformation.module.scss';
const AnimalCards = (): JSX.Element => {
  const animalInformation = useSelector(renterAnimals);
  const getAnimalByType = (
    animalInfo: AnimalInformationProps[]
  ): { assistiveAnimals: AnimalInformationProps[]; petAnimals: AnimalInformationProps[] } =>
    animalInfo.reduce(
      (acc, animal) => {
        if (animal.furryFriendType === FurryFriendTypes.ASSISTIVE_ANIMAL) {
          acc.assistiveAnimals.push(animal);
        } else if (animal.furryFriendType === FurryFriendTypes.PETS) {
          acc.petAnimals.push(animal);
        }

        return acc;
      },
      { assistiveAnimals: [] as AnimalInformationProps[], petAnimals: [] as AnimalInformationProps[] }
    );
  const { assistiveAnimals, petAnimals } = useMemo(() => getAnimalByType(animalInformation ?? []), [animalInformation]);

  if (!animalInformation?.length) {
    return <div />;
  }

  return (
    <div className={animalStyles.AnimalContainer}>
      <AnimalHeader isSummaryPage animalType={FurryFriendTypes.PETS} animalInformation={petAnimals} />
      {petAnimals?.map((animal, index) => (
        <div key={`animal-display-${animal.id}`}>
          <AnimalDisplayCard index={index + 1} animalInformation={animal} shouldShowEditIcon={false} />
        </div>
      ))}
      <AnimalHeader isSummaryPage animalType={FurryFriendTypes.ASSISTIVE_ANIMAL} animalInformation={assistiveAnimals} />
      {assistiveAnimals?.map((animal, index) => (
        <div key={`animal-display-${animal.id}`}>
          <AnimalDisplayCard index={index + 1} animalInformation={animal} shouldShowEditIcon={false} />
        </div>
      ))}
    </div>
  );
};

export default AnimalCards;
