import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { AddPropertyParams } from 'shared/types/propertyType';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

import { AddressHistoryViewPDF } from '../../components/AddressHistoryViewPDF/AddressHistoryViewPDF';
import { CustomPDFPage } from '../../components/shared/CustomPDFPage/CustomPDFPage';

interface Props {
  propertyInformation: AddPropertyParams;
  renterDetails: RenterProfileInformationProps;
  addressVerificationInformation: AddressVerificationResponse[];
  application: ApplicationType;
}

export const AddressesPagePDF = ({
  propertyInformation,
  renterDetails,
  addressVerificationInformation,
  application,
}: Props): JSX.Element => (
  <CustomPDFPage propertyInformation={propertyInformation} renterDetails={renterDetails} application={application}>
    <AddressHistoryViewPDF
      addressVerificationInformation={addressVerificationInformation}
      addresses={renterDetails?.addresses ?? []}
    />
  </CustomPDFPage>
);
