import { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { t } from 'i18next';
import { capitalize } from 'lodash-es';
import { deleteFurryFriend } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { RenterDeleteConfirmationModal } from 'components/Renter/components/RenterDeleteConfirmationModal/RenterDeleteConfirmationModal';
import AnimalDetailsEditModal from 'components/Renter/PetsInformation/AnimalDetailsEditModal';
import { AnimalTooltip } from 'components/Renter/PetsInformation/AnimalTooltip/AnimalTooltip';
import { AssistiveAnimalOption } from 'constants/renterConstants';
import { pluralize } from 'helpers/user';
import { AnimalDetailsInformationCardProps } from 'shared/types/renterTypes';

import RCButton from '../Button/Button';

import { InfoContainer } from './InfoContainer/InfoContainer';

import styles from './AnimalDetailsInformationCard.module.scss';

const AnimalDetailsInformationCard = ({
  animalInformation,
  url,
  setExplorerOpen,
  shouldShowEditIcon = true,
  shouldShowPill = false,
  index,
  animalId,
  setAnimalId,
}: AnimalDetailsInformationCardProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [animalToDelete, setAnimalToDelete] = useState<number>(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAnimalDeleteModalOpen, setIsAnimalDeleteModalOpen] = useState(false);
  const [isShowingOverlay, setIsShowingOverlay] = useState(false);
  const animalTypeLabel = useMemo(() => {
    if (animalInformation?.assistiveAnimalType === AssistiveAnimalOption[0].value) {
      return t('renter.petsInformation.serviceAnimalLabel');
    } else if (animalInformation?.assistiveAnimalType === AssistiveAnimalOption[1].value) {
      return t('renter.petsInformation.OtherPetlLabel');
    }

    return t('renter.petsInformation.petlLabel');
  }, [animalInformation]);
  const onDeleteHandler = useCallback(() => {
    if (!!animalToDelete) {
      dispatch(deleteFurryFriend({ animalId: animalToDelete }))
        .unwrap()
        .finally(() => {
          setIsAnimalDeleteModalOpen(false);
        });
    }
  }, [animalToDelete, dispatch]);
  const onHideHandler = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const editIconHandler = useMemo(
    () => (
      <div className={styles.HeaderContainer}>
        <span className={styles.typeValueContainer}>{capitalize(animalInformation.furryFriendSubType)}</span>

        <div className={styles.displayAtEnd}>
          <div className={shouldShowPill ? styles.PillContainer : ''}>
            <span
              className={classNames(styles.typeLabel, shouldShowPill && styles.FontSizeContainer, styles.displayAtEnd)}
            >
              {animalTypeLabel}
            </span>
            {shouldShowEditIcon && (
              <RCButton variant="outline" onBlur={() => setIsShowingOverlay(false)} className={styles.IconButton}>
                <AnimalTooltip
                  onDeleteHandler={() => {
                    setIsAnimalDeleteModalOpen(true);
                  }}
                  setAnimalToDelete={setAnimalToDelete}
                  onEditHandler={(id: number) => {
                    if (setIsModalOpen && setAnimalId) {
                      setIsModalOpen(true);
                      setAnimalId(id);
                    }

                    setIsShowingOverlay(false);
                  }}
                  animalInformation={animalInformation}
                  isShowingOverlay={isShowingOverlay}
                  setIsShowingOverlay={setIsShowingOverlay}
                  index={index ?? 0}
                />
              </RCButton>
            )}
          </div>
        </div>
      </div>
    ),
    [animalInformation, animalTypeLabel, index, isShowingOverlay, setAnimalId, shouldShowEditIcon, shouldShowPill]
  );

  return (
    animalInformation && (
      <div className={styles.container} key={animalInformation.id}>
        {!!index && <div className={styles.FloatingLabel}>{`${t('renter.petsInformation.animalLabel')} ${index}`}</div>}
        <Row className={styles.RowContainer}>
          <Col xs={4} md={3} lg={shouldShowPill ? 2 : 3} xl={shouldShowPill && 1} className={styles.alignImage}>
            <input
              type="image"
              src={url}
              alt={t('renter.petsInformation.animalLabel')}
              className={styles.displayImage}
              onClick={() => {
                if (setExplorerOpen) {
                  setExplorerOpen(true);
                }

                if (setAnimalId) {
                  setAnimalId(animalInformation.id);
                }
              }}
            ></input>
          </Col>
          <Col xs={8} md={9} lg={shouldShowPill ? 10 : 9} xl={shouldShowPill && 11} className={styles.rightContainer}>
            <Row className={classNames({ [styles.furryFriendTypeDiv]: !shouldShowEditIcon })}>{editIconHandler}</Row>
            <div className={styles.infoContainer}>
              <InfoContainer
                shouldShowPill={shouldShowPill}
                label="renter.CollectedInformation.name"
                value={capitalize(animalInformation.name)}
              />
              <InfoContainer
                shouldShowPill={shouldShowPill}
                label="renter.petsInformation.breedlabel"
                value={capitalize(animalInformation.breed)}
              />
            </div>
            <div className={styles.infoContainer}>
              <InfoContainer
                shouldShowPill={shouldShowPill}
                label="renter.petsInformation.weightLabel"
                value={`${animalInformation.weight} lbs`}
              />
              <InfoContainer
                shouldShowPill={shouldShowPill}
                label="renter.petsInformation.ageLabel"
                value={pluralize(animalInformation.age, animalInformation.ageUnit)}
              />
              <InfoContainer
                shouldShowPill={shouldShowPill}
                label="renter.petsInformation.sex"
                value={capitalize(animalInformation.genderType)}
              />
            </div>
          </Col>
        </Row>
        {isAnimalDeleteModalOpen && (
          <RenterDeleteConfirmationModal
            shouldOpen={isAnimalDeleteModalOpen}
            handleDelete={onDeleteHandler}
            onGoBack={() => setIsAnimalDeleteModalOpen(false)}
            heading={`${t('renter.petsInformation.deleteAnimal')}?`}
            validationHeading=""
          />
        )}
        {isModalOpen && animalId && (
          <AnimalDetailsEditModal
            isModalVisible={isModalOpen}
            setIsModalVisible={setIsModalOpen}
            onHideHandler={onHideHandler}
            id={animalId}
            animalIndex={index}
          />
        )}
      </div>
    )
  );
};

export default AnimalDetailsInformationCard;
