import { isEmpty } from 'lodash-es';

import { RenterAddressSteps, RenterAddressTypes, RenterProfileSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { renterRoutes } from 'shared/routes';
import {
  CalculateCurrentProgressType,
  NavigationKeys,
  RenterNavigationData,
  restrictedProgressKeys,
  StepStatus,
  UrlNavigationKeys,
} from 'shared/types/renterProgress';
import { RenterApplicationProps, RenterProfileInformationProps, RenterRoles } from 'shared/types/renterTypes';

import { findApplication } from './agentHelper';

export interface CalculateCurrentProgressProps {
  data: RenterProfileInformationProps;
  currentStep: string;
  role: RenterRoles;
  applicationId: number;
}

export const calculateCurrentProgress = ({
  data,
  currentStep,
  role,
  applicationId,
}: CalculateCurrentProgressProps): CalculateCurrentProgressType => {
  if (!data) {
    return { progress: 0, options: [] };
  }

  const {
    // priorEmployments,
    presentEmployments,
    addresses,
    firstName,
    lastName,
    ssnTailDigits,
    renterPhoneNumbers,
    stripeVerified,
    applications,
    birthDate,
    vehicles,
    furryFriends,
    isPaymentSucceeded,
    hasNoFurryFriends,
    hasNoPriorAddress,
    hasNoVehicles,
    // hasNoPriorEmployments,
    renterCriteriaAccepted,
    hasNoSSN,
  } = data;
  const progressKeys = restrictedProgressKeys[role];
  let currentProgress = 0;
  const options: RenterNavigationData[] = [];
  const progressEachStep = Math.round(100 / (progressKeys.length - 2)) + (role === RenterRoles.PRIMARY ? 1 : 0);
  const { adults, minors, hasNoAdults, hasNoMinors, id, backgroundInformation, isConfirmed } =
    applications.find((application) => application.id === applicationId) || {};

  progressKeys.forEach((key) => {
    let status: StepStatus = StepStatus.PENDING;
    let disabled = false;

    switch (key) {
      case NavigationKeys.SOURCE_OF_INCOME:
        if (presentEmployments?.length) {
          currentProgress += progressEachStep;
          status = StepStatus.COMPLETED;
        }

        break;
      case NavigationKeys.ADDRESS_HISTORY:
        if (addresses?.[0]?.moveInDate || hasNoPriorAddress) {
          currentProgress += progressEachStep;
          status = StepStatus.COMPLETED;
        }

        break;
      case NavigationKeys.PERSONAL_PROFILE:
        if (
          firstName &&
          lastName &&
          (ssnTailDigits || hasNoSSN) &&
          renterPhoneNumbers &&
          (stripeVerified || (firstName && lastName && birthDate))
        ) {
          currentProgress += progressEachStep;
          status = StepStatus.COMPLETED;
        }

        break;
      case NavigationKeys.HOUSEHOLD_DETAILS:
        if (!applications) {
          break;
        }

        if (role === RenterRoles.OCCUPANTS) {
          if (hasNoMinors || !isEmpty(minors)) {
            currentProgress += progressEachStep;
            status = StepStatus.COMPLETED;
          }
        } else if ((!isEmpty(adults) || hasNoAdults) && (hasNoMinors || !isEmpty(minors))) {
          currentProgress += progressEachStep;
          status = StepStatus.COMPLETED;
        }

        break;
      case NavigationKeys.ANIMAL_INFORMATION:
        if (furryFriends?.length || hasNoFurryFriends) {
          currentProgress += progressEachStep;
          status = StepStatus.COMPLETED;
        }

        break;
      case NavigationKeys.BACKGROUND_QUESTIONS:
        if (isEmpty(applications)) {
          break;
        }

        if (backgroundInformation?.length) {
          currentProgress += progressEachStep;
          status = StepStatus.COMPLETED;
        }

        break;
      case NavigationKeys.SUMMARY_REPORT:
        currentProgress += progressEachStep;
        status = StepStatus.COMPLETED;

        break;
      case NavigationKeys.PAYMENT:
        const isPresentAddress = addresses?.some((address) => address.addressType === RenterAddressTypes.PRESENT);
        const hasValidAddresses = addresses?.[0]?.moveInDate && isPresentAddress;
        const hasRequiredInfo =
          firstName &&
          lastName &&
          birthDate &&
          ssnTailDigits &&
          renterPhoneNumbers &&
          !isEmpty(addresses) &&
          hasValidAddresses &&
          presentEmployments?.length;

        if (!hasRequiredInfo) {
          disabled = true;
        } else if (isPaymentSucceeded) {
          disabled = false;
          status = StepStatus.COMPLETED;
        } else {
          disabled = false;
        }

        break;
      case NavigationKeys.PROPERTY_SELECTION:
        if (id) {
          currentProgress += progressEachStep;
          status = StepStatus.COMPLETED;
        }

        break;
      case NavigationKeys.RENTAL_CRITERIA:
        if (renterCriteriaAccepted) {
          currentProgress += progressEachStep;
          status = StepStatus.COMPLETED;
        }

        break;
      case NavigationKeys.VEHICLE_INFORMATION:
        if (vehicles?.length || hasNoVehicles) {
          currentProgress += progressEachStep;
          status = StepStatus.COMPLETED;
        }

        break;
      case NavigationKeys.REPORT:
        if (isConfirmed) {
          status = StepStatus.COMPLETED;
        } else {
          status = StepStatus.CURRENT;
        }

        break;
      default:
        break;
    }

    if (key === currentStep) {
      status = StepStatus.CURRENT;
    }

    options.push({
      value: key,
      label: key,
      disabled,
      status,
    });
  });

  if (currentProgress > 100) {
    currentProgress = 100;
  }

  return { progress: currentProgress, options: options };
};

export const currentLocationKey = (data: string): string => {
  const currentLocation = Object.keys(UrlNavigationKeys).filter((key) => data?.includes(key));

  if (!currentLocation?.length) {
    return '';
  }

  return UrlNavigationKeys[currentLocation[0]];
};

export const currentNavigationRoute = (
  renterInfo: RenterProfileInformationProps,
  step: string | undefined,
  applicationId?: string
): string => {
  const {
    presentEmployments,
    addresses,
    vehicles,
    furryFriends,
    firstName,
    renterPhoneNumbers,
    hasNoSSN,
    ssnTailDigits,
    birthDate,
    lastName,
    stripeVerified,
  } = renterInfo;

  switch (step) {
    case NavigationKeys.SOURCE_OF_INCOME:
      return presentEmployments?.length
        ? renterRoutes.generateRenterPresentSourceOfIncomeSummary(String(applicationId))
        : renterRoutes.generateRenterPresentSourceOfIncome(String(applicationId));
    case NavigationKeys.PROPERTY_SELECTION:
      return renterRoutes.generateViewRenterCriteriaUrl(applicationId);
    case NavigationKeys.RENTAL_CRITERIA:
      return renterRoutes.generateViewRenterCriteriaUrl(applicationId);
    case NavigationKeys.PERSONAL_PROFILE:
      let completedStep = '';

      if (!renterPhoneNumbers) {
        completedStep = renterRoutes.generateRenterPhoneNumber(applicationId);
      } else if (!firstName && !lastName && !birthDate && !stripeVerified) {
        completedStep = renterRoutes.generateRenterStripeIdentityUrl(Number(applicationId));
      } else if (!hasNoSSN && !ssnTailDigits) {
        completedStep = renterRoutes.generatePersonalInformationUrl(RenterProfileSteps.SSNINPUT, Number(applicationId));
      } else if (!hasNoSSN && ssnTailDigits && firstName && lastName && birthDate && stripeVerified) {
        completedStep = renterRoutes.generatePersonalInformationUrl(
          RenterProfileSteps.PROFILESUMMARY,
          Number(applicationId)
        );
      } else {
        completedStep = renterRoutes.generatePersonalInformationUrl(
          RenterProfileSteps.PROFILESUMMARY,
          Number(applicationId)
        );
      }

      return completedStep;
    case NavigationKeys.ADDRESS_HISTORY:
      return addresses?.length && addresses[0].moveInDate
        ? renterRoutes.generateAddressHistoryInformation(String(applicationId), RenterAddressSteps.ADDRESS_HISTORY)
        : renterRoutes.generateAddressHistoryInformation(
            String(applicationId),
            RenterAddressSteps.PRESENT_ADDRESS_CONFIRMATION
          );
    case NavigationKeys.BACKGROUND_QUESTIONS:
      return renterRoutes.generateViewRenterBackgroundInformation(String(applicationId));
    case NavigationKeys.ANIMAL_INFORMATION:
      return !furryFriends?.length
        ? renterRoutes.generateRenterPetsInformation(applicationId)
        : `${renterRoutes.generateRenterPetsInformation(String(applicationId))}&step=confirmation`;
    case NavigationKeys.VEHICLE_INFORMATION:
      return vehicles?.length
        ? renterRoutes.generateVehiclesInformationSummary(String(applicationId))
        : renterRoutes.generateVehiclesInformation(applicationId);
    case NavigationKeys.HOUSEHOLD_DETAILS:
      return renterRoutes.generateGuarantorInformation(String(applicationId));
    case NavigationKeys.PAYMENT:
      return renterRoutes.generateRenterPaymentMethod(String(applicationId));
    case NavigationKeys.SUMMARY_REPORT:
      return renterRoutes.generateRenterPaymentVerification(String(applicationId));
    case NavigationKeys.REPORT:
      return renterRoutes.generateRenterReportUrl(String(applicationId));
    default:
      return renterRoutes.renter;
  }
};

export const lastCompletedStep = (
  renterInfo: RenterProfileInformationProps,
  role: RenterRoles,
  applicationId: number | string
): string => {
  const {
    // priorEmployments,
    presentEmployments,
    addresses,
    firstName,
    lastName,
    stripeVerified,
    birthDate,
    ssnTailDigits,
    renterPhoneNumbers,
    applications,
    vehicles,
    furryFriends,
    signedTermsOfUse,
    isPaymentSucceeded,
    hasNoFurryFriends,
    hasNoSSN,
    hasNoPriorAddress,
    hasNoVehicles,
    // hasNoPriorEmployments,
    renterCriteriaAccepted,
  } = renterInfo;
  let completedStep = '';
  const progressKeys = restrictedProgressKeys[role];
  const currentApplication = applications.find((application) => application.id === applicationId);

  progressKeys.forEach((key) => {
    if (completedStep) {
      return;
    }

    switch (key) {
      case NavigationKeys.RENTAL_CRITERIA:
        if (!applications?.length) {
          completedStep = renterRoutes.startApplication;
        } else if (!signedTermsOfUse && !renterCriteriaAccepted) {
          completedStep = renterRoutes.generateViewRenterCriteriaUrl(String(currentApplication?.id));
        }

        break;
      case NavigationKeys.PERSONAL_PROFILE:
        if (!renterPhoneNumbers) {
          completedStep = renterRoutes.generateRenterPhoneNumber(applicationId);
        } else if (!firstName && !lastName && !birthDate && !stripeVerified) {
          completedStep = renterRoutes.generateRenterStripeIdentityUrl(applicationId);
        } else if (!hasNoSSN && !ssnTailDigits) {
          completedStep = renterRoutes.generatePersonalInformationUrl(RenterProfileSteps.SSNINPUT, applicationId);
        }

        break;

      case NavigationKeys.HOUSEHOLD_DETAILS:
        if (isEmpty(applications)) {
          break;
        }

        const { guarantor, adults, minors, renterRole, hasNoAdults, hasNoMinors, hasNoGuarantor } =
          currentApplication ?? ({} as RenterApplicationProps);

        if (!hasNoGuarantor && !guarantor && applications) {
          completedStep = renterRoutes.generateGuarantorInformation(applicationId);
        } else if (!hasNoAdults && !adults?.length && !renterRestrictions[renterRole].adults) {
          completedStep = renterRoutes.renterRelation.replace(':applicationId', `${applicationId}`);
        } else if (!hasNoMinors && isEmpty(minors)) {
          completedStep = renterRoutes.renterRelationMinorIndex.replace(':applicationId', `${applicationId}`);
        }

        break;

      case NavigationKeys.ADDRESS_HISTORY:
        const isPresentAddress = !!addresses?.find((address) => address.addressType === RenterAddressTypes.PRESENT);
        const isPriorAddress = !!addresses?.find((address) => address.addressType === RenterAddressTypes.PRIOR);

        if (isEmpty(addresses) || !addresses?.[0]?.moveInDate || !isPresentAddress) {
          completedStep = renterRoutes.generateAddressHistoryInformation(
            applicationId,
            RenterAddressSteps.PRESENT_ADDRESS
          );
        } else if (role !== RenterRoles.GUARANTOR && !hasNoPriorAddress && !isPriorAddress) {
          completedStep = renterRoutes.generateAddressHistoryInformation(
            Number(applicationId),
            RenterAddressSteps.PRIOR_ADDRESS
          );
        }

        break;

      case NavigationKeys.SOURCE_OF_INCOME:
        if (!presentEmployments?.length) {
          completedStep = renterRoutes.generateRenterPresentSourceOfIncome(applicationId);
        }
        // else if (!hasNoPriorEmployments && !priorEmployments?.length) {
        //   completedStep = renterRoutes.generateRenterPriorSourceOfIncome(applicationId);
        // }

        break;

      case NavigationKeys.VEHICLE_INFORMATION:
        if (!vehicles?.length && !hasNoVehicles) {
          completedStep = renterRoutes.generateVehiclesInformation(applicationId);
        }

        break;
      case NavigationKeys.ANIMAL_INFORMATION:
        if (!hasNoFurryFriends && !furryFriends?.length) {
          completedStep = renterRoutes.generateRenterPetsInformation(applicationId);
        }

        break;

      case NavigationKeys.BACKGROUND_QUESTIONS:
        if (isEmpty(applications)) {
          break;
        }

        const isBackgroundInfo = isEmpty(
          applications.find((app) => app.id === Number(applicationId))?.backgroundInformation
        );

        if (isBackgroundInfo) {
          completedStep = renterRoutes.generateViewRenterBackgroundInformation(applicationId);
        }

        break;

      case NavigationKeys.REPORT:
        if (isEmpty(applications)) {
          break;
        }

        const application = findApplication(applications, applicationId);

        if (!application?.isConfirmed) {
          completedStep = renterRoutes.generateRenterReportUrl(applicationId);
        }

        break;
      case NavigationKeys.SUMMARY_REPORT:
        if (isEmpty(applications)) {
          break;
        }

        const hasBackgroundInfo = !isEmpty(
          applications.find((app) => app.id === Number(applicationId))?.backgroundInformation
        );

        if (hasBackgroundInfo) {
          completedStep = renterRoutes.generateRenterPaymentVerification(applicationId);
        }

        break;

      case NavigationKeys.PAYMENT:
        if (!isPaymentSucceeded) {
          completedStep = renterRoutes.generateRenterPaymentMethod(applicationId);
        }

        break;

      default:
        break;
    }
  });

  return completedStep;
};
