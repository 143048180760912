import classNames from 'classnames';

import styles from './../PropertyIndex.module.scss';

export interface PropertyTypeProps {
  propertyType?: string;
  label: string;
}

const PropertyType = ({ propertyType = 'Some property Type', label }: PropertyTypeProps): JSX.Element => (
  <div className={styles.propertyText}>
    <span className={classNames(styles.titleMinWidth, styles.stackCardTitleMinWidth)}>{label}</span>
    <span className={styles.stackCardPropertyType}> {(propertyType && propertyType) || 'Some property Type'}</span>
  </div>
);

export default PropertyType;
