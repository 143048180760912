import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { getRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { RenterAddressSteps, RenterAddressTypes } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { ConfirmAddressProps, convertAddressToFrontEndFormat, getPresentAddress } from 'helpers/address';
import { formatAddressForFrontEnd } from 'helpers/renterHelper';
import { renterRoutes } from 'shared/routes';
import { AddressInitialValuesProps } from 'shared/types/renterTypes';

import PresentAddress from '../PresentAddress/PresentAddress';
import RenterIconInfoContainer from '../RenterIconInfoContainer';
import RenterOwnerInformation from '../RenterOwnerInformation/RenterOwnerInformation';

const ConfirmPresentAddress = ({ isRenting, setIsRenting }: ConfirmAddressProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<AddressInitialValuesProps>();
  const { applicationId } = useParams();
  const [searchParams] = useSearchParams();
  const stepName = searchParams.get('step');
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isApiCalled, setIsApiCalled] = useState(false);
  const { addresses } = useSelector(selectRenterProfileInformation);
  const address = getPresentAddress(addresses ?? []) ?? values.renterAddress;
  const renterRole = useSelector(applicantRenterRole);
  const navigate = useNavigate();

  useEffect(() => {
    const isPresentAddress = !!addresses?.find((addr) => addr.addressType === RenterAddressTypes.PRESENT);

    if (
      renterRestrictions[renterRole].presentAddress ||
      (isPresentAddress && addresses?.[0]?.moveInDate && stepName === RenterAddressSteps.PRESENT_ADDRESS_CONFIRMATION)
    ) {
      navigate(renterRoutes.generateAddressHistoryInformation(Number(applicationId), RenterAddressSteps.PRIOR_ADDRESS));
    }
  }, [addresses, applicationId, navigate, renterRole, stepName]);

  //this is only for until stripe API isn't integrated
  useEffect(() => {
    if (!isApiCalled && stepName === RenterAddressSteps.PRESENT_ADDRESS_CONFIRMATION) {
      dispatch(getRenterProfileInformation());
      setIsApiCalled(true);
    }
  }, [dispatch, isApiCalled, setFieldValue, stepName, values]);
  useEffect(() => {
    if (addresses && getPresentAddress(addresses)) {
      setFieldValue('renterAddress', convertAddressToFrontEndFormat(getPresentAddress(addresses) ?? {}));
    }
  }, [addresses, setFieldValue]);

  return (
    <div style={{ overflow: 'visible' }}>
      <RenterIconInfoContainer />
      {address && !isRenting && <PresentAddress address={address} isRenting={isRenting} setIsRenting={setIsRenting} />}
      {address && isRenting && (
        <RenterOwnerInformation
          address={values.renterAddress.id ? formatAddressForFrontEnd(values.renterAddress) : values.renterAddress}
          setIsRenting={setIsRenting}
        />
      )}
    </div>
  );
};

export default ConfirmPresentAddress;
