import { Modal as BModal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import { PropertyEditModalProps } from 'shared/types/propertyType';

import styles from './../PropertyDetails.module.scss';

export const PropertyEditModal = ({
  isModalVisible,
  setIsModalVisible,
  title,
  content,
  isValid,
  submitForm,
  isSubmitting,
  closeButtonText = 'Close',
  isFooterVisible = true,
}: PropertyEditModalProps): JSX.Element | null => {
  const { t } = useTranslation();

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal
      size="lg"
      buttonText={closeButtonText}
      centered
      show={isModalVisible}
      title={title}
      onHide={() => setIsModalVisible(false)}
      isCrossIconVisible={!isFooterVisible}
    >
      <div className={styles.container}>
        <BModal.Body className={styles.bodyPadding}>{content}</BModal.Body>
        {isFooterVisible && (
          <div>
            <BModal.Footer>
              <Row className={styles.row}>
                <Button
                  variant="outline"
                  size="sm"
                  className={styles.backButton}
                  onClick={() => setIsModalVisible(false)}
                >
                  {t('agent.properties.property.edit.button.cancel')}
                </Button>
                <Button
                  data-testid="modal-button"
                  onClick={submitForm}
                  className={styles.button}
                  disabled={!isValid || isSubmitting}
                  size="sm"
                >
                  {t('agent.properties.property.edit.button.save')}
                </Button>
              </Row>
            </BModal.Footer>
          </div>
        )}
      </div>
    </Modal>
  );
};
