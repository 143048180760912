import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import RCButton from 'components/shared/Button/Button';
import styles from 'components/shared/Modal/ErrorModal/ErrorModal.module.scss';
import { ErrorCardProps } from 'shared/types/agentTypes';

export const ServerErrorCard = ({
  onButtonClick,
  errorIcon,
  mainHeading,
  subHeading,
  description,
}: ErrorCardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Col className={styles.container}>
      <Col>{errorIcon}</Col>
      <Col className={styles.heading}>{mainHeading}</Col>
      {subHeading && <Col className={styles.subHeading}>{subHeading}</Col>}
      {description && <Col className={styles.subHeading}>{description}</Col>}
      <Col>
        <RCButton className={styles.renterButton} variant="primary" onClick={onButtonClick}>
          {t('modal.serverMessage.buttonBack')}
        </RCButton>
      </Col>
    </Col>
  );
};
