import { ResumeVerifierQuestions } from 'constants/agentConstants';
import { convertVerificationResponseToQuestions } from 'helpers/agentHelper';
import { AddressVerificationResponse } from 'shared/types/agentTypes';

import { ViewResidencyQuestion } from './ViewResidencyQuestion';

import styles from './ResidencyQuestions.module.scss';

export const ResidencyQuestions = ({
  verificationInformation,
}: {
  verificationInformation: AddressVerificationResponse;
}): JSX.Element => (
  <div className={styles.QuestionsListContainer}>
    {convertVerificationResponseToQuestions(verificationInformation?.submittedVerifierResponse)?.map(
      (question, index) => (
        <div key={`Question-${question}-${question.id}`} className={styles.ItemContainer}>
          <ViewResidencyQuestion singleQuestion={question} index={index} />
          {index !== ResumeVerifierQuestions.length - 1 && <div className={styles.HorizontalDivider} />}
        </div>
      )
    )}
  </div>
);
