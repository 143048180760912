import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import AgentLayout from 'layouts/agentLayout';
import AuthOnlyLayout from 'layouts/authLayout';
import { PublicLayout } from 'layouts/publicLayout';
import RenterLayout from 'layouts/renterLayout';
import { MaintenancePage } from 'pages/Maintenance/MaintenancePage';
import PropertiesPage from 'pages/Properties/PropertiesPage';
import { isAuthenticated, userType } from 'redux/selectors/authSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import { useFullStory } from 'hooks/useFullStory';
import { routes } from 'shared/routes';
import { canViewRecordsAndActivity } from 'shared/types/agentInvite';
import { UserType } from 'shared/types/authType';

import agentRoutes from './agentRoutes';
import authenticationRoutes from './authRoutes';
import { publicRoute } from './publicRoutes';
import renterRoutes from './renterRouters';

const publicRouter = {
  element: <PublicLayout />,
  children: publicRoute,
};
const agentsRouter = {
  element: <AgentLayout />,
  children: agentRoutes,
};
const unAuthenticatedRoutes = {
  element: <AuthOnlyLayout />,
  children: authenticationRoutes,
};
const rentersRouter = {
  element: <RenterLayout />,
  children: renterRoutes,
};
const propertiesRoute = {
  path: routes.properties,
  element: <PropertiesPage />,
};
const maintenanceRoutes = {
  path: '*',
  element: <MaintenancePage />,
};
const Router: React.FC = () => {
  const isUserAuthenticated = useSelector(isAuthenticated);
  const authUserType = useSelector(userType);
  const currentOrganization = useSelector(selectedOrganization);
  const isUnderMaintenance = process.env.REACT_APP_UNDER_MAINTENANCE === 'true';

  useFullStory();
  const accessibleRoutes = useMemo(() => {
    if (!isUserAuthenticated) {
      return unAuthenticatedRoutes;
    }

    if (authUserType === UserType.RENTER) {
      return rentersRouter;
    }

    if (authUserType === UserType.AGENT) {
      return agentsRouter;
    }

    return agentsRouter;
  }, [isUserAuthenticated, authUserType]);
  // Include the properties route only if the user can view records and activity
  const restrictedUserRoutes =
    canViewRecordsAndActivity(currentOrganization) && isUserAuthenticated
      ? {
          ...accessibleRoutes,
          children: [...accessibleRoutes.children, propertiesRoute],
        }
      : accessibleRoutes;

  return useRoutes(
    isUnderMaintenance
      ? [maintenanceRoutes]
      : [
          {
            element: <Navigate to={routes.home} replace />,
            children: [
              {
                path: '*',
                element: <Navigate to={routes.home} replace />,
              },
            ],
          },
          publicRouter,
          restrictedUserRoutes,
        ]
  );
};

export default Router;
