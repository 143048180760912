import classNames from 'classnames';

import { ReactComponent as MapPinSvg } from 'assets/svgs/MapPin.svg';

import styles from './../PropertyIndex.module.scss';

export interface PropertyAddressProps {
  address?: string | null;
  dotted?: boolean;
}

const PropertyAddress = ({ address, dotted = false }: PropertyAddressProps): JSX.Element => (
  <div className={classNames(styles.propertyText, { [styles.ellipsisText]: dotted })}>
    <MapPinSvg /> {address || 'Some property address'}
  </div>
);

export default PropertyAddress;
