import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import {
  applicantRenterRole,
  isSaving,
  renterPresentEmployments,
  selectRenterProfileInformation,
} from 'redux/selectors/renterSelector';

import AddressBox from 'components/shared/AddressBox/AddressBox';
import RCButton from 'components/shared/Button/Button';
import { RenterAddressSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { convertAddressToFrontEndFormat } from 'helpers/address';
import { renterRoutes } from 'shared/routes';
import { AddressInformation, AddressInitialValuesProps } from 'shared/types/renterTypes';

import RenterIconInfoContainer from '../RenterIconInfoContainer';
import RenterOwnerInformation from '../RenterOwnerInformation/RenterOwnerInformation';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './ConfirmAddressHistory.module.scss';
const ConfirmAddressHistory = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const [isRenting, setIsRenting] = useState(false);
  const { resetForm, setFieldTouched } = useFormikContext<AddressInitialValuesProps>();
  const renterRole = useSelector(applicantRenterRole);
  const priorAddressRestricted = renterRestrictions[renterRole].priorAddress;
  const renterProfileInformation = useSelector(selectRenterProfileInformation);
  const renterAddresses = renterProfileInformation.addresses ?? [];
  const hasNoPriorAddress: boolean = renterAddresses?.length === 1;
  const presentEmployments = useSelector(renterPresentEmployments);
  const isSavingState = useSelector(isSaving);
  const [selectedAddress, setSelectedAddress] = useState<AddressInformation>({} as AddressInformation);
  const onClearStates = useCallback(() => {
    resetForm();
  }, [resetForm]);
  const clearTouchValues = useCallback(() => {
    setFieldTouched('renterAddress.fromDate', false);
    setFieldTouched('renterAddress.toDate', false);
  }, [setFieldTouched]);
  const onAddPriorClick = useCallback(() => {
    onClearStates();
    clearTouchValues();
    navigate(
      renterRoutes.generateAddressHistoryInformation(
        Number(applicationId),
        RenterAddressSteps.PRIOR_ADDRESS,
        renterRoutes.generateAddressHistoryInformation(Number(applicationId), RenterAddressSteps.ADDRESS_HISTORY)
      )
    );
  }, [applicationId, clearTouchValues, navigate, onClearStates]);
  const onClickHandler = useCallback(() => {
    // it should navigate to the source of income  page
    if (!presentEmployments?.length) {
      navigate(renterRoutes.generateRenterPresentSourceOfIncome(applicationId));
    } else {
      navigate(renterRoutes.generateRenterPresentSourceOfIncomeSummary(applicationId));
    }
  }, [applicationId, navigate, presentEmployments]);

  useEffect(() => {
    if (priorAddressRestricted && renterRestrictions[renterRole].presentAddress) {
      onClickHandler();
    }
  }, [onClickHandler, priorAddressRestricted, renterRole]);

  return (
    <div className={styles.confirmAddressHistoryContainer}>
      <RenterIconInfoContainer />
      {!isRenting ? (
        <div className={indexStyles.renterContentContainer}>
          <p className={indexStyles.renterH2}>
            {hasNoPriorAddress && !priorAddressRestricted
              ? t('renter.ConfirmAddressHistory.confirmPresentAndPrior')
              : t('renter.ConfirmAddressHistory.confirmYourAddressHistory.')}
          </p>
          <div className={styles.historyAddressesContainer}>
            {renterAddresses?.map((el, index) => (
              <div key={`${index}`} className={styles.addresBoxContainer}>
                <AddressBox
                  address={convertAddressToFrontEndFormat(el)}
                  doShowMoreData={true}
                  shouldShowLivingSituation
                  setIsRenting={setIsRenting}
                  setSelectedAddress={setSelectedAddress}
                />
              </div>
            ))}

            {hasNoPriorAddress && !priorAddressRestricted && (
              <div className={styles.addresBoxContainer}>
                <AddressBox
                  setIsRenting={setIsRenting}
                  hasPriorAddress={false}
                  doShowMoreData={true}
                  shouldShowLivingSituation
                  setSelectedAddress={setSelectedAddress}
                />
              </div>
            )}
          </div>
          <div className={classNames(indexStyles.renterButtonContainer, styles.addressHistoryButtonConatiner)}>
            {!priorAddressRestricted && (
              <RCButton
                onClick={onAddPriorClick}
                disabled={isSavingState}
                className={classNames(styles.renterAddButton, indexStyles.renterButton, styles.addressHistoryButton)}
                variant="outline"
              >
                {hasNoPriorAddress
                  ? t('renter.ConfirmAddressHistory.addPriorAddress')
                  : t('renter.ConfirmAddressHistory.addAnotherPriorAddress')}
              </RCButton>
            )}
          </div>
          <div className={classNames(indexStyles.renterButtonContainer, styles.addressHistoryButtonConatiner)}>
            <RCButton
              className={classNames(indexStyles.renterButton, styles.addressHistoryButton)}
              variant="outline"
              disabled={isSavingState}
              onClick={onClickHandler}
            >
              {t('renter.ConfirmAddressHistory.looksGood')}
            </RCButton>
          </div>
        </div>
      ) : (
        <RenterOwnerInformation address={selectedAddress} setIsRenting={setIsRenting} />
      )}
    </div>
  );
};

export default ConfirmAddressHistory;
