import { Navigate } from 'react-router-dom';
import { ForgotPassword } from 'pages/ForgotPassword/ForgotPassword';
import Login from 'pages/Login/Login';
import { ResetPassword } from 'pages/ResetPassword/ResetPassword';
import Signup from 'pages/Signup/Signup';

import RedirectionPage from 'components/Google/RedirectionPage';
import { routes } from 'shared/routes';

const authenticationRoutes = [
  { path: '*', element: <Navigate to={routes.login} replace />, index: true },
  {
    path: routes.login,
    element: <Login />,
  },
  {
    path: routes.renterSignup,
    element: <Signup />,
  },
  {
    path: routes.signup,
    element: <Signup />,
  },
  {
    path: routes.googleAuthCallback,
    element: <RedirectionPage />,
  },
  {
    path: routes.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: routes.resetPassword,
    element: <ResetPassword />,
  },
  {
    path: routes.resetPassword,
    element: <ResetPassword />,
  },
];

export default authenticationRoutes;
