import { t } from 'i18next';

import indexStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import { formatPhoneNumber } from 'helpers/renterHelper';
import { PhoneNumber } from 'shared/types/renterTypes';

import styles from '../PersonalProfile.module.scss';

type RenterContactInformationProps = {
  email: string;
  phoneNumbers: PhoneNumber[] | undefined;
};

const RenterContactInformation = ({ email, phoneNumbers }: RenterContactInformationProps): JSX.Element => (
  <div className={styles.container}>
    <div className={indexStyles.secondaryHeading}>{t('application.contact')}</div>
    <div className={styles.infoContainer}>
      <span className={styles.headingEmail}>{email}</span>
      <span className={styles.subHeading}>{t('renter.Ownerinformation.email')}</span>
    </div>
    {phoneNumbers &&
      phoneNumbers.map((phoneNumber, index) => (
        <div className={styles.infoContainer} key={`phone-${index}`}>
          <span className={styles.heading}>{formatPhoneNumber(phoneNumber.phoneNumber as string)}</span>
          <span className={styles.subHeading}>{`${t('renter.relationship.adultPhoneLabel')} ${index + 1}`}</span>
        </div>
      ))}
  </div>
);

export default RenterContactInformation;
