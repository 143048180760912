import { VerificationPDFPage } from 'pages/VerificationPDF/VerificationPDF';

import { IdvModal } from 'components/Agent/components/shared/IdvModal/IdvModal';
import { AdultInviteHandler } from 'components/Public/AdultInviteHandler/AdultInviteHandler';
import { AgentOrganizationInviteHandler } from 'components/Public/AgentOrganizationInviteHandler/AgentOrganizationInviteHandler';
import { EmployerConfirmation } from 'components/Public/EmploymentHistoryInformation/EmployerConfirmation/EmployerConfirmation';
import { EmploymentVerification } from 'components/Public/EmploymentHistoryInformation/EmploymentVerification/EmploymentVerification';
import { GuarantorInviteHandler } from 'components/Public/GuarantorInviteHandler/GuarantorInviteHandler';
import { RentalAddressHistory } from 'components/Public/RentalHistoryInformation';
import { RenterAddressNonVerifierInformation } from 'components/Public/RenterAddressNonVerifierInformation/RenterAddressNonVerifierInformation';
import { RenterInvitation } from 'components/Public/RenterInvitation/RenterInvitation';
import { publicRoutes } from 'shared/routes';

export const publicRoute = [
  {
    path: publicRoutes.renterAdultInvite,
    element: <AdultInviteHandler />,
  },
  {
    path: publicRoutes.renterGuarantorInvite,
    element: <GuarantorInviteHandler />,
  },
  {
    path: publicRoutes.addressHistoryRequestVerification,
    element: <RentalAddressHistory />,
  },
  {
    path: publicRoutes.verifierRedirectForm,
    element: <RenterAddressNonVerifierInformation />,
  },
  {
    path: publicRoutes.employmentHistoryRequestVerification,
    element: <EmploymentVerification />,
  },
  {
    path: publicRoutes.verifierEmploymentHistory,
    element: <EmployerConfirmation />,
  },
  {
    path: publicRoutes.agentOrganizationInvite,
    element: <AgentOrganizationInviteHandler />,
  },
  {
    path: publicRoutes.renterInvite,
    element: <RenterInvitation />,
  },
  {
    path: publicRoutes.identityVerification,
    element: <IdvModal />,
  },
  {
    path: publicRoutes.voepdf,
    element: <VerificationPDFPage />,
  },
];
