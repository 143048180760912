import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Formik, FormikErrors } from 'formik';
import { omit } from 'lodash-es';
import {
  applicantRenterRole,
  renterPriorEmployments,
  selectRenterProfileInformation,
} from 'redux/selectors/renterSelector';
import { createPresentEmployment } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterPresentEmploymentsSchema } from 'schema/renterSchema';

import { EmploymentSubTypes, NavigationKeys } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { tabTitle } from 'helpers/helper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { RenterEmploymentsInitialValue } from 'shared/types/renterEmploymentInformation';
import { StepProps } from 'shared/types/renterTypes';
import { RenterPresentEmployments } from 'shared/types/renterTypes';
import { formatDateForBackend } from 'shared/utils/formatDate';

import RenterContainer from '../../components/RenterContainer';
import RenterIconInfoContainer from '../../components/RenterIconInfoContainer';

import RenterEmployed from './PresentSourceOfIncomeForm/Employed';
import RenterEmployedTypeOther from './PresentSourceOfIncomeForm/EmploymentTypeOther';
import RenterSelfEmployed from './PresentSourceOfIncomeForm/SelfEmployed';
import TypeOfPresentSourceOfIncome from './TypeOfPresentSourceOfIncome/TypeOfPresentSourceOfIncome';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './RenterPresentSourceOfIncome.module.scss';

const RenterPresentSourceOfIncome = (): JSX.Element => {
  tabTitle('Your Application | Source of Income');
  const { applicationId } = useParams();
  const [currentStep, setCurrentStep] = useState('typeOfIncome');
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const maxHeight = useDeviceHeight(220);
  const [searchParams] = useSearchParams();
  const navigateNext = searchParams.get(NavigationKeys.NEXT);
  const renterRole = useSelector(applicantRenterRole);
  const { hasNoSSN } = useSelector(selectRenterProfileInformation);
  const priorEmployments = useSelector(renterPriorEmployments);
  const handleSubmit = useCallback(
    (values: RenterPresentEmployments, setErrors: (errors: FormikErrors<RenterPresentEmployments>) => void): void => {
      const newValues: RenterPresentEmployments = omit(values, 'jobEndDate');

      newValues.employmentSubType = EmploymentSubTypes[currentStep];

      if (EmploymentSubTypes[currentStep] !== 'others') {
        newValues.jobStartDate = formatDateForBackend(newValues.jobStartDate);
      }

      dispatch(createPresentEmployment({ values: newValues }))
        .unwrap()
        .then((response) => {
          const employmentId = response.presentEmployments.slice(-1)[0].id;

          navigate(
            hasNoSSN
              ? renterRoutes.generateRenterIncomeInformation(String(applicationId), employmentId, `${navigateNext}`) // Navigates to the next step i.e document upload page
              : renterRoutes.generateConnectBankDetails(applicationId, currentStep, employmentId, `${navigateNext}`)
          );
        })
        .catch((error) => {
          setErrors(error); // Sets form errors if an error occurs
        });
    },
    [applicationId, currentStep, dispatch, hasNoSSN, navigate, navigateNext]
  );

  useEffect(() => {
    if (renterRestrictions[renterRole].presentIncome) {
      if (!priorEmployments?.length) {
        navigate(renterRoutes.generateRenterPriorSourceOfIncome(applicationId));
      } else {
        navigate(renterRoutes.generateRenterPriorSourceOfIncomeSummary(applicationId));
      }
    }
  }, [applicationId, navigate, priorEmployments, renterRole]);
  const currentStepComponent: StepProps = {
    typeOfIncome: <TypeOfPresentSourceOfIncome handleTypeOfEmployment={(params: string) => setCurrentStep(params)} />,
    employed: <RenterEmployed />,
    selfEmployed: <RenterSelfEmployed />,
    other: <RenterEmployedTypeOther />,
  };

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div
          className={styles.renterPresentSourceOfIncome}
          style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
        >
          <div>
            <RenterIconInfoContainer />
          </div>
          <Formik
            initialValues={RenterEmploymentsInitialValue}
            enableReinitialize
            validationSchema={renterPresentEmploymentsSchema[currentStep]}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
          >
            {currentStepComponent[currentStep]}
          </Formik>
        </div>
      </section>
    </RenterContainer>
  );
};

export default RenterPresentSourceOfIncome;
