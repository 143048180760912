export const DUMMY_INTIAL_EMPLOYMENT_QUESTIONS = [
  {
    id: 1,
    question: 'Are the stated Dates of Employment accurate?',
    questionType: 'DATE',
    acceptedReason: 'yes',
  },
  {
    id: 2,
    question: 'Is the stated title for the Employee accurate?',
    questionType: 'TEXT',
    acceptedReason: 'yes',
  },
  {
    id: 3,
    question: 'Are there any other inaccuracies to report?',
    questionType: 'TEXT',
    acceptedReason: 'no',
  },
];
export const employmentRelationshipOptions = [
  { label: 'Superviser/Manager', value: ' – The Employee is a direct or indirect report.' },
  { label: 'Human Resources', value: ' – I am a department representative responsible for people-related concerns.' },
  { label: 'Other', value: ' (more detail required)', isItalic: true },
];

export const INITIAL_DEFAULT = {
  verificationQuestions: [],
  verifierType: '',
  submitVerifier: {
    phoneNumber: '',
    fullName: '',
    emailAddress: '',
    companyName: '',
    relationshipProperty: '',
    privacyConsent: false,
    titleOfVerifier: '',
  },
};
export enum SubmittionStatus {
  SUCCESS = 'Verification Submitted Successfully',
}
