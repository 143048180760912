import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { t } from 'i18next';

// import AgentPills, { Pill, PillTypes } from 'components/shared/AgentPills/AgentPills';
import { DescriptionContainer } from '../../../shared';

import styles from '../RenterPersonalProfile.module.scss';

type GuarantorDetailsProps = {
  contactFullName: string;
  relationship: string;
  email: string;
  phone: string;
};
type VerifierRow = {
  heading: string;
  data: string | undefined;
};

const GuarantorDetails = ({ contactFullName, relationship, email, phone }: GuarantorDetailsProps): JSX.Element => {
  const GuarantorFirstRow = useMemo(
    (): VerifierRow[] => [
      { heading: t('renter.relationship.fullNamePlaceHolder'), data: contactFullName },
      { heading: t('renter.paymentConfirmation.houseHoldDetails.relationship'), data: relationship },
    ],
    [contactFullName, relationship]
  );
  const GuarantorSecondRow = useMemo(
    (): VerifierRow[] => [
      { heading: t('application.email'), data: email },
      { heading: t('application.phone'), data: phone },
    ],
    [email, phone]
  );
  // const guarantorStatus: Pill[] = [{ heading: t('application.completed'), type: PillTypes.SUCCESS, isItalic: true }];

  return (
    <div className={styles.Container}>
      <div className={styles.AddressInformation}>
        <div>
          <Row className={styles.RowContainer}>
            {GuarantorFirstRow.map(({ heading, data }) =>
              data ? (
                <Col key={heading} sm={12} lg={4} md={4}>
                  <DescriptionContainer heading={heading} description={data} />
                </Col>
              ) : null
            )}
            <Col sm={12} lg={4} md={4} />
          </Row>
        </div>
        <div className={styles.PillsContainer}>{/* <AgentPills pillsData={guarantorStatus} /> */}</div>
        <div>
          <Row className={styles.RowContainer}>
            {GuarantorSecondRow.map(({ heading, data }) => (
              <Col key={heading} sm={12} lg={4} md={4}>
                <DescriptionContainer heading={heading} description={data} />
              </Col>
            ))}
            <Col sm={12} lg={4} md={4} />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default GuarantorDetails;
