//TODO: Temporarily disabled Stepper component feature and will re-enable later.
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { Col, Modal as BModal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { amenitiesList } from 'redux/selectors/propertySelector';
import { resetPropertyId, updateAmenitiesFeature } from 'redux/slices/propertySlice';

import Button from 'components/shared/Button/Button';
import Modal, { IModelProps } from 'components/shared/Modal/Modal';
// import Stepper from 'components/shared/Stepper/Stepper';
import { PropertySteps } from 'constants/propertyConstants';
import { PropertyProps } from 'shared/types/propertyType';

import styles from './AddProperty.module.scss';

export type IAddPropertyProps = IModelProps & {
  children: React.ReactElement;
  onMediaHide: () => void;
  setIsError: Dispatch<React.SetStateAction<boolean>>;
  setIsCustomUtilityClicked: Dispatch<React.SetStateAction<boolean>>;
};
const AddPropertyModal = ({
  children,
  setCurrentStep,
  currentStep,
  isError,
  setIsError,
  setIsCustomUtilityClicked,
  onMediaHide,
  ...props
}: IAddPropertyProps): JSX.Element => {
  const [willSaveORClose, setWillSaveORClose] = useState<boolean>(false);
  const { values, errors, setErrors, resetForm, setFieldValue, isValid, validateForm } =
    useFormikContext<PropertyProps>();
  const amenities = useSelector(amenitiesList);
  const dispatch = useDispatch();
  const isPropertyTypeAddressExist = values && !!values?.propertyType && !!values?.streetAddress1;
  const isAnyImageInLoadingState =
    values.propertyImages && !!values.propertyImages.length ? values.propertyImages.some((el) => el.isLoading) : false;
  const isPropertyTypeAndAddressAndTotalUnit =
    // if Property Type === Apartment then check the totalCount too
    values.propertyType === 'Apartment'
      ? isPropertyTypeAddressExist && !!values.totalUnits
      : isPropertyTypeAddressExist;
  const isButtonDisabled = useMemo(
    (): boolean => Object.keys(errors).length || isError || !isValid || (currentStep === 5 && isAnyImageInLoadingState),
    [currentStep, errors, isAnyImageInLoadingState, isError, isValid]
  );

  //To Validate the form on each step
  useEffect(() => {
    validateForm();
  }, [validateForm, currentStep]);
  useEffect(() => {
    if (values.propertyType === undefined) {
      values.allowedPets = [];
    }
  }, [values]);
  useEffect(() => {
    Object.values(values).forEach((val) => {
      if (val) {
        setWillSaveORClose(true);
      }
    });
  }, [values]);

  const publishProperty = (): void => {
    if (currentStep === 5) {
      values.propertyImages = [];
      onMediaHide();
      setCurrentStep((step: number) => step - 4);
      resetForm();

      dispatch(resetPropertyId());
      setFieldValue('allowedPets', []);
      // remove the preselected amenities
      dispatch(
        updateAmenitiesFeature(
          amenities.map((el) => ({
            ...el,
            isSelected: false,
            isAnimation: false,
            tempSelected: false,
          }))
        )
      );
      setIsError(true);
      setIsCustomUtilityClicked(false);

      return;
    }

    if (currentStep !== 5) {
      setCurrentStep((step: number) => step + 1);
    }
  };

  return (
    <Modal
      {...props}
      buttonText={willSaveORClose && isPropertyTypeAndAddressAndTotalUnit ? 'Save and Close' : 'Close'}
      isImagesLoading={currentStep === 5 && isAnyImageInLoadingState}
      size="lg"
      centered
    >
      <div className={styles.container}>
        <BModal.Body className={styles.bodyPadding}>{children}</BModal.Body>
        <div>
          <BModal.Footer>
            <Row className={styles.row}>
              <Col lg={6} md={12} sm={12}>
                {/* <Stepper currentStep={currentStep} setCurrentStep={setCurrentStep} /> */}
              </Col>
              <Col lg={6} md={12} sm={12} className={classNames(styles.buttonCol)}>
                {currentStep !== PropertySteps.basicInfo && (
                  <Button
                    variant="outline"
                    size="sm"
                    className={styles.backButton}
                    onClick={() => {
                      setCurrentStep((step: number) => step - 1);
                      setErrors({});
                    }}
                  >
                    Back
                  </Button>
                )}
                <Button
                  data-testid="modal-button"
                  onClick={publishProperty}
                  className={styles.button}
                  size="sm"
                  disabled={isButtonDisabled}
                >
                  {currentStep === 5 ? 'Publish' : 'Next'}
                </Button>
              </Col>
            </Row>
          </BModal.Footer>
        </div>
      </div>
    </Modal>
  );
};

export default AddPropertyModal;
