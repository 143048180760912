import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Stripe } from '@stripe/stripe-js';
import { AppThunkDispatch } from 'redux/store';

import Card from 'components/shared/CustomCard/Card';
import { handleStripeButtonClick, intializeStripeObject } from 'helpers/stripeHelper';

import { ContentBubble } from './ContentBubble/ContentBubble';
import { GetStartedIdvPage } from './GetStartedIdvPage/GetStartedIdvPage';
import { HeadingBubble } from './HeadingBubble/HeadingBubble';
import { IdvSuccessPage } from './IdvSuccessPage/IdvSuccessPage';

import styles from './IdvModal.module.scss';
export const IdvModal = (): JSX.Element => {
  const { organizationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isGetStartedPage, setIsGetStartedPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const { t } = useTranslation();
  const handleLoading = useCallback((shouldLoad: boolean) => setIsLoading(shouldLoad), []);
  const successCallBack = useCallback(() => {
    setIsGetStartedPage(false);
  }, []);
  const onGetStartedClickHandler = useCallback((): void => {
    if (!stripe) {
      return;
    }

    handleStripeButtonClick({
      handleLoading,
      stripe,
      successCallBack,
      dispatch,
      propertyId: null,
      organizationId: Number(organizationId),
    });
  }, [dispatch, handleLoading, organizationId, stripe, successCallBack]);
  const getContentComponent = useMemo(
    (): ReactNode =>
      isGetStartedPage ? (
        <GetStartedIdvPage isLoading={isLoading} onClickHandler={onGetStartedClickHandler} />
      ) : (
        <IdvSuccessPage />
      ),
    [isGetStartedPage, isLoading, onGetStartedClickHandler]
  );

  useEffect(() => intializeStripeObject({ setStripe }), []);

  return (
    <>
      <Helmet>
        <script src="https://js.stripe.com/v3" async></script>
      </Helmet>
      <Card parentClassName={styles.Container} className={styles.CardClass} lg={6} sm={12}>
        {isGetStartedPage && <HeadingBubble heading={t('agent.identityVerification')} />}
        <ContentBubble contentComponent={getContentComponent} />
      </Card>
    </>
  );
};
