import { Row } from 'react-bootstrap';
import classNames from 'classnames';

import styles from './../PropertyIndex.module.scss';

export interface PropertyTitleProps {
  type: string;
  activeCount: number;
  houseHoldCount: number;
}

const PropertyActiveHousehold = ({ type, activeCount, houseHoldCount }: PropertyTitleProps): JSX.Element => (
  <Row
    className={classNames(styles.propertyActiveHouseHold, type === 'row' ? styles.houseHoldRow : styles.houseHoldCol)}
  >
    <div className={styles.active}>{activeCount} Active</div>
    <div className={styles.households}>{houseHoldCount} HOUSEHOLDS</div>
  </Row>
);

export default PropertyActiveHousehold;
