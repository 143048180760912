import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { omit } from 'lodash-es';

import { InviteTypes, organizationAPI } from 'constants/organizationConstants';
import { handleServerResponseError, parseResponseErrors } from 'helpers/helper';
import * as organizationService from 'services/organizationService';
import { Notification } from 'shared/Notification/Notification';
import { OrganizationInviteLinkParams } from 'shared/types/agentInvite';
import {
  Invite,
  InviteType,
  MembersRetrievalParams,
  OrganizationProps,
  PropertySpecificInvite,
  PropertySpecificInvites,
  UserInformation,
} from 'shared/types/organizationTypes';

interface OrganizationInterface {
  organizations: OrganizationProps[];
  isLoading: boolean;
  isSaving: boolean;
  selectedOrganization: OrganizationProps;
  inviteId: number | null;
  organizationMembers: UserInformation[];
  invites: InviteType;
}

export const initialState: OrganizationInterface = {
  organizations: [],
  isLoading: false,
  isSaving: false,
  selectedOrganization: {} as OrganizationProps,
  inviteId: null,
  organizationMembers: [],
  invites: {} as InviteType,
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setOrganizationMembers: (state, action) => {
      state.organizationMembers = action.payload;
    },
    resetInviteId: (state) => {
      state.inviteId = null;
    },
    resetInvites: (state) => {
      state.invites = {} as InviteType;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgentOrganizations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAgentOrganizations.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.organizations = payload;
      })
      .addCase(getAgentOrganizations.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getLastVisitedOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLastVisitedOrganization.fulfilled, (state, { payload }) => {
        state.selectedOrganization = payload;
        state.isLoading = false;
      })
      .addCase(getLastVisitedOrganization.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(generateOrganizationMemberInvite.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(generateOrganizationMemberInvite.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        state.inviteId = payload.inviteId;
      })
      .addCase(generateOrganizationMemberInvite.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(getOrganizationMembers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrganizationMembers.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getOrganizationMembers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getInvites.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInvites.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const agentSpecificInvites = [] as Invite[];
        const propertySpecificInvites = [] as PropertySpecificInvites[];

        payload.map((invite: Invite) => {
          if (invite.type === InviteTypes.AGENT_SPECIFIC) {
            agentSpecificInvites.push(invite);
          } else if (invite.type === InviteTypes.PROPERTY_SPECIFIC) {
            const propertyIndex = propertySpecificInvites.findIndex(
              (p) => p.property.propertyId === invite.propertyDetails?.propertyId
            );
            const newValues: Invite = omit(invite, 'propertyDetails');

            if (propertyIndex > -1) {
              propertySpecificInvites[propertyIndex].invites.push(newValues as PropertySpecificInvite);
            } else {
              invite.propertyDetails &&
                propertySpecificInvites.push({
                  property: {
                    ...invite.propertyDetails,
                    agentFirstName: invite.agentFirstName,
                    agentLastName: invite.agentLastName,
                  },
                  invites: [newValues as PropertySpecificInvite],
                });
            }
          }
        });
        state.invites = { agentSpecificInvites, propertySpecificInvites };
      })
      .addCase(getInvites.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const getAgentOrganizations = createAsyncThunk(organizationAPI.getOrganizations, async (_, { dispatch }) => {
  try {
    const {
      data: { payload },
    }: AxiosResponse = await organizationService.getOrganizations();

    if (payload && payload.length > 0) {
      const firstOrganization = payload[0];

      dispatch(setOrganization(firstOrganization));
    }

    return payload;
  } catch (error) {
    handleServerResponseError({ error, dispatch });

    if (error instanceof AxiosError && error.response) {
      Notification({ message: parseResponseErrors(error) });

      return Promise.reject();
    }
  }
});
export const getLastVisitedOrganization = createAsyncThunk(
  organizationAPI.switchOrganization,
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await organizationService.switchOrganization(id);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(parseResponseErrors(error));
      }
    }
  }
);
export const generateOrganizationMemberInvite = createAsyncThunk(
  organizationAPI.generateOrganizationMemberInvite,
  async (values: OrganizationInviteLinkParams, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await organizationService.generateOrganizationMemberInvite(values);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.errors[0]);
      }

      return Promise.reject();
    }
  }
);

export const getOrganizationMembers = createAsyncThunk(
  organizationAPI.organizationMembers,
  async ({ id, sortBy, sortType, filter, target }: MembersRetrievalParams, { rejectWithValue, dispatch }) => {
    try {
      if (id) {
        const {
          data: { payload },
        }: AxiosResponse = await organizationService.getOrganizationMembers({
          id,
          sortBy,
          sortType,
          filter,
          target,
        });

        return payload;
      }
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(parseResponseErrors(error));
      }
    }
  }
);
export const getInvites = createAsyncThunk(
  organizationAPI.organizationInvites,
  async (organizationId: number, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await organizationService.getInvites(organizationId);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(parseResponseErrors(error));
      }
    }
  }
);
export const { setOrganization, resetInviteId, setOrganizationMembers, resetInvites } = slice.actions;
export const { reducer } = slice;
