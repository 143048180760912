import { useTranslation } from 'react-i18next';

import styles from '../PropertyDetails.module.scss';

export const AvailableTag = ({ dateAvailable }: { dateAvailable: string }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.AvailableContainer}>
      <div className={styles.AvailableLabel}>{t('agent.applications.property.available')}</div>

      <div className={styles.AvailableValue}>
        {new Date(dateAvailable || '').toLocaleDateString() === new Date().toLocaleDateString()
          ? t('application.propertyHeader.now')
          : dateAvailable}
      </div>
    </div>
  );
};
