import React from 'react';
import BootstrapButton, { ButtonProps } from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classnames from 'classnames';

import styles from './Button.module.scss';

interface RCButtonProps extends ButtonProps {
  shouldShowTooltip: boolean;
  tooltipText: string;
}

const ButtonWithTooltip: React.FC<RCButtonProps> = ({
  children,
  className,
  shouldShowTooltip,
  tooltipText,
  ...props
}) => {
  const button = (
    <BootstrapButton {...props} bsPrefix="ir-btn" className={classnames(styles.irBtn, className)}>
      {children}
    </BootstrapButton>
  );

  if (shouldShowTooltip) {
    return (
      <OverlayTrigger placement="top" overlay={<Tooltip>{tooltipText}</Tooltip>}>
        {button}
      </OverlayTrigger>
    );
  }

  return button;
};

export default ButtonWithTooltip;
