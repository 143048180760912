import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { renterAnimals } from 'redux/selectors/renterSelector';
import { renterAnimalSchema } from 'schema/renterSchema';

import { ReactComponent as PetIcon } from 'assets/svgs/petsIconOneGrayPaw.svg';
import styles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import Title from 'components/Renter/IdentityVerification/RenterDetails/shared/Title/Title';
import { PaymentVerificationKeys } from 'constants/renterConstants';
import { renterRoutes } from 'shared/routes';
import { AnimalInformationInitialValues, NextRouteProp } from 'shared/types/renterTypes';

import AnimalCards from './AnimalCards/AnimalCards';
import AnimalInformationTooltip from './AnimalsInformationTooltip/AnimalInformationTooltip';

const AnimalsInformation = ({ next }: NextRouteProp): JSX.Element => {
  const { t } = useTranslation();
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const navigate = useNavigate();
  const renterAnimalsInformation = useSelector(renterAnimals);
  const handleAddInfo = (): void => {
    navigate(
      renterRoutes.generateRenterPetsInformation(
        currentApplicationId,
        next ?? renterRoutes.generateRenterPaymentVerification(currentApplicationId),
        PaymentVerificationKeys.ANIMAL_INFORMATION
      )
    );
  };

  if (!renterAnimalsInformation?.length) {
    return (
      <div id={PaymentVerificationKeys.ANIMAL_INFORMATION}>
        <Title
          mainTitle={t('renter.paymentConfirmation.animals.title')}
          iconElement={<PetIcon />}
          shouldShowOptions
          optionElement={
            <AnimalInformationTooltip animalInformation={renterAnimalsInformation} handleAddInfo={handleAddInfo} />
          }
        />
        <Title mainTitle={t('renter.paymentConfirmation.animals.noAnimals')} />
      </div>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={AnimalInformationInitialValues}
      validationSchema={renterAnimalSchema}
      onSubmit={() => {
        console.log('formik values');
      }}
    >
      <div className={styles.container}>
        <AnimalCards />
      </div>
    </Formik>
  );
};

export default AnimalsInformation;
