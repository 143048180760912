import styles from './ColoredCircularPill.module.scss';
export interface ColoredCircularPillProps {
  color: string;
  width?: number;
  height?: number;
}
//This component will make a colored circle of height and width given to it
//When rendered it looks like a colored circular pill
const ColoredCircularPill = ({ color, width = 11, height = 11 }: ColoredCircularPillProps): JSX.Element => (
  <div
    className={styles.CircularPill}
    style={{
      backgroundColor: color,
      width: width,
      height: height,
    }}
  />
);

export default ColoredCircularPill;
