import { useMemo } from 'react';
import { t } from 'i18next';

import { formatDate } from 'helpers/user';
import { AddressInformation } from 'shared/types/renterTypes';

import EditPriorAddressToolTip from '../EditPriorAddressToolTip/EditPriorAddressToolTip';

import styles from './DescriptionCard.module.scss';

type DescriptionCardProps = {
  handleEditAddress: (address: AddressInformation) => void;
  handleEditVerifier: (address: AddressInformation) => void;
  address: AddressInformation;
};

const DescriptionCard = ({ address, handleEditAddress, handleEditVerifier }: DescriptionCardProps): JSX.Element => {
  const isRented = useMemo(() => {
    if (address && address.emailAddress && address.fullName && address.phoneNumber) {
      return true;
    }

    return false;
  }, [address]);

  return (
    <div>
      <div className={styles.DescriptionCardContainer}>
        <div className={styles.heading}>
          <span>{address?.streetAddress}</span>
          <div className={styles.toolTipContainer}>
            <span className={styles.pill}>
              {isRented ? t('renter.ConfirmPresentAddress.iRented') : t('renter.ConfirmPresentAddress.iDoNotRent')}
            </span>
            <span>
              <EditPriorAddressToolTip
                handleEditVerifier={handleEditVerifier}
                handleEditAddress={handleEditAddress}
                currentAddress={address}
              />
            </span>
          </div>
        </div>
        {address?.city && (
          <div className={styles.subHeading}>{`${address?.city}, ${address?.state} ${address?.zipCode}`}</div>
        )}
        <div className={styles.date}>
          {formatDate(address?.fromDate ?? '')} - {formatDate(address?.toDate ?? '')}
        </div>
        <div className={styles.addressType}>{t('application.residency')}</div>
      </div>
    </div>
  );
};

export default DescriptionCard;
