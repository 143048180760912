import { ChangeEvent, useCallback, useState } from 'react';
import { FloatingLabel } from 'react-bootstrap';
import classNames from 'classnames';
import { Field, useField } from 'formik';
import { t } from 'i18next';

import CustomErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import TextField from 'components/shared/TextField/TextField';
import { SSN_PLACEHOLDER_TEXT } from 'constants/agentConstants';
import { formatSSN } from 'helpers/renterHelper';

import styles from './AgentSSNCheckerInput.module.scss';

const AgentSSNCheckerInput = (): JSX.Element => {
  const [, meta, helpers] = useField({ name: 'socialSecurityNumber' });
  const [blurred, setBlurred] = useState(false);
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      //formatting the value to SSN format
      helpers.setValue(formatSSN(event.target.value));
      setBlurred(false);
    },
    [helpers]
  );
  const handleInputBlur = (): void => {
    setBlurred(true);
    helpers.setTouched(true);
  };

  return (
    <>
      <div className={styles.AgentSSNInputWrapper}>
        <FloatingLabel controlId={t('agent.profile.SSN.labelText')} label={t('agent.profile.SSN.labelText')}>
          <Field
            as={TextField}
            className={`${classNames(styles.SsnTextField, blurred && meta.error && styles.Error)} fs-mask`}
            name="agentSSN"
            value={meta.value}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            placeholder={SSN_PLACEHOLDER_TEXT}
          />
        </FloatingLabel>
      </div>
      {blurred && !!meta.error && <CustomErrorMessage message={meta.error} />}
    </>
  );
};

export default AgentSSNCheckerInput;
