export enum ApplicationPillsKeys {
  NEW = 'New',
  PENDING = 'Pending',
}

export enum ApplicationRequestValues {
  APPLICATION_AND_REPORTS = 'application_reports',
  REPORTS_ONLY = 'reports_only',
  VERIFY_IDENTITY = 'verify_identity',
}
