import { useCallback, useState } from 'react';
import { t } from 'i18next';

import { ReactComponent as CopyIcon } from 'assets/svgs/CopyIcon.svg';
import ButtonWithTooltip from 'components/shared/Button/ButtonWithTooltip';
import Spinner from 'components/shared/Spinner/Spinner';

import styles from './TextCopyButton.module.scss';

type TextCopyButtonProps = {
  text: string;
  tooltipText?: string;
  isLoading?: boolean;
  buttonText?: string;
};

const TextCopyButton = ({
  text,
  tooltipText,
  isLoading = false,
  buttonText = t('agent.inviteApplicant.copy'),
}: TextCopyButtonProps): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false);
  const onButtonClick = useCallback(() => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((error) => {
        console.error(t('agent.inviteApplicant.copy.error'), error);
      });
  }, [text]);

  return (
    <div className={styles.container}>
      <input className={styles.text} type="text" value={text} disabled />
      <div className={styles.buttonContainer}>
        <ButtonWithTooltip
          className={styles.button}
          shouldShowTooltip
          variant="outline"
          tooltipText={tooltipText ?? t('agent.inviteApplicant.copy')}
          onClick={onButtonClick}
          disabled={isCopied || isLoading || !text.length}
        >
          <div className={styles.buttonLoader}>{isLoading ? <Spinner /> : <CopyIcon />}</div>
          {buttonText && (isCopied ? t('agent.inviteApplicant.copied') : t('agent.inviteApplicant.copy'))}
        </ButtonWithTooltip>
      </div>
    </div>
  );
};

export default TextCopyButton;
