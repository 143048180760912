import { Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';
import Drawer from 'react-modern-drawer';
import classNames from 'classnames';

import { ReactComponent as ArrowHead } from 'assets/svgs/EncircledArrowHead.svg';
import ResumeSideMenu from 'components/shared/ResumeSideMenu/ResumeSideMenu';
import { RenterResumeNavbarKeys } from 'constants/agentConstants';
import { MOBILE_VIEW_WIDTH } from 'constants/organizationConstants';
import { InitialSideMenuData } from 'constants/resumeSideMenuConstants';
import { Size, useWindowSize } from 'hooks/useResize';

import styles from './ResumeDrawer.module.scss';

export interface OrganizationDrawerProps {
  contentComponent: ReactNode;
  setIsDrawerOpen?: Dispatch<SetStateAction<boolean>>;
}

export const ResumeDrawer = ({
  setIsDrawerOpen,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  contentComponent: ContentComponent,
}: OrganizationDrawerProps): JSX.Element => {
  const size: Size = useWindowSize();
  const [isTriggered, setIsTriggered] = useState(false);
  const onClickHandler = useCallback(() => {
    setIsTriggered(!isTriggered);

    if (setIsDrawerOpen) {
      setIsDrawerOpen(!isTriggered);
    }
  }, [isTriggered, setIsDrawerOpen]);

  // const onMouseEnterHandler = useCallback(() => {
  //   setIsTriggered(true);
  // }, []);

  return (
    <div
      className={classNames(styles.MainContainer, {
        [styles.MobileMainContainer]: size.width <= MOBILE_VIEW_WIDTH,
      })}
    >
      <div className={styles.SideBar}>
        {/* <ArrowHead className={styles.RightArrowIcon} onClick={onClickHandler} /> */}
        <Drawer
          enableOverlay={window.innerWidth <= MOBILE_VIEW_WIDTH}
          open={isTriggered}
          direction="left"
          className={styles.DrawerClass}
        >
          <ResumeSideMenu menuList={InitialSideMenuData} selectedOption={RenterResumeNavbarKeys.APPLICATION} />
          <ArrowHead className={styles.LeftArrowIcon} onClick={onClickHandler} />
        </Drawer>
      </div>
      <div
        className={classNames(
          styles.PageContent,
          { [styles.PageContentUnselected]: !isTriggered },
          {
            [styles.PageOverLay]: window.innerWidth < MOBILE_VIEW_WIDTH && isTriggered,
          }
        )}
      >
        {ContentComponent}
      </div>
    </div>
  );
};
