import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isAuthenticated } from 'redux/selectors/authSelector';
import { getAgentOrganizationInvites, setInviteCode } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';

import { PublicSearchParams } from 'constants/routeConstants';
import { acceptAgentOrganizationInviteHanlder } from 'helpers/organizationHelper';
import { clearAllInvitesDetails } from 'helpers/publicHelper';
import { routes } from 'shared/routes';
import { AgentOrganizationInvitesProps } from 'shared/types/organizationTypes';

export const AgentOrganizationInviteHandler = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get(PublicSearchParams.INVITE_CODE);
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const isUserAuthenticated = useSelector(isAuthenticated);

  useEffect(() => {
    if (inviteCode) {
      if (!isUserAuthenticated) {
        clearAllInvitesDetails({ dispatch: dispatch });
        dispatch(getAgentOrganizationInvites(inviteCode))
          .unwrap()
          .then((res: AgentOrganizationInvitesProps) => {
            dispatch(setInviteCode(inviteCode));
            navigate(res.invitedAgentExists ? routes.login : routes.signup);
          });
      } else {
        acceptAgentOrganizationInviteHanlder({ inviteCode, dispatch, navigate });
      }
    }
  }, [dispatch, inviteCode, isUserAuthenticated, navigate]);

  return <Spinner animation="border" />;
};
