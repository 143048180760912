import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  selectCurrentProperty,
  selectedRenterDetails,
  selectRenterCreditProfile,
  selectRenterEvictionReport,
} from 'redux/selectors/agentSelector';
import {
  applicationProperties,
  selectRenterCreditProfile as selectRenterCreditProfileInformation,
  selectRenterEvictionReport as selectRenterEvictionReportInformation,
  selectRenterProfileInformation,
} from 'redux/selectors/renterSelector';

import { ReactComponent as ArrowIcon } from 'assets/svgs/BlackDownArrow.svg';
import { BackgroundDataTypes } from 'constants/agentConstants';
import { ReportErrorTypes } from 'constants/reportConstants';
import { formatDateToApplicantDOBFormat } from 'helpers/applicantsHelper';

import { EmptyBackgroundReport } from '../EmptyBackgroundReport/EmptyBackgroundReport';
import { ReportErrorMessage } from '../ReportErrorMessage/ReportErrorMessage';
import { ReportHeading } from '../ReportHeading/ReportHeading';
import { ReportStatusLoader } from '../ReportStatusLoader/ReportStatusLoader';
import { EvictionReport } from '../SharedReports/EvictionReport/EvictionReport';

import styles from './HousingCourtRecords.module.scss';

export const HousingCourtRecords = ({ isRenterFlow = false }: { isRenterFlow?: boolean }): JSX.Element => {
  const [isReportExpanded, setIsReportExpanded] = useState(true);
  const { t } = useTranslation();
  const renterDetails = useSelector(selectedRenterDetails);
  const renterFlowRenterDetails = useSelector(selectRenterProfileInformation);
  const renterFlowPropertyInformation = useSelector(applicationProperties);
  const agentPropertyInformation = useSelector(selectCurrentProperty);
  const creditProfile = useSelector(isRenterFlow ? selectRenterCreditProfileInformation : selectRenterCreditProfile);
  const housingRecordsInformation = useSelector(
    isRenterFlow ? selectRenterEvictionReportInformation : selectRenterEvictionReport
  );
  const orderResponse = housingRecordsInformation?.response?.XML2025?.OrderResponse;
  const evictionReportResponse = housingRecordsInformation?.response?.XML2025?.Response;
  const isEvictionReportResponse = Number(housingRecordsInformation?.response?.XML2025?.Response?.Count) > 0;

  if (housingRecordsInformation.success === null) {
    return <Spinner />;
  }

  return (
    <div className={styles.Container}>
      {!isRenterFlow && (
        <div className={styles.IconContainer}>
          <ArrowIcon
            onClick={() => setIsReportExpanded(!isReportExpanded)}
            className={classNames(styles.ArrowIcon, { [styles.ArrowIconExpanded]: isReportExpanded })}
          />
          <ReportHeading
            heading={t('agent.creditReport.housingCourtRecords')}
            hasCounter={!!housingRecordsInformation?.response?.XML2025?.Response?.Count}
            counter={Number(housingRecordsInformation?.response?.XML2025?.Response?.Count)}
            customHeadingClass={styles.Heading}
          />
        </div>
      )}
      {!housingRecordsInformation.success ? (
        <div className={styles.ErrorContainer}>
          <ReportStatusLoader
            errorCode={housingRecordsInformation?.error?.Code}
            errorMessage={housingRecordsInformation?.error?.Message ?? ''}
            errorType={ReportErrorTypes.ERROR}
          />
        </div>
      ) : (
        isReportExpanded &&
        (isEvictionReportResponse ? (
          <div className={styles.ExpandedInforamtion}>
            {orderResponse && evictionReportResponse ? (
              isEvictionReportResponse && (
                <EvictionReport
                  isRenterFlow={isRenterFlow}
                  orderResponse={orderResponse}
                  evictionReportResponse={evictionReportResponse}
                />
              )
            ) : (
              <div className={styles.ErrorContainer}>
                <ReportErrorMessage errorType={ReportErrorTypes.ERROR} />
              </div>
            )}
          </div>
        ) : (
          <EmptyBackgroundReport
            reportDate={creditProfile?.report?.Header?.ReportDate ?? '____'}
            reportId={housingRecordsInformation?.response?.XML2025?.OrderResponse?.ResponseID ?? '____'}
            fullName={
              `${creditProfile?.report?.ConsumerIdentity?.[0]?.Name?.First} ${creditProfile?.report?.ConsumerIdentity?.[0]?.Name?.Surname}` ??
              '____'
            }
            applyingZip={
              isRenterFlow
                ? renterFlowPropertyInformation?.zipCode ?? '____'
                : agentPropertyInformation?.zipCode ?? '____'
            }
            dateOfBirth={
              isRenterFlow
                ? formatDateToApplicantDOBFormat(renterFlowRenterDetails?.birthDate)
                : formatDateToApplicantDOBFormat(renterDetails?.birthDate) ?? '____'
            }
            page={BackgroundDataTypes.HOUSING}
          />
        ))
      )}
    </div>
  );
};
