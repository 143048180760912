//TODO: Temporarily disabled CheckBox component feature and dependant imports, will re-enable later.
// import classNames from 'classnames';

import PropertyTest from 'assets/images/defaultImage.svg';

// import Checkbox from 'components/shared/Checkbox/Checkbox';
import styles from './../PropertyIndex.module.scss';

const PropertyImage = ({ fileLink }: { fileLink: string | undefined }): JSX.Element => (
  <div className={styles.imageContainer}>
    {/* <Checkbox
      data-testid="imgSelectCheckbox"
      className={classNames(styles.propertyCheckbox, styles.textAlignCenter, { [styles.noImage]: !fileLink })}
    /> */}
    <img className={styles.propertyImage} src={fileLink || PropertyTest} alt="PropertyImage" />
  </div>
);

export default PropertyImage;
