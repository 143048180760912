import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { relationshipOptions } from 'constants/agentConstants';
import { employmentRelationshipOptions } from 'constants/publicConstants';
import { SubmitVerifier, VerifierTypes } from 'shared/types/renterTypes';

import { PDFCheckBox } from '../PDFCheckbox/PDFCheckbox';
import { PDFValueField } from '../PDFValueField/PDFValueField';

const styles = StyleSheet.create({
  Container: {
    marginTop: '20px',
    gap: '12px',
    marginBottom: '24px',
  },
  Heading: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 600,
    paddingBottom: '5px',
  },
  RowContainer: {
    width: '100%',
    gap: '10px',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  CheckBoxContainer: {
    gap: '8px',
    flexDirection: 'row',
    width: '100%',
  },
  InformationalText: {
    color: '#4D4D4D',
    fontSize: '11px',
  },
});

interface Props {
  submitVerifier?: SubmitVerifier;
  verifierType: VerifierTypes;
}

export const PDFCompletedBy = ({ submitVerifier, verifierType }: Props): JSX.Element => {
  const options = verifierType === VerifierTypes.ADDRESS ? relationshipOptions : employmentRelationshipOptions;
  const relation = options.find((val) => val.label === submitVerifier?.relationshipProperty);

  return (
    <View wrap={false} style={styles.Container}>
      <Text style={styles.Heading}>Completed By</Text>
      <PDFValueField label="Full Name" value={submitVerifier?.fullName} />
      <View style={styles.RowContainer}>
        {submitVerifier?.companyName && <PDFValueField label="Company" value={submitVerifier.companyName} />}
        {submitVerifier?.titleOfVerifier && <PDFValueField label="Title" value={submitVerifier?.titleOfVerifier} />}
      </View>
      <View style={styles.RowContainer}>
        <PDFValueField label="Email" value={submitVerifier?.emailAddress} />
        <PDFValueField label="Phone" value={submitVerifier?.phoneNumber} />
      </View>
      {submitVerifier?.relationshipProperty && (
        <PDFValueField
          label={verifierType === VerifierTypes.ADDRESS ? 'Relation to rental property' : 'Relation to Employer'}
          value={`${relation?.label} ${relation?.value}`}
        />
      )}
      <View style={styles.CheckBoxContainer}>
        <PDFCheckBox isChecked={false} />
        <Text style={styles.InformationalText}>
          I certify to be the authorized contact for the requested verification, all information provided is accurate,
          and I have received the Intellirent Privacy Policy.
        </Text>
      </View>
    </View>
  );
};
