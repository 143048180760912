import { PropertyType } from 'shared/types/propertType';
import { PropertyProps } from 'shared/types/propertyType';
import { getTimeDifferenceString } from 'shared/utils/formatDate';

import styles from './../PropertyIndex.module.scss';

export interface PropertyCreationProps {
  status: string | undefined;
}

const PropertyCreation = ({ property }: { property: PropertyProps }): JSX.Element => (
  <span className={styles.propertyText}>
    {(property.propertyStatus === PropertyType.DRAFT &&
      `Modified ${getTimeDifferenceString(property.updatedAt || property.createdAt || new Date())}`) ||
      (property.propertyStatus === PropertyType.PUBLISHED &&
        `Published ${getTimeDifferenceString(property.publishedAt || property.createdAt || new Date())}`) ||
      (property.propertyStatus === PropertyType.ARCHIVED &&
        `Archived ${getTimeDifferenceString(property.archivedAt || property.createdAt || new Date())}`)}
  </span>
);

export default PropertyCreation;
