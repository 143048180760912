import { useTranslation } from 'react-i18next';

import { ReactComponent as PawIcon } from 'assets/svgs/petsIcon.svg';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { AnimalCapsuleProps } from 'shared/types/applicantsType';

import styles from './AnimalCapsule.module.scss';

export const AnimalCapsule = ({ petsCount, petsAddedText }: AnimalCapsuleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.CapsuleContainer}>
      <div className={styles.AnimalInformation}>
        <Tooltip
          placement="top"
          text={petsAddedText ?? ''}
          icon={<div className={styles.AnimalCount}>{petsCount}</div>}
          customIconClassName={styles.CountContainer}
        />

        {/**This code below will be used when we know how to check if its for service or not */}

        {/* <AgentPills
        pillContainerClassName={styles.PillContainer}
        pillsData={[{ heading: 'Service', type: PillTypes.SECONDARY }]}
      /> */}
      </div>
      <div className={styles.LabelContainer}>
        <PawIcon />
        <div className={styles.CapsuleLabel}>{t('renter.paymentConfirmation.animals.title')}</div>

        {/**This code below will be used when we know how to check if the form needs review or not */}

        {/* <AgentPills
        pillContainerClassName={styles.PillContainer}
        pillsData={[{ heading: 'NEED REVIEW', type: PillTypes.WARNING }]}
      /> */}
      </div>
    </div>
  );
};
