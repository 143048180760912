import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { creditReportCriteria } from 'constants/agentConstants';
import { vantageScoreReportLabelsFirst, vantageScoreReportLabelsSecond } from 'constants/reportConstants';
import { convertResponseToObject, getValueFormated, isEven } from 'helpers/reportsHelper';
import { VantageScoreReportItem } from 'shared/types/reportTypes';

import styles from './VantageScoreReport.module.scss';
export const VantageScoreReport = ({
  vantageScoreReport,
}: {
  vantageScoreReport: VantageScoreReportItem;
}): JSX.Element => {
  const convertedVantageScore = convertResponseToObject(vantageScoreReport);
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.InformationContainer}>
        <div className={styles.VantageScoreContainer}>
          <div className={styles.VantageHeading}>{t('agent.creditReport.vantageScore')}</div>
          <div className={styles.VantageScore}>{getValueFormated(convertedVantageScore['Score'])}</div>
        </div>
        <div className={styles.ScoreFactorsContainer}>
          <div className={styles.FactorHeading}>{t('agent.creditReport.scoreFactors')}</div>
          <div className={styles.ScoreMainContainer}>
            <div className={styles.ScoresContainer}>
              {vantageScoreReportLabelsFirst.map(
                (label, index) =>
                  getValueFormated(convertedVantageScore[label]) !== '—' && (
                    <div className={styles.ScoresValueContainer} key={`vintage-score-item-${index}`}>
                      <div
                        key={`vantage-report-score-${label}-${convertedVantageScore[label]}-${index}`}
                        className={styles.Score}
                      >
                        {getValueFormated(convertedVantageScore[label])}
                      </div>
                      <div className={classNames(styles.ScoreValue, { [styles.ScoreValueHighlighted]: isEven(index) })}>
                        {t(creditReportCriteria[getValueFormated(convertedVantageScore[label])])}
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className={styles.ScoresContainer}>
              {vantageScoreReportLabelsSecond.map(
                (label, index) =>
                  getValueFormated(convertedVantageScore[label]) !== '—' && (
                    <div className={styles.ScoresValueContainer} key={`vintage-score-item-${index}`}>
                      <div
                        key={`vantage-report-score-${label}-${convertedVantageScore[label]}-${index}`}
                        className={styles.Score}
                      >
                        {getValueFormated(convertedVantageScore[label])}
                      </div>
                      <div className={classNames(styles.ScoreValue, { [styles.ScoreValueHighlighted]: isEven(index) })}>
                        {t(creditReportCriteria[getValueFormated(convertedVantageScore[label])])}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
