import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Formik } from 'formik';
import { selectRenterApplication } from 'redux/selectors/agentSelector';

import { ReactComponent as QuestionAnswerIcon } from 'assets/svgs/QuestionAnswer.svg';
import styles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import AgentPills, { Pill, PillTypes } from 'components/shared/AgentPills/AgentPills';
import { MOBILE_VIEW_WIDTH } from 'constants/organizationConstants';
import { QuestionAnswerKey } from 'constants/renterConstants';
import { Size, useWindowSize } from 'hooks/useResize';

import { SectionTitle } from '../shared';

import RenterQuestionCard from './RenterQuestionCard/RenterQuestionCard';

const RenterQuestions = (): JSX.Element => {
  const { backgroundInformation } = useSelector(selectRenterApplication);
  const { t } = useTranslation();
  const size: Size = useWindowSize();
  const datePills: Pill[] = [
    {
      heading: <div className={styles.PillsSizeContainer}>{t('application.warning')}</div>,
      type: PillTypes.DANGER,
    },
  ];

  return (
    <Formik initialValues={backgroundInformation} onSubmit={() => console.log()} enableReinitialize>
      {({ values }) => (
        <div className={classNames(styles.container, styles.ApplicationDetails)}>
          <div className={styles.infoDiv}>
            <SectionTitle
              mainTitle={t('renter.BackgroundInformation.backgroundHeading')}
              iconElement={<QuestionAnswerIcon height={24} width={24} />}
              description={t('renter.BackgroundInformation.headingTitle')}
              // optionElement={<ThreeDotsIcon width={24} height={24} />}
              shouldShowOptions
              shouldDrawLine
            />
            <div
              className={classNames(styles.BackgroundQuestionContainer, {
                [styles.BackgroundQuestionContainerMobile]: size.width <= MOBILE_VIEW_WIDTH,
              })}
            >
              {values?.map((question, index) => (
                <Col key={`renter-resume-background-question-${index}`}>
                  <div className={classNames(styles.BackgroundQuestionContainer, styles.PillGapContainer)}>
                    <span className={styles.subTitle}>
                      {`${t('renter.BackgroundInformation.headingTitleShort')} ${index + 1}`}
                    </span>
                    {question.answer === QuestionAnswerKey.YES && <AgentPills pillsData={datePills} />}
                  </div>
                  <RenterQuestionCard question={question} />
                </Col>
              ))}
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default RenterQuestions;
