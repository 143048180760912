import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import placeholderImage from 'assets/svgs/PlaceholderRound.svg';

import styles from './ContentBubble.module.scss';

export const ContentBubble = ({ contentComponent }: { contentComponent: ReactNode }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      {contentComponent}
      <div className={styles.FooterContainer}>
        <img alt={t('imageAlt.Profile')} src={placeholderImage} />
        <div className={styles.AgentInformation}>
          <div className={styles.AgentName}>Lynn Blake</div>
          <div className={styles.AgentLabel}>{t('agent')}</div>
        </div>
      </div>
    </div>
  );
};
