import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import { PlacesAutocompleteComponent } from 'components/shared/PlacesAutoCompleteComponent/PlacesAutoCompleteComponent';
import { defaultDate, NavigationKeys, RenterAddressSteps, RenterAddressTypes } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { ADDRESS_FORMAT_INDEX, ConfirmAddressProps } from 'helpers/address';
import { formatAddressForFrontEnd } from 'helpers/renterHelper';
import { renterRoutes } from 'shared/routes';
import { AddressInformation, AddressInitialValuesProps } from 'shared/types/renterTypes';

import PriorAddress from '../PriorAddress/PriorAddress';
import RenterIconInfoContainer from '../RenterIconInfoContainer';
import RenterOwnerInformation from '../RenterOwnerInformation/RenterOwnerInformation';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './ConfirmPriorAddress.module.scss';

const ConfirmPriorAddress = ({ isRenting, setIsRenting }: ConfirmAddressProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isError, setIsError] = useState(false);
  const [searchParams] = useSearchParams();
  const [shouldClassify, setShouldClassify] = useState(false);
  const { userId, hasNoPriorAddress, addresses } = useSelector(selectRenterProfileInformation);
  const nextStep = searchParams.get(NavigationKeys.NEXT);
  const { values, setFieldValue, dirty: isDirty } = useFormikContext<AddressInitialValuesProps>();
  const address = values.renterAddress.addressType !== RenterAddressTypes.PRESENT ? values.renterAddress : undefined;
  const [isGoogleApiCalled, setIsGoogleApiCalled] = useState(false);
  const renterRole = useSelector(applicantRenterRole);

  useEffect(() => {
    const hasPriorAddress = !!addresses?.find((addr) => addr.addressType === RenterAddressTypes.PRIOR);

    if (
      renterRestrictions[renterRole].priorAddress ||
      (hasNoPriorAddress && !nextStep) ||
      (hasPriorAddress && !nextStep)
    ) {
      navigate(
        renterRoutes.generateAddressHistoryInformation(Number(applicationId), RenterAddressSteps.ADDRESS_HISTORY)
      );
    } else if (nextStep) {
      navigate(
        renterRoutes.generateAddressHistoryInformation(
          Number(applicationId),
          RenterAddressSteps.PRIOR_ADDRESS,
          nextStep
        )
      );
    }
  }, [addresses, applicationId, hasNoPriorAddress, navigate, nextStep, renterRole]);

  useEffect(() => {
    const fullAddress: string = values.streetAddress1 ? values.streetAddress1 : '';

    //Extracting the apartment and the street address from the full address given by palces-autocomplete
    if (!isGoogleApiCalled && values.zipCode && values.city && fullAddress.split(',').length > 1 && !isError) {
      const apartment: string = fullAddress.substring(
        fullAddress.indexOf(',') + ADDRESS_FORMAT_INDEX,
        fullAddress.indexOf(values.city) - ADDRESS_FORMAT_INDEX
      );
      const initialPriorAddress: AddressInformation = {
        streetAddress: fullAddress.split(',')[0],
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
        addressType: RenterAddressTypes.PRIOR,
        apartmentNumber: apartment,
      };

      setFieldValue('renterAddress', initialPriorAddress);
      setIsGoogleApiCalled(true);
    }

    if (!address) {
      setIsRenting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isError, setFieldValue, values]);

  return (
    <div style={{ overflow: 'visible' }}>
      <FormCloseWarning isDirty={isDirty} />
      {/* icons list */}
      <div className={styles.confirmPriorAddressContainer}>
        <RenterIconInfoContainer />
        {!isRenting && (
          <div className={indexStyles.renterContentContainer}>
            {!shouldClassify && (
              <p className={indexStyles.renterH2}>
                {!address || isError
                  ? t('renter.PriorAddress.includePriorAddresses')
                  : t('renter.PriorAddress.confirmAndEnterPrior')}
              </p>
            )}
            {(!address || isError) && (
              <div
                className={classNames(styles.placeAutoCompleteContainer, {
                  [styles.errorPlaceAutoComplete]: isError,
                })}
              >
                <PlacesAutocompleteComponent
                  addressField="streetAddress1"
                  setIsError={setIsError}
                  placeholder="Type to search"
                  shouldAcceptCustomAddress={false}
                  labelField="Street"
                  className="boldLabel"
                  data-testid="addressSearchBar"
                />
              </div>
            )}
            {(!address || isError) && (
              <div className={indexStyles.renterButtonContainer}>
                <RCButton
                  onClick={() => {
                    const params = { hasNoPriorAddress: true, id: userId };

                    if (!hasNoPriorAddress) {
                      dispatch(addRenterProfileInformation({ values: params }));
                    }

                    setIsError(false);
                    setFieldValue('zipCode', '');
                    navigate(
                      nextStep
                        ? nextStep
                        : renterRoutes.generateAddressHistoryInformation(
                            Number(applicationId),
                            RenterAddressSteps.ADDRESS_HISTORY
                          )
                    );
                  }}
                  className={classNames(indexStyles.renterButton, styles.button)}
                  variant="outline"
                >
                  {t('renter.PriorAddress.iDoNotHave')}
                </RCButton>
              </div>
            )}
            {address && !isError && (
              <PriorAddress
                defaultSelected={defaultDate}
                address={address}
                isRenting={isRenting}
                setIsRenting={setIsRenting}
                setShouldClassify={setShouldClassify}
                shouldClassify={shouldClassify}
              />
            )}
          </div>
        )}
        {isRenting && address && (
          <RenterOwnerInformation
            address={address.id ? formatAddressForFrontEnd(address) : address}
            setIsRenting={setIsRenting}
          />
        )}
      </div>
    </div>
  );
};

export default ConfirmPriorAddress;
