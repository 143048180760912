import Router from 'routes';

import 'react-loading-skeleton/dist/skeleton.css';
import './App.scss';
const App = (): JSX.Element => (
  <div className="App">
    <Router />
  </div>
);

export default App;
