/* eslint-disable @typescript-eslint/naming-convention */
import React, { Dispatch, SetStateAction } from 'react';
import { SingleValue } from 'react-select';
import { FormikErrors } from 'formik';

import { AgentProfileInformationProps } from 'shared/types/agentTypes';

import styles from './AgentProfileForm.module.scss';

export type ICountryProps = {
  countryCode?: string;
  dialCode?: string;
  format: string;
  iso2?: string;
  mainCode?: boolean;
  name?: string;
  priority?: number;
  regions?: [];
};

export type IPhoneNumberProps = {
  value: string;
  country: ICountryProps;
  setErrors: (errors: FormikErrors<AgentProfileInformationProps>) => void;
  errors: FormikErrors<AgentProfileInformationProps>;
};

/* This code defines an object OptionHash with two keys, 'Real Estate Agent/Broker'
and 'Property Manager', each with a React element as its value.*/
export const OptionHash: Record<string, React.ReactElement> = {
  'Real Estate Agent/Broker': (
    <span>
      REALTOR® License <span className={styles.optionals}>(Optional)</span>
    </span>
  ),
  'Property Manager': (
    <span>
      License or Certification <span className={styles.optionals}>(Optional)</span>
    </span>
  ),
};

/* This is an array of objects representing options for a dropdown list,
with each object having a value and a label property.*/
export const housingProviderCategories = [
  { value: 'Real Estate Agent/Broker', label: 'Real Estate Agent/Broker' },
  { value: 'Property Manager', label: 'Property Manager' },
  { value: 'Independent Owner', label: 'Independent Owner' },
  {
    value: 'Other Real Estate Professional',
    label: 'Other',
  },
];
export const categoryDescriptions: { [key: string]: string } = {
  'Real Estate Agent/Broker': 'agent.profileScreen.realEstate',
  'Property Manager': 'agent.invitedScreen.propertyManager',
  'Independent Owner': 'agent.invitedScreen.independentOwner',
  'Other Real Estate Professional': 'agent.invitedScreen.otherCategory',
};
export type Option = SingleValue<{
  value: string;
  label: string;
}>;
export type IOptionProps = {
  value: string;
  label: string;
};
export interface PropertySelectProps {
  isEnabled: boolean;
  publishedList?: IOptionProps[];
  onChangeHandler?: (e: Option) => void;
  tooltipText?: string;
}
export interface VerificationPageProps {
  setIsUserVerified: Dispatch<SetStateAction<boolean>>;
  heading?: string;
  subHeading?: string;
  buttonText?: string;
}

export interface FieldValuePair {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}
