import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Field, Form, useFormikContext } from 'formik';
import { isSaving } from 'redux/selectors/renterSelector';

import Button from 'components/shared/Button/Button';
import CustomRangeDatePicker from 'components/shared/CustomRangeDatePicker/CustomRangeDatePicker';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikField from 'components/shared/TextField/FormikTextField';
import { defaultDate, endDate } from 'constants/renterConstants';
import { INITIAL_DATE_VALUE } from 'shared/types/renterEmploymentInformation';
import { CustomEditModalProps, RenterPresentEmployments } from 'shared/types/renterTypes';

import styles from './FormStyles.module.scss';

const SelfEmployedForm = ({ heading }: CustomEditModalProps): JSX.Element => {
  const { t } = useTranslation();
  const isSavingInformation = useSelector(isSaving);
  const { values, isValid, dirty: isDirty } = useFormikContext<RenterPresentEmployments>();
  const isButtonDisabled = useMemo(
    (): boolean => (!isValid || !isDirty || isSavingInformation ? true : false),
    [isValid, isSavingInformation, isDirty]
  );

  return (
    <div className={styles.container}>
      <div className={classNames(styles.heading, heading ? styles.centeralized : '')}>
        {heading ? (
          <span>{heading}</span> // If heading is present, render the heading
        ) : (
          <span>
            {t('renter.priorSourceOfIncome.enterDetails')}
            <span className={styles.highlightedText}>{t('renter.priorSourceOfIncome.selfEmployed')}</span>
            {t('renter.priorSourceOfIncome.sourceIncome')}
          </span>
        )}
      </div>
      <div className={styles.fromContainer}>
        <Form>
          <div>
            <div className={styles.userFields}>
              <span className={styles.userLabel}>{t('renter.sourceOfIncome.title')}</span>
              <FormikField
                className={styles.inputBackgroundColor}
                name="title"
                placeholder={t('renter.sourceOfIncome.yourRole')}
              />
            </div>

            <div className={styles.datePicker}>
              <Field
                as={CustomRangeDatePicker}
                leftCalendarName="jobStartDate"
                rightCalendarName="jobEndDate"
                leftCalenderStartDate={INITIAL_DATE_VALUE}
                leftCalenderEndDate={endDate}
                rightCalenderStartDate={INITIAL_DATE_VALUE}
                rightCalenderEndDate={endDate}
                floatingLabelStyling={styles.label}
                defaultSelected={defaultDate}
              />
            </div>

            <div className={styles.userFields}>
              <span className={styles.userLabel}>{t('renter.sourceOfIncome.description')}</span>
              <FormikField
                className={styles.textField}
                name="jobDescription"
                placeholder={t('renter.sourceOfIncome.jobDescription')}
                isTextArea="textarea"
              />
            </div>

            <div className={styles.userFields}>
              <span className={styles.userLabel}>{t('renter.sourceOfIncome.monthlyGross')}</span>
              {!!values?.monthlyGrossIncome && <span className={styles.userGrossDollar}>$</span>}
              <FormikField
                className={classNames(styles.inputBackgroundColor, {
                  [styles.grossIncome]: !!values?.monthlyGrossIncome,
                })}
                name="monthlyGrossIncome"
                placeholder="$ 00,000.00"
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
              />
            </div>

            <div className={styles.buttonContainer}>
              <Button variant="outline" className={styles.buttonPrimary} disabled={isButtonDisabled} type="submit">
                {t('renter.priorSourceOfIncome.done')}
              </Button>
            </div>
          </div>
          <FormCloseWarning isDirty={isDirty} />
        </Form>
      </div>
    </div>
  );
};

export default SelfEmployedForm;
