import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { renterId } from 'redux/selectors/authSelector';

import { NOVA_CREDIT_STATUS } from 'constants/renterConstants';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';

import styles from './CreditHistory.module.scss';

// Declare the global Nova object
declare global {
  interface Window {
    Nova: {
      register: (config: { env: string; publicId: string; productId: string; userArgs: string }) => void;
      onSuccess: (publicToken: string, status: string) => void;
      onExit: (publicToken: string, status: string) => void;
      onError: (publicToken: string, error: string) => void;
      fire: () => void;
    };
  }
}

interface NovaCreditWidgetProps {
  handleCreditHistoryNext: (publicToken: string, status: string) => void; // Callback function to handle credit history next step
}

const NovaCreditWidget: React.FC<NovaCreditWidgetProps> = ({ handleCreditHistoryNext }) => {
  const renterUserId = useSelector(renterId); // Retrieve renter user ID from Redux store
  const { t } = useTranslation(); // Translation hook
  const [isButtonVisible, setIsButtonVisible] = useState(false); // Button visibility state
  const [isDisabled, setIsDisabled] = useState(false); // Button disabled state
  const PENDING_STATUS = 'PENDING';
  const { applicationId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize Nova Credit widget
    if (window.Nova && renterUserId) {
      window.Nova.register({
        env: process.env.REACT_APP_NOVA_CREDIT_ENV as string, // @params: env - Nova Credit environment
        publicId: process.env.REACT_APP_NOVA_CREDIT_PUBLIC_ID as string, // @params: publicId - Nova Credit public ID
        productId: process.env.REACT_APP_NOVA_CREDIT_PRODUCT_ID as string, // @params: productId - Nova Credit product ID
        userArgs: `${renterUserId}`, // @params: userArgs - Additional user arguments (renter user ID)
      });
    }
  }, [renterUserId]);

  useEffect(() => {
    if (window.Nova) {
      window.Nova.onSuccess = (_publicToken: string, status: string) => {
        Notification({ message: NOVA_CREDIT_STATUS[status as keyof typeof NOVA_CREDIT_STATUS] });

        // Handle successful Nova Credit widget completion
        if (status === PENDING_STATUS) {
          setIsDisabled(true);
        }

        navigate(renterRoutes.generateGuarantorInformation(applicationId));
      };

      window.Nova.onExit = (publicToken: string, status: string) => {
        // Handle Nova Credit widget exit
        Notification({ message: NOVA_CREDIT_STATUS[status as keyof typeof NOVA_CREDIT_STATUS] });
        handleCreditHistoryNext(publicToken, status); // @params: publicToken - Public token returned by Nova Credit widget, status - Credit history status
      };
    }
  }, [applicationId, handleCreditHistoryNext, navigate]);

  useEffect(() => {
    const novaButton = document.querySelector('#nova-button');

    if (novaButton && !novaButton.childNodes.length) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  }, []);

  const handleClick = useCallback((): void => {
    // Trigger Nova Credit widget only if the button is not disabled
    if (!isDisabled) {
      window.Nova.fire();
    }
  }, [isDisabled]);

  return (
    <>
      <div id="nova-button" data-testid="nova-credit-button"></div>
      {isButtonVisible && (
        <button
          className={`nova-button-class nova-button-blue nova-clearfix nova-button-logo ${
            isDisabled ? styles.disabledNovaButton : ''
          }`}
          id="nova-button-id"
          onClick={handleClick}
          disabled={isDisabled}
        >
          {t('renter.creditHistory.importCreditHistory')} {/* @returns: Button text */}
        </button>
      )}
    </>
  );
};

export default NovaCreditWidget;
