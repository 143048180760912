import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getInviteDetails } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';

import Spinner from 'components/shared/Spinner/Spinner';
import { routes } from 'shared/routes';

import styles from './RenterInvitation.module.scss';
export const RenterInvitation = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('inviteCode') || '';
  const inviteType = searchParams.get('inviteType') || '';
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!inviteCode) {
      dispatch(getInviteDetails({ inviteCode: inviteCode, inviteType: inviteType }))
        .unwrap()
        .then((res) => {
          navigate(res.renterExists ? routes.renterLogin : routes.renterSignup);
        });
    }
  }, [dispatch, inviteCode, inviteType, navigate]);

  return (
    <div className={styles.Container}>
      <Spinner className={styles.SpinnerClass} />
    </div>
  );
};
