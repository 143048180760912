import { PropertyProps } from 'shared/types/propertyType';

export type PropertySelectType = {
  value: string;
  label: string;
};

type AmenitiesType = {
  name: string;
  isSelected: boolean;
  type: string;
  isCustom: boolean;
  isAnimation: boolean;
  tempSelected: boolean;
};

export const formatProperties = (data: PropertyProps[]): PropertySelectType[] => {
  const temp: PropertySelectType[] = [];

  if (data.length) {
    data.map(({ id, streetAddress1, isCustomAddress }) => {
      if (!isCustomAddress) {
        temp.push({ value: String(id), label: streetAddress1 ?? 'Street Address' });
      }
    });
  }

  return temp;
};

export const formatAmenities = (amenitiesList: string[] | undefined, type: string, isCustom = false): AmenitiesType[] =>
  amenitiesList?.map((item) => ({
    name: item,
    isSelected: true,
    type,
    isCustom,
    isAnimation: false,
    tempSelected: false,
  })) || [];

export const parseApplicantsInitials = (applicants: string[]): string[] => {
  const initials = applicants.map((name) => {
    const parts = name.split(' ');
    const capitalizedInitials = parts.map((part) => part.charAt(0).toUpperCase()).join('');

    return capitalizedInitials;
  });

  return initials.slice(0, 4);
};
