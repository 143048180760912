import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';

import CircularProgressBar from 'components/shared/CircularProgressBar/CircularProgressBar';
import { calculateCurrentProgress, currentLocationKey, currentNavigationRoute } from 'helpers/renterProgress';

import NavigationSelect from './NavigationSelect/NavigationSelect';

import styles from './RenterNavigationBar.module.scss';

const RenterNavigationBar = (): JSX.Element => {
  const navigation = useNavigate();
  const { applicationId } = useParams();
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState('');
  const renterInfo = useSelector(selectRenterProfileInformation);
  const renterRole = useSelector(applicantRenterRole);

  useEffect(() => setCurrentLocation(currentLocationKey(location.pathname)), [location]);

  const { progress, options } = useMemo(
    () =>
      calculateCurrentProgress({
        data: renterInfo,
        currentStep: currentLocation,
        role: renterRole,
        applicationId: Number(applicationId),
      }),
    [applicationId, currentLocation, renterInfo, renterRole]
  );
  const handleNavigation = useCallback(
    (step: string | undefined) => navigation(currentNavigationRoute(renterInfo, step, applicationId)),
    [applicationId, navigation, renterInfo]
  );

  if (!currentLocation) {
    return <div />;
  }

  return (
    <div className={styles.BackIcon}>
      <div className={styles.container}>
        <div>
          <CircularProgressBar progress={progress} />
        </div>
        <div>
          <NavigationSelect options={options} currentLocation={currentLocation} handleNavigation={handleNavigation} />
        </div>
      </div>
    </div>
  );
};

export default RenterNavigationBar;
