import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as DollarIcon } from 'assets/svgs/DollarIconBlueSolid.svg';
import AgentPills, { Pill, PillTypes } from 'components/shared/AgentPills/AgentPills';
import { formatAmountToUsdString } from 'helpers/helper';
import { IncomeReportProps } from 'shared/types/reportTypes';

import ReportData from './ReportData/ReportData';

import styles from './IncomeReport.module.scss';

const IncomeReport = ({
  past3months,
  past6months,
  averageMonthly,
  projected12Months,
  propertyRent,
  isCustomProperty = false,
}: IncomeReportProps): JSX.Element => {
  const { t } = useTranslation();
  const calculateEstMonthlyAvrg = useMemo((): number => {
    if (isCustomProperty) {
      return averageMonthly / 3;
    }

    return averageMonthly / (propertyRent ?? 1);
  }, [averageMonthly, isCustomProperty, propertyRent]);
  const incomeReportPills = useMemo((): Pill[] => {
    const data: Pill[] = [];
    const pillHeading = t('agent.applicants.renterResume.sourceOfIncome.incomeReport.rentToIncomeRatio');
    const type = calculateEstMonthlyAvrg >= 2.5 ? PillTypes.PRIMARY_CYAN_LIGHT : PillTypes.DANGER_LIGHT;

    data.push({ heading: pillHeading, type });

    return data;
  }, [calculateEstMonthlyAvrg, t]);

  return (
    <div className={styles.IncomeReport}>
      <div className={styles.Heading}>
        <DollarIcon width={12} height={12} />
        <span>{t('agent.creditReport.summary')}</span>
      </div>
      <div className={styles.InformationText}>
        {t('agent.applicants.renterResume.sourceOfIncome.incomeReport.text')}
      </div>
      <div className={classNames(styles.OuterDiv)}>
        <div className={styles.LeftDiv}>
          <div className={styles.InnerDiv}>
            <ReportData
              label={t('agent.applicants.renterResume.sourceOfIncome.incomeReport.threeMonths')}
              value={formatAmountToUsdString(past3months)}
            />
            <ReportData
              label={t('agent.applicants.renterResume.sourceOfIncome.incomeReport.sixMonths')}
              value={formatAmountToUsdString(past6months)}
            />
          </div>
          <div className={styles.InnerDiv}>
            <ReportData
              label={t('agent.applicants.renterResume.sourceOfIncome.incomeReport.averageMonthly')}
              value={formatAmountToUsdString(averageMonthly)}
            />
            <ReportData
              label={t('agent.applicants.renterResume.sourceOfIncome.incomeReport.projrectedTwelveMonths')}
              value={formatAmountToUsdString(projected12Months)}
            />
          </div>
        </div>
        <div className={styles.RightDiv}>
          <div className={styles.EstMonthly}>
            <span className={styles.Text}>
              {t('agent.applicants.renterResume.sourceOfIncome.incomeReport.estMonthlyAvg')}
            </span>
            <span className={styles.LargetText}>{calculateEstMonthlyAvrg.toFixed(2)} X</span>
            <AgentPills pillContainerClassName={styles.PillContainer} pillsData={incomeReportPills} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeReport;
