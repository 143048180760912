import styles from './DetailsContainer.module.scss';

type DescriptionContainerProps = {
  heading: string;
  description?: string;
};

const DescriptionContainer = ({ heading, description }: DescriptionContainerProps): JSX.Element => (
  <div className={styles.DetailsContainer}>
    <span className={styles.DetailsHeading}>{heading}</span>
    <span className={styles.Details}>{description}</span>
  </div>
);

export default DescriptionContainer;
