import { ReactNode } from 'react';

import Button from 'components/shared/Button/Button';

import styles from './RenterCriteriaInfo.module.scss';

/**
 * Props for RenterCriteriaInfo component
 */
export interface RenterCriteriaInfoProps {
  icon?: ReactNode;
  text?: string | null;
  button?: string | null;
  badge?: string;
  logo?: ReactNode;
}

/**
 * RenterCriteriaInfo component displays renter criteria information.
 * @param {RenterCriteriaInfoProps} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const RenterCriteriaInfo = ({ icon, text, badge, button, logo }: RenterCriteriaInfoProps): JSX.Element => (
  <div className={styles.renterCriteriaInfo}>
    <div className={styles.criteriaLeftDiv}>
      <span className={styles.icon}>{icon && icon}</span>
      <span className={styles.text}>{text}</span>
    </div>
    <div className={styles.criteriaRightDiv}>
      <span>{logo}</span>
      {badge && <span className={styles.criteriaBadge}>{badge}</span>}
      {button && (
        <Button variant="outline" className={styles.criteriaBtn}>
          {button}
        </Button>
      )}
    </div>
  </div>
);

export default RenterCriteriaInfo;
