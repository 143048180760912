import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import { AxiosResponse } from 'axios';
import {
  selectAgentProfileInformation,
  selectCurrentProperty,
  selectedRenterDetails,
  selectRenterApplication,
  selectRenterCriminalReport,
  selectRenterEvictionReport,
  selectVerifierAddressInformation,
  selectVerifierEmploymentInformation,
} from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getRenterExperianReport } from 'redux/slices/agentSlice';
import { getAdditionalApplicationInfo } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { retrieveRenterTransactionReport } from 'services/agentService';
import { RenterResumeV2 } from 'shared/pdf/RenterResumePDF_V2';
import { ApplicationAdditionalInfoType } from 'shared/types/renterTypes';
import { FinancialData } from 'shared/types/reportTypes';

export const PDFTestPage = (): JSX.Element => {
  const { applicationId } = useParams();
  const currentOrganization = useSelector(selectedOrganization);
  const agentDetails = useSelector(selectAgentProfileInformation);
  const renterDetails = useSelector(selectedRenterDetails);
  const application = useSelector(selectRenterApplication);
  const criminalReport = useSelector(selectRenterCriminalReport);
  const selectedProperty = useSelector(selectCurrentProperty);
  const housingRecordsInformation = useSelector(selectRenterEvictionReport);
  const employmentVerificationInformation = useSelector(selectVerifierEmploymentInformation);
  const addressVerificationInformation = useSelector(selectVerifierAddressInformation);
  const [areTransactionsFetched, setAreTransactionsFetched] = useState(false);
  const [transactionalInformation, setTransactionalInformation] = useState<FinancialData>({} as FinancialData);
  const [additionalInfo, setAdditionalInfo] = useState({} as ApplicationAdditionalInfoType);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isReportLoaded, setIsReportLoaded] = useState(false);

  useEffect(() => {
    if (currentOrganization.id && applicationId && !isReportLoaded) {
      dispatch(
        getRenterExperianReport({ organizationId: currentOrganization.id, applicationId: Number(applicationId) })
      )
        .unwrap()
        .then((res) => {
          if (!res?.success) {
            // setIsReportError(true);

            return;
          }
        })
        .catch(() => {
          // setIsReportExpired(errorCode.message === reportExpireyErrorCode);
          // setIsReportError(true);
        })
        .finally(() => {
          setIsReportLoaded(true);
        });
    }
  }, [applicationId, currentOrganization, dispatch, isReportLoaded]);
  useEffect(() => {
    if (!areTransactionsFetched && renterDetails?.userId) {
      setAreTransactionsFetched(false);
      retrieveRenterTransactionReport({
        organizationId: currentOrganization.id,
        renterId: renterDetails.userId,
        applicationId: Number(applicationId),
      })
        .then((res: AxiosResponse) => {
          setTransactionalInformation(res.data.payload);
        })
        .finally(() => {
          setAreTransactionsFetched(true);
        });
    }
  }, [applicationId, areTransactionsFetched, currentOrganization.id, dispatch, renterDetails.userId]);

  useEffect(() => {
    if (selectedProperty.id) {
      dispatch(
        getAdditionalApplicationInfo({
          propertyId: Number(selectedProperty.id),
          applicationId: Number(applicationId),
          organizationId: currentOrganization.id,
        })
      )
        .unwrap()
        .then((res: ApplicationAdditionalInfoType) => {
          if (res) {
            setAdditionalInfo(res);
          }
        });
    }
  }, [selectedProperty.id, dispatch, currentOrganization.id, applicationId]);

  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <RenterResumeV2
        renterDetails={renterDetails}
        agentDetails={agentDetails}
        currentOrganization={currentOrganization}
        application={application}
        criminalReport={criminalReport}
        housingRecordsInformation={housingRecordsInformation}
        employmentVerificationInformation={employmentVerificationInformation}
        addressVerificationInformation={addressVerificationInformation}
        incomeTransactions={transactionalInformation}
        propertyInformation={selectedProperty}
        additionalInfo={additionalInfo}
        verifierAddresses={[]}
        verifierEmployments={[]}
      />
    </PDFViewer>
  );
};
