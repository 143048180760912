import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { routes } from 'shared/routes';

import styles from './../PropertyIndex.module.scss';

export interface PropertyTitleProps {
  title?: string | null;
  dotted?: boolean;
  id: number | undefined;
}

const PropertyTitle = ({ title, id, dotted = false }: PropertyTitleProps): JSX.Element => {
  const navigate = useNavigate();
  const handlePropertyClick = (): void => {
    navigate(routes.generatePropertyDetails(Number(id)));
  };

  return (
    <div
      onClickCapture={handlePropertyClick}
      className={classNames(styles.propertyTitle, { [styles.ellipsisText]: dotted })}
    >
      {title || 'Some property name'}
    </div>
  );
};

export default PropertyTitle;
