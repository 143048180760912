import { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import Modal from 'components/shared/Modal/Modal';
import { IsMobile } from 'helpers/IsMobile';
import { RenterModalProps } from 'shared/types/renterInformation';

import RenterConfirmChangesModal from '../RenterConfirmChangesModal/RenterConfirmChangesModal';
import RenterDrawer from '../RenterDrawer/RenterDrawer';

import styles from './RenterModal.module.scss';

const RenterModal = ({
  show: shouldShow,
  onHide,
  children,
  customCrossIcon,
  crossButtonClassName,
  isDirty = false,
  backDropClassName,
  contentClassName,
  dialogClassName,
  className,
  customHeaderClassName,
}: RenterModalProps): JSX.Element => {
  const isMobile = IsMobile();
  const [shouldOpen, setShouldOpen] = useState(false);
  const [isVisible, setVisible] = useState(true);

  useEffect(() => {
    if (shouldShow) {
      setVisible(true);
    }
  }, [shouldShow]);

  const customOnHide = useCallback((): void => {
    onHide();
  }, [onHide]);
  const handleOnExit = useCallback((): void => onHide(), [onHide]);
  const handleOnGoBack = useCallback((): void => setShouldOpen(false), []);
  const modalRoot = document.getElementById('modal-root');

  if (!modalRoot) {
    return <div></div>;
  }

  return ReactDOM.createPortal(
    <div>
      <RenterConfirmChangesModal shouldOpen={shouldOpen} onExit={handleOnExit} onGoBack={handleOnGoBack} />
      <div>
        {isVisible && isMobile ? (
          <RenterDrawer
            show={shouldShow}
            onHide={customOnHide}
            isDirty={isDirty}
            crossButtonClassName={crossButtonClassName}
          >
            <div>{children}</div>
          </RenterDrawer>
        ) : (
          <Modal
            centered
            show={shouldShow}
            onHide={customOnHide}
            contentClassName={classNames(contentClassName, styles.contentClassName)}
            customCrossIcon={customCrossIcon}
            crossButtonClassName={crossButtonClassName}
            backDropClassName={backDropClassName}
            dialogClassName={dialogClassName}
            className={className}
            customHeaderClassName={customHeaderClassName}
          >
            {children}
          </Modal>
        )}
      </div>
    </div>,
    modalRoot
  );
};

export default RenterModal;
