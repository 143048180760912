import { Col, Row } from 'react-bootstrap';

import { ConsumerIdentityProps } from 'shared/types/reportTypes';

import { EmptyReportRecord } from '../SharedReports/EmptyReportRecord/EmptyReportRecord';
import { VantageScoreReport } from '../SharedReports/VantageScoreReport/VantageScoreReport';

import styles from './ConsumerIdentity.module.scss';
export const ConsumerIdentity = ({ consumerIdentity, vantageScore }: ConsumerIdentityProps): JSX.Element => (
  <div className={styles.Container}>
    {!!consumerIdentity?.length && vantageScore?.length ? (
      <Row className={styles.InformationContainer}>
        <Col md={12} lg={12}>
          <VantageScoreReport vantageScoreReport={vantageScore[0]} />
        </Col>
      </Row>
    ) : (
      <EmptyReportRecord />
    )}
  </div>
);
