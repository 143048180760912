import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form } from 'formik';
import { useFormikContext } from 'formik';
import { isSaving } from 'redux/selectors/renterSelector';

import Button from 'components/shared/Button/Button';
import CustomDatePicker from 'components/shared/CustomDatePicker/CustomDatePicker';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import { RenterPersonalInfoModalProps } from 'shared/types/renterBackgroundInformation';
import { RenterProfileInformationInitialValue } from 'shared/types/renterTypes';

import RenterModal from '../RenterModal/RenterModal';

import styles from './RenterInfoDetailsModal.module.scss';

export const RenterPersonalInfoModal = ({
  shouldShowModal,
  onCloseModal,
  onSubmit,
}: RenterPersonalInfoModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { errors, dirty: isDirty, values } = useFormikContext<typeof RenterProfileInformationInitialValue>();
  const isSavingInformation = useSelector(isSaving);
  const isButtonDisabled = useMemo(
    (): boolean => (errors.birthDate || !values.birthDate || isSavingInformation ? true : false),
    [errors.birthDate, isSavingInformation, values.birthDate]
  );

  return (
    <RenterModal show={shouldShowModal} onHide={onCloseModal} isDirty={isDirty}>
      <div className={styles.RenterInfoDetailsModal}>
        <div className={styles.Title}>{t('renter.PhotoIdInformation.editDetails')}</div>
        <div className={styles.Heading}>
          {t('renter.stripeInfo.makeChanges')}&nbsp;
          <span>{t('renter.stripeInfo.title')}</span>&nbsp;
          {t('renter.stripeInfo.details')}
        </div>

        <div className={styles.Description}>{t('renter.stripeInfo.stripeIdentity.details')}</div>
        <div>
          <Form>
            <div className={styles.FormContainer}>
              <CustomDatePicker
                placeholder={t('renter.property.datePlaceholder')}
                name="birthDate"
                floatingLabel={t('renter.PhotoIdInformation.dateOfBirth')}
                startDate="1900-01-01"
                endDate={new Date().toDateString()}
              />
            </div>

            <div className={styles.ButtonContainer}>
              <Button
                variant="outline"
                className={styles.RenterPhotoInformationButton}
                onClick={onSubmit ?? undefined}
                disabled={isButtonDisabled}
                type="submit"
              >
                {t('saveAndClose')}
              </Button>
            </div>
            <FormCloseWarning isDirty={isDirty} />
          </Form>
        </div>
      </div>
    </RenterModal>
  );
};
