import { Dispatch, useCallback } from 'react';
import { Modal as BModal } from 'react-bootstrap';
import { t } from 'i18next';

import { ReactComponent as GreenTickIcon } from 'assets/svgs/CheckIconSuccess.svg';
import { ReactComponent as MessageSentIcon } from 'assets/svgs/EmailSentIcon.svg';
import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import Button from 'components/shared/Button/Button';

import styles from './InviteSuccessModal.module.scss';

type InviteSuccessModalProps = {
  isModalOpen: boolean;
  onHide: () => void;
  setIsInviteSuccess: Dispatch<React.SetStateAction<boolean>>;
};

const InviteSuccessModal = ({ isModalOpen, onHide, setIsInviteSuccess }: InviteSuccessModalProps): JSX.Element => {
  const onClickHandler = useCallback(() => {
    setIsInviteSuccess(false);
  }, [setIsInviteSuccess]);

  return (
    <AgentModal
      show={isModalOpen}
      onHide={onHide}
      size="lg"
      customTitleIcon={<GreenTickIcon />}
      title={t('agent.inviteApplicantSuccess.newApplicantUserInvited')}
      customTitleClassName={styles.modalTitle}
      customContainerClassName={styles.customContainer}
    >
      <div className={styles.container}>
        <BModal.Body>
          <div className={styles.innerContainer}>
            <MessageSentIcon className={styles.messageSentIcon} />
            {t('agent.inviteApplicantSuccess.yourOrganizationInviteHas')}
            <Button className={styles.button} onClick={onClickHandler}>
              {t('agent.inviteApplicantSuccess.inviteAnotherApplicant')}
            </Button>
          </div>
        </BModal.Body>
      </div>
    </AgentModal>
  );
};

export default InviteSuccessModal;
