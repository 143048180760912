import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ErrorMessage, useFormikContext } from 'formik';

import { ReactComponent as InfoIcon } from 'assets/svgs/infoIconFilled.svg';
import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import CustomRangeDatePicker from 'components/shared/CustomRangeDatePicker/CustomRangeDatePicker';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import FormikField from 'components/shared/TextField/FormikField';
import { paymentFailureAttemps, RentalAnswerCheckbox } from 'constants/agentConstants';
import { endDate, VerificationAnswerType, VerificationQuestionType } from 'constants/renterConstants';
import { PRIOR_ADDRESS_START_DATE } from 'helpers/address';
import { Colors } from 'shared/constants';
import { QuestionFormValues, RentalHistoryQuestionnaireProps } from 'shared/types/renterTypes';
import { SelectOption } from 'shared/types/renterTypes';

export const RentalHistoryQuestionnaire = ({ questionnaire, index }: RentalHistoryQuestionnaireProps): JSX.Element => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const { values, setFieldValue } = useFormikContext<QuestionFormValues>();
  const handleCheckboxChange = (value: string): void => {
    setFieldValue(`verificationQuestions[${index}].answer`, value);

    if (value.toLowerCase() === questionnaire.acceptedReason.toLowerCase()) {
      setFieldValue(`verificationQuestions[${index}].meta`, null);
    }
  };
  const handleSelectChange = (option: SelectOption): void => {
    setFieldValue(`verificationQuestions[${index}].meta.numberDetails`, option?.value);
  };
  const isNotAccepted = useMemo(
    () =>
      values?.verificationQuestions[index]?.answer &&
      values?.verificationQuestions[index]?.answer?.toLowerCase() !== questionnaire?.acceptedReason?.toLowerCase(),
    [index, questionnaire.acceptedReason, values?.verificationQuestions]
  );
  const isNa = useMemo(
    () => values?.verificationQuestions[index]?.answer?.toLowerCase() === VerificationAnswerType.NA,
    [values?.verificationQuestions, index]
  );
  const isQuestionType = useCallback(
    (type: string) => questionnaire.questionType === type,
    [questionnaire.questionType]
  );

  return (
    <div className={styles.RentalQuestionContainer}>
      <span className={styles.RentalQuestion}>
        {index === 4 ? (
          <span>
            {`${index + 1}.) ${questionnaire.question.replace(t('agent.properties.learnMore'), '')} `}
            <Link to={pathname + search} className={classNames(styles.LinkContainer, styles.TextContainer)}>
              {t('agent.properties.learnMore')}
            </Link>
          </span>
        ) : (
          `${index + 1}.) ${questionnaire.question}`
        )}
      </span>
      <span className={styles.RentalAnswerContainer}>
        {RentalAnswerCheckbox.map(({ label, value }) => (
          <div key={`${label}${index}`}>
            <Checkbox
              label={t(label)}
              className={styles.RentalAnswerCheckbox}
              onClick={() => handleCheckboxChange(value)}
              checked={values.verificationQuestions[index].answer?.toLowerCase() === value.toLowerCase()}
            />
          </div>
        ))}
      </span>
      {isNotAccepted && (isNa || isQuestionType(VerificationQuestionType.TEXT)) && (
        <div className={styles.AnswerResponseContainer}>
          <FormikField
            label={t('rental.questionnaireNaResponse')}
            name={`verificationQuestions[${index}].meta.textDetails`}
            labelClassName={classNames(styles.RequestDetails, styles.ResponseFormField)}
            className={styles.AnswerResponse}
            value={values.verificationQuestions[index]?.meta?.textDetails || ''}
          />
          <span className={styles.RentalLedger}>
            <InfoIcon height={16} width={16} />
            <span>{t('rental.questionnaire.ledger')}</span>
          </span>
        </div>
      )}
      {isNotAccepted && !isNa && isQuestionType(VerificationQuestionType.DROPDOWN) && (
        <div className={styles.AnswerResponseContainer}>
          <ReactSelect
            hasFloatingLabel
            floatingLabel={t('rental.questionnaireYesResponse')}
            className={styles.ReactSelectContainer}
            backgroundColor={Colors.WARM_CYAN_LIGHT}
            options={paymentFailureAttemps}
            onChange={handleSelectChange}
          />
          <ErrorMessage name={`verificationQuestions[${index}].meta.numberDetails`} />
        </div>
      )}
      {isNotAccepted && !isNa && isQuestionType(VerificationQuestionType.DATE) && (
        <div className={styles.AnswerResponseContainer}>
          <CustomRangeDatePicker
            leftCalendarName={`verificationQuestions[${index}].meta.fromDate`}
            rightCalendarName={`verificationQuestions[${index}].meta.toDate`}
            leftCalenderStartDate={PRIOR_ADDRESS_START_DATE}
            leftCalenderEndDate={endDate}
            rightCalenderStartDate={PRIOR_ADDRESS_START_DATE}
            rightCalenderEndDate={endDate}
          />
        </div>
      )}
    </div>
  );
};
