import { useMemo } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { t } from 'i18next';

import { ApplicationPillsKeys } from 'constants/applicationConstants';

import styles from './CollapsibleComponent.module.scss';

type CollapsibleComponentProps = {
  heading: string;
  shouldShowLineAbove?: boolean;
  children: React.ReactElement;
  shouldBeCollapsedByDefault?: boolean;
  badges?: string[];
};

const CollapsibleComponent = ({
  heading,
  children,
  shouldShowLineAbove = true,
  shouldBeCollapsedByDefault = false,
  badges,
}: CollapsibleComponentProps): JSX.Element => {
  const renderStatusBadges = useMemo(
    (): JSX.Element => (
      <div className={styles.BadgesContainer}>
        {badges?.includes(ApplicationPillsKeys.NEW) && (
          <div className={styles.NewBadge}>{ApplicationPillsKeys.NEW}</div>
        )}
        {badges?.includes(ApplicationPillsKeys.PENDING) && (
          <div className={styles.PendingBadge}>{ApplicationPillsKeys.PENDING}</div>
        )}
      </div>
    ),
    [badges]
  );

  return (
    <div className={styles.container}>
      {shouldShowLineAbove && <div className={styles.lineDiv} />}
      <Accordion defaultActiveKey={shouldBeCollapsedByDefault ? '' : '0'} flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className={styles.heading}>
              <span>{t(heading)}</span>
              {renderStatusBadges}
            </div>
          </Accordion.Header>
          <Accordion.Body>{children}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default CollapsibleComponent;
