import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';

import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import styles from 'components/Renter/IdentityVerification/RenterDetails/shared/Popover.module.scss';
import { RenterProfileSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { closePopOverOnClick, findApplication } from 'helpers/agentHelper';
import { renterRoutes } from 'shared/routes';

type PersonalProfileToolTipProps = {
  handleEditInfo: () => void;
  handleEditPhone: () => void;
  handleEditSSN: () => void;
  handleEditGuarantor: () => void;
  setSelectedPhoneNum: Dispatch<SetStateAction<number>>;
};

const PersonalProfileToolTip = ({
  handleEditGuarantor,
  handleEditInfo,
  handleEditPhone,
  handleEditSSN,
  setSelectedPhoneNum,
}: PersonalProfileToolTipProps): JSX.Element => {
  const renterDetails = useSelector(selectRenterProfileInformation);
  const { applicationId, appId } = useParams();
  const renterRole = useSelector(applicantRenterRole);
  const { firstName, lastName, birthDate, renterPhoneNumbers, ssnTailDigits, stripeVerified } = renterDetails;
  const currentAppplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const currentApplication = useMemo(
    () => findApplication(renterDetails?.applications, Number(currentAppplicationId)),
    [currentAppplicationId, renterDetails]
  );
  const isGuarantorInfoPresent = Boolean(currentApplication?.guarantor);
  const isRestricted = renterRestrictions[renterRole].guarantor;
  const renterInfo = useMemo(
    () => ({
      personalInfoPresent: Boolean(firstName && lastName && birthDate && stripeVerified),
      contactInfoPresent: Boolean(renterPhoneNumbers?.length),
      ssnInfoPresent: Boolean(ssnTailDigits),
      isGuarantorInfoPresent,
    }),
    [firstName, lastName, birthDate, renterPhoneNumbers, stripeVerified, ssnTailDigits, isGuarantorInfoPresent]
  );
  const handleAction = useCallback((actionCallback: () => void) => {
    closePopOverOnClick();
    actionCallback();
  }, []);
  const renderTooltip = (
    <Popover id="popover-positioned-bottom">
      <Popover.Body className={styles.popoverBody}>
        <div className={styles.popoverWrapper}>
          {renterInfo.personalInfoPresent ? (
            <button className={styles.popoverBtn} onClick={() => handleAction(handleEditInfo)}>
              {t('renter.paymentConfirmation.personalProfile.editIdentification')}
            </button>
          ) : (
            <Link
              className={styles.popoverBtn}
              to={renterRoutes.generateRenterStripeIdentityUrl(Number(currentAppplicationId))}
            >
              {t('renter.paymentConfirmation.personalProfile.addIdentification')}
            </Link>
          )}

          {renterDetails?.renterPhoneNumbers ? (
            renterDetails.renterPhoneNumbers.map((_, index) => (
              <button
                className={styles.popoverBtn}
                onClick={() => {
                  setSelectedPhoneNum(index);
                  handleAction(handleEditPhone);
                }}
                key={`phone-${index}`}
              >
                {`${t('renter.paymentConfirmation.personalProfile.editcontact')} ${index + 1}`}
              </button>
            ))
          ) : (
            <Link
              className={styles.popoverBtn}
              to={renterRoutes.generateRenterPhoneNumber(
                currentAppplicationId,
                renterRoutes.generateRenterPaymentVerification(currentAppplicationId)
              )}
            >
              {t('renter.paymentConfirmation.personalProfile.addcontact')}
            </Link>
          )}

          {renterInfo.ssnInfoPresent ? (
            <button className={styles.popoverBtn} onClick={() => handleAction(handleEditSSN)}>
              {t('renter.paymentConfirmation.personalProfile.editSsn')}
            </button>
          ) : (
            <Link
              className={styles.popoverBtn}
              to={`${renterRoutes.generatePersonalInformationUrl(
                RenterProfileSteps.SSNINPUT,
                Number(currentAppplicationId)
              )}&next=${renterRoutes.generateRenterPaymentVerification(currentAppplicationId)}`}
            >
              {t('renter.paymentConfirmation.personalProfile.addSsn')}
            </Link>
          )}

          {isRestricted ? null : renterInfo.isGuarantorInfoPresent ? (
            <button className={styles.popoverBtn} onClick={() => handleAction(handleEditGuarantor)}>
              {t('renter.paymentConfirmation.personalProfile.editGuarantor')}
            </button>
          ) : (
            <Link
              className={styles.popoverBtn}
              to={`${renterRoutes.generateGuarantorInformation(
                currentAppplicationId
              )}?step=form&next=${renterRoutes.generateRenterPaymentVerification(currentAppplicationId)}`}
            >
              {t('renter.paymentConfirmation.personalProfile.addGuarantor')}
            </Link>
          )}
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose trigger="click" key="bottom" placement="bottom" overlay={renderTooltip}>
      <ThreeDotsIcon />
    </OverlayTrigger>
  );
};

export default PersonalProfileToolTip;
