export type QuestionsProps = {
  iconType?: string;
  question: string;
  value: boolean;
  onChange: () => void;
};

export type FurtherQuestionsType = {
  id: number;
  question: string;
  answer: boolean;
  type: string;
};

export type IconsType = {
  [key: string]: JSX.Element;
};

export const furtherQuestions: FurtherQuestionsType[] = [
  {
    id: 1,
    question: 'Are you interested in the optional parking (if offered for this property?)',
    type: 'parking',
    answer: false,
  },
  {
    id: 2,
    question: 'Do you have a motorcycle?',
    type: 'bike',
    answer: false,
  },
  {
    id: 3,
    question: 'Do you have a boat?',
    type: 'boat',
    answer: true,
  },
  {
    id: 4,
    question: 'Do you have a pet?',
    type: 'pet',
    answer: false,
  },
  {
    id: 5,
    question: 'Do you have a waterbed?',
    type: 'bed',
    answer: false,
  },
  {
    id: 6,
    question: 'Do you have an aquarium?',
    type: 'pet',
    answer: false,
  },
  {
    id: 7,
    question: 'Do you or any of the other occupants smoke?',
    type: 'smoking',
    answer: false,
  },
];
