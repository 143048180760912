import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Formik } from 'formik';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { applicantRenterRole, renterApplication } from 'redux/selectors/renterSelector';
import { updateApplication } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RCButton from 'components/shared/Button/Button';
import { RenterAddressSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../../RenterContainer';
import RenterIconInfoContainer from '../../RenterIconInfoContainer';

import styles from '../../../RenterIndex.module.scss';
import adultStyles from '../RenterAdult/RenterAdult.module.scss';

const RelationshipMinorIndex = (): JSX.Element => {
  const navigation = useNavigate();
  const { applicationId } = useParams();
  // const presentEmployments = useSelector(renterPresentEmployments);
  const renterRole = useSelector(applicantRenterRole);
  const dispatch = useDispatch<AppThunkDispatch>();
  const maxHeight = useDeviceHeight(250);
  const applications = useSelector(renterApplication);
  const { hasNoMinors, minors } = applications?.find((app) => app.id === Number(applicationId)) || {};
  const onClickYes = useCallback(() => {
    if (minors?.length) {
      navigation(renterRoutes.generateRenterRelationMinorConfirmationUrl(applicationId));
    } else {
      navigation(renterRoutes.generateRenterMinors(applicationId));
    }
  }, [applicationId, minors, navigation]);
  const onClickNo = useCallback(() => {
    if (!hasNoMinors) {
      dispatch(updateApplication({ values: { hasNoMinors: true }, applicationId: Number(applicationId) }));
    }

    navigation(
      renterRoutes.generateAddressHistoryInformation(
        Number(applicationId),
        RenterAddressSteps.PRESENT_ADDRESS_CONFIRMATION
      )
    );
  }, [applicationId, dispatch, hasNoMinors, navigation]);

  useEffect(() => {
    if (renterRestrictions[renterRole].minors || (hasNoMinors && isEmpty(minors))) {
      navigation(
        renterRoutes.generateAddressHistoryInformation(
          Number(applicationId),
          RenterAddressSteps.PRESENT_ADDRESS_CONFIRMATION
        )
      );
    } else if (minors?.length) {
      navigation(renterRoutes.generateRenterRelationMinorConfirmationUrl(applicationId));
    }
  }, [applicationId, hasNoMinors, minors, navigation, renterRole]);

  return (
    <Formik
      initialValues={{}}
      validationSchema={{}}
      onSubmit={() => {
        console.log('formik values');
      }}
    >
      {() => (
        <RenterContainer>
          <section className={styles.renterInvitePropertyContainer}>
            <div style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
              <RenterIconInfoContainer />
              <h2 className={styles.renterInvitePropertyH2}>
                {t('renter.relationship.headingAnother')}&nbsp;
                <span className={styles.renterSpan}>{t('renter.relationship.descriptionMinorText')}</span>&nbsp;
                {t('renter.relationship.adultRental')}
              </h2>

              <div className={adultStyles.maritalContainer}>
                <div className={adultStyles.maritalButtonContainer}>
                  <RCButton onClick={onClickYes} className={adultStyles.renterMaritalButton} variant="outline">
                    {t('renter.relationship.minorYesButton')}
                  </RCButton>
                  <RCButton
                    onClick={onClickNo}
                    className={classNames(adultStyles.renterMaritalButton)}
                    variant="outline"
                  >
                    {t('renter.relationship.minorNoButton')}
                  </RCButton>
                </div>
              </div>
            </div>
          </section>
        </RenterContainer>
      )}
    </Formik>
  );
};

export default RelationshipMinorIndex;
