import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Form, useFormikContext } from 'formik';
import { t } from 'i18next';
import { isSaving } from 'redux/selectors/renterSelector';

import indexStyles from 'components/Renter/RenterIndex.module.scss';
import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import { VehicleTypes } from 'constants/renterConstants';
import { kebabCaseToTitle } from 'helpers/user';
import { VehiclesInformationType } from 'shared/types/renterVehicleInformation';

import VehicleInformationInputFields from './VehicleInformationInputFields/VehicleInformationInputFields';

import styles from './VehiclesForm.module.scss';
const VehiclesForm = (): JSX.Element => {
  const { isValid, dirty: isDirty } = useFormikContext<VehiclesInformationType>();
  const [searchParams] = useSearchParams();
  const selectedVehicleType = searchParams.get('type') || VehicleTypes.CAR;
  const isSavingInformation = useSelector(isSaving);

  return (
    <section className={styles.vehiclesForm}>
      <FormCloseWarning isDirty={isDirty} />
      <h2 className={indexStyles.renterH2}>
        {t('renter.vehiclesInformation.form.heading')} {kebabCaseToTitle(selectedVehicleType)}
      </h2>
      <Form>
        <VehicleInformationInputFields />
        <div className={indexStyles.renterButtonContainer}>
          <RCButton
            className={classNames(indexStyles.renterButton, indexStyles.nextButton)}
            variant="outline"
            type="submit"
            disabled={!isValid || isSavingInformation}
          >
            {t('renter.vehiclesInformation.form.saveAndContinue')}
          </RCButton>
        </div>
      </Form>
    </section>
  );
};

export default VehiclesForm;
