import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getOrganizationMembers, setOrganizationMembers } from 'redux/slices/organizationSlice';
import { AppThunkDispatch } from 'redux/store';

import { parseResponseErrors } from 'helpers/helper';
import { Notification } from 'shared/Notification/Notification';
import { AgentUserRoles } from 'shared/types/agentInvite';
import { UserInformation } from 'shared/types/organizationTypes';

import UserIndexPage from './UserIndexPage/UserIndexPage';
import UserInviteForm from './UserInviteForm/UserInviteForm';
import UserInviteSuccess from './UserInviteSuccess/UserInviteSuccess';

import styles from './UserOrganization.module.scss';
const UserOrganization = (): JSX.Element => {
  const [isInviteSuccess, setIsInviteSuccess] = useState(false);
  const currentOrganization = useSelector(selectedOrganization);
  const [areMembersFetched, setAreMembersFetched] = useState(false);
  const [organizationMembersList, setOrganizationMembersList] = useState<UserInformation[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const getComponentToRender = useMemo((): ReactNode => {
    if (
      currentOrganization.roleName !== AgentUserRoles.OWNER ||
      (currentOrganization.roleName === AgentUserRoles.OWNER && organizationMembersList?.length > 1)
    ) {
      return (
        <UserIndexPage
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setIsInviteSuccess={setIsInviteSuccess}
          isInviteSuccess={isInviteSuccess}
        />
      );
    } else {
      return <UserInviteForm setIsInviteSuccess={setIsInviteSuccess} />;
    }
  }, [currentOrganization.roleName, isInviteSuccess, isModalOpen, organizationMembersList?.length]);

  useEffect(() => {
    if (currentOrganization.id && (isInviteSuccess || !areMembersFetched)) {
      dispatch(getOrganizationMembers({ id: currentOrganization.id, target: '' }))
        .unwrap()
        .then((response) => {
          dispatch(setOrganizationMembers(response));
          setOrganizationMembersList(response);
          setAreMembersFetched(true);
        })
        .catch((error) => {
          Notification({ message: parseResponseErrors(error) });
        });
    }
  }, [areMembersFetched, currentOrganization, dispatch, isInviteSuccess]);

  if (!areMembersFetched) {
    return <Spinner />;
  }

  return (
    <>
      {isInviteSuccess && (
        <UserInviteSuccess
          setIsModalOpen={setIsModalOpen}
          onHide={() => setIsInviteSuccess(false)}
          setIsInviteSuccess={setIsInviteSuccess}
        />
      )}
      <div className={styles.Container}>{getComponentToRender}</div>
    </>
  );
};

export default UserOrganization;
