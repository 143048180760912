import Skeleton from 'react-loading-skeleton';

import styles from './ApplicationSkeleton.module.scss';
export const ApplicationSkeleton = (): JSX.Element => (
  <div className={styles.Container}>
    <div className={styles.ActiveStatus}>
      <Skeleton height={20} width={250} />
    </div>
    <div className={styles.HeaderContainer}>
      <div className={styles.CapsuleItem}>
        {Array.from([0, 1, 2, 3], (n) => (
          <Skeleton height={53} key={`upper-skeleton-${n}`} containerClassName={styles.SkeletonItem} />
        ))}
      </div>
    </div>
    <div className={styles.ApplicationContainer}>
      <div className={styles.Application}>
        <Skeleton height={20} width={200} containerClassName={styles.SkeletonItem} />
        <div className={styles.NameContainer}>
          <Skeleton height={30} width={150} containerClassName={styles.SkeletonItem} />
          <Skeleton height={13} width={80} containerClassName={styles.SkeletonItem} />
        </div>
      </div>
      {Array.from([0, 1, 2], (n) => (
        <Skeleton height={53} key={`lower-skeleton-${n}`} containerClassName={styles.SkeletonItem} />
      ))}
    </div>
  </div>
);
