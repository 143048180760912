import { Pill, PillTypes } from 'components/shared/AgentPills/AgentPills';
import { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';

export enum ValidaStatus {
  LOOKS_GOOD = 'LOOKS GOOD!',
  POTENTIAL_RISK = 'POTENTIAL RISK',
  RESULTS_IN_PROGRESS = 'RESULTS IN PROGRESS',
  INCONCLUSIVE = 'INCONCLUSIVE',
}

export const determineDocumentStatusPill = (documents?: PreviewFile[]): Pill => {
  if (documents?.some((document) => document.validaStatus === ValidaStatus.POTENTIAL_RISK)) {
    return {
      heading: ValidaStatus.POTENTIAL_RISK,
      type: PillTypes.DANGER,
    };
  }

  if (documents?.some((document) => document.validaStatus === ValidaStatus.INCONCLUSIVE)) {
    return {
      heading: ValidaStatus.INCONCLUSIVE,
      type: PillTypes.WARNING,
    };
  }

  if (documents?.every((document) => document.validaStatus === ValidaStatus.LOOKS_GOOD)) {
    return {
      heading: ValidaStatus.LOOKS_GOOD,
      type: PillTypes.SUCCESS,
    };
  }

  return {
    heading: ValidaStatus.RESULTS_IN_PROGRESS,
    type: PillTypes.WARM_GREY,
  };
};
