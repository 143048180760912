import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { isEmpty } from 'lodash-es';

import { dateFormatFrontend } from 'constants/calendarConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { RenterAdultProps, RenterMinorProps, RenterRoles } from 'shared/types/renterTypes';

import { FieldViewPDF } from '../shared/FieldViewPDF/FieldViewPDF';
import { HeadingPDF } from '../shared/HeadingPDF/HeadingPDF';

const styles = StyleSheet.create({
  MainContainer: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  Container: {
    width: '100%',
    padding: '4px 8px',
    borderRadius: '4px',
    border: '1px solid #E1EAEB',
  },
  OccupantInformation: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
  },
  NotFound: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
  },
  HorizontalSpacing: {
    width: '166px',
  },
  MinorsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
});

export interface HouseHoldViewPDFProps {
  adults: RenterAdultProps[];
  minors: RenterMinorProps[];
  renterRole: RenterRoles;
  isShowGap?: boolean;
}
export const HouseHoldViewPDF = ({
  adults,
  minors,
  renterRole,
  isShowGap = false,
}: HouseHoldViewPDFProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View style={{ ...styles.MainContainer, gap: isShowGap ? '1px' : '8px' }}>
      {!renterRestrictions[renterRole].adults && !isEmpty(adults) && (
        <HeadingPDF
          heading={t('agnet.renterResumePdf.adultCapital')}
          shouldShowCount
          count={(adults?.length ?? 0) + 1}
        />
      )}

      {adults.map((adult) => (
        <View key={`adult-${adult?.id}`} style={styles.Container} wrap={false}>
          <View style={styles.OccupantInformation}>
            <View style={styles.HorizontalSpacing}>
              <FieldViewPDF label={t('renter.relationship.relationshipLabel')} value={adult?.relationship} />
            </View>
            <View style={{ ...styles.HorizontalSpacing, width: '300px', marginLeft: '45px' }}>
              <FieldViewPDF label={t('application.email')} value={adult.emailAddress} />
            </View>

            <View style={styles.HorizontalSpacing}>
              <FieldViewPDF label="" value="" />
            </View>
          </View>
        </View>
      ))}
      {!renterRestrictions[renterRole].adults && isEmpty(adults) && (
        <View style={styles.Container}>
          <Text style={styles.NotFound}>{t('agnet.renterResumePdf.iAmTheOnyAdult')}</Text>
        </View>
      )}
      <View wrap={false} style={styles.MinorsContainer}>
        {!isEmpty(minors) && (
          <HeadingPDF heading={t('agnet.renterResumePdf.minorCapital')} shouldShowCount count={minors?.length ?? 0} />
        )}
        {minors.map((minor, index) => (
          <View key={`minor-${minor?.id}`} style={styles.Container} wrap={false}>
            <View style={styles.OccupantInformation}>
              <View style={styles.HorizontalSpacing}>
                <FieldViewPDF
                  label={t('agnet.renterResumePdf.coOccupant') + `${(adults?.length ?? 0) + 2 + index}`}
                  value={`${minor?.firstName ?? ''} ${minor?.lastName ?? ''}`}
                />
              </View>
              <View style={styles.HorizontalSpacing}>
                {!!minor?.birthDate && (
                  <FieldViewPDF
                    label={t('application.dob')}
                    value={format(new Date(minor?.birthDate), dateFormatFrontend)}
                  />
                )}
              </View>
              <View style={styles.HorizontalSpacing}>
                <FieldViewPDF label={t('renter.relationship.relationshipLabel')} value={minor?.relationship} />
              </View>
            </View>
          </View>
        ))}
      </View>
      {isEmpty(minors) && (
        <View style={styles.Container}>
          <Text style={styles.NotFound}>{t('renter.relationship.minorNoButton')}</Text>
        </View>
      )}
    </View>
  );
};
