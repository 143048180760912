/* eslint-disable jsx-a11y/img-redundant-alt */

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Formik } from 'formik';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { updatePropertyMarketingStatus } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';

import DefaultPropertyImage from 'assets/images/defaultImage.svg';
import MediaInformation from 'components/Agent/Properties/AddProperty/MediaInformation';
import RCButton from 'components/shared/Button/Button';
import { PreviewFile } from 'components/shared/DropZone/Dropzone';
import { InactiveButton } from 'components/shared/InActiveButton/InActiveButton';
import { ExperianRegistrationStatus } from 'constants/agentConstants';
import { PropertyType } from 'shared/types/propertType';

import { PropertyEditModal } from './PropertyEditModal';

import styles from '../PropertyDetails.module.scss';

type Props = {
  propertyImages: PreviewFile[];
  marketingEnabled: boolean;
  propertyId: number;
  propertyStatus: string;
};

export const ImageGallery = ({ propertyImages, marketingEnabled, propertyId, propertyStatus }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id: organizationId, experianVerified } = useSelector(selectedOrganization);
  const agentProfileInformation = useSelector(selectAgentProfileInformation);
  const dispatch = useDispatch<AppThunkDispatch>();
  const INITIAL_VALUES = {
    propertyImages: propertyImages.map((img: PreviewFile) => ({
      preview: img.fileLink,
      fileLink: img.fileLink,
      imageId: img.id,
    })),
  };
  const onMarketingClickHandler = useCallback(() => {
    dispatch(updatePropertyMarketingStatus({ propertyId, organizationId, marketingEnabled: !marketingEnabled }));
  }, [dispatch, marketingEnabled, organizationId, propertyId]);

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
        setIsModalVisible(false);
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <div>
          <div className={styles.EditMediaRow}>
            <div className={styles.MarketingButton}>
              {t('agent.properties.property.media.marketing')}
              &nbsp;
              {(experianVerified ||
                agentProfileInformation.experianRegistration === ExperianRegistrationStatus.SUCCEEDED) &&
              propertyStatus === PropertyType.PUBLISHED ? (
                <InactiveButton isActive={marketingEnabled} onClick={onMarketingClickHandler} />
              ) : (
                <span className={styles.DisabledStatus}>{t('agent.properties.property.media.marketing.disabled')}</span>
              )}
            </div>
            <RCButton variant="outline" className={styles.EditMediaButton} onClick={() => setIsModalVisible(true)}>
              {t('agent.properties.property.media.edit')}
            </RCButton>
          </div>
          <div className="row">
            {!propertyImages.length ? (
              <div className={styles.DefaultPropertyImage}>
                <img src={DefaultPropertyImage} alt="First Image" className={'img-fluid w-100'} />
              </div>
            ) : (
              <>
                <div className={`col-12 mb-4 ${propertyImages.length !== 1 && 'col-md-6'}`}>
                  <img
                    src={propertyImages[0]?.fileLink || DefaultPropertyImage}
                    alt="First Image"
                    className={classNames(styles.FeaturedPropertyImage, 'img-fluid w-100')}
                  />
                </div>

                <div className={classNames(styles.PropertyImageCol, 'col-12 col-md-6')}>
                  <div className="row h-100">
                    {propertyImages?.slice(1)?.map((image: PreviewFile, index: number) => (
                      <div
                        key={`property-image-${index}`}
                        className={`mb-4 d-flex ${index === 0 || index === 1 ? 'col-6' : 'col-4'}`}
                      >
                        <img
                          src={image.fileLink}
                          alt={`Image ${index + 2}`}
                          className={classNames(styles.PropertImage, 'img-fluid align-self-stretch w-100')}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
          <PropertyEditModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            title={t('agent.properties.property.media.edit')}
            isValid={true}
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            content={<MediaInformation />}
          />
        </div>
      )}
    </Formik>
  );
};
