import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
import { isSaving } from 'redux/selectors/renterSelector';
import { updateRenterAddressInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as GreyCrossIcon } from 'assets/svgs/GreyCrossIcon.svg';
import { ReactComponent as TickIcon } from 'assets/svgs/SimpleTick.svg';
import RCButton from 'components/shared/Button/Button';
import { RenterAddressSteps, RenterAddressTypes } from 'constants/renterConstants';
import { getAddressToApiFormat } from 'helpers/address';
import { getPrimaryVerifier } from 'helpers/renterHelper';
import { Notification } from 'shared/Notification/Notification';
import { AddressInformation, AddressInitialValuesProps, RenterCustomAddressType } from 'shared/types/renterTypes';

import AddressDiscardModal from '../AddressDiscardModal/AddressDiscardModal';
import AddressEditDetailsForm from '../AddressEditForm/AddressEditDetailsForm';
import RenterModal from '../RenterModal/RenterModal';

import styles from './AddressEditModal.module.scss';

interface IAddressEditModalProps {
  address: AddressInformation;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<React.SetStateAction<boolean>>;
  setIsRenting?: Dispatch<React.SetStateAction<boolean>>;
  setSelectedAddress?: Dispatch<React.SetStateAction<AddressInformation>>;
}

const AddressEditModal = ({
  isModalOpen,
  address,
  setIsModalOpen,
  setIsRenting,
  setSelectedAddress,
}: IAddressEditModalProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [searchParams] = useSearchParams();
  const stepName = searchParams.get('step') || 'presentAddressConfirmation';
  const { values, setFieldValue, errors, setTouched, touched } = useFormikContext<
    AddressInitialValuesProps | RenterCustomAddressType
  >();
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const isSavingState = useSelector(isSaving);
  const touchedResetRef = useRef(false);
  const [isFormSet, setIsFormSet] = useState(false);
  const isRentedValue = useMemo((): boolean => {
    if ((values.isRenting === false || values.isRenting === true) && values.isRenting !== address?.isRented) {
      return false;
    }

    return address.isRented || false;
  }, [address.isRented, values.isRenting]);
  const resetValuesToBefore = useCallback(() => {
    setFieldValue('streetAddress1', address.streetAddress);
    setFieldValue('apartment', address.apartmentNumber);
    setFieldValue('city', address.city);
    setFieldValue('state', address.state);
    setFieldValue('zipCode', address.zipCode);
    setFieldValue('moveInDate', address.moveInDate);
    setFieldValue('fromDate', address.fromDate);
    setFieldValue('toDate', address.toDate);
    setFieldValue('rent', address.contactRent);
    setFieldValue('isRenting', address.isRented);
  }, [
    address.apartmentNumber,
    address.city,
    address.contactRent,
    address.fromDate,
    address.isRented,
    address.moveInDate,
    address.state,
    address.streetAddress,
    address.toDate,
    address.zipCode,
    setFieldValue,
  ]);
  const onIAmSureClickHandler = useCallback(() => {
    resetValuesToBefore();
    setIsDiscardModalOpen(false);
    setIsModalOpen(false);
  }, [resetValuesToBefore, setIsModalOpen]);
  const setAddressValues = useCallback(
    ({
      streetAddress,
      zipCode,
      state,
      city,
      apartmentNumber,
      moveInDate,
      fromDate,
      toDate,
      addressVerifiers,
      contactRent,
    }: AddressInformation) => {
      setFieldValue('renterAddress', {
        ...values.renterAddress,
        streetAddress: streetAddress,
        zipCode: zipCode,
        state: state,
        city: city,
        apartmentNumber: apartmentNumber,
        moveInDate: moveInDate,
        fromDate: fromDate,
        toDate: toDate,
        contactRent: !contactRent ? getPrimaryVerifier(addressVerifiers ?? [])?.contactRent : contactRent,
      });
    },
    [setFieldValue, values.renterAddress]
  );
  const isDirty = useMemo(
    (): boolean =>
      values.apartment !== address.apartmentNumber ||
      values.city !== address.city ||
      values.fromDate !== address.fromDate ||
      values.state !== address.state ||
      values.zipCode !== address.zipCode ||
      values.streetAddress1 !== address.streetAddress ||
      values.moveInDate !== address.moveInDate ||
      values.toDate !== address.toDate ||
      values.fromDate !== address.fromDate ||
      values.rent !== address?.contactRent ||
      values.isRenting !== address?.isRented,
    [
      address.apartmentNumber,
      address.city,
      address?.contactRent,
      address.fromDate,
      address?.isRented,
      address.moveInDate,
      address.state,
      address.streetAddress,
      address.toDate,
      address.zipCode,
      values.apartment,
      values.city,
      values.fromDate,
      values.isRenting,
      values.moveInDate,
      values.rent,
      values.state,
      values.streetAddress1,
      values.toDate,
      values.zipCode,
    ]
  );
  const isButtonDisabled = useMemo(
    (): boolean =>
      !isDirty ||
      isSavingState ||
      !!errors.apartment?.length ||
      !!errors.streetAddress1?.length ||
      !!errors.state?.length ||
      !!errors.city?.length ||
      (!!errors.moveInDate?.length && !!address.moveInDate) ||
      (!!errors.fromDate?.length && !!address.fromDate) ||
      (!!errors.toDate?.length && !!address.toDate) ||
      (!!errors.rent?.length && !!address.contactRent) ||
      !!errors.zipCode?.length,
    [
      isDirty,
      isSavingState,
      errors.apartment?.length,
      errors.streetAddress1?.length,
      errors.state?.length,
      errors.city?.length,
      errors.moveInDate?.length,
      errors.fromDate?.length,
      errors.toDate?.length,
      errors.rent?.length,
      errors.zipCode?.length,
      address.moveInDate,
      address.fromDate,
      address.toDate,
      address.contactRent,
    ]
  );
  const onClickHandler = useCallback(() => {
    const id = address.id;

    if (id) {
      dispatch(
        updateRenterAddressInformation({
          id: id,
          address: {
            ...getAddressToApiFormat({
              ...address,
              apartmentNumber: values.apartment,
              moveInDate: values.moveInDate,
              toDate: values.toDate,
              fromDate: values.fromDate,
              contactRent: values.rent,
            }),
            streetAddress: values.streetAddress1,
            zipCode: values.zipCode,
            state: values.state,
            city: values.city,
            isRented: isRentedValue,
            contactRent: isRentedValue ? values.rent : null,
            emailAddress: isRentedValue ? address.emailAddress : null,
            phoneNumber: isRentedValue ? address.phoneNumber : null,
            fullName: isRentedValue ? address.fullName : null,
          },
        })
      )
        .unwrap()
        .then((response) => {
          setAddressValues(response.addresses.find((obj: AddressInformation) => obj.id === id));

          if (address?.isRented !== values?.isRenting && setIsRenting && setSelectedAddress) {
            setSelectedAddress(response.addresses.find((obj: AddressInformation) => obj.id === id));
            setIsRenting(!!values.isRenting);
          }

          setIsModalOpen(false);
        })
        .catch((error) => {
          Notification({ message: error });
        });
    } else {
      setFieldValue('renterAddress', {
        ...values.renterAddress,
        streetAddress: values.streetAddress1,
        zipCode: values.zipCode,
        state: values.state,
        city: values.city,
        apartmentNumber: values.apartment,
        moveInDate: values.moveInDate,
        toDate: values.toDate,
        fromDate: values.fromDate,
        contactRent: values.rent,
      });
      setIsModalOpen(false);
    }

    setTouched({ ...touched, streetAddress1: false, city: false, state: false, zipCode: false, apartment: false });
  }, [
    address,
    dispatch,
    isRentedValue,
    setAddressValues,
    setFieldValue,
    setIsModalOpen,
    setIsRenting,
    setSelectedAddress,
    setTouched,
    touched,
    values.apartment,
    values.city,
    values.fromDate,
    values.isRenting,
    values.moveInDate,
    values.rent,
    values.renterAddress,
    values.state,
    values.streetAddress1,
    values.toDate,
    values.zipCode,
  ]);
  // const allFieldsAreFilled = useMemo(
  //   (): boolean =>
  //     !!values.streetAddress1 &&
  //     !!values.zipCode &&
  //     !!values.city &&
  //     !!values.state &&
  //     (!!values.moveInDate || !address.moveInDate) &&
  //     (!!values.rent || !address.contactRent),
  //   [
  //     address.contactRent,
  //     address.moveInDate,
  //     values.city,
  //     values.moveInDate,
  //     values.rent,
  //     values.state,
  //     values.streetAddress1,
  //     values.zipCode,
  //   ]
  // );
  const getAddressType = (): string => {
    if (address.addressType === RenterAddressTypes.CUSTOM) {
      return t('renter.AddressEditModal.custom');
    } else if (address.addressType === RenterAddressTypes.PRESENT) {
      return t('renter.AddressEditModal.present');
    }

    return t('renter.AddressEditModal.prior');
  };
  const onHideHandler = useCallback(() => {
    if (isDirty) {
      setIsDiscardModalOpen(true);
    } else {
      setIsModalOpen(false);
    }

    setTouched({ ...touched, streetAddress1: false, city: false, state: false, zipCode: false, apartment: false });
  }, [isDirty, setIsModalOpen, setTouched, touched]);

  //Resetting touched at the start
  useEffect(() => {
    if (!touchedResetRef.current) {
      setTouched({
        ...touched,
        streetAddress1: false,
        city: false,
        state: false,
        zipCode: false,
        apartment: false,
        moveInDate: false,
        rent: false,
        toDate: false,
        fromDate: false,
        classification: false,
        description: false,
        reasonForMoving: false,
      });
      touchedResetRef.current = true;
    }
  }, [setTouched, touched]);
  //Setting form fields with initial data from the address
  useEffect(() => {
    if (!isFormSet) {
      setFieldValue('streetAddress1', address?.streetAddress);
      setFieldValue('city', address?.city);
      setFieldValue('state', address?.state);
      setFieldValue('zipCode', address?.zipCode);
      setFieldValue('apartment', address?.apartmentNumber);
      setFieldValue('moveInDate', address?.moveInDate);
      setFieldValue('toDate', address?.toDate);
      setFieldValue('fromDate', address?.fromDate);
      setFieldValue('rent', address?.contactRent);
      setFieldValue('isRenting', address?.isRented);

      setIsFormSet(true);
    }
  }, [
    address?.apartmentNumber,
    address?.city,
    address?.contactRent,
    address?.fromDate,
    address?.isRented,
    address?.moveInDate,
    address?.state,
    address?.streetAddress,
    address?.toDate,
    address?.zipCode,
    isFormSet,
    setFieldValue,
  ]);

  return (
    <>
      {!isDiscardModalOpen && (
        <RenterModal show={isModalOpen} onHide={onHideHandler} customCrossIcon={GreyCrossIcon}>
          <div className={styles.contentClassName}>
            <div className={styles.heading}>{t('renter.PhotoIdInformation.editDetails')}</div>
            <h2 className={styles.renterH2}>
              {t('renter.AddressEditModal.makeChangesTo')}
              <span className={styles.renterH2Color}>{`${getAddressType()} ${t(
                'renter.AddressEditModal.address'
              )}`}</span>
            </h2>
            {(address.isRented || address.isRented === false) && stepName === RenterAddressSteps.ADDRESS_HISTORY && (
              <div className={styles.ButtonsContainer}>
                <RCButton
                  onClick={() => {
                    setFieldValue('isRenting', true);
                  }}
                  variant="outline"
                  className={classNames(styles.ButtonClass, { [styles.ActiveButton]: values.isRenting })}
                >
                  {values.isRenting && <TickIcon className={styles.TickIcon} />}
                  {t('renter.ConfirmPresentAddress.iRent')}
                </RCButton>
                <RCButton
                  variant="outline"
                  onClick={() => {
                    setFieldValue('isRenting', false);
                  }}
                  className={classNames(styles.ButtonClass, { [styles.ActiveButton]: !values.isRenting })}
                >
                  {!values.isRenting && <TickIcon className={styles.TickIcon} />}
                  {t('renter.ConfirmPresentAddress.iDoNotRent')}
                </RCButton>
              </div>
            )}
            <AddressEditDetailsForm
              streetName="streetAddress1"
              cityName="city"
              stateName="state"
              zipCodeName="zipCode"
              apartmentName="apartment"
              isPrior={address?.addressType === RenterAddressTypes.PRIOR}
              moveInDateName="moveInDate"
              toDateName="toDate"
              fromDateName="fromDate"
              rentName="rent"
              hasContactRent={!!address.contactRent}
              isRenting={!!values.isRenting}
            />

            <div className={styles.renterButtonContainer}>
              <RCButton
                onClick={onClickHandler}
                className={styles.renterButton}
                variant="outline"
                disabled={isButtonDisabled}
              >
                {t('renter.PhotoIdInformation.savechanges')}
              </RCButton>
            </div>
          </div>
        </RenterModal>
      )}

      {isDiscardModalOpen && (
        <AddressDiscardModal
          isModalOpen={isDiscardModalOpen}
          setIsModalOpen={setIsDiscardModalOpen}
          onBackClick={() => {
            setIsDiscardModalOpen(false);
          }}
          onImSureClick={onIAmSureClickHandler}
        />
      )}
    </>
  );
};

export default AddressEditModal;
