import { Document, Page, View } from '@react-pdf/renderer';

import { FormValues, RenterAddressDetails, RenterEmploymentDetails, VerifierTypes } from 'shared/types/renterTypes';

import { EmployeeInfoSectionPDF } from '../EmployeeInfoSectionPDF/EmployeeInfoSectionPDF';
import { PDFCompletedBy } from '../PDFCompletedBy/PDFCompletedBy';
import { QuestionnairePart1PDF } from '../QuestionnairePDF/QuestionnairePart1PDF';
import { QuestionnairePart2PDF } from '../QuestionnairePDF/QuestionnairePart2PDF';
import { QuestionnairePDF } from '../QuestionnairePDF/QuestionnairePDF';
import { RenterInfoSectionPDF } from '../RenterInfoSectionPDF/RenterInfoSectionPDF';
import { VerifierPage } from '../VerifierPage/VerifierPage';
import { verifierStyles } from '../verifierStyles';

export enum VerifierURLType {
  VOE = 'VOE',
  VOR = 'VOR',
}

interface Props {
  type: VerifierURLType;
  userEmploymentDetails?: RenterEmploymentDetails;
  userAddressDetails?: RenterAddressDetails;
  questionnaire: FormValues;
  isEmbedded?: boolean;
  isLastPage?: boolean;
}

export const VerificationPDF = ({
  type,
  questionnaire,
  userEmploymentDetails,
  userAddressDetails,
  isEmbedded = false,
  isLastPage = false,
}: Props): JSX.Element => {
  const { submitVerifier, submittedAt, verifierType } = questionnaire;

  return isEmbedded ? (
    <Page size="LETTER">
      {!submittedAt ? (
        <VerifierPage isLastPage={isLastPage}>
          {type === VerifierURLType.VOE && userEmploymentDetails ? (
            <EmployeeInfoSectionPDF userDetails={userEmploymentDetails} submittedAt={submittedAt} />
          ) : (
            userAddressDetails && <RenterInfoSectionPDF userDetails={userAddressDetails} submittedAt={submittedAt} />
          )}
          <PDFCompletedBy submitVerifier={submitVerifier} verifierType={verifierType as VerifierTypes} />
        </VerifierPage>
      ) : (
        <View>
          <VerifierPage>
            {type === VerifierURLType.VOE && userEmploymentDetails ? (
              <EmployeeInfoSectionPDF userDetails={userEmploymentDetails} submittedAt={submittedAt} />
            ) : (
              userAddressDetails && <RenterInfoSectionPDF userDetails={userAddressDetails} submittedAt={submittedAt} />
            )}
            <QuestionnairePart1PDF questionnaire={questionnaire} />
          </VerifierPage>

          <View style={verifierStyles.QuestionnairePartTwo}>
            <QuestionnairePart2PDF questionnaire={questionnaire} />
          </View>

          <VerifierPage isLastPage={isLastPage}>
            <PDFCompletedBy submitVerifier={submitVerifier} verifierType={verifierType as VerifierTypes} />
          </VerifierPage>
        </View>
      )}
    </Page>
  ) : (
    <Document>
      <Page style={verifierStyles.page} size="LETTER">
        {type === VerifierURLType.VOE && userEmploymentDetails ? (
          <EmployeeInfoSectionPDF userDetails={userEmploymentDetails} submittedAt={submittedAt} />
        ) : (
          userAddressDetails && <RenterInfoSectionPDF userDetails={userAddressDetails} submittedAt={submittedAt} />
        )}
        <QuestionnairePDF questionnaire={questionnaire} />
        <PDFCompletedBy submitVerifier={submitVerifier} verifierType={verifierType as VerifierTypes} />
      </Page>
    </Document>
  );
};
