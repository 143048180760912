import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckIcon } from 'assets/svgs/CheckIconGrey.svg';
import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import AddressBox from 'components/shared/AddressBox/AddressBox';
import RCButton from 'components/shared/Button/Button';
import { AddressLivingSituationSummaryProps } from 'shared/types/renterTypes';

import styles from './AddressLivingSituationSummary.module.scss';

export const AddressLivingSituationSummary = ({
  address,
  onClickHandler,
  isForRent = false,
  customButtonText,
}: AddressLivingSituationSummaryProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <span className={styles.Heading}>{t('address.livingSituation.reviewDetailsToContinue.')}</span>
      <div className={styles.AddressInformation}>
        <AddressBox address={address} doShowMoreData shouldShowLivingSituation />
        {isForRent && (
          <div className={styles.InformationBox}>
            <InformationIcon className={styles.InformationIcon} />
            <span className={styles.InformationText}>{t('address.livingSituation.informationTag')}</span>
          </div>
        )}
        {isForRent && (
          <div className={styles.PermissionCheck}>
            <CheckIcon />
            <span className={styles.PermissionText}>{t('address.livingSituation.permissionTag')}</span>
          </div>
        )}
      </div>
      <RCButton onClick={onClickHandler} className={styles.SubmitButton}>
        {customButtonText || t('renter.ConfirmAddressHistory.looksGood')}
      </RCButton>
    </div>
  );
};
