import { useMemo } from 'react';
import { t } from 'i18next';

import { formatAmountToUsdString } from 'helpers/helper';
import { getPrimaryVerifier } from 'helpers/renterHelper';
import { formatDate } from 'helpers/user';
import { AddressInformation } from 'shared/types/renterTypes';

import styles from './DescriptionCard.module.scss';

type DescriptionCardProps = {
  address: AddressInformation;
  isReportPage?: boolean;
};

const DescriptionCard = ({ address, isReportPage = false }: DescriptionCardProps): JSX.Element => {
  const isRented = useMemo(() => {
    if (address && address.emailAddress && address.fullName && address.phoneNumber) {
      return true;
    }

    return false;
  }, [address]);
  const contactRent = address.contactRent ?? getPrimaryVerifier(address?.addressVerifiers ?? [])?.contactRent;

  return (
    <div>
      <div className={styles.DescriptionCardContainer}>
        <div className={styles.heading}>
          <span>{address?.streetAddress}</span>
          {!isReportPage && (
            <span className={styles.pill}>
              {isRented ? t('renter.ConfirmPresentAddress.iRented') : t('renter.ConfirmPresentAddress.iDoNotRent')}
            </span>
          )}
        </div>
        {address?.city && (
          <div className={styles.subHeading}>{`${address?.apartmentNumber ? address?.apartmentNumber + ' ' : ''}${
            address?.city
          }, ${address?.state} ${address?.zipCode}`}</div>
        )}
        {!isReportPage && !!address.reasonForMoving && (
          <>
            <div className={styles.date}>{address.reasonForMoving}</div>
            <div className={styles.addressType}>{t('agnet.renterResumePdf.reasonForMoving')}</div>
          </>
        )}
        {!isReportPage && (
          <div className={styles.Footer}>
            <div className={styles.DateContainer}>
              <div className={styles.date}>
                {formatDate(address?.moveInDate)} - {t('addressBox.present')}
              </div>
              <div className={styles.addressType}>{t('application.residency')}</div>
            </div>
            {!!contactRent && (
              <div className={styles.RentContainer}>
                <div className={styles.date}>{formatAmountToUsdString(contactRent)}</div>
                <div className={styles.addressType}>{t('renter.applicationInfoBox.rent')}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DescriptionCard;
