import { ProgressBar as BootstrapProgressBar } from 'react-bootstrap';
import classNames from 'classnames';

import styles from './ProgressBar.module.scss';

export interface IProgressProps {
  className?: string;
  progress?: number;
  animated?: boolean;
}

const ProgressBar = ({ className, progress, animated = true }: IProgressProps): JSX.Element => (
  <div className={styles.container}>
    <BootstrapProgressBar animated={animated} className={classNames(className)} now={progress || 100} />
  </div>
);

export default ProgressBar;
