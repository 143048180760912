import { ReactNode, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { applicantRenterRole, renterApplication } from 'redux/selectors/renterSelector';

import { ReactComponent as EditSvg } from 'assets/svgs/Edit.svg';
import { ReactComponent as InfoSvg } from 'assets/svgs/RenterInfo.svg';
import RCButton from 'components/shared/Button/Button';
import { RenterOccupantType } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { RelationOccupantProps } from 'shared/types/renterTypes';

import EditDetailsMinorModal from '../RenterMinor/EditDetailsMinorModal';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from './RelationMinorConfirmation.module.scss';

export const MinorsOccupantListCard = ({
  attributeLabel,
  label,
  addAnotherButtonLabel,
  confirmButtonLabel,
  informationText,
  headerText,
  floatingLabel,
  addAnotherButtonOnClick,
  continueButtonOnClick,
}: RelationOccupantProps): JSX.Element => {
  const { applicationId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [occupantId, setOccupantId] = useState<number | undefined>();
  const onClickHandler = useCallback((id: number | undefined): void => {
    setIsModalVisible(true);
    setOccupantId(id);
  }, []);
  const applications = useSelector(renterApplication);
  const { minors } = applications.find((app) => app.id === Number(applicationId)) || {};
  const renterRole = useSelector(applicantRenterRole);
  const onHideHandler = useCallback((): void => setIsModalVisible(false), []);
  const isRestricted = renterRestrictions[renterRole].minors;
  const adultFloatingLabel = RenterOccupantType.MINOR;

  return (
    <div className={styles.container}>
      {isModalVisible && (
        <div>
          {label === RenterOccupantType.MINORS && (
            <EditDetailsMinorModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              onHideHandler={onHideHandler}
              id={occupantId}
            />
          )}
        </div>
      )}

      <div className={styles.confirmationHeading}>
        {isRestricted ? t('renter.relationship.restrictedMinorsApplicant') : headerText}
      </div>
      <div className={styles.countDiv}>
        <div className={styles.floatingText}>{floatingLabel}</div>
        <div className={styles.adultCountDiv}>
          <div>Total {label.toLowerCase()}</div>
          <div>{minors?.length}</div>
        </div>
      </div>

      <div className={classNames(styles.adultListDiv, 'documentRow')}>
        {minors?.map((minor, index) => (
          <div className={styles.countDiv} key={minor.id}>
            <div className={styles.floatingText}>{`${adultFloatingLabel} ${index + 1}`}</div>
            <div className={styles.emailText}>
              <Link to={''} onClick={() => onClickHandler(minor.id)}>
                <EditSvg className={styles.editButton} />
              </Link>
              {`${minor.firstName ?? ''} ${minor.lastName ?? ''}`}
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.infoDiv}>
                <div className={styles.valueText}>{minor.birthDate as ReactNode}</div>
                <div className={styles.labelText}>{attributeLabel}</div>
              </div>
              <div className={styles.infoDiv}>
                <div className={styles.valueText}>{minor.relationship}</div>
                <div className={styles.labelText}>{t('renter.relationship.relationshipLabel')}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isRestricted ? (
        <div className={styles.footer}>
          <RCButton className={styles.renterButton} variant="outline" onClick={continueButtonOnClick}>
            {t('renter.relationship.minorConfirmButton')}
          </RCButton>
        </div>
      ) : (
        <>
          <div className={styles.inviteInfoContainer}>
            <div className={styles.inviteInfo}>
              <div className={styles.infoIconDiv}>
                <InfoSvg />
              </div>
              <div className={styles.inviteTextDiv}>{informationText}</div>
            </div>
          </div>
          <div className={styles.footer}>
            <RCButton
              className={classNames(styles.renterAddButton, styles.renterButton)}
              variant="outline"
              onClick={addAnotherButtonOnClick}
            >
              {addAnotherButtonLabel}
            </RCButton>
            <RCButton className={styles.renterButton} variant="outline" onClick={continueButtonOnClick}>
              {confirmButtonLabel}
            </RCButton>
          </div>
        </>
      )}
    </div>
  );
};
