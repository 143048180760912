import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from 'redux/selectors/authSelector';
import { getGuarantorInviteDetails } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';

import { PublicSearchParams } from 'constants/routeConstants';
import { clearAllInvitesDetails } from 'helpers/publicHelper';
import { renterRoutes, routes } from 'shared/routes';

export const GuarantorInviteHandler = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const isUserAuthenticated = useSelector(isAuthenticated);
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const inviteCode = searchParams.get(PublicSearchParams.INVITE_CODE);

  useEffect(() => {
    if (inviteCode) {
      clearAllInvitesDetails({ dispatch: dispatch });
      dispatch(getGuarantorInviteDetails({ inviteCode: inviteCode }))
        .unwrap()
        .then((res) => {
          let route = '';

          if (isUserAuthenticated) {
            route = renterRoutes.renter;
          } else {
            route = res.invitedGuarantorExists ? routes.renterLogin : routes.renterSignup;
          }

          navigate(route);
        });
    }
  }, [inviteCode, dispatch, navigate, isUserAuthenticated]);

  return <div />;
};
