import { useTranslation } from 'react-i18next';
import { Text, View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash-es';

import { formatDateToShortFormat } from 'helpers/applicantsHelper';
import { formatAmountToUsdString } from 'helpers/helper';
import { formatPhoneNumber } from 'helpers/renterHelper';
import { sharedStyles } from 'shared/pdf/sharedStyles';
import { RenterAddressDetails } from 'shared/types/renterTypes';

import { PDFCheckBox } from '../PDFCheckbox/PDFCheckbox';
import { verifierStyles } from '../verifierStyles';

interface Props {
  userDetails: RenterAddressDetails;
  submittedAt?: string;
}

export const RenterInfoSectionPDF = ({ userDetails, submittedAt }: Props): JSX.Element => {
  const { t } = useTranslation();

  return !isEmpty(userDetails) ? (
    <View>
      <View style={verifierStyles.verifierHeading}>
        <Text>{'AUTHORIZATION TO RELEASE RENTAL INFORMATION'}</Text>
      </View>
      <View style={verifierStyles.subtext}>
        <Text>{t('employment.historyVerification.pdfDisclaimer.vor')}</Text>
      </View>
      <View style={verifierStyles.boldSubText}>
        <Text>{t('employment.historyVerification.immediateResponseText')}</Text>
      </View>
      <View style={verifierStyles.authorizeContainer}>
        <PDFCheckBox isChecked={true} />
        <Text>{'I hereby authorize the release of information for verification.'}</Text>
      </View>
      <View style={verifierStyles.nameContainer}>
        <Text>{`${userDetails.renterFirstName} ${userDetails.renterLastName}`}</Text>
        <View style={sharedStyles.line} />
        <View style={verifierStyles.signatureContainer}>
          <Text>{`Digitally signed by ${userDetails.renterFirstName} ${userDetails.renterLastName}`}</Text>
          {submittedAt && <Text>{`Date: ${formatDateToShortFormat(submittedAt)}`}</Text>}
        </View>
      </View>
      <View style={verifierStyles.contactInformation}>
        <Text>{'Contact Information'}</Text>
      </View>
      <View style={verifierStyles.contactContainer}>
        <View>
          <Text style={sharedStyles.greyHeading}>{'Email'}</Text>
          <Text>{userDetails.renterEmail}</Text>
        </View>
        <View>
          <Text style={sharedStyles.greyHeading}>{'Mobile'}</Text>
          <Text>{formatPhoneNumber(userDetails.renterPhoneNumber)}</Text>
        </View>
      </View>
      <View style={verifierStyles.greyLine} />
      <View style={verifierStyles.submittedByRenter}>
        <Text>{'Submitted by renter'}</Text>
      </View>
      <View style={verifierStyles.greyHeadingRow}>
        <Text>{'RENTAL PROPERTY'}</Text>
        <Text>{'OWNER/MANAGER'}</Text>
      </View>
      <View style={verifierStyles.renterContainer}>
        <View style={verifierStyles.rentalProperty}>
          <View>
            <Text>{`${userDetails.renterAddressInformation.streetAddress} ${userDetails.renterAddressInformation.city}, ${userDetails.renterAddressInformation.state} ${userDetails.renterAddressInformation.zipCode}`}</Text>
          </View>
          <View>
            <Text style={verifierStyles.greyText}>{'Monthly Rent'}</Text>
            <Text>{formatAmountToUsdString(userDetails.renterAddressInformation.rent)}</Text>
          </View>
          <View>
            <Text style={verifierStyles.greyText}>{'Residency'}</Text>
            <Text>{`${userDetails.renterAddressInformation.moveInDate} - Present`}</Text>
          </View>
        </View>
        <View style={verifierStyles.ownerContainer}>
          <View>
            <Text style={verifierStyles.greyText}>{'Full Name'}</Text>
            <Text>{userDetails.verifierFullName}</Text>
          </View>
          <View>
            <Text style={verifierStyles.greyText}>{'Email'}</Text>
            <Text>{userDetails.verifierEmail}</Text>
          </View>
          <View>
            <Text style={verifierStyles.greyText}>{'Phone'}</Text>
            <Text>{formatPhoneNumber(userDetails.verifierPhoneNumber)}</Text>
          </View>
        </View>
      </View>
    </View>
  ) : (
    <View></View>
  );
};
