import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { selectSecurityQuestions } from 'redux/selectors/authSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { setIsLoading } from 'redux/slices/agentSlice';
import { getAgentProfileInformation } from 'redux/slices/authSlice';
import { getLastVisitedOrganization } from 'redux/slices/organizationSlice';
import { setIsRenterLoading } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as ExperianIcon } from 'assets/svgs/ExperianLogoFull.svg';
import RCButton from 'components/shared/Button/Button';
import { RadioButton } from 'components/shared/RadioButton/RadioButton';
import { parseResponseErrors } from 'helpers/helper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { submitAgentSecurityQuestion } from 'services/agentService';
import { submitRenterSecurityQuestion } from 'services/renterService';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes, routes } from 'shared/routes';
import { UserFlowTpe } from 'shared/types/agentTypes';
import { SecurityAnswer } from 'shared/types/authType';
import { SecurityQuestionnaireProps } from 'shared/types/sharedTypes';

import Card from '../CustomCard/Card';

import styles from './SecurityQuestionnaire.module.scss';

export const SecurityQuestionnaire: React.FC<SecurityQuestionnaireProps> = ({ flowType }) => {
  const { applicationId } = useParams();
  const isRenter = flowType === UserFlowTpe.RENTER;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const securityQuestions = useSelector(selectSecurityQuestions);
  const currentOrganization = useSelector(selectedOrganization);
  const dispatch = useDispatch<AppThunkDispatch>();
  const maxHeight = useDeviceHeight(147);
  const initialSelectedAnswers = Object.fromEntries(securityQuestions.map((question) => [question.QuestionType, null]));
  const [selectedAnswers, setSelectedAnswers] = useState(initialSelectedAnswers);
  const handleRadioButtonChange = (questionType: number, value: string): void => {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionType]: value,
    }));
  };
  const generateFormattedAnswers = (): SecurityAnswer => ({
    kbaAnswers: securityQuestions.map((question) => ({
      answer: question.QuestionSelect.QuestionChoice.indexOf(selectedAnswers[question.QuestionType] || '') + 1,
    })),
  });

  return (
    <Card
      parentClassName={styles.AgentSecurityQuestionnaireContainer}
      xl={8}
      lg={10}
      md={12}
      rowClassName={styles.CardRowContainer}
    >
      <div
        className={styles.Container}
        style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto', paddingInline: '35px' }}
      >
        <span className={styles.Title}>{t('renter.securityQuestionnaire.title')}</span>
        <Row className={styles.ExperianContainer}>
          <span className={styles.ExperianLogo}>
            <ExperianIcon />
          </span>
          <span className={styles.ExperianText}>{t('renter.securityQuestionnaire.experianText')}</span>
        </Row>
        <div className={classNames(styles.QuestionnaireContainer)}>
          {securityQuestions.map((question) => (
            <div key={`security-question-${question.QuestionText}`} className={styles.QuestionContainer}>
              <span className={styles.Question}>{t(question.QuestionText)}</span>
              <div className={styles.AnswerContainer}>
                {question.QuestionSelect.QuestionChoice.map((answer) => (
                  <div key={`security-answer-${question.QuestionType}`} className={styles.AnswerContainer}>
                    <RadioButton
                      name={`security-question-${question.QuestionType}`}
                      className={styles.Question}
                      label={t(answer)}
                      value={answer}
                      required
                      checked={selectedAnswers[question.QuestionType] === answer}
                      onChange={() => handleRadioButtonChange(question.QuestionType, answer)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.RenterButtonContainer}>
          <RCButton
            variant="primary"
            disabled={Object.values(selectedAnswers).filter(Boolean).length !== Object.keys(securityQuestions).length}
            onClick={() => {
              const values = generateFormattedAnswers();

              if (isRenter) {
                dispatch(setIsRenterLoading(true));
                submitRenterSecurityQuestion(values, Number(applicationId))
                  .catch((error) => {
                    const message = error?.response?.data;

                    Notification({ message: message?.errors[0]?.errorMessage ?? message?.payload?.error?.Message });
                  })
                  .finally(() => {
                    navigate(renterRoutes.applications);
                    dispatch(setIsRenterLoading(false));
                  });
              } else {
                dispatch(setIsLoading(true));
                submitAgentSecurityQuestion(values, currentOrganization.id)
                  .then(() => {
                    dispatch(getLastVisitedOrganization(currentOrganization.id))
                      .unwrap()
                      .then(() => navigate(routes.applicants))
                      .catch((error) => {
                        Notification({ message: parseResponseErrors(error) });
                      });
                    dispatch(getAgentProfileInformation());
                  })
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  .catch((res: any) => {
                    const { payload } = res?.response?.data?.payload;
                    const message =
                      payload?.errors[0]?.errorMessage ?? payload?.error?.Message ?? payload?.payload?.error?.Message;

                    Notification({
                      message: message.includes('Failed Authentication') ? 'Failed Authentication' : message,
                    });
                  })
                  .finally(() => dispatch(setIsLoading(false)));
              }
            }}
            className={styles.RenterButton}
          >
            {t('renter.incomeInformation.saveAndContinueButton')}
          </RCButton>
        </div>
      </div>
    </Card>
  );
};
