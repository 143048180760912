import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import RCButton from 'components/shared/Button/Button';
import {
  bePreparedText,
  creditBackground1,
  creditBackground2,
  dashboardText,
  disclaimer,
  householdText1,
  householdText2,
  renterCriteria1,
} from 'constants/renterConstants';

import { StructuredPropertiesHeader } from '../StructuredPropertiesHeader/StructuredPropertiesHeader';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './RenterCriteriaText.module.scss';

interface Props {
  agentOrganizationName: string | null;
  agreedWithTerms?: () => void;
}

export const RenterCriteriaText = ({ agentOrganizationName, agreedWithTerms }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Row className={classNames('documentRow', styles.documentRow)}>
      {agentOrganizationName === 'Structure Properties' && <StructuredPropertiesHeader />}
      <div>{renterCriteria1}</div>
      <div className={styles.documentHeading}>{t('application.household').toUpperCase()}</div>
      <div>{householdText1}</div>
      <div className={styles.Space} />
      <div>{householdText2}</div>
      <div className={styles.Space} />
      <div className={styles.documentHeading}>BE PREPARED</div>
      <div>{bePreparedText}</div>
      <div>Items you will need to include,</div>
      <div className={styles.List}>
        <div className={classNames(styles.ListItem, styles.Bold)}>
          <div className={styles.Dot} />
          Supporting Documents
        </div>
        <div className={classNames(styles.SecondListItem)}>
          <div className={styles.Dot} />
          Government Issued ID
        </div>
        <div className={classNames(styles.SecondListItem)}>
          <div className={styles.Dot} />
          Proof of Income
        </div>
        <div className={classNames(styles.ListItem, styles.Bold)}>
          <div className={styles.Dot} />
          Contact Information
        </div>
        <div className={classNames(styles.SecondListItem)}>
          <div className={styles.Dot} />
          For Employment
        </div>
        <div className={classNames(styles.ThirdListItem)}>
          <div className={styles.Dot} />
          Human resource or supervisor
        </div>
        <div className={classNames(styles.SecondListItem)}>
          <div className={styles.Dot} />
          For present and prior rental history,
        </div>
        <div className={classNames(styles.ThirdListItem)}>
          <div className={styles.Dot} />
          Owner or management company
        </div>
        <div className={classNames(styles.ListItem, styles.Bold)}>
          <div className={styles.Dot} />
          Bank and/or creditor details:
        </div>
        <div className={classNames(styles.SecondListItem)}>
          <div className={styles.Dot} />
          For app questionnaire
        </div>
        <div className={classNames(styles.SecondListItem)}>
          <div className={styles.Dot} />
          For payment
        </div>
      </div>
      <div className={styles.Space} />
      <div className={styles.documentHeading}>CREDIT & BACKGROUND</div>
      <div className={styles.Space} />
      <div>{creditBackground1}</div>
      <div className={styles.Space} />
      <div>{creditBackground2}</div>
      <div className={styles.Space} />
      <div className={styles.documentHeading}>DASHBOARD</div>
      <div>{dashboardText}</div>
      <div className={styles.Space} />
      <div className={styles.MediumText}>{disclaimer}</div>
      {agreedWithTerms && (
        <div className={indexStyles.footer}>
          <RCButton
            variant="outline"
            className={classNames(indexStyles.viewRenterCriteriaBtn, indexStyles.continueBtn)}
            onClick={agreedWithTerms}
          >
            {t('renter.renterCriteria.agreedButtonText')}
          </RCButton>
        </div>
      )}
    </Row>
  );
};
