import { Store } from 'redux';
let storeSingleton: Store<unknown> | undefined;

export const setStore = (store: Store<unknown> | undefined): void => {
  if (!storeSingleton || store === undefined) {
    storeSingleton = store;
  }
};

export const getStore = (): Store<unknown> | undefined => storeSingleton;
