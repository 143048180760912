import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ArrowIcon } from 'assets/svgs/ArrowForward.svg';
import { formatAmountToUsdString } from 'helpers/helper';
import { sortTransactionsByMonth } from 'helpers/reportsHelper';
import { FinancialReportProps, Transaction } from 'shared/types/reportTypes';

import { FinancialReportBody } from './FinancialReportBody';

import styles from './FinancialReport.module.scss';
export const FinancialReport = ({
  accountInformation,
  accountTransactions,
  shouldShowInformationByDefault = true,
}: FinancialReportProps): JSX.Element => {
  const [shouldShowInformation, setShouldShowInformation] = useState(shouldShowInformationByDefault);
  const { t } = useTranslation();
  const onDropDownClickHandler = (): void => {
    setShouldShowInformation(!shouldShowInformation);
  };
  const formattedTransactions: Transaction[][] = sortTransactionsByMonth(accountTransactions);

  return (
    <div className={styles.Container}>
      <div className={styles.BankInformationContainer}>
        <ArrowIcon
          onClick={onDropDownClickHandler}
          className={classNames(styles.DropDownIcon, { [styles.ShowInformation]: shouldShowInformation })}
        />
        <img
          src={accountInformation.financialInstitutionIcon}
          alt={t('incomeReport.BankIcon')}
          className={styles.BankIcon}
        ></img>
        <div className={styles.BankInformation}>
          <div className={styles.BankName}>{accountInformation.institutionName}</div>
          <div className={styles.AccountInformation}>
            <div className={styles.Type}>{accountInformation.accountName}</div>
            <div className={styles.Number}>{`.... ${accountInformation.accountNumberDisplay}`}</div>
            <div className={styles.Currency}>{accountInformation.currency}</div>
            <div className={styles.Balance}>{formatAmountToUsdString(accountInformation.balance)}</div>
          </div>
        </div>
      </div>
      {shouldShowInformation && <FinancialReportBody transactions={formattedTransactions} />}
    </div>
  );
};
