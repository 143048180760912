import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import { capitalize } from 'lodash-es';

import { FileCheckSVG } from 'shared/pdf/v2/svgs/FIleCheckSVG';
import { AnimalInformationProps } from 'shared/types/renterTypes';

import { FieldViewPDF } from '../FieldViewPDF/FieldViewPDF';

interface Props {
  animal: AnimalInformationProps;
}

const styles = StyleSheet.create({
  detailContainer: {
    width: '100%',
    border: '1px solid #E6EFF0',
    borderRadius: '4px',
    padding: '8px 4px',
    marginTop: '8px',
  },
  photoContainer: {
    width: '100%',
    padding: '4px 12px',
    border: '1px solid #E6EFF0',
    borderRadius: '4px',
    flexDirection: 'row',
    fontWeight: 'semibold',
    fontSize: '11px',
    gap: '8px',
    color: '#5E6566',
  },
  documentItem: {
    flexDirection: 'row',
    gap: '8px',
  },
  headerRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#5E6566',
    fontSize: '12px',
    fontWeight: 'semibold',
  },
  petLabel: {
    backgroundColor: '#E6EFF0',
    borderRadius: '2px',
    fontWeight: 'semibold',
    fontSize: '11px',
    color: '#303333',
    paddingHorizontal: '3px',
  },
  detailRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '8px',
    marginTop: '8px',
  },
  detailItem: {
    width: '166px',
  },
  fileLink: {
    color: '#303333',
    fontSize: '12px',
    textDecoration: 'none',
    fontWeight: 'normal',
    width: '170px',
  },
});

export const AnimalCardPDF = ({ animal }: Props): JSX.Element => {
  const photo = animal.images?.[0];

  return (
    <View>
      <View style={styles.detailContainer}>
        <View style={styles.headerRow}>
          <Text>{capitalize(animal.breed)}</Text>
          <View style={styles.petLabel}>
            <Text>{animal.furryFriendType}</Text>
          </View>
        </View>
        <View style={styles.detailRow}>
          <View style={styles.detailItem}>
            <FieldViewPDF isReversed label="Name" value={animal.name} />
          </View>
          <View style={styles.detailItem}>
            <FieldViewPDF isReversed label="Breed" value={animal.breed} />
          </View>
        </View>
        <View style={styles.detailRow}>
          <View style={styles.detailItem}>
            <FieldViewPDF isReversed label="Weight" value={String(animal.weight)} />
          </View>
          <View style={styles.detailItem}>
            <FieldViewPDF isReversed label="Age" value={`${animal.age} years`} />
          </View>
          <View style={styles.detailItem}>
            <FieldViewPDF isReversed label="Gender" value={capitalize(animal.genderType)} />
          </View>
        </View>
      </View>
      <View style={styles.photoContainer}>
        <div style={styles.documentItem}>
          <Text>{'Photo'}</Text>
          <FileCheckSVG />
          <Link src={photo?.fileLink} style={styles.fileLink}>
            {photo?.fileName}
          </Link>
        </div>
        {animal.documents?.map((document) => (
          <div style={styles.documentItem} key={`document-${document.id}`}>
            <Text>{'Document'}</Text>
            <FileCheckSVG />
            <Link src={document.fileLink} style={styles.fileLink}>
              {document.fileName}
            </Link>
          </div>
        ))}
      </View>
    </View>
  );
};
