import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import { ResumeMenuItemsStatus, ResumeStatesColors, SideMenuLevel } from 'constants/resumeSideMenuConstants';
import { getItemFromList, isOptionPresentInList } from 'helpers/resumeSideMenuHelper';
import { SelectedOption, SingleSideMenuItem } from 'shared/types/renterTypes';

import ColoredCircularPill from '../ColoredCircularPill/ColoredCircularPill';

import styles from './ResumeSideMenu.module.scss';
export interface ResumeSideMenuProps {
  selectedOption: string;
  menuList: SingleSideMenuItem[];
}

const ResumeSideMenu = ({ menuList, selectedOption }: ResumeSideMenuProps): JSX.Element => {
  const [selectedArray, setSelectedArray] = useState<SelectedOption[]>(getItemFromList(menuList, selectedOption));
  const onClickHandler = useCallback(
    (level: number, option: string) => {
      setSelectedArray(getItemFromList(menuList, option));
    },
    [menuList]
  );
  const renderMenuItem = useMemo(
    () =>
      function (items: SingleSideMenuItem[], level: number): JSX.Element {
        const itemsArray = items;

        return (
          <>
            {itemsArray.map((item, index) => (
              <div
                onMouseDownCapture={() => {
                  onClickHandler(level, item.heading);
                }}
                key={`Menu-${level}-Item-${item.heading}-${index}`}
                className={classNames(styles.ItemContainer, {
                  [styles.LeftBorder]: level === SideMenuLevel.ONE && item.subItems,
                  [styles.ExtraPadding]: level > SideMenuLevel.ONE,
                })}
              >
                <div className={styles.ItemContentContainer}>
                  <span
                    className={classNames(styles.Heading, {
                      [styles.LevelOne]: level === SideMenuLevel.ONE,
                      [styles.LevelTwo]: level > SideMenuLevel.ONE,
                      [styles.SelectedLevelOne]: isOptionPresentInList(selectedArray ?? [], {
                        option: item.heading,
                        level: SideMenuLevel.ONE,
                      }),
                      [styles.SelectedLevelTwo]:
                        isOptionPresentInList(selectedArray ?? [], {
                          option: item.heading,
                          level: SideMenuLevel.TWO,
                        }) && item.subItems,
                      [styles.SelectedLevelThree]:
                        isOptionPresentInList(selectedArray ?? [], {
                          option: item.heading,
                          level: level,
                        }) && level > SideMenuLevel.TWO,
                    })}
                  >
                    {item.heading}
                  </span>
                  {item.status && (
                    <div className={styles.StatusPillContainer}>
                      <ColoredCircularPill color={ResumeStatesColors[item.status as ResumeMenuItemsStatus]} />
                    </div>
                  )}
                </div>
                {item.subItems && renderMenuItem(item.subItems, level + 1)}
              </div>
            ))}
          </>
        );
      },
    [onClickHandler, selectedArray]
  );

  return (
    <div className={styles.ResumeSideMenuContainer}>
      <div className={styles.MenuListContainer}>{renderMenuItem(menuList, 1)}</div>
    </div>
  );
};

export default ResumeSideMenu;
