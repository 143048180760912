import { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import classNames from 'classnames';

// import { ReactComponent as NotificationIcon } from 'assets/svgs/notification_importantPinkBellIcon.svg';
// import { VerifierTenancyStatus } from 'constants/agentConstants';
import { VerifierNavbarProps } from 'shared/types/agentTypes';
import { AddressInformation, RenterPresentEmployments } from 'shared/types/renterTypes';

import styles from './VerifierNavbar.module.scss';

export const VerifierNavbar = ({
  tenancyList,
  activeTab,
  setSelectedAddress,
  setSelectedEmployment,
}: VerifierNavbarProps): JSX.Element => {
  const [isActiveTab, setIsActiveTab] = useState(activeTab ?? '');
  const onClickHandler = (address: AddressInformation | RenterPresentEmployments): void => {
    setIsActiveTab(address);

    if (setSelectedAddress) {
      setSelectedAddress(address);
    } else if (setSelectedEmployment) {
      setSelectedEmployment(address);
    }
  };

  return (
    <Navbar expand="lg" className={styles.Container}>
      <div className={styles.NavList}>
        {tenancyList.map((item) => (
          <Nav onClick={() => onClickHandler(item.address)} key={`Address-${item.title}-${item}`}>
            <div className={styles.NavItem}>
              <div className={classNames(styles.Title, { [styles.SelectedTitle]: isActiveTab === item.address })}>
                {item.title}
              </div>
              {/* {(item.status === VerifierTenancyStatus.WARNING || item.title === 'Present Address') && ( //the condition for (Present) is only for showing the notificaiton for demo
                <NotificationIcon />
              )} */}
            </div>
          </Nav>
        ))}
      </div>
    </Navbar>
  );
};
