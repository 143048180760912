import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { capitalize } from 'lodash-es';

import { getStreetAddress1 } from 'helpers/address';
import { formatAmount } from 'helpers/user';
import { ApplicationType } from 'shared/types/applicantsType';
import { AddPropertyParams } from 'shared/types/propertyType';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

interface Props {
  propertyInformation: AddPropertyParams;
  renterDetails: RenterProfileInformationProps;
  application: ApplicationType;
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  occupant: {
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
  renterName: {
    fontSize: '16px',
    fontWeight: 'semibold',
    color: '#303333',
  },
  apartmentCol: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    color: '#636A6B',
    fontSize: '11px',
  },
  rentRow: {
    flexDirection: 'row',
    gap: '8px',
    fontWeight: 'semibold',
  },
  rent: {
    color: '#2D3030',
    fontSize: '12px',
  },
  AddressContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  CityZipContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

export const HeaderPDF = ({ propertyInformation, renterDetails, application }: Props): JSX.Element => (
  <View style={styles.container} fixed>
    <View>
      <Text style={styles.occupant}>{`OCCUPANT ${capitalize(application.renterRole)}`}</Text>
      <Text style={styles.renterName}>{`${renterDetails.firstName} ${renterDetails.lastName}`}</Text>
    </View>
    <View style={styles.apartmentCol}>
      <View style={styles.rentRow}>
        <Text>{`Rent`}</Text>
        <Text style={styles.rent}>{`$${formatAmount(propertyInformation.monthlyRentAmount ?? 0)}`}</Text>
      </View>
      <View style={styles.AddressContainer}>
        <Text>
          {getStreetAddress1(propertyInformation?.streetAddress1 ?? '', propertyInformation?.city ?? '') ?? ''}
        </Text>
        <View style={styles.CityZipContainer}>
          <Text>{`${propertyInformation.city}, ${propertyInformation.state} ${propertyInformation.zipCode}`}</Text>
        </View>
      </View>
    </View>
  </View>
);
