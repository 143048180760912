import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export const MapPinOutlineSVG = (): JSX.Element => (
  <Svg width="10" height="14" viewBox="0 0 10 14" fill="white">
    <G clip-path="url(#clip0_9997_109706)">
      <G>
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.75 5C8.75 7.072 5 12.75 5 12.75C5 12.75 1.25 7.072 1.25 5C1.25 2.929 2.9285 1.25 5 1.25C7.071 1.25 8.75 2.929 8.75 5Z"
          stroke="#636A6B"
        />
        <Path
          d="M5 6.5C5.82843 6.5 6.5 5.82843 6.5 5C6.5 4.17157 5.82843 3.5 5 3.5C4.17157 3.5 3.5 4.17157 3.5 5C3.5 5.82843 4.17157 6.5 5 6.5Z"
          stroke="#636A6B"
        />
      </G>
    </G>
    <Defs>
      <ClipPath id="clip0_9997_109706">
        <Rect width="10" height="14" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
