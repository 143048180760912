import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import styles from './PropertyIndex.module.scss';

const PropertyConatinerSkeleton = (): JSX.Element => (
  <Row>
    <Col lg={4} md={4} sm={12} />
    <Col lg={4} md={4} sm={12} className={styles.paginationCol}>
      <Skeleton height={30} className={styles.skeletonWidth60} />
    </Col>
    <Col lg={4} md={4} sm={12} className={styles.perPageCol}>
      <Skeleton width={116} height={30} className={styles.addPaddingRight} />
    </Col>
  </Row>
);

export default PropertyConatinerSkeleton;
