import { StyleSheet } from '@react-pdf/renderer';

export const sharedV2Styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: '20px 30px',
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
  },
});
