/* eslint-disable @typescript-eslint/naming-convention */
import { EmploymentStatus } from 'shared/types/renterEmploymentInformation';
import { FieldType } from 'shared/types/renterSignup';
import { StringHashType } from 'shared/types/renterTypes';

//Max field length for agent and applicant(renter) name is 1 less as we are not counting spaces here
// with spaces agent:  20 , applicant : 14
export const MAX_FIELD_LENGTH: Record<FieldType, number> = {
  agentFullName: 19,
  address: 39,
  applicantFullName: 13,
};
export const dummyDate = 'August 4, 2023 1:24 PM PDT';
export const PLACEHOLDER_PHONE_NUM = '1234567890';
export const UNINITIALIZED_STRIPE_VERIFICATION_ERROR = 202;
export const APPLICATION_REGISTRATION_AMOUNT = 30;
export const endDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toString();
export const defaultDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
export const encodedRenterRole = 'cmVudGVy';

export const BUSINESS_NAME_MIN_CHAR = 5;
export const BUSINESS_NAME_MAX_CHAR = 50;

export const renterCriteria1 = `An application is unique to an individual. As the potential renter, submit all required details using only your personal, full, and legal details. Incorrect information may cause delays!`;
export const renterCritera2 = `All parties 18 years of age or older are each required to complete an individual application and pay all applicable fees.`;

export const householdText1 = `A primary occupant will start the process, adding each co-occupant. When acceptable, an individual occupant may add their own guarantor.`;
export const householdText2 = `Each additional adult applicant will receive an email notification where they should then start their individual submission.`;
export const bePreparedText = `All submitted data will be verified. Ensure all information is accurate and documents are up-to-date.`;
export const creditBackground1 = `A potential renter with a social security number is asked to share their Experian reports with the selected housing provider.`;
export const creditBackground2 = `Sharing reports is a soft-inquiry and does NOT impact your credit history.`;
export const dashboardText = `After payment, your agent is immediately notified! Be sure to review all submitted details to ensure the accurate and timely submission of a complete application.`;
export const disclaimer = `By continuing, you understand the application fee is non-refunable, regardless of the outcome.`;

export const PhoneNumberType = [
  { label: 'Mobile', name: 'Mobile' },
  { label: 'Home', name: 'Home' },
  { label: 'Office', name: 'Office' },
];
export const backgroundQuestionOptions = [
  { label: 'Yes', name: 'YES' },
  { label: 'No', name: 'NO' },
];
export const EmploymentStatusType: EmploymentStatus[] = [
  { label: 'Student', name: 'student' },
  { label: 'Retired', name: 'retired' },
  { label: 'Homemaker', name: 'homemaker' },
];
export const IdTypes = [
  { label: 'Driver’s License', value: 'drivers-License' },
  { label: 'Passport', value: 'passport' },
  { label: 'State ID', value: 'state-Id' },
  { label: 'National ID', value: 'national-id' },
];
export const RenterBackgroundQuestions = {
  backgroundInformation: [
    {
      id: 30,
      backgroundQuestion: {
        id: 1,
        question:
          'Have you (or any person on this application) ever filed for, or currently involved in a bankruptcy, been foreclosed on, or been a defendant in a civil suit?',
      },
      answer: 'Yes',
      explanation: 'gkjhvhvjh',
      createdAt: '2023-12-24T20:15:56.920146Z',
      updatedAt: null,
    },
    {
      id: 31,
      backgroundQuestion: {
        id: 2,
        question:
          'Have you (or anyone named in this application) ever been evicted from a tenancy or left owing money?',
      },
      answer: 'Yes',
      explanation: 'jhvhjvjhh',
      createdAt: '2023-12-24T20:15:56.926621Z',
      updatedAt: null,
    },
    {
      id: 32,
      backgroundQuestion: {
        id: 3,
        question:
          'Do you or any of the members of your household have pending criminal charges, or ever been convinced of, plead guilty or no contest to, any criminal offense(s) or had any criminal offense(s) other than traffic infractions that were disposed of other than by acquittal or a finding of ‘not guilty’?',
      },
      answer: 'No',
      createdAt: '2023-12-24T20:15:56.933034Z',
      updatedAt: null,
    },
  ],
};

export const RenterPersonalInformation = {
  firstName: 'Jennifer',
  middleName: 'Bianca',
  lastName: 'Roberts',
  dateOfBirth: '12/12/1984',
};
export const InitialMoveInDate = {
  moveInDate: '',
};
export enum RenterProfileSteps {
  BASICINFO = 'basicInfo',
  PHONENUMBER = 'phoneNumber',
  SSNCHECK = 'SSNCheck',
  SSNINPUT = 'SSNInput',
  PROFILESUMMARY = 'profileSummary',
  MANULLYADDINFORMATION = 'manullyAddInformation',
}
export enum StripeManualVerificationSteps {
  RENTER_INFO = 'renterInfo',
  ID_INFO = 'idInfo',
}

export enum RenterOccupantType {
  MINORS = 'Minors',
  MINOR = 'Minor',
  ADULTS = 'Adults',
  OCCUPANT = 'Occupant',
}
export const RenterCoOccupantButtonType = {
  ANOTHERBUTTON: '+ Add another minor',
  CONTINUE: 'continue',
};
export const startDate = '01,01,1959';
export const SSN_LENGTH = 9;
export enum RenterAddressSteps {
  PRESENT_ADDRESS = 'presentAddress',
  PRIOR_ADDRESS = 'priorAddress',
  ADDRESS_HISTORY = 'addressHistory',
  PRESENT_ADDRESS_CONFIRMATION = 'presentAddressConfirmation',
}

export const STRIPE_NEXT_NAVIGATION = `&next=${RenterAddressSteps.PRESENT_ADDRESS_CONFIRMATION}`;

export enum RenterAddressTypes {
  PRESENT = 'PRESENT',
  PRIOR = 'PRIOR',
  CUSTOM = 'CUSTOM',
}

export const NAME_MIN_LENGTH = 2;
export const PROPERTY_NAME_MAX_LENGTH = 100;
export const NAME_MAX_LENGTH = 50;
export const CITY_MAX_LENGTH = 27;
export const SUITE_MAX_LENGTH = 100;
export const EMPLOYMENT_TITLE_MAX_LENGTH = 50;
export const TEXT_MAX_LENGTH = 250;
export const LICENCE_MAX_LENGTH = 12;

export const DESCRIPTION_MIN_LENGTH = 5;
export const DESCRIPTION_MAX_LENGTH = 50;

export const EmploymentSubTypes: StringHashType = {
  selfEmployed: 'self-employed',
  employed: 'current-employed',
  other: 'others',
};
export const MAX_RENT_AMOUNT = 20000;
export const MIN_RENT_AMOUNT = 1;

export enum RenterGuarantorSteps {
  CHECK = 'check',
  FORM = 'form',
  SUMMARY = 'summary',
}

export enum RenterVehicleSteps {
  CHECK = 'check',
  TYPE = 'selectType',
  FORM = 'form',
  CONFIRMATION = 'confirmation',
}

export enum RenterAnimalSteps {
  CHECK = 'check',
  SELECTION = 'selection',
  FORM = 'form',
  SUMMARY = 'summary',
  CONFIRMATION = 'confirmation',
}
export enum VehicleTypes {
  CAR = 'car',
  MOTORCYCLE = 'motor-cycle',
}
export const vehicleSubTypes = [VehicleTypes.CAR, VehicleTypes.MOTORCYCLE];
export const VehiclesType = [
  { label: 'Car', value: VehicleTypes.CAR },
  { label: 'Motorcycle', value: VehicleTypes.MOTORCYCLE },
];
export enum AnimalType {
  PETS = 'Pet',
  ASSISTIVE_ANIMAL = 'Assistive animal',
}
export enum AnimalSubType {
  DOG = 'Dog',
  CAT = 'Cat',
  BIRD = 'Bird',
  OTHER = 'Other',
}
export const animalSubtypes = [AnimalSubType.DOG, AnimalSubType.CAT, AnimalSubType.BIRD, AnimalSubType.OTHER];

export enum FurryFriendTypes {
  PETS = 'Pet',
  ASSISTIVE_ANIMAL = 'assistive-animal',
  SERVICE_PETS = 'service-animal',
  OTHER = 'other-support-animal',
}
export enum FurryFriendParamTypes {
  ANIMAL_TYPE = 'animalType',
  FURRY_FRIEND_TYPE = 'furryFriendType',
  ASSISTIVE_ANIMAL_TYPE = 'assistiveAnimalType',
}
export enum AssistiveAnimalType {
  SERVICE_PETS = 'Service Animal',
  OTHER = 'Other Support Animal',
}

export const AssistiveAnimalOption = [
  { label: 'Service Animal', value: 'service-animal' },
  { label: 'Other Support Animal', value: 'other-support-animal' },
];
export const GenderType = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];
export const AgeType = [
  { label: 'Yrs', value: 'year' },
  { label: 'Mon', value: 'month' },
];

export const DEFAULT_PHONE_NUMBER_CODE = '+1';

export enum PaymentVerificationKeys {
  PERSONAL_PROFILE = 'Personal Profile',
  HOUSEHOLD_DETAILS = 'Household',
  ADDRESS_HISTORY = 'Address History',
  SOURCE_OF_INCOME = 'Source of Income',
  VEHICLE_INFORMATION = 'Vehicles',
  ANIMAL_INFORMATION = 'Animals',
  BACKGROUND_QUESTIONS = 'Background Questions',
}

export const QuestionAnswerKey = {
  SELECT: 'SELECT',
  YES: 'YES',
  NO: 'NO',
};

export const VerificationQuestionType = {
  DATE: 'DATE',
  TEXT: 'TEXT',
  DROPDOWN: 'DD_NUM',
};

export const VerificationAnswerType = {
  NA: 'na',
};

export const PaymentVerificationSteps = [
  PaymentVerificationKeys.PERSONAL_PROFILE,
  PaymentVerificationKeys.HOUSEHOLD_DETAILS,
  PaymentVerificationKeys.ADDRESS_HISTORY,
  PaymentVerificationKeys.SOURCE_OF_INCOME,
  PaymentVerificationKeys.VEHICLE_INFORMATION,
  PaymentVerificationKeys.ANIMAL_INFORMATION,
  PaymentVerificationKeys.BACKGROUND_QUESTIONS,
];

export enum HouseholdDetailsKeys {
  ADULTS = 'adults',
  MINORS = 'minors',
  VEHICLE = 'vehicles',
}

export enum NavigationKeys {
  NEXT = 'next',
  SECTION = 'section',
}

export enum SourceOfIncomeKeys {
  PRIOR = 'prior-income',
  PRESENT = 'present-income',
}

export enum RenterInviteTypes {
  ADULT_INVITE = 'co-occupant',
}
export enum CreditBackgroundKeys {
  CREDIT_REPORTS = 'Credit Report',
  CRIMINAL_RECORDS = 'Criminal Records',
  HOUSING_COURT_RECORDS = 'Housing Court Records',
}
export const RenterCreditBackgroundSteps = [
  CreditBackgroundKeys.CREDIT_REPORTS,
  CreditBackgroundKeys.CRIMINAL_RECORDS,
  CreditBackgroundKeys.HOUSING_COURT_RECORDS,
];

export enum ExperianAuthenticationStatus {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  IN_PROGRESS = 'InProgress',
}

export enum VisaTypes {
  INTERNATIONAL_STUDENT = 'international_student',
  WORK_VISA = 'work_visa',
  OTHER = 'other',
}

export enum CreditCheckProvider {
  EXPERIAN = 'EXPERIAN',
  NOVA = 'NOVA',
  DOCUMENT = 'DOCUMENT',
}

export enum NovaAuthenticationStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  NOT_FOUND = 'NOT_FOUND',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  ERROR = 'ERROR',
  BUREAU_UNRESPONSIVE = 'BUREAU_UNRESPONSIVE',
  UNSUPPORTED_COUNTRY = 'UNSUPPORTED_COUNTRY',
  SKIPPED = 'SKIPPED',
  FINISH_LATER_INITIATED = 'FINISH_LATER_INITIATED',
}

export const addressClassificationOptions = [
  { label: 'I own this home', value: 'I own this home' },
  { label: 'I live with family or friends', value: 'I live with family or friends' },
  { label: 'Other', value: 'Other' },
];

export const OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY = 2;

export const NOVA_CREDIT_STATUS = {
  NOT_FOUND: 'The applicant could not be found in the database overseas. There is no Nova Credit Passport® to retrieve',
  NOT_AUTHENTICATED:
    'The applicant was found but could not be authenticated. There is no Nova Credit Passport® to retrieve',
  ERROR: 'An internal error occurred and the Nova Credit Passport® could not be compiled.',
  BUREAU_UNRESPONSIVE:
    'Encountered a bureau outage while going through Nova. You may need to go through NovaConnect again at a later time',
  UNSUPPORTED_COUNTRY:
    'You have a credit history in a country that is not yet supported. There is no Nova Credit Passport® to retrieve',
};
