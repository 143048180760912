import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import InviteApplicantModal from 'components/Agent/components/shared/InviteApplicantModal/InviteApplicantModalHandler';
import { ApplicationRequestValues } from 'constants/applicationConstants';
import { propertyGeneralInviteInitialValues } from 'shared/types/agentTypes';
import { RentalRequest } from 'shared/types/authType';
import { PropertyType as PropertyTypes } from 'shared/types/propertType';
import { PropertyProps, PropertyRowCardProps } from 'shared/types/propertyType';

import styles from './../PropertyIndex.module.scss';
export type PropertyInviteProps = {
  property: PropertyProps;
};

const PropertyInvite = ({ property }: PropertyRowCardProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <span
        className={classNames(styles.propertyLinksLarge, {
          [styles.disabledInvite]:
            property?.propertyStatus === PropertyTypes.DRAFT || property?.propertyStatus === PropertyTypes.ARCHIVED,
        })}
        onClickCapture={() => {
          setIsModalOpen(true);
        }}
      >
        {t('organizations.inviteAgentPropertySpecific')}
      </span>
      {isModalOpen && (
        <InviteApplicantModal
          inviteType={ApplicationRequestValues.APPLICATION_AND_REPORTS}
          isModalOpen={isModalOpen}
          onHide={() => setIsModalOpen(false)}
          invitationInitialValues={{ ...propertyGeneralInviteInitialValues, propertyId: property.id } as RentalRequest}
          propertyAddress={property.streetAddress1 ?? 'Street Address'}
        />
      )}
    </>
  );
};

export default PropertyInvite;
