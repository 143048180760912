import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ReportItem } from 'components/shared/ReportItem/ReportItem';
import {
  tradeLineExtraLabelsToHide,
  TradelineLowerHeaderLabels,
  tradeLinesAmountColumns,
  tradeLinesExtraFirstColumnLabels,
  tradeLinesFirstColumnLabels,
  tradeLinesSecondColumnLabels,
} from 'constants/reportConstants';
import { convertResponseToObject, getValueFormated } from 'helpers/reportsHelper';
import { TradeLineAmountItem, TradeLineReportProps } from 'shared/types/reportTypes';

import { TradeCalendar } from '../TradeCalendar/TradeCalendar';
import { TradeIconLegend } from '../TradeIconLegend/TradeIconLegend';

import styles from './TradeLineReport.module.scss';
export const TradeLineReport = ({ tradeLineReport, isRenterFlow }: TradeLineReportProps): JSX.Element => {
  const convertedTradeLineReport = convertResponseToObject(tradeLineReport);
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const convertedAmountObject: any = convertResponseToObject(tradeLineReport?.Amount ?? ([] as TradeLineAmountItem[]));
  const paymentProfile = getValueFormated(convertedTradeLineReport['PaymentProfile']);

  return (
    <div className={styles.Container}>
      <div className={styles.HeaderContainer}>
        <div className={styles.LeftContent}>
          <div className={styles.UpperLeftContent}>
            <div className={styles.DisplayName}>
              {getValueFormated(convertedTradeLineReport['SubscriberDisplayName'])}
            </div>
            <div className={styles.KindOfBusiness}> {getValueFormated(convertedTradeLineReport['KOB'])}</div>
          </div>
          <div className={styles.LowerLeftContent}>
            {TradelineLowerHeaderLabels.map(
              (label, index) =>
                getValueFormated(convertedTradeLineReport[label]) !== '—' && (
                  <Fragment key={`lower-trade-header-${label}-${index}`}>
                    <span className={styles.LowerHeaderLabel}>{getValueFormated(convertedTradeLineReport[label])}</span>
                    {TradelineLowerHeaderLabels.length - 1 > index && <span className={styles.Divider}>|</span>}
                  </Fragment>
                )
            )}
            <span className={styles.LowerHeaderLabel}>{getValueFormated(convertedTradeLineReport['BalanceDate'])}</span>
          </div>
        </div>
        {convertedTradeLineReport['FirstDelinquencyDate'] && (
          <div className={styles.RightContent}>{t('creditReport.negative')}</div>
        )}
      </div>
      <Row className={styles.RowContainer}>
        <Col md={12} lg={isRenterFlow ? 12 : 6}>
          {Object.keys(tradeLinesFirstColumnLabels).map(
            (label, index) =>
              (label !== 'StatusDate' ||
                (!!convertedTradeLineReport[label] &&
                  label === 'StatusDate' &&
                  !!convertedTradeLineReport['Status'])) && (
                <div key={`trade-line-label-${index}`}>
                  <ReportItem
                    label={tradeLinesFirstColumnLabels[label]}
                    value={getValueFormated(convertedTradeLineReport[label])}
                    isValueBold={true}
                  />
                </div>
              )
          )}
          {tradeLinesAmountColumns.map(
            (label, index) =>
              !!convertedAmountObject[label] && (
                <ReportItem
                  key={`amount-${label}-${index}`}
                  label={convertedAmountObject[label].Qualifier.desc}
                  value={getValueFormated(convertedAmountObject[label].Value)}
                  isValueBold={true}
                />
              )
          )}
        </Col>
        <Col md={12} lg={isRenterFlow ? 12 : 6}>
          <ReportItem
            label={t('creditReport.paymentStatus')}
            value={getValueFormated(convertResponseToObject(tradeLineReport.EnhancedPaymentData)['PaymentStatus'])}
            isValueBold
          />
          {Object.keys(tradeLinesSecondColumnLabels).map((label, index) => (
            <div key={`trade-line-second-label-${index}`}>
              <ReportItem
                label={tradeLinesSecondColumnLabels[label]}
                value={getValueFormated(convertedTradeLineReport[label])}
                isValueBold
              />
            </div>
          ))}
        </Col>
        <Col md={12}>
          {Object.keys(tradeLinesExtraFirstColumnLabels).map(
            (label, index) =>
              (!tradeLineExtraLabelsToHide.includes(label) ||
                (tradeLineExtraLabelsToHide.includes(label) &&
                  getValueFormated(convertedTradeLineReport[label]) !== '—')) && (
                <div key={`trade-line-second-label-${index}`}>
                  <ReportItem
                    label={tradeLinesExtraFirstColumnLabels[label]}
                    value={getValueFormated(
                      label === 'AccountCondition'
                        ? convertResponseToObject(tradeLineReport.EnhancedPaymentData)[label]
                        : convertedTradeLineReport[label]
                    )}
                    isValueBold
                    customLabelClass={styles.CustomLabelClass}
                  />
                </div>
              )
          )}
        </Col>
      </Row>
      <div className={styles.PaymentHistoryContainer}>
        <div className={styles.Tag}>{t('agent.creditReport.tradelineReport.paymentHistory')}</div>
        <div className={styles.TagValue}>{`(${getValueFormated(
          convertedTradeLineReport['MonthsHistory']
        )} months)`}</div>
      </div>
      <div className={styles.ScrollAbleContent}>
        <TradeIconLegend paymentProfile={paymentProfile} />
        <TradeCalendar
          balanceDate={getValueFormated(convertedTradeLineReport['BalanceDate'])}
          paymentProfile={paymentProfile}
        />
      </div>
    </div>
  );
};
