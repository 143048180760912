/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import RCButton from 'components/shared/Button/Button';
import { ImageDimensions } from 'components/shared/Cropper/Cropper';

import ImageProgressBox from '../../ImageProgressBox/ImageProgressBox';

import indexStyles from '../Dropzone.module.scss';
import styles from './CustomDropzone.module.scss';
export interface CustomPreviewFile extends FileWithPath {
  preview?: string;
  dimension?: ImageDimensions;
  url?: string;
  isLoading: boolean;
}

interface CustomDropzoneProps {
  acceptedFiles: CustomPreviewFile[];
  setAcceptedFiles: Dispatch<React.SetStateAction<CustomPreviewFile[]>>;
  isPdfAllowed?: boolean;
  renderInDropzone?: boolean;
  showUploadBox?: boolean;
  onlyOneAllowed?: boolean;
  customImage?: string;
  className?: string;
  showFiles?: boolean;
}
const CustomDropzone = ({
  acceptedFiles,
  setAcceptedFiles,
  isPdfAllowed = true,
  renderInDropzone = false,
  showUploadBox = false,
  onlyOneAllowed = false,
  customImage,
  className,
  showFiles = true,
}: CustomDropzoneProps): JSX.Element => {
  const { t } = useTranslation();
  const isAnyImageStillInProgress = (!!acceptedFiles && acceptedFiles.some((el) => el.isLoading)) || false;
  const removeFile = (file: FileWithPath): void => {
    setAcceptedFiles(acceptedFiles.filter((f) => f.path !== file.path));
  };
  const { getRootProps, open, getInputProps } = useDropzone({
    maxSize: 26214400,
    noClick: true,
    noKeyboard: true,
    // type of file
    accept: isPdfAllowed
      ? {
          'image/png': ['.png'],
          'image/jpeg': ['.jpeg'],
          'image/jpg': ['.jpg'],
          'application/pdf': ['.pdf'],
        }
      : {
          'image/png': ['.png'],
          'image/jpeg': ['.jpeg'],
          'image/jpg': ['.jpg'],
        },

    // create the list of file, if the file type is HEIC execute if block, other case else
    onDrop: (acceptedFiles1) => {
      const tempFiles = acceptedFiles1.filter((f) => !acceptedFiles.some((cItem) => cItem.name === f.name));
      const tempData: CustomPreviewFile[] = [];

      tempFiles.forEach((f) => {
        const reader = new FileReader();

        if (isPdfAllowed && f.type === 'application/pdf') {
          reader.readAsArrayBuffer(f);
          reader.onload = function () {
            const pdf = new Uint8Array(reader.result as ArrayBuffer);
            const pdfUrl = URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' }));
            const previewFile: CustomPreviewFile = Object.assign(f, {
              preview: pdfUrl,
              dimension: undefined,
              url: pdfUrl,
              isLoading: true,
            });

            tempData.push(previewFile);
            setAcceptedFiles([...acceptedFiles, ...tempData]);
          };
        } else if (f.type.startsWith('image/')) {
          reader.readAsDataURL(f);
          reader.onload = function () {
            const img = new Image();

            img.src = reader.result as string;
            // get the height and width of image
            img.onload = function () {
              const previewFile: CustomPreviewFile = Object.assign(f, {
                preview: URL.createObjectURL(f),
                dimension: {
                  height: img.height,
                  width: img.width,
                },
                url: img.src,
                isLoading: true,
              });

              tempData.push(previewFile);
              setAcceptedFiles([...acceptedFiles, ...tempData]);
            };
          };
        }
      });
    },
  });

  return (
    <section
      className={classNames(styles.customDropzoneContainer, {
        [styles.hideCustomDropzone]: renderInDropzone || showUploadBox ? false : isAnyImageStillInProgress,
      })}
      {...getRootProps()}
    >
      <div
        className={classNames('dropzone', className, indexStyles.noFilesDropzone, styles.customDropzone, {
          [styles.customImageBackground]: customImage,
        })}
      >
        <input {...getInputProps({ multiple: !onlyOneAllowed })} />
        <div className={classNames(styles.customDropzoneTextContainer, styles.customDropzoneImage)}>
          <div
            className={classNames(styles.customDropzoneText, {
              [styles.customImageText]: customImage,
            })}
          >
            {customImage && <img src={customImage} alt={`${t('imageAlt.Profile')}`} className={styles.lowerOpacity} />}
            <p className={styles.customDropzoneP}>
              {customImage ? (
                <div className={styles.customImageTextContainer}>
                  <span className={styles.customImageStatement}>
                    <RCButton onClick={open} className={styles.customImageButton}>
                      {t('customDropzone.iUpload')}
                    </RCButton>{' '}
                    {t('customDropzone.anotherPhotoYourself')}
                  </span>
                  <div className={styles.customImageAcceptedText}>
                    {isPdfAllowed
                      ? t('customDropzone.acceptedFormatsJPEG,PDF,PNG,JPG')
                      : t('customDropzone.acceptedFormatsJPEG,PNG,JPG')}
                  </div>
                </div>
              ) : (
                t('customDropzone.dropYourFile(s)InHereOrChooseFileTo')
              )}
              {!customImage && (
                <RCButton onClick={open} className={styles.customDropzoneButton}>
                  {t('customDropzone.upload')}
                </RCButton>
              )}
            </p>
          </div>

          <div>
            <input {...getInputProps({ multiple: !onlyOneAllowed })} />

            {showFiles &&
              renderInDropzone &&
              acceptedFiles.map((file, index) => (
                <div key={index} className={styles.imageRenderContainer}>
                  <ImageProgressBox
                    fileName={file.name}
                    progress={64}
                    label={t('renter.fileUploadingState.uploadingFile')}
                    onRemoveHandler={() => removeFile(file)}
                  />
                </div>
              ))}
          </div>
          {!customImage && (
            <span className={styles.customDropzoneAcceptedText}>
              {isPdfAllowed
                ? t('customDropzone.acceptedFormatsJPEG,PDF,PNG,JPG')
                : t('customDropzone.acceptedFormatsJPEG,PNG,JPG')}
            </span>
          )}
        </div>
      </div>
    </section>
  );
};

export default CustomDropzone;
