import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useField } from 'formik';
import lodash from 'lodash';
import { IOptionProps } from 'pages/AgentPofileForm/types';
import { amenitiesList } from 'redux/selectors/propertySelector';
import { updateAmenitiesFeature } from 'redux/slices/propertySlice';

import { ReactComponent as BlackCrossIcon } from 'assets/svgs/BlackCrossIcon.svg';
import { ReactComponent as Spinner } from 'assets/svgs/BlueSyncIcon.svg';
import Button from 'components/shared/Button/Button';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import FormikField from 'components/shared/TextField/FormikField';
import { CustomFeatureData } from 'constants/propertyConstants';
import { AddPropertyParams } from 'shared/types/propertyType';

import styles from './AmenitiesFeature.module.scss';

export type Props = {
  onClick: () => void;
  name: string;
  handleBlur: (params: AddPropertyParams) => void | undefined;
};
const AddCustomFeature = ({ onClick, name, handleBlur }: Props): JSX.Element => {
  const [featureType, setFeatureType] = useState<IOptionProps | null>();
  const [tagError, setTagError] = useState('');
  const [isAnimationStart, setStartAnimation] = useState(false);
  const [{ value }, meta, { setTouched, setValue }] = useField(name);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const amenitiesFeatureList = useSelector(amenitiesList);
  const onClickSave = (): void => {
    if (!value.length || !featureType?.value?.length || meta.error) {
      return;
    }

    const isExistFeature = amenitiesFeatureList.some(
      (el) => lodash.lowerCase(el.name) === lodash.lowerCase(value) && el.type === lodash.lowerCase(featureType.value)
    );

    if (isExistFeature) {
      setTagError(`${value} already exist in ${featureType.value.toLocaleLowerCase()} section`);

      return;
    }

    setStartAnimation(true);
    // add the slice after one second to perform the animation

    const updatedData = [
      ...amenitiesFeatureList,
      {
        name: value,
        isSelected: true,
        type: featureType.value?.toLocaleLowerCase(),
        isCustom: true,
        isAnimation: true,
      },
    ];
    const selectTags = updatedData.filter((el) => el.isSelected);
    const unitList = selectTags.filter((el) => el.type === 'unit').map((el) => el.name);
    const propertyList = selectTags.filter((el) => el.type === 'property').map((el) => el.name);

    handleBlur({
      propertyAmenities: unitList,
      unitAmenities: propertyList,
    });
    setTimeout((): void => {
      dispatch(updateAmenitiesFeature(updatedData));
      setValue('');
      setFeatureType(null);
      setStartAnimation(false);
    }, 500);
  };

  useEffect(() => {
    if (value) {
      setTouched(true);
    }

    setTagError('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  useEffect(() => {
    setValue('');
    setTagError('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={styles.addCustomFeature}>
        <Row className={styles.customFeatureContainer}>
          <Col lg={5} md={12} sm={12}>
            <div className={styles.featureLabel}>{t('agent.addProperty.customFeaturePresentFor')}</div>
            <ReactSelect
              options={CustomFeatureData}
              placeholder="Select location"
              onChange={(el) => {
                setFeatureType(el as IOptionProps);
                setTagError('');
              }}
              value={featureType}
              className={styles.reactSelectCustomStyling}
              isSearchable={false}
            />
            <div className={styles.customFeatureError} />
          </Col>
          <Col lg={5} md={12} sm={12}>
            <div className={styles.featureLabel}>{t('agent.addProperty.customFeaturePresentName')}</div>
            <FormikField name={name} className={styles.inputFiled} />
            {meta.error && meta.touched ? null : <div className={styles.customFeatureError} />}
          </Col>
          <Col className={styles.saveButtonContainer} lg={2} md={12} sm={12}>
            <Button
              className={classNames(styles.saveButton, styles.saveButtonActive)}
              variant="outline"
              onClick={onClickSave}
              disabled={!featureType || !value || !!meta.error}
            >
              {isAnimationStart ? <Spinner className={styles.icon} /> : <span className={styles.plus}>+</span>}
              <span className={styles.saveSpan}>{isAnimationStart ? 'Saving' : 'Save'}</span>
            </Button>
            <div className={styles.customFeatureError} />
          </Col>
          <div className={styles.crossBtnCustomFeature}>
            <BlackCrossIcon onClick={onClick} />
          </div>
        </Row>
      </div>
      <div className={styles.tagError}>{tagError}</div>
    </div>
  );
};

export default AddCustomFeature;
