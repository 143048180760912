import { getUniqueEmploymentVerifiers } from 'helpers/helper';
import { formatAddressAndEmploymentResponseToPDF } from 'helpers/verification';
import { VerificationPDF, VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

interface Props {
  employmentVerificationInformation: AddressVerificationResponse[];
  renterDetails: RenterProfileInformationProps;
}

export const generateEmploymentVerificationPDFComponents = ({
  employmentVerificationInformation,
  renterDetails,
}: Props): JSX.Element[] =>
  getUniqueEmploymentVerifiers(employmentVerificationInformation).map((employment, index) => {
    const { questionnaire, userEmploymentDetails } = formatAddressAndEmploymentResponseToPDF({
      type: VerifierURLType.VOE,
      renterDetails,
      employmentResponse: employment,
    });

    return (
      <VerificationPDF
        key={employment.renterEmploymentInformation?.presentEmploymentId}
        questionnaire={questionnaire}
        userEmploymentDetails={userEmploymentDetails}
        type={VerifierURLType.VOE}
        isEmbedded={true}
        isLastPage={index === getUniqueEmploymentVerifiers(employmentVerificationInformation).length - 1}
      />
    );
  });
