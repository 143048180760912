interface Props {
  comp: JSX.Element;
  count: number;
}
const Wrapper = (props: Props): JSX.Element => {
  const { comp: Component, count } = props;
  const components = Array(count)
    .fill(null)
    .map((_) => Component);

  return <>{components.map((el) => el)}</>;
};

export default Wrapper;
