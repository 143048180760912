import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { selectRenterApplication } from 'redux/selectors/agentSelector';

// import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/HorizontalDotsIcon.svg';
import { ReactComponent as PersonIcon } from 'assets/svgs/PersonIdentityIcon.svg';
import styles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import { SectionTitle } from 'components/Agent/Applications/ApplicationDetails/components/shared';
import { formatName } from 'helpers/helper';
import { formatPhoneNumber } from 'helpers/renterHelper';

import { GuarantorDetails, RenterDetails } from './components';

const RenterPersonalProfile = (): JSX.Element => {
  const renterDetails = useSelector(selectRenterApplication);
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.container, styles.ApplicationDetails)}>
      <div className={styles.infoDiv}>
        <SectionTitle
          mainTitle={t('imageAlt.Profile')}
          iconElement={<PersonIcon />}
          // optionElement={<ThreeDotsIcon width={24} height={24} />}
          shouldShowOptions
        />
        <RenterDetails />

        {!isEmpty(renterDetails?.guarantor) && (
          <>
            <SectionTitle
              description={t('renter.guarantorInformationForm.guarantor')}
              // optionElement={<ThreeDotsIcon width={24} height={24} />}
              shouldShowSubHeadingOptions
            />
            <GuarantorDetails
              contactFullName={formatName(renterDetails.guarantor.firstName, renterDetails.guarantor.lastName)}
              relationship={renterDetails.guarantor.relationship}
              email={renterDetails.guarantor.emailAddress}
              phone={formatPhoneNumber(renterDetails.guarantor.phoneNumber)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RenterPersonalProfile;
