import { FocusEvent } from 'react';
import { ErrorMessage, useField } from 'formik';

import { AddPropertyParams } from 'shared/types/propertyType';

import CustomErrorMessage from '../CustomErrorMessage/ErrorMessage';

import TextField, { IInputProps } from './TextField';

import styles from './FormikTextField.module.scss';

const FormikField = (
  props: IInputProps & { name: string; handleBlur?: (params: AddPropertyParams) => void }
): JSX.Element => {
  const { name, handleBlur } = props;
  const [field, meta] = useField(name);
  const onBlur = (e: FocusEvent): void => {
    field.onBlur(e);

    if (handleBlur && !meta.error) {
      handleBlur && handleBlur({ [name]: field.value });
    }
  };

  return (
    <div className="formik-field">
      <TextField {...props} {...field} isError={meta.touched && !!meta.error} onBlur={onBlur} />

      <div className={styles.minHeight}>
        {!!meta.error && (
          <ErrorMessage render={(errorMessage) => <CustomErrorMessage message={errorMessage} />} name={name} />
        )}
      </div>
    </div>
  );
};

export default FormikField;
