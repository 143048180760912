import { useState } from 'react';
import { useFormikContext } from 'formik';

import { AddressInitialValuesProps, PresentAddressInformationProps } from 'shared/types/renterTypes';

import { AddressLivingSituation } from '../AddressLivingSituation/AddressLivingSituation';
import { AddressLivingSituationSummary } from '../AddressLivingSituationSummary/AddressLivingSituationSummary';

export const PresentAddressInformation = ({
  address,
  onClickHandler,
  isPrior = false,
  mainHeading,
  subHeading,
  isForRent = false,
  customSubButtonText,
  customMainButtonText,
}: PresentAddressInformationProps): JSX.Element => {
  const { values } = useFormikContext<AddressInitialValuesProps>();
  const [isConfirmPage, setIsConfirmPage] = useState(isPrior && isForRent);
  const onDoneClickHandler = (): void => {
    setIsConfirmPage(true);
  };

  return (
    <>
      {!isConfirmPage && (
        <AddressLivingSituation
          onClickHandler={onDoneClickHandler}
          address={address}
          mainHeading={mainHeading}
          subHeading={subHeading}
          isPrior={isPrior}
          customButtonText={customMainButtonText}
          isForRent={isForRent}
        />
      )}
      {isConfirmPage && (
        <AddressLivingSituationSummary
          isForRent={isForRent}
          onClickHandler={onClickHandler}
          address={values.renterAddress}
          customButtonText={customSubButtonText}
        />
      )}
    </>
  );
};
