import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';

import Button from 'components/shared/Button/Button';
import { NavigationKeys } from 'constants/renterConstants';
import { renterRoutes } from 'shared/routes';
import { HavePriorSourceOfIncomeProps } from 'shared/types/renterEmploymentInformation';

import styles from '../RenterPriorSourceOfIncome.module.scss';

const HavePriorSourceOfIncome = ({ onHandleNo, onHandleYes }: HavePriorSourceOfIncomeProps): JSX.Element => {
  // Initialize the translation function
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const { hasNoPriorEmployments, priorEmployments } = useSelector(selectRenterProfileInformation);
  const [searchParams] = useSearchParams();
  const nextStep = searchParams.get(NavigationKeys.NEXT);
  const navigate = useNavigate();

  useEffect(() => {
    if (hasNoPriorEmployments && isEmpty(priorEmployments) && !nextStep) {
      onHandleNo();
    } else if (priorEmployments?.length) {
      navigate(renterRoutes.generateRenterPriorSourceOfIncomeSummary(applicationId));
    }
  }, [applicationId, hasNoPriorEmployments, navigate, nextStep, onHandleNo, priorEmployments]);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>{t('renter.priorSourceOfIncome.heading')}</div>
      <div className={styles.buttonContainer}>
        {/* Button for selecting "Yes" for having prior source of income */}
        <Button variant="outline" onClick={onHandleYes} className={styles.buttonPrimary}>
          {t('renter.priorSourceOfIncome.yes')}
        </Button>

        {/* Button for selecting "No" for not having prior source of income */}
        <Button variant="outline" onClick={onHandleNo} className={styles.buttonPrimary}>
          {t('renter.priorSourceOfIncome.no')}
        </Button>
      </div>
    </div>
  );
};

export default HavePriorSourceOfIncome;
