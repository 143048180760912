import { getUniqueAddressVerifiers } from 'helpers/helper';
import { formatAddressAndEmploymentResponseToPDF } from 'helpers/verification';
import { VerificationPDF, VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

interface Props {
  addressVerificationResponse: AddressVerificationResponse[];
  renterDetails: RenterProfileInformationProps;
  isLastPage?: boolean;
}

export const generateAddressVerificationPDFComponents = ({
  addressVerificationResponse,
  renterDetails,
  isLastPage = false,
}: Props): JSX.Element[] =>
  getUniqueAddressVerifiers(addressVerificationResponse).map((address) => {
    const { questionnaire, userAddressDetails } = formatAddressAndEmploymentResponseToPDF({
      type: VerifierURLType.VOR,
      renterDetails,
      addressResponse: address,
    });

    return (
      <VerificationPDF
        key={`addy-${address.renterAddressInformation?.addressId}`}
        questionnaire={questionnaire}
        type={VerifierURLType.VOR}
        userAddressDetails={userAddressDetails}
        isEmbedded={true}
        isLastPage={isLastPage}
      />
    );
  });
