import { RefObject, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Cropper from 'react-cropper';
import lodash from 'lodash';

import Button from 'components/shared/Button/Button';

import { PreviewFile } from '../DropZone/Dropzone';

import 'cropperjs/dist/cropper.css';
import styles from './Cropper.module.scss';
interface Props {
  file: PreviewFile;
  onCroppedClicked: (file: PreviewFile) => void;
  onClose: () => void;
}
export interface ImageDimensions {
  height: number;
  width: number;
}
interface ReactCropperElement extends HTMLImageElement {
  cropper?: Cropper;
}
const ImageCropper = ({ file, onCroppedClicked, onClose }: Props): JSX.Element => {
  const [croppedCanvas, setCroppedCanvas] = useState<string>('');
  const cropperRef = useRef<ReactCropperElement>(null) as RefObject<ReactCropperElement>;
  const [imageDimension, setImageDimensions] = useState({} as ImageDimensions);
  // Will crop the image
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCrop = (e: any): void => {
    if (cropperRef.current) {
      setCroppedCanvas(e.target.cropper.getCroppedCanvas().toDataURL());
      setImageDimensions({
        height: e.target.cropper.getCroppedCanvas().height,
        width: e.target.cropper.getCroppedCanvas().width,
      });
    }
  };
  // on click crop button will create cropper file
  const onCroppedClick = (): void => {
    const testFile = lodash.cloneDeep(file);
    const data = Object.assign(testFile, {
      preview: croppedCanvas,
      dimension: imageDimension,
    });

    onCroppedClicked(data);
  };

  return (
    <div className={styles.cropperContainer}>
      <Cropper
        initialAspectRatio={1}
        src={file.preview}
        ref={cropperRef}
        zoomable={false}
        viewMode={1}
        guides={true}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        cropend={handleCrop}
        checkOrientation={false}
      />
      {/* cropper button and close button */}
      <Row>
        <Col lg={6} md={12} sm={12}>
          <Button
            onClick={onCroppedClick}
            className={styles.buttonMargin}
            disabled={
              imageDimension.height < 220 || imageDimension.width < 330 || !Object.entries(imageDimension).length
            }
          >
            Crop Image
            {!!Object.entries(imageDimension).length &&
              `/ Height: ${imageDimension.height} Width: ${imageDimension.width}`}
          </Button>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <Button className={styles.buttonMargin} onClick={onClose}>
            Close Cropper
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ImageCropper;
