import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isSaving } from 'redux/selectors/renterSelector';
import { addRenterAddressInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import AddressBox from 'components/shared/AddressBox/AddressBox';
import RCButton from 'components/shared/Button/Button';
import { PlacesAutocompleteComponent } from 'components/shared/PlacesAutoCompleteComponent/PlacesAutoCompleteComponent';
import { NavigationKeys, RenterAddressSteps, RenterAddressTypes } from 'constants/renterConstants';
import {
  ADDRESS_FORMAT_INDEX,
  getAddressToApiFormat,
  getPresentAddress,
  onCreatePresentAddressCall,
  onUpdatePresentAddressCall,
  PresentAddressProps,
} from 'helpers/address';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { AddressInformation, AddressInitialValuesProps } from 'shared/types/renterTypes';

import PresentAddressDate from '../PresentAddressDate/PresentAddressDate';
import { PresentAddressInformation } from '../PresentAddressInformation/PresentAddressInformation';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './PresentAddress.module.scss';
const PresentAddress = ({ address, isRenting, setIsRenting }: PresentAddressProps): JSX.Element => {
  const { t } = useTranslation();
  const [shouldClassify, setShouldClassify] = useState(false);
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const { values, setFieldValue, resetForm } = useFormikContext<AddressInitialValuesProps>();
  const [searchParams] = useSearchParams();
  const nextStep = searchParams.get(NavigationKeys.NEXT);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const stepName = searchParams.get('step');
  const isSavingInformation = useSelector(isSaving);
  const [isGoogleApiCalled, setIsGoogleApiCalled] = useState(false);
  const getHeadingStatement = useMemo((): string => {
    if (!address.streetAddress) {
      return t('renter.ConfirmPresentAddress.enterDetailsForYour');
    } else {
      return t('renter.ConfirmPresentAddress.confirmDetailsForYour');
    }
  }, [address.streetAddress, t]);
  const onClearStates = useCallback(() => {
    setFieldValue('state', '');
    setFieldValue('city', '');
    setFieldValue('streetAddress1', '');
    setFieldValue('zipCode', '');
  }, [setFieldValue]);
  const clearStates = useCallback((): void => {
    onClearStates();
    resetForm();
  }, [onClearStates, resetForm]);
  const createPresentAddress = useCallback(
    (route: string) => {
      dispatch(
        addRenterAddressInformation({
          address: { ...getAddressToApiFormat(values.renterAddress), isRented: values.renterAddress.isRented ?? false },
        })
      )
        .unwrap()
        .then((response) => {
          setFieldValue('renterAddress', getPresentAddress(response.addresses));
          navigate(route);
        })
        .catch((error) => {
          Notification({ message: error });
        });
    },
    [dispatch, navigate, setFieldValue, values]
  );
  const onClickHandler = useCallback(
    (route: string) => {
      onClearStates();

      if (!address.id) {
        createPresentAddress(route);
      } else {
        navigate(route);
      }
    },
    [address, createPresentAddress, navigate, onClearStates]
  );
  const onClassifyClickHandler = useCallback(() => {
    const route = nextStep
      ? nextStep
      : renterRoutes.generateAddressHistoryInformation(Number(applicationId), RenterAddressSteps.PRIOR_ADDRESS);

    if (address.id) {
      onUpdatePresentAddressCall({
        route,
        navigate,
        onClearStates: clearStates,
        address,
        renterAddress: values.renterAddress,
        dispatch,
      });
    } else {
      onCreatePresentAddressCall({
        route,
        dispatch,
        renterAddress: values.renterAddress,
        navigate,
        onClearStates: clearStates,
      });
    }
  }, [address, applicationId, clearStates, dispatch, navigate, nextStep, values.renterAddress]);

  useEffect(() => {
    const fullAddress: string = values.streetAddress1 ? values.streetAddress1 : '';

    //Extracting the apartment and the street address from the full address given by palces-autocomplete
    if (!isGoogleApiCalled && values.zipCode && values.city && fullAddress.split(',').length > 1 && !isError) {
      const apartment: string = fullAddress.substring(
        fullAddress.indexOf(',') + ADDRESS_FORMAT_INDEX,
        fullAddress.indexOf(values.city) - ADDRESS_FORMAT_INDEX
      );
      const initialPriorAddress: AddressInformation = {
        streetAddress: fullAddress.split(',')[0],
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
        addressType: RenterAddressTypes.PRESENT,
        apartmentNumber: apartment,
      };

      setFieldValue('renterAddress', initialPriorAddress);
      setIsGoogleApiCalled(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isError, setFieldValue, values]);

  return (
    <div className={classNames(indexStyles.renterContentContainer, styles.presentAddressContainer)}>
      <div className={indexStyles.renterContentContainer}>
        {!shouldClassify && (
          <p className={indexStyles.renterH2}>
            {getHeadingStatement}
            <span className={styles.renterH2Color}>
              {shouldClassify
                ? t('renter.ConfirmPresentAddress.livingSituation')
                : t('renter.ConfirmPresentAddress.presentAddress')}
            </span>
          </p>
        )}
        {(!address.streetAddress || isError) && !shouldClassify && (
          <div
            className={classNames(styles.placeAutoCompleteContainer, {
              [styles.errorPlaceAutoComplete]: isError,
            })}
          >
            <PlacesAutocompleteComponent
              addressField="streetAddress1"
              setIsError={setIsError}
              placeholder="Type to search"
              shouldAcceptCustomAddress={false}
              labelField="Street"
              className="boldLabel"
              data-testid="addressSearchBar"
            />
          </div>
        )}
        {(address.streetAddress || !isError) && !shouldClassify && (
          <div data-testid="presentAddressBox" className={styles.addresBoxContainer}>
            <AddressBox address={values.renterAddress} />
          </div>
        )}
        {stepName === RenterAddressSteps.PRESENT_ADDRESS_CONFIRMATION && address.streetAddress && !shouldClassify && (
          <div className={classNames(indexStyles.renterButtonContainer, styles.addressButtonConatiner)}>
            <RCButton
              className={classNames(indexStyles.renterButton, styles.presentAddressButton)}
              variant="outline"
              onClick={() => {
                setIsError(false);
                onClickHandler(
                  renterRoutes.generateAddressHistoryInformation(
                    Number(applicationId),

                    RenterAddressSteps.PRESENT_ADDRESS
                  )
                );
              }}
              disabled={isSavingInformation}
            >
              {t('renter.InformationEdit.confirmAndContiue')}
            </RCButton>
          </div>
        )}
        {stepName === RenterAddressSteps.PRESENT_ADDRESS && !shouldClassify && (
          <PresentAddressDate
            setRequireClassification={setShouldClassify}
            address={values.renterAddress}
            isRenting={isRenting}
            setIsRenting={setIsRenting}
          />
        )}
        {stepName === RenterAddressSteps.PRESENT_ADDRESS && shouldClassify && (
          <PresentAddressInformation
            mainHeading={t('renter.ConfirmPresentAddress.ifNotRenting')}
            subHeading={t('renter.ConfirmPresentAddress.livingSituation')}
            onClickHandler={onClassifyClickHandler}
            address={values.renterAddress}
          />
        )}
      </div>
    </div>
  );
};

export default PresentAddress;
