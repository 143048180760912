import { useTranslation } from 'react-i18next';

import Button from 'components/shared/Button/Button';
import { PriorSourceOfIncomeTypes, TypeOfPriorSourceOfIncomeProps } from 'shared/types/renterEmploymentInformation';

import styles from '../RenterPriorSourceOfIncome.module.scss';

const TypeOfPriorSourceOfIncome = ({ handleTypeOfEmployment }: TypeOfPriorSourceOfIncomeProps): JSX.Element => {
  // Initialize the translation function
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.heading}>{t('renter.priorSourceOfIncome.heading2')}</div>
      <div className={styles.buttonContainer}>
        {/* Button for selecting "Employed" as the prior source of income */}
        <Button
          variant="outline"
          onClick={() => handleTypeOfEmployment(PriorSourceOfIncomeTypes.EMPLOYED)}
          className={styles.buttonPrimary}
        >
          {t('renter.sourceOfIncome.employed')}
        </Button>

        {/* Button for selecting "Self-employed" as the prior source of income */}
        <Button
          variant="outline"
          onClick={() => handleTypeOfEmployment(PriorSourceOfIncomeTypes.SELF_EMPLOYED)}
          className={styles.buttonPrimary}
        >
          {t('renter.sourceOfIncome.selfEmployed')}
        </Button>
      </div>
    </div>
  );
};

export default TypeOfPriorSourceOfIncome;
