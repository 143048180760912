import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { serverErrorAuth } from 'redux/selectors/authSelector';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as HomeDesign } from 'assets/svgs/authBackGround.svg';
import { ReactComponent as HomeRightDesign } from 'assets/svgs/RenterBackgroundArrow.svg';
import Card from 'components/shared/CustomCard/Card';
import { Footer } from 'components/shared/Footer/Footer';
import { ErrorModal } from 'components/shared/Modal/ErrorModal/ErrorModal';
import { IsMobile } from 'helpers/IsMobile';
import { errorStatusDetails } from 'shared/constants';
import { renterRoutes } from 'shared/routes';

import PropertyInformation from './PropertyInformation/PropertyInformation';
import RenterNavigationBar from './RenterNavigationBar/RenterNavigationBar';

import styles from './../RenterIndex.module.scss';

const RenterContainer = ({
  children,
  shouldShowNavigation = true,
}: {
  children: React.ReactElement;
  shouldShowNavigation?: boolean;
}): JSX.Element => {
  const serverError = useSelector(serverErrorAuth);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { pathname } = useLocation();
  const isMobileScreen = IsMobile();
  const shouldShowProperty = useMemo(
    () =>
      !pathname?.includes(renterRoutes.applications) &&
      !pathname?.includes(renterRoutes.applicationToRent.substring(0, renterRoutes.applicationToRent.lastIndexOf('/'))),
    [pathname]
  );

  useEffect(() => {
    if (serverError && !!errorStatusDetails[serverError]) {
      setIsErrorModalVisible(true);
    }
  }, [dispatch, serverError]);

  return (
    <div className={styles.renterContainer}>
      {isErrorModalVisible && (
        <ErrorModal
          isModalVisible={isErrorModalVisible}
          setIsModalVisible={setIsErrorModalVisible}
          serverError={serverError ?? 0}
        />
      )}

      <div className={styles.renterContainerLeftArrow}>
        <HomeDesign />
      </div>
      <Card xl={5} md={8} className={styles.container}>
        <div className={styles.renterContainerRightArrow}>
          <HomeRightDesign />
        </div>
        {shouldShowNavigation && <RenterNavigationBar />}
        {shouldShowProperty && (
          <div className={styles.propertyInformation}>
            <PropertyInformation />
          </div>
        )}
        {children}
      </Card>
      {!isMobileScreen && <Footer />}
    </div>
  );
};

export default RenterContainer;
