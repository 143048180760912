/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { deleteFurryFriendsDocument, uploadFurryFriendsDocuments } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import ImageIcon from 'assets/images/ImageIcon.png';
import AnimalDetailsInformationCard from 'components/shared/AnimalDetailsInformationCard/AnimalDetailsInformationCard';
import MinimizedDropzone, { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import { AnimalType } from 'constants/renterConstants';
import { imageUrl } from 'helpers/renterHelper';
import { AnimalDisplayCardProps } from 'shared/types/renterTypes';

import { Ladder } from '../IdentityVerification/RenterDetails/shared/Ladder/Ladder';

import styles from './AnimalDocumentation/AnimalDocumentation.module.scss';

const AnimalDisplayCard = ({
  animalInformation,
  shouldUploadData = false,
  index,
}: AnimalDisplayCardProps): JSX.Element => {
  const { t } = useTranslation();
  const [animalId, setAnimalId] = useState<number | undefined>();
  const [acceptedDocuments, setAcceptedDocuments] = useState<PreviewFile[]>([]);
  const dispatch = useDispatch<AppThunkDispatch>();
  const uploadDocumentApi = async (previewFile: PreviewFile): Promise<any> => {
    try {
      return await dispatch(
        uploadFurryFriendsDocuments({ document: previewFile, furryFriendId: animalInformation?.id })
      )
        .unwrap()
        .then((response) => response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const deleteDocumentApi = async (previewFile: PreviewFile): Promise<any> => {
    const currentDocument = animalInformation?.documents?.find(
      (obj) => obj.fileName === previewFile.path || obj.fileName === previewFile.fileName
    );

    if (currentDocument && animalInformation.id) {
      try {
        return await dispatch(
          deleteFurryFriendsDocument({ furryFriendId: animalInformation?.id, documentId: currentDocument.id })
        )
          .unwrap()
          .then((response) => response);
      } catch (error) {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(new Error(t('renter.incomeInformation.documentType.error.notFound')));
    }
  };

  return (
    <div className={classNames(styles.animalDetailCardDiv)}>
      <div className={styles.AnimalInformationContainer}>
        <AnimalDetailsInformationCard
          animalInformation={animalInformation}
          url={imageUrl(animalInformation) ?? ImageIcon}
          shouldShowEditIcon={true}
          index={index}
          setAnimalId={setAnimalId}
          animalId={animalId}
        />
        {animalInformation.furryFriendType !== AnimalType.PETS && animalInformation?.documents && (
          <div
            className={classNames(
              styles.dropdownContainer,
              styles.animalSummaryDropDownContainer,
              styles.LadderContainer
            )}
          >
            <Ladder
              ladderContainerClassName={styles.Ladder}
              verticalBranchClassName={styles.VerticalLine}
              horizontalBranchClassName={styles.HorizontalLine}
            />
            <div className={styles.DropZoneContainer}>
              <MinimizedDropzone
                title={t('renter.documentationLabel')}
                setAcceptedFiles={setAcceptedDocuments}
                uploadApiCall={uploadDocumentApi}
                deleteApiCall={deleteDocumentApi}
                acceptedFiles={animalInformation.documents || acceptedDocuments}
                parentId={animalInformation.id}
                setParentId={setAnimalId}
                enabled={shouldUploadData}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnimalDisplayCard;
