import { Dispatch } from 'react';
import { t } from 'i18next';

import { ReactComponent as ErrorInfoSvg } from 'assets/svgs/ErrorInfo.svg';
import RCButton from 'components/shared/Button/Button';

import RenterModal from '../RenterModal/RenterModal';

import styles from './AddressDiscardModal.module.scss';

interface IAddressDiscardModalProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<React.SetStateAction<boolean>>;
  onBackClick: () => void;
  onImSureClick: () => void;
}
const AddressDiscardModal = ({
  isModalOpen,
  setIsModalOpen,
  onBackClick,
  onImSureClick,
}: IAddressDiscardModalProps): JSX.Element => (
  <RenterModal show={isModalOpen} onHide={() => setIsModalOpen(false)} crossButtonClassName={styles.hideCrossButton}>
    <div className={styles.contentClassName}>
      <div className={styles.container}>
        <div className={styles.messageDiv}>
          <ErrorInfoSvg />
          <div className={styles.headingDiv}>{t('renter.PhotoIdInformation.savechanges')}?</div>
          <div className={styles.description}>{t('renter.relationship.discardText')}</div>
        </div>
        <div className={styles.buttonDiv}>
          <RCButton className={styles.dullButton} variant="outline" onClick={onBackClick}>
            {t('renter.relationship.discardBackButton')}
          </RCButton>
          <RCButton className={styles.renterButton} onClick={onImSureClick}>
            {t('renter.relationship.discardYesButton')}
          </RCButton>
        </div>
      </div>
    </div>
  </RenterModal>
);

export default AddressDiscardModal;
