import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import { IsMobileApplicantsPage } from 'helpers/IsMobile';
import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { ViewPropertyProps } from 'shared/types/propertyType';

import { GeneralInvitedApplicantInformation } from '../GeneralInvitedApplicantInformation/GeneralInvitedApplicant';
import { PropertyHeader } from '../PropertyHeader/PropertyHeader';

import styles from './ViewPropertyInformation.module.scss';
export const ViewProperty = memo(({ invite, property }: ViewPropertyProps): JSX.Element => {
  const {
    streetAddress1,
    zipCode,
    state,
    bedroomsCount,
    bathroomsCount,
    depositAmount,
    monthlyRentAmount,
    city,
    propertyImages,
  } = invite.property;
  const [isExpanded, setIsExpanded] = useState(true);
  const activeOrganization = useSelector(selectedOrganization);
  const isMobileView = IsMobileApplicantsPage();

  return (
    <div className={styles.Container}>
      {property?.propertyId && (
        <PropertyHeader
          streetAddress1={streetAddress1?.split(',')[0] ?? ''}
          streetAddress2={`${city} ${state} ${zipCode}`}
          availableDate={new Date().toDateString()}
          beds={Number(bedroomsCount)}
          baths={Number(bathroomsCount)}
          deposit={depositAmount}
          rent={monthlyRentAmount}
          ownerName={`${property.agentFirstName} ${property.agentLastName}`}
          ownerOrganization={activeOrganization.name ?? ''}
          propertyType={''}
          sqFtSize={0}
          totalUnits={property.totalUnits ?? ''}
          totalActive={invite.invites.length ?? 0}
          totalHouseHolds={property?.householdCount ?? 0}
          agentName={`${property.agentFirstName} ${property.agentLastName}`}
          onDropDownClick={() => {
            setIsExpanded(!isExpanded);
          }}
          isInvited={true}
          propertyImages={propertyImages as PreviewFile[]}
        />
      )}
      {isExpanded && !isEmpty(invite.invites) && (
        <div
          className={classNames(styles.ApplicationContainer, {
            [styles.MobileApplicationContainer]: isMobileView,
          })}
        >
          {!isMobileView && property?.propertyId && <div className={styles.VerticalLine} />}
          <div className={styles.InvitedApplicationList}>
            {invite.invites.map((application, index) => (
              <div key={`Property-Application-${index}-${application.inviteId}`}>
                <GeneralInvitedApplicantInformation
                  invite={application}
                  applicationStatus={PropertyApplicationStatus.NOT_STARTED}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});
