import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getFinicityConnectUrl } from 'redux/slices/finicitySlice';

import RCButton from 'components/shared/Button/Button';
import CustomSpinner from 'components/shared/Spinner/Spinner';
import { parseResponseErrors } from 'helpers/helper';
import { Notification } from 'shared/Notification/Notification';
import { FinicityConnectButtonProps } from 'shared/types/renterTypes';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './ConnectBankDetails.module.scss';

// Declare the global interface for the finicityConnect object in the window object
declare global {
  interface Window {
    finicityConnect: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      launch: (iframeUrl: string, options: any) => void; // Method to launch Finicity Connect
      destroy: () => void; // Method to destroy Finicity Connect
    };
  }
}

const FinicityConnectButton = ({
  isClicked,
  applicationId,
  setIsFinicityButtonClicked,
}: FinicityConnectButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const [finictyConnectLink, setFinicityConnectLink] = useState<string>('');
  const generateFinicityConnectUrl = useCallback(async () => {
    await getFinicityConnectUrl(applicationId)
      .then((response) => {
        setFinicityConnectLink(response.link);
      })
      .catch((error): void => {
        Notification({ message: parseResponseErrors(error) });
      });
  }, [applicationId]);
  // Callback function to connect bank details using Finicity Connect
  const connectBankDetails = useCallback(async () => {
    window.finicityConnect.launch(finictyConnectLink, {
      selector: '#connect-container', // CSS selector for the container where Finicity Connect will be rendered
      success: async () => {
        window.finicityConnect.destroy();
        generateFinicityConnectUrl();
      },
      cancel: async () => {
        setIsFinicityButtonClicked(false);
        window.finicityConnect.destroy();
        generateFinicityConnectUrl();
      },
      error: async () => {
        setIsFinicityButtonClicked(false);
        window.finicityConnect.destroy();
        generateFinicityConnectUrl();
      },
      loaded: async () => {
        isClicked(true);
      },
    });
  }, [finictyConnectLink, generateFinicityConnectUrl, isClicked, setIsFinicityButtonClicked]);

  useEffect(() => {
    generateFinicityConnectUrl();
  }, [generateFinicityConnectUrl]);

  return (
    <>
      <Helmet>
        <script src="https://connect2.finicity.com/assets/sdk/finicity-connect.min.js"></script>
      </Helmet>
      <div className={classNames(indexStyles.renterButtonContainer, styles.finicityButtonConatiner)}>
        <RCButton
          className={classNames(indexStyles.renterButton, styles.finicityButton)}
          variant="outline"
          onClick={connectBankDetails}
          disabled={!finictyConnectLink}
        >
          {t('renter.connectFinicity.connectMyBankDetails')} {!finictyConnectLink && <CustomSpinner />}
        </RCButton>
      </div>
    </>
  );
};

export default FinicityConnectButton;
