import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { setInviteCode, setInviteDetails, setInviteSubType, setInviteType } from 'redux/slices/authSlice';

/**
 * A utility used to generate google OAuth url with specific params.
 * It gets the client id and redirect url from the environment variables
 *
 * @returns A complete string url to be used for google authentication .
 */
export const googleAuthUrl = (userType?: string): string => {
  const baseUrl = 'https://accounts.google.com/o/oauth2/auth/identifier';
  const query = new URLSearchParams();
  const role = {
    userType: userType,
  };

  query.append('access_type', 'offline');
  query.append('client_id', `${process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}.apps.googleusercontent.com`);
  query.append('redirect_uri', process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URL as string);
  query.append('response_type', 'code');
  query.append('scope', 'openid profile email https://www.googleapis.com/auth/contacts.readonly');
  query.append('state', JSON.stringify(role));
  query.append('service', 'lso');
  query.append('o2v', '1');
  query.append('flowName', 'GeneralOAuthFlow');

  return baseUrl + '?' + query.toString();
};

export const resetInviteDetails = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  shouldClearRenterInviteDetails?: boolean
): void => {
  dispatch(setInviteCode(''));
  dispatch(setInviteType(''));
  dispatch(setInviteSubType(''));
  shouldClearRenterInviteDetails && dispatch(setInviteDetails({}));
};
