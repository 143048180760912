import { ReactNode } from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';

import { FooterPDF } from 'shared/pdf/v2/components/shared/FooterPDF/FooterPDF';

interface Props {
  children: ReactNode;
  isLastPage?: boolean;
}

const styles = StyleSheet.create({
  page: {
    padding: '20px 30px',
  },
  children: {
    flexGrow: 1,
  },
});

export const VerifierPage = ({ children, isLastPage = false }: Props): JSX.Element => (
  <View style={styles.page}>
    <View style={styles.children}>{children}</View>
    <FooterPDF isOnLastPage={isLastPage} />
  </View>
);
