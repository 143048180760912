import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { startCase, toLower } from 'lodash-es';

import { formatDateToShortFormat } from 'helpers/applicantsHelper';
import { formatAmount } from 'helpers/user';
import { ApplicationType } from 'shared/types/applicantsType';
import { AddPropertyParams } from 'shared/types/propertyType';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

import { MapPinOutlineSVG } from '../../svgs/MapPinOutlineSVG';
import { SubmittedAtPDF } from '../SubmittedAtPDF/SubmittedAtPDF';

interface Props {
  application: ApplicationType;
  renterDetails: RenterProfileInformationProps;
  propertyInformation: AddPropertyParams;
  moveInDate: string;
}

const styles = StyleSheet.create({
  submittedDate: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    fontSize: '11px',
  },
  occupantContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
  occupantDetails: {
    fontWeight: 'semibold',
    fontSize: '16px',
    color: '#303333',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  rentalContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  rentalAddressStreet: {
    fontSize: '12px',
    fontWeight: 'semibold',
    color: '#303333',
    marginLeft: '5px',
  },
  apartmentAddress: {
    flexDirection: 'row',
    gap: '6px',
    paddingHorizontal: '12px',
    fontSize: '12px',
    fontWeight: 'semibold',
    color: '#5E6566',
  },
  apartmentDetails: {
    flexDirection: 'row',
    gap: '12px',
  },
  apartmentDetailItem: {
    flexDirection: 'column',
    gap: '5px',
    alignItems: 'center',
  },
  detailValue: {
    fontWeight: 'semibold',
    fontSize: '12px',
    color: '#2D3030',
  },
  detailLabel: {
    fontSize: '11px',
    color: '#636A6B',
  },
  sqFt: {
    marginBottom: '4px',
    fontSize: '10px',
  },
  sqFtValue: {
    marginBottom: '4px',
    color: '#2D3030',
  },
});

export const RenterPersonalDetailsPDF = ({
  application,
  renterDetails,
  propertyInformation,
  moveInDate,
}: Props): JSX.Element => {
  const {
    streetAddress1,
    city,
    state,
    zipCode,
    squareFootage,
    monthlyRentAmount,
    depositAmount,
    bedroomsCount,
    bathroomsCount,
  } = propertyInformation;

  return (
    <View>
      <View style={styles.submittedDate}>
        {application.submittedAt && <SubmittedAtPDF submittedAt={application.submittedAt} />}
      </View>
      <View style={{ paddingVertical: '8px' }}>
        <View style={styles.occupantContainer}>
          <Text>{`OCCUPANT ${startCase(toLower(application.renterRole))}`}</Text>
          <Text>{`DESIRED MOVE-IN DATE`}</Text>
        </View>
        <View style={styles.occupantDetails}>
          <Text>{`${renterDetails.firstName} ${renterDetails.lastName}`}</Text>
          <Text>{`${formatDateToShortFormat(moveInDate)}`}</Text>
        </View>
        <View style={styles.rentalContainer}>
          <View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <MapPinOutlineSVG />
              <Text style={styles.rentalAddressStreet}>{streetAddress1?.substring(0, 10)}</Text>
              <Text style={{ ...styles.rentalAddressStreet, color: '#8D9899' }}>{`${city}, ${state} ${zipCode}`}</Text>
            </View>
            <View style={styles.apartmentAddress}>
              <Text>{`APARTMENT`}</Text>
              <Text style={styles.sqFt}>{`Sq Ft`}</Text>
              <Text style={styles.sqFtValue}>{squareFootage}</Text>
            </View>
          </View>
          <View style={styles.apartmentDetails}>
            <View style={styles.apartmentDetailItem}>
              <Text style={styles.detailValue}>{`$${formatAmount(monthlyRentAmount ?? 0)}`}</Text>
              <Text style={styles.detailLabel}>{'Rent'}</Text>
            </View>
            <View style={styles.apartmentDetailItem}>
              <Text style={styles.detailValue}>{`$${formatAmount(depositAmount ?? 0)}`}</Text>
              <Text style={styles.detailLabel}>{'Deposit'}</Text>
            </View>
            <View style={styles.apartmentDetailItem}>
              <Text style={styles.detailValue}>{bedroomsCount}</Text>
              <Text style={styles.detailLabel}>{'Beds'}</Text>
            </View>
            <View style={styles.apartmentDetailItem}>
              <Text style={styles.detailValue}>{bathroomsCount}</Text>
              <Text style={styles.detailLabel}>{'Baths'}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
