import { useTranslation } from 'react-i18next';

import { ReactComponent as YellowWarningIcon } from 'assets/svgs/ErrorInfo.svg';
import { formatDateForApplicationIndex } from 'helpers/renterHelper';
import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { ApplicationInfoBoxHeaderProps } from 'shared/types/renterTypes';

import AgentPills, { PillTypes } from '../AgentPills/AgentPills';

import styles from './ApplicationInfoBox.module.scss';

export const ApplicationInfoBoxHeader = ({
  submittedAt,
  applicationStatus,
}: ApplicationInfoBoxHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const getPillType = (): PillTypes => {
    if (applicationStatus === PropertyApplicationStatus.COMPLETED) {
      return PillTypes.SUCCESS;
    } else if (applicationStatus === PropertyApplicationStatus.IN_PROGRESS) {
      return PillTypes.WARNING_DARK;
    }

    return PillTypes.SECONDARY;
  };

  return (
    <div className={styles.HeaderContainer}>
      <div className={styles.SubmitInfo}>
        {t('renter.applicationInfoBox.submitted')}
        <span className={styles.SubmittedDate}>{formatDateForApplicationIndex(new Date(submittedAt))}</span>
      </div>
      <div className={styles.StatusContaienr}>
        <AgentPills
          pillsData={[
            {
              heading: applicationStatus,
              isItalic: true,
              type: getPillType(),
            },
          ]}
          pillContainerClassName={styles.CompleteStatus}
        />
        <YellowWarningIcon height={18} width={18} className={styles.StatusIcon} />
      </div>
    </div>
  );
};
