import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { isLoading, selectedAgent } from 'redux/selectors/renterSelector';
import { getAgentDetails } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { desiredMoveInDateSchema } from 'schema/renterSchema';

import { ReactComponent as ArrowLeftDirected } from 'assets/svgs/ArrowLeftDirected.svg';
// import { ReactComponent as Search } from 'assets/svgs/Search.svg';
import RenterContainer from 'components/Renter/components/RenterContainer';
import RCButton from 'components/shared/Button/Button';
import CustomDatePicker from 'components/shared/CustomDatePicker/CustomDatePicker';
import { dateFormatBackend } from 'constants/calendarConstants';
import { InitialMoveInDate } from 'constants/renterConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { Colors } from 'shared/constants';
import { InviteType } from 'shared/types/authType';
import { AddPropertyParams } from 'shared/types/propertyType';
import {
  ApplicationCreationBodyParams,
  createApplication,
  fetchPropertyAgainstAcceptedInvitation,
  InviteDataKeys,
} from 'shared/types/renterApplication';

import { PropertyCard } from '.';

import styles from './PropertiesList.module.scss';
export const PropertiesList = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [params] = useSearchParams();
  const isLoadingInformation = useSelector(isLoading);
  const inviteCode = params.get(InviteDataKeys.INVITE_CODE) || '';
  const inviteType = params.get(InviteDataKeys.INVITE_TYPE) || '';
  const [acceptedInviteProperties, setAcceptedInviteProperties] = useState<AddPropertyParams[]>([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState<number>(0);
  const inviteAgent = useSelector(selectedAgent);
  const maxHeight = useDeviceHeight(220);

  useEffect(() => {
    if (inviteCode && isEmpty(inviteAgent)) {
      dispatch(getAgentDetails({ inviteCode }));
    }
  }, [dispatch, inviteAgent, inviteCode]);
  const goBack = (): void => {
    navigate(-1);
  };
  const onPropertySelect = useCallback(
    (id?: number): void => {
      setSelectedPropertyId((prevSelectedPropertyId) => {
        if (id === undefined) {
          return prevSelectedPropertyId; // Do nothing if id is undefined
        }

        if (id === prevSelectedPropertyId) {
          return 0; // Deselect if it's already selected
        } else {
          return id; // Select the property
        }
      });
    },
    [setSelectedPropertyId]
  );
  const continueWithProperty = useCallback(
    (moveInDate: string): void => {
      const createPropertyParams: ApplicationCreationBodyParams = {
        applicationId: null,
        propertyId: selectedPropertyId,
        inviteCode: inviteType.toLowerCase().includes(InviteType.GENERAL) ? null : inviteCode,
        desiredMoveInDate: format(new Date(moveInDate), dateFormatBackend),
      };

      createApplication(createPropertyParams, dispatch, navigate);
    },
    [selectedPropertyId, inviteType, inviteCode, dispatch, navigate]
  );

  useEffect(() => {
    if (inviteCode) {
      fetchPropertyAgainstAcceptedInvitation(inviteCode, dispatch, setAcceptedInviteProperties);
    }
  }, [dispatch, inviteCode]);

  //TODO: Update fetch property API to not include custom address properties
  if (isEmpty(acceptedInviteProperties.filter((p) => !p.isCustomAddress)) && !isLoadingInformation) {
    return (
      <RenterContainer>
        <section className={styles.PropertiesListSection}>
          <div className={styles.Header}>
            <ArrowLeftDirected fill={Colors.COLOR_SVG_GRAY_LIGHT} onClick={goBack} cursor={'pointer'} />
          </div>
          <div className={styles.EmptyContainer}>{t('renter.application.agentGeneral.noProperties')}</div>
        </section>
      </RenterContainer>
    );
  }

  return (
    <Formik
      initialValues={InitialMoveInDate}
      validationSchema={desiredMoveInDateSchema}
      onSubmit={() => console.log('')}
    >
      {({ isValid, values, resetForm }) => (
        <RenterContainer>
          <section className={styles.PropertiesListSection}>
            {/* icons list */}
            <div className={styles.Header}>
              <ArrowLeftDirected fill={Colors.COLOR_SVG_GRAY_LIGHT} onClick={goBack} cursor={'pointer'} />
              <h2 className={styles.Title}>{t('renter.application.selectAProperty')}</h2>
              <div /> {/** TODO: Remove this when search icon functionality is implemented */}
              {/* <Search width={24} height={24} color={Colors.WARM_GRAY} /> */}
            </div>

            {/* show property */}
            <div
              className={classNames(styles.PropertiesListContainer, {
                [styles.MaxHeightWhenSelected]: selectedPropertyId,
              })}
              style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
            >
              {acceptedInviteProperties
                .filter((p) => !p.isCustomAddress)
                .map((property) => (
                  <div key={`proeprty-${property.id}-${property}`} className={styles.PropertyRentContainer}>
                    <PropertyCard
                      selectedPropertyId={selectedPropertyId}
                      id={property.id || 0}
                      onClickProperty={(id: number | undefined) => {
                        onPropertySelect(id);
                        resetForm();
                      }}
                      property={property}
                    />
                    {!!selectedPropertyId && selectedPropertyId === property.id && (
                      <div className={styles.MoveInDateContainer}>
                        <div className={styles.MoveInDateHeading}>{t('renter.propertySelect.pleaseEnterDesired')}</div>
                        <div className={styles.MoveInDate}>
                          <CustomDatePicker
                            placeholder={t('renter.property.datePlaceholder')}
                            floatingLabel={t('renter.propertySelect.moveInDate')}
                            name="moveInDate"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              {!!selectedPropertyId && (
                <div className={styles.ContinueButtonContainer}>
                  <RCButton
                    className={classNames(styles.Button, styles.PrimaryButton)}
                    variant="outline"
                    onClick={() => continueWithProperty(values.moveInDate)}
                    disabled={!isValid || !values.moveInDate}
                  >
                    {t('renter.application.continueWithProperty')}
                  </RCButton>
                </div>
              )}
            </div>
          </section>
        </RenterContainer>
      )}
    </Formik>
  );
};
