import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Form, Formik, FormikState } from 'formik';
import { isEqual } from 'lodash-es';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { setApplicationId } from 'redux/slices/authSlice';
import { addAdultsToApplication } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterAdultSchema } from 'schema/renterSchema';

import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import FormikField from 'components/shared/TextField/FormikTextField';
import { NavigationKeys } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { AdultDispatchProps, RenterAdultInitialValues, RenterAdultProps } from 'shared/types/renterTypes';

import RenterContainer from '../../RenterContainer';
import RenterIconInfoContainer from '../../RenterIconInfoContainer';

import styles from '../../../RenterIndex.module.scss';
import adultStyles from './RenterAdult.module.scss';
export const RENTER_RELATIONSHIPS = [
  { label: 'Roommate', value: 'Roommate' },
  { label: 'Partner', value: 'Partner' },
  { label: 'Spouse', value: 'Spouse' },
];
const AdultPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [searchParams] = useSearchParams();
  const navigateNext = searchParams.get(NavigationKeys.NEXT);
  const navigateSection = searchParams.get(NavigationKeys.SECTION);
  const renterInfo = useSelector(selectRenterProfileInformation);
  const adultLength = useMemo(
    () =>
      renterInfo?.applications?.find((application) => application.id === Number(applicationId))?.adults?.length ?? 0,
    [applicationId, renterInfo]
  );
  const navigate = useNavigate();
  const renterRole = useSelector(applicantRenterRole);

  useEffect(() => {
    if (renterRestrictions[renterRole].adults) {
      navigate(renterRoutes.renterRelationAdultConfirmation.replace(':applicationId', `${applicationId}`));
    }
  }, [renterRole, navigate, applicationId]);

  useEffect(() => {
    dispatch(setApplicationId(applicationId));
  }, [applicationId, dispatch]);

  const continueButtonOnClick = useCallback(
    ({ values, setErrors, resetForm, setSubmitting }: AdultDispatchProps) => {
      if (isEqual(values, RenterAdultInitialValues)) {
        return navigate(renterRoutes.renterRelationAdultConfirmation.replace(':applicationId', `${applicationId}`));
      }

      dispatch(
        addAdultsToApplication({
          applicationId: Number(applicationId),
          adults: { ...values, phoneNumber: `+${values.phoneNumber}` },
        })
      )
        .unwrap()
        .then(() => {
          if (resetForm) {
            resetForm();
          } else {
            navigateNext
              ? navigate(`${navigateNext}?section=${navigateSection}`)
              : navigate(renterRoutes.renterRelationAdultConfirmation.replace(':applicationId', `${applicationId}`));
          }
        })
        .catch((errorObject) => {
          if (setErrors) {
            setErrors(errorObject);
          }

          if (setSubmitting) {
            setSubmitting(false);
          }
        });
    },
    [applicationId, dispatch, navigate, navigateNext, navigateSection]
  );

  return (
    <Formik
      initialValues={RenterAdultInitialValues}
      validationSchema={renterAdultSchema}
      validateOnBlur
      enableReinitialize
      onSubmit={(values, { resetForm, setErrors, setSubmitting }) => {
        continueButtonOnClick({
          values,
          setErrors,
          resetForm: resetForm as (nextState?: Partial<FormikState<RenterAdultProps>> | undefined) => void,
          setSubmitting,
        });
      }}
    >
      {({ values, isValid, setErrors, isSubmitting, setSubmitting, dirty: isDirty }) => (
        <Form>
          <FormCloseWarning isDirty={isDirty} />
          <div className={classNames({ [adultStyles.renterRelationContainer]: adultLength > 1 })}>
            <h2 className={styles.renterInvitePropertyH2}>
              {adultLength > 1 ? (
                <>
                  {t('renter.relationship.headingAdditionalAdult')}&nbsp;
                  <span className={styles.renterSpan}> {t('renter.relationship.coocupant')} </span>&nbsp;
                  {t('renter.relationship.contactInfo')}.
                </>
              ) : (
                <>
                  {t('renter.relationship.enterAdultDetails')}
                  <span className={styles.renterSpan}> {t('renter.relationship.coocupant')} </span>&nbsp;
                  {t('renter.relationship.contactInfo')}.
                </>
              )}
            </h2>
            <div className={adultStyles.maritalContainer} key={adultLength}>
              <FormikSelectField
                hasFloatingLabel={true}
                floatingLabel={t('renter.relationship.relationshipLabel')}
                placeholder={t('renter.realtionship.select')}
                name="relationship"
                options={RENTER_RELATIONSHIPS}
                dropDownIconContainer={styles.dropDownFillArrow}
              />
              <div className={adultStyles.phoneNumberContainer}>
                <FormikPhoneNumber
                  label={t('renter.relationship.adultPhoneLabel')}
                  name="phoneNumber"
                  isCountryCodeDisabled={false}
                  onlyCountry={[]}
                  isCountryCodeEditable={false}
                />
              </div>
              <div className={adultStyles.userField}>
                <span className={adultStyles.userLabel}>{t('renter.relationship.adult') + ` ${adultLength + 1}`}</span>
                <FormikField
                  className={adultStyles.inputBackgroundColor}
                  name="emailAddress"
                  placeholder={t('renter.relationship.adultEmailAddress')}
                />
              </div>
            </div>
            <div className={adultStyles.footer}>
              <RCButton
                className={classNames(styles.renterAddButton, styles.continueBtn, adultStyles.button)}
                variant="outline"
                type="submit"
                disabled={!isValid || isSubmitting || !isDirty}
              >
                {t('renter.relationship.addAnotherAdult')}
              </RCButton>
              <RCButton
                disabled={!isValid || isSubmitting || !isDirty}
                className={classNames(styles.continueBtn, adultStyles.button)}
                onClick={() => {
                  setSubmitting(true);
                  continueButtonOnClick({ values, setErrors, setSubmitting });
                }}
                variant="outline"
              >
                {t('renter.phone.continueButtonText')}
              </RCButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
const RenterAdult = (): JSX.Element => {
  const maxHeight = useDeviceHeight(220);

  return (
    <RenterContainer>
      <section className={styles.renterInvitePropertyContainer}>
        <div style={{ paddingRight: '5px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
          <RenterIconInfoContainer />
          <AdultPage />
        </div>
      </section>
    </RenterContainer>
  );
};

export default RenterAdult;
