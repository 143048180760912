import { useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getAgentProfileInformation, setAgentOrganizationInviteDetails } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';
import { agentInvitedSchema } from 'schema/agentSchema';

import RCButton from 'components/shared/Button/Button';
import Card from 'components/shared/CustomCard/Card';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import FormikField from 'components/shared/TextField/FormikTextField';
import { PhoneNoTypes } from 'constants/agentConstants';
import { parseResponseErrors } from 'helpers/helper';
import { updateAgentProfessionalProfile } from 'services/agentService';
import { Notification } from 'shared/Notification/Notification';
import { routes } from 'shared/routes';
import { profileInvitedScreenInitialValues } from 'shared/types/agentTypes';
import { AgentOrganizationInvitesProps } from 'shared/types/organizationTypes';

import styles from './ProfileInvitedScreen.module.scss';

export interface ProfileInvitedScreenProps {
  invitedBy: string;
  invitedOrganization: string;
  organizationAddress: string;
}
export const ProfileInvitedScreen = ({
  invitedBy,
  invitedOrganization,
  organizationAddress,
}: ProfileInvitedScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentOrganization = useSelector(selectedOrganization);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Formik
      initialValues={profileInvitedScreenInitialValues}
      validationSchema={agentInvitedSchema}
      onSubmit={(values): void => {
        setIsSaving(true);
        updateAgentProfessionalProfile(
          { ...values, phoneType: values.phoneType?.toLocaleLowerCase() || '' },
          currentOrganization?.id
        )
          .then(() => {
            dispatch(getAgentProfileInformation())
              .unwrap()
              .then(() => {
                dispatch(setAgentOrganizationInviteDetails({} as AgentOrganizationInvitesProps));
                navigate(routes.applicants);
              });
          })
          .catch((error) => {
            Notification({ message: parseResponseErrors(error) });
          })
          .finally(() => {
            setIsSaving(false);
          });
      }}
    >
      {({ isValid }) => (
        <Form className={styles.MainContainer}>
          <Card
            rowClassName={styles.CardRowContainer}
            parentClassName={styles.CardContainer}
            className={styles.Container}
            xl={8}
            lg={10}
            md={12}
          >
            <div className={styles.MainHeading}>{t('imageAlt.Profile')}</div>
            <div className={styles.SubHeading}>{t('agent.invitedScreen.organizationDetails')}</div>
            <div className={styles.OrganizationDetails}>
              <div className={styles.InvitedByDetails}>
                <div className={styles.InviteSubHeading}>{t('agent.invitedScreen.invitedBy')}</div>
                <div className={styles.InvitedBy}>{invitedBy}</div>
              </div>
              <div className={styles.InvitedByDetails}>
                <div className={styles.InviteHeading}>{invitedOrganization}</div>
                <div className={styles.InvitedBy}>{organizationAddress}</div>
              </div>
            </div>
            <div className={styles.SubHeading}>{t('agent.invitedScreen.personalDetails')}</div>
            <div className={styles.PersonalDetails}>
              <Row className={styles.InformationRow}>
                <Col md={6} sm={12} p={0}>
                  <FormikField
                    className="inputBackgroundColor"
                    name="firstName"
                    placeholder={t('renter.PhotoIdInformation.firstName')}
                    label={t('renter.PhotoIdInformation.firstName')}
                  />
                </Col>

                <Col md={6} sm={12} p={0}>
                  <FormikField
                    className="inputBackgroundColor"
                    name="lastName"
                    placeholder={t('renter.PhotoIdInformation.lastName')}
                    label={t('renter.PhotoIdInformation.lastName')}
                  />
                </Col>
              </Row>
              <Row className={styles.InformationRow}>
                <Col md={8} sm={12}>
                  <FormikPhoneNumber
                    name="phoneNumber"
                    placeholder="(  )  -"
                    label={t('agent.invitedScreen.phoneNumber')}
                    isShowCountryCode={false}
                  />
                </Col>
                {/* Phone Type */}
                <Col md={4} sm={12} className={styles.StateSelectField}>
                  <FormikSelectField
                    name="phoneType"
                    options={PhoneNoTypes}
                    placeholder={t('renter.realtionship.select')}
                    dropDownIconContainer={styles.dropDownFillArrow}
                    hasFloatingLabel={true}
                    backgroundColor="#f7fafa"
                    floatingLabel={t('agent.profile.phoneType.text')}
                    isCustomOptions={true}
                  />
                </Col>
              </Row>
            </div>
            <div className={styles.Footer}>
              <RCButton disabled={!isValid || isSaving} className={styles.SubmitButton} type="submit">
                {t('saveAndContinue')} {isSaving && <Spinner className={styles.Spinner} />}
              </RCButton>
            </div>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
