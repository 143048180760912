import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export const FileCheckSVG = (): JSX.Element => (
  <Svg width="16" height="14" viewBox="0 0 16 14" fill="none">
    <G clip-path="url(#clip0_10515_63662)">
      <Path
        d="M1.77778 12.6875H7.90278C8.23611 13.1906 8.66389 13.6309 9.16111 13.9809C9.07222 13.9945 8.98333 14 8.89167 14H1.77778C0.797222 14 0 13.2152 0 12.25V1.75C0 0.784766 0.797222 0 1.77778 0H6.375C6.84722 0 7.3 0.183203 7.63333 0.511328L10.1472 2.98594C10.4806 3.31406 10.6667 3.75977 10.6667 4.22461V5.43047C10.1889 5.56445 9.73889 5.7668 9.33333 6.02656V4.375H7.11111C6.61944 4.375 6.22222 3.98398 6.22222 3.5V1.3125H1.77778C1.53333 1.3125 1.33333 1.50937 1.33333 1.75V12.25C1.33333 12.4906 1.53333 12.6875 1.77778 12.6875ZM8 10.0625C8 9.01821 8.42143 8.01669 9.17157 7.27827C9.92172 6.53984 10.9391 6.125 12 6.125C13.0609 6.125 14.0783 6.53984 14.8284 7.27827C15.5786 8.01669 16 9.01821 16 10.0625C16 11.1068 15.5786 12.1083 14.8284 12.8467C14.0783 13.5852 13.0609 14 12 14C10.9391 14 9.92172 13.5852 9.17157 12.8467C8.42143 12.1083 8 11.1068 8 10.0625ZM13.8694 8.87852C13.6972 8.70898 13.4139 8.70898 13.2417 8.87852L11.5556 10.5383L10.7583 9.75352C10.5861 9.58398 10.3028 9.58398 10.1306 9.75352C9.95833 9.92305 9.95833 10.202 10.1306 10.3715L11.2417 11.4652C11.4139 11.6348 11.6972 11.6348 11.8694 11.4652L13.8694 9.49648C14.0417 9.32695 14.0417 9.04805 13.8694 8.87852Z"
        fill="#5E6566"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_10515_63662">
        <Rect width="16" height="14" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
