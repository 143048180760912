import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardCvcElement, StripeCardExpiryElement, StripeCardNumberElement } from '@stripe/stripe-js';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import {
  applicationPropertyLink,
  renterApplication,
  selectRenterProfileInformation,
} from 'redux/selectors/renterSelector';
import { setSecurityQuestionsRenter } from 'redux/slices/authSlice';
import { confirmRenterApplication, getRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as InfoIcon } from 'assets/svgs/infoIconFilled.svg';
import Button from 'components/shared/Button/Button';
import ErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import FormikField from 'components/shared/TextField/FormikTextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { initialFormValues, initialPaymentAddress, initialStyles } from 'constants/paymentConstants';
import { findApplication } from 'helpers/agentHelper';
import { sendGuarantorInvite } from 'helpers/guarantor';
import { parseResponseErrors } from 'helpers/helper';
import { useDeviceInfoCollector } from 'hooks/useDeviceInfoCollector';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { InitialValuesPayment, PaymentFormProps } from 'shared/types/renterTypes';
import { stripeInterval } from 'shared/types/stripeType';

import styles from './PaymentForm.module.scss';

const PaymentForm = ({ clientSecret, setIsOtpModalOpen, setInitialOtpCode }: PaymentFormProps): JSX.Element => {
  const elements = useElements();
  const { jscInputRef, getDeviceInfo } = useDeviceInfoCollector();
  const { applicationId } = useParams();
  const stripe = useStripe();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(initialFormValues);
  const { t } = useTranslation();
  const { values, setFieldValue, setFieldTouched, errors } = useFormikContext<InitialValuesPayment>();
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const [cardNumberError, setCardNumberError] = useState<string>('');
  const [isWaitingForWebhook, setIsWaitingForWebhook] = useState(false);
  const [cardCvcError, setCardCvcError] = useState<string>('');
  const [cardExpiryError, setCardExpiryError] = useState<string>('');
  const applicationInformation = useSelector(renterApplication);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const { propertyId } = useSelector(applicationPropertyLink);
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const currentApplication = useMemo(
    () => findApplication(applicationInformation, Number(applicationId)),
    [applicationId, applicationInformation]
  );
  const resetForm = useCallback(() => {
    setFieldValue('name', '');
    setFieldTouched('name', false);
    setIsFormFilled(initialFormValues);
  }, [setFieldTouched, setFieldValue]);
  const paymentHandler = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setIsPaymentInProgress(true);

      const cardElement = elements.getElement(CardNumberElement) as StripeCardNumberElement;
      const cvcElement = elements.getElement(CardCvcElement) as StripeCardCvcElement;
      const expiryElement = elements.getElement(CardExpiryElement) as StripeCardExpiryElement;

      await stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: values.name,
              phone: '',
              address: initialPaymentAddress,
            },
          },

          return_url: process.env.REACT_APP_PROPERTY_PAYMENT_SUCESS_URL ?? '',
        })
        .then(({ error }) => {
          if (error) {
            Notification({ message: error.message || t('renter.payment.unsuccessful!') });
            setIsPaymentInProgress(false);

            return;
          }

          setIsWaitingForWebhook(true);
        })
        .catch((error) => {
          Notification({ message: parseResponseErrors(error) });
        });

      cardElement.clear();
      cvcElement.clear();
      expiryElement.clear();
      resetForm();
    },
    [stripe, elements, clientSecret, values.name, resetForm, t]
  );

  useEffect(() => {
    setIsFormCompleted(!isFormFilled.cvc || !isFormFilled.number || !isFormFilled.expiry || isEmpty(values.name));
  }, [isFormFilled, values.name]);

  const hasCardErrors = useMemo(
    (): boolean => !!cardNumberError || !!cardCvcError || !!cardExpiryError,
    [cardCvcError, cardExpiryError, cardNumberError]
  );
  const isButtonDisabled = useMemo(
    (): boolean => isPaymentInProgress || isFormCompleted || !isEmpty(errors.name) || hasCardErrors,
    [errors, hasCardErrors, isFormCompleted, isPaymentInProgress]
  );

  useEffect(() => {
    if (!isWaitingForWebhook) {
      return;
    }

    const timer = setInterval(() => {
      dispatch(getRenterProfileInformation())
        .unwrap()
        .then((renter) => {
          if (renter.isPaymentSucceeded) {
            clearInterval(timer);
            setIsWaitingForWebhook(false);
            setIsPaymentInProgress(false);

            const guarantorId = currentApplication?.guarantor?.id;

            if (guarantorId && !!applicationId && renterDetails.firstName && renterDetails.lastName) {
              sendGuarantorInvite(guarantorId, Number(applicationId), propertyId);
            }

            if (!currentApplication?.isConfirmed) {
              const body = getDeviceInfo();

              if (!body) {
                return;
              }

              dispatch(confirmRenterApplication({ applicationId: Number(applicationId), body }))
                .unwrap()
                .then((res) => {
                  if (res?.success) {
                    if (res?.kiqEnabled) {
                      dispatch(setSecurityQuestionsRenter(res?.kba?.questionSet));
                      navigate(renterRoutes.generateRenterSecurityQuestionnaire(applicationId));

                      return;
                    }

                    if (res?.otpEnabled) {
                      setInitialOtpCode(
                        res?.crossCoreFullResponse?.clientResponsePayload?.decisionElements?.[0]?.decisions?.[0]?.value
                      );
                      setIsOtpModalOpen(true);

                      return;
                    }
                  } else {
                    res?.error && Notification({ message: res?.error?.Message });
                  }

                  dispatch(getRenterProfileInformation());
                  navigate(renterRoutes.applications);
                });
            }
          }
        })
        .catch((error) => {
          clearInterval(timer);
          setIsWaitingForWebhook(false);
          setIsPaymentInProgress(false);
          Notification({ message: parseResponseErrors(error) });
        });
    }, stripeInterval);

    return () => clearInterval(timer);
  }, [
    applicationId,
    currentApplication?.guarantor?.id,
    currentApplication?.isConfirmed,
    dispatch,
    getDeviceInfo,
    isWaitingForWebhook,
    navigate,
    propertyId,
    renterDetails.firstName,
    renterDetails.lastName,
    setInitialOtpCode,
    setIsOtpModalOpen,
  ]);

  return (
    <form onSubmit={paymentHandler} className={styles.paymentFormContainer}>
      <input type="hidden" name="user_prefs2" id="user_prefs2" ref={jscInputRef} />
      {!isFormLoaded && (
        <div className={styles.spinnerContainer}>
          <Spinner></Spinner>
        </div>
      )}
      <div className={classNames({ [styles.hideFields]: !isFormLoaded })}>
        {isFormLoaded && (
          <div className={classNames(styles.textFieldContainer)}>
            <span className={styles.textFieldLabel}>{t('renter.paymentInformation.nameOnCard')}</span>

            <FormikField name="name" placeholder={t('renter.paymentInformation.fullName')} />
          </div>
        )}
        <div className={styles.paymentFieldContainer}>
          <span className={styles.textFieldLabel}>{t('renter.paymentInformation.cardNumber')}</span>
          <div className={classNames(styles.paymentField, { [styles.paymentFieldError]: cardNumberError })}>
            <CardNumberElement
              onChange={(e) => {
                setIsFormFilled({ ...isFormFilled, number: e.complete });
                setCardNumberError(e.error?.message ?? '');
              }}
              onReady={() => {
                setIsFormLoaded(true);
              }}
              options={{
                showIcon: true,

                style: {
                  base: initialStyles,
                },
              }}
            />
          </div>
          <ErrorMessage message={cardNumberError} />
        </div>
        <div className={styles.paymentDetails}>
          <div className={styles.paymentFieldContainer}>
            <span className={styles.textFieldLabel}>
              {t('renter.paymentInformation.cvc')}
              <Tooltip
                placement="top"
                text={t('renter.paymentInformation.cvcTooltip')}
                icon={<InfoIcon />}
                customIconClassName={styles.infoIconContainer}
              />
            </span>
            <div className={classNames(styles.paymentField, { [styles.paymentFieldError]: cardCvcError })}>
              <CardCvcElement
                onChange={(e) => {
                  setIsFormFilled({ ...isFormFilled, cvc: e.complete });

                  setCardCvcError(e.error?.message ?? '');
                }}
                options={{
                  style: {
                    base: initialStyles,
                  },
                }}
              />
            </div>
            <ErrorMessage message={cardCvcError} />
          </div>
          <div className={styles.paymentFieldContainer}>
            <span className={styles.textFieldLabel}>{t('renter.paymentInformation.expiry')}</span>
            <div className={classNames(styles.paymentField, { [styles.paymentFieldError]: cardExpiryError })}>
              <CardExpiryElement
                onChange={(e) => {
                  setIsFormFilled({ ...isFormFilled, expiry: e.complete });

                  setCardExpiryError(e.error?.message ?? '');
                }}
                options={{
                  style: {
                    base: initialStyles,
                  },
                }}
              />
            </div>
            <ErrorMessage message={cardExpiryError} />
          </div>
        </div>
      </div>

      {isFormLoaded && (
        <>
          <div className={styles.horizontalLine}></div>
          {/* <div className={classNames(styles.textFieldContainer, styles.coupenTextField)}>
              <span className={styles.textFieldLabel}>{t('renter.paymentInformation.coupnCode')}</span>
              <FormikField name="discountCoupon" placeholder={t('renter.paymentInformation.enterTheCodeIfYouHave')} />
              <Button className={styles.coupenButton}>{t('renter.paymentInformation.apply')}</Button>
            </div> */}
          <br />
          <div className={styles.buttonContainer}>
            <Button disabled={isButtonDisabled} type="submit" className={styles.button}>
              {t('renter.paymentInformation.pay30')}
              {isPaymentInProgress && <Spinner className={styles.spinnerClass} />}
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default PaymentForm;
