/* eslint-disable @typescript-eslint/naming-convention */
export const profileSummaryFirstColumnLabels: Record<string, string> = {
  PublicRecordsCount: 'Public Records count',
  TotalInquiries: 'Total Inquiries',
  InquiriesDuringLast6Months: 'Inquiries in last 6 Mos',
  TotalTradeItems: 'Total Tradelines',
  DelinquenciesOver30Days: 'Delinquencies Over 30 Days',
  DelinquenciesOver60Days: 'Delinquencies Over 60 Days',
  DelinquenciesOver90Days: 'Delinquencies Over 90 Days',
  MonthlyPaymentPartialFlagDesc: 'Mo Pymt Partial Flag Desc',
  DisputedAccountsExcluded: 'Disputed Accounts Excluded',
  DerogCounter: 'Derog Counter',
  NowDelinquentDerog: 'Now Delinquent Derog',
  WasDelinquentDerog: 'Was Delinquent Derog',
  RealEstatePaymentPartialFlagDesc: 'Real Estate Pymt Partial Flag Desc',
};
export const profileSummarySecondColumnLabels: Record<string, string> = {
  RevolvingAvailablePartialFlagDesc: 'RevolvingAvailablePartialFlagDesc',
  PaidAccounts: 'PaidAccounts',
  hasProfileSummary: 'hasProfileSummary',
  InstallmentBalance: 'InstallmentBalance',
  MonthlyPayment: 'MonthlyPayment',
  PastDueAmount: 'PastDueAmount',
  RealEstateBalance: 'RealEstateBalance',
  RealEstatePayment: 'RealEstatePayment',
  RevolvingBalance: 'RevolvingBalance',
  RevolvingAvailablePercent: 'RevolvingAvailablePercent',
  OldestTradeOpenDate: 'OldestTradeOpenDate',
  Y2KOldestTradeline: 'Y2KOldestTradeline',
  SatisfactoryAccount: 'SatisfactoryAccount',
};

export const profileSummaryNonBoldLabels = [
  'MonthlyPaymentPartialFlagDesc',
  'RealEstatePaymentPartialFlagDesc',
  'RevolvingAvailablePartialFlagDesc',
  'OldestTradeOpenDate',
  'Y2KOldestTradeline',
  'hasProfileSummary',
];

export const publicProfileFirstColumnLabels: Record<string, string> = {
  Status: 'Status',
  Court: 'Court',
  PlaintiffName: 'PlaintiffName',
  Amount: 'Amount',
  Evaluation: 'Evaluation',
  ReferenceNumber: 'ReferenceNumber',
  DisputeFlag: 'DisputeFlag',
  ECOA: 'ECOA',
  ConsumerComment: 'ConsumerComment',
};

export const publicProfileSecondColumnLabels: Record<string, string> = {
  StatusDate: 'StatusDate',
  FilingDate: 'FilingDate',
  BookPageSequence: 'BookPageSequence',
};
export const publicRecordNonBoldLabels = [
  'ReferenceNumber',
  'Evaluation',
  'ECOA',
  'ConsumerComment',
  'StatusDate',
  'FilingDate',
  'BookPageSequence',
  'DisputeFlag',
  'Type',
];

export const tradeLinesFirstColumnLabels: Record<string, string> = {
  OpenDate: 'Opened',
  TermsDuration: 'Terms',
  ECOA: 'ECOA',
};
export const tradeLinesAmountColumns = ['0', '1'];
export const tradeLinesSecondColumnLabels: Record<string, string> = {
  AmountPastDue: 'Past Due',
  MonthlyPaymentAmount: 'Monthly Payment',
  LastPaymentDate: 'Last Payment',
};
export const tradeLinesExtraFirstColumnLabels: Record<string, string> = {
  BalanceAmount: 'Balance',
  SpecialComment: 'Special Comment',
  AccountCondition: 'Condition',
  MaxPayment: 'Max Delinquency',
  ConsumerComment: 'Consumer Comment',
};
export const tradeLineExtraLabelsToHide = ['MaxPayment', 'ConsumerComment', 'SpecialComment'];
export const tradeLinesBoldLabels = [
  'AccountType',
  'Value',
  'BalanceAmount',
  'OpenOrClosed',
  'DelinquenciesOver30Days',
  'DelinquenciesOver60Days',
  'DelinquenciesOver90Days',
  'DerogCounter',
  'KOB',
  'SubscriberDisplayName',
  'AccountCondition',
];
export const consumerReportLabels: Record<string, string> = {
  Surname: 'Last Name',
  First: 'First',
  Middle: 'Middle',
};

export const addressReportLabels: Record<string, string> = {
  StreetName: 'Street Name',
  City: 'City',
  State: 'State',
  Zip: 'Zip',
};

export const employmentReportLabels: Record<string, string> = {
  Name: 'Name',
  AddressFirstLine: 'Address',
};

export const vantageScoreReportLabelsFirst: string[] = [
  'positiveScoreFactorCodeOne',
  'positiveScoreFactorCodeTwo',
  'positiveScoreFactorCodeThree',
  'positiveScoreFactorCodeFour',
];
export const vantageScoreReportLabelsSecond: string[] = [
  'ScoreFactorCodeOne',
  'ScoreFactorCodeTwo',
  'ScoreFactorCodeThree',
  'ScoreFactorCodeFour',
  'ScoreFactorCodeFive',
];

export enum ReportErrorTypes {
  EXPIRED = 'Expired',
  IN_PROGRESS = 'In progress',
  ERROR = 'Error',
}

export const criminalOffenseFirstColumnLabels: Record<string, string> = {
  Restitution: 'Restitution',
  Description: 'Description',
  CommitmentLocation: 'CommitmentLocation',
  SentenceLength: 'SentenceLength',
  ArrestDate: 'ArrestDate',
  WarrantDate: 'WarrantDate',
  SupervisionDate: 'SupervisionDate',
  Court: 'Court',
  ConvictionDate: 'ConvictionDate',
  Fines: 'Fines',
  OffenseDate: 'OffenseDate',
  SupervisionState: 'SupervisionState',
  Statute: 'Statute',
  ProbationSentenceLength: 'ProbationSentenceLength',
  CommitmentCounty: 'CommitmentCounty',
  OffenseCode: 'OffenseCode',
  Plea: 'Plea',
  FBINumber: 'FBINumber',
};

export const criminalOffenseSecondColumnLabels: Record<string, string> = {
  Status: 'Status',
  ArrestingAgency: 'ArrestingAgency',
  SupervisionCounty: 'SupervisionCounty',
  CommitmentState: 'CommitmentState',
  ConvictionLocation: 'ConvictionLocation',
  WarrantCounty: 'WarrantCounty',
  ReleaseDate: 'ReleaseDate',
  CommitmentDate: 'CommitmentDate',
  CourtCosts: 'CourtCosts',
  NCICcode: 'NCICcode',
  FilingDate: 'FilingDate',
  AmendedDisposition: 'AmendedDisposition',
  CaseType: 'CaseType',
  Disposition: 'Disposition',
  DispositionDate: 'DispositionDate',
  SuspendedSentence: 'SuspendedSentence',
  NCICcodeDescription: 'NCICcodeDescription',
  BookingNumber: 'BookingNumber',
};

export const criminalSubjectFirstColumnLabels: Record<string, string> = {
  Category: 'Category',
  Address: 'Address',
  Sex: 'Sex',
  Image: 'Image',
  Source: 'Source',
  EyeColor: 'EyeColor',
  DOB: 'DOB',
  Race: 'Race',
  CaseNumber: 'CaseNumber',
  Height: 'Height',
  DriversLicense: 'DriversLicense',
  Age: 'Age',
  Status: 'Status',
  Comments: 'Comments',
};
export const criminalSubjectSecondColumnLabels: Record<string, string> = {
  HairColor: 'HairColor',
  Weight: 'Weight',
  DeathVerificationCode: 'DeathVerificationCode',
  SkinTone: 'SkinTone',
  Jurisdiction: 'Jurisdiction',
  ScarsMarks: 'ScarsMarks',
  State: 'State',
  Alias: 'Alias',
  FullName: 'FullName',
  MilitaryService: 'MilitaryService',
  AgeAtDeath: 'AgeAtDeath',
};

export const canidateOffenseReportBoldedLabels = [
  'DOBSupplied',
  'LAST',
  'Name',
  'FIRST',
  'MIDDLE',
  'Description',
  'Court',
  'Status',
  'CaseType',
];
export const canidateSubjectReportBoldedLabels = ['Category', 'Source', 'State', 'FullName'];

export const evictionCanidateLabels: Record<string, string> = {
  Address: 'Address',
  Zone: 'Zone',
  FirstName: 'FirstName',
  Middle: 'Middle',
  LastName: 'LastName',
  City: 'City',
  State: 'State',
  ZipCode: 'ZipCode',
};

export const evictionReportFirstColumnLabels: Record<string, string> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'Count_National-1': 'Count_National-1',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'Count_West-4': 'Count_West-4',
  Count: 'Count',
};

export const evictionReportBoldedLabels = [
  'Address',
  'FirstName',
  'Middle',
  'LastName',
  'Notice_Type',
  'Court',
  'Plaintiff',
  'Judgement',
  'ConsumerStatement',
];

export const reportExpireyErrorCode = '287';

export enum IncomeReportNavbarKeys {
  INCOME_REPORT = 'income_report',
  SOURCE_OF_INCOME = 'source_of_income',
  SUPPORTING_DOCUMENTS = 'supporting_documents',
}

export enum IncomeReportNavbarURLParams {
  INCOME_REPORT = 'INCOME_REPORT',
  SOURCE_OF_INCOME = 'SOURCE_OF_INCOME',
  SUPPORTING_DOCUMENTS = 'SUPPORTING_DOCUMENTS',
}

export const INCOME_NAVBAR_TABS = [
  { key: 'SOURCE_OF_INCOME', label: 'incomeReport.sourceOfIncome' },
  {
    key: 'SUPPORTING_DOCUMENTS',
    label: 'agent.applicants.renterResume.sourceOfIncome.incomeReport.supportingDocuments',
  },
  { key: 'INCOME_REPORT', label: 'agent.applicants.renterResume.sourceOfIncome.incomeReport' },
];

export const allNegativeTradePaymentCodes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'G', 'J', 'L'];
export const allTradePaymentCodes = [...allNegativeTradePaymentCodes, 'C', 'N', '0', 'B', '-'];

export const tradePaymentIcons: Record<string, { label: string; color: string; iconInformation: string }> = {
  1: { label: '', color: '#F8E352', iconInformation: '30 days' },
  2: { label: '', color: '#F5A726', iconInformation: '60 days' },
  3: { label: '', color: '#EA7B1F', iconInformation: '90 days' },
  4: { label: '', color: '#EF2200', iconInformation: '120 days' },
  5: { label: '', color: '#A27EB8', iconInformation: '150 days' },
  6: { label: '', color: '#4A4A4A', iconInformation: '180 days' },
  7: {
    label: 'B',
    color: '',
    iconInformation: 'Chapter 13 Bankruptcy (Petitioned, Discharged, Reaffirmation of debt rescinded)',
  },
  8: { label: 'F', color: '', iconInformation: 'Foreclosure proceeding, deed in lieu.' },
  9: {
    label: 'BB',
    color: '',
    iconInformation: 'Chapter 7, 11 or 12 Bankruptcy (Petitioned, Discharged, Reaffirmation of debt rescinded).',
  },
  G: { label: 'FF', color: '', iconInformation: 'Collection H Foreclosure.' },
  J: { label: 'V', color: '', iconInformation: 'Voluntary surrender KRepossession' },
  L: { label: 'CH', color: '', iconInformation: 'Charge-off' },
  B: { label: 'NA', color: '', iconInformation: 'Account condition change, payment code not applicable.' },
  C: { label: 'tick', color: '', iconInformation: 'Current' },
  N: { label: 'tick', color: '', iconInformation: 'Current' },
  0: { label: 'tick', color: '', iconInformation: 'Current' },
};
export const AbsoluteLegendsArray = ['–', 'C', 'N', '0', '1', '2', '3'];
export const InitialLegendsArray = ['0', '1', '2', '3', '4', '5', '6'];
export const VerticalLegendArray = ['7', '8', '9', 'G', 'J', 'L', 'B'];
export const TradelineLowerHeaderLabels: string[] = ['AccountType', 'RevolvingOrInstallment', 'OpenOrClosed', 'Status'];
