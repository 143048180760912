import { StyleSheet, View } from '@react-pdf/renderer';

import { AddPropertyParams } from 'shared/types/propertyType';
import { FinancialData } from 'shared/types/reportTypes';

import { DocumentSVG } from '../../svgs/DocumentSVG';
import { IncomeReportBoxPDF } from '../IncomeReportBoxPDF/IncomeReportBoxPDF';
import { HeadingPDF } from '../shared/HeadingPDF/HeadingPDF';
import { VerificationBarPDF } from '../VerificationBarPDF/VerificationBarPDF';
const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
});

export const IncomeReportViewPDF = ({
  incomeTransactions,
  propertyInformation,
}: {
  incomeTransactions: FinancialData;
  propertyInformation: AddPropertyParams;
}): JSX.Element => (
  <View wrap={false} style={styles.Container}>
    <HeadingPDF heading="INCOME REPORT" />
    <IncomeReportBoxPDF propertyInformation={propertyInformation} incomeTransactions={incomeTransactions} />
    <VerificationBarPDF
      customIcon={<DocumentSVG />}
      verificationLabel="Financial Institutions"
      verificationLink="income_report_03192024.pdf"
    />
  </View>
);
