import { AxiosResponse } from 'axios';

import { AxiosPromise } from 'shared/types/axiosPromise';
import {
  CreateStripeKeyServiceProps,
  StripeClientDocuments,
  StripeClientKeyType,
  StripeClientSensitiveDocuments,
} from 'shared/types/stripeType';

import baseService from './baseService';

export const createStripeKey = (values: CreateStripeKeyServiceProps): AxiosPromise =>
  baseService.post<AxiosResponse<StripeClientKeyType>>('/stripe/identity/stripe-session', values);

export const getStripeInformation = (): AxiosPromise<StripeClientDocuments> =>
  baseService.get<StripeClientDocuments>('/stripe/identity/stripe-session');

export const getStripeSensitiveInformation = (): AxiosPromise<StripeClientSensitiveDocuments> =>
  baseService.get<StripeClientSensitiveDocuments>('/stripe/identity/document');
