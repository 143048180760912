import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { FieldViewPDFProps } from 'shared/types/pdfTypes';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: '1px',
  },
  label: {
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'capitalize',
  },
  value: {
    // padding: '0px 2px',
    alignItems: 'center',
    // overflow: 'hidden',
    color: '#303333',
    // textOverflow: 'ellipsis',
    fontSize: '12px',
    fontWeight: 'semibold',
  },
});

export const FieldViewPDF = ({ label, value, isReversed }: FieldViewPDFProps): JSX.Element => (
  <View style={isReversed ? { ...styles.container, flexDirection: 'column-reverse' } : styles.container} wrap={false}>
    <Text style={styles.label}>{label}</Text>
    <Text style={styles.value}>{value}</Text>
  </View>
);
