import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { QuestionnaireAnswerOptions } from 'constants/agentConstants';
import { PDF_QUESTIONS_PER_PAGE_MAX } from 'shared/constants';
import { FormValues } from 'shared/types/renterTypes';

import { QuestionPDF } from './QuestionsPDF';

const styles = StyleSheet.create({
  Container: {
    gap: '10px',
  },
  Heading: {
    color: '#303333',
    fontSize: '12px',
    fontStyle: 'bold',
    fontWeight: 600,
  },
  QuestionnaireContainer: {
    gap: '12px',
  },
});

interface Props {
  questionnaire: FormValues;
}

export const QuestionnairePart1PDF = ({ questionnaire }: Props): JSX.Element => {
  const { verificationQuestions } = questionnaire;

  return (
    <View style={styles.Container}>
      <Text style={styles.Heading}>Requested Information</Text>
      <View style={styles.QuestionnaireContainer}>
        {verificationQuestions?.map((question, index) => {
          if (index < PDF_QUESTIONS_PER_PAGE_MAX) {
            return (
              <QuestionPDF
                key={question.question}
                index={index + 1}
                answer={question.answer as QuestionnaireAnswerOptions}
                question={question.question}
                extraInformation={question.meta?.textDetails}
                dateRange={{
                  fromDate: question.meta?.fromDate,
                  toDate: question.meta?.toDate,
                }}
                numberDetails={question.meta?.numberDetails}
              />
            );
          }
        })}
      </View>
    </View>
  );
};
