//TODO: Temporarily disabled some non-interactive features and will re-enable later.

import { useMemo } from 'react';
import { Col, Nav, Navbar as BootstrapNavbar, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import { ReactComponent as HelpIcon } from 'assets/svgs/HelpIcon.svg';
import { ReactComponent as IntellirentIcon } from 'assets/svgs/IntellirentIcon.svg';
// import { ReactComponent as MessageIcon } from 'assets/svgs/MessageIcon.svg';
import placeholderImage from 'assets/svgs/PlaceholderRound.svg';
import { ReactComponent as PProfileIcon } from 'assets/svgs/ProfileIcon.svg';
import { ReactComponent as PropertyIcon } from 'assets/svgs/PropertyIcon.svg';
import { PropertyOptions } from 'constants/dropdownOptions';
import { checkFormPage, checkProfilePage } from 'helpers/agentHelper';
import { userInfo } from 'helpers/user';
import { IntellirentPublicUrls } from 'shared/constants';
import { routes } from 'shared/routes';
import { canViewRecordsAndActivity } from 'shared/types/agentInvite';
import { AgentProfileInformationProps } from 'shared/types/agentTypes';
import { OrganizationProps } from 'shared/types/organizationTypes';

import LabelIcon from '../../../LabelIcon/LabelIcon';
import PopoverDropdown from '../../../PopoverDropdown/PopoverDropdown';
import ProfileIcon from '../../../ProfileIcon/ProfileIcon';

// import SearchBar from '../../../SearchBar/SearchBar';
import styles from './../AgentNavbar.module.scss';

export interface AgentWebNavBarProps {
  selectedOrg: OrganizationProps;
  agentProfileInformation: AgentProfileInformationProps;
  isProfileSet?: boolean;
}

const AgentWebNavBar = ({
  selectedOrg,
  agentProfileInformation,
  isProfileSet = true,
}: AgentWebNavBarProps): JSX.Element => {
  const { t } = useTranslation();
  const currentOrganization = useSelector(selectedOrganization);
  const location = useLocation();
  const isProfilePage = checkProfilePage(location);
  const isFormPage = checkFormPage(location);
  const isNonProfilePage = useMemo((): boolean => !isFormPage && !isProfilePage, [isFormPage, isProfilePage]);

  return (
    <BootstrapNavbar expand="md" className={styles.navBarWrapper}>
      <div className={styles.navBarContainer}>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" className={styles.navToggler}>
          <LabelIcon className={styles.alignIcon} customIcon={IntellirentIcon} />
        </BootstrapNavbar.Toggle>
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className={classnames('me-auto', styles.agentNavbar)}>
            <Row className={classnames(styles.navBarRow, { [styles.ProfileNotSetRow]: !isProfileSet })}>
              <Col md={1} className={styles.navBarIcon}>
                {isNonProfilePage ? (
                  <LabelIcon className={styles.hideIcon} customIcon={IntellirentIcon} url={routes.applicants} />
                ) : (
                  <div className={styles.IntellirentLogo}>
                    <span className={styles.navIntelli}>intelli</span>
                    <span className={styles.navRent}>rent</span>
                  </div>
                )}
              </Col>
              {isProfileSet && (
                <>
                  <Col className={styles.navBarRowCol1} md={4} lg={4}>
                    <NavLink to={`${routes.applicants}?option=Active`} className={styles.dropdownToggle}>
                      {({ isActive }) =>
                        isActive ? (
                          <span className={styles.linkContainerActive}>
                            {<PProfileIcon />} {t('agent.applicants.property')}
                          </span>
                        ) : (
                          <div className={styles.linkContainer}>
                            <span className={styles.dropDownIcon}>{<PProfileIcon />}</span>
                            {t('agent.applicants.property')}
                          </div>
                        )
                      }
                    </NavLink>
                    {canViewRecordsAndActivity(currentOrganization) && (
                      <PopoverDropdown
                        navLink={t('agent.properties.property')}
                        icon={<PropertyIcon />}
                        options={PropertyOptions}
                        routeLink={routes.properties}
                      />
                    )}
                  </Col>
                  <Col md={3} lg={4} className={styles.navBarRowCol2}>
                    {/* <SearchBar className={styles.searchBar} /> */}
                  </Col>
                </>
              )}
              <Col
                className={classnames(styles.navBarRowCol3, { [styles.ProfileNotSetCol3]: !isProfileSet })}
                md={4}
                lg={3}
              >
                {isNonProfilePage ? (
                  <a
                    href={IntellirentPublicUrls.NEW_REQUEST}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.helpLink}
                  >
                    <LabelIcon
                      shouldStackIconAndLabel={!isProfileSet}
                      customIcon={HelpIcon}
                      label={t('application.help')}
                    />
                  </a>
                ) : (
                  <div className={styles.DesktopAgentEmail}>{agentProfileInformation?.email}</div>
                )}
                {/* {isProfileSet && <LabelIcon className={styles.hideIcon} customIcon={MessageIcon} url={routes.inbox} />} */}
                <ProfileIcon
                  name={userInfo(
                    agentProfileInformation.email,
                    agentProfileInformation.firstName,
                    agentProfileInformation.lastName
                  )}
                  isProfileSet={isProfileSet}
                  organizationName={selectedOrg?.name}
                  imageSrc={placeholderImage}
                  isNonProfilePage={isNonProfilePage}
                />
              </Col>
            </Row>
          </Nav>
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>
  );
};

export default AgentWebNavBar;
