import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { INITIAL_DEFAULT } from 'constants/publicConstants';
import { fetchVerifierQuestion, getUserDetails } from 'helpers/publicHelper';
import { VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { FormValues, RenterAddressDetails, RenterEmploymentDetails } from 'shared/types/renterTypes';

interface Props {
  token: string;
  type: VerifierURLType;
  isSubmitted: boolean;
}

export const useSaveVerificationPDF = ({
  token,
  type,
  isSubmitted,
}: Props): {
  questionnaire: FormValues;
  userEmploymentDetails: RenterEmploymentDetails;
  userAddressDetails: RenterAddressDetails;
} => {
  const [, setIsSubmitted] = useState(false);
  const [questionnaire, setQuestionnaire] = useState<FormValues>(INITIAL_DEFAULT);
  const [userEmploymentDetails, setUserEmploymentDetails] = useState<RenterEmploymentDetails>(
    {} as RenterEmploymentDetails
  );
  const [userAddressDetails, setUserAddressDetails] = useState<RenterAddressDetails>({} as RenterAddressDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchVerifierQuestion({ token: token, setQuestionnaire, dispatch, setIsSubmitted });

    if (type === VerifierURLType.VOE) {
      getUserDetails(token, setUserEmploymentDetails);
    } else {
      getUserDetails(token, setUserAddressDetails);
    }
  }, [dispatch, token, type, isSubmitted]);

  return { questionnaire, userAddressDetails, userEmploymentDetails };
};
