import { VehicleTypes } from 'constants/renterConstants';

export const vehicleInitialValue = {
  licensePlate: '',
  vehicleType: '',
  stateIssued: '',
  make: '',
  model: '',
  color: '',
  yearOfVehicle: '',
};

export type VehiclesInformationType = {
  id?: number;
  licensePlate?: string;
  vehicleType?: string;
  stateIssued?: string;
  make?: string;
  model?: string;
  color?: string;
  yearOfVehicle?: string;
};

export type RenterVehicleProps = {
  renterId: number;
  vehicles: VehiclesInformationType[];
};
export type VehiclesSelectType = {
  label: string;
  name: VehicleTypes.CAR | VehicleTypes.MOTORCYCLE;
};

export const vehicleInfoLabels = [
  { key: 'licensePlate', label: 'lisencePlate' },
  { key: 'stateIssued', label: 'state' },
  { key: 'yearOfVehicle', label: 'year' },
  { key: 'make', label: 'make' },
  { key: 'model', label: 'model' },
  { key: 'color', label: 'color' },
] as const; // Use 'as const' to infer a specific tuple type

export const MAX_OLD_CAR_YEAR = 1900;
