import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as PawIcon } from 'assets/svgs/petsIcon.svg';
import AnimalInformationTooltip from 'components/Renter/IdentityVerification/RenterDetails/AnimalsInformation/AnimalsInformationTooltip/AnimalInformationTooltip';
import { AnimalType } from 'constants/renterConstants';
import { renterRoutes } from 'shared/routes';
import { AnimalHeaderProps } from 'shared/types/renterTypes';

import styles from './AnimalHeader.module.scss';

export const AnimalHeader = ({
  animalType,
  animalInformation,
  isSummaryPage = false,
}: AnimalHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const getAnimalHeading = useMemo((): string => {
    if (animalType === AnimalType.PETS) {
      return animalInformation.length > 1 ? t('renter.petsInformation.petsText') : t('renter.petsInformation.petText');
    } else {
      return animalInformation.length > 1
        ? t('renter.petsInformation.assistiveAnimals')
        : t('renter.petsInformation.assistiveAnimal');
    }
  }, [animalInformation.length, animalType, t]);
  const handleAddInfo = (): void => {
    navigate(
      renterRoutes.generateRenterPetsInformation(
        currentApplicationId,
        renterRoutes.generateRenterPaymentVerification(currentApplicationId)
      )
    );
  };

  return (
    <div className={styles.AnimalHeader}>
      <div className={styles.HeaderContent}>
        <PawIcon />
        <span className={styles.HeaderText}>{getAnimalHeading}</span>
      </div>
      {isSummaryPage && (
        <AnimalInformationTooltip handleAddInfo={handleAddInfo} animalInformation={animalInformation} />
      )}
    </div>
  );
};
