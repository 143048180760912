import { useCallback } from 'react';
import { Modal as BModal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikErrors } from 'formik';
import { t } from 'i18next';
import { isSaving } from 'redux/selectors/renterSelector';
import { addVehicleInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { vehiclesInformationSchema } from 'schema/renterSchema';

import RenterModal from 'components/Renter/components/RenterModal/RenterModal';
import Button from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import { EditVehicleDetailsProps } from 'shared/types/renterTypes';
import { VehiclesInformationType } from 'shared/types/renterVehicleInformation';

import VehicleInformationInputFields from '../VehiclesForm/VehicleInformationInputFields/VehicleInformationInputFields';

import vehicleFormstyles from '../VehiclesForm/VehiclesForm.module.scss';
import styles from './VehiclesEditModal.module.scss';
const EditVehicleInformation = ({
  isModalVisible,
  setIsModalVisible,
  onHideHandler,
  data,
}: EditVehicleDetailsProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const isSavingInformation = useSelector(isSaving);
  const onClickHandler = useCallback(
    (values: VehiclesInformationType, setErrors: (errors: FormikErrors<VehiclesInformationType>) => void): void => {
      const id = values.id || 0;

      dispatch(addVehicleInformation({ values, id }))
        .unwrap()
        .then(() => {
          setIsModalVisible(false);
        })
        .catch((error) => {
          setErrors(error);
        });
    },
    [dispatch, setIsModalVisible]
  );

  return (
    <RenterModal show={isModalVisible} onHide={onHideHandler}>
      <BModal.Body>
        <section className={vehicleFormstyles.vehiclesForm}>
          <div className={styles.heading}>{t('renter.PhotoIdInformation.editDetails')}</div>
          <div className={styles.subHeading}>
            {t('renter.PhotoIdInformation.makeChanges')}&nbsp;
            <span className={styles.spanColor}>{t('renter.vehiclesInformation.vehicle')}</span>&nbsp;
            {t('renter.PhotoIdInformation.information')}
          </div>
          <Formik
            initialValues={data}
            validationSchema={vehiclesInformationSchema}
            onSubmit={(values, { setErrors }) => {
              onClickHandler(values, setErrors);
            }}
          >
            {({ isValid, dirty: isDirty }) => (
              <Form>
                <FormCloseWarning isDirty={isDirty} />
                <VehicleInformationInputFields />
                <div className={styles.renterButtonContainer}>
                  <Button
                    className={styles.renterButton}
                    variant="outline"
                    type="submit"
                    disabled={isSavingInformation || !isValid || !isDirty}
                  >
                    {t('renter.PhotoIdInformation.savechanges')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </BModal.Body>
    </RenterModal>
  );
};

export default EditVehicleInformation;
