import { useDispatch } from 'react-redux';
import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { setStore } from 'services/store';

import { rootReducer } from './reducer';
import { RootState } from './types';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authSlice'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
setStore(store);

export const persistor = persistStore(store);
