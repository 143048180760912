import { MenuItem } from 'shared/types/organizationTypes';

export const MOBILE_VIEW_WIDTH = 767;
export enum OrgazniationNavMenu {
  USERS = 'Users',
  TEAMS = 'Teams',
  CONNECTIONS = 'Connections',
  PRESETS = 'Presets',
  SECURITY = 'Security',
  ANALYTICS = 'Analytics',
}

export enum OrganizationSortBy {
  NAME = 'Name',
  ROLE = 'Role',
  LAST_ACTIVE_TIME = 'Last_Active_Time',
}
export enum OrganizationSortTypes {
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending',
}

export enum UsersControlOptions {
  TARGET = 'target',
  SORTBY = 'sortBy',
  SORTTYPE = 'sortType',
  FILTER = 'filter',
}

export const USERS_FILTER_OPTIONS = [
  {
    value: 'All',
    label: 'All',
  },
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Invited',
    label: 'Invited',
  },
];

export enum FilterOptions {
  ACTIVE = 'Active',
  LEADS = 'Leads',
  ARCHIVE = 'Archived',
  DENIED = 'Denied',
  APPROVED = 'Approved',
  LEGACY = 'Legacy',
}

export enum InviteOptions {
  PENDING = 'PENDING',
}

export enum InviteTypes {
  AGENT_SPECIFIC = 'AGENT_SPECIFIC',
  PROPERTY_SPECIFIC = 'PROPERTY_SPECIFIC',
}

export const INITIAL_USER_FILTER = { value: 'All', label: 'All' };

export const SKELETON_ELEMENTS = 4;

export const SORT_INITIAL_VALUE = {
  sortBy: OrganizationSortBy.NAME,
  sortType: OrganizationSortTypes.ASCENDING,
};
export const SEARCH_DEBOUNCE_TIME = 300;

export enum LastActiveStatus {
  NOW = 'now',
}
export const sideMenuList: MenuItem[] = [
  {
    title: OrgazniationNavMenu.USERS,
    subOptions: [{ title: OrgazniationNavMenu.TEAMS }, { title: OrgazniationNavMenu.CONNECTIONS }],
  },
  {
    title: OrgazniationNavMenu.PRESETS,
  },
  {
    title: OrgazniationNavMenu.SECURITY,
  },
  {
    title: OrgazniationNavMenu.ANALYTICS,
  },
];

export const organizationAPI = {
  getOrganizations: '/organization/organizations',
  emailInvite: '/organization/email-invite',
  switchOrganization: '/organization/switch-organization',
  generateOrganizationMemberInvite: '/organization/agent/invites',
  organizationMembers: 'organization/getMembers',
  organizationInvites: 'organization/invites',
};
