import { useTranslation } from 'react-i18next';
import { View } from '@react-pdf/renderer';

import { renterRestrictions } from 'constants/restrictionConstants';
import { ApplicationType } from 'shared/types/applicantsType';
import { AddPropertyParams } from 'shared/types/propertyType';
import { ApplicationAdditionalInfoType, RenterProfileInformationProps } from 'shared/types/renterTypes';
import { CriminalReportItem, EvictionReportItem } from 'shared/types/reportTypes';

import { GuarantorViewPDF } from '../../components/GuarantorViewPDF/GuarantorViewPDF';
import { HouseHoldViewPDF } from '../../components/HouseHoldViewPDF/AdultViewPDF';
import { RenterInfoGridPDF } from '../../components/RenterInfoGridPDF/RenterInfoGridPDF';
import { RenterPersonalDetailsPDF } from '../../components/RenterPersonalDetailsPDF/RenterPersonalDetailsPDF';
import { RenterProfilePDF } from '../../components/RenterProfilePDF/RenterProfilePDF';
import { CustomPDFPage } from '../../components/shared/CustomPDFPage/CustomPDFPage';
import { HeadingPDF } from '../../components/shared/HeadingPDF/HeadingPDF';

interface Props {
  application: ApplicationType;
  renterDetails: RenterProfileInformationProps;
  propertyInformation: AddPropertyParams;
  additionalInfo: ApplicationAdditionalInfoType;
  criminalReport: CriminalReportItem;
  housingRecordsInformation: EvictionReportItem;
}

export const RenterDetailPagePDF = ({
  application,
  renterDetails,
  propertyInformation,
  additionalInfo,
  criminalReport,
  housingRecordsInformation,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <CustomPDFPage
      propertyInformation={propertyInformation}
      renterDetails={renterDetails}
      application={application}
      shouldShowHeader={false}
    >
      <RenterPersonalDetailsPDF
        application={application}
        renterDetails={renterDetails}
        propertyInformation={propertyInformation}
        moveInDate={additionalInfo.desiredMoveInDate}
      />
      <RenterInfoGridPDF
        creditScore={renterDetails?.creditScore ?? ''}
        criminalRecords={criminalReport?.response?.XMLCRIM?.CICcriminal?.Candidates?.count ?? ''}
        housingCourtRecords={housingRecordsInformation?.response?.XML2025?.Response?.Count ?? ''}
        additionalInfo={additionalInfo}
      />
      <RenterProfilePDF renterDetails={renterDetails} />
      {(!renterRestrictions[application?.renterRole].guarantor ||
        !renterRestrictions[application?.renterRole].adults ||
        !renterRestrictions[application?.renterRole].minors) && (
        <View style={{ marginTop: '30px' }}>
          {!renterRestrictions[application?.renterRole].guarantor && (
            <View>
              <HeadingPDF heading={`Guarantor`} />
              <GuarantorViewPDF
                email={application?.guarantor?.emailAddress}
                relationship={application?.guarantor?.relationship ?? ''}
              />
            </View>
          )}
          {(!renterRestrictions[application?.renterRole].adults ||
            !renterRestrictions[application?.renterRole].minors) && (
            <View>
              <HeadingPDF
                heading={t('application.household')}
                subHeading={t('agnet.renterResumePdf.totalOccupants')}
                shouldShowCount
                count={(application?.adults?.length ?? 0) + (application?.minors?.length ?? 0) + 1}
                shouldShowLine
              />
              <HouseHoldViewPDF
                renterRole={application?.renterRole}
                minors={application?.minors ?? []}
                adults={application?.adults ?? []}
                isShowGap={(application?.adults?.length ?? 0) + (application?.minors?.length ?? 0) === 0}
              />
            </View>
          )}
        </View>
      )}
    </CustomPDFPage>
  );
};
