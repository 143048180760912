import React from 'react';
import { FormCheck, FormCheckProps } from 'react-bootstrap';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';

const Checkbox: React.FC<FormCheckProps> = ({ label, className, disabled, ...props }) => (
  <div className={styles.container}>
    <FormCheck
      type="checkbox"
      label={label}
      disabled={disabled}
      className={classNames(styles.formCheck, className)}
      {...props}
    />
  </div>
);

export default Checkbox;
