import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as CameraIcon } from 'assets/svgs/CameraIcon.svg';
import { ReactComponent as CheckIcon } from 'assets/svgs/CheckIconFilled.svg';
import { ReactComponent as SecurityIcon } from 'assets/svgs/SecurityIcon.svg';
import RCButton from 'components/shared/Button/Button';
import { GetStartedIdvPageProps } from 'shared/types/publicTypes';

import styles from './GetStartedIdvPage.module.scss';

export const GetStartedIdvPage = ({
  onClickHandler,
  isLoading,
  isBranded = false,
}: GetStartedIdvPageProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.ContentContainer}>
      <div className={styles.InformationContainer}>
        <SecurityIcon className={styles.SecurityIcon} />
        <div className={styles.InformationText}>{t('agent.identityVerification.weWorkWithIntellirent')}</div>
      </div>
      <div className={styles.Requirements}>
        <div className={styles.Requirement}>
          <CameraIcon className={styles.SecurityIcon} />
          <div className={styles.RequirnmentText}>{t('agent.identityVerification.youScanValidPhoto')}</div>
        </div>
        <div className={styles.Requirement}>
          <CheckIcon className={styles.SecurityIcon} />
          <div className={styles.RequirnmentText}>
            {t('agent.identityVerification.acceptedFormsOfId')}
            <ul className={styles.IDList}>
              <li>{t('agent.identityVerification.driverLicense')}</li>
              <li>{t('agent.identityVerification.passport')}</li>
              <li>{t('agent.identityVerification.governmentIssuePhotoId')}</li>
            </ul>
          </div>
        </div>
      </div>
      <RCButton
        disabled={isLoading}
        className={classNames(styles.GetStartedButton, { [styles.Branded]: isBranded })}
        onClick={onClickHandler}
      >
        {t('agent.identityVerification.getStarted')}&nbsp;
        {isLoading && <Spinner className={styles.SpinnerClass} />}
      </RCButton>
    </div>
  );
};
