import { StyleSheet, View } from '@react-pdf/renderer';

import { formatAmount } from 'helpers/user';
import { ApplicationAdditionalInfoType } from 'shared/types/renterTypes';

import { ChartOutlineIconSVG } from '../../svgs/ChartOutlineIconSVG';
import { GroupIconSVG } from '../../svgs/GroupIconSVG';
import { PetPawSVG } from '../../svgs/PetPawSVG';
import { GridItemPDF } from '../shared/GridItemPDF/GridItemPDF';

interface Props {
  additionalInfo: ApplicationAdditionalInfoType;
  creditScore: string;
  criminalRecords: string;
  housingCourtRecords: string;
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: '8px',
    width: '100%',
    backgroundColor: '#E6EFF0',
  },
  mainRow: {
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#E6EFF0',
    gap: '8px',
  },
});

export const RenterInfoGridPDF = ({
  additionalInfo,
  creditScore,
  criminalRecords,
  housingCourtRecords,
}: Props): JSX.Element => (
  <View>
    <View style={{ ...styles.container, paddingTop: '8px', paddingBottom: '4px' }}>
      <View style={styles.mainRow}>
        <GridItemPDF value={creditScore || '____'} label={`Credit`} />
        <GridItemPDF value={criminalRecords || '____'} label={`Criminal Records`} />
        <GridItemPDF value={housingCourtRecords || '____'} label={`Housing Court Records`} />
      </View>
    </View>
    <View style={{ ...styles.container, paddingBottom: '8px', paddingTop: '4px' }}>
      <View style={styles.mainRow}>
        <GridItemPDF
          value={`${Number(additionalInfo?.occupantsCount ?? 0) + 1}`}
          label={`Occupants`}
          icon={<GroupIconSVG />}
        />
        <GridItemPDF value={additionalInfo.animalsCount} label={`Animals`} icon={<PetPawSVG />} />
        <GridItemPDF
          value={`$${formatAmount(Number(additionalInfo.groupIncome))}`}
          label={`Budget`}
          icon={<ChartOutlineIconSVG />}
        />
      </View>
    </View>
  </View>
);
