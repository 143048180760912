//TODO: Temporarily disabled some non-interactive features and will re-enable later.

import { Dispatch, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
// import { hideAddressTooltipInfo } from 'pages/Properties/constant';
import { propertyTypes } from 'redux/selectors/propertySelector';

// import { ReactComponent as InfoIcon } from 'assets/svgs/InfoIcon.svg';
// import Checkbox from 'components/shared/Checkbox/Checkbox';
import CustomDatePicker from 'components/shared/CustomDatePicker/CustomDatePicker';
import { PlacesAutocompleteComponent } from 'components/shared/PlacesAutoCompleteComponent/PlacesAutoCompleteComponent';
import FormikField from 'components/shared/TextField/FormikTextField';
// import Tooltip from 'components/shared/Tooltip/Tooltip';
import { dateFormatBackend } from 'constants/calendarConstants';
import { AddressGeoLocation } from 'shared/types/agentTypes';
import {
  AddPropertyParams,
  PropertyAddressInitialValue,
  PropertyInitialValue,
  PropertyProps,
} from 'shared/types/propertyType';

import PropertyCategoryField from '../../../shared/FormikSelectField/FormikSelectField';

import styles from './AddProperty.module.scss';
export type BasicInfoProps = {
  setIsError: Dispatch<React.SetStateAction<boolean>>;
  isError: boolean | undefined;
  handleBlur?: (params: AddPropertyParams) => void;
  isPropertyEdit?: boolean;
};

const PropertyBasicInformation = ({ setIsError, handleBlur, isPropertyEdit = false }: BasicInfoProps): JSX.Element => {
  const propertyTypeList = useSelector(propertyTypes);
  const [geographicObject, setGeographicObject] = useState<AddressGeoLocation>({} as AddressGeoLocation);
  const initialValues = isPropertyEdit ? PropertyAddressInitialValue : PropertyInitialValue;
  const { values } = useFormikContext<typeof initialValues>();
  // if address and propertyType exist then make the api call on blur
  const isPropertyTypeAddressExist = values && !!values?.propertyType && !!values?.streetAddress1;
  const isPropertyTypeAndAddressAndTotalUnit =
    // if Property Type === Apartment then check the totalCount too
    values.propertyType === 'Apartment'
      ? isPropertyTypeAddressExist && !!values.totalUnits
      : isPropertyTypeAddressExist;
  const updateOnPropertyTypeAndAddress = (el: PropertyProps | { [x: string]: boolean }): void => {
    if (isPropertyTypeAddressExist) {
      handleBlur && handleBlur(el);
    }
  };
  const onBlurPropertyAddress = (): void => {
    const { latitude, longitude, timezone } = geographicObject;

    if (isPropertyTypeAndAddressAndTotalUnit) {
      handleBlur &&
        handleBlur({
          propertyType: values?.propertyType,
          streetAddress1: values?.streetAddress1,
          state: values?.state,
          zipCode: values?.zipCode,
          totalUnits: values?.propertyType === 'Apartment/Community' ? values?.totalUnits : undefined,
          latitude,
          longitude,
          timezone,
        });
    }
  };

  return (
    <div>
      <Row className={styles.row}>
        <Col md={4} sm={12}>
          <div className={styles.propertyType}>{t('agent.addProperty.propertyTypeLabel')}</div>
        </Col>
        <Col md={8} sm={12}>
          <PropertyCategoryField
            name="propertyType"
            placeholder={t('agent.addProperty.selectPropertyTypePlaceholder')}
            options={propertyTypeList}
            handleBlur={onBlurPropertyAddress}
          />
        </Col>
      </Row>
      <Row>
        <h1 className={styles.formHeading}>{t('agent.addProperty.basicPropertyAddressLabel')}</h1>
      </Row>
      <Row>
        <Col md={12} sm={12}>
          <div className={classNames(styles.addressHelpText)}>{t('agent.addProperty.basicPropertyAddress')}</div>
        </Col>
      </Row>
      <Row className={styles.propertyBasicInformation}>
        <Col md={12} sm={12} className={styles.errorRelativenWithPlacesAutoComplete}>
          <PlacesAutocompleteComponent
            addressField="streetAddress1"
            setIsError={setIsError}
            placeholder="Type to search"
            shouldAcceptCustomAddress={false}
            labelField="Address"
            handleBlur={onBlurPropertyAddress}
            className="boldLabel"
            setGeoLocationInformation={setGeographicObject}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12} className={styles.customTextFieldWithLabel}>
          <FormikField
            name="totalUnits"
            label={`Unit # (${
              values.propertyType === 'Apartment/Community' ? 'Required' : 'Recommended if applicable'
            })`}
            type="text"
            className={styles.customTextFieldWithLabel}
            handleBlur={(el) => updateOnPropertyTypeAndAddress(el as PropertyProps)}
          />
        </Col>
        <Col lg={6} md={12} sm={12} className={styles.datePicker}>
          <CustomDatePicker
            name="dateAvailable"
            handleBlur={(date) =>
              updateOnPropertyTypeAndAddress({ dateAvailable: format(date, dateFormatBackend) } as PropertyProps)
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12} className={styles.customTextFieldWithLabel}>
          <FormikField
            name="name"
            type="text"
            label="Property Name or Title (Optional)"
            className={styles.inputBackgroundColor}
            handleBlur={(el) => updateOnPropertyTypeAndAddress(el as PropertyProps)}
          />
        </Col>
      </Row>
      {/* {values.propertyType === 'Apartment' && (
        <Row className={styles.inputContainer}>
          <Col md={12} sm={12} className={styles.customTextFieldWithLabel}>
            <FormikField
              name="totalUnits"
              type="text"
              label="Building Count"
              handleBlur={onBlurPropertyAddress}
              // onWheel={(event) => event.currentTarget.blur()}
            />
          </Col>
        </Row>
      )} */}
      {/* {!isPropertyEdit && (
        <Row>
          <Col lg={7} md={12}>
            <Field name="sharedLivingSpace">
              {({ field }: FieldProps) => (
                <Checkbox
                  label={t('agent.properties.property.address.roomRent')}
                  className={styles.checkboxLabel}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    updateOnPropertyTypeAndAddress({ [field.name]: !field.value });
                  }}
                  checked={field.value}
                />
              )}
            </Field>
          </Col>
          <Col className={styles.alignToolTip}>
            <Field name="hideAddress">
              {({ field }: FieldProps) => (
                <Checkbox
                  label={t('agent.properties.property.address.hide')}
                  className={styles.checkboxLabel}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    updateOnPropertyTypeAndAddress({ [field.name]: !field.value });
                  }}
                  checked={field.value}
                />
              )}
            </Field>
            <Tooltip
              text={hideAddressTooltipInfo}
              icon={<InfoIcon data-testid="tooltip-icon" className={styles.infoToolTip} />}
              placement={'top'}
            />
          </Col>
        </Row>
      )} */}
    </div>
  );
};

export default PropertyBasicInformation;
