import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { RenterAddressTypes } from 'constants/renterConstants';
import { formatAmountToUsdString } from 'helpers/helper';
import { AddressBoxPDFProps } from 'shared/types/pdfTypes';

import { GreyBackgroundTextPDF } from '../shared/GreyBackgroundTextPDF/GreyBackgroundTextPDF';
import { VerificationStatusPDF } from '../shared/VerificationStatusPDF/VerificationStatusPDF';
const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    padding: '4px 8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '4px',
    border: '1px solid #E1EAEB',
    background: '#FFF',
    gap: '5px',
  },
  FirstRow: {
    paddingTop: '5px',
    paddingRight: '5px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  Address: {
    overflow: 'hidden',
    color: '#303333',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    fontWeight: 'semibold',
  },
  StatusContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'center',
  },
  SecondRow: {
    paddingBottom: '5px',
    paddingRight: '5px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  StateCity: {
    overflow: 'hidden',
    color: '#303333',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    fontWeight: 'semibold',
    paddingLeft: '2px',
  },
  MonthlyRentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
  },
  Label: {
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
  Value: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
  },
  DescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const AddressBoxPDF = ({
  address,
  city,
  zipCode,
  description,
  monthlyRent,
  reasonToMove,
  state,
  isRented = false,
  addressType,
  addressId,
  addressVerificationInformation,
  classification,
}: AddressBoxPDFProps): JSX.Element => {
  const { t } = useTranslation();
  const getRentedText = (): string => {
    if (isRented) {
      return addressType === RenterAddressTypes.PRIOR
        ? t('renter.ConfirmPresentAddress.iRented')
        : t('renter.ConfirmPresentAddress.iRent');
    }

    return t('renter.ConfirmPresentAddress.iDoNotRent');
  };
  const verificationInformation = useMemo(
    () =>
      addressVerificationInformation.find(
        (verifierAddress) => verifierAddress?.renterAddressInformation?.addressId === addressId
      ),
    [addressId, addressVerificationInformation]
  );

  return (
    <View style={styles.Container} wrap={false}>
      <View style={styles.FirstRow}>
        <Text style={styles.Address}>{address}</Text>
        <View style={styles.StatusContainer}>
          <GreyBackgroundTextPDF textValue={getRentedText()} />
          {verificationInformation?.verifierStatus && (
            <VerificationStatusPDF status={verificationInformation?.verifierStatus} />
          )}
        </View>
      </View>
      <View style={styles.SecondRow}>
        <Text style={styles.StateCity}>{`${city}, ${state} ${zipCode}`}</Text>
        {monthlyRent ? (
          <View style={styles.MonthlyRentContainer}>
            <Text style={styles.Label}>{t('application.monthlyRent')}</Text>
            <Text style={styles.Value}>{formatAmountToUsdString(monthlyRent)}</Text>
          </View>
        ) : (
          <Text style={styles.Value}>
            {addressType === RenterAddressTypes.PRIOR
              ? t('agnet.renterResumePdf.iOwnedThisHome')
              : t('agnet.renterResumePdf.iOwnThisHome')}
          </Text>
        )}
      </View>
      {!!classification && (
        <View style={styles.DescriptionContainer}>
          <Text style={styles.Label}>{t('address.livingSituation.classification.')}</Text>
          <Text style={styles.Value}>{classification}</Text>
        </View>
      )}
      {!!description && (
        <View style={styles.DescriptionContainer}>
          <Text style={styles.Label}>{t('agent.properties.property.description')}</Text>
          <Text style={styles.Value}>{description}</Text>
        </View>
      )}
      {reasonToMove && (
        <View style={styles.DescriptionContainer}>
          <Text style={styles.Label}>{t('agnet.renterResumePdf.reasonForMoving')}</Text>
          <Text style={styles.Value}>{reasonToMove}</Text>
        </View>
      )}
    </View>
  );
};
