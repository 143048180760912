import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isSaving } from 'redux/selectors/propertySelector';

import RCButton from 'components/shared/Button/Button';
import FormikField from 'components/shared/TextField/FormikTextField';
import { RenterEmploymentsInitialValue } from 'shared/types/renterEmploymentInformation';

import DescriptionCard from '../../RenterPresentIncomeSummary/DescriptionCard/DescriptionCard';

import indexStyles from '../../../RenterIndex.module.scss';
import renterEmployStyles from './RenterPresentForm.module.scss';

export const SelfEmployedConfirmation = ({ isForEditing = false }: { isForEditing?: boolean }): JSX.Element => {
  const { t } = useTranslation();
  const { values, dirty: isDirty, isValid } = useFormikContext<typeof RenterEmploymentsInitialValue>();
  const isSavingInformation = useSelector(isSaving);
  const isButtonDisabled = useMemo(
    (): boolean => (!isValid || !isDirty || isSavingInformation ? true : false),
    [isValid, isSavingInformation, isDirty]
  );

  return (
    <div className={renterEmployStyles.container}>
      {!isForEditing && (
        <div className={renterEmployStyles.linkedinHeading}>
          <span>
            {t('employment.presentSourceOfIncome.confirmAndCompeleteDetails')}
            <span className={renterEmployStyles.textContainer}> {t('renter.sourceOfIncome.selfEmployed')} </span>
            &nbsp;
            {t('renter.customInformation.heading2')}
          </span>
        </div>
      )}
      {!isForEditing && (
        <DescriptionCard shouldShowExtraInformation={false} data={values} isSelfEmployed shouldShowEditIcon={false} />
      )}
      <div className={renterEmployStyles.userFields}>
        <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.description')}</span>
        <FormikField
          className={renterEmployStyles.textField}
          name="jobDescription"
          placeholder={t('renter.sourceOfIncome.selfJobDescription')}
        />
      </div>

      <div className={renterEmployStyles.userFields}>
        <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.monthlyIncome')}</span>
        {!!values?.monthlyGrossIncome && <span className={renterEmployStyles.userGrossDollar}>$</span>}
        <FormikField
          className={classNames(renterEmployStyles.inputBackgroundColor, {
            [renterEmployStyles.grossIncome]: !!values?.monthlyGrossIncome,
          })}
          name="monthlyGrossIncome"
          placeholder="$ 00,000.00"
          onWheel={(event) => event.currentTarget.blur()}
        />
      </div>

      <div className={classNames(indexStyles.renterButtonContainer)}>
        <RCButton
          className={classNames(indexStyles.renterButton, renterEmployStyles.continueButton)}
          variant="outline"
          type="submit"
          disabled={isButtonDisabled}
        >
          {isForEditing ? t('application.save') : t('confirm and continue')}
        </RCButton>
      </div>
    </div>
  );
};
