import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { isAuthenticated, renterInviteCode, selectInviteCode } from 'redux/selectors/authSelector';
import { applicantRenterRole, isLoading, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { acceptInvite } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';

import RenterRedirection from 'components/Renter/RenterRedirection';
import Spinner from 'components/shared/Spinner/Spinner';
import { ExperianAuthenticationStatus } from 'constants/renterConstants';
import { resetInviteDetails } from 'helpers/auth';
import { lastCompletedStep } from 'helpers/renterProgress';
import { renterRoutes } from 'shared/routes';

const Renter = (): JSX.Element => {
  const navigate = useNavigate();
  const renterDetails = useSelector(selectRenterProfileInformation);
  const renterRole = useSelector(applicantRenterRole);
  const isInfoLoading = useSelector(isLoading);
  const isUserAuthenticated = useSelector(isAuthenticated);
  const inviteCode = useSelector(selectInviteCode);
  const renterSubInviteCode = useSelector(renterInviteCode);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    if (isUserAuthenticated && renterDetails?.userId) {
      //Accept the invite if there is an invite Code
      if (inviteCode) {
        dispatch(acceptInvite(inviteCode))
          .unwrap()
          .then(() => {
            resetInviteDetails(dispatch, false);
            navigate(renterRoutes.startApplication);
          });
      }

      //if renter has no application then he will be redirected to start application page
      if (isEmpty(renterDetails.applications) && !inviteCode && !renterSubInviteCode.inviteCode) {
        return navigate(renterRoutes.startApplication);
      }

      const unconfirmedApplication = renterDetails.applications?.find((application) => !application.isConfirmed);

      if (!inviteCode && !renterSubInviteCode.inviteCode) {
        if (unconfirmedApplication && renterDetails.experianAuth !== ExperianAuthenticationStatus.IN_PROGRESS) {
          navigate(lastCompletedStep(renterDetails, renterRole, unconfirmedApplication.id));
        } else {
          navigate(renterRoutes.applications);
        }
      }
    }
  }, [renterDetails, navigate, renterRole, isUserAuthenticated, inviteCode, dispatch, t, renterSubInviteCode]);

  if (isInfoLoading) {
    return <Spinner />;
  }

  return <RenterRedirection />;
};

export default Renter;
