/* eslint-disable react/jsx-no-target-blank */
import classNames from 'classnames';

import styles from './ReportItem.module.scss';
export interface ExperianReportItemProps {
  label: string;
  customContainerClass?: string;
  customLabelClass?: string;
  value: string;
  code?: string;
  hasGreyBackground?: boolean;
  isLabelBold?: boolean;
  isValueBold?: boolean;
  isCodeHighlighted?: boolean;
  isLink?: boolean;
  customValueClass?: string;
}
export const ReportItem = ({
  label,
  customContainerClass,
  customLabelClass,
  customValueClass,
  value,
  hasGreyBackground = false,
  isLabelBold = true,
  code,
  isValueBold = false,
  isCodeHighlighted = true,
  isLink = false,
}: ExperianReportItemProps): JSX.Element => (
  <div className={classNames(styles.Container, { [styles.GreyContainer]: hasGreyBackground }, customContainerClass)}>
    <div className={classNames(styles.LabelContainer, { [styles.BoldedText]: isLabelBold }, customLabelClass)}>
      {label}
    </div>
    {(!isLink || !value) && (
      <span className={classNames(styles.ValueContainer, { [styles.BoldedValueText]: isValueBold }, customValueClass)}>
        {!!code ? (
          <span className={classNames(styles.Code, { [styles.isCodeHighlighted]: isCodeHighlighted })}>{code}</span>
        ) : null}
        &nbsp;
        <span className={classNames(styles.Value)}>{value ? value : '—'}</span>
      </span>
    )}
    {isLink && value && (
      <div className={styles.LinkValueContainer}>
        <a className={styles.LinkValue} href={value} target="_blank" rel="noopener">
          {value}
        </a>
      </div>
    )}
  </div>
);
