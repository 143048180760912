import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { AddPropertyParams } from 'shared/types/propertyType';

interface Props {
  propertyInformation?: AddPropertyParams;
  isOnLastPage?: boolean;
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  continued: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    fontSize: '10px',
    color: '#5E6566',
    paddingVertical: '8px',
    fontStyle: 'italic',
  },
  footerRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    color: '#303333',
    fontWeight: 'semibold',
    borderTop: '1px solid #E1E8EB',
    paddingVertical: '8px',
  },
});

export const FooterPDF = ({ propertyInformation, isOnLastPage = false }: Props): JSX.Element => (
  <View style={styles.container} fixed>
    {isOnLastPage ? (
      <View style={{ ...styles.continued, justifyContent: 'center' }}>
        <Text>{'End of Application to Rent'}</Text>
      </View>
    ) : (
      <View style={styles.continued}>
        <Text style={{ marginRight: '16px' }}>{`Continued on the next page`}</Text>
      </View>
    )}
    <View style={styles.footerRow}>
      {propertyInformation && (
        <Text>{`${propertyInformation.agentFirstName} ${propertyInformation.agentLastName}`}</Text>
      )}
      {/* <Image src={DreamTeamIcon} style={{ width: '75px', height: '18px' }} /> */}
    </View>
  </View>
);
