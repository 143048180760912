import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';

import { RenterAddressSteps } from 'constants/renterConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../../RenterContainer';
import RenterIconInfoContainer from '../../RenterIconInfoContainer';

import { MinorsOccupantListCard } from './MinorsOccupantListCard';

import styles from './RelationMinorConfirmation.module.scss';

const RelationMinorConfirmation = (): JSX.Element => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const maxHeight = useDeviceHeight(220);
  const onContinueClickHandler = useCallback(() => {
    navigate(
      renterRoutes.generateAddressHistoryInformation(
        Number(applicationId),
        RenterAddressSteps.PRESENT_ADDRESS_CONFIRMATION
      )
    );
  }, [applicationId, navigate]);

  return (
    <RenterContainer>
      <div className={styles.container} style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
        <RenterIconInfoContainer />
        <MinorsOccupantListCard
          attributeLabel={t('renter.relationship.dateOfBirth')}
          label={t('renter.relationship.descriptionMinorTextCapital')}
          addAnotherButtonLabel={t('renter.relationship.addAnotherMinor')}
          confirmButtonLabel={t('renter.relationship.minorConfirmButton')}
          informationText={t('renter.relationship.minorInviteText')}
          floatingLabel={t('renter.relationship.minorAgeWarning')}
          headerText={t('renter.relationship.minorHeaderText')}
          continueButtonOnClick={onContinueClickHandler}
          addAnotherButtonOnClick={() => navigate(renterRoutes.generateRenterMinors(applicationId))}
        />
      </div>
    </RenterContainer>
  );
};

export default RelationMinorConfirmation;
