import { components } from 'react-select';
import classNames from 'classnames';
import { t } from 'i18next';

import { ReactComponent as CheckIcon } from 'assets/svgs/CheckIconLightGrey.svg';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';

import styles from './ActionDropDown.module.scss';

type ActionDropDownProps = {
  status: string | undefined;
  dropDownOptions: { value: string; label: string }[];
  handleOptionChange: (option: any) => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ActionDropDown = ({ status, dropDownOptions, handleOptionChange }: ActionDropDownProps): JSX.Element => {
  const CustomOption = (props: any): JSX.Element => {
    const { data, label } = props;

    if (data.isDummy) {
      return (
        <>
          <div className={styles.DropdownOptionDivider}></div>
          <div className={styles.DropdownDummyOption}>{label}</div>
        </>
      );
    }

    if ((label as string).toLowerCase() === status?.toLowerCase()) {
      return (
        <div className={styles.OptionDisabledContainer}>
          <div className={classNames(styles.OptionDisabled, styles.DropdownOptionLabel)}>{label}</div>
          <CheckIcon className={styles.CheckIcon} />
        </div>
      );
    }

    return (
      <components.Option {...props}>
        <span className={classNames(styles.DropdownOptionLabel)}>{label}</span>
      </components.Option>
    );
  };

  return (
    <ReactSelect
      className={styles.ReactSelect}
      isSearchable={false}
      placeholder={t('application.actions')}
      options={dropDownOptions}
      value={null}
      customOption={CustomOption}
      onChange={handleOptionChange}
    />
  );
};

export default ActionDropDown;
