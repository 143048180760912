import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { renterApplication } from 'redux/selectors/renterSelector';

import { ReactComponent as ContactInfo } from 'assets/svgs/ContactInfoLighter.svg';
import EditDetailsMinorModal from 'components/Renter/components/RenterHouseHold/RenterMinor/EditDetailsMinorModal';
import styles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import Title from 'components/Renter/IdentityVerification/RenterDetails/shared/Title/Title';
import { HouseholdDetailsKeys } from 'constants/renterConstants';
import { closePopOverOnClick, scrollToViewOfGivenId } from 'helpers/agentHelper';
import { pluralize } from 'helpers/user';
import { renterRoutes } from 'shared/routes';
import { NextRouteProp } from 'shared/types/renterTypes';

import DescriptionCard from './DescriptionCard/DescriptionCard';
import MinorsTooltip from './MinorsTooltip/MinorsTooltip';

const Minors = ({ next }: NextRouteProp): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customIndex, setCustomIndex] = useState(0);
  const [minorId, setMinorId] = useState(0);
  const applications = useSelector(renterApplication);
  const { minors } = applications.find((app) => app.id === Number(currentApplicationId)) || {};
  const minorsCount = minors?.length ?? 0;
  const title = pluralize(minorsCount, t('renter.paymentConfirmation.houseHoldDetails.minor'));
  const onHideHandler = useCallback((): void => setIsModalVisible(false), []);
  const handleAddInfo = (): void => {
    navigate(
      renterRoutes.generateRenterMinors(
        currentApplicationId,
        next ?? renterRoutes.generateRenterPaymentVerification(currentApplicationId),
        HouseholdDetailsKeys.MINORS
      )
    );
  };
  const handleEditInfo = (currentIndex: number, selectedId?: number): void => {
    closePopOverOnClick();
    setCustomIndex(currentIndex);
    setMinorId(selectedId ?? 0);
    setIsModalVisible(true);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sectionId = queryParams.get('section');

    minorsCount && scrollToViewOfGivenId(sectionId);
  }, [location, minorsCount]);

  if (!minors) {
    return (
      <div className={styles.titleMargin}>
        <Title
          mainTitle={title}
          iconElement={<ContactInfo />}
          description={t('renter.paymentConfirmation.houseHoldDetails.minors.description')}
          shouldShowOptions
          optionElement={<MinorsTooltip handleEditInfo={handleEditInfo} handleAddInfo={handleAddInfo} />}
        />
      </div>
    );
  }

  return (
    <div className={styles.titleMargin} id="minors">
      <Title
        mainTitle={title}
        iconElement={<ContactInfo />}
        shouldShowOptions
        optionElement={<MinorsTooltip handleEditInfo={handleEditInfo} handleAddInfo={handleAddInfo} />}
      />
      <EditDetailsMinorModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onHideHandler={onHideHandler}
        id={minorId}
        heading={`${t('renter.paymentConfirmation.houseHoldDetails.editMinor')} ${customIndex}`}
      />

      {minors.map((minor, index) => (
        <DescriptionCard minor={minor} key={`minor-${index}`} customKey={index} />
      ))}
    </div>
  );
};

export default Minors;
