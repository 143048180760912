import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
import { isSaving } from 'redux/selectors/renterSelector';
import { updateRenterAddressInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as CheckIcon } from 'assets/svgs/CheckIconGrey.svg';
import { ReactComponent as GreyCrossIcon } from 'assets/svgs/GreyCrossIcon.svg';
import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import AddressBox from 'components/shared/AddressBox/AddressBox';
import RCButton from 'components/shared/Button/Button';
import { addressClassificationOptions, OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY } from 'constants/renterConstants';
import { getAddressToApiFormat } from 'helpers/address';
import { Notification } from 'shared/Notification/Notification';
import { AddressInformation, AddressInitialValuesProps, RenterCustomAddressType } from 'shared/types/renterTypes';

import AddressDiscardModal from '../AddressDiscardModal/AddressDiscardModal';
import RenterModal from '../RenterModal/RenterModal';
import { VerifierEditDetailsForm } from '../VerifierEditDetailsForm/VerifierEditDetailsForm';

import styles from './LivingSituationEditModal.module.scss';

interface IAddressEditModalProps {
  address: AddressInformation;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<React.SetStateAction<boolean>>;
}

export const LivingSituationEditModal = ({
  isModalOpen,
  address,
  setIsModalOpen,
}: IAddressEditModalProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { values, setFieldValue, errors, setTouched, touched } = useFormikContext<
    AddressInitialValuesProps | RenterCustomAddressType
  >();
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const isSavingState = useSelector(isSaving);
  const touchedResetRef = useRef(false);
  const [isFormSet, setIsFormSet] = useState(false);
  const isRenting =
    address.isRented || !!address.fullName || !!address.contactRent || !!address.emailAddress || !!address.phoneNumber;
  const resetValuesToBefore = useCallback(() => {
    setFieldValue('fullName', address.fullName);
    setFieldValue('phoneNumber', address.phoneNumber);
    setFieldValue('emailAddress', address.emailAddress);
    setFieldValue('classification', address.classification);
    setFieldValue('description', address.description);
    setFieldValue('reasonForMoving', address.reasonForMoving);
  }, [
    address.classification,
    address.description,
    address.emailAddress,
    address.fullName,
    address.phoneNumber,
    address.reasonForMoving,
    setFieldValue,
  ]);
  const onIAmSureClickHandler = useCallback(() => {
    resetValuesToBefore();
    setIsDiscardModalOpen(false);
    setIsModalOpen(false);
  }, [resetValuesToBefore, setIsModalOpen]);
  const setAddressValues = useCallback(
    ({ fullName, phoneNumber, emailAddress, classification, description, reasonForMoving }: AddressInformation) => {
      setFieldValue('renterAddress', {
        ...values.renterAddress,
        reasonForMoving: reasonForMoving,
        description: description,
        classification: classification,
        fullName: fullName,
        phoneNumber: phoneNumber,
        emailAddress: emailAddress,
      });
    },
    [setFieldValue, values.renterAddress]
  );
  const isDirty = useMemo(
    (): boolean =>
      values.reasonForMoving !== address.reasonForMoving ||
      values.description !== address.description ||
      values.classification !== address.classification ||
      values.fullName !== address.fullName ||
      values.phoneNumber !== address.phoneNumber ||
      values.emailAddress !== address.emailAddress,
    [
      address.classification,
      address.description,
      address.emailAddress,
      address.fullName,
      address.phoneNumber,
      address.reasonForMoving,
      values.classification,
      values.description,
      values.emailAddress,
      values.fullName,
      values.phoneNumber,
      values.reasonForMoving,
    ]
  );
  const isButtonDisabled = useMemo(
    (): boolean =>
      !isDirty ||
      isSavingState ||
      (!!errors.classification?.length && !!address.classification) ||
      (!!errors.description?.length &&
        values.classification === addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label) ||
      (!!errors.emailAddress?.length && !!address.emailAddress) ||
      (!!errors.fullName?.length && !!address.fullName) ||
      (!!errors.phoneNumber?.length && !!address.phoneNumber) ||
      (!!errors.reasonForMoving?.length && !!address.reasonForMoving),
    [
      address.classification,
      address.emailAddress,
      address.fullName,
      address.phoneNumber,
      address.reasonForMoving,
      errors.classification?.length,
      errors.description?.length,
      errors.emailAddress?.length,
      errors.fullName?.length,
      errors.phoneNumber?.length,
      errors.reasonForMoving?.length,
      isDirty,
      isSavingState,
      values.classification,
    ]
  );
  const onClickHandler = useCallback(() => {
    const id = address.id;

    if (id) {
      dispatch(
        updateRenterAddressInformation({
          id: id,
          address: {
            ...getAddressToApiFormat({
              ...address,
              fullName: values.fullName,
              phoneNumber: values.phoneNumber,
              emailAddress: values.emailAddress,
              classification: values.classification,
              description: values.description,
              reasonForMoving: values.reasonForMoving,
            }),
          },
        })
      )
        .unwrap()
        .then((response) => {
          setAddressValues(response.addresses.find((obj: AddressInformation) => obj.id === id));
          setIsModalOpen(false);
        })
        .catch((error) => {
          Notification({ message: error });
        });
    } else {
      setFieldValue('renterAddress', {
        ...values.renterAddress,
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        emailAddress: values.emailAddress,
        classification: values.classification,
        description: values.description,
        reasonForMoving: values.reasonForMoving,
      });
      setIsModalOpen(false);
    }

    setTouched({ ...touched, streetAddress1: false, city: false, state: false, zipCode: false, apartment: false });
  }, [address, dispatch, setAddressValues, setFieldValue, setIsModalOpen, setTouched, touched, values]);
  const allFieldsAreFilled = useMemo(
    (): boolean =>
      (!!values.reasonForMoving || !address.reasonForMoving) &&
      (!!values.classification || !address.classification) &&
      (!!values.fullName || !address.fullName) &&
      (!!values.phoneNumber || !address.phoneNumber) &&
      (!!values.emailAddress || !address.emailAddress) &&
      (!!values.description ||
        !address.classification ||
        (!!values.classification &&
          values.classification !== addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label)),
    [
      address.classification,
      address.emailAddress,
      address.fullName,
      address.phoneNumber,
      address.reasonForMoving,
      values.classification,
      values.description,
      values.emailAddress,
      values.fullName,
      values.phoneNumber,
      values.reasonForMoving,
    ]
  );
  const getHeadings = useMemo((): string[] => {
    if (isRenting) {
      return [t('address.verifierEditModal.makeChangesToThe'), t('renter.OwnerInformation.ownerManager')];
    }

    return [t('renter.AddressEditModal.makeChangesTo'), t('address.livingSituation.heading.')];
  }, [isRenting]);
  const onHideHandler = useCallback(() => {
    if (isDirty) {
      setIsDiscardModalOpen(true);
    } else {
      setIsModalOpen(false);
    }

    setTouched({ ...touched, streetAddress1: false, city: false, state: false, zipCode: false, apartment: false });
  }, [isDirty, setIsModalOpen, setTouched, touched]);

  //Resetting touched at the start
  useEffect(() => {
    if (!touchedResetRef.current) {
      setTouched({
        ...touched,
        classification: false,
        description: false,
        reasonForMoving: false,
        fullName: false,
        emailAddress: false,
        phoneNumber: false,
      });
      touchedResetRef.current = true;
    }
  }, [setTouched, touched]);
  //Setting form fields with initial data from the address
  useEffect(() => {
    if (!isFormSet) {
      setFieldValue('fullName', address.fullName);
      setFieldValue('phoneNumber', address.phoneNumber);
      setFieldValue('emailAddress', address.emailAddress);
      setFieldValue('classification', address.classification);
      setFieldValue('description', address.description);
      setFieldValue('reasonForMoving', address.reasonForMoving);
      setIsFormSet(true);
    }
  }, [
    address.classification,
    address.description,
    address.emailAddress,
    address.fullName,
    address.phoneNumber,
    address.reasonForMoving,
    isFormSet,
    setAddressValues,
    setFieldValue,
  ]);

  return (
    <>
      {!isDiscardModalOpen && (
        <RenterModal show={isModalOpen} onHide={onHideHandler} customCrossIcon={GreyCrossIcon}>
          <div className={styles.contentClassName}>
            <div className={styles.heading}>{t('renter.PhotoIdInformation.editDetails')}</div>
            <h2 className={styles.renterH2}>
              {getHeadings[0]}
              <span className={styles.renterH2Color}>{getHeadings[1]}</span>
            </h2>

            <AddressBox address={address} doShowMoreData shouldShowEditIcon={false} />

            <VerifierEditDetailsForm
              ownerName="fullName"
              emailName="emailAddress"
              phoneName="phoneNumber"
              reasonForMovingName="reasonForMoving"
              descriptionName="description"
              classificationName="classification"
              hasClassification={!!address.classification}
              hasReasonForMoving={!!address.reasonForMoving}
              isRenting={isRenting}
            />
            <div className={styles.AddressInformation}>
              {isRenting && (
                <div className={styles.InformationBox}>
                  <InformationIcon className={styles.InformationIcon} />
                  <span className={styles.InformationText}>{t('address.livingSituation.informationTag')}</span>
                </div>
              )}
              {isRenting && (
                <div className={styles.PermissionCheck}>
                  <CheckIcon />
                  <span className={styles.PermissionText}>{t('address.livingSituation.permissionTag')}</span>
                </div>
              )}
            </div>
            {allFieldsAreFilled && (
              <div className={styles.renterButtonContainer}>
                <RCButton
                  onClick={onClickHandler}
                  className={styles.renterButton}
                  variant="outline"
                  disabled={isButtonDisabled}
                >
                  {t('renter.noCreditHistory.SaveAndContinue')}
                </RCButton>
              </div>
            )}
          </div>
        </RenterModal>
      )}

      {isDiscardModalOpen && (
        <AddressDiscardModal
          isModalOpen={isDiscardModalOpen}
          setIsModalOpen={setIsDiscardModalOpen}
          onBackClick={() => {
            setIsDiscardModalOpen(false);
          }}
          onImSureClick={onIAmSureClickHandler}
        />
      )}
    </>
  );
};
