import { useTranslation } from 'react-i18next';

import { formatAmountToUsdString } from 'helpers/helper';
import { isEven } from 'helpers/reportsHelper';
import { IncomeReportItemProps } from 'shared/types/reportTypes';

import { ReportRow } from '../ReportItemRow/ReportRow';

import styles from './IncomeReportItem.module.scss';

export const IncomeReportItem = ({ month, transactions }: IncomeReportItemProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.TitleHeadings}>
        <div className={styles.Month}>{month}</div>
        <div className={styles.Description}> {t('agent.properties.property.description')}</div>
        <div className={styles.Amount}>{t('agent.creditReport.amount')}</div>
      </div>
      <div className={styles.Transactions}>
        {transactions.map((singleTransaction, index) => (
          <ReportRow
            key={`transaction-${singleTransaction}-${index}`}
            transactionDate={singleTransaction.transactionDate}
            description={singleTransaction.description}
            amount={singleTransaction.amount}
            isGreyBackground={isEven(index)}
          />
        ))}
      </div>
      <div className={styles.TotalContainer}>
        <div className={styles.TotalTitle}>{t('agent.properties.total')}</div>
        <div className={styles.TotalAmount}>
          {formatAmountToUsdString(
            Number(transactions.reduce((accumulator, transaction) => accumulator + transaction.amount, 0).toFixed(2))
          )}
        </div>
      </div>
    </div>
  );
};
