import { useCallback } from 'react';
import { ModalBody } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setServerError } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';

import RenterModal from 'components/Renter/components/RenterModal/RenterModal';
import { ServerErrorCard } from 'components/shared/ServerErrors/ServerErrorCard/ServerErrorCard';
import { errorStatusDetails } from 'shared/constants';
import { ModalBasicProps } from 'shared/types/renterTypes';

import styles from './ErrorModal.module.scss';

export const ErrorModal = ({ setIsModalVisible, serverError, isModalVisible }: ModalBasicProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t } = useTranslation();
  const { mainHeading, subHeading, description, ErrorIcon } = errorStatusDetails[serverError];
  const closeModal = useCallback(() => {
    dispatch(setServerError(0));
    setIsModalVisible(false);
  }, [dispatch, setIsModalVisible]);

  return (
    <div className={styles.container}>
      <RenterModal
        show={isModalVisible}
        onHide={closeModal}
        crossButtonClassName={styles.closeButton}
        backDropClassName={styles.modalPriorityDiv}
        className={styles.modalPriorityDiv}
      >
        <ModalBody>
          <ServerErrorCard
            mainHeading={t(mainHeading)}
            subHeading={t(subHeading)}
            description={description ? t(description) : undefined}
            errorIcon={<ErrorIcon />}
            onButtonClick={closeModal}
          />
        </ModalBody>
      </RenterModal>
    </div>
  );
};
