import { t } from 'i18next';

import { ReactComponent as ErrorIcon } from 'assets/svgs/InfoErrorDanger.svg';
import RenterModal from 'components/Renter/components/RenterModal/RenterModal';
import Button from 'components/shared/Button/Button';
import Spinner from 'components/shared/Spinner/Spinner';
import { RenterLogoutModal } from 'shared/types/renterInformation';

import styles from './RenterLogOutModal.module.scss';

const RenterLogOutModal = ({ shouldShowModal, onCloseModal, onLogout, isLoading }: RenterLogoutModal): JSX.Element => (
  <RenterModal show={shouldShowModal} onHide={onCloseModal}>
    <div className={styles.container}>
      <div className={styles.title}>{t('application.logoutSure')}</div>
      <div className={styles.iconContainer}>
        <ErrorIcon />
      </div>
      <div className={styles.buttonContainer}>
        <Button variant="outline" className={styles.renterPhotoInformationButton} onClick={onCloseModal}>
          {t('application.cancel')}
        </Button>
        <Button onClick={onLogout} disabled={isLoading}>
          <div className={styles.buttonWihIcon}>
            {t('application.logout')} {isLoading && <Spinner />}
          </div>
        </Button>
      </div>
    </div>
  </RenterModal>
);

export default RenterLogOutModal;
