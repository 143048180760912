/* eslint-disable react/jsx-no-useless-fragment */
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { amenitiesFeaturePropertyList, amenitiesFeatureUnitList } from 'redux/selectors/propertySelector';
import {
  addProperty,
  getPropertyExternalAttributes,
  updateAmenitiesFeature,
  updatePropertyDetails,
} from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as AddIcon } from 'assets/svgs/AddIcon.svg';
import { ReactComponent as DropDownFillArrow } from 'assets/svgs/ArrowDownWithBackgroundColor.svg';
import AddCustomFeature from 'components/Agent/Properties/AddProperty/Amenities/AddCustomFeature';
import AmenitiesFeature from 'components/Agent/Properties/AddProperty/Amenities/AmenitiesFeature';
import CustomChip from 'components/Agent/Properties/AddProperty/Amenities/FeatureTag';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import { allowedPetsValues, laundaryInfo, parkingInfo } from 'constants/propertyConstants';
import { PropertyAmenitiesProps, Tag, UpdatePropertyType } from 'shared/types/propertyType';

import { PropertyEditRow } from './PropertyEditRow';
import { PropertySaveRow } from './PropertySaveRow';

import styles from '../PropertyDetails.module.scss';

type Params = {
  propertyDetails: UpdatePropertyType;
  isEditableAmeneties: boolean;
};

export const PropertyAmenetiesForm = ({ propertyDetails, isEditableAmeneties }: Params): JSX.Element => {
  const { t } = useTranslation();
  const { propertyId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isFeatureClicked, setFeatureClicked] = useState(false);
  const [isCustomFeatureClicked, setIsCustomFeatureClicked] = useState(false);
  const [isEditable, setIsEditable] = useState(isEditableAmeneties);
  const customUnitFeature = useSelector(amenitiesFeatureUnitList);
  const customPropertyFeature = useSelector(amenitiesFeaturePropertyList);
  const activeOrganization = useSelector(selectedOrganization);
  const { propertyLaundryAmenity, propertyParkingOption, allowedPets, unitAmenities, propertyAmenities } =
    propertyDetails;
  const INITIAL_VALUES = {
    allowedPets,
    propertyParkingOption,
    propertyLaundryAmenity,
    unitAmenities,
    propertyAmenities,
  };
  const handleNoPetsChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, obj: string[]) => void,
    values: PropertyAmenitiesProps
  ): void => {
    const { checked, value } = event.target;
    let updatedAllowedPets: string[] = [];

    if (value === 'No pets') {
      updatedAllowedPets = checked ? ['No pets'] : [];
    } else {
      if (checked) {
        updatedAllowedPets = values.allowedPets
          ? values.allowedPets.filter((pet) => pet !== 'No pets').concat(value)
          : [value];
      } else {
        updatedAllowedPets = values.allowedPets ? values.allowedPets.filter((pet) => pet !== value) : [];
      }
    }

    setFieldValue('allowedPets', updatedAllowedPets);
  };
  const toggleShowFeature = (isShowFeature: boolean): void => {
    setFeatureClicked(isShowFeature);
  };
  const toggleCustomShowFeature = (): void => {
    setIsCustomFeatureClicked(!isCustomFeatureClicked);
  };
  const fetchAndUpdateAmenities = useCallback(() => {
    dispatch(getPropertyExternalAttributes())
      .unwrap()
      .then((attributes) => {
        const amenities = ([] as Tag[]).concat(
          attributes.unitAmenities.map((a: string) => ({
            name: a,
            type: 'unit',
            isSelected: unitAmenities?.includes(a),
          })),
          attributes.propertyAmenities.map((a: string) => ({
            name: a,
            type: 'property',
            isSelected: propertyAmenities?.includes(a),
          })),
          (unitAmenities || [])
            .filter((a) => !attributes.unitAmenities.includes(a))
            .map((a: string) => ({
              name: a,
              type: 'unit',
              isSelected: true,
              isAnimation: false,
              tempSelected: false,
              isCustom: true,
            })),
          (propertyAmenities || [])
            .filter((a) => !attributes.propertyAmenities.includes(a))
            .map((a: string) => ({
              name: a,
              type: 'property',
              isSelected: true,
              isAnimation: false,
              tempSelected: false,
              isCustom: true,
            }))
        );

        dispatch(
          updateAmenitiesFeature(
            amenities.map((el) => ({
              ...el,
              isAnimation: false,
              tempSelected: false,
            }))
          )
        );
      });
  }, [dispatch, propertyAmenities, unitAmenities]);

  useEffect(() => {
    fetchAndUpdateAmenities();
  }, [fetchAndUpdateAmenities]);

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        dispatch(
          addProperty({
            values: {
              ...values,
              propertyId: Number(propertyId),
              unitAmenities: values?.unitAmenities ?? [],
              propertyAmenities: values?.propertyAmenities ?? [],
            },
            organizationId: activeOrganization.id,
          })
        )
          .unwrap()
          .then((updatedPropertyDetails) => {
            dispatch(updatePropertyDetails(updatedPropertyDetails));
            setIsEditable(false);
            setIsCustomFeatureClicked(false);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ values, isSubmitting, submitForm, resetForm, setFieldValue }) => (
        <Form>
          <PropertyEditRow
            title={t('agent.properties.property.ameneties')}
            handleClick={() => setIsEditable(true)}
            disabled={isEditable}
          />
          <div className={styles.unitSpecs}>
            <Row>
              <Col lg={3} md={4} sm={6} className={styles.CustomTextFieldWithLabel}>
                <FormikSelectField
                  name="propertyLaundryAmenity"
                  options={laundaryInfo}
                  placeholder="Select"
                  dropDownIcon={isEditable ? <DropDownFillArrow /> : <></>}
                  dropDownIconContainer={styles.dropDownFillArrow}
                  hasFloatingLabel={true}
                  floatingLabel="Laundary"
                  isCustomOptions={true}
                  isDisabled={!isEditable}
                />
              </Col>
              <Col lg={3} md={4} sm={6} className={styles.CustomTextFieldWithLabel}>
                <FormikSelectField
                  name="propertyParkingOption"
                  options={parkingInfo}
                  placeholder="Select"
                  dropDownIcon={isEditable ? <DropDownFillArrow /> : <></>}
                  dropDownIconContainer={styles.dropDownFillArrow}
                  hasFloatingLabel={true}
                  floatingLabel="Parking"
                  isCustomOptions={true}
                  isDisabled={!isEditable}
                />
              </Col>
              <Col lg={6} md={12} sm={12} className={styles.CustomTextFieldWithLabel}>
                <Row className={styles.PetsRow}>
                  <span className={styles.PetsLabel}>{t('agent.properties.property.ameneties.pets')}</span>
                  {allowedPetsValues.map((pet, index) => (
                    <Col
                      lg={index === 0 ? 12 : 4}
                      md={3}
                      sm={6}
                      key={`pets-${index}`}
                      className={index === 0 ? styles.PetsColLg : styles.PetsCol}
                    >
                      <Field
                        as={Checkbox}
                        className={styles.checkboxLabel}
                        name="allowedPets"
                        label={pet.label}
                        value={pet.value}
                        disabled={!isEditable}
                        checked={values.allowedPets?.includes(pet.value)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleNoPetsChecked(e, setFieldValue, values)
                        }
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Row className={styles.AdditionalAmenetiesContainer}>
                <div className={styles.AdditionalAmeneties}>{t('agent.properties.property.ameneties.additional')}</div>
                <div className={styles.AdditionalAmenetiesDivider} />
              </Row>
            </Row>
          </div>
          <div className={styles.AmenetiesDiv}>
            <Row>
              <div>
                <p className={styles.AmenitiesParagraph}>{t('agent.addProperty.featuresLabel')}</p>
                <AmenitiesFeature
                  handleBlur={() => console.log('Blurred')}
                  toggleShowFeature={toggleShowFeature}
                  isFeatureClicked={isFeatureClicked}
                  isEditable={isEditable}
                />
              </div>
            </Row>
            <Row className={styles.AmenitiesPadding}>
              <Col>
                {isEditable && (
                  <Row>
                    <Col>
                      <div
                        aria-hidden="true"
                        onClick={toggleCustomShowFeature}
                        className={styles.CustomAmenitiesButton}
                      >
                        <span>
                          <AddIcon className={styles.AddIconClass} />
                        </span>
                        <span className={styles.AddCustomText}>Add Custom</span>
                      </div>
                    </Col>
                  </Row>
                )}
                {isCustomFeatureClicked && (
                  <Row>
                    <Col>
                      <AddCustomFeature
                        handleBlur={() => console.log('Blurred')}
                        onClick={toggleCustomShowFeature}
                        name="customFeatureFiled"
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <CustomChip
                      handleBlur={() => console.log('Blurred')}
                      data={customUnitFeature}
                      isPropertyEdit={isEditable}
                      title="Unit"
                      type="unit"
                    />
                    <CustomChip
                      handleBlur={() => console.log('Blurred')}
                      data={customPropertyFeature}
                      isPropertyEdit={isEditable}
                      title="Property"
                      type="property"
                    />
                  </Col>
                  {!isEditable && isEmpty(unitAmenities) && isEmpty(propertyAmenities) && (
                    <div className={styles.NoAmenities}>{t('agent.properties.noAmenities')}</div>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
          {isEditable && (
            <PropertySaveRow
              handleSaveClick={submitForm}
              handleCancelClick={() => {
                resetForm();
                fetchAndUpdateAmenities();
                setIsEditable(false);
                setIsCustomFeatureClicked(false);
              }}
              isSubmitting={isSubmitting}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
