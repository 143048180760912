import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { applicantRenterRole, isLoading, renterApplication } from 'redux/selectors/renterSelector';
import { GuarantorFormSchema } from 'schema/renterSchema';

import { NavigationKeys, RenterGuarantorSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { StepRouteType } from 'helpers/address';
import { findApplication } from 'helpers/agentHelper';
import { tabTitle } from 'helpers/helper';
import { renterRoutes } from 'shared/routes';
import { GuarantorInitialValues } from 'shared/types/renterTypes';

import GuarantorInformation from './GuarantorInformation/GuarantorInformation';
import GuarantorInformationForm from './GuarantorInformationForm/GuarantorInformationForm';
import GuarantorSummary from './GuarantorSummary/GuarantorSummary';

const stepRoute: StepRouteType = {
  check: <GuarantorInformation />,
  form: <GuarantorInformationForm />,
  summary: <GuarantorSummary />,
};
const Guarantor = (): JSX.Element => {
  tabTitle('Your Application | Household Details');
  const [formValues, setFormValues] = useState(GuarantorInitialValues);
  const [searchParams] = useSearchParams();
  const { applicationId } = useParams();
  const isLoadingInformation = useSelector(isLoading);
  const stepName = searchParams.get('step') || RenterGuarantorSteps.CHECK;
  const renterApplicationsInformation = useSelector(renterApplication);
  const navigate = useNavigate();
  const renterRole = useSelector(applicantRenterRole);
  const navigateNext = searchParams.get(NavigationKeys.NEXT);
  const currentApplication = useMemo(
    () => findApplication(renterApplicationsInformation, Number(applicationId)),
    [applicationId, renterApplicationsInformation]
  );

  useEffect(() => {
    if (
      renterRestrictions[renterRole].guarantor ||
      (currentApplication?.hasNoGuarantor && isEmpty(currentApplication?.guarantor) && !navigateNext)
    ) {
      navigate(renterRoutes.renterRelation.replace(':applicationId', `${applicationId}`));
    }
  }, [applicationId, currentApplication, navigate, navigateNext, renterRole]);

  useEffect(() => {
    if (currentApplication?.guarantor) {
      setFormValues({
        relation: {
          ...currentApplication.guarantor,
          phoneNumber: currentApplication?.guarantor?.phoneNumber.slice(1),
        },
        relationship: currentApplication?.guarantor?.relationship || '',
        phoneNumber: currentApplication?.guarantor?.phoneNumber?.slice(1) || '',
        emailAddress: currentApplication?.guarantor?.emailAddress || '',
      });
    }
  }, [applicationId, currentApplication, renterApplicationsInformation]);

  if (isLoadingInformation) {
    return <div />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      validationSchema={GuarantorFormSchema}
      onSubmit={(values) => {
        console.log('form', values);
      }}
    >
      {stepRoute[currentApplication?.guarantor ? RenterGuarantorSteps.SUMMARY : stepName]}
    </Formik>
  );
};

export default Guarantor;
