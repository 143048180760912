import { useTranslation } from 'react-i18next';

import { ReactComponent as CircleIcon } from 'assets/svgs/Circle.svg';

import styles from './StatusPills.module.scss';

export interface StatusTypeProps {
  value: string;
  text: string;
}

export interface StatusPillsProps {
  status: string;
}

export enum StatusType {
  PENDING = 'Pending',
  COMPLETE = 'Complete',
}

export const StatusTypeDetails: { [key: string]: StatusTypeProps } = {
  //PendingStatus
  [StatusType.PENDING]: { value: 'Pending', text: 'rental.historyInformation.pending' },
  //CompleteStatus
  [StatusType.COMPLETE]: { value: 'Complete', text: 'rental.historyInformation.complete' },
};

export const StatusPills = ({ status }: StatusPillsProps): JSX.Element => {
  const { t } = useTranslation();
  const statusDetails = StatusTypeDetails[status];

  return (
    <span className={styles.Container}>
      <CircleIcon className={styles[statusDetails.value]} /> <span>{t(statusDetails.text)}</span>
    </span>
  );
};
