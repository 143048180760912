import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Field, FieldProps, Form, Formik, FormikErrors } from 'formik';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { isAgentLoading, selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { agentOrganizationInviteDetails } from 'redux/selectors/authSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { updateAgentDetails } from 'redux/slices/agentSlice';
import {
  getAgentOrganizationInvitesDetails,
  getAgentProfileInformation,
  setSecurityQuestions,
} from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';
import { AgentFormSchema } from 'schema/agentSchema';

import { ReactComponent as Security } from 'assets/svgs/security.svg';
import { ProfileInvitedScreen } from 'components/Agent/Profile/components/ProfileInvitedScreen/ProfileInvitedScreen';
import { ProfileScreen } from 'components/Agent/Profile/ProfileScreen';
import AgentSSNCheckerInput from 'components/shared/AgentSSNCheckerInput/AgentSSNCheckerInput';
import Button from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Card from 'components/shared/CustomCard/Card';
import { OTPModal } from 'components/shared/OTPModal/OTPModal';
import PhoneNoTypeSelectField from 'components/shared/PhoneNoTypeSelectField/PhoneNoTypeSelectField';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import { PlacesAutocompleteComponent } from 'components/shared/PlacesAutoCompleteComponent/PlacesAutoCompleteComponent';
import Spinner from 'components/shared/Spinner/Spinner';
import StateSelectField from 'components/shared/StateSelectField/StateSelectField';
import FormikField from 'components/shared/TextField/FormikTextField';
import Typography from 'components/shared/Typography/Typography';
import { AgentProfileFormSteps, PhoneNoTypes } from 'constants/agentConstants';
import { agentResendOtpRequest, convertSecurityQuestions, isPMCUser, submitAgentOtpRequest } from 'helpers/agentHelper';
import { useDeviceInfoCollector } from 'hooks/useDeviceInfoCollector';
import { US_STATES } from 'shared/data/usStates';
import { Notification } from 'shared/Notification/Notification';
import { routes } from 'shared/routes';
import { AgentUserRoles } from 'shared/types/agentInvite';
import { AgentProfileInformationProps } from 'shared/types/agentTypes';

import { ICountryProps } from './types';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from './AgentProfileForm.module.scss';
export interface AgentProps {
  value: string;
  country: ICountryProps;
  setErrors: (errors: FormikErrors<AgentProfileInformationProps>) => void;
  errors: FormikErrors<AgentProfileInformationProps>;
}

export interface Props {
  initialValues: AgentProfileInformationProps;
}
const AgentProfileForm = ({ initialValues }: Props): JSX.Element => {
  const [searchParams] = useSearchParams();
  const stepName = searchParams.get('step') || AgentProfileFormSteps.UN_INVITED;
  const { jscInputRef, getDeviceInfo } = useDeviceInfoCollector();
  const [isError, setIsError] = useState<boolean>(false);
  const [isAddressError, setIsAddressError] = useState<boolean>(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const isLoadingEnabled = useSelector(isAgentLoading);
  const { id: currentOrganizationId, roleName, housingProviderCategory } = useSelector(selectedOrganization);
  const agentValues = useSelector(selectAgentProfileInformation);
  const { agentFirstName, agentLastName, organizationName, organizationAddress } =
    useSelector(agentOrganizationInviteDetails);
  const navigate = useNavigate();
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [initialOtpCode, setInitialOtpCode] = useState('');
  const [hasOtpExpired, setHasOtpExpired] = useState(false);
  const [profileType, setProfileType] = useState('');
  const [isOtpRequestLaoding, setIsOtpRequestLoading] = useState(false);
  const isApiRequested = useRef(false);
  const isHousingCategorySet = useRef(false);
  const onSubmitClickHandler = useCallback(
    (code: string) => {
      submitAgentOtpRequest({
        code: code,
        setHasOtpExpired: setHasOtpExpired,
        setIsOtpRequestLoading: setIsOtpRequestLoading,
        dispatch: dispatch,
        navigate: navigate,
        setInitialOtpCode: setInitialOtpCode,
      });
    },
    [dispatch, navigate]
  );
  const onResendOtpClickHandler = (): void => {
    agentResendOtpRequest({ setInitialOtpCode: setInitialOtpCode, setHasOtpExpired: setHasOtpExpired });
  };

  useEffect(() => {
    if (!isHousingCategorySet.current && housingProviderCategory) {
      setProfileType(housingProviderCategory);
      isHousingCategorySet.current = true;
    }
  }, [housingProviderCategory]);
  const getFormComponent = useMemo((): JSX.Element => {
    if (stepName === AgentProfileFormSteps.INVITED) {
      return (
        <ProfileInvitedScreen
          invitedBy={`${agentFirstName ?? ''} ${agentLastName ?? ''}`}
          invitedOrganization={organizationName}
          organizationAddress={organizationAddress ?? ''}
        />
      );
    }

    if (!profileType && !agentValues?.lastOrganizationVisitedId) {
      return <ProfileScreen setProfileType={setProfileType} />;
    }

    if (!profileType) {
      return <div />;
    }

    return (
      <Card
        parentClassName={styles.AgentProfileFormContainer}
        xl={8}
        lg={10}
        md={12}
        rowClassName={styles.CardRowContainer}
      >
        <div className={styles.container}>
          <Formik
            initialValues={
              !!agentValues?.agentId
                ? { ...agentValues, housingProviderCategory: profileType ?? null }
                : { ...initialValues, housingProviderCategory: profileType ?? null }
            }
            validationSchema={AgentFormSchema}
            onSubmit={(values, { setErrors }) => {
              const deviceInfo = getDeviceInfo();

              if (!deviceInfo) {
                return;
              }

              const body: AgentProfileInformationProps = {
                ...values,
                license: values?.license || null,
                businessName: values?.businessName || null,
                socialSecurityNumber: !!values?.socialSecurityNumber
                  ? values?.socialSecurityNumber.replace(/\D/g, '')
                  : null,
                middleName: values?.middleName || null,
                suite: values?.suite || null,
                jscPayload: deviceInfo.jscPayload,
                hdimPayload: deviceInfo.hdimPayload,
                addressLineTwo: values?.addressLineTwo || null,
                businessSuite: values?.businessSuite || null,
              };

              dispatch(updateAgentDetails({ values: body, setErrors }))
                .unwrap()
                .then((res) => {
                  if (res.experianResponse?.payload?.userAuthenticated) {
                    dispatch(getAgentProfileInformation()).then(() => {
                      navigate(routes.applicants);
                    });

                    return;
                  }

                  if (res?.experianResponse?.payload?.success) {
                    const kbaQuestions = isPMCUser(values.housingProviderCategory || '')
                      ? res?.experianResponse.payload?.preciseIDServer?.KBA?.QuestionSet
                      : res?.experianResponse.payload;

                    if (!isEmpty(kbaQuestions) && isPMCUser(values.housingProviderCategory || '')) {
                      dispatch(setSecurityQuestions(kbaQuestions));
                      navigate(routes.agentSecurityQuestionnaire);
                    } else if (kbaQuestions?.kiqEnabled) {
                      dispatch(
                        setSecurityQuestions(
                          kbaQuestions.kba.questionSet?.[0]?.questionType
                            ? convertSecurityQuestions(kbaQuestions.kba.questionSet)
                            : kbaQuestions.kba.questionSet
                        )
                      );

                      navigate(routes.agentSecurityQuestionnaire);
                    } else if (kbaQuestions?.otpEnabled) {
                      setInitialOtpCode(
                        kbaQuestions?.crossCoreFullResponse?.clientResponsePayload?.decisionElements?.[0]
                          ?.decisions?.[0]?.value
                      );
                      setIsOtpModalOpen(true);
                    } else {
                      Notification({ message: res.experianResponse?.payload?.error?.Message });
                    }
                  } else {
                    Notification({ message: res.experianResponse?.payload?.error?.Message });
                  }
                })
                .catch((errorObject) => {
                  setErrors(errorObject);
                });
            }}
          >
            {({ isValid }) => (
              <Form>
                <input type="hidden" name="user_prefs2" id="user_prefs2" ref={jscInputRef} />
                <Typography variant={'h1'} className={styles.title}>
                  Profile
                </Typography>
                {isPMCUser(profileType) && (
                  <div className={styles.BusinessDetailsContainer}>
                    <div className={styles.BusinessDetails}>Business details</div>
                    <Row>
                      <FormikField
                        className="inputBackgroundColor"
                        name="businessName"
                        placeholder="Business Name"
                        label="Business Name"
                      />
                    </Row>
                    <Row>
                      <Col md={8} sm={12} className={styles.errorRelativenWithPlacesAutoComplete}>
                        <PlacesAutocompleteComponent
                          addressField="businessMailingAddress"
                          zipField="businessZipCode"
                          stateField="businessState"
                          cityField="businessCity"
                          setIsError={setIsAddressError}
                          shouldAcceptCustomAddress
                          placeholder="Type to search"
                          labelField="Mailing Address"
                          className="inputBackgroundColor"
                          shouldTrimAddress
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <FormikField className="inputBackgroundColor" name="businessSuite" label="Suite (Optional)" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8} sm={12}>
                        <FormikField
                          className="inputBackgroundColor"
                          name="businessCity"
                          type="text"
                          placeholder="City"
                          label="City"
                        />
                      </Col>
                      <Col md={2} sm={12} className={styles.stateSelectField}>
                        <span className={styles.textFieldLabel}>{t('state')}</span>
                        <StateSelectField
                          name="businessState"
                          backgroundColor="#f7fafa"
                          placeholder="Select"
                          statesArray={US_STATES}
                        />
                      </Col>
                      <Col md={2} sm={12}>
                        <FormikField
                          className="inputBackgroundColor"
                          name="businessZipCode"
                          type="number"
                          placeholder="Zip"
                          label="Zip"
                          onWheel={(event) => event.currentTarget.blur()}
                        />
                      </Col>
                    </Row>
                    {/* Phone Number */}
                    <Row>
                      <Col md={8} sm={12}>
                        <FormikPhoneNumber
                          name="businessPhoneNumber"
                          placeholder="(  )  -"
                          label="Phone Number"
                          isShowCountryCode={false}
                        />
                      </Col>
                      {/* Phone Type */}
                      <Col md={4} sm={12} className={styles.stateSelectField}>
                        <span className={styles.textFieldLabel}>{t('agent.profile.phoneType.text')}</span>
                        <PhoneNoTypeSelectField
                          name="businessPhoneType"
                          backgroundColor="#f7fafa"
                          placeholder={t('renter.realtionship.select')}
                          statesArray={PhoneNoTypes}
                          data-testId="buisnessPhoneTypeSelector"
                        />
                      </Col>
                    </Row>
                  </div>
                )}
                <div>
                  <div className={styles.PersonalDetails}>Personal details</div>
                  <div className={styles.partnershipContainer}>
                    <div className={styles.partnershipIcon}>
                      <Security />
                    </div>
                    <p className={styles.partnershipText}>{t('agent.profile.IR.introduction.text')}</p>
                  </div>
                </div>
                <div className={styles.LegalName}>Legal name of person</div>
                <Row>
                  <Col md={4} sm={12} p={0}>
                    <FormikField
                      className="inputBackgroundColor"
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                    />
                  </Col>
                  <Col md={4} sm={12} p={0}>
                    <FormikField
                      className="inputBackgroundColor"
                      name="middleName"
                      placeholder="Middle Name"
                      label="Middle Name (Optional)"
                    />
                  </Col>
                  <Col md={4} sm={12} p={0}>
                    <FormikField
                      className="inputBackgroundColor"
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                    />
                  </Col>
                </Row>
                {/* Business Name */}
                {!isPMCUser(profileType) && (
                  <Row>
                    <FormikField
                      className="inputBackgroundColor"
                      name="businessName"
                      placeholder="Business Name"
                      label="Business Name (Optional)"
                    />
                  </Row>
                )}

                {/* Mailing Address && suite */}
                <Row>
                  <Col md={8} sm={12} className={styles.errorRelativenWithPlacesAutoComplete}>
                    <PlacesAutocompleteComponent
                      addressField="mailingStreetAddress"
                      setIsError={setIsError}
                      shouldAcceptCustomAddress
                      placeholder="Type to search"
                      labelField="Home Address"
                      className="inputBackgroundColor"
                      shouldTrimAddress
                    />
                  </Col>
                  <Col md={4} sm={12}>
                    <FormikField
                      className="inputBackgroundColor"
                      name="addressLineTwo"
                      label="Address line 2 (Optional)"
                    />
                  </Col>
                </Row>
                {/* City && Zip && State */}
                <Row>
                  <Col md={8} sm={12}>
                    <FormikField
                      className="inputBackgroundColor"
                      name="city"
                      type="text"
                      placeholder="City"
                      label="City"
                    />
                  </Col>
                  <Col md={2} sm={12} className={styles.stateSelectField}>
                    <span className={styles.textFieldLabel}>{t('state')}</span>
                    <StateSelectField
                      name="state"
                      backgroundColor="#f7fafa"
                      placeholder="Select"
                      statesArray={US_STATES}
                    />
                  </Col>
                  <Col md={2} sm={12}>
                    <FormikField
                      className="inputBackgroundColor"
                      name="zipCode"
                      type="number"
                      placeholder="Zip"
                      label="Zip"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </Col>
                </Row>
                {/* Phone Number */}
                <Row>
                  <Col md={8} sm={12}>
                    <FormikPhoneNumber
                      name="phoneNumber"
                      placeholder="(  )  -"
                      label="Phone Number"
                      isShowCountryCode={false}
                    />
                  </Col>
                  {/* Phone Type */}
                  <Col md={4} sm={12} className={styles.stateSelectField}>
                    <span className={styles.textFieldLabel}>{t('agent.profile.phoneType.text')}</span>
                    <PhoneNoTypeSelectField
                      name="phoneType"
                      backgroundColor="#f7fafa"
                      placeholder={t('renter.realtionship.select')}
                      statesArray={PhoneNoTypes}
                      data-testId="PhoneTypeSelector"
                    />
                  </Col>
                  <Col sm={12} className={styles.phoneTypeSelectField}>
                    <AgentSSNCheckerInput />
                    <div className={styles.SsnInfo}>
                      {t(isPMCUser(profileType) ? 'agent.profile.ssnPmc.info' : 'agent.profile.ssnNonPmc.info')}
                    </div>
                  </Col>
                </Row>
                <Typography className={styles.statement}>{t('agent.profile.iAgreeWithRules.text')}</Typography>
                <Field name="isConsented">
                  {({ field }: FieldProps) => (
                    <Checkbox
                      className={styles.ConsentContainer}
                      label={<span>{t('agent.profile.iAgreeCheckBox')}</span>}
                      {...field}
                      checked={field.value}
                    />
                  )}
                </Field>
                <div className="d-flex justify-content-center">
                  <Button
                    disabled={isLoadingEnabled || !isValid || isError || isAddressError}
                    variant="primary"
                    type="submit"
                    className={styles.btnPrimary}
                  >
                    Next
                    {isLoadingEnabled && <Spinner />}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          {isOtpModalOpen && (
            <OTPModal
              isLoading={isOtpRequestLaoding}
              isExpired={hasOtpExpired}
              onSubmitHandler={onSubmitClickHandler}
              initialOtpValue={initialOtpCode}
              onResendOtpClickHandler={onResendOtpClickHandler}
            />
          )}
        </div>
      </Card>
    );
  }, [
    agentFirstName,
    agentLastName,
    agentValues,
    dispatch,
    getDeviceInfo,
    hasOtpExpired,
    initialOtpCode,
    initialValues,
    isAddressError,
    isError,
    isLoadingEnabled,
    isOtpModalOpen,
    isOtpRequestLaoding,
    jscInputRef,
    navigate,
    onSubmitClickHandler,
    organizationAddress,
    organizationName,
    profileType,
    stepName,
  ]);

  useEffect(() => {
    if (
      !isApiRequested.current &&
      agentValues.userId &&
      roleName !== AgentUserRoles.OWNER &&
      !agentFirstName &&
      currentOrganizationId &&
      stepName === AgentProfileFormSteps.INVITED
    ) {
      isApiRequested.current = true;
      dispatch(getAgentOrganizationInvitesDetails(currentOrganizationId));
    }
  }, [agentFirstName, agentValues.userId, currentOrganizationId, dispatch, roleName, stepName]);

  if (!agentValues.userId) {
    return <div />;
  }

  return <React.Fragment> {getFormComponent} </React.Fragment>;
};

export default AgentProfileForm;
