import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';

import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';
import CustomDropzone, { CustomPreviewFile } from 'components/shared/DropZone/CustomDropzone/CustomDropzone';
import ImageProgressBox from 'components/shared/ImageProgressBox/ImageProgressBox';
import { VerificationSupportingDocumentsProps } from 'shared/types/sharedTypes';

export const VerificationSupportingDocuments = ({
  acceptedFiles,
  setAcceptedFiles,
}: VerificationSupportingDocumentsProps): JSX.Element => {
  const { t } = useTranslation();
  const removeFile = useCallback(
    (file: CustomPreviewFile): void => {
      setAcceptedFiles(acceptedFiles.filter((f) => f.path !== file.path));
    },
    [acceptedFiles, setAcceptedFiles]
  );

  return (
    <div className={styles.SupportingDocumentsContainer}>
      <div className={styles.SupportingDocumentsHeading}>
        {t('agent.applicants.renterResume.sourceOfIncome.incomeReport.supportingDocuments.large')}&nbsp;
        <span className={styles.OptionalFieldContainer}>{t('rental.verifierRedirect.form.optional')}</span>
      </div>
      <CustomDropzone
        className={styles.CustomDropZoneContainer}
        showUploadBox
        acceptedFiles={acceptedFiles}
        setAcceptedFiles={setAcceptedFiles}
      />
      {!isEmpty(acceptedFiles) && (
        <div className={styles.RentalDocumentsContainer}>
          {acceptedFiles.map((file) => (
            <div className={styles.ImageContainer} key={`verification-document-${file.name}`}>
              {file.isLoading && (
                <ImageProgressBox
                  fileName={file.name}
                  progress={64}
                  label={t('renter.fileUploadingState.uploadingFile')}
                  onRemoveHandler={() => removeFile(file)}
                  isRemovable
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
