import { ChangeEventHandler } from 'react';
import classNames from 'classnames';

interface ISelectProps {
  className: string;
  value: string | number;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  options: string[] | number[];
}
const MonthYearSelect = ({ className, value, onChange, options }: ISelectProps): JSX.Element => (
  <select className={classNames(className)} value={value} onChange={onChange}>
    {options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))}
  </select>
);

export default MonthYearSelect;
