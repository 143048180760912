import { RenterRoles, StringHashType } from './renterTypes';

export enum NavigationKeys {
  PROPERTY_SELECTION = 'Property Selection',
  RENTAL_CRITERIA = 'Rental Criteria',
  PERSONAL_PROFILE = 'Personal Profile',
  HOUSEHOLD_DETAILS = 'Household Details',
  ADDRESS_HISTORY = 'Address History',
  SOURCE_OF_INCOME = 'Source of Income',
  VEHICLE_INFORMATION = 'Vehicle Information',
  ANIMAL_INFORMATION = 'Animal Information',
  BACKGROUND_QUESTIONS = 'Background Questions',
  SUMMARY_REPORT = 'Summary',
  PAYMENT = 'Payment',
  REPORT = 'Report',
}

export enum StepStatus {
  COMPLETED = 'completed',
  CURRENT = 'current',
  PENDING = 'pending',
}

const progressKeys = [
  NavigationKeys.PROPERTY_SELECTION,
  NavigationKeys.RENTAL_CRITERIA,
  NavigationKeys.PERSONAL_PROFILE,
  NavigationKeys.HOUSEHOLD_DETAILS,
  NavigationKeys.ADDRESS_HISTORY,
  NavigationKeys.SOURCE_OF_INCOME,
  NavigationKeys.VEHICLE_INFORMATION,
  NavigationKeys.ANIMAL_INFORMATION,
  NavigationKeys.BACKGROUND_QUESTIONS,
  NavigationKeys.SUMMARY_REPORT,
  NavigationKeys.REPORT,
  NavigationKeys.PAYMENT,
];

export type RenterNavigationData = {
  label: string;
  value: string;
  status: StepStatus;
  disabled: boolean;
};

export type CalculateCurrentProgressType = {
  progress: number;
  options: RenterNavigationData[];
};

export type NavigationSelectProps = {
  options: RenterNavigationData[];
  currentLocation: string;
  handleNavigation: (step: string | undefined) => void;
};

export enum NavigationKeyValues {
  PROPERTY_SELECTION = 'property-selection',
  RENTAL_CRITERIA = 'rental-criteria',
  PERSONAL_PROFILE = 'personal-profile',
  HOUSEHOLD_DETAILS = 'household-details',
  ADDRESS_HISTORY = 'address-history',
  SOURCE_OF_INCOME = 'source-of-income',
  VEHICLE_INFORMATION = 'vehicles-information',
  ANIMAL_INFORMATION = 'animal-information',
  BACKGROUND_QUESTIONS = 'background-questions',
  SUMMARY_REPORT = 'summary',
  REPORT = 'report',
  PAYMENT = 'payment-method',
}

export const UrlNavigationKeys: StringHashType = {
  [NavigationKeyValues.PROPERTY_SELECTION]: NavigationKeys.PROPERTY_SELECTION,
  [NavigationKeyValues.RENTAL_CRITERIA]: NavigationKeys.RENTAL_CRITERIA,
  [NavigationKeyValues.PERSONAL_PROFILE]: NavigationKeys.PERSONAL_PROFILE,
  [NavigationKeyValues.HOUSEHOLD_DETAILS]: NavigationKeys.HOUSEHOLD_DETAILS,
  [NavigationKeyValues.ADDRESS_HISTORY]: NavigationKeys.ADDRESS_HISTORY,
  [NavigationKeyValues.SOURCE_OF_INCOME]: NavigationKeys.SOURCE_OF_INCOME,
  [NavigationKeyValues.VEHICLE_INFORMATION]: NavigationKeys.VEHICLE_INFORMATION,
  [NavigationKeyValues.ANIMAL_INFORMATION]: NavigationKeys.ANIMAL_INFORMATION,
  [NavigationKeyValues.BACKGROUND_QUESTIONS]: NavigationKeys.BACKGROUND_QUESTIONS,
  [NavigationKeyValues.SUMMARY_REPORT]: NavigationKeys.SUMMARY_REPORT,
  [NavigationKeyValues.REPORT]: NavigationKeys.REPORT,
  [NavigationKeyValues.PAYMENT]: NavigationKeys.PAYMENT,
};

type RenterRestrictionsType = {
  [key in RenterRoles]: string[];
};

export const restrictedProgressKeys: RenterRestrictionsType = {
  [RenterRoles.PRIMARY]: progressKeys,
  [RenterRoles.OCCUPANTS]: [
    NavigationKeys.RENTAL_CRITERIA,
    NavigationKeys.PERSONAL_PROFILE,
    NavigationKeys.HOUSEHOLD_DETAILS,
    NavigationKeys.ADDRESS_HISTORY,
    NavigationKeys.SOURCE_OF_INCOME,
    NavigationKeys.VEHICLE_INFORMATION,
    NavigationKeys.ANIMAL_INFORMATION,
    NavigationKeys.BACKGROUND_QUESTIONS,
    NavigationKeys.REPORT,
    NavigationKeys.PAYMENT,
  ],
  [RenterRoles.GUARANTOR]: [
    NavigationKeys.RENTAL_CRITERIA,
    NavigationKeys.PERSONAL_PROFILE,
    NavigationKeys.ADDRESS_HISTORY,
    NavigationKeys.SOURCE_OF_INCOME,
    NavigationKeys.BACKGROUND_QUESTIONS,
    NavigationKeys.REPORT,
    NavigationKeys.PAYMENT,
  ],
};
