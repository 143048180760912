import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import { ReactComponent as AccountCircle } from 'assets/svgs/AccountCircle.svg';
import { ReactComponent as BathroomSvg } from 'assets/svgs/Bathroom.svg';
import { ReactComponent as BedroomSvg } from 'assets/svgs/Bedroom.svg';
import Avatar from 'components/shared/Avatar/Avatar';
import Toggle from 'components/shared/ToggleSwitch/ToggleSwitch';
import { ExperianRegistrationStatus } from 'constants/agentConstants';
import { makeAddress } from 'helpers/address';
import { parseApplicantsInitials } from 'helpers/propertyHelper';
import { PropertyType as PropertyStatusType } from 'shared/types/propertType';
import { PropertyRowCardProps } from 'shared/types/propertyType';

import PropertyActiveHousehold from './components/PropertyActiveHousehold';
import PropertyAddress from './components/PropertyAddress';
import PropertyCreation from './components/PropertyCreation';
import PropertyDate from './components/PropertyDate';
import PropertyGeneralInformation from './components/PropertyGeneralInformation';
import PropertyImage from './components/PropertyImage';
import PropertyInvite from './components/PropertyInvite';
import PropertyOwner from './components/PropertyOwner';
import PropertyTitle from './components/PropertyTitle';
import PropertyTransactions from './components/PropertyTransactions';
import PropertyType from './components/PropertyType';

import styles from './PropertyIndex.module.scss';

const PropertyRowCard = ({ property }: PropertyRowCardProps): JSX.Element => {
  const [checked, setChecked] = useState(false);
  const { experianVerified } = useSelector(selectedOrganization);
  const { experianRegistration } = useSelector(selectAgentProfileInformation);
  const { t } = useTranslation();

  return (
    <div className={styles.alignWithFlexHorizontalAlign} key={property.id}>
      <Row className={styles.propertyRowForData}>
        {/* first Column*/}
        <Col sm={5}>
          <Row>
            <Col xs={12} sm={3} md={3} lg={3}>
              {<PropertyImage fileLink={property.propertyImages?.[0]?.fileLink} />}
              <Row>
                <Col xs={12} md={12} lg={12} xl={6}>
                  <span className={styles.marketingLink}>Marketing</span>
                </Col>
                <Col xs={12} md={12} lg={12} xl={6}>
                  {(experianVerified || experianRegistration === ExperianRegistrationStatus.SUCCEEDED) &&
                  property.propertyStatus === PropertyStatusType.PUBLISHED ? (
                    <div className={styles.MarketingStatusContainer}>
                      <span
                        className={classNames(styles.Circle, { [styles.CircleActive]: property.marketingEnabled })}
                      />
                      <span className={classNames(styles.Text, { [styles.TextActive]: property.marketingEnabled })}>
                        {property.marketingEnabled
                          ? t('agent.properties.property.applicants.active')
                          : t('agent.properties.property.applicants.inActive')}
                      </span>
                    </div>
                  ) : (
                    <Toggle
                      className={(checked && styles.widthAdjustForMarketingStatus) || ''}
                      enabledText="Enabled"
                      disabledText="Disabled"
                      onColor="#ffffff"
                      offColor="#ffffff"
                      onChange={setChecked}
                      checked={checked}
                      width={65}
                      disabled={true}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm={9} md={9} lg={9} className={`${styles.PropertyDetailPadding}`}>
              <PropertyTitle id={property.id} title={property.name ?? makeAddress(property, 'title')} />

              <Col className={styles.gapWithFlex}>
                <PropertyAddress
                  address={property.name ? makeAddress(property, 'full address') : makeAddress(property, 'address')}
                />
                <PropertyType propertyType={property.propertyType} label="Type" />
                <PropertyDate date={property.dateAvailable} label="Available" />
                <Row>
                  <PropertyOwner propertyOwner={`${property.agentFirstName} ${property.agentLastName} `} />
                </Row>
              </Col>
            </Col>
          </Row>
        </Col>
        {/* Second Column*/}
        <Col sm={4} md={4}>
          <Row>
            <Row className={styles.propertyPadding}>
              <Row>
                <PropertyCreation property={property} />
              </Row>
            </Row>
            <Row className={classNames(styles.horizontalAlign, styles.textAlignEnd)}>
              <Col xs={3} sm={5} md={3} lg={3}>
                <PropertyGeneralInformation count={property.monthlyRentAmount} label="Rent" icon="$" />
              </Col>
              <Col xs={3} sm={5} md={3} lg={3}>
                <PropertyGeneralInformation count={property.depositAmount} label="Deposit" icon="$" />
              </Col>
              <Col xs={3} sm={5} md={3} lg={3}>
                <PropertyGeneralInformation count={property.bedroomsCount} label="Beds" icon={<BedroomSvg />} />
              </Col>
              <Col xs={3} sm={5} md={3} lg={3}>
                <PropertyGeneralInformation count={property.bathroomsCount} label="Baths" icon={<BathroomSvg />} />
              </Col>
            </Row>
          </Row>
        </Col>
        <Col sm={1} className={styles.alignWithFlexHorizontalAlign}>
          <div className={styles.verticleLine} />
        </Col>
        {/* Third Column*/}
        <Col sm={2}>
          <Row className={styles.applicants}>
            <span className={classNames(styles.propertyText, styles.addPadding)}>
              <AccountCircle /> Applicants
            </span>
            {property?.propertyStatus === PropertyStatusType.PUBLISHED && <PropertyInvite property={property} />}
            <Row className={styles.applicantInviteSection}>
              {!isEmpty(property?.applicants?.listOfApplicants) &&
                !!property?.applicants?.active &&
                !!property?.applicants?.sumOfHouseholds &&
                parseApplicantsInitials(property?.applicants?.listOfApplicants as []).map((el, index) => (
                  <Avatar
                    key={`avatar-${el}-${index}`}
                    firstName={el}
                    className={index === 0 ? '' : styles.overLapIcons}
                  />
                ))}

              <div className={styles.activeHouseholdPadding} />
              <PropertyActiveHousehold
                type="row"
                activeCount={property?.applicants?.active ?? 0}
                houseHoldCount={property?.applicants?.sumOfHouseholds ?? 0}
              />

              {/* {!property.propertyAmenities && <span className={styles.propertyText}>No transactions </span>} */}
              <PropertyTransactions status={property.propertyStatus} id={property.id} />
            </Row>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PropertyRowCard;
