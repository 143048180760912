import { renterRestrictions } from 'constants/restrictionConstants';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { AddPropertyParams } from 'shared/types/propertyType';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';
import { FinancialData } from 'shared/types/reportTypes';

import { AnimalSectionPDF } from '../../components/AnimalSectionPDF/AnimalSectionPDF';
import { EmploymentHistoryViewPDF } from '../../components/EmploymentHistoryViewPDF/EmploymentHistoryViewPDF';
import { CustomPDFPage } from '../../components/shared/CustomPDFPage/CustomPDFPage';
import { VehiclesSectionPDF } from '../../components/VehiclesSectionPDF/VehiclesSectionPDF';

interface Props {
  propertyInformation: AddPropertyParams;
  renterDetails: RenterProfileInformationProps;
  employmentVerificationInformation: AddressVerificationResponse[];
  application: ApplicationType;
  transactions: FinancialData;
}

export const IncomeVehicleAnimalPagePDF = ({
  propertyInformation,
  renterDetails,
  employmentVerificationInformation,
  application,
  transactions,
}: Props): JSX.Element => (
  <CustomPDFPage propertyInformation={propertyInformation} renterDetails={renterDetails} application={application}>
    <EmploymentHistoryViewPDF
      propertyInformation={propertyInformation}
      employments={renterDetails?.presentEmployments ?? []}
      transactions={transactions}
      employmentVerificationInformation={employmentVerificationInformation}
    />
    {!renterRestrictions[application?.renterRole].vehicles && <VehiclesSectionPDF vehicles={renterDetails.vehicles} />}
    {!renterRestrictions[application?.renterRole].animals && <AnimalSectionPDF animals={renterDetails.furryFriends} />}
  </CustomPDFPage>
);
