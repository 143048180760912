import { FunctionComponent, SVGProps } from 'react';
import { t } from 'i18next';

import { ReactComponent as BasicInfoIcon } from 'assets/svgs/BasicInfoIcon.svg';
import { ReactComponent as GroupIcon } from 'assets/svgs/GroupIcon.svg';
import { ReactComponent as ImageIcon } from 'assets/svgs/ImageIcon.svg';
import { ReactComponent as LineIcon } from 'assets/svgs/LineIcon.svg';
import { ReactComponent as SettingIcon } from 'assets/svgs/SettingIcon.svg';

export const PropertySteps = {
  basicInfo: 1,
  unitSpecs: 2,
  amentites: 3,
  description: 4,
  media: 5,
};
export interface StepperProps {
  name: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}
export const stepperIcons: StepperProps[] = [
  { name: 'basicInfo', Icon: BasicInfoIcon },
  { name: 'unitSpecs', Icon: GroupIcon },
  { name: 'amentites', Icon: SettingIcon },
  { name: 'description', Icon: LineIcon },
  { name: 'media', Icon: ImageIcon },
];

export const bedsInfo = [
  { label: 'Studio', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10+', value: 10 },
];

export const leaseDurationInfo = [
  { label: 'Monthly', value: 'Monthly' },
  { label: '6 months', value: '6 months' },
  { label: '1 year', value: '1 year' },
  { label: 'Contact for details', value: 'Contact for details' },
];

export const utilityInfo = [
  { label: 'No utilities included', value: 'No utilities included' },
  { label: 'All utilities included', value: 'All utilities included' },
  { label: 'Water, garbage utilities included', value: 'Water, garbage utilities included' },
];

export const parkingInfo = [
  { label: 'No parking', value: 'No parking' },
  { label: 'Street parking', value: 'Street parking' },
  { label: 'Off-street parking', value: 'Off-street parking' },
  { label: 'Attached garage', value: 'Attached garage' },
  { label: 'Detached garage', value: 'Detached garage' },
  { label: 'Carport', value: 'Carport' },
];

export const laundaryInfo = [
  { label: 'No laundry on-site', value: 'No laundry on-site' },
  { label: 'In unit', value: 'In unit' },
  { label: 'Shared, on-site', value: 'Shared, on-site' },
];

export const bathOptions = [
  { label: '1', value: 1 },
  { label: '1.5', value: 1.5 },
  { label: '2', value: 2 },
  { label: '2.5', value: 2.5 },
  { label: '3', value: 3 },
  { label: '3.5', value: 3.5 },
  { label: '4', value: 4 },
  { label: '4.5', value: 4.5 },
  { label: '5', value: 5 },
  { label: '5.5', value: 5.5 },
  { label: '6', value: 6 },
  { label: '6.5', value: 6.5 },
  { label: '7', value: 7 },
  { label: '7.5', value: 7.5 },
  { label: '8', value: 8 },
  { label: '8.5', value: 8.5 },
  { label: '9', value: 9 },
  { label: '9.5', value: 9.5 },
  { label: '10+', value: 10 },
];

export const allowedPetsValues = [
  { label: 'No pets allowed', value: 'No pets' },
  { label: 'Cats', value: 'Cats' },
  { label: 'Small dogs', value: 'Small dogs' },
  { label: 'Large dogs', value: 'Large dogs' },
];

export const CustomFeatureData = [
  {
    label: 'Unit',
    value: 'Unit',
  },
  {
    label: 'Property',
    value: 'Property',
  },
];

export enum ContactSelectTypes {
  EMAIL = 'Email',
  SMS = 'SMS/Text',
}

export const contactSelectValues = [
  { value: ContactSelectTypes.EMAIL, label: ContactSelectTypes.EMAIL },
  { value: ContactSelectTypes.SMS, label: ContactSelectTypes.SMS },
];
export const propertyOptionsTransaltion = [
  { label: 'Published', value: t('agent.properties.property.published') },
  { label: 'Archived', value: t('agent.properties.property.archived') },
  { label: 'Draft', value: t('agent.properties.property.Draft') },
];

export enum PropertyUtilities {
  BEDS = 'Beds',
  RENT = 'Rent',
  DEPOSIT = 'Deposit',
  BATHS = 'Baths',
}

export const PropertyViewOptions = [
  { label: 'Show page', value: t('agent.properties.property.show') },
  { label: 'View Show page', value: t('agent.properties.property.share') },
  { label: 'Share Show page', value: t('agent.properties.property.view') },
  { label: 'Brouchure', value: t('agent.properties.property.brouchure') },
  { label: 'Archive', value: t('agent.properties.property.archive') },
];

export const ApplicantViewOptions = [
  { label: 'View APPROVED', value: t('agent.properties.property.applicant.approved') },
  { label: 'View DENIED', value: t('agent.properties.property.applicant.denied') },
  { label: 'View ARCHIEVED', value: t('agent.properties.property.applicant.archieved') },
  { label: 'View LEADS', value: t('agent.properties.property.applicant.leads') },
  { label: 'View ALL', value: t('agent.properties.property.applicant.all') },
  { label: 'Past Transactions', value: t('agent.properties.property.applicant.past') },
];

export enum PropertyStatuses {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export const ApplicantsList = [
  { name: 'ML', color: '#735F32' },
  { name: 'AA', color: '#816797' },
  { name: 'MI', color: '#A3C7D6' },
  { name: 'TR', color: '#562B08' },
];

export const UnitAmenetiesList = [
  {
    name: 'Dishwasher',
    isSelected: true,
    type: 'unit',
    isCustom: false,
    isAnimation: false,
    tempSelected: false,
  },
  {
    name: 'Dryer',
    isSelected: true,
    type: 'unit',
    isCustom: false,
    isAnimation: false,
    tempSelected: false,
  },
  {
    name: 'Freezer',
    isSelected: true,
    type: 'unit',
    isCustom: false,
    isAnimation: false,
    tempSelected: false,
  },
];
export const PropertyAmenetiesList = [
  {
    name: 'Doorman',
    isSelected: true,
    type: 'property',
    isCustom: false,
    isAnimation: false,
    tempSelected: false,
  },
  {
    name: 'Dock',
    isSelected: true,
    type: 'property',
    isCustom: false,
    isAnimation: false,
    tempSelected: false,
  },
  {
    name: 'Elevator',
    isSelected: true,
    type: 'property',
    isCustom: false,
    isAnimation: false,
    tempSelected: false,
  },
];
