import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { setApplicationId, setInviteCode, setServerError } from 'redux/slices/authSlice';
import { getRenterProfileInformation, setIsRenterLoading } from 'redux/slices/renterSlice';

import { handleServerResponseError, parseResponseErrors } from 'helpers/helper';
import {
  createApplicationPropertyLink,
  createCustomAddress,
  getPropertyAgainstAcceptedInvitation,
} from 'services/renterService';
import { StatusCodes } from 'shared/constants';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { createFormikErrorObject } from 'shared/utils/errorObject';

import { ApiErrorType } from './apiErrorType';
import { AddPropertyParams } from './propertyType';
import { AgentCustomAddressApiProps, CreateCustomProperty } from './renterTypes';

export type AcceptedInvitation = {
  agentFirstName: string;
  agentLastName: string;
  inviteType: string;
  inviteCode: string;
  invitationStatus: string;
  createdAt: string | Date;
  updatedAt: string | Date;
};

export type RenterAcceptedInvitationsResponse = {
  payload: AcceptedInvitation[];
  errors: ApiErrorType[];
};

export enum InviteType {
  PROPERTY_GENERAL = 'PROPERTY_GENERAL',
  PROPERTY_SPECIFIC = 'PROPERTY_SPECIFIC',
  AGENT_SPECIFIC = 'AGENT_SPECIFIC',
  AGENT_GENERAL = 'AGENT_GENERAL',
}

export enum CustomPropertySteps {
  CREATE_PROPERTY = 'CREATE_PROPERTY',
  CONFIRM_PROPERTY = 'CONFIRM_PROPERTY',
}

export type PropertiesOfAcceptedInvite = {
  payload: AddPropertyParams[];
  errors: ApiErrorType[];
};

export type InviteProps = {
  inviteCode: string;
};

export type ApplicationCreationBodyParams = {
  applicationId: null | string;
  propertyId: number;
  inviteCode: string | null;
  desiredMoveInDate: string | Date;
};

export enum InviteDataKeys {
  INVITE_CODE = 'inviteCode',
  INVITE_TYPE = 'inviteType',
}

export interface AgentDetailsResponse {
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string | null;
}

export interface PropertyAdditionalInfo {
  animalsCount: string;
  desiredMoveInDate: string;
  experianAuth: string;
  groupIncome: string;
  occupantsCount: string;
}

export const fetchPropertyAgainstAcceptedInvitation = <T>(
  inviteCode: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  setProperty?: Dispatch<SetStateAction<T>>,
  setAcceptedInviteProperties?: Dispatch<SetStateAction<T>>
  // eslint-disable-next-line max-params
): void => {
  dispatch(setIsRenterLoading(true));
  getPropertyAgainstAcceptedInvitation(inviteCode)
    .then((response: AxiosResponse) => {
      const payload = response.data.payload;

      if (setProperty) {
        setProperty(payload);
      }

      if (setAcceptedInviteProperties) {
        setAcceptedInviteProperties(payload);
      }
    })
    .catch((error) => {
      if (error instanceof AxiosError && error.response) {
        if (
          error.response?.status === StatusCodes.NOT_FOUND ||
          error.response?.status === StatusCodes.INTERNAL_SERVER_ERROR
        ) {
          const status = (error as AxiosError)?.response?.status;

          dispatch(setServerError(status));
        } else {
          Notification({ message: parseResponseErrors(error) });
        }
      }
    })
    .finally(() => dispatch(setIsRenterLoading(false)));
};

export const createApplication = (
  params: ApplicationCreationBodyParams,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  navigate: NavigateFunction
): void => {
  dispatch(setIsRenterLoading(true));

  createApplicationPropertyLink(params)
    .then((response: AxiosResponse) => {
      dispatch(setApplicationId(response?.data?.payload?.applicationId));
      dispatch(getRenterProfileInformation());
      dispatch(setInviteCode(null));
      navigate(renterRoutes.generateViewRenterCriteriaUrl(response?.data?.payload?.applicationId));
    })
    .catch((error) => {
      if (error instanceof AxiosError && error.response) {
        if (
          error.response?.status === StatusCodes.NOT_FOUND ||
          error.response?.status === StatusCodes.INTERNAL_SERVER_ERROR
        ) {
          const status = (error as AxiosError)?.response?.status;

          dispatch(setServerError(status));
        } else {
          Notification({ message: parseResponseErrors(error) });
        }
      }
    })
    .finally(() => dispatch(setIsRenterLoading(false)));
};

export const navigateToSelectProperty = (navigate: NavigateFunction, inviteType: string, inviteCode: string): void => {
  navigate(
    `${renterRoutes.applicationProperties}?${InviteDataKeys.INVITE_TYPE}=${inviteType}&${InviteDataKeys.INVITE_CODE}=${inviteCode}`
  );
};
export const navigateToCreateCustomProperty = ({
  navigate,
  inviteType,
  inviteCode,
  stepName,
}: CreateCustomProperty): void => {
  navigate(
    `${renterRoutes.customProperty}?${InviteDataKeys.INVITE_TYPE}=${inviteType}&${InviteDataKeys.INVITE_CODE}=${inviteCode}&stepName=${stepName}`
  );
};

export const createCustomAddresses = ({ values, dispatch, setErrors, navigate }: AgentCustomAddressApiProps): void => {
  dispatch(setIsRenterLoading(true));
  createCustomAddress(values)
    .then((res) => {
      const {
        data: { payload },
      }: AxiosResponse = res;

      dispatch(setApplicationId(payload?.applicationId));
      dispatch(getRenterProfileInformation());
      dispatch(setInviteCode(null));
      navigate(renterRoutes.generateViewRenterCriteriaUrl(payload?.applicationId));
    })
    .catch((error) => {
      handleServerResponseError({ error, dispatch });
      const errorObject = createFormikErrorObject(error as AxiosError);

      if (errorObject?.null) {
        setErrors({ streetAddress1: errorObject?.null });
      } else if (errorObject?.inviteCode) {
        setErrors({ streetAddress1: errorObject?.inviteCode });
      } else if (errorObject?.city) {
        setErrors({ streetAddress1: errorObject?.city });
      } else if (errorObject?.state) {
        setErrors({ streetAddress1: errorObject?.state });
      } else if (errorObject?.zipCode) {
        setErrors({ streetAddress1: errorObject?.zipCode });
      } else if (errorObject?.streetAddress1) {
        setErrors({ streetAddress1: errorObject?.streetAddress1 });
      }

      Notification({ message: parseResponseErrors(error) });
    })
    .finally(() => dispatch(setIsRenterLoading(false)));
};
