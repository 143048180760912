import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isEmpty, omitBy } from 'lodash-es';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { getRenterProfileInformation, setIsRenterLoading } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as InfoIcon } from 'assets/svgs/ErrorInfo.svg';
import RCButton from 'components/shared/Button/Button';
import { NavigationKeys, RenterAddressSteps, RenterProfileSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { convertAddressToFrontEndFormat } from 'helpers/address';
import { formatRenterName, formatRenterPresentAddress, renterPresentAddress } from 'helpers/renterHelper';
import { formattedDateString } from 'helpers/user';
import { getStripeSensitiveInformation } from 'services/stripeService';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { RenterProfileInformationInitialValue } from 'shared/types/renterTypes';
import { RenterInformationEditProps } from 'shared/types/renterTypes';
import { StripeErrorCode } from 'shared/types/stripeType';
import { formatDateForBackend } from 'shared/utils/formatDate';

import AddressEditModal from '../components/AddressEditModal/AddressEditModal';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';
import RenterInfoDetails from '../components/RenterInfoDetails/RenterInfoDetails';
import RenterInfoDetailsModal from '../components/RenterInfoDetailsModal/RenterInfoDetailsModal';
import { RenterPersonalInfoModal } from '../components/RenterPersonalInfoModal/RenterPersonalInfoModal';
import ScanNewIDModal from '../components/ScanNewIDModal/ScanNewIDModal';
import StripeWaiting from '../components/Stripe/StripeWaiting/StripeWaiting';

import indexStyles from '../RenterIndex.module.scss';
import styles from './RenterPhotoIdInformation.module.scss';

const RenterPhotoIdInformation = ({ handleNext }: RenterInformationEditProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const renterDetails = useSelector(selectRenterProfileInformation);
  const [searchParams] = useSearchParams();
  const nextNavigationStep = searchParams.get(NavigationKeys.NEXT);
  const { values, setErrors } = useFormikContext<typeof RenterProfileInformationInitialValue>();
  const [shouldShowModal, setShowModal] = useState(false);
  const [shouldShowPersonalInfoModal, setShouldShowPersonalInfoModal] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [shouldShowAddressModal, setShowAddressModal] = useState(false);
  const [shouldShowScanModal, setShowScanModal] = useState(false);
  const [documentData, setDocumentData] = useState({ id: '---', type: '---' });
  const renterRole = useSelector(applicantRenterRole);
  const handleStripeInfoModal = (): void => {
    setShouldShowPersonalInfoModal(true);
    setShowModal(false);
  };

  useEffect(() => {
    if (renterRestrictions[renterRole].profile) {
      navigate(renterRoutes.generatePersonalInformationUrl(RenterAddressSteps.PRESENT_ADDRESS, Number(applicationId)));
    } else {
      if (renterDetails?.stripeVerified) {
        dispatch(setIsRenterLoading(true));
        getStripeSensitiveInformation()
          .then((res: AxiosResponse) => {
            const { payload } = res.data;

            setDocumentData({ id: payload.documentNumber, type: payload.documentType });
          })
          .catch((err) => {
            if (err?.response?.data?.errors[0]?.errorCode === StripeErrorCode.CONSENT_DECLINED) {
              Notification({ message: t('renter.PhotoIdInformation.stripeDocument.error') });
              dispatch(getRenterProfileInformation());
              navigate(renterRoutes.generateRenterStripeIdentityUrl(Number(applicationId)));
            }
          })
          .finally(() => dispatch(setIsRenterLoading(false)));
      }
    }
  }, [applicationId, dispatch, navigate, renterDetails?.stripeVerified, renterRole, t]);
  const handleShowModal = useCallback((willShowModal: boolean): void => setShowModal(willShowModal), []);
  const handleSubmit = useCallback(() => {
    const { firstName, lastName, middleName } = values;
    const formValues = { firstName, lastName, middleName };

    if (handleNext) {
      handleNext({
        params: omitBy(formValues, isEmpty),
        nextStep: '',
        setErrors,
        successCallback: () => setShowModal(false),
      });
    }
  }, [values, handleNext, setErrors]);
  const handleDobSubmit = useCallback(() => {
    const { birthDate } = values;
    const formValues = { birthDate: formatDateForBackend(birthDate) };

    if (handleNext) {
      handleNext({
        params: omitBy(formValues, isEmpty),
        nextStep: '',
        setErrors,
        successCallback: () => setShouldShowPersonalInfoModal(false),
      });
    }
  }, [values, handleNext, setErrors]);

  if (isWaiting) {
    return (
      <StripeWaiting
        setWaitingFalse={() => {
          setShowScanModal(false);
          setIsWaiting(false);
        }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <script src="https://js.stripe.com/v3" async></script>
      </Helmet>

      {/* icons list */}
      <div className={styles.renterScrollBar}>
        <div className={styles.Container}>
          <RenterIconInfoContainer />
          <h2 className={indexStyles.renterH2}>
            {t('renter.PhotoIdInformation.please')}{' '}
            <span className={styles.linkButtonColor}>{t('renter.PhotoIdInformation.confirm')}</span>{' '}
            {t('renter.PhotoIdInformation.personalInformation')}
          </h2>
          <RenterInfoDetails
            name={formatRenterName(renterDetails)}
            dateOfBirth={formattedDateString(renterDetails.birthDate)}
            idType={documentData.type ?? '---'}
            idNumber={documentData.id ?? '---'}
            address={formatRenterPresentAddress(renterDetails.addresses)}
            onEditPress={() => handleShowModal(true)}
            onEditAdressPress={() => setShowAddressModal(true)}
          />
          {shouldShowModal && (
            <RenterInfoDetailsModal
              shouldShowModal={shouldShowModal}
              onCloseModal={() => handleShowModal(false)}
              onSubmit={handleSubmit}
              dateOfBirth={formattedDateString(renterDetails.birthDate)}
              documentNumber={documentData.id}
              documentType={documentData.type}
              handleStripeInfoModal={handleStripeInfoModal}
            />
          )}
          {process.env.REACT_APP_ENV !== 'production' && shouldShowPersonalInfoModal && (
            <RenterPersonalInfoModal
              shouldShowModal={shouldShowPersonalInfoModal}
              onCloseModal={() => {
                setShouldShowPersonalInfoModal(false);
                setShowModal(true);
              }}
              onSubmit={handleDobSubmit}
            />
          )}
          {shouldShowAddressModal && (
            <AddressEditModal
              isModalOpen={shouldShowAddressModal}
              setIsModalOpen={() => setShowAddressModal(false)}
              address={convertAddressToFrontEndFormat(renterPresentAddress(renterDetails.addresses) ?? {})}
            />
          )}
          {shouldShowScanModal && (
            <ScanNewIDModal
              isOpenModal={shouldShowScanModal}
              onClose={() => setShowScanModal(false)}
              setIsWaiting={setIsWaiting}
            />
          )}
          {!renterDetails?.middleName && (
            <div className={styles.InfoContainer}>
              <InfoIcon width={18} height={18} />
              <div className={styles.DescriptionContainer}>
                {t('renter.PhotoIdInformation.infoMiddleName')}&nbsp;
                <i>
                  <strong>{t('application.not')}</strong>
                </i>
                &nbsp;{t('renter.PhotoIdInformation.infoMiddleNameText')}
              </div>
            </div>
          )}
          <div className={(indexStyles.renterButtonContainer, styles.renterPhotoInformationtButtonContainer)}>
            <RCButton
              className={classNames(styles.renterPhotoInformationButton)}
              variant="outline"
              onClick={() => {
                if (renterDetails.stripeVerified) {
                  setShowScanModal(true);
                } else {
                  navigate(renterRoutes.generateRenterStripeIdentityUrl(Number(applicationId)));
                }
              }}
            >
              {t('renter.PhotoIdInformation.scanID')}
            </RCButton>

            <RCButton
              className={classNames(styles.renterPhotoInformationButton)}
              variant="outline"
              onClick={() => {
                if (handleNext && nextNavigationStep) {
                  handleNext({
                    params: {},
                    nextStep: nextNavigationStep,
                    setErrors,
                  });
                } else {
                  navigate(
                    renterRoutes.generatePersonalInformationUrl(RenterProfileSteps.SSNCHECK, Number(applicationId))
                  );
                }
              }}
            >
              {t('renter.PhotoIdInformation.confirmAndContiue')}
            </RCButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenterPhotoIdInformation;
