/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { camelCase } from 'lodash-es';
import { uploadedDocuments } from 'redux/selectors/renterSelector';
import { deletePresentEmploymentDocuments, uploadPresentEmploymentDocuments } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { RootState } from 'redux/types';

import MinimizedDropzone, { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import { documentTypesInformation, EmploymentType } from 'shared/types/renterIncomeInformation';
import { RenterPresentEmployments } from 'shared/types/renterTypes';

import styles from './DocumentTypes.module.scss';
const DocumentTypesDropzones = ({
  data,
  currentEmploymentId,
}: {
  data: RenterPresentEmployments;
  currentEmploymentId?: number;
}): JSX.Element => {
  const { employmentSubType } = data;
  const { employmentId } = useParams();
  const currentId = employmentId ? parseInt(employmentId) : 0;
  const presentEmploymentUploadedDocuments = useSelector((state: RootState) =>
    uploadedDocuments(state, currentEmploymentId ?? currentId)
  );
  const [acceptedFiles, setAcceptedFiles] = useState<PreviewFile[]>(presentEmploymentUploadedDocuments || []);
  const selectedDocumentTypes = documentTypesInformation[camelCase(employmentSubType) || EmploymentType.EMPLOYED];
  const dispatch = useDispatch<AppThunkDispatch>();
  const uploadPresentEmploymentDocument = async (previewFile: PreviewFile): Promise<any> => {
    const presentEmploymentId = data.id || 0;
    const documentType = 'supporting';
    const documentSubType = selectedDocumentTypes.primaryDocument;

    // Assuming dispatch returns a Promise, you need to await it

    try {
      return await dispatch(
        uploadPresentEmploymentDocuments({
          presentEmploymentId: presentEmploymentId,
          document: previewFile,
          documentSubType: documentSubType,
          documentType: documentType,
        })
      )
        .unwrap()
        .then((response) => response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const deletePresentEmploymentDocument = (previewFile: PreviewFile): Promise<any> => {
    const currentDocument = presentEmploymentUploadedDocuments?.find(
      (obj) => obj.fileName === previewFile.path || obj.fileName === previewFile.fileName
    );

    if (currentDocument) {
      return dispatch(
        deletePresentEmploymentDocuments({
          presentEmploymentId: currentEmploymentId ?? currentId,
          document: currentDocument,
        })
      ).then((res) => res.payload);
    } else {
      return Promise.reject(new Error(t('renter.incomeInformation.documentType.error.notFound')));
    }
  };

  useEffect(() => {
    setAcceptedFiles(presentEmploymentUploadedDocuments || []);
  }, [presentEmploymentUploadedDocuments]);

  return (
    <div className={styles.documentTypes}>
      <MinimizedDropzone
        title={selectedDocumentTypes.primaryDocument}
        uploadApiCall={uploadPresentEmploymentDocument}
        canReorder={false}
        deleteApiCall={deletePresentEmploymentDocument}
        acceptedFiles={acceptedFiles}
        setAcceptedFiles={setAcceptedFiles}
        enabled
      />
      {employmentSubType === EmploymentType.SELF_EMPLOYED && (
        <MinimizedDropzone
          title="Other"
          uploadApiCall={uploadPresentEmploymentDocument}
          canReorder={false}
          deleteApiCall={deletePresentEmploymentDocument}
          acceptedFiles={acceptedFiles}
          setAcceptedFiles={setAcceptedFiles}
          enabled
        />
      )}
    </div>
  );
};

export default DocumentTypesDropzones;
