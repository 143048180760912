import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { useFormikContext } from 'formik';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import { ReactComponent as DropDownFillArrow } from 'assets/svgs/BlackDropdownArrow.svg';
import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import AdminPermissions from 'components/Agent/Organizations/OrganizationPage/AgentInviteModalBody/AdminPermissions/AdminPermissions';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { isPMCUser } from 'helpers/agentHelper';
import { AgentInviteInitialValues, AgentUserRoles, onRoleChange, visibleOptions } from 'shared/types/agentInvite';

import styles from '../UserInviteForm.module.scss';

interface AgentRoleDropdownProps {
  isLoading: boolean;
}
const UserRoleDropdown = ({ isLoading }: AgentRoleDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<typeof AgentInviteInitialValues>();
  const currentOrganization = useSelector(selectedOrganization);
  const currentAgentRole = currentOrganization.roleName || AgentUserRoles.AGENT;
  const agentRoleOptions = useMemo(
    () =>
      visibleOptions(
        currentAgentRole,
        isPMCUser(currentOrganization.housingProviderCategory) || currentOrganization.roleName !== AgentUserRoles.OWNER
      ),
    [currentAgentRole, currentOrganization.housingProviderCategory, currentOrganization.roleName]
  ); // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customOption = (props: any): JSX.Element => {
    const { label, data } = props;

    return (
      <components.Option {...props}>
        <div>
          <div>
            <span className={styles.label}>{label}</span>
          </div>
          <small className={styles.description}>{data.description}</small>
        </div>
      </components.Option>
    );
  };
  const getInfoStatement = useMemo(
    () =>
      (title: string): string => {
        if (title === AgentUserRoles.ADMIN) {
          return t('agent.userInviteForm.adminRoleDescription');
        } else if (title === AgentUserRoles.MANAGING_AGENT) {
          return t('agent.userInviteForm.managerRoleDescription');
        } else if (title === AgentUserRoles.AGENT) {
          return t('agent.userInviteForm.agentRoleDescription');
        }

        return t('agent.userInviteForm.guestRoleDescription');
      },
    [t]
  );
  const renderInfoBox = useMemo(
    () =>
      function (title: string): JSX.Element {
        return (
          <div className={styles.managerInformation}>
            <InformationIcon className={styles.managerInformationIcon} />
            <p className={styles.managerInformationText}>{getInfoStatement(title)}</p>
          </div>
        );
      },
    [getInfoStatement]
  );

  return (
    <div className={styles.selectContainer}>
      <ReactSelect
        placeholder={t('agent.userInviteForm.select')}
        options={agentRoleOptions}
        isFocusedFontSize={600}
        isDisabled={isLoading}
        dropDownIcon={<DropDownFillArrow />}
        dropDownIconContainer={styles.dropDownFillArrow}
        customOption={customOption}
        isClearable={!!values.roleName?.length}
        value={{ name: values.roleName, label: values.roleName }}
        onChange={async (selectedOption) => {
          onRoleChange(
            selectedOption,
            setFieldValue,
            isPMCUser(currentOrganization.housingProviderCategory) ||
              currentOrganization.roleName !== AgentUserRoles.OWNER
          );
          setFieldValue('inviteLink', '');
        }}
      />
      {values.roleName && renderInfoBox(values.roleName)}
      {values.roleName === AgentUserRoles.ADMIN && (
        <div className={styles.permissionChecks}>
          <AdminPermissions shouldShowDescription={true} shouldShowTitle={false} />
        </div>
      )}
    </div>
  );
};

export default UserRoleDropdown;
