import { Popover, PopoverBody as Body } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { updateRenterReportConsent } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as AddIcon } from 'assets/svgs/AddIconFilled.svg';
import { ReactComponent as DropDownArrow } from 'assets/svgs/BlackDownArrow.svg';
import { ReactComponent as TickIcon } from 'assets/svgs/GreenTick.svg';
import styles from 'components/Renter/IdentityVerification/RenterDetails/shared/Popover.module.scss';
import RCButton from 'components/shared/Button/Button';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { RENTER_START_A_DISPUTE_URL } from 'shared/constants';
import { ActionTooltipProps } from 'shared/types/renterTypes';

import tooltipStyles from './ActionTooltip.module.scss';

export const ActionTooltip = ({
  isShowingOverlay,
  setIsShowingOverlay,
  hasShareConsent,
  setHasShareConsent,
}: ActionTooltipProps): JSX.Element => {
  const { t } = useTranslation();
  const { appId: applicationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const onOptionSelect = (): void => {
    setIsShowingOverlay(false);
  };
  const onShareClickHandler = (): void => {
    dispatch(updateRenterReportConsent({ applicationId: Number(applicationId), isConsented: true }));
    setHasShareConsent(true);
    onOptionSelect();
  };
  const onStopShareClickHandler = (): void => {
    dispatch(updateRenterReportConsent({ applicationId: Number(applicationId), isConsented: false }));
    setHasShareConsent(false);
    onOptionSelect();
  };
  const onDisputeClickHandler = (): void => {
    window.open(RENTER_START_A_DISPUTE_URL, '_blank');
    onOptionSelect();
  };
  const renderTooltip = (
    <Popover className={styles.PopOverClass} id="popover-positioned-bottom" hasDoneInitialMeasure>
      <Body className={classNames(styles.popoverBody, tooltipStyles.Container)}>
        <div className={styles.popoverWrapper}>
          <RCButton className={tooltipStyles.OptionClassName} variant="outline" onClick={onShareClickHandler}>
            <div className={tooltipStyles.OptionIconContainer}>{hasShareConsent && <TickIcon />}</div>
            {t('actionTooltip.share')}
          </RCButton>
          <RCButton className={tooltipStyles.OptionClassName} variant="outline" onClick={onStopShareClickHandler}>
            <div className={tooltipStyles.OptionIconContainer}>{!hasShareConsent && <TickIcon />}</div>
            {t('actionTooltip.stopShare')}
          </RCButton>
          <div className={tooltipStyles.HorizontalLine} />
          <RCButton className={tooltipStyles.OptionClassName} variant="outline" onClick={onDisputeClickHandler}>
            <div className={tooltipStyles.OptionIconContainer}>
              <AddIcon />
            </div>
            {t('actionTooltip.startDispute')}
          </RCButton>
        </div>
      </Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      show={isShowingOverlay}
      rootClose
      trigger="click"
      key="bottom"
      placement="bottom"
      overlay={renderTooltip}
    >
      <span className={styles.overlayTrigger} onBlur={() => setIsShowingOverlay(false)}>
        <Tooltip
          placement="top"
          text={''}
          className={tooltipStyles.TooltipClassName}
          icon={
            <RCButton
              variant="outline"
              className={tooltipStyles.ActionButton}
              onClick={() => setIsShowingOverlay(!isShowingOverlay)}
            >
              <div className={tooltipStyles.ButtonContent}>
                {t('actionTooltip.action')} <DropDownArrow />
              </div>
            </RCButton>
          }
        />
      </span>
    </OverlayTrigger>
  );
};
