import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export const InfoIconSVG = (): JSX.Element => (
  <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <G clip-path="url(#clip0_9997_104495)">
      <Path
        opacity="0.3"
        d="M9 3C5.6925 3 3 5.6925 3 9C3 12.3075 5.6925 15 9 15C12.3075 15 15 12.3075 15 9C15 5.6925 12.3075 3 9 3ZM9.75 12.75H8.25V8.25H9.75V12.75ZM9.75 6.75H8.25V5.25H9.75V6.75Z"
        fill="#5E6566"
      />
      <Path
        d="M8.25 5.25H9.75V6.75H8.25V5.25ZM8.25 8.25H9.75V12.75H8.25V8.25ZM9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15Z"
        fill="#5E6566"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_9997_104495">
        <Rect width="18" height="18" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
