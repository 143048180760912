import { Dispatch, SetStateAction } from 'react';
import { SingleValue } from 'react-select';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { generateOrganizationMemberInvite, resetInviteId } from 'redux/slices/organizationSlice';

import { parseResponseErrors } from 'helpers/helper';
import { sendOrganizationEmailInvite } from 'services/organizationService';
import { Notification } from 'shared/Notification/Notification';

import { ApiErrorType } from './apiErrorType';
import { OrganizationProps } from './organizationTypes';

export const AgentInviteInitialValues = {
  roleName: '',
  emailAddress: '',
  permissions: [],
  inviteLink: '',
};

export enum AgentUserRoles {
  OWNER = 'OWNER',
  ADMIN = 'ADMINISTRATOR',
  MANAGING_AGENT = 'MANAGER',
  AGENT = 'AGENT',
  GUEST_AGENT = 'GUEST',
}

export enum AgentPermissions {
  BRAND_ASSETS_AND_FORMS = 'BRAND_ASSETS_AND_FORMS',
  USERS_AND_TEAMS = 'USERS_AND_TEAMS',
  RECORDS_AND_ACTIVITY = 'RECORDS_AND_ACTIVITY',
}

export enum InviteErrorCodes {
  ALREADY_ACCEPTED = 258,
}
export const TEMP_SHARE_LINK =
  "www.myintellirent.leasing86c146da-59d8-4f1d-8824-2cb686bfd4ea/request/26484655-7c7ba556-ef90-42ee-a8b2-391fbce1b581teamsfasdfdas24vd'";
export const AdminDefaultPermissions = [
  AgentPermissions.BRAND_ASSETS_AND_FORMS,
  AgentPermissions.USERS_AND_TEAMS,
  AgentPermissions.RECORDS_AND_ACTIVITY,
];
export const NonPMCAdminDefaultPermissions = [AgentPermissions.BRAND_ASSETS_AND_FORMS];
export const NonPMCAgentRoles = [
  {
    label: 'Administrator',
    name: AgentUserRoles.ADMIN,
    description: 'Full access to modify settings, users and/or manage user records.',
  },
];
export const AgentRoles = [
  {
    label: 'Administrator',
    name: AgentUserRoles.ADMIN,
    description: 'Full access to modify settings, users and/or manage user records.',
  },
  {
    label: 'Manager',
    name: AgentUserRoles.MANAGING_AGENT,
    description: 'Can add, edit users and access records in team when specified.',
  },
  { label: 'Agent', name: AgentUserRoles.AGENT, description: 'Can add guests and access records created or assigned.' },
  { label: 'Guest', name: AgentUserRoles.GUEST_AGENT, description: 'Can access records created or assigned.' },
];

export type OrganizationInviteLinkParams = {
  organizationId: number;
  agentEmail: string;
  invitedRole: string; // ADMINISTRATOR, MANAGER, AGENT, GUEST
  permissions?: string[]; // BRAND_ASSETS_AND_FORMS, RECORDS_AND_ACTIVITY, USERS_AND_TEAMS
  inviteId?: number | null; // if a non null value is provided for invite's ID, it will get trigger update
  inviteLink?: string;
};

export type GenerateInviteLinkPayload = {
  inviteId: number;
  inviteCode: string;
  inviteLink: string;
};

export type GenerateInviteLinkApiResponse = {
  errors: [] | ApiErrorType[];
  payload: GenerateInviteLinkPayload;
};

export interface InviteAgentModalProps {
  setInviteSent: Dispatch<SetStateAction<boolean>>;
}

export interface AgentInviteParams {
  invitedRole: string;
  agentEmail: string;
  organizationId: number;
  permissions?: string[]; // Make permissions an optional property
}

// Determine which options to show based on the selected role
export const visibleOptions = (
  currentAgentRole: string,
  isPMCUser: boolean
): { label: string; name: AgentUserRoles }[] =>
  AgentRoles.filter((option) => {
    if (!isPMCUser) {
      return option.name === AgentUserRoles.ADMIN;
    } else if (currentAgentRole === AgentUserRoles.ADMIN || currentAgentRole === AgentUserRoles.OWNER) {
      return true; // Show all options for admin and owner currentAgentRole
    } else if (currentAgentRole === AgentUserRoles.MANAGING_AGENT) {
      return (
        option.name === AgentUserRoles.MANAGING_AGENT ||
        option.name === AgentUserRoles.AGENT ||
        option.name === AgentUserRoles.GUEST_AGENT
      );
    } else if (currentAgentRole === AgentUserRoles.AGENT) {
      return option.name === AgentUserRoles.GUEST_AGENT;
    } else {
      return false; // Hide options and guest roles
    }
  });

/**
 * Checks if the current user has invite privilege.
 *
 * @returns {boolean} True if the user has invite privilege, otherwise false.
 */
export const hasInvitePrivilege = (currentOrganization: OrganizationProps): boolean => {
  if (
    currentOrganization.roleName === AgentUserRoles.GUEST_AGENT ||
    (currentOrganization.roleName === AgentUserRoles.ADMIN &&
      !currentOrganization.permissions.includes(AgentPermissions.USERS_AND_TEAMS))
  ) {
    return false;
  }

  return true;
};

/**
 * Determines whether the current user can view records and activity based on their organization and role.
 *
 * @param {OrganizationProps} currentOrganization - The organization of the current user.
 * @returns {boolean} - `true` if the user can view records and activity; otherwise, `false`.
 */
export const canViewRecordsAndActivity = (currentOrganization: OrganizationProps): boolean => {
  // Check if the user's role is GUEST_AGENT, in which case they cannot view records and activity.
  if (currentOrganization.roleName === AgentUserRoles.GUEST_AGENT) {
    return false;
  }

  // Check if the user is an ADMIN but does not have the RECORDS_AND_ACTIVITY permission, in which case they cannot view records and activity.
  if (
    currentOrganization.roleName === AgentUserRoles.ADMIN &&
    !currentOrganization.permissions.includes(AgentPermissions.RECORDS_AND_ACTIVITY)
  ) {
    return false;
  }

  // If none of the above conditions are met, the user can view records and activity.
  return true;
};

export type Option = SingleValue<{
  name: string;
  label: string;
}>;
export const onRoleChange = async (
  selectedOption: Option,
  setFieldValue: (field: string, value: string | AgentPermissions[] | undefined) => void,
  isPMCUser: boolean
): Promise<void> => {
  // Set the selected role value to the 'roleName' field in the formik values
  await setFieldValue('roleName', selectedOption?.name);

  // if the role is admin, set all permissions otherwise clear them
  if (selectedOption?.name === AgentUserRoles.ADMIN) {
    setFieldValue('permissions', isPMCUser ? AdminDefaultPermissions : NonPMCAdminDefaultPermissions);
  } else {
    setFieldValue('permissions', []);
  }
};

export const generateLink = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  values: typeof AgentInviteInitialValues,
  setFieldValue: (field: string, value: string | undefined) => void,
  orgId: number,
  inviteId: number | null
  // eslint-disable-next-line max-params
): void => {
  const params = {
    organizationId: orgId,
    agentEmail: values.emailAddress,
    invitedRole: values.roleName,
    permissions: values.permissions,
    inviteId: inviteId ?? null,
  };

  dispatch(generateOrganizationMemberInvite(params))
    .unwrap()
    .then((res) => {
      setFieldValue('inviteLink', res.inviteLink);
    })
    .catch((error) => {
      Notification({ message: parseResponseErrors(error) });
    });
};

export const dispatchAgentInvite = async (
  params: OrganizationInviteLinkParams,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  setIsDataLoading: Dispatch<SetStateAction<boolean>>,
  setInviteSent: Dispatch<SetStateAction<boolean>>
  // eslint-disable-next-line max-params
): Promise<void> => {
  try {
    setIsDataLoading(true);

    if (!params.inviteId || (params.inviteLink && !params.inviteLink.length)) {
      await dispatch(generateOrganizationMemberInvite(params))
        .unwrap()
        .then(async (response) => {
          await sendOrganizationEmailInvite(params.organizationId, response.inviteId).then(() => {
            setInviteSent(true);
            dispatch(resetInviteId());
          });
        })
        .catch((error) => {
          Notification({ message: parseResponseErrors(error) });
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    } else {
      await sendOrganizationEmailInvite(params.organizationId, params.inviteId)
        .then(() => {
          setInviteSent(true);
          dispatch(resetInviteId());
        })
        .catch()
        .finally(() => {
          setIsDataLoading(false);
        });
    }
  } catch (e) {
    if (e instanceof AxiosError && e.response) {
      if (e.response.data.errors[0].errorCode === InviteErrorCodes.ALREADY_ACCEPTED) {
        // Reset inviteId
        dispatch(resetInviteId());

        // Clone the params object to avoid mutating the original
        const newParams = { ...params };

        newParams.inviteId = null;

        // Dispatch the agent invite with updated params
        dispatchAgentInvite(newParams, dispatch, setIsDataLoading, setInviteSent);
      } else {
        Notification({ message: e.response.data.errors[0].errorMessage });
      }
    }
  }
};
