import { useCallback } from 'react';
import { Navbar as NavBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { AppThunkDispatch } from 'redux/store';

import Button from 'components/shared/Button/Button';
import { clearAllInvitesDetails } from 'helpers/publicHelper';
import { routes } from 'shared/routes';

import styles from './Navbar.module.scss';
const Navbar = (): JSX.Element => {
  const { pathname } = useLocation();
  const isPageLogin = pathname === routes.login;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const onClickHandler = useCallback(() => {
    if (isPageLogin) {
      clearAllInvitesDetails({ dispatch });
      navigate(routes.signup);

      return;
    }

    navigate(routes.login);
  }, [dispatch, isPageLogin, navigate]);

  return (
    <NavBar
      collapseOnSelect
      expand="lg"
      className={classnames('navbar navbar-light ', styles.navContainer, {
        [styles.hideNavbar]: pathname.toLowerCase() === routes.signup || pathname === routes.renterLogin,
      })}
    >
      <div className="container-fluid">
        <NavLink to={routes.home} className={classnames('navbar-brand', styles.navHeading)}>
          <span className={styles.navIntelli}>intelli</span>
          <span className={styles.navRent}>rent</span>
        </NavLink>
        <Button onClick={onClickHandler} variant="primary" className={styles.btnPrimary}>
          {isPageLogin ? t('agent.signup.createAccount') : t('agent.signup.logIn')}
        </Button>
      </div>
    </NavBar>
  );
};

export default Navbar;
