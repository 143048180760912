import { useSearchParams } from 'react-router-dom';

import { Size, useWindowSize } from 'hooks/useResize';
import { PropertyProps } from 'shared/types/propertyType';

import PropertyCreation from './components/PropertyCreation';
import { MOBILE_SCREEN } from './PropertyIndex';
import PropertyRowCard from './PropertyRowCard';
import PropertyStackCard from './PropertyStackCard';

import styles from './PropertyIndex.module.scss';
export interface PropertyIndex {
  propertyObject: PropertyProps[];
}

const Property = ({ propertyObject }: PropertyIndex): JSX.Element => {
  const size: Size = useWindowSize();
  const [searchParams] = useSearchParams();
  const activeOption =
    size.width !== 0 && size.width < MOBILE_SCREEN
      ? 'grid'
      : searchParams.get('layout')
      ? searchParams.get('layout')
      : 'list';

  if (activeOption !== 'list') {
    return (
      <div className={styles.propertyIndexPageBackground}>
        <div className={styles.grid}>
          <div className={styles.grid_container}>
            {propertyObject.map((property) => (
              <div key={property.id}>
                <div className={styles.propertyCreationPosition}>
                  <PropertyCreation property={property} />
                </div>
                <div className={styles.card}>
                  <PropertyStackCard property={property} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (activeOption === 'list') {
    return (
      <div className={styles.propertyIndexPageBackground}>
        {propertyObject.map((property) => (
          <div key={property.id}>
            <PropertyRowCard property={property} />
          </div>
        ))}
      </div>
    );
  }

  return <div />;
};

export default Property;
