import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { selectedRenterDetails } from 'redux/selectors/agentSelector';

import { ReactComponent as VehicleIcon } from 'assets/svgs/CarIconDefault.svg';
import styles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import VehicleDescriptionCard from 'components/shared/VehicleDescriptionCard/VehicleDescriptionCard';

import { SectionTitle } from '../shared';

const RenterVehicles = (): JSX.Element => {
  const { t } = useTranslation();
  const { vehicles } = useSelector(selectedRenterDetails);

  return (
    <div className={classNames(styles.container, styles.ApplicationDetails)}>
      <div className={styles.infoDiv}>
        <SectionTitle
          mainTitle={t('renter.paymentConfirmation.vehciles.title')}
          iconElement={<VehicleIcon height={24} width={24} />}
          description={!!vehicles && t('renter.vehiclesInformation.renterResume.description')}
          count={vehicles?.length}
          // optionElement={<ThreeDotsIcon width={24} height={24} />}
          shouldShowCount={!isEmpty(vehicles)}
          shouldShowOptions
          shouldDrawLine={!!vehicles}
        />
        {isEmpty(vehicles) && (
          <div className={styles.EmptySectionTitle}>{t('renter.paymentConfirmation.vehicles.noVehicles')}</div>
        )}

        {vehicles?.map((vehicle, index) => (
          <div key={`renter-resume-vehicle-${vehicle.id}`}>
            <div className={styles.infoDiv}>
              <span className={styles.subTitle}>{`${t('renter.vehiclesInformation.vehicle')} ${index + 1}`}</span>
              <div className={styles.section}>
                <VehicleDescriptionCard hasFontSize vehicle={vehicle} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RenterVehicles;
