import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { backgroundInformation } from 'redux/selectors/renterSelector';
import { addBackgroundInformation, getBackgroundQuestionnaire } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { BackgroundQuestionnaireSchema } from 'schema/renterSchema';

import { tabTitle } from 'helpers/helper';
import { formatBackgroundInformationToFormValues } from 'helpers/renterHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { MyFormValues } from 'shared/types/renterBackgroundInformation';
import { BackgroundQuestion } from 'shared/types/renterTypes';

import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import BackgroundQuestions from './BackgroundQuestions/BackgroundQuestions';

import indexStyles from './../RenterIndex.module.scss';

const RenterBackgroundInformation = (): JSX.Element => {
  tabTitle('Your Application | Background Questions');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const backgroundInfo = useSelector(backgroundInformation);
  const [questionnaire, setQuestionnaire] = useState<MyFormValues>({ questions: [] });
  const dispatch = useDispatch<AppThunkDispatch>();
  const maxHeight = useDeviceHeight(220);
  const handleSubmit = (data: MyFormValues): void => {
    if (!isEmpty(backgroundInfo)) {
      navigate(renterRoutes.generateRenterPaymentVerification(applicationId));

      return;
    }

    const submitBackgroundInformation = data.questions.map((question) => ({
      questionId: question.id,
      answer: question.answer,
      explanation: question.explanation,
    }));

    dispatch(addBackgroundInformation({ applicationId: Number(applicationId), submitBackgroundInformation }))
      .unwrap()
      .catch((error) => {
        if (Object.keys(error).includes('null')) {
          Notification({
            message: t('renter.verifier.responseAlreadySubmitted'),
          });
        }
      })
      .finally(() => navigate(renterRoutes.generateRenterPaymentVerification(applicationId)));
  };

  useEffect(() => {
    if (!isEmpty(backgroundInfo)) {
      setQuestionnaire({ questions: formatBackgroundInformationToFormValues(backgroundInfo) });
    } else {
      dispatch(getBackgroundQuestionnaire())
        .unwrap()
        .then(({ backgroundQuestions }): void => {
          const newQuestions = backgroundQuestions.map((q: BackgroundQuestion) => ({
            ...q,
            answer: '',
            explanation: null,
          }));

          setQuestionnaire({ questions: newQuestions });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
          <RenterIconInfoContainer />

          <Formik
            initialValues={questionnaire}
            validationSchema={BackgroundQuestionnaireSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {() => (
              <Form>
                <BackgroundQuestions />
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </RenterContainer>
  );
};

export default RenterBackgroundInformation;
