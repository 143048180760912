import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { renterAnimalSchema } from 'schema/renterSchema';

import { NavigationKeys, RenterAnimalSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { StepRouteType } from 'helpers/address';
import { tabTitle } from 'helpers/helper';
import { renterRoutes } from 'shared/routes';
import { AnimalInformationInitialValues } from 'shared/types/renterTypes';

import AnimalDocumentation from './AnimalDocumentation/AnimalDocumentation';
import AnimalIndex from './AnimalIndex/AnimalIndex';
import AnimalConfirmation from './AnimalConfirmation';
import AnimalDetailInputCard from './AnimalDetailInputCard';
import AssistiveAnimalCard from './AssistiveAnimalCard';

const AnimalInformation = (): JSX.Element => {
  tabTitle('Your Application | Animal Information');
  const { applicationId } = useParams();
  const [searchParams] = useSearchParams();
  const stepName = searchParams.get('step') || RenterAnimalSteps.CHECK;
  const renterRole = useSelector(applicantRenterRole);
  const navigate = useNavigate();
  const nextStep = searchParams.get(NavigationKeys.NEXT);
  const { hasNoFurryFriends, furryFriends } = useSelector(selectRenterProfileInformation);
  const stepRoute: StepRouteType = {
    check: <AnimalIndex />,
    selection: <AssistiveAnimalCard />,
    form: <AnimalDetailInputCard />,
    summary: <AnimalDocumentation />,
    confirmation: <AnimalConfirmation />,
  };

  useEffect(() => {
    if (renterRestrictions[renterRole].animals || (hasNoFurryFriends && isEmpty(furryFriends) && !nextStep)) {
      navigate(renterRoutes.generateViewRenterBackgroundInformation(applicationId));
    }
  }, [applicationId, furryFriends, hasNoFurryFriends, navigate, nextStep, renterRole]);

  return (
    <Formik
      enableReinitialize
      initialValues={AnimalInformationInitialValues}
      validationSchema={renterAnimalSchema}
      onSubmit={() => {
        console.log('formik values');
      }}
    >
      {stepRoute[stepName]}
    </Formik>
  );
};

export default AnimalInformation;
