import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { routes } from 'shared/routes';
import { PropertyType as PropertyTypes } from 'shared/types/propertType';

import styles from './../PropertyIndex.module.scss';
export interface PropertyTransactionProps {
  status?: string | undefined;
  id: number | undefined;
}

const PropertyTransactions = ({ status, id }: PropertyTransactionProps): JSX.Element => {
  const navigate = useNavigate();
  const shouldShowManageDetails = useMemo(
    () => status === PropertyTypes.DRAFT || status === PropertyTypes.ARCHIVED,
    [status]
  );

  return shouldShowManageDetails ? (
    <button
      className={classNames(styles.applicantTitle, styles.applicantTitleBlock, styles.manageDetailsPublish)}
      onClick={() => navigate(routes.generatePropertyDetails(Number(id)), { state: { editable: true } })}
    >
      Manage details & publish to begin
    </button>
  ) : (
    <span className={classNames(styles.applicantTitle, styles.applicantTitleBlock)}>Click +Invite to begin</span>
  );
};

export default PropertyTransactions;
