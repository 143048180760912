import { Popover, PopoverBody as Body } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as FolderIcon } from 'assets/svgs/FolderIcon.svg';
import { ReactComponent as PeopleIcon } from 'assets/svgs/PeopleIcon.svg';
import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import styles from 'components/Renter/IdentityVerification/RenterDetails/shared/Popover.module.scss';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { ApplicationTooltipProps } from 'shared/types/agentTypes';

import tooltipStyles from './ApplicationTooltip.module.scss';

export const ApplicationTooltip = ({
  isShowingOverlay,
  setIsShowingOverlay,
  onRenterResumeSelect,
  isShowRenterResume = false,
}: ApplicationTooltipProps): JSX.Element => {
  const { t } = useTranslation();
  const onOptionSelect = (): void => {
    setIsShowingOverlay(false);
  };
  const renderTooltip = (
    <Popover className={styles.PopOverClass} id="popover-positioned-bottom" hasDoneInitialMeasure>
      <Body className={styles.popoverBody}>
        <div className={classNames(styles.popoverWrapper, tooltipStyles.Container)}>
          <button onClick={onOptionSelect} className={classNames(styles.popoverBtn, tooltipStyles.EditGroup)}>
            <FolderIcon />
            <div className={tooltipStyles.Text}>{t('application.editGroup')}</div>
          </button>
          <button onClick={onOptionSelect} className={classNames(styles.popoverBtn, tooltipStyles.EditGroup)}>
            <PeopleIcon height={24} width={24} />
            <div className={tooltipStyles.Text}>{t('application.editPermissions')}</div>
          </button>
          {isShowRenterResume && (
            <button onClick={onRenterResumeSelect} className={classNames(styles.popoverBtn, tooltipStyles.EditGroup)}>
              <div className={tooltipStyles.Text}>{t('application.renterResume')}</div>
            </button>
          )}
          <div className={tooltipStyles.HorizontalLine} />
          <div className={tooltipStyles.FileChanges}>
            <div className={tooltipStyles.Label}>{t('application.changeFileTo')}</div>
            <button onClick={onOptionSelect} className={tooltipStyles.ButtonText}>
              {t('application.approved')}
            </button>
            <button onClick={onOptionSelect} className={tooltipStyles.ButtonText}>
              {t('application.denied')}
            </button>
            <button onClick={onOptionSelect} className={tooltipStyles.ButtonText}>
              {t('application.archived')}
            </button>
          </div>
        </div>
      </Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      show={isShowingOverlay}
      rootClose
      trigger="click"
      key="bottom"
      placement="bottom"
      overlay={renderTooltip}
    >
      <span className={styles.overlayTrigger}>
        <Tooltip
          placement="top"
          text={t('application.editFileOptions')}
          icon={
            <ThreeDotsIcon
              style={{ transform: 'rotate(90deg)' }}
              onClick={() => setIsShowingOverlay(!isShowingOverlay)}
            />
          }
        />
      </span>
    </OverlayTrigger>
  );
};
