import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash-es';

import { VehiclesInformationType } from 'shared/types/renterVehicleInformation';

import { HeadingPDF } from '../shared/HeadingPDF/HeadingPDF';
import { VehicleCardPDF } from '../shared/VehicleCardPDF/VehicleCardPDF';

interface Props {
  vehicles: VehiclesInformationType[];
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  emptyContainer: {
    paddingHorizontal: '8px',
    paddingVertical: '4px',
    marginTop: '8px',
    borderRadius: '4px',
    border: '1px solid #E1EAEB',
    fontSize: '12px',
    fontWeight: 'semibold',
    color: '#303333',
  },
});

export const VehiclesSectionPDF = ({ vehicles }: Props): JSX.Element => (
  <View style={styles.container} wrap={false}>
    <HeadingPDF heading="Vehicles" count={vehicles.length} shouldShowCount shouldShowLine />
    {!isEmpty(vehicles) ? (
      vehicles.map((vehicle) => <VehicleCardPDF vehicle={vehicle} key={vehicle.id} />)
    ) : (
      <View style={styles.emptyContainer}>
        <Text>{`I do not have a vehicle`}</Text>
      </View>
    )}
  </View>
);
