import { Placement } from 'react-bootstrap/esm/types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from 'assets/svgs/InfoIcon.svg';

import styles from './PropertyInformation.module.scss';

export const CustomPropertyTooltip = ({ placement = 'bottom' }: { placement?: Placement }): JSX.Element => {
  const { t } = useTranslation();
  const renderTooltip = (
    <Popover id="popover-positioned-customAddress">
      <Popover.Body className={styles.popoverBody}>
        <span className={styles.tooltipText}>{t('renter.customPropertyTooltip.text')}</span>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose trigger="hover" placement={placement} overlay={renderTooltip}>
      <InfoIcon />
    </OverlayTrigger>
  );
};
