import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { formatAmountToUsdString } from 'helpers/helper';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { EmploymentBoxPDFProps } from 'shared/types/pdfTypes';
import { EditEmploymentTypes } from 'shared/types/renterEmploymentInformation';

import { GreyBackgroundTextPDF } from '../shared/GreyBackgroundTextPDF/GreyBackgroundTextPDF';
import { VerificationStatusPDF } from '../shared/VerificationStatusPDF/VerificationStatusPDF';
const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    padding: '4px 8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '4px',
    border: '1px solid #E1EAEB',
    background: '#FFF',
    gap: '5px',
  },
  FirstRow: {
    paddingTop: '5px',
    paddingRight: '5px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  Address: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
  },
  StatusContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'center',
  },
  SecondRow: {
    paddingBottom: '5px',
    paddingRight: '5px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  StateCity: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
    paddingLeft: '2px',
  },
  MonthlyRentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
  },
  Label: {
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
  ItalicLabel: {
    // fontStyle: 'italic',
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
  Value: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
  },
  DescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const EmploymentBoxPDF = ({
  jobDescription,
  jobRole,
  company,
  income,
  employmentType,
  employmentId,
  employmentVerificationInformation,
  status,
  businessName,
}: EmploymentBoxPDFProps): JSX.Element => {
  const { t } = useTranslation();
  const currentEmploymentVerification = useMemo(
    () =>
      employmentVerificationInformation.find(
        (emp) => emp?.renterEmploymentInformation?.presentEmploymentId === employmentId
      ) ?? ({} as AddressVerificationResponse),
    [employmentId, employmentVerificationInformation]
  );

  return (
    <View style={styles.Container} wrap={false}>
      <View style={styles.FirstRow}>
        {(!!company || !status) && <Text style={styles.Address}>{company || businessName}</Text>}
        {!!status && <Text style={styles.Label}>{t('agnet.renterResumePdf.currentStatus')}</Text>}
        <View style={styles.StatusContainer}>
          <GreyBackgroundTextPDF
            textValue={
              employmentType === EditEmploymentTypes.EMPLOYED ? t('renter.sourceOfIncome.employed') : employmentType
            }
          />
          {!!currentEmploymentVerification?.verifierStatus && (
            <VerificationStatusPDF status={currentEmploymentVerification?.verifierStatus} />
          )}
        </View>
      </View>
      <View style={styles.SecondRow}>
        {!!jobRole && <Text style={styles.StateCity}>{jobRole}</Text>}
        {!!status && <Text style={styles.Value}>{status}</Text>}
        {!!income && (
          <View style={styles.MonthlyRentContainer}>
            <Text style={styles.Label}>{t('agnet.renterResumePdf.statedIncome')}</Text>
            <Text style={styles.Value}>{formatAmountToUsdString(income)}</Text>
            <Text style={styles.ItalicLabel}>{t('agnet.renterResumePdf.monthly')}</Text>
          </View>
        )}
      </View>
      {!!jobDescription && (
        <View style={styles.DescriptionContainer}>
          <Text style={styles.Label}>{t('agent.properties.property.description')}</Text>
          <Text style={styles.Value}>{jobDescription}</Text>
        </View>
      )}
    </View>
  );
};
