import { AxiosResponse } from 'axios';

import { organizationAPI } from 'constants/organizationConstants';
import { getMemberRetrievalUrl } from 'helpers/organizationHelper';
import { GenerateInviteLinkApiResponse, OrganizationInviteLinkParams } from 'shared/types/agentInvite';
import { InviteResponse } from 'shared/types/authType';
import { AxiosPromise } from 'shared/types/axiosPromise';
import {
  AgentOrganizationInvitesProps,
  AgentOrganizations,
  MembersRetrievalParams,
  OrganizationProps,
} from 'shared/types/organizationTypes';

import baseService from './baseService';

export const getOrganizations = (): AxiosPromise =>
  baseService.get<AxiosResponse<AgentOrganizations>>(organizationAPI.getOrganizations);

export const switchOrganization = (id: number): AxiosPromise =>
  baseService.get<AxiosResponse<OrganizationProps>>(`${organizationAPI.switchOrganization}/${id}`);

export const generateOrganizationMemberInvite = (params: OrganizationInviteLinkParams): AxiosPromise =>
  baseService.put<AxiosResponse<GenerateInviteLinkApiResponse>>(
    `${organizationAPI.generateOrganizationMemberInvite}`,
    params
  );
export const getAgentOrganizationInviteDetails = (inviteCode: string): AxiosPromise =>
  baseService.get<AxiosResponse<AgentOrganizationInvitesProps>>(`/organization/agent/invites/${inviteCode}`);

export const acceptAgentOrganizationInvite = (inviteCode: string): AxiosPromise =>
  baseService.post<AxiosResponse<string>>(`/organization/accept-invitation`, inviteCode);

export const sendOrganizationEmailInvite = (organizationId: number, inviteId: number | null): AxiosPromise =>
  baseService.post<AxiosResponse>(`/organization/${organizationId}/invite/${inviteId}/dispatch`);

export const getOrganizationMembers = ({
  id,
  target,
  sortBy,
  sortType,
  filter,
}: MembersRetrievalParams): AxiosPromise =>
  baseService.get<AxiosResponse<AgentOrganizations>>(
    `/organization/${id}/members${getMemberRetrievalUrl({
      target: target,
      sortBy: sortBy,
      filter: filter,
      sortType: sortType,
    })}`
  );

export const getInvites = (organizationId: number): AxiosPromise =>
  baseService.get<AxiosResponse<InviteResponse>>(`/organization/pending-invites`, {
    headers: { organizationId },
  });

//Get agent organization invite details using OrganizationId
export const getAgentOrganizationInvitationDetails = ({ organizationId }: { organizationId: number }): AxiosPromise =>
  baseService.get<AxiosResponse<AgentOrganizationInvitesProps>>(`/organization/agent/invite-details`, {
    headers: {
      organizationId: organizationId,
    },
  });
