import { useCallback, useMemo, useState } from 'react';
import { Placement } from 'react-bootstrap/esm/types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isFormDirty } from 'redux/selectors/renterSelector';
import { logoutSuccess } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as HelpIcon } from 'assets/svgs/filledHelp.svg';
import { ReactComponent as LogoutIcon } from 'assets/svgs/filledLogout.svg';
import { ReactComponent as HumburgerIcon } from 'assets/svgs/humburgerIcon.svg';
import Spinner from 'components/shared/Spinner/Spinner';
import { IsMobile } from 'helpers/IsMobile';
import { logout } from 'services/authService';
import { IntellirentPublicUrls } from 'shared/constants';

import Button from '../Button/Button';

import RenterLogOutModal from './RenterLogOutModal/RenterLogOutModal';

import styles from './RenterNavDropDown.module.scss';

const RenterNavDropDown = (): JSX.Element => {
  const { t } = useTranslation();
  const isMobile = IsMobile();
  const isDirty = useSelector(isFormDirty);
  const [isLogout, setIsLogout] = useState(false);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const popoverPlacement = useMemo((): Placement => (isMobile ? 'top' : 'bottom'), [isMobile]);
  const handleLogout = useCallback(() => {
    setIsLogout(true);
    logout()
      .then(() => {
        dispatch(logoutSuccess());
      })
      .finally(() => setIsLogout(false));
  }, [dispatch]);
  const handleOpenModal = useCallback((): void => {
    if (isDirty) {
      setShouldShowModal(true);
    } else {
      handleLogout();
    }
  }, [handleLogout, isDirty]);
  const renderTooltip = (
    <Popover id={`popover-positioned-${popoverPlacement}`}>
      <Popover.Body className={styles.popoverBody}>
        <Link to={IntellirentPublicUrls.NEW_REQUEST} target="_blank" rel="noreferrer" className={styles.HelpLink}>
          <div className={styles.buttonWithIcon}>
            <HelpIcon />
            {t('application.help')}
          </div>
        </Link>
        <Button variant="link" onClick={handleOpenModal} disabled={isLogout}>
          <div className={styles.buttonWithIcon}>
            {isLogout && !shouldShowModal ? <Spinner /> : <LogoutIcon />}
            {t('application.logout')}
          </div>
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {shouldShowModal && (
        <RenterLogOutModal
          shouldShowModal={shouldShowModal}
          onCloseModal={() => setShouldShowModal(false)}
          onLogout={handleLogout}
          isLoading={isLogout}
        />
      )}
      <OverlayTrigger
        rootClose
        trigger={isMobile ? ['hover', 'focus'] : 'click'}
        placement={popoverPlacement}
        overlay={renderTooltip}
      >
        <HumburgerIcon />
      </OverlayTrigger>
    </>
  );
};

export default RenterNavDropDown;
