import { ReactElement } from 'react';

import styles from './Title.module.scss';
type SectionTitleProps = {
  mainTitle: string;
  subTitle?: string;
  iconElement?: ReactElement;
  optionElement?: ReactElement;
  description?: string;
  shouldShowOptions?: boolean;
};
const Title = ({
  mainTitle,
  subTitle,
  iconElement,
  description,
  shouldShowOptions,
  optionElement,
}: SectionTitleProps): JSX.Element => (
  <div className={styles.title}>
    <div className={styles.topDiv}>
      <div className={styles.header}>
        <span className={styles.icon}>{iconElement}</span>
        <span className={styles.heading}>{mainTitle}</span>
        <span className={styles.description}>{subTitle}</span>
      </div>
      {shouldShowOptions && optionElement && <div>{optionElement}</div>}
    </div>
    <div className={styles.nothingToShow}>
      <span className={styles.text}>{description}</span>
    </div>
  </div>
);

export default Title;
