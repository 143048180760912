import classNames from 'classnames';

import { ReportHeadingProps } from 'shared/types/reportTypes';

import styles from './ReportHeading.module.scss';

export const ReportHeading = ({
  heading,
  counter = 0,
  hasCounter = false,
  customHeadingClass,
}: ReportHeadingProps): JSX.Element => (
  <div className={styles.Container}>
    <div className={classNames(styles.Heading, customHeadingClass)}>
      {heading}
      {hasCounter && <div className={styles.CounterText}>{counter}</div>}
    </div>
  </div>
);
