import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Form, useFormikContext } from 'formik';
import { isLoading } from 'redux/selectors/renterSelector';

import styles from 'components/Renter/components/ConfirmPriorAddress/ConfirmPriorAddress.module.scss';
import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import indexStyles from 'components/Renter/RenterIndex.module.scss';
import RCButton from 'components/shared/Button/Button';
import { PlacesAutocompleteComponent } from 'components/shared/PlacesAutoCompleteComponent/PlacesAutoCompleteComponent';
import { RenterAddressTypes } from 'constants/renterConstants';
import { ADDRESS_FORMAT_INDEX } from 'helpers/address';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { CustomPropertySteps, InviteDataKeys } from 'shared/types/renterApplication';
import { AddressInformation, RenterCustomAddressType } from 'shared/types/renterTypes';
const RenterCustomAddress = (): JSX.Element => {
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteCode = searchParams.get(InviteDataKeys.INVITE_CODE) || '';
  const inviteType = searchParams.get(InviteDataKeys.INVITE_TYPE) || '';
  const isPageLoading = useSelector(isLoading);
  const isGoogleApiCalled = useRef(false);
  const { values, setFieldValue } = useFormikContext<RenterCustomAddressType>();
  const maxHeight = useDeviceHeight(230);

  useEffect(() => {
    if (inviteCode) {
      setFieldValue('inviteCode', inviteCode);
    }
  }, [inviteCode, setFieldValue]);

  useEffect(() => {
    const fullAddress: string = values.streetAddress1 ? values.streetAddress1 : '';

    //Extracting the apartment and the street address from the full address given by places-autocomplete
    if (!isGoogleApiCalled.current && values.zipCode && values.city && fullAddress.split(',').length > 1) {
      const apartment: string = fullAddress.substring(
        fullAddress.indexOf(',') + ADDRESS_FORMAT_INDEX,
        fullAddress.indexOf(values.city) - ADDRESS_FORMAT_INDEX
      );
      const initialPriorAddress: AddressInformation = {
        streetAddress: fullAddress.split(',')[0],
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
        addressType: RenterAddressTypes.CUSTOM,
        apartmentNumber: apartment,
      };

      setFieldValue('renterAddress', initialPriorAddress);
      isGoogleApiCalled.current = true;
    }
  }, [isGoogleApiCalled, setFieldValue, values]);

  return (
    <Form>
      <div
        className={styles.confirmPriorAddressContainer}
        style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto', overflow: 'visible' }}
      >
        <RenterIconInfoContainer />
        <div className={indexStyles.renterContentContainer}>
          <p className={indexStyles.renterH2}>{t('agent.invite.customInformationTitle')}</p>
          <div
            className={classNames(styles.placeAutoCompleteContainer, {
              [styles.errorPlaceAutoComplete]: isError,
            })}
          >
            <PlacesAutocompleteComponent
              addressField="streetAddress1"
              setIsError={setIsError}
              placeholder={t('application.fieldPlaceholder')}
              shouldAcceptCustomAddress={false}
              labelField={t('addressEditForm.street')}
              className="boldLabel"
              data-testid="addressSearchBar"
            />
          </div>
          <div className={indexStyles.renterButtonContainer}>
            <RCButton
              onClick={() =>
                setSearchParams({
                  inviteType: inviteType,
                  inviteCode: inviteCode,
                  stepName: CustomPropertySteps.CONFIRM_PROPERTY,
                })
              }
              className={classNames(indexStyles.renterButton, styles.button)}
              variant="outline"
              disabled={isError || isPageLoading}
            >
              {t('application.save')}
            </RCButton>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default RenterCustomAddress;
