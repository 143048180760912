//TODO: Temporarily disabled some non-interactive features and will re-enable later.

import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { applicationPropertyLink, selectedAgent, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation, getAgentDetails, getOrganizationName } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { desiredMoveInDateSchema } from 'schema/renterSchema';

import { ReactComponent as ContactInfoIcon } from 'assets/svgs/ContactInfo.svg';
import { ReactComponent as DollarIcon } from 'assets/svgs/DollarIcon.svg';
// import { ReactComponent as DownloadIcon } from 'assets/svgs/DownloadIcon.svg';
import { ReactComponent as ExperianIcon } from 'assets/svgs/ExperianIcon.svg';
import { ReactComponent as PaySlipIcon } from 'assets/svgs/PaySlipIcon.svg';
import { ReactComponent as PhotoIdIcon } from 'assets/svgs/PhotoIdIcon.svg';
import { ReactComponent as WorkInfoIcon } from 'assets/svgs/WorkInfoIcon.svg';
import RCButton from 'components/shared/Button/Button';
import { InitialMoveInDate } from 'constants/renterConstants';
import { tabTitle } from 'helpers/helper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../components/RenterContainer';
import RenterCriteriaInfo from '../components/RenterCriteriaInfo';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import { RenterCriteriaText } from './RenterCriteriaText/RenterCriteriaText';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from '../RenterIndex.module.scss';
const RenterCriteria = (): JSX.Element => {
  tabTitle('Your application | Rental Criteria');
  const { applicationId } = useParams();
  const [isDocumentOpened, setIsDocumentOpened] = useState(false);
  const [agentOrganizationName, setAgentOrganizationName] = useState<string | null>(null);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const applicationPropertyDetails = useSelector(applicationPropertyLink);
  const [isInitalMoveInDate, setIsInitalMoveInDate] = useState(InitialMoveInDate);
  const inviteAgent = useSelector(selectedAgent);
  const dispatch = useDispatch<AppThunkDispatch>();
  const maxHeight = useDeviceHeight(220);
  const viewDocument = (): void => {
    setIsDocumentOpened(!isDocumentOpened);
  };
  const agreedWithTerms = (): void => {
    setIsDocumentOpened(!isDocumentOpened);
    const params = { renterCriteriaAccepted: true, id: renterDetails.userId };

    if (!renterDetails?.renterCriteriaAccepted) {
      dispatch(addRenterProfileInformation({ values: params }));
    }

    navigate(renterRoutes.generateViewRenterCriteriaUrl(applicationId));
  };

  useEffect(() => {
    if (applicationId && isEmpty(inviteAgent)) {
      dispatch(getAgentDetails({ applicationId: Number(applicationId) }));
    }
  }, [applicationId, dispatch, inviteAgent]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsInitalMoveInDate({ moveInDate: applicationPropertyDetails.desiredMoveInDate });

    if (applicationPropertyDetails.propertyId) {
      dispatch(
        getOrganizationName({
          propertyId: applicationPropertyDetails.propertyId,
        })
      ).then((response) => {
        const { organizationName } = response.payload;

        setAgentOrganizationName(organizationName);
      });
    }
  }, [applicationPropertyDetails, dispatch]);

  return (
    <Formik
      initialValues={isInitalMoveInDate}
      enableReinitialize
      validationSchema={desiredMoveInDateSchema}
      onSubmit={() => console.log('')}
    >
      <RenterContainer>
        {renterDetails?.renterCriteriaAccepted ? (
          <section
            className={styles.renterInvitePropertyContainer}
            style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
          >
            <RenterIconInfoContainer />
            <h2 className={styles.renterInvitePropertyH2}>{t('renter.renterCriteria.keyPoints')}</h2>

            <div className={styles.renterCriteriaInfo}>
              <RenterCriteriaInfo
                icon={<DollarIcon />}
                text={t('renter.renterCriteriaInfo.agentFee')}
                badge={'$30.00'}
              />
              <RenterCriteriaInfo icon={<ContactInfoIcon />} text={t('renter.renterCriteriaInfo.contactInfo')} />
              <RenterCriteriaInfo icon={<PhotoIdIcon />} text={t('renter.renterCriteriaInfo.photoId')} />
              <RenterCriteriaInfo icon={<WorkInfoIcon />} text={t('renter.renterCriteriaInfo.employmentInfo')} />
              <RenterCriteriaInfo icon={<PaySlipIcon />} text={t('renter.renterCriteriaInfo.recentPayStubs')} />
              <RenterCriteriaInfo
                icon={<ExperianIcon width={22} height={22} />}
                text={t('renter.renterCriteriaInfo.creditReport')}
                badge={'Above 650'}
              />
              <RCButton
                variant="outline"
                className={classNames(styles.viewRenterCriteriaBtn, styles.continueBtn)}
                disabled={!renterDetails?.renterCriteriaAccepted}
                onClick={() => {
                  navigate(renterRoutes.generateRenterPhoneNumber(`${applicationId}`));
                }}
              >
                {t('renter.renterCriteria.understandAndContinueButtonText')}
              </RCButton>
            </div>
          </section>
        ) : (
          <div className={styles.Container} style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
            {!isDocumentOpened && (
              <div className={styles.GreetingContainer}>
                <RenterIconInfoContainer />
                <div className={styles.Greeting}>
                  {`${t('renter.application.HiIm')} ${inviteAgent?.firstName}, ${t(
                    'renter.application.yourAgent'
                  )}. ${t('renter.renterCriteria.introSecondLine')}`}
                </div>
                <div className={styles.ButtonContainer}>
                  <RCButton
                    variant="outline"
                    className={classNames(styles.viewRenterCriteriaBtn, styles.continueBtn)}
                    onClick={viewDocument}
                  >
                    {t('renter.renterCriteria.viewRenterCriteriaButtonText')}&nbsp;
                    {<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                  </RCButton>
                </div>
              </div>
            )}

            {isDocumentOpened && (
              <section
                className={styles.renterCriteriaDoc}
                style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
              >
                <Row className={styles.renterCriteriaHeader}>
                  <Col></Col>
                  <Col>
                    <span className={styles.h1}>{t('renter.applicationInfoBox.rentalCriteria')}</span>
                  </Col>
                  <Col>
                    <RCButton variant="white" className={styles.actionButtons}>
                      {/* <DownloadIcon /> */}
                    </RCButton>
                  </Col>
                </Row>
                <RenterCriteriaText agreedWithTerms={agreedWithTerms} agentOrganizationName={agentOrganizationName} />
              </section>
            )}
          </div>
        )}
      </RenterContainer>
    </Formik>
  );
};

export default RenterCriteria;
