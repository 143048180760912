import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Button, Col, Modal as BModal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { ReactComponent as QuestionAnswerIcon } from 'assets/svgs/QuestionAnswer.svg';
import RenterModal from 'components/Renter/components/RenterModal/RenterModal';
import styles from 'components/Renter/RenterBackgroundInformation/BackgroundQuestions/BackgroundQuestions.module.scss';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import TextField from 'components/shared/TextField/TextField';
import { backgroundQuestionOptions, QuestionAnswerKey } from 'constants/renterConstants';
import { BackgroundInformationValues, Option, questionOptions } from 'shared/types/renterBackgroundInformation';
import { UpdateBackgroundInfo } from 'shared/types/renterTypes';

interface BackgroundQuestionEditModalProps {
  index: number;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  isModalVisible: boolean;
  updateBackgroundInfoResponse: (backgroundInfoId: number, backgroundInfo: UpdateBackgroundInfo) => void;
}
const BackgroundQuestionEditModal = ({
  index,
  isModalVisible,
  setIsModalVisible,
  updateBackgroundInfoResponse,
}: BackgroundQuestionEditModalProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<BackgroundInformationValues>();
  const { t } = useTranslation();
  const [answer, setAnswer] = useState<string | null | undefined>(QuestionAnswerKey.YES);
  const [explanation, setExplanation] = useState<string | null | undefined>(
    values.backgroundInformation[index].explanation
  );
  const handleWillAnswer = useCallback((value: Option) => {
    if (value?.name === QuestionAnswerKey.NO) {
      setExplanation(null);
    }

    setAnswer(value?.name);
  }, []);
  const handleSave = (): void => {
    const updatedInfo = {
      answer: answer,
      explanation: explanation,
    };

    updateBackgroundInfoResponse(values.backgroundInformation[index].id, updatedInfo);
    setFieldValue(`backgroundInformation[${index}].explanation`, explanation);
    setFieldValue(`backgroundInformation[${index}].answer`, answer);
    setIsModalVisible(false);
  };

  return (
    <RenterModal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
      <BModal.Body className={styles.backgroundQuestionsContainer}>
        <div className={styles.modalHeading}>{t('application.edit')}</div>
        <Col className={styles.titileDiv}>
          <span>{t('renter.BackgroundInformation.headingTitle')}</span>
          <QuestionAnswerIcon />
        </Col>
        <div className={classNames(styles.questionContainer, styles.noScroll)}>
          <div className={styles.borderContainer}>
            {t(`${values.backgroundInformation[index].backgroundQuestion.question}`)}
            <div className={styles.dropDownContainer}>
              <ReactSelect
                value={questionOptions[answer ?? QuestionAnswerKey.NO]}
                isSearchable={false}
                onChange={(value) => handleWillAnswer(value)}
                options={backgroundQuestionOptions}
                className={styles.questionSelect}
                backgroundColor="#F7FAFA"
              />
            </div>
          </div>
        </div>
        {answer === QuestionAnswerKey.YES && (
          <div className={styles.answerContainer}>
            <div className={styles.textContainer}>
              <TextField
                value={explanation as string}
                onChange={(e) => setExplanation(e.target.value)}
                label={t('application.backgroundQuestion.pleaseExplain')}
                isTextArea="textarea"
                placeholder={t('renter.BackgroundInformation.typeResponse')}
                className={styles.textField}
                labelClassName={styles.labelStyle}
              />
            </div>
          </div>
        )}
        <div className={styles.renterButtonContainer}>
          <Button
            className={styles.renterButton}
            variant="outline"
            onClick={handleSave}
            disabled={answer === QuestionAnswerKey.YES && !explanation}
          >
            {t('saveAndContinue')}
          </Button>
        </div>
      </BModal.Body>
    </RenterModal>
  );
};

export default BackgroundQuestionEditModal;
