import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from 'assets/svgs/calendarIcon.svg';
import { DateViewerProps } from 'shared/types/agentTypes';

import styles from './DateRangeViewer.module.scss';

export const DateViewer = ({ label, date, isRangeViewer = false }: DateViewerProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={isRangeViewer ? '' : styles.Container}>
      <div className={styles.Label}>{label}</div>
      <div className={styles.DateContainer}>
        {date ? <CalendarIcon height={16} width={16} /> : null}
        <div className={styles.Date}>{date ? date : t('addressBox.present')}</div>
      </div>
    </div>
  );
};
