import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal as BModal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik, FormikErrors } from 'formik';
import { t } from 'i18next';
import { renterApplication } from 'redux/selectors/renterSelector';
import { updateAdultToApplication } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterAdultSchema } from 'schema/renterSchema';

import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import FormikField from 'components/shared/TextField/FormikTextField';
import { EditDetailsProps, RenterAdultInitialValues, RenterAdultProps } from 'shared/types/renterTypes';

import RenterModal from '../../RenterModal/RenterModal';

import { RENTER_RELATIONSHIPS } from './RenterAdult';

import styles from './RenterAdult.module.scss';

const EditDetailsAdultModal = ({
  isModalVisible,
  setIsModalVisible,
  onHideHandler,
  id,
  heading,
  description,
}: EditDetailsProps): JSX.Element => {
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const [formValues, setFormValues] = useState(RenterAdultInitialValues);
  const applications = useSelector(renterApplication);
  const { adults } = applications.find((app) => app.id === Number(currentApplicationId)) || {};
  const adultInfo = useMemo(() => adults?.find((adult) => adult?.id === id), [adults, id]);
  const dispatch = useDispatch<AppThunkDispatch>();
  const onSubmitHandler = useCallback(
    (values: RenterAdultProps, setErrors: (errors: FormikErrors<RenterAdultProps>) => void) => {
      dispatch(
        updateAdultToApplication({
          applicationId: Number(currentApplicationId),
          adults: {
            ...values,
            phoneNumber: values?.phoneNumber.includes('+') ? values.phoneNumber : '+' + values?.phoneNumber,
          },
          adultId: adultInfo?.id,
        })
      )
        .unwrap()
        .then(() => {
          setIsModalVisible(false);
        })
        .catch((errorObject) => {
          setErrors(errorObject);
        });
    },
    [adultInfo?.id, currentApplicationId, dispatch, setIsModalVisible]
  );

  useEffect(() => {
    if (adultInfo) {
      setFormValues(adultInfo);
    }
  }, [adultInfo]);

  return (
    <Formik
      initialValues={formValues}
      validationSchema={renterAdultSchema}
      enableReinitialize
      onSubmit={(values, { setErrors }) => {
        onSubmitHandler(values, setErrors);
      }}
    >
      {({ isValid, dirty: isDirty }) => (
        <RenterModal show={isModalVisible} onHide={onHideHandler} isDirty={isDirty}>
          <BModal.Body>
            <div className={styles.heading}>{heading ?? t('renter.PhotoIdInformation.editDetails')}</div>
            <div className={styles.subHeading}>
              {description ?? (
                <span>
                  {t('renter.PhotoIdInformation.makeChanges')}&nbsp;
                  <span className={styles.spanColor}>{t('renter.PhotoIdInformation.personal')}</span>&nbsp;
                  {t('renter.PhotoIdInformation.information')}
                </span>
              )}
            </div>
            <Form>
              <FormCloseWarning isDirty={isDirty} />
              <div className={styles.maritalContainer}>
                <FormikSelectField
                  hasFloatingLabel={true}
                  floatingLabel={t('renter.relationship.relationshipLabel')}
                  placeholder={t('renter.realtionship.select')}
                  name="relationship"
                  options={RENTER_RELATIONSHIPS}
                  dropDownIconContainer={styles.dropDownFillArrow}
                />
                <div className={styles.phoneNumberContainer}>
                  <FormikPhoneNumber
                    label={t('renter.relationship.adultPhoneLabel')}
                    name="phoneNumber"
                    onlyCountry={[]}
                    isCountryCodeDisabled={false}
                    isCountryCodeEditable={false}
                  />
                </div>
                <div className={styles.userField}>
                  <span className={styles.userLabel}>{t('renter.relationship.adult')}</span>
                  <FormikField
                    className={styles.inputBackgroundColor}
                    name={'emailAddress'}
                    placeholder={t('renter.relationship.adultEmailAddress')}
                  />
                </div>
                <div className={styles.renterButtonContainer}>
                  <RCButton className={styles.renterButton} variant="outline" type="submit" disabled={!isValid}>
                    {t('renter.PhotoIdInformation.savechanges')}
                  </RCButton>
                </div>
              </div>
            </Form>
          </BModal.Body>
        </RenterModal>
      )}
    </Formik>
  );
};

export default EditDetailsAdultModal;
