import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';

import { dateFormatFrontend } from 'constants/calendarConstants';
import { RenterAddressTypes } from 'constants/renterConstants';
import { getDateDifferenceFormattedString } from 'helpers/helper';
import { durationBetweenTwoDates } from 'helpers/user';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { AddressInformation } from 'shared/types/renterTypes';

import { AddressBoxPDF } from '../AddressBoxPDF/AddressBoxPDF';
import { HeadingPDF } from '../shared/HeadingPDF/HeadingPDF';
import { VerificationBarPDF } from '../VerificationBarPDF/VerificationBarPDF';

const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  AddressContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  AddressHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  AddressTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
  },
  AddressType: {
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'capitalize',
  },
  AddressStatusContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  DateDifference: {
    overflow: 'hidden',
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
  },
  TotalDifference: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2px',
    backgroundColor: '#E6EFF0',
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
    padding: '0px 3px',
  },
  AddressTag: {
    color: '#303333',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
  },
  NoAddressContainer: {
    borderRadius: '4px',
    border: '1px solid #E1EAEB',
  },
  NoAddress: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
    paddingLeft: '8px',
  },
});

export const AddressHistoryViewPDF = ({
  addresses,
  addressVerificationInformation,
}: {
  addresses: AddressInformation[];
  addressVerificationInformation: AddressVerificationResponse[];
}): JSX.Element => {
  let count = 1;
  const { t } = useTranslation();

  return (
    <View wrap={false} style={styles.Container}>
      <HeadingPDF heading={t('renter.ConfirmAddressHistory.addressHistory.')} shouldShowLine />
      {addresses.map((address) => (
        <View key={`address-${address.id}`} style={styles.AddressContainer} wrap={false}>
          <View style={styles.AddressHeader}>
            <View style={styles.AddressTypeContainer}>
              <Text style={styles.AddressType}>{address?.addressType}</Text>
              {address?.addressType === RenterAddressTypes.PRIOR && (
                <Text style={styles.AddressTag}>{`${t('application.address')} ${count++}`}</Text>
              )}
            </View>
            <View style={styles.AddressStatusContainer}>
              {address?.addressType !== RenterAddressTypes.PRIOR && address?.moveInDate && (
                <Text style={styles.DateDifference}>
                  {`${format(new Date(address?.moveInDate), dateFormatFrontend)} - Present`}
                </Text>
              )}
              {address?.addressType === RenterAddressTypes.PRIOR && address?.toDate && address?.fromDate && (
                <Text style={styles.DateDifference}>
                  {`${format(new Date(address?.fromDate), dateFormatFrontend)} - ${format(
                    new Date(address?.toDate),
                    dateFormatFrontend
                  )}`}
                </Text>
              )}
              {address?.addressType !== RenterAddressTypes.PRIOR && address?.moveInDate && (
                <Text style={styles.TotalDifference}>{getDateDifferenceFormattedString(address?.moveInDate)}</Text>
              )}
              {address?.addressType === RenterAddressTypes.PRIOR && address?.toDate && address?.fromDate && (
                <Text style={styles.TotalDifference}>
                  {durationBetweenTwoDates(address?.toDate, address?.fromDate)}
                </Text>
              )}
            </View>
          </View>
          <AddressBoxPDF
            addressType={address.addressType ?? ''}
            description={address?.description ?? ''}
            reasonToMove={address.reasonForMoving ?? ''}
            address={address?.streetAddress ?? ''}
            city={address?.city ?? ''}
            state={address?.state ?? ''}
            zipCode={address?.zipCode ?? ''}
            isRented={address?.isRented}
            addressId={address.id ?? 0}
            monthlyRent={address?.addressVerifiers?.[0]?.contactRent}
            addressVerificationInformation={addressVerificationInformation}
            classification={address?.classification ?? ''}
          />
          <VerificationBarPDF
            verificationLabel={t('renter.relationship.verificationOfResidency')}
            verificationLink="vor_03192024.jpg"
          />
        </View>
      ))}
      {addresses?.length === 1 && (
        <View style={styles.AddressContainer}>
          <View style={styles.AddressTypeContainer}>
            <Text style={styles.AddressType}>{RenterAddressTypes.PRIOR}</Text>
            <Text style={styles.AddressTag}>{`${t('application.address')} ${count++}`}</Text>
          </View>
          <View style={styles.NoAddressContainer}>
            <Text style={styles.NoAddress}>{t('renter.PriorAddress.iDoNotHave')}</Text>
          </View>
        </View>
      )}
    </View>
  );
};
