import { useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { capitalize } from 'lodash-es';

import { ReactComponent as EditIcon } from 'assets/svgs/Edit.svg';
import { ReactComponent as WorkIcon } from 'assets/svgs/WorkIcon.svg';
import { differenceInYearsAndMonths } from 'helpers/renterHelper';
import { formatDate } from 'helpers/user';
import { PresentSourceOfIncomeTypes, PrioEmploymentrCardProps } from 'shared/types/renterEmploymentInformation';
import { EditEmploymentTypes } from 'shared/types/renterEmploymentInformation';

import styles from './DescriptionCard.module.scss';
const DescriptionCard = ({
  data,
  isSelfEmployed,
  onEditClick,
  shouldShowEditIcon = true,
  shouldShowExtraInformation = true,
}: PrioEmploymentrCardProps): JSX.Element => {
  const isTypeOther = useMemo((): boolean => data.employmentSubType === EditEmploymentTypes.OTHERS, [data]);

  return (
    <>
      <div className={styles.descriptionCard}>
        <span className={styles.FloatingLabel}>{t('renter.paymentConfirmation.sourceOfIncome.source')}</span>
        <div className={styles.leftDiv}>
          <span className={styles.workIcon}>
            <WorkIcon />
          </span>

          <div className={styles.descriptionContainer}>
            {isTypeOther && <div className={classNames(styles.company, styles.upcaseText)}>{data.status}</div>}
            <div className={styles.Header}>
              {!isSelfEmployed && <div className={styles.company}>{data.company}</div>}
              {isSelfEmployed && <div className={styles.company}>{capitalize(data.businessName)}</div>}
              {isSelfEmployed && <div className={styles.EmploymentType}>{PresentSourceOfIncomeTypes.EMPLOYED}</div>}
            </div>

            <div className={styles.title}>{capitalize(data.title)}</div>
            {!isTypeOther && (
              <div className={styles.title}>{`${formatDate(data.jobStartDate)} - Present  ${differenceInYearsAndMonths(
                data.jobStartDate
              )}`}</div>
            )}
            {!isSelfEmployed && data.presentEmploymentVerifiers && data.presentEmploymentVerifiers.length > 0 && (
              <div className={styles.title}>
                <div>Verifier</div>
                {data.presentEmploymentVerifiers.map((verifier, index) => (
                  <div key={index}>
                    <div>
                      <strong>Full Name:</strong> {verifier.fullName}
                    </div>

                    <div>
                      <strong>Phone Number:</strong> {verifier.phoneNumber}
                    </div>
                    <div>
                      <strong>Email:</strong> {verifier.emailAddress}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {shouldShowEditIcon && (
          <div className={styles.EditButtonContainer}>
            <button
              className={styles.editButton}
              onClick={() => {
                if (onEditClick) {
                  onEditClick(data.id);
                }
              }}
              type="button"
            >
              <EditIcon />
            </button>
          </div>
        )}
      </div>
      {shouldShowExtraInformation && (
        <div className={styles.descriptionCard}>
          <div className={styles.descriptionContainer}>
            <div className={styles.title}>
              <span className={styles.label}>
                {isTypeOther || isSelfEmployed
                  ? t('renter.sourceOfIncome.other.monthlyGross')
                  : t('renter.sourceOfIncome.employed.monthlyGross')}
              </span>
              $ {data.monthlyGrossIncome}
            </div>
            {(isSelfEmployed || isTypeOther) && (
              <div className={styles.title}>
                <span className={styles.label}>{t('renter.sourceOfIncome.description')} </span>
                {capitalize(data.jobDescription)}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DescriptionCard;
