import { useCallback } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as NotificationIcon } from 'assets/svgs/notification_importantPinkBellIcon.svg';
import { INCOME_NAVBAR_TABS } from 'constants/reportConstants';

import styles from './IncomeReportNavbar.module.scss';
export const IncomeReportNavbar = ({ hasReportError = false }: { hasReportError?: boolean }): JSX.Element => {
  const { t } = useTranslation();
  const [params, setSearchParams] = useSearchParams();
  const activeTabName = params.get('incomeTab') || INCOME_NAVBAR_TABS[2].key;
  const tabSwitch = useCallback(
    (activeTab: string) => {
      const searchParams = new URLSearchParams(params.toString());

      searchParams.set('incomeTab', activeTab);
      setSearchParams(searchParams.toString());
    },
    [params, setSearchParams]
  );

  return (
    <Navbar expand="lg" className={styles.NavBar}>
      <div className={styles.Container}>
        {INCOME_NAVBAR_TABS.map((tab) => (
          <Nav
            key={tab.key}
            className={classNames(styles.NavLinks, { [styles.active]: activeTabName === tab.key })}
            onClick={() => tabSwitch(tab.key)}
          >
            {t(tab.label)}
            {hasReportError && INCOME_NAVBAR_TABS[2].key === tab.key && <NotificationIcon />}
          </Nav>
        ))}
      </div>
    </Navbar>
  );
};
