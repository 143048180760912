import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as DropDownArrow } from 'assets/svgs/ArrowForward.svg';
import { formatDateTime } from 'helpers/agentHelper';
import { formatPhoneNumber } from 'helpers/renterHelper';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { AddressVerifier, VerifierTypes } from 'shared/types/renterTypes';

import { ViewSubmittedData } from './ViewSubmittedData';

import styles from './VerifierAddressContainer.module.scss';

export const VerifierAddressContainer = ({
  verificationInformation,
  primaryVerifier,
}: {
  primaryVerifier: AddressVerifier;
  verificationInformation: AddressVerificationResponse;
}): JSX.Element => {
  const [isVerifierCollapsed, setIsVerifierCollapsed] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={styles.VerifierModalContainer}>
      <div className={styles.HeadingContainer}>
        <div className={styles.VerifierHeading}>{t('agent.verifier')}</div>
        <DropDownArrow
          onClick={() => setIsVerifierCollapsed(!isVerifierCollapsed)}
          width={12}
          className={classNames(styles.IconContainer, { [styles.IconArrowUp]: isVerifierCollapsed })}
        />
      </div>
      {!isVerifierCollapsed && (
        <>
          <Row className={styles.RenterInformation}>
            <Col sm={12} md={6} className={styles.LeftInformationContainer}>
              <ViewSubmittedData
                fullName={primaryVerifier.fullName || ''}
                emailAddress={primaryVerifier.emailAddress ?? ''}
                phoneNumber={formatPhoneNumber(primaryVerifier.phoneNumber || '')}
                verifierLabel={t('agent.verifier.submittedBy')}
                customContentClassName={styles.TopLeftRounded}
                hasPropertyRelation={false}
              />
            </Col>
            <Col sm={12} md={6} className={styles.RightInformationContainer}>
              <ViewSubmittedData
                submittedTime={formatDateTime(verificationInformation.submittedAt ?? '')}
                fullName={verificationInformation.submitVerifierFullName}
                emailAddress={verificationInformation.submitVerifierEmail}
                phoneNumber={formatPhoneNumber(verificationInformation.submitVerifierPhoneNumber)}
                propertyRelation={verificationInformation.submitVerifierRelationshipProperty}
                verifierLabel={t('agent.verifier.completedBy')}
                customContentClassName={styles.TopRightRounded}
                verifierType={verificationInformation.verifierType as VerifierTypes}
              />
            </Col>
          </Row>
          <div className={styles.EventHistoryContainer}>
            <DropDownArrow height={18} width={18} />
            <div className={styles.EventHistory}>{t('agent.verifier.eventHistory')}</div>
          </div>
        </>
      )}
      <div className={styles.HorizontalLine}></div>
    </div>
  );
};
