import { Dispatch } from 'react';

import AgentInviteModalBody from 'components/Agent/Organizations/OrganizationPage/AgentInviteModalBody/AgentInviteModalBody';

import AgentModal from '../../AgentModal/AgentModal';

interface OrganizationModalProps {
  isModalOpen: boolean;
  setIsInviteSuccess: Dispatch<React.SetStateAction<boolean>>;
  notInvitedTitle: string;
  onHideHandler: () => void;
}
const OrganizationModal = ({
  isModalOpen,
  setIsInviteSuccess,
  notInvitedTitle,
  onHideHandler,
}: OrganizationModalProps): JSX.Element => (
  <AgentModal show={isModalOpen} onHide={onHideHandler} title={notInvitedTitle}>
    <AgentInviteModalBody setInviteSent={setIsInviteSuccess} />
  </AgentModal>
);

export default OrganizationModal;
