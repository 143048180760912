import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { t } from 'i18next';

import indexStyles from 'components/Renter/RenterIndex.module.scss';
import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import { RenterVehicleSteps, VehiclesType } from 'constants/renterConstants';
import { VehiclesInformationType } from 'shared/types/renterVehicleInformation';

import styles from './VehicleType.module.scss';
const VehicleType = (): JSX.Element => {
  const [, setSearchParams] = useSearchParams();
  const { values, dirty: isDirty } = useFormikContext<VehiclesInformationType>();
  const onClickHandler = useCallback(() => {
    values.vehicleType && setSearchParams({ step: RenterVehicleSteps.FORM, type: values.vehicleType });
  }, [setSearchParams, values.vehicleType]);

  return (
    <section className={styles.vehicleType}>
      <FormCloseWarning isDirty={isDirty} />
      <h2 className={indexStyles.renterH2}>{t('renter.vehiclesInformation.vehicleType.question')}</h2>

      <FormikSelectField
        hasFloatingLabel={true}
        floatingLabel={t('renter.vehiclesInformation.vehicleType.floatingLabel')}
        name="vehicleType"
        options={VehiclesType}
        dropDownIconContainer={styles.dropDownFillArrow}
        placeholder={t('renter.vehiclesInformation.vehicleType.placeholder')}
        backgroundColor="#f7fafa"
      />

      <div className={classNames(indexStyles.renterButtonContainer, styles.buttonsContainer)}>
        <RCButton
          onClick={onClickHandler}
          className={classNames(indexStyles.renterButton, indexStyles.nextButton)}
          variant="outline"
          type="button"
          disabled={!values.vehicleType}
        >
          {t('renter.vehiclesInformation.form.continue')}
        </RCButton>
      </div>
    </section>
  );
};

export default VehicleType;
