import { Modal as BModal } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import { renterInviteSchema } from 'schema/agentSchema';

import { ReactComponent as InvitedIcon } from 'assets/svgs/InviteIconWhite.svg';
import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import Button from 'components/shared/Button/Button';
import Spinner from 'components/shared/Spinner/Spinner';
import { ApplicationRequestValues } from 'constants/applicationConstants';
import { retrieveAgentInviteModalTitleDescription } from 'helpers/agentHelper';
import { PHONE_NUMBER_DIAL_CODE_LIMIT } from 'shared/constants';
import { InviteApplicantModalViewProps } from 'shared/types/agentTypes';

import ContactInformation from '../ContactInformation/ContactInformation';
import PropertyType from '../PropertyType/PropertyType';
import ShareLink from '../ShareLink/ShareLink';

import styles from './InviteApplicantModalView.module.scss';

const InviteApplicantModalView = ({
  isModalOpen,
  onHide,
  handleSubmit,
  handlePropertyChange,
  inviteLink,
  isLoading,
  formattedData,
  inviteRenterInitialValues,
  inviteType,
  propertyAddress,
}: InviteApplicantModalViewProps): JSX.Element => {
  const { title, description } = retrieveAgentInviteModalTitleDescription(inviteType);

  return (
    <AgentModal
      show={isModalOpen}
      onHide={onHide}
      size="lg"
      buttonText={t('application.cancel')}
      title={t(title)}
      titleDescription={t(description)}
      customContainerClassName={styles.MainContainer}
    >
      <div className={styles.container}>
        <Formik initialValues={inviteRenterInitialValues} onSubmit={handleSubmit} validationSchema={renterInviteSchema}>
          {({ dirty: isDirty, isValid, values }) => (
            <Form>
              <BModal.Body>
                <div className={styles.innerContainer}>
                  <PropertyType
                    publishedList={formattedData}
                    onChangeHandler={handlePropertyChange}
                    tooltipText={t('agent.inviteApplicant.noAdditionalFormsAre')}
                    inviteType={inviteType}
                    propertyAddress={propertyAddress}
                  />

                  <ContactInformation inviteType={inviteType} />
                  <div className={styles.buttonContainer}>
                    <Button
                      className={styles.button}
                      type="submit"
                      disabled={
                        !(
                          isDirty &&
                          isValid &&
                          (values.renterEmail ||
                            (values.renterPhone && values.renterPhone.length > PHONE_NUMBER_DIAL_CODE_LIMIT))
                        )
                      }
                    >
                      <div className={styles.sendInviteButton}>
                        <div>{isLoading ? <Spinner /> : <InvitedIcon />}</div>
                        <div>{t('agent.inviteApplicant.sendRequest')}</div>
                      </div>
                    </Button>
                  </div>
                  <ShareLink
                    inviteLink={inviteLink}
                    isIDVLink={inviteType === ApplicationRequestValues.VERIFY_IDENTITY}
                  />
                </div>
              </BModal.Body>
              <BModal.Footer>
                <Button className={styles.button} variant="outline" disabled={isLoading} type="button" onClick={onHide}>
                  {t('application.done')}
                </Button>
              </BModal.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </AgentModal>
  );
};

export default InviteApplicantModalView;
