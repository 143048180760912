import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';

import { ReactComponent as ForwardArrow } from 'assets/svgs/ArrowForward.svg';
import { ReactComponent as DocumentIcon } from 'assets/svgs/DocumentIcon.svg';
import { ReactComponent as EmailIcon } from 'assets/svgs/EmailGrey.svg';
import { VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

import DocumentItem from '../../../shared/Documents/DocumentItem/DocumentItem';
import { ResidencyQuestions } from '../ResidencyQuestions/ResidencyQuestions';
import { VerificationPopover } from '../VerificationPopover/VerificationPopover';

import styles from './QuestionsDropDown.module.scss';

interface Props {
  verificationInformation: AddressVerificationResponse;
  renterDetails: RenterProfileInformationProps;
  type: VerifierURLType;
}

export const QuestionsDropDown = ({ verificationInformation, renterDetails, type }: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const isTypeVor = type === VerifierURLType.VOR;

  return (
    <div>
      <div className={styles.Heading}>
        <div>
          {isTypeVor
            ? t('renter.relationship.verificationOfResidency')
            : t('agent.applicants.renterResume.sourceOfIncome.verification')}
        </div>
        <VerificationPopover
          type={type}
          id={
            isTypeVor
              ? Number(verificationInformation.renterAddressInformation?.addressId)
              : Number(verificationInformation.renterEmploymentInformation?.presentEmploymentId)
          }
          renterDetails={renterDetails}
        />
      </div>
      <div className={styles.Container}>
        <div className={styles.Title}>
          <ForwardArrow
            width={18}
            height={18}
            onClick={() => setIsExpanded(!isExpanded)}
            className={classNames(styles.DropDownIcon, {
              [styles.RotateDown]: isExpanded,
              [styles.RotateUp]: !isExpanded,
            })}
          />
          <EmailIcon width={24} height={24} />
          <div>{`${
            isTypeVor
              ? t('agent.applicants.renterResume.sourceOfIncome.verification.action')
              : t('agent.applicants.renterResume.action.requested.VOE')
          } ${renterDetails.firstName} ${renterDetails.lastName}`}</div>
        </div>
        {isExpanded && (
          <div className={styles.QuestionsContainer}>
            {!isEmpty(verificationInformation?.submitVerifierDocument) && (
              <div className={styles.AttachmentRow}>
                {verificationInformation.submitVerifierDocument.map((document, index) => (
                  <DocumentItem
                    key={`attachment-${document.fileLink}`}
                    documentName={document.fileName}
                    documentLink={document.fileLink}
                    icon={<DocumentIcon />}
                    index={index + 1}
                  />
                ))}
              </div>
            )}
            <ResidencyQuestions verificationInformation={verificationInformation} />
          </div>
        )}
      </div>
    </div>
  );
};
