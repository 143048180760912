import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import {
  applicantRenterRole,
  applicationPropertyLink,
  isLoading,
  renterApplication,
  selectRenterProfileInformation,
} from 'redux/selectors/renterSelector';

import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import RCButton from 'components/shared/Button/Button';
import RelationshipInfoBox from 'components/shared/RelationshipInfoBox/RelationshipInfoBox';
import { renterRestrictions } from 'constants/restrictionConstants';
import { findApplication } from 'helpers/agentHelper';
import { sendGuarantorInvite } from 'helpers/guarantor';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { GuarantorInitialValuesProps } from 'shared/types/renterTypes';

import RenterContainer from '../../RenterContainer';
import RenterIconInfoContainer from '../../RenterIconInfoContainer';

import indexStyles from '../../../RenterIndex.module.scss';
import styles from './GuarantorSummary.module.scss';
const GuarantorSummary = (): JSX.Element => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const { values, setFieldValue, errors } = useFormikContext<GuarantorInitialValuesProps>();
  const isLoadingInformation = useSelector(isLoading);
  const renterRole = useSelector(applicantRenterRole);
  const applications = useSelector(renterApplication);
  const { adults } = applications.find((app) => app.id === Number(applicationId)) || {};
  const navigate = useNavigate();
  const applicationInformation = useSelector(renterApplication);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const { propertyId } = useSelector(applicationPropertyLink);
  const maxHeight = useDeviceHeight(220);
  const currentApplication = useMemo(
    () => findApplication(applicationInformation, Number(applicationId)),
    [applicationId, applicationInformation]
  );

  useEffect(() => {
    if (renterRestrictions[renterRole].guarantor) {
      navigate(renterRoutes.renterRelation.replace(':applicationId', `${applicationId}`));
    }
  }, [applicationId, navigate, renterRole]);
  const areAllValuesPresent = useMemo(() => {
    if (values.relation.emailAddress && values.relation.phoneNumber && values.relation.relationship) {
      return true;
    }

    return false;
  }, [values]);
  const onClickHandler = useCallback(() => {
    const guarantorId = currentApplication?.guarantor?.id;

    if (renterDetails.isPaymentSucceeded && guarantorId && !!applicationId) {
      sendGuarantorInvite(guarantorId, Number(applicationId), propertyId);
    }

    if (adults?.length || renterRestrictions[renterRole].adults) {
      navigate(renterRoutes.renterRelationAdultConfirmation.replace(':applicationId', `${applicationId}`));
    } else {
      navigate(renterRoutes.renterRelation.replace(':applicationId', `${applicationId}`));
    }
  }, [
    currentApplication?.guarantor?.id,
    renterDetails.isPaymentSucceeded,
    applicationId,
    adults?.length,
    renterRole,
    propertyId,
    navigate,
  ]);
  const isButtonDisabled = useMemo(
    (): boolean => Boolean(!areAllValuesPresent || !isEmpty(errors)),
    [areAllValuesPresent, errors]
  );

  // populating the fields if component is refreshed.This is only until API's are not done
  useEffect(() => {
    if (
      !values.relationship &&
      !values.phoneNumber &&
      !values.emailAddress &&
      values.relation.emailAddress &&
      values.relation.phoneNumber &&
      values.relation.relationship
    ) {
      setFieldValue('emailAddress', values.relation.emailAddress);
      setFieldValue('phoneNumber', values.relation.phoneNumber);
      setFieldValue('relationship', values.relation.relationship);
    }
  }, [setFieldValue, values]);

  if (isLoadingInformation) {
    return <div />;
  }

  return (
    <RenterContainer>
      <section className={classNames(indexStyles.renterBody, styles.guarantorSummaryContainer)}>
        {/* icons list */}
        <div
          className={styles.guarantorSummary}
          style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflow: 'auto' }}
        >
          <RenterIconInfoContainer />
          <div className={indexStyles.renterContentContainer}>
            <p className={indexStyles.renterH2}>{t('renter.guarantorSummary.confirmAndInviteGuarantor')}</p>
            {areAllValuesPresent && (
              <div className={styles.informationBox}>
                <RelationshipInfoBox
                  relationEmail={values.relation.emailAddress ?? ''}
                  relationLabel={t('renter.guarantorInformationForm.guarantor')}
                  relationPhoneNumber={values.relation.phoneNumber ?? ''}
                  relationType={values.relation.relationship ?? ''}
                />
              </div>
            )}
            <div className={styles.ownerInformation}>
              <InformationIcon className={styles.ownerInformationIcon} />
              <p className={styles.ownerInformationText}>{t('renter.guarantorSummary.invitesAreSentViaEmail')}</p>
            </div>
            <div className={classNames(indexStyles.renterButtonContainer)}>
              <RCButton
                onClick={onClickHandler}
                disabled={isButtonDisabled}
                className={classNames(indexStyles.renterButton, indexStyles.nextButton)}
                variant="outline"
              >
                {t('renter.guarantorSummary.confirm')}
              </RCButton>
            </div>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default GuarantorSummary;
