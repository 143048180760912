import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { renterApplication, selectedAgent } from 'redux/selectors/renterSelector';
import { getAgentDetails, setIsRenterLoading } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { desiredMoveInDateSchema } from 'schema/renterSchema';

import indexStyles from 'components/Renter/RenterIndex.module.scss';
import { InitialMoveInDate } from 'constants/renterConstants';
import { StepRouteType } from 'helpers/address';
import { getAcceptedInvitations } from 'services/renterService';
import { renterRoutes } from 'shared/routes';
import { InviteDataKeys, InviteType } from 'shared/types/renterApplication';

import RenterContainer from '../components/RenterContainer';

import { AgentGeneral, AgentSpecific, PropertySpecific } from '.';

export const RenterPropertySelection = (): JSX.Element => {
  const [params, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [inviteType, setInviteType] = useState<string>(params.get(InviteDataKeys.INVITE_TYPE) ?? '');
  const inviteCode = params.get(InviteDataKeys.INVITE_CODE) ?? '';
  const renterApplications = useSelector(renterApplication);
  const inviteAgent = useSelector(selectedAgent);

  useEffect(() => {
    if (inviteCode && isEmpty(inviteAgent)) {
      dispatch(getAgentDetails({ inviteCode }));
    }
  }, [dispatch, inviteAgent, inviteCode]);

  useEffect(() => {
    if (!inviteType) {
      dispatch(setIsRenterLoading(true));

      getAcceptedInvitations()
        .then((response: AxiosResponse) => {
          if (!response.data.payload.length) {
            navigate(
              renterRoutes.generateViewRenterCriteriaUrl(`${renterApplications[renterApplications.length - 1].id}`)
            );
          }

          const firstInvite = response.data.payload[0];

          setInviteType(firstInvite.inviteType);
          setSearchParams({ inviteType: firstInvite.inviteType, inviteCode: firstInvite.inviteCode });
        })
        .finally(() => dispatch(setIsRenterLoading(false)));
    }
  }, [dispatch, inviteType, navigate, renterApplications, setSearchParams]);

  const propertySelectionComponents: StepRouteType = {
    [InviteType.PROPERTY_SPECIFIC]: <PropertySpecific />,
    [InviteType.AGENT_GENERAL]: <AgentGeneral />,
    [InviteType.AGENT_SPECIFIC]: <AgentSpecific />,
    [InviteType.PROPERTY_GENERAL]: <PropertySpecific />,
  };

  return (
    <Formik
      initialValues={InitialMoveInDate}
      validationSchema={desiredMoveInDateSchema}
      onSubmit={() => console.log('')}
    >
      <RenterContainer>
        <section className={indexStyles.renterBody}>{propertySelectionComponents[inviteType]}</section>
      </RenterContainer>
    </Formik>
  );
};
