import { ErrorMessage, useField } from 'formik';

import CustomErrorMessage from '../CustomErrorMessage/ErrorMessage';

import TextField, { IInputProps } from './TextField';

const FormikField = (props: IInputProps & { name: string }): JSX.Element => {
  const { name } = props;
  const [field, meta] = useField(name);

  return (
    <div className="formik-field">
      <TextField {...props} {...field} isError={meta.touched && !!meta.error} />
      <ErrorMessage render={(errorMessage) => <CustomErrorMessage message={errorMessage} />} name={name} />
    </div>
  );
};

export default FormikField;
