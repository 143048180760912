import { useTranslation } from 'react-i18next';

import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import RCButton from 'components/shared/Button/Button';
import { formatAmount } from 'helpers/user';
import { IncomeCapsuleProps } from 'shared/types/applicantsType';

import styles from './IncomeCapsule.module.scss';
export const IncomeCapsule = ({ income, hasIncomeStatus, onIncomeClickHandler }: IncomeCapsuleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.CapsuleContainer}>
      {income ? (
        <div className={styles.Income}>{`$${formatAmount(income)}`}</div>
      ) : (
        <div className={styles.EmptyState}>{'___'}</div>
      )}
      <div className={styles.LabelContainer}>
        <RCButton variant="outline" onClick={onIncomeClickHandler} className={styles.CapsuleLabel}>
          {t('agent.applications.applicant.occupant.income')}
        </RCButton>
        {!!hasIncomeStatus && (
          <AgentPills
            pillContainerClassName={styles.PillContainer}
            pillsData={[{ heading: t('rental.historyInformation.pending'), type: PillTypes.WARNING }]}
          />
        )}
      </div>
    </div>
  );
};
