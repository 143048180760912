import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Form } from 'formik';
import { useFormikContext } from 'formik';
import { isSaving } from 'redux/selectors/renterSelector';

import { ReactComponent as InfoIcon } from 'assets/svgs/InformationIcon.svg';
import Button from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikField from 'components/shared/TextField/FormikTextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { RenterInformationModal } from 'shared/types/renterInformation';
import { RenterProfileInformationInitialValue } from 'shared/types/renterTypes';

import RenterModal from '../RenterModal/RenterModal';

import styles from './RenterInfoDetailsModal.module.scss';

const RenterInfoDetailsModal = ({
  shouldShowModal,
  onCloseModal,
  onSubmit,
  dateOfBirth,
  documentNumber,
  documentType,
  handleStripeInfoModal = () => null,
}: RenterInformationModal): JSX.Element => {
  const { t } = useTranslation();
  const { errors, dirty: isDirty } = useFormikContext<typeof RenterProfileInformationInitialValue>();
  const isSavingInformation = useSelector(isSaving);
  const isButtonDisabled = useMemo(
    (): boolean => (errors.firstName || errors.lastName || !isDirty || isSavingInformation ? true : false),
    [errors, isSavingInformation, isDirty]
  );

  return (
    <RenterModal show={shouldShowModal} onHide={onCloseModal} isDirty={isDirty}>
      <div className={styles.RenterInfoDetailsModal}>
        <div className={styles.title}>{t('renter.PhotoIdInformation.editDetails')}</div>
        <div className={styles.heading}>
          {t('renter.PhotoIdInformation.makeChanges')}&nbsp;
          <span>{t('renter.PhotoIdInformation.personal')}</span>&nbsp;
          {t('renter.PhotoIdInformation.information')}
        </div>
        <div>
          <Form>
            <div className={styles.formContainer}>
              <FormikField name="firstName" label={t('renter.PhotoIdInformation.firstName')} />
              <FormikField name="middleName" label={t('renter.PhotoIdInformation.middleName')} />
              <FormikField name="lastName" label={t('renter.PhotoIdInformation.lastName')} />
            </div>

            <div
              className={styles.borderbox}
              onClickCapture={process.env.REACT_APP_ENV !== 'production' ? handleStripeInfoModal : undefined}
            >
              <div className={styles.infoContainer}>
                <div>
                  <div className={styles.dateHeading}>{dateOfBirth}</div>
                  <div className={styles.subHeading}>{t('renter.CollectedInformation.dateOfBirth')}</div>
                </div>
                <div className={styles.information}>
                  <div className={styles.flexContainer}>
                    <div className={styles.dateHeading}>{documentType}</div>
                    <div className={styles.subHeading}>{t('renter.CollectedInformation.idType')}</div>
                  </div>
                  <div className={styles.flexContainer}>
                    <div className={styles.dateHeading}>{documentNumber}</div>
                    <div className={styles.subHeading}>{t('renter.CollectedInformation.idNumber')}</div>
                  </div>
                </div>
              </div>
              <div>
                <Tooltip icon={<InfoIcon />} text={t('renter.PhotoIdInformation.editID')} />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              <Button
                variant="outline"
                className={styles.renterPhotoInformationButton}
                onClick={onSubmit}
                disabled={isButtonDisabled}
                type="submit"
              >
                {t('renter.PhotoIdInformation.savechanges')}
              </Button>
            </div>
            <FormCloseWarning isDirty={isDirty} />
          </Form>
        </div>
      </div>
    </RenterModal>
  );
};

export default RenterInfoDetailsModal;
