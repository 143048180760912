/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

//TODO: Temporarily disabled some non-interactive features and will re-enable later.
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { t } from 'i18next';
import { isEmpty, startCase } from 'lodash-es';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import defaultImage from 'assets/images/defaultProfilePicture.png';
import { ReactComponent as DropDownArrow } from 'assets/svgs/ArrowDownWithBackgroundColor.svg';
// import { ReactComponent as DotsIcon } from 'assets/svgs/HorizontalDotsIcon.svg';
import { getLastActiveStatus } from 'helpers/organizationHelper';
import { pluralize } from 'helpers/user';
import { AgentUserRoles } from 'shared/types/agentInvite';
import { UserInformation } from 'shared/types/organizationTypes';

// import Checkbox from '../Checkbox/Checkbox';
import styles from './UserInfoBox.module.scss';
export interface UserInformationProps extends UserInformation {
  hoverIndex: number;
}

export interface UserInfoBoxProps {
  members: UserInformation[];
}

const UserInfoBox = ({ members }: UserInfoBoxProps): JSX.Element => {
  /**
   * Array to track the visibility of guests for each user in the members list.
   * Each element corresponds to a user, and the boolean value indicates whether
   * guests are currently visible for that user.
   */
  const currentOrganization = useSelector(selectedOrganization);
  const [isGuestVisibleForUser, setIsGuestVisibilityForUser] = useState<boolean[]>(
    Array.from({ length: members.length }, () => false)
  );
  const [isHoveredArray, setIsHoveredArray] = useState(new Array(members.length).fill(false));
  const [isCheckedArray, setIsCheckedArray] = useState(new Array(members.length).fill(false));
  const setShowGuestArray = useCallback(
    (index: number) => {
      const tempArray = [...isGuestVisibleForUser];

      tempArray[index] = !tempArray[index];
      setIsGuestVisibilityForUser(tempArray);
    },
    [isGuestVisibleForUser]
  );
  const getMemberGuests = useMemo(
    () =>
      (member: UserInformation): UserInformation[] =>
        member?.guests ?? [],
    []
  );
  const handleMouseEnter = useCallback((index: number): void => {
    setIsHoveredArray((prevIsHoveredArray) => {
      const updatedHoverArray = [...prevIsHoveredArray];

      updatedHoverArray[index] = true;

      return updatedHoverArray;
    });
  }, []);
  const handleMouseLeave = useCallback((index: number): void => {
    setIsHoveredArray((prevIsHoveredArray) => {
      const updatedHoverArray = [...prevIsHoveredArray];

      updatedHoverArray[index] = false;

      return updatedHoverArray;
    });
  }, []);
  const handleCheckboxChange = useCallback((index: number): void => {
    setIsCheckedArray((prevIsCheckedArray) => {
      const updatedCheckedArray = [...prevIsCheckedArray];

      updatedCheckedArray[index] = !updatedCheckedArray[index];

      return updatedCheckedArray;
    });
  }, []);
  const displayUserInformation = useMemo(
    () =>
      function ({
        firstName,
        lastName,
        role,
        limited,
        acceptedInvite,
        lastActive,
        profileImage,
        email,
        guests,
      }: // hoverIndex,
      UserInformationProps): JSX.Element {
        return (
          <div className={styles.userInfoContainer}>
            <div className={styles.headerContainer}>
              <div className={styles.TeamInformation}>
                {role.toUpperCase() !== AgentUserRoles.GUEST_AGENT && (
                  <div className={styles.checkBoxContainer}>
                    {/* <Checkbox
                      className={
                        isCheckedArray[hoverIndex] || isHoveredArray[hoverIndex]
                          ? styles.hoveredStyle
                          : styles.nonHoveredStyle
                      }
                      checked={isCheckedArray[hoverIndex]}
                      onChange={() => handleCheckboxChange(hoverIndex)}
                    /> */}
                  </div>
                )}
                <div
                  className={classNames(styles.AgentTeam, {
                    [styles.GuestAgentTeam]: role.toUpperCase() === AgentUserRoles.GUEST_AGENT,
                  })}
                >
                  {currentOrganization.name}
                </div>
              </div>
              {guests && (
                <div className={styles.userOptionsContainer}>{/* <DotsIcon className={styles.userOptions} /> */}</div>
              )}
            </div>
            <div className={styles.UserInformation}>
              <div className={styles.personalInformation}>
                <div className={styles.avatar}>
                  {role.toUpperCase() !== AgentUserRoles.GUEST_AGENT && (
                    <img className={styles.profileImage} src={profileImage ?? defaultImage} alt={t('agent.photo')} />
                  )}
                </div>
                <div className={styles.nameContainer}>
                  <div className={styles.agentName}>{startCase(`${firstName ?? ''} ${lastName ?? ''}`) ?? ''}</div>
                  <div className={styles.agentEmail}>{email}</div>
                </div>
              </div>
              <div className={styles.roleContainer}>
                {role.toUpperCase() !== AgentUserRoles.GUEST_AGENT && (
                  <div className={styles.agentRole}>
                    {role} {limited && <span className={styles.roleStatus}>{t('agent.userInfoBox.limited')}</span>}
                  </div>
                )}
              </div>
              <div className={styles.activeContainer}>
                <div className={styles.lastActive}>{getLastActiveStatus(lastActive)}</div>
                {!acceptedInvite && !role.toUpperCase().includes(AgentUserRoles.OWNER) && (
                  <div className={styles.activeStatus}>{t('agent.userInfoBox.invited')}</div>
                )}
              </div>
            </div>
          </div>
        );
      },
    [currentOrganization.name]
  );

  return (
    <div className={styles.container}>
      {(Array.isArray(members) ? members : [members]).map((user, index) => (
        <div
          key={`main-${user.email + index}`}
          className={styles.contentContainer}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
        >
          <div key={`${user.email + index}`} className={styles.userContainer}>
            {displayUserInformation({ ...user, hoverIndex: index })}
            {!isEmpty(user.guests) && (
              <div className={styles.guestsContainerMain}>
                <div className={styles.guestToggler}>
                  <DropDownArrow
                    onClick={() => setShowGuestArray(index)}
                    className={classNames({ [styles.toggleButtonDown]: isGuestVisibleForUser[index] })}
                  />
                </div>
                {!isEmpty(user.guests) && (
                  <>
                    <div className={styles.totalGuests}>
                      {pluralize(user.guests?.length, t('agent.userInfoBox.guest')).split(' ')[1]}
                    </div>
                    <div className={styles.totalGuestsCount}>{user.guests?.length}</div>
                  </>
                )}
              </div>
            )}
          </div>

          {!isEmpty(user.guests) &&
            isGuestVisibleForUser[index] &&
            getMemberGuests(user)?.map((guest, guestIndex) => (
              <div key={`${guest.email + guestIndex}`} className={styles.guestsContainer}>
                <div className={classNames(styles.guestsList, { [styles.FirstGuest]: guestIndex === 0 })}>
                  <div
                    className={classNames(styles.branchContainer, {
                      [styles.bottomPadding]: guestIndex === (user.guests?.length ?? 0) - 1,
                    })}
                  >
                    <div className={styles.verticalLine}></div>
                    <div className={styles.horizontalLine}></div>
                  </div>
                  <div
                    className={classNames(styles.guestsBox, {
                      [styles.NotFirstGuest]: guestIndex > 0,
                      [styles.FirstGuestBox]: guestIndex === 0,
                      [styles.LastGuestBox]: index === getMemberGuests(user)?.map.length - 1,
                    })}
                  >
                    {displayUserInformation({ ...guest, hoverIndex: guestIndex })}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default UserInfoBox;
