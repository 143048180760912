import { Dispatch, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { categoryDescriptions, housingProviderCategories } from 'pages/AgentPofileForm/types';

import { ProfileType } from './components/ProfileType';

import styles from './ProfileScreen.module.scss';

type Props = {
  setProfileType: Dispatch<React.SetStateAction<string>>;
};

export const ProfileScreen = ({ setProfileType }: Props): JSX.Element => {
  const { t } = useTranslation();
  const myRef = useRef<HTMLDivElement>(null);
  const myRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const parent = myRef2.current;
    const child = myRef.current;

    if (!parent || !child) {
      return;
    }

    const handleTouchMove = (e: TouchEvent): void => {
      const isBottom = child.scrollHeight - child.scrollTop === child.clientHeight;

      if (isBottom) {
        parent.scrollTop += e.touches[0].clientY - parent.scrollTop;
      }
    };

    child.addEventListener('touchmove', handleTouchMove);

    return () => {
      child.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  return (
    <div className={styles.Container} ref={myRef2}>
      <div className={styles.Description}>{t('agent.profileType.description')}</div>
      <div className={styles.Heading}>{t('agent.profileType.heading')}</div>
      <div className={styles.Categories} ref={myRef}>
        {housingProviderCategories.map((category, index) => (
          <ProfileType
            description={t(categoryDescriptions[category.value])}
            category={category}
            key={`housing-provider-${index}`}
            setProfileType={setProfileType}
          />
        ))}
      </div>
    </div>
  );
};
