import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { EmploymentInformationProps } from 'shared/types/reportTypes';

import { ReportHeading } from '../ReportHeading/ReportHeading';
import { EmploymentReport } from '../SharedReports/EmploymentReport/EmploymentReport';
import { EmptyReportRecord } from '../SharedReports/EmptyReportRecord/EmptyReportRecord';

import styles from './EmploymentInformation.module.scss';

export const EmploymentInformation = ({
  employmentInformation,
  isRenterFlow = false,
}: EmploymentInformationProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <ReportHeading
        heading={t('agent.creditReport.employmentInformation')}
        hasCounter={!!employmentInformation?.length}
        counter={employmentInformation?.length ?? 0}
      />
      {!!employmentInformation?.length ? (
        <Row>
          {employmentInformation.map((employmentInformationItem, index) => (
            <Col
              className={styles.EmploymentInformation}
              sm={12}
              md={isRenterFlow ? 12 : 6}
              lg={isRenterFlow ? 6 : 4}
              key={`employment-information-${employmentInformationItem}-${index}`}
            >
              <EmploymentReport employmentReport={employmentInformationItem} />
            </Col>
          ))}
        </Row>
      ) : (
        <EmptyReportRecord />
      )}
    </div>
  );
};
