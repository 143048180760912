import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { googleAuth } from 'redux/slices/authSlice';
import { setUserType } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';

import { StatusCodes } from 'shared/constants';
import { renterRoutes, routes } from 'shared/routes';
import { UserType } from 'shared/types/authType';
// This page is called by the backend after user is authenticated by google
const RedirectionPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const onSuccess = (userType: string, status: number): void => {
    dispatch(setUserType(userType));

    if (status === StatusCodes.CREATED) {
      userType === UserType.AGENT ? navigate(routes.applicants) : navigate(renterRoutes.renter);
    } else if (status === StatusCodes.OK) {
      userType === UserType.AGENT ? navigate(routes.applicants) : navigate(renterRoutes.renter);
    }
  };
  const onError = (): void => navigate(routes.signup);
  const stateParam = searchParams.get('state');
  const role = stateParam ? JSON.parse(stateParam) : {};

  useEffect(() => {
    const code = searchParams.get('code');

    if (!code) {
      navigate(routes.login);

      return;
    }

    dispatch(googleAuth({ values: { code, ...role }, onError }))
      .unwrap()
      .then((response) => {
        const {
          data: {
            payload: { userType },
          },
        }: AxiosResponse = response;

        // on signup we don't get userType in response
        onSuccess(userType || role.userType, response.status);
      });
  });

  return (
    <div className="d-flex justify-content-center mt-5">
      <h3>
        Signing you in... <Spinner />
      </h3>
    </div>
  );
};

export default RedirectionPage;
