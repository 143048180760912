import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { ReactComponent as PenIcon } from 'assets/svgs/Edit.svg';
import GuarantorEditModal from 'components/Renter/components/Guarantor/GuarantorEditModal/GuarantorEditModal';
import { getFormatedPhoneNumber } from 'helpers/guarantor';
import { RelationshipInfoBoxProps } from 'shared/types/relationshipInfoBox';
import { GuarantorInitialValuesProps } from 'shared/types/renterTypes';

import styles from './RelationshipInfoBox.module.scss';

const RelationshipInfoBox = ({
  relationEmail,
  relationLabel,
  relationPhoneNumber,
  relationType,
}: RelationshipInfoBoxProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<GuarantorInitialValuesProps>();
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onClickHandler = useCallback((): void => {
    setIsModalVisible(true);
  }, []);
  const onHideHandler = useCallback((): void => {
    setFieldValue('emailAddress', relationEmail);
    setFieldValue('phoneNumber', relationPhoneNumber);
    setFieldValue('relationship', relationType);

    setIsModalVisible(false);
  }, [relationEmail, relationPhoneNumber, relationType, setFieldValue]);

  return (
    <div className={styles.infoBoxContainer}>
      <div className={styles.infoBoxLabel}>{relationLabel}</div>
      <div className={styles.informationContainer}>
        <div className={styles.editIconContainer}>
          <PenIcon className={styles.PenIcon} onClick={onClickHandler} />
        </div>
        <div className={styles.relationshipInformation}>
          <div className={styles.emailAddress}>{relationEmail}</div>
          <div className={styles.fieldsContainer}>
            <div className={styles.relationInformation}>
              <span className={styles.information}>{relationType}</span>
              <span className={styles.informationType}>{t('renter.guarantorInformationForm.relationship')}</span>
            </div>
            <div className={styles.relationInformation}>
              <span className={styles.information}>{getFormatedPhoneNumber(relationPhoneNumber)}</span>
              <span className={styles.informationType}>{t('renter.guarantorInformationForm.phone')}</span>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <GuarantorEditModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          onHideHandler={onHideHandler}
          guarantorInformation={values.relation}
        />
      )}
    </div>
  );
};

export default RelationshipInfoBox;
