import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FullStory, init as initFullStory } from '@fullstory/browser';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { userType } from 'redux/selectors/authSelector';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';

import { UserType } from 'shared/types/authType';

const isProduction = process.env.REACT_APP_ENV === 'production';

export const useFullStory = (): void => {
  const fullStoryRef = useRef(false);
  const agentProfile = useSelector(selectAgentProfileInformation);
  const renterProfile = useSelector(selectRenterProfileInformation);
  const authenticatedUserType = useSelector(userType);

  useEffect(() => {
    if (!isProduction) {
      return;
    }

    initFullStory({ orgId: `${process.env.REACT_APP_FULL_STORY_ORGANIZATION_ID}` });
    fullStoryRef.current = true;
  }, []);

  useEffect(() => {
    if (!isProduction || !fullStoryRef) {
      return;
    }

    if (authenticatedUserType === UserType.AGENT && agentProfile.userId && agentProfile.email) {
      FullStory('setIdentity', { uid: `${agentProfile.userId}`, properties: { role: UserType.AGENT } });
      FullStory('setProperties', {
        type: 'user',
        properties: {
          displayName: agentProfile.firstName
            ? `${agentProfile.firstName} ${agentProfile.lastName}`
            : agentProfile.email.split('@')[0],
          email: agentProfile.email,
        },
      });
    } else if (authenticatedUserType === UserType.RENTER && renterProfile.userId) {
      FullStory('setIdentity', { uid: `${renterProfile.userId}`, properties: { role: UserType.AGENT } });
      FullStory('setProperties', {
        type: 'user',
        properties: {
          displayName: renterProfile.firstName
            ? `${renterProfile.firstName} ${renterProfile.lastName}`
            : renterProfile.email?.split('@')[0],
          email: renterProfile.email,
        },
      });
    }
  }, [agentProfile, authenticatedUserType, renterProfile]);
};
