import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Field, Form, useFormikContext } from 'formik';
import { isSaving } from 'redux/selectors/renterSelector';

import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import Button from 'components/shared/Button/Button';
import CustomRangeDatePicker from 'components/shared/CustomRangeDatePicker/CustomRangeDatePicker';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import FormikField from 'components/shared/TextField/FormikTextField';
import { defaultDate, endDate } from 'constants/renterConstants';
import { INITIAL_DATE_VALUE, RenterEmploymentsInitialValue } from 'shared/types/renterEmploymentInformation';
import { CustomEditModalProps } from 'shared/types/renterTypes';

import indexStyles from '../../../RenterIndex.module.scss';
import renterEmployStyles from './RenterPresentForm.module.scss';
const RenterEmployed = ({ heading, isForEditing = false }: CustomEditModalProps): JSX.Element => {
  const { t } = useTranslation();
  const isSavingInformation = useSelector(isSaving);
  const { values, dirty: isDirty, isValid } = useFormikContext<typeof RenterEmploymentsInitialValue>();
  const isButtonDisabled = useMemo(
    (): boolean => (!isValid || !isDirty || isSavingInformation ? true : false),
    [isValid, isSavingInformation, isDirty]
  );

  return (
    <section>
      <Form>
        <div className={renterEmployStyles.container}>
          <div
            className={classNames(renterEmployStyles.linkedinHeading, heading ? renterEmployStyles.centeralized : '')}
          >
            {heading ? (
              <span>{heading}</span>
            ) : (
              <span>
                {t('renter.customInformation.heading1')}
                <span className={renterEmployStyles.textContainer}> {t('renter.sourceOfIncome.employed')} </span>
                {t('renter.customInformation.heading2')}
              </span>
            )}
          </div>

          <div>
            <div className={renterEmployStyles.userFields}>
              <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.company')}</span>
              <FormikField
                className={renterEmployStyles.inputBackgroundColor}
                name="company"
                placeholder={t('renter.sourceOfIncome.companyName')}
              />
            </div>

            <div className={renterEmployStyles.userFields}>
              <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.title')}</span>
              <FormikField
                className={renterEmployStyles.inputBackgroundColor}
                name="title"
                placeholder={t('renter.sourceOfIncome.yourRole')}
              />
            </div>

            <div className={renterEmployStyles.datePicker}>
              <Field
                as={CustomRangeDatePicker}
                leftCalendarName="jobStartDate"
                rightCalendarName="jobEndDate"
                leftCalenderStartDate={INITIAL_DATE_VALUE}
                leftCalenderEndDate={endDate}
                rightCalenderStartDate={INITIAL_DATE_VALUE}
                rightCalenderEndDate={endDate}
                floatingLabelStyling={renterEmployStyles.label}
                isToPresent
                defaultSelected={defaultDate}
              />
            </div>

            <div className={renterEmployStyles.userFields}>
              <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.monthlyGross')}</span>
              {!!values?.monthlyGrossIncome && <span className={renterEmployStyles.userGrossDollar}>$</span>}
              <FormikField
                className={classNames(renterEmployStyles.inputBackgroundColor, {
                  [renterEmployStyles.grossIncome]: !!values?.monthlyGrossIncome,
                })}
                name="monthlyGrossIncome"
                placeholder="$ 00,000.00"
                type="number"
                onWheel={(event) => event.currentTarget.blur()}
              />
            </div>

            <div className={renterEmployStyles.userFields}>
              <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.supervisor')}</span>
              <FormikField
                className={renterEmployStyles.inputBackgroundColor}
                name={isForEditing ? 'presentEmploymentVerifiers[0].fullName' : 'fullName'}
                placeholder={t('renter.sourceOfIncome.fullName')}
              />
            </div>

            <div className={renterEmployStyles.phoneContainer}>
              <FormikPhoneNumber
                name={isForEditing ? 'presentEmploymentVerifiers[0].phoneNumber' : 'phoneNumber'}
                label={t('renter.sourceOfIncome.supervisorPhone')}
                placeholder="(000) 000-0000"
                isShowCountryCode={false}
                className={renterEmployStyles.phoneNumber}
              />
            </div>
            <div className={renterEmployStyles.userFields}>
              <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.supervisorEmail')}</span>
              <FormikField
                className={renterEmployStyles.inputBackgroundColor}
                name={isForEditing ? 'presentEmploymentVerifiers[0].emailAddress' : 'emailAddress'}
                placeholder="example@supervisor.com"
              />
            </div>

            <div className={renterEmployStyles.ownerInformationContainer}>
              <InformationIcon className={renterEmployStyles.ownerInformationIcon} />
              <p className={renterEmployStyles.ownerInformationText}>{t('renter.customInformation.owner')}</p>
            </div>
          </div>

          <div className={(indexStyles.renterButtonContainer, indexStyles.looksGoodButton)}>
            <Button
              className={renterEmployStyles.continueButton}
              variant="outline"
              type="submit"
              disabled={isButtonDisabled}
            >
              {t('renter.ConfirmAddressHistory.looksGood')}
            </Button>
          </div>
        </div>
        <FormCloseWarning isDirty={isDirty} />
      </Form>
    </section>
  );
};

export default RenterEmployed;
