import classNames from 'classnames';
import { t } from 'i18next';
import { capitalize } from 'lodash-es';

import defaultAgentProfile from 'assets/images/defaultProfilePicture.png';
import { ReactComponent as HomeIcon } from 'assets/svgs/HomeIconGrayBackground.svg';
import { ReactComponent as DotsIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import { CustomPropertyTooltip } from 'components/Renter/components/PropertyInformation/CustomPropertyTooltip';
import { ApplicationSelectedProps } from 'shared/types/renterTypes';

import styles from './ApplicationInfoBox.module.scss';

const ApplicationSelected = ({
  city,
  dateAvailable,
  propertyImage,
  rent,
  state,
  streetAddress,
  zipCode,
  isCustomAddress,
  moveInDate,
  agentProfile,
  agentFirstName,
  agentLastName,
}: ApplicationSelectedProps): JSX.Element => (
  <div className={styles.SelectedContainer}>
    <div className={styles.AvailableStatusContainer}>
      <p className={styles.MoveInTag}>
        {t('renter.applicationInfoBox.moveInDate')}&nbsp;
        {moveInDate && <span className={styles.AvailableStatus}>{moveInDate.toUpperCase()}</span>}
      </p>
      {!isCustomAddress && (
        <p className={styles.AvailableTag}>
          {`${t('agent.applications.property.available')}:`}&nbsp;
          <span className={styles.AvailableStatus}>
            {new Date(dateAvailable).toLocaleDateString() === new Date().toLocaleDateString()
              ? t('application.propertyHeader.now')
              : dateAvailable.toUpperCase()}
          </span>
        </p>
      )}
    </div>
    <div className={classNames(styles.PropertyInfoContainer, styles.SelectedPropertyInfoContainer)}>
      {!isCustomAddress ? (
        <img
          height={81}
          width={81}
          src={propertyImage}
          alt={t('renter.applicationInfoBox.propertyImage')}
          className={styles.PropertyImgae}
        />
      ) : (
        <HomeIcon className={styles.PropertyImgae} />
      )}
      <div className={styles.SelectedInformation}>
        <div className={styles.DetailsContainer}>
          {!isCustomAddress && <div className={classNames(styles.Measure, styles.SelectedRent)}>{`$${rent}/mo`}</div>}
          <div className={(styles.AddressContainer, styles.SelectedAddressContainer)}>
            <p className={classNames(styles.Address, styles.SelectedAddress)}>
              <span className={classNames(styles.PropertyAddress, styles.SelectedPropertyAddress)}>
                {streetAddress}&nbsp;
              </span>
              <span
                className={classNames(styles.CityAddress, styles.SelectedCityAddress)}
              >{`${city}, ${state} ${zipCode}`}</span>
              {isCustomAddress && (
                <span className={styles.CustomIcon}>
                  <CustomPropertyTooltip placement="top" />
                </span>
              )}
            </p>
          </div>
        </div>
        <div className={styles.SelectedAgentInformation}>
          <img
            src={agentProfile ?? defaultAgentProfile}
            width={24}
            height={24}
            alt={t('renter.applicationInfoBox.agentProfile')}
            className={styles.AgentProfilePic}
          />
          <div className={styles.AgentInformation}>
            <div className={styles.AgentDetails}>
              <p className={classNames(styles.AgentName, styles.SelectedAgentName)}>
                <span className={styles.FirstName}>{capitalize(agentFirstName)}</span>
                <span className={styles.LastName}>{capitalize(agentLastName)}</span>
              </p>
            </div>
            <div className={styles.AgentIcons}>
              <DotsIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ApplicationSelected;
