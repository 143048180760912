import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';

import { Footer } from 'components/shared/Footer/Footer';
import { Navbar } from 'components/shared/Navbar';
import { IsMobileApplicantsPage } from 'helpers/IsMobile';

import styles from './authLayout.module.scss';
const AuthLayout = (): JSX.Element => {
  const isMobilePage = IsMobileApplicantsPage();

  return (
    <div className="main">
      <Navbar />
      <Container fluid className={classNames(styles.Container, { [styles.mobileContainer]: isMobilePage }, 'p-0')}>
        <Outlet />
      </Container>
      {!isMobilePage && <Footer />}
    </div>
  );
};

export default AuthLayout;
