import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { renterApplication } from 'redux/selectors/renterSelector';

import { ReactComponent as ContactInfo } from 'assets/svgs/ContactInfoLighter.svg';
import EditDetailsAdultModal from 'components/Renter/components/RenterHouseHold/RenterAdult/EditDetailsAdultModal';
import styles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import Title from 'components/Renter/IdentityVerification/RenterDetails/shared/Title/Title';
import { HouseholdDetailsKeys } from 'constants/renterConstants';
import { closePopOverOnClick, scrollToViewOfGivenId } from 'helpers/agentHelper';
import { pluralize } from 'helpers/user';
import { renterRoutes } from 'shared/routes';
import { NextRouteProp } from 'shared/types/renterTypes';

import AdultsTooltip from './AdultsTooltip/AdultsTooltip';
import DescriptionCard from './DescriptionCard/DescriptionCard';

const Adults = ({ next }: NextRouteProp): JSX.Element => {
  const { t } = useTranslation();
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customIndex, setCustomIndex] = useState(0);
  const [adultId, setAdultId] = useState(0);
  const applications = useSelector(renterApplication);
  const { adults } = applications.find((app) => app.id === Number(currentApplicationId)) || {};
  const adultsCount = adults?.length ?? 0;
  const title = pluralize(adultsCount, t('renter.paymentConfirmation.houseHoldDetails.adults'));
  const onHideHandler = useCallback((): void => setIsModalVisible(false), []);
  const handleAddInfo = useCallback((): void => {
    navigate(
      renterRoutes.generateRenterAdults(
        currentApplicationId,
        next ?? renterRoutes.generateRenterPaymentVerification(currentApplicationId),
        HouseholdDetailsKeys.ADULTS
      )
    );
  }, [currentApplicationId, navigate, next]);
  const handleEditInfo = useCallback((currentIndex: number, selectedId?: number): void => {
    closePopOverOnClick();
    setCustomIndex(currentIndex);
    setAdultId(selectedId ?? 0);
    setIsModalVisible(true);
  }, []);
  const renderEditModal = useMemo(
    (): JSX.Element => (
      <EditDetailsAdultModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onHideHandler={onHideHandler}
        id={adultId}
        heading={`${t('renter.paymentConfirmation.houseHoldDetails.editAdult')} ${customIndex}`}
        description={t('renter.paymentConfirmation.houseHoldDetails.editAdult.description')}
      />
    ),
    [adultId, customIndex, isModalVisible, onHideHandler, t]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sectionId = queryParams.get('section');

    adultsCount && scrollToViewOfGivenId(sectionId);
  }, [location, adultsCount]);

  if (!adults) {
    return (
      <div className={styles.titleMargin}>
        <Title
          mainTitle={title}
          iconElement={<ContactInfo />}
          description={t('renter.paymentConfirmation.houseHoldDetails.adults.description')}
          shouldShowOptions
          optionElement={<AdultsTooltip handleEditInfo={handleEditInfo} handleAddInfo={handleAddInfo} />}
        />
      </div>
    );
  }

  return (
    <div className={styles.titleMargin} id="adults">
      <Title
        mainTitle={title}
        iconElement={<ContactInfo />}
        shouldShowOptions
        optionElement={<AdultsTooltip handleEditInfo={handleEditInfo} handleAddInfo={handleAddInfo} />}
      />
      {renderEditModal}
      {adults.map((adult, index) => (
        <DescriptionCard adult={adult} key={`adult-${index}`} customKey={index} />
      ))}
    </div>
  );
};

export default Adults;
