import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';
import { RentalHistoryTitleProps } from 'shared/types/renterTypes';

export const RentalHistoryTitle = ({ infoDescription, title }: RentalHistoryTitleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.HistoryTitleContainer}>
      <div className={styles.TitleContainer}>{title || t('rental.historyInformation.title')}</div>
      {infoDescription && <Row className={styles.DescriptionContainer}>{t(infoDescription)}</Row>}
    </div>
  );
};
