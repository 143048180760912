import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { setIsRenterLoading } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as FinicityIcon } from 'assets/svgs/FinicityIcon.svg';
import RCButton from 'components/shared/Button/Button';
import { NavigationKeys } from 'constants/renterConstants';
import { parseResponseErrors } from 'helpers/helper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { initiateTransactionReportEvent, retrievalOfCustomBankAccounts } from 'services/finicityService';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../RenterContainer';
import RenterIconInfoContainer from '../RenterIconInfoContainer';

import FinicityConnectButton from './FinicityConnectButton';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './ConnectBankDetails.module.scss';

const ConnectBankDetails = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isFinicityButtonClicked, setIsFinicityButtonClicked] = useState<boolean>(false);
  const { employmentId, applicationId } = useParams();
  const [searchParams] = useSearchParams();
  const maxHeight = useDeviceHeight(220);
  const navigateNext = searchParams.get(NavigationKeys.NEXT) ?? '';
  const handleNoBankAccount = useCallback(() => {
    navigate(renterRoutes.generateRenterIncomeInformation(String(applicationId), employmentId, navigateNext)); // Navigates to the next step i.e document upload page
  }, [applicationId, employmentId, navigate, navigateNext]);
  const onNextClickHandler = useCallback(() => {
    dispatch(setIsRenterLoading(true));
    retrievalOfCustomBankAccounts(Number(applicationId))
      .then(() => {
        initiateTransactionReportEvent(Number(applicationId))
          .then(() => {
            handleNoBankAccount();
          })
          .catch((error) => {
            Notification({ message: parseResponseErrors(error) });
          })
          .finally(() => {
            dispatch(setIsRenterLoading(false));
          });
      })
      .catch((error) => {
        Notification({ message: parseResponseErrors(error) });
      });
  }, [applicationId, dispatch, handleNoBankAccount]);

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody} id="connect-container">
        {/* icons list */}
        <div
          className={styles.connectFinicityContainer}
          style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
        >
          <RenterIconInfoContainer />
          <div className={indexStyles.renterContentContainer}>
            <h2 className={indexStyles.renterH2}>{t('renter.connectFinicity.shortenTheTime')}</h2>

            <div className={styles.finicityInformationContainer}>
              <div className={styles.finicityInformationIcon}>
                <FinicityIcon data-testid="finicity-icon" />
              </div>
              <div className={styles.finicityInformationText}>
                {t('renter.connectFinicity.easilyPermissionLimited')}
              </div>
            </div>
            <div className={styles.horizontalLine}></div>
            <FinicityConnectButton
              setIsFinicityButtonClicked={setIsFinicityButtonClicked}
              applicationId={Number(applicationId)}
              isClicked={setIsFinicityButtonClicked}
            />
            <div className={classNames(indexStyles.renterButtonContainer, styles.finicityButtonConatiner)}>
              <RCButton
                className={classNames(indexStyles.renterButton, styles.finicityButton)}
                variant="outline"
                onClick={handleNoBankAccount}
                disabled={isFinicityButtonClicked}
              >
                {t('renter.connectFinicity.iDoNotHaveBank')}
              </RCButton>
            </div>
            {isFinicityButtonClicked && (
              <div className={classNames(indexStyles.renterButtonContainer, styles.finicityButtonConatiner)}>
                <RCButton
                  className={classNames(indexStyles.renterButton, styles.finicityButton)}
                  variant="outline"
                  onClick={onNextClickHandler}
                >
                  {t('renter.connectFinicity.next')}
                </RCButton>
              </div>
            )}
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default ConnectBankDetails;
