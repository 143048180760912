import { Col, Row } from 'react-bootstrap';

import { NavigationKeys } from 'shared/types/renterProgress';

import RenterModal from '../../components/RenterModal/RenterModal';
import { RenterCriteriaText } from '../RenterCriteriaText/RenterCriteriaText';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from './RenterCriteriaModal.module.scss';
const RenterCriteriaModal = ({ onHide }: { onHide: () => void }): JSX.Element => (
  <RenterModal show onHide={onHide}>
    <section className={styles.renterCriteriaDoc}>
      <Row className={styles.renterCriteriaHeader}>
        <Col>
          <span className={styles.h1}>{NavigationKeys.RENTAL_CRITERIA}</span>
        </Col>
      </Row>
      <div className={styles.outerDiv}>
        <RenterCriteriaText agentOrganizationName={'Structure Properties'} />
      </div>
    </section>
  </RenterModal>
);

export default RenterCriteriaModal;
