import React from 'react';
import classNames from 'classnames';

import styles from './Avatar.module.scss';

interface Props {
  firstName: string;
  className?: string;
}

const Avatar: React.FC<Props> = ({ firstName, className }) => {
  const initials = firstName.slice(0, 2);
  const color = getColor(initials);

  return (
    <div
      style={{
        backgroundColor: color,
      }}
      className={classNames(styles.avatarText, className)}
    >
      {initials.toUpperCase()}
    </div>
  );
};

function getColor(initials: string): string {
  const colors = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#ffeb3b',
    '#ffc107',
    '#ff5722',
  ];
  const charCodeSum = initials.charCodeAt(0) + initials.charCodeAt(1);
  const index = charCodeSum % colors.length;

  return colors[index];
}

export default Avatar;
