import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { startCase } from 'lodash-es';

import indexStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import Badge from 'components/Renter/IdentityVerification/RenterDetails/shared/Badge/Badge';
import { Ladder } from 'components/Renter/IdentityVerification/RenterDetails/shared/Ladder/Ladder';
import DocumentTypesDropzones from 'components/Renter/RenterIncomeInformation/DocumentTypes/DocumentTypesDropzones';
import { differenceInYearsAndMonths } from 'helpers/renterHelper';
import { formatDate } from 'helpers/user';
import { EditEmploymentTypes, getEmploymentType } from 'shared/types/renterEmploymentInformation';
import { RenterPresentEmployments } from 'shared/types/renterTypes';

import styles from './DescriptionCard.module.scss';

type EmploymentProps = {
  employment: RenterPresentEmployments;
  indexValue: number;
  isTypeOther: boolean;
  isSelfEmployed: boolean;
  hasOnlyOne: boolean;
};
const DescriptionCard = ({
  employment,
  indexValue,
  isTypeOther,
  isSelfEmployed,
  hasOnlyOne,
}: EmploymentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Badge text={`${t('renter.paymentConfirmation.sourceOfIncome.source')} ${hasOnlyOne ? '' : indexValue + 1}`} />
      <div className={classNames(indexStyles.borderContainer, styles.container)}>
        <div className={styles.cardBody}>
          <div className={indexStyles.topDiv}>
            <div>
              {isTypeOther && <div className={styles.company}>{employment.status}</div>}
              {!isSelfEmployed && <div className={styles.company}>{employment.company}</div>}
              <div className={isSelfEmployed ? styles.company : styles.title}>{employment.title}</div>
            </div>
            <div className={indexStyles.tag}>
              <span className={indexStyles.text}>{startCase(getEmploymentType(employment.employmentSubType))}</span>
            </div>
          </div>

          {!isTypeOther && (
            <div className={styles.title}>{`${formatDate(employment.jobStartDate)} - ${t(
              'addressBox.present'
            )}  ${differenceInYearsAndMonths(employment.jobStartDate)}`}</div>
          )}
        </div>
      </div>
      <div className={styles.container}>
        <Ladder isBottomPadding={false} />
        <div className={classNames(indexStyles.borderContainer, indexStyles.noTopBorder)}>
          <div className={styles.details}>
            <span className={styles.label}>
              {isTypeOther
                ? t('renter.sourceOfIncome.other.monthlyGross')
                : t('renter.sourceOfIncome.employed.monthlyGross')}
            </span>
            <span className={styles.title}>$ {employment.monthlyGrossIncome}</span>
          </div>
        </div>
      </div>
      {employment.jobDescription && (
        <div className={styles.container}>
          <Ladder isBottomPadding={false} />
          <div className={classNames(indexStyles.borderContainer, indexStyles.noTopBorder)}>
            <div className={styles.jobDescription}>
              <span className={styles.title}>{employment.jobDescription}</span>
              <span className={styles.label}>{t('renter.sourceOfIncome.description')}</span>
            </div>
          </div>
        </div>
      )}
      {employment.employmentSubType === EditEmploymentTypes.EMPLOYED && (
        <div className={styles.container}>
          <Ladder isBottomPadding={false} />
          <div className={classNames(indexStyles.borderContainer, indexStyles.noTopBorder)}>
            <div className={styles.verificationSection}>
              <span className={styles.verificationLabel}>
                {t('renter.paymentConfirmation.sourceOfIncome.verification')}
              </span>
              <div className={styles.info}>
                <span className={styles.title}>{employment.fullName}</span>
                <span className={styles.label}>{t('renter.paymentConfirmation.sourceOfIncome.owner')}</span>
              </div>
              <div className={styles.info}>
                <span className={styles.title}>{employment.emailAddress}</span>
                <span className={styles.label}>{t('renter.Ownerinformation.email')}</span>
              </div>
              <div className={styles.info}>
                <span className={styles.title}>{employment.phoneNumber}</span>
                <span className={styles.label}>{t('renter.Ownerinformation.phone')}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.container}>
        <Ladder isBottomPadding={true} />
        <div className={classNames(styles.documents, indexStyles.noTopBorder)}>
          <DocumentTypesDropzones data={employment} currentEmploymentId={employment.id} />
        </div>
      </div>
    </>
  );
};

export default DescriptionCard;
