import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import indexStyles from 'components/Renter/RenterIndex.module.scss';
import RCButton from 'components/shared/Button/Button';
import { RenterVehicleSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { renterRoutes } from 'shared/routes';
const VehiclesCheck = (): JSX.Element => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const renterRole = useSelector(applicantRenterRole);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    if (renterRestrictions[renterRole].vehicles) {
      navigate(renterRoutes.generateRenterPetsInformation(applicationId));
    }
  }, [applicationId, navigate, renterRole]);
  const onClickHandler = useCallback(() => {
    setSearchParams({ step: RenterVehicleSteps.TYPE });
  }, [setSearchParams]);

  return (
    <>
      <h2 className={indexStyles.renterH2}>{t('renter.vehiclesInformation.vehicleCheck.question')}</h2>

      <div className={indexStyles.renterButtonContainer}>
        <RCButton
          className={classNames(indexStyles.renterButton, indexStyles.nextButton)}
          variant="outline"
          onClick={onClickHandler}
        >
          {t('renter.vehiclesInformation.vehicleCheck.question.yesOption')}
        </RCButton>
      </div>

      <div className={indexStyles.renterButtonContainer}>
        <RCButton
          className={classNames(indexStyles.renterButton, indexStyles.nextButton)}
          variant="outline"
          onClick={() => {
            const params = { hasNoVehicles: true, id: renterDetails.userId };

            if (!renterDetails?.hasNoVehicles) {
              dispatch(addRenterProfileInformation({ values: params }));
            }

            navigate(renterRoutes.generateRenterPetsInformation(applicationId));
          }}
        >
          {t('renter.vehiclesInformation.vehicleCheck.question.noOption')}
        </RCButton>
      </div>
    </>
  );
};

export default VehiclesCheck;
