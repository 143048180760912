import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { TradeLineInformationProps } from 'shared/types/reportTypes';

import { ReportHeading } from '../ReportHeading/ReportHeading';
import { EmptyReportRecord } from '../SharedReports/EmptyReportRecord/EmptyReportRecord';
import { TradeLineReport } from '../SharedReports/TradeLineReport/TradeLineReport';

import styles from './TradeLineInformation.module.scss';

export const TradeLineInformation = ({
  tradeLineInformation,
  isRenterFlow = false,
}: TradeLineInformationProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <ReportHeading
        heading={t('agent.creditReport.tradeLines')}
        hasCounter={!!tradeLineInformation?.length}
        counter={tradeLineInformation?.length ?? 0}
      />
      {!!tradeLineInformation?.length ? (
        <div className={styles.TradeLineReports}>
          {tradeLineInformation.map((tradeLineReport, index) => (
            <div
              key={`trade-line-record-${tradeLineReport}-${index}`}
              className={classNames(styles.TradeLineItem, {
                [styles.ItemDivider]: index !== tradeLineInformation?.length - 1,
              })}
            >
              <TradeLineReport isRenterFlow={isRenterFlow} tradeLineReport={tradeLineReport} />
            </div>
          ))}
        </div>
      ) : (
        <EmptyReportRecord />
      )}
    </div>
  );
};
