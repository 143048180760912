import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { applicantRenterRole, renterApplication } from 'redux/selectors/renterSelector';

import { ReactComponent as EditSvg } from 'assets/svgs/Edit.svg';
import { ReactComponent as InfoSvg } from 'assets/svgs/RenterInfo.svg';
import RCButton from 'components/shared/Button/Button';
import { RenterOccupantType } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { renterRoutes } from 'shared/routes';
import { RelationOccupantProps } from 'shared/types/renterTypes';

import EditDetailsAdultModal from './EditDetailsAdultModal';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from '../RenterMinor/RelationMinorConfirmation.module.scss';

export const AdultsOccupantListCard = ({
  attributeLabel,
  label,
  addAnotherButtonLabel,
  confirmButtonLabel,
  informationText,
  headerText,
  floatingLabel,
  addAnotherButtonOnClick,
  continueButtonOnClick,
}: RelationOccupantProps): JSX.Element => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [occupantId, setOccupantId] = useState<number | undefined>();
  const onClickHandler = useCallback((id: number | undefined): void => {
    setIsModalVisible(true);
    setOccupantId(id);
  }, []);
  const applications = useSelector(renterApplication);
  const { adults } = applications.find((app) => app.id === Number(applicationId)) || {};
  const renterRole = useSelector(applicantRenterRole);
  const onHideHandler = useCallback((): void => setIsModalVisible(false), []);
  const isRestricted = renterRestrictions[renterRole].adults;
  const adultfloatinglabel = RenterOccupantType.OCCUPANT;

  useEffect(() => {
    if (isRestricted) {
      navigate(renterRoutes.renterRelationMinorIndex.replace(':applicationId', `${applicationId}`));
    }
  }, [applicationId, isRestricted, navigate]);

  return (
    <div className={styles.container}>
      {isModalVisible && (
        <div>
          <EditDetailsAdultModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            onHideHandler={onHideHandler}
            id={occupantId}
          />
        </div>
      )}

      <div className={styles.confirmationHeading}>
        {isRestricted ? t('renter.relationship.ocuupantApplicant') : headerText}
      </div>
      <div className={styles.countDiv}>
        <div className={styles.floatingText}>{floatingLabel}</div>
        <div className={styles.adultCountDiv}>
          <div>Total {label.toLowerCase()}</div>

          {(adults?.length ?? 0) + 1}
          {/* Note: Added 1 for the renter is it self an adult occupant */}
        </div>
      </div>
      <div className={classNames(styles.adultListDiv, 'documentRow')}>
        {adults?.map((adult, index) => (
          <div className={styles.countDiv} key={adult.id}>
            <div className={styles.floatingText}>{`${adultfloatinglabel} ${index + 1}`}</div>
            <div className={styles.emailText}>
              {!isRestricted ? (
                <Link to={''} onClick={() => onClickHandler(adult.id)}>
                  <EditSvg className={styles.editButton} />
                </Link>
              ) : null}
              {adult.emailAddress}
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.infoDiv}>
                <div className={styles.valueText}>{adult.relationship}</div>
                <div className={styles.labelText}>{attributeLabel}</div>
              </div>
              <div>
                <div className={styles.valueText}>{adult.phoneNumber}</div>
                <div className={styles.labelText}>Phone</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isRestricted ? (
        <div className={styles.footer}>
          <RCButton className={styles.renterButton} variant="outline" onClick={continueButtonOnClick}>
            {t('renter.relationship.minorConfirmButton')}
          </RCButton>
        </div>
      ) : (
        <>
          <div className={styles.inviteInfoContainer}>
            <div className={styles.inviteInfo}>
              <div className={styles.infoIconDiv}>
                <InfoSvg />
              </div>
              <div className={styles.inviteTextDiv}>{informationText}</div>
            </div>
          </div>
          <div className={styles.footer}>
            <RCButton
              className={classNames(styles.renterAddButton, styles.renterButton)}
              variant="outline"
              onClick={addAnotherButtonOnClick}
            >
              {addAnotherButtonLabel}
            </RCButton>
            <RCButton className={styles.renterButton} variant="outline" onClick={continueButtonOnClick}>
              {confirmButtonLabel}
            </RCButton>
          </div>
        </>
      )}
    </div>
  );
};
