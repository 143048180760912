import { StyleSheet } from '@react-pdf/renderer';

export const sharedStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#ffffff',
    fontWeight: 'normal',
    paddingHorizontal: '20px',
  },
  heading: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textDecoration: 'underline',
    color: '#17adbc',
    fontSize: '16px',
    marginVertical: '10px',
  },
  infoContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  itemContainer: {
    width: '100%',
    flexDirection: 'column',
    gap: '5px',
  },
  item: {
    flexDirection: 'row',
    width: '100%',
    fontSize: '14px',
    justifyContent: 'space-between',
  },
  gaurantorItem: {
    border: '1px solid #5e5666',
    width: '100%',
    gap: '10px',
    fontSize: '14px',
    padding: '10px',
    marginVertical: '10px',
  },
  line: {
    height: '1px',
    width: '100%',
    backgroundColor: 'black',
  },
  vehicleOuterContainer: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '14px',
  },
  vehicleContainer: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
    padding: '10px',
    border: '1px solid grey',
  },
  vehicleInnerContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '60px',
  },
  vehicleHeading: {
    fontWeight: 'bold',
    marginTop: '14px',
  },
  greyHeading: {
    color: '#8d9899',
    marginBottom: '5px',
  },
  addressHeading: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '14px',
    gap: '50px',
    color: '#212529',
  },
  questionContainer: {
    marginTop: '10px',
    flexDirection: 'column',
    gap: '10px',
    fontSize: '11px',
  },
  documentCol: {
    flexDirection: 'column',
    gap: '5px',
  },
  verticleCol: {
    marginVertical: '5px',
  },
});
