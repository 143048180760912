import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ErrorSvg } from 'assets/svgs/InfoErrorDanger.svg';
import styles from 'components/shared/ServerErrors/ServerErrors.module.scss';

import { ServerErrorCard } from '../ServerErrorCard/ServerErrorCard';

export const InternalServerError = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.Container}>
      <ServerErrorCard
        mainHeading={t('modal.internelServerErrorMessage.heading')}
        errorIcon={<ErrorSvg />}
        onButtonClick={() => navigate(-1)}
      />
    </div>
  );
};
