import { useEffect, useState } from 'react';
import { Modal as BModal } from 'react-bootstrap';

import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import { otpFieldMaxDigits } from 'shared/constants';
import { OTPModalProps } from 'shared/types/renterTypes';

import { OTPModalBody } from './OTPModalBody';
import { OTPModalHeader } from './OTPModalHeader';

import styles from './OTPModal.module.scss';

export const OTPModal = ({
  onSubmitHandler,
  initialOtpValue,
  isExpired = false,
  isLoading = false,
  onResendOtpClickHandler,
}: OTPModalProps): JSX.Element => {
  const [otp, setOtp] = useState('');
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  useEffect(() => {
    if (initialOtpValue) {
      setOtp(initialOtpValue);
    }
  }, [initialOtpValue]);
  useEffect(() => {
    if (isSubmitClicked) {
      onSubmitHandler(otp);
      setIsSubmitClicked(false);
      setOtp('');
    }
  }, [isSubmitClicked, onSubmitHandler, otp]);

  return (
    <AgentModal
      show
      onHide={() => console.log('')}
      size="lg"
      customTitleClassName={styles.modalTitle}
      customContainerClassName={styles.CustomHeader}
      customModalClassName={styles.ModalContainer}
    >
      <>
        <BModal.Header className={styles.HeaderContainer}>
          <OTPModalHeader />
        </BModal.Header>
        <BModal.Body className={styles.BodyContainer}>
          <OTPModalBody
            isLoading={isLoading}
            isExpired={isExpired}
            initialOtpCode={initialOtpValue}
            setIsSubmitClicked={setIsSubmitClicked}
            onOtpChangeHandler={(e) => {
              setOtp(e);
            }}
            onResendOtpClickHandler={onResendOtpClickHandler}
            hasErrors={otp.length < otpFieldMaxDigits}
          />
          <div className={styles.ContentContainer}></div>
        </BModal.Body>
      </>
    </AgentModal>
  );
};
