import { useTranslation } from 'react-i18next';

import { ReactComponent as PeopleIcon } from 'assets/svgs/PeopleIcon.svg';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import { CountCard } from 'components/shared/CountCard/CountCard';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { OccupantsCapsuleProps } from 'shared/types/applicantsType';

import styles from './OccupantsCapsule.module.scss';

export const OccupantsCapsule = ({
  minorsCount,
  minorAddedByText,
  occupantsCount,
}: OccupantsCapsuleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.CapsuleContainer}>
      <div className={styles.InformationContainer}>
        <Tooltip
          placement="top"
          text={minorAddedByText ?? ''}
          icon={<div className={styles.OccupantsCount}>{occupantsCount}</div>}
          customIconClassName={styles.CountContainer}
        />
        {!!minorsCount && (
          <AgentPills
            pillContainerClassName={styles.MinorContainer}
            pillsData={[{ heading: t('renter.relationship.minor'), type: PillTypes.SECONDARY }]}
            pillIcon={<CountCard count={minorsCount} customCountClassName={styles.MinorCount} />}
          />
        )}
      </div>
      <div className={styles.LabelContainer}>
        <div className={styles.PeopleIcon}>
          <PeopleIcon />
        </div>
        <div className={styles.OccupantLabel}>{t('renter.relationship.occupants')}</div>
      </div>
    </div>
  );
};
