import { FC, ReactNode } from 'react';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import classNames from 'classnames';

import styles from './Tooltip.module.scss';
export interface TooltipProps {
  text: string;
  icon?: ReactNode;
  placement?: Placement;
  className?: string;
  customIconClassName?: string;
}

const Tooltip: FC<TooltipProps> = ({ text, icon, placement = 'auto', className, customIconClassName }) => (
  <OverlayTrigger
    placement={placement}
    overlay={<BootstrapTooltip className={classNames(styles.tooltip, className)}>{text}</BootstrapTooltip>}
  >
    <span className={customIconClassName}>{icon ? icon : text}</span>
  </OverlayTrigger>
);

export default Tooltip;
