import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectedRenterDetails } from 'redux/selectors/agentSelector';

import indexStyles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import { SectionTitle } from 'components/Agent/Applications/ApplicationDetails/components/shared';

import PresentEmploymentCard from '../PresentIncome/PresentEmploymentCard/PresentEmploymentCard';
// import PriorEmploymentCard from '../PriorIncome/PriorEmploymentCard.tsx/PriorEmploymentCard';

const EmploymentContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const { presentEmployments, firstName, lastName } = useSelector(selectedRenterDetails);

  return (
    <>
      <div className={indexStyles.IncomeSection}>
        <SectionTitle description={t('agent.applicants.renterResume.sourceOfIncome.present')} shouldDrawLine />
        {!presentEmployments && (
          <div className={indexStyles.EmptySectionTitle}>
            {t('renter.paymentConfirmation.sourceOfIncome.noPresent')}
          </div>
        )}
        {presentEmployments?.map((employment, index) => (
          <PresentEmploymentCard
            fullName={`${firstName} ${lastName}`}
            employment={employment}
            indexValue={index}
            key={`present-income-${index}`}
          />
        ))}
      </div>

      {/* <div className={indexStyles.IncomeSection}>
        <SectionTitle description={t('agent.applicants.renterResume.sourceOfIncome.prior')} shouldDrawLine />
        {!priorEmployments && (
          <div className={indexStyles.EmptySectionTitle}>{t('renter.paymentConfirmation.sourceOfIncome.noPrior')}</div>
        )}
        {priorEmployments?.map((employment, index) => (
          <PriorEmploymentCard employment={employment} indexValue={index} key={`prior-income-${index}`} />
        ))}
      </div> */}
    </>
  );
};

export default EmploymentContainer;
