import { ViewSubmittedItemProps } from 'shared/types/agentTypes';

import { SubmittedViewTooltip } from './SubmittedViewTooltip';

import styles from './VerifierAddressContainer.module.scss';

export const ViewSubmittedItem = ({ label, content, isEmail = false }: ViewSubmittedItemProps): JSX.Element => (
  <div className={styles.ContentContainer}>
    <div className={styles.ContentLabel}>{label}</div>
    {!isEmail ? <div className={styles.Content}>{content}</div> : <SubmittedViewTooltip value={content} />}
  </div>
);
