import { isEmpty } from 'lodash-es';

import { ReactComponent as DocumentIcon } from 'assets/svgs/DocumentIcon.svg';
import { StripeDocument } from 'shared/types/renterTypes';

import DocumentItem from '../../../shared/Documents/DocumentItem/DocumentItem';

import styles from './StripeDocumentDetails.module.scss';

export const StripeDocumentDetails = ({ stripeDocument }: { stripeDocument: StripeDocument }): JSX.Element => (
  <div className={styles.Container}>
    <div className={styles.DocumentDetails}>
      <div className={styles.DocumentHeading}>{stripeDocument?.documentType}</div>
      <div className={styles.DetailsInformation}>
        <div className={styles.DocumentId}>{stripeDocument?.documentId}</div>
        {!!stripeDocument?.state && <div className={styles.State}>{stripeDocument?.state}</div>}
      </div>
    </div>
    {!isEmpty(stripeDocument.stripeVerificationDocumentFileLink) && (
      <div className={styles.Documents}>
        {stripeDocument.stripeVerificationDocumentFileLink.map((doc, index) => (
          <DocumentItem
            key={`stripe-document-${doc}`}
            customLabel=""
            shouldShowCount={false}
            documentName={`${stripeDocument?.documentType} ${index > 0 ? index + 1 : ''}`}
            documentLink={`${doc?.documentFileLink}`}
            icon={<DocumentIcon />}
          />
        ))}
      </div>
    )}
  </div>
);
