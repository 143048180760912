import { useEffect, useState } from 'react';

import styles from './CircularProgressBar.module.scss';

interface ICircularProgressBar {
  progress: number;
}

const CircularProgressBar = ({ progress }: ICircularProgressBar): JSX.Element => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(((100 - progress) / 100) * 100);
  }, [setOffset, progress]);

  return (
    <div className={styles.circularProgressBarContainer}>
      <svg className={styles.circularProgressBar}>
        <circle className={styles.circularProgressBarBackground} r="16" cx="17.5" cy="17.5" />
        <circle
          className={styles.circularProgressBarProgress}
          r="16"
          cx="17.5"
          cy="17.5"
          strokeDasharray="100"
          strokeDashoffset={offset}
        />
      </svg>
      <div className={styles.circularProgressBarText}>
        <span>{progress}%</span>
      </div>
    </div>
  );
};

export default CircularProgressBar;
