/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Col } from 'react-bootstrap';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useField } from 'formik';
import heic2any from 'heic2any';
import { selectPropertyId } from 'redux/selectors/propertySelector';
import { deleteUploadedPropertyImage, orderPropertyImages } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as AddIconBlack } from 'assets/svgs/AddIconBlack.svg';
import { ReactComponent as CloseIconMedia } from 'assets/svgs/CloseIconMedia.svg';
import { ReactComponent as ImageIconGrey } from 'assets/svgs/ImageIconGrey.svg';
import { ReactComponent as LoadingIcon } from 'assets/svgs/LoadingIcon.svg';
import RCButton from 'components/shared/Button/Button';
import { ImageDimensions } from 'components/shared/Cropper/Cropper';
import { Notification } from 'shared/Notification/Notification';

import styles from './Dropzone.module.scss';
export interface PreviewFile extends FileWithPath {
  preview?: string;
  dimension?: ImageDimensions;
  url?: string;
  isLoading: boolean;
  fileLink?: string;
  forgedFileLink?: string;
  imageId?: number;
  isApiMade?: boolean;
  recentUpload?: boolean;
  id?: number;
}

export interface IDropZone {
  acceptedFiles: PreviewFile[];
  selectedFile?: PreviewFile;
  setAcceptedFiles: Dispatch<React.SetStateAction<PreviewFile[]>>;
  setSelectedFile: Dispatch<React.SetStateAction<PreviewFile | undefined>>;
  onToggleFile: (file: PreviewFile) => void;
}

const Dropzone = ({
  onToggleFile,
  acceptedFiles,
  selectedFile,
  setAcceptedFiles,
  setSelectedFile,
}: IDropZone): JSX.Element => {
  const [, , { setValue }] = useField('propertyImages');
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [maxElementPerRow, setMaxElementsPerRow] = useState<number>(0);
  const [maxRows, setMaxRows] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { propertyId } = useParams();
  const currentPropertyId = useSelector(selectPropertyId) || Number(propertyId);
  const [isFileDeleting, setIsFileDeleting] = useState(false);
  const isAnyImageStillInProgress =
    (!!acceptedFiles && acceptedFiles.some((el) => el.isLoading)) || false || isFileDeleting;
  // set to hold the name of file that are invalid
  const removeFile = useCallback(
    (file: PreviewFile, acceptedFile: PreviewFile[]): void => {
      const filteredFiles = (f: PreviewFile): boolean => f.fileLink !== file.fileLink || f.url !== file.url;

      setAcceptedFiles(acceptedFile.filter(filteredFiles));
      setValue(acceptedFile.filter(filteredFiles));

      // mean that file is still not saved in db
      if (file.isLoading) {
        return;
      }

      setIsFileDeleting(true);

      dispatch(
        deleteUploadedPropertyImage({
          propertyId: currentPropertyId,
          imageId: file?.imageId as number,
        })
      )
        .unwrap()
        .finally(() => {
          setIsFileDeleting(false);
        });

      if (file === selectedFile) {
        setSelectedFile(undefined);
      }
    },
    [currentPropertyId, dispatch, selectedFile, setAcceptedFiles, setSelectedFile, setValue]
  );
  const isImageDimensionsValid = useCallback((file: PreviewFile): boolean => {
    if (file.dimension) {
      if (file.dimension?.height < 220 || file.dimension?.width < 330) {
        Notification({ message: `Images should have height and width greater than 220px and 330px respectively.` });

        return false;
      }
    }

    return true;
  }, []);
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(acceptedFiles);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    setAcceptedFiles(items);
    setValue(items);
    const propertyImagesIds = items.map((el) => el.imageId).filter((el) => !!el) || [];

    // order image api
    dispatch(
      orderPropertyImages({
        propertyId: currentPropertyId,
        imagesId: propertyImagesIds,
      })
    );
  };
  const { getRootProps, open, getInputProps } = useDropzone({
    maxSize: 26214400,
    noClick: true,
    noKeyboard: true,
    // type of file
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'image/HEIC': ['.HEIC'],
    },

    // create the list of file, if the file type is HEIC execute if block, other case else
    onDrop: (acceptedFiles1) => {
      const tempFiles = acceptedFiles1.filter((f) => !acceptedFiles.some((cItem) => cItem.name === f.name));
      const filterImages = tempFiles.map(
        (f) =>
          new Promise((resolve, reject) => {
            const tempFile = f;

            if (tempFile.type === 'image/heic') {
              heic2any({ blob: tempFile, toType: 'image/jpeg' }).then((jpgBlob) => {
                const reader = new FileReader();

                reader.readAsDataURL(jpgBlob instanceof Array ? jpgBlob[0] : jpgBlob);

                reader.onload = function () {
                  const img = new Image();

                  img.src = reader.result as string;
                  img.onload = function () {
                    const previewFile: PreviewFile = Object.assign(f, {
                      preview: URL.createObjectURL(jpgBlob instanceof Array ? jpgBlob[0] : jpgBlob),
                      dimension: {
                        height: img.height,
                        width: img.width,
                      },
                      isLoading: true,
                      isApiMade: false,
                      url: img.src,
                    });

                    if (isImageDimensionsValid(previewFile)) {
                      resolve(previewFile);
                    }
                  };
                };
              });
            } else {
              const reader = new FileReader();

              reader.readAsDataURL(f);
              reader.onload = function () {
                const img = new Image();

                img.src = reader.result as string;
                // get the height and width of image
                img.onload = function () {
                  const previewFile: PreviewFile = Object.assign(f, {
                    preview: URL.createObjectURL(f),
                    dimension: {
                      height: img.height,
                      width: img.width,
                    },
                    isLoading: true,
                    isApiMade: false,
                    url: img.src,
                  });

                  if (isImageDimensionsValid(previewFile)) {
                    resolve(previewFile);
                  } else {
                    reject();
                  }
                };
              };
            }
          })
      );

      Promise.all(filterImages.map((p) => Promise.resolve(p).catch(() => undefined))).then((results) => {
        const settledPromises = results.filter((p) => p !== undefined);

        setAcceptedFiles([...acceptedFiles, ...(settledPromises as PreviewFile[])]);
        setValue([...acceptedFiles, ...(settledPromises as PreviewFile[])]);
      });
    },
  });
  // render all the images

  useEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      // BreakPoints: 380px the image container size is 100,  for 580px the image container size is 75px else 65
      // FileSize = 75px || 75 || 100 at different Break points
      // numberOfElementPerRow:  number of images a single row can hold
      // numberOfRows: number of row need for dropzone to support vertical and horizontal dragging
      // ref.current?.offsetWidth:  the parent container with that holds all the images
      const imageContainerSize = windowWidth <= 380 ? 100 : windowWidth <= 580 ? 75 : 65;
      const numberOfElementPerRow = Math.ceil(ref.current?.offsetWidth / imageContainerSize);
      const numberOfRows = Math.ceil(acceptedFiles.length / numberOfElementPerRow);

      setMaxElementsPerRow(numberOfElementPerRow);
      setMaxRows(numberOfRows);
    }
  }, [windowWidth, acceptedFiles]);

  const RenderImages = ({ file }: { file: PreviewFile }): JSX.Element => {
    const { preview } = file;

    return (
      <div className={styles.previewContainer}>
        <img
          src={preview}
          alt=""
          aria-hidden="true"
          className={styles.previewImage}
          onClick={() => {
            onToggleFile(file);
          }}
        />
        {!file.isLoading && (
          <CloseIconMedia onClick={() => removeFile(file, acceptedFiles)} className={styles.removeButton} />
        )}
        {file.isLoading && <LoadingIcon className={styles.loadingIcon} />}
      </div>
    );
  };

  return (
    <section {...getRootProps()}>
      <div className={classNames('dropzone', acceptedFiles.length === 0 ? styles.noFilesDropzone : '')}>
        <input {...getInputProps({ multiple: true })} />

        <div className={styles.dropzoneContentContainer}>
          {acceptedFiles.length > 0 && (
            <span className={styles.dropzoneLabel}>
              {isAnyImageStillInProgress
                ? 'In progress'
                : acceptedFiles.length === 1
                ? 'Complete'
                : 'Drag & drop to reorder'}
            </span>
          )}
          {acceptedFiles.length === 0 && (
            <div className={styles.emptyDropzone}>
              <div className={styles.dropzoneText}>
                <ImageIconGrey className={styles.addIcon} />
                <p className={styles.dragText}>Drag & drop photo(s) here or</p>
              </div>
              <RCButton onClick={open} className={styles.selectButton}>
                Select from computer
              </RCButton>
            </div>
          )}
          {acceptedFiles.length > 0 && acceptedFiles.length < 3 && windowWidth > 991 && (
            <div className={styles.dropzoneText}>
              <AddIconBlack className={styles.addIcon} />
              <p>Drop photo(s) or</p>
              <RCButton onClick={open} className={styles.selectButton}>
                select
              </RCButton>
            </div>
          )}
          <Col>
            <div ref={ref} className={styles.previewList}>
              <DragDropContext onDragEnd={onDragEnd}>
                <input {...getInputProps({ multiple: true })} />
                {Array(maxRows)
                  .fill(0)
                  .map((item, i) => (
                    <Droppable droppableId={`droppable-${i}`} direction="horizontal" key={`dropable-item-${i}`}>
                      {(provided) => (
                        <ul className={styles.imagesListContainer} {...provided.droppableProps} ref={provided.innerRef}>
                          {acceptedFiles
                            .slice(maxElementPerRow * i, maxElementPerRow * i + maxElementPerRow)
                            .map((file, index) => (
                              <Draggable
                                key={file.imageId ?? file.id}
                                draggableId={`draggable-${file.imageId ?? file.id}`}
                                index={maxElementPerRow * i + index}
                                isDragDisabled={isAnyImageStillInProgress}
                              >
                                {(currentImageDrag) => (
                                  <li
                                    ref={currentImageDrag.innerRef}
                                    {...currentImageDrag.draggableProps}
                                    {...currentImageDrag.dragHandleProps}
                                    style={{ ...currentImageDrag.draggableProps.style }}
                                  >
                                    <div
                                      className={classNames(styles.fileContainer, {
                                        [styles.greenBorder]: maxElementPerRow * i + index === 0 && !file.isLoading,
                                      })}
                                      key={index}
                                    >
                                      {maxElementPerRow * i + index === 0 && !file.isLoading && (
                                        <span className={styles.featuredText}>Featured</span>
                                      )}
                                      <RenderImages file={file} />
                                    </div>
                                  </li>
                                )}
                              </Draggable>
                            ))}
                        </ul>
                      )}
                    </Droppable>
                  ))}
              </DragDropContext>
            </div>
          </Col>
        </div>
      </div>
      {(acceptedFiles.length > 2 || (windowWidth < 991 && acceptedFiles.length > 0)) && (
        <div className={styles.dragDropField}>
          <input {...getInputProps({ multiple: true })} />

          <AddIconBlack className={styles.addIcon} />
          <p className={styles.dragDropText}>Drop photo(s) or </p>
          <RCButton onClick={open} className={styles.selectButton}>
            select
          </RCButton>
          <input {...getInputProps({ multiple: true })} />
        </div>
      )}
      <span className={styles.dropzoneBottomText}>Accepted formats: JPEG, PDF, PNG, JPG</span>
    </section>
  );
};

export default Dropzone;
