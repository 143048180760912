import { useTranslation } from 'react-i18next';

import { formatSSN } from 'helpers/renterHelper';

import styles from './SSNDetails.module.scss';

type SSNDetailsProps = {
  data: string;
};

const SSNDetails = ({ data }: SSNDetailsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.Heading}>{t('application.ssn')}</div>
      <div className={styles.Details}>
        <span className="fs-mask">{formatSSN(data, true)}</span>
      </div>
    </div>
  );
};

export default SSNDetails;
