import { SingleValue } from 'react-select';

export interface FormValues {
  id: number;
  question: string;
  answer?: string;
  explanation?: string | null;
}

export const questionOptions: { [key: string]: { label: string; name: string } } = {
  SELECT: { label: 'Select', name: 'SELECT' },
  YES: { label: 'Yes', name: 'YES' },
  NO: { label: 'No', name: 'NO' },
};

export type Option = SingleValue<{
  name: string;
  label: string;
}>;

export interface Values {
  currentStep: number;
}

export interface MyFormValues {
  questions: FormValues[];
}

export interface BackgroundQuestion {
  id: number;
  question: string;
}
export interface BackgroundInfo {
  id: number;
  answer: string;
  explanation?: string;
  backgroundQuestion: BackgroundQuestion;
}
export interface BackgroundInformationValues {
  backgroundInformation: BackgroundInfo[];
}

export interface RenterPersonalInfoModalProps {
  shouldShowModal: boolean;
  onCloseModal: () => void;
  onSubmit?: () => void;
}
