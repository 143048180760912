import classNames from 'classnames';
import { t } from 'i18next';

import { kebabCaseToTitle } from 'helpers/user';
import { VehicleDescriptionCardProps } from 'shared/types/renterTypes';
import { vehicleInfoLabels } from 'shared/types/renterVehicleInformation';

import styles from './VehicleDescriptionCard.module.scss';

const VehicleDescriptionCard = ({
  vehicle,
  customKey,
  subtype,
  hasFontSize = false,
}: VehicleDescriptionCardProps): JSX.Element => (
  <div className={styles.vehicleDescriptionCard}>
    {subtype ? (
      <span className={styles.faded}>
        {subtype} {customKey}
      </span>
    ) : (
      <div className={classNames(styles.heading, hasFontSize && styles.FontContainer)}>
        {kebabCaseToTitle(vehicle.vehicleType)}
      </div>
    )}
    <div className={styles.infoRow}>
      {vehicleInfoLabels.slice(0, 3).map((info) => (
        <div className={styles.infoItem} key={info.key}>
          <div className={classNames(styles.label, hasFontSize && styles.FontContainer)}>{vehicle[info.key]}</div>
          <div className={classNames(styles.subLabel, hasFontSize && styles.FontContainer)}>
            {t(`renter.vehiclesInformation.${info.label}`)}
          </div>
        </div>
      ))}
    </div>
    <div className={styles.infoRow}>
      {vehicleInfoLabels.slice(3).map((info) => (
        <div className={styles.infoItem} key={info.key}>
          <div className={classNames(styles.label, hasFontSize && styles.FontContainer)}>{vehicle[info.key]}</div>
          <div className={classNames(styles.subLabel, hasFontSize && styles.FontContainer)}>
            {t(`renter.vehiclesInformation.${info.label}`)}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default VehicleDescriptionCard;
