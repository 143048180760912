import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isSaving } from 'redux/selectors/renterSelector';
import { addRenterAddressInformation, updateRenterAddressInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import AddressBox from 'components/shared/AddressBox/AddressBox';
import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import FormikField from 'components/shared/TextField/FormikTextField';
import { NavigationKeys, RenterAddressSteps, RenterAddressTypes } from 'constants/renterConstants';
import { getAddressToApiFormat, PresentAddressProps } from 'helpers/address';
import { formatRentPrefixString } from 'helpers/renterHelper';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { AddressInitialValuesProps } from 'shared/types/renterTypes';
import { formatDateForBackend } from 'shared/utils/formatDate';

import { PresentAddressInformation } from '../PresentAddressInformation/PresentAddressInformation';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './RenterOwnerInformation.module.scss';

const RenterOwnerInformation = ({ address, setIsRenting }: PresentAddressProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [searchParams] = useSearchParams();
  const nextStep = searchParams.get(NavigationKeys.NEXT);
  const isSavingInformation = useSelector(isSaving);
  const isFormSet = useRef(false);
  const [shouldClassify, setShouldClassify] = useState(false);
  const {
    values,
    resetForm,
    errors,
    touched,
    dirty: isDirty,
    setFieldValue,
  } = useFormikContext<AddressInitialValuesProps>();
  const onClearStates = useCallback(() => {
    resetForm();
  }, [resetForm]);
  const getNextNavigationStep = useMemo((): string => {
    if (nextStep) {
      return nextStep;
    } else {
      return renterRoutes.generateAddressHistoryInformation(
        Number(applicationId),
        address.addressType === RenterAddressTypes.PRESENT
          ? RenterAddressSteps.PRIOR_ADDRESS
          : RenterAddressSteps.ADDRESS_HISTORY
      );
    }
  }, [address.addressType, applicationId, nextStep]);
  const updateRenterAddress = useCallback(() => {
    if (address.id && values.renterAddress) {
      dispatch(
        updateRenterAddressInformation({
          id: address.id,
          address: {
            ...getAddressToApiFormat(address),
            fullName: values.renterAddress.fullName,
            emailAddress: values.renterAddress.emailAddress,
            phoneNumber: values.renterAddress.phoneNumber,
            contactRent: values.renterAddress.contactRent,
            moveInDate: !!values.renterAddress.moveInDate
              ? formatDateForBackend(values.renterAddress.moveInDate)
              : null,
            isRented: true,
          },
        })
      )
        .unwrap()
        .then(() => {
          setIsRenting(false);
          onClearStates();
          navigate(getNextNavigationStep);
        })
        .catch((error) => {
          Notification({ message: error });
        });
    }
  }, [address, dispatch, getNextNavigationStep, navigate, onClearStates, setIsRenting, values]);
  const createRenterAddress = useCallback(() => {
    if (address) {
      dispatch(
        addRenterAddressInformation({
          address: { ...getAddressToApiFormat(address), isRented: true },
        })
      )
        .unwrap()
        .then(() => {
          onClearStates();
          setIsRenting(false);
          navigate(getNextNavigationStep);
        })
        .catch((error) => {
          Notification({ message: error });
        });
    }
  }, [address, dispatch, getNextNavigationStep, navigate, onClearStates, setIsRenting]);
  const onClickHandler = useCallback(() => {
    if (address.id) {
      updateRenterAddress();
    } else {
      createRenterAddress();
    }
  }, [address, createRenterAddress, updateRenterAddress]);
  const areAllFieldsFilled = useMemo((): boolean => {
    if (
      address &&
      values.renterAddress.fullName &&
      values.renterAddress.emailAddress &&
      values.renterAddress.contactRent &&
      values.renterAddress.phoneNumber
    ) {
      return true;
    }

    return false;
  }, [address, values]);
  const isButtonDisabled = useMemo(
    (): boolean =>
      isSavingInformation ||
      !!errors.renterAddress?.fullName ||
      !!errors.renterAddress?.contactRent ||
      !!errors.renterAddress?.phoneNumber ||
      !!errors.renterAddress?.emailAddress ||
      !areAllFieldsFilled,
    [
      areAllFieldsFilled,
      errors.renterAddress?.contactRent,
      errors.renterAddress?.emailAddress,
      errors.renterAddress?.fullName,
      errors.renterAddress?.phoneNumber,
      isSavingInformation,
    ]
  );

  //Setting Initial Values
  useEffect(() => {
    if (address.addressVerifiers && address.emailAddress && !values.renterAddress.emailAddress) {
      setFieldValue('renterAddress', address);
    }
  }, [address, setFieldValue, values.renterAddress.emailAddress]);
  useEffect(() => {
    if (!isFormSet.current) {
      setFieldValue('renterAddress', address);
      isFormSet.current = true;
    }
  }, [address, setFieldValue]);

  return (
    <div className={classNames(styles.Container, indexStyles.renterContentContainer)}>
      {!shouldClassify ? (
        <div className={classNames(indexStyles.renterContentContainer, styles.renterOwnerInformationContainer)}>
          <FormCloseWarning isDirty={isDirty} />
          <p className={indexStyles.renterH2}>
            {address.addressType === RenterAddressTypes.PRIOR
              ? t('renter.OwnerInformation.provideContactInformationPrior')
              : t('renter.OwnerInformation.provideContactInformation')}
            <span className={styles.renterH2Color}>{t('renter.OwnerInformation.ownerManager')}</span>
          </p>
          {address && (
            <>
              <div data-testid="ownerAddressBox" className={styles.addresBoxContainer}>
                <AddressBox address={address} doShowMoreData />
              </div>

              <div className={styles.textFieldContainer}>
                <span className={styles.textFieldLabel}>{t('renter.Ownerinformation.owner/Manager')}</span>
                <FormikField name={`renterAddress.fullName`} placeholder="Full Name" />
              </div>
              <div
                className={classNames(styles.textFieldContainer, {
                  [styles.contactRentFieldContainer]: values.renterAddress.contactRent,
                  [styles.rentErrorFieldContainer]:
                    !!errors.renterAddress?.contactRent?.length && touched.renterAddress?.contactRent,
                })}
              >
                <span className={styles.textFieldLabel}>{t('renter.Ownerinformation.rentAmount')}</span>
                <FormikField
                  prefixString={formatRentPrefixString(values.renterAddress.contactRent)}
                  name={`renterAddress.contactRent`}
                  placeholder="$ 0,000"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>

              <div className={styles.textFieldContainer}>
                <FormikPhoneNumber
                  name={`renterAddress.phoneNumber`}
                  placeholder="(000) 000-0000"
                  label="Phone"
                  isShowCountryCode={false}
                />
              </div>
              <div className={styles.textFieldContainer}>
                <span className={styles.textFieldLabel}>{t('renter.Ownerinformation.email')}</span>
                <FormikField name={`renterAddress.emailAddress`} placeholder="ex@manager.com" />
              </div>

              <div className={styles.ownerInformationContainer}>
                <InformationIcon className={styles.ownerInformationIcon} />
                <p className={styles.ownerInformationText}>{t('renter.OwnerInformation.aRequestForVerification')}</p>
              </div>

              <div className={classNames(indexStyles.renterButtonContainer, styles.ownerButtonConatiner)}>
                <RCButton
                  className={classNames(indexStyles.renterButton, styles.ownerInformationButton)}
                  variant="outline"
                  disabled={isButtonDisabled}
                  onClick={() => setShouldClassify(true)}
                >
                  {t('renter.OwnerInformation.gotItNext')}
                </RCButton>
              </div>
            </>
          )}
        </div>
      ) : (
        <PresentAddressInformation
          address={values.renterAddress}
          mainHeading={t('address.livingSituation.tellUsMoreAboutWhy')}
          subHeading=""
          customMainButtonText={t('renter.connectFinicity.next')}
          customSubButtonText={`${t('renter.guarantorInformation.yes')}, ${t(
            'renter.vehiclesInformation.form.continue'
          ).toLocaleLowerCase()}`}
          isPrior={address.addressType === RenterAddressTypes.PRIOR}
          onClickHandler={onClickHandler}
          isForRent
        />
      )}
    </div>
  );
};

export default RenterOwnerInformation;
