import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { renterVehicles } from 'redux/selectors/renterSelector';

import { ReactComponent as CarIcon } from 'assets/svgs/CarIconDark.svg';
import styles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import Title from 'components/Renter/IdentityVerification/RenterDetails/shared/Title/Title';
import EditVehicleInformation from 'components/Renter/RenterVehiclesInformation/VehiclesEditModal/VehiclesEditModal';
import { PaymentVerificationKeys, RenterVehicleSteps, vehicleSubTypes } from 'constants/renterConstants';
import { renterRoutes } from 'shared/routes';
import { NextRouteProp } from 'shared/types/renterTypes';
import { VehiclesInformationType } from 'shared/types/renterVehicleInformation';

import VehiclesCard from './VehicleCard/VehiclesCard';
import VehicleInformationTooltip from './VehicleInformationTooltip/VehicleInformationTooltip';

const VehiclesInformation = ({ next }: NextRouteProp): JSX.Element => {
  const navigate = useNavigate();
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const { t } = useTranslation();
  const vehicles = useSelector(renterVehicles);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<VehiclesInformationType>({});
  const onHideHandler = useCallback((): void => setIsModalOpen(false), []);
  const handleAddInfo = useCallback((): void => {
    navigate(
      renterRoutes.generateVehiclesInformation(
        currentApplicationId,
        PaymentVerificationKeys.VEHICLE_INFORMATION,
        RenterVehicleSteps.TYPE,
        next ?? renterRoutes.generateRenterPaymentVerification(currentApplicationId)
      )
    );
  }, [currentApplicationId, navigate, next]);

  if (!vehicles?.length) {
    return (
      <div id={PaymentVerificationKeys.VEHICLE_INFORMATION}>
        <Title
          mainTitle={t('renter.paymentConfirmation.vehciles.title')}
          iconElement={<CarIcon />}
          shouldShowOptions
          optionElement={
            <VehicleInformationTooltip
              handleAddInfo={handleAddInfo}
              vehicleInformation={vehicles}
              domId={PaymentVerificationKeys.VEHICLE_INFORMATION}
            />
          }
        />
        <Title mainTitle={t('renter.paymentConfirmation.vehicles.noVehicles')} />
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        {vehicleSubTypes.map((subtype, index) => (
          <VehiclesCard
            key={subtype + index}
            subtype={subtype}
            next={next}
            setFormValues={setFormValues}
            setIsModalOpen={setIsModalOpen}
          />
        ))}
      </div>
      {isModalOpen && (
        <EditVehicleInformation
          isModalVisible={isModalOpen}
          setIsModalVisible={setIsModalOpen}
          onHideHandler={onHideHandler}
          data={formValues}
        />
      )}
    </>
  );
};

export default VehiclesInformation;
