import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import RCButton from '../Button/Button';

import styles from './ReadMore.module.scss';

interface ReadMoreProps {
  text: string;
}
const ReadMore = ({ text }: ReadMoreProps): JSX.Element => {
  const { t } = useTranslation();
  const [hasReadMore, setHasReadMore] = useState(true);
  const toggleReadMore = (): void => {
    setHasReadMore(!hasReadMore);
  };
  const [hasOverflowed, setHasOverflowed] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      setHasOverflowed(container.scrollHeight > container.clientHeight);
    }
  }, [text]);

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.textField,
          { [styles.overflow]: hasOverflowed },
          { [styles.heightDiv]: hasReadMore }
        )}
        ref={containerRef}
      >
        {text}
      </div>
      {hasOverflowed && (
        <div className={styles.readContainer}>
          <RCButton className={classNames(styles.readDiv, styles.textField)} variant="outline" onClick={toggleReadMore}>
            {hasReadMore ? t('application.readMore') : t('application.readLess')}
          </RCButton>
        </div>
      )}
    </div>
  );
};

export default ReadMore;
