import { t } from 'i18next';

import { AddressInformation } from 'shared/types/renterTypes';

import styles from './VerifierCard.module.scss';

type VerifierCardProps = {
  address: AddressInformation | undefined;
};

const VerifierCard = ({ address }: VerifierCardProps): JSX.Element => (
  <div className={styles.VerifierCardContainer}>
    <div className={styles.mainHeading}>
      {!!address?.classification
        ? t('address.livingSituation.withoutDot.')
        : t('renter.ConfirmAddressHistory.verifier')}
    </div>

    {!!address?.fullName && (
      <div className={styles.infoContainer}>
        <span className={styles.heading}>{address.fullName}</span>
        <span className={styles.subHeading}>{t('renter.ConfirmAddressHistory.owner')}</span>
      </div>
    )}
    {!!address?.emailAddress && (
      <div className={styles.infoContainer}>
        <span className={styles.heading}>{address.emailAddress}</span>
        <span className={styles.subHeading}>{t('renter.Ownerinformation.email')}</span>
      </div>
    )}
    {!!address?.phoneNumber && (
      <div className={styles.infoContainer}>
        <span className={styles.heading}>{address.phoneNumber}</span>
        <span className={styles.subHeading}>{t('renter.Ownerinformation.phone')}</span>
      </div>
    )}
    {!!address?.classification && (
      <div className={styles.infoContainer}>
        <span className={styles.heading}>{address.classification}</span>
        <span className={styles.subHeading}>{t('address.livingSituation.classification.')}</span>
      </div>
    )}
    {!!address?.description && (
      <div className={styles.infoContainer}>
        <span className={styles.heading}>{address.description}</span>
        <span className={styles.subHeading}>{t('agent.properties.property.description')}</span>
      </div>
    )}
  </div>
);

export default VerifierCard;
