import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import { isSaving } from 'redux/selectors/renterSelector';
import { addRenterAddressInformation, updateRenterAddressInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import AddressBox from 'components/shared/AddressBox/AddressBox';
import RCButton from 'components/shared/Button/Button';
import CustomRangeDatePicker from 'components/shared/CustomRangeDatePicker/CustomRangeDatePicker';
import FormikField from 'components/shared/TextField/FormikTextField';
import { endDate, NavigationKeys, RenterAddressSteps } from 'constants/renterConstants';
import { getAddressToApiFormat, PRIOR_ADDRESS_START_DATE, PriorAddressProps } from 'helpers/address';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { AddressInitialValuesProps } from 'shared/types/renterTypes';

import { PresentAddressInformation } from '../PresentAddressInformation/PresentAddressInformation';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './PriorAddress.module.scss';

const PriorAddress = ({
  address,
  setIsRenting,
  defaultSelected,
  setShouldClassify,
  shouldClassify,
}: PriorAddressProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const { values, resetForm, errors, setFieldValue, setFieldTouched } = useFormikContext<AddressInitialValuesProps>();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [searchParams] = useSearchParams();
  const nextStep = searchParams.get(NavigationKeys.NEXT);
  const isSavingInformation = useSelector(isSaving);
  const isButtonDisabled = useMemo(
    (): boolean =>
      isSavingInformation ||
      !!errors.renterAddress?.streetAddress ||
      !!errors.renterAddress?.toDate ||
      !!errors.renterAddress?.fromDate,
    [
      errors.renterAddress?.fromDate,
      errors.renterAddress?.streetAddress,
      errors.renterAddress?.toDate,
      isSavingInformation,
    ]
  );

  //setting intial touch for date fields
  useEffect(() => {
    if (
      errors.renterAddress?.fromDate &&
      errors.renterAddress.fromDate !== t('renter.addressSchema.fromDateIsRequired')
    ) {
      setFieldTouched('renterAddress.fromDate', true);
    } else if (
      errors.renterAddress?.toDate &&
      errors.renterAddress.toDate !== t('renter.addressSchema.toDateIsRequired')
    ) {
      setFieldTouched('renterAddress.toDate', true);
    }
  }, [errors, setFieldTouched, t]);
  const onClearStates = useCallback(() => {
    resetForm();
    setFieldTouched('renterAddress.fromDate', false);
    setFieldTouched('renterAddress.toDate', false);
  }, [resetForm, setFieldTouched]);
  const onIRentClickHandler = useCallback(() => {
    setIsRenting(true);
  }, [setIsRenting]);
  const createAddress = useCallback(() => {
    dispatch(
      addRenterAddressInformation({
        address: { ...getAddressToApiFormat(values.renterAddress), isRented: values.renterAddress.isRented ?? false },
      })
    )
      .unwrap()
      .then((response) => {
        setFieldValue('renterAddress', response.addresses[response.addresses.length - 1]);
        onClearStates();
        navigate(
          nextStep
            ? nextStep
            : renterRoutes.generateAddressHistoryInformation(Number(applicationId), RenterAddressSteps.ADDRESS_HISTORY)
        );
      })
      .catch((error) => {
        Notification({ message: error });
      });
  }, [applicationId, dispatch, navigate, nextStep, onClearStates, setFieldValue, values.renterAddress]);
  const updateAddress = useCallback(() => {
    if (address.id) {
      dispatch(
        updateRenterAddressInformation({
          id: address.id,
          address: getAddressToApiFormat(values.renterAddress),
        })
      )
        .unwrap()
        .then((response) => {
          setFieldValue('renterAddress', response.addresses[response.addresses.length - 1]);
          onClearStates();
          navigate(
            nextStep
              ? nextStep
              : renterRoutes.generateAddressHistoryInformation(
                  Number(applicationId),
                  RenterAddressSteps.ADDRESS_HISTORY
                )
          );
        })
        .catch((error) => {
          Notification({ message: error });
        });
    }
  }, [address.id, applicationId, dispatch, navigate, nextStep, onClearStates, setFieldValue, values.renterAddress]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const onClickHandler = useCallback(() => {
    if (!address.id) {
      createAddress();
    } else {
      updateAddress();
    }
  }, [address, createAddress, updateAddress]);

  return (
    <div className={styles.Container}>
      {!shouldClassify ? (
        <div className={styles.priorAddressContainer}>
          <div className={styles.addresBoxContainer}>
            <AddressBox address={address} />
          </div>

          <div className={styles.textFieldContainer}>
            <span className={styles.textFieldLabel}>{t('renter.PriorAddress.apt')}</span>
            <FormikField name={`renterAddress.apartmentNumber`} />
          </div>
          <div
            className={classNames(styles.rangeDatePickerContainer, {
              [styles.errorDatePickerContainer]: !isEmpty(errors),
            })}
          >
            <CustomRangeDatePicker
              leftCalendarName={`renterAddress.fromDate`}
              rightCalendarName={`renterAddress.toDate`}
              leftCalenderStartDate={PRIOR_ADDRESS_START_DATE}
              leftCalenderEndDate={endDate}
              rightCalenderStartDate={PRIOR_ADDRESS_START_DATE}
              rightCalenderEndDate={endDate}
              defaultSelected={defaultSelected}
            />
          </div>
          <div className={classNames(indexStyles.renterButtonContainer, styles.addressButtonConatiner)}>
            <RCButton
              className={classNames(indexStyles.renterButton, styles.priorAddressButton)}
              variant="outline"
              disabled={isButtonDisabled}
              onClick={onIRentClickHandler}
            >
              {t('renter.ConfirmPresentAddress.iRent')}
            </RCButton>
          </div>
          <div className={classNames(indexStyles.renterButtonContainer, styles.addressButtonConatiner)}>
            <RCButton
              className={classNames(indexStyles.renterButton, styles.priorAddressButton)}
              onClick={() => {
                setShouldClassify(true);
              }}
              disabled={isButtonDisabled}
              variant="outline"
            >
              {t('renter.ConfirmPresentAddress.iDoNotRent')}
            </RCButton>
          </div>
        </div>
      ) : (
        <PresentAddressInformation
          mainHeading={t('renter.ConfirmPresentAddress.ifNotRenting')}
          subHeading={t('renter.ConfirmPresentAddress.livingSituation')}
          isPrior
          address={address}
          onClickHandler={onClickHandler}
        />
      )}
    </div>
  );
};

export default PriorAddress;
