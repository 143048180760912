import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckIcon } from 'assets/svgs/CheckIcon.svg';

import styles from './StripeDocumentUpload.module.scss';

export const AcceptedIdType = (): JSX.Element => {
  const { t } = useTranslation();
  const ACCEPTED_TYPES = [
    t('renter.CollectedInformation.drivingLicense'),
    t('renter.CollectedInformation.passport'),
    t('renter.CollectedInformation.stateId'),
    t('renter.CollectedInformation.nationalId'),
  ];

  return (
    <Row className={styles.AcceptedContainer}>
      <div className={styles.AcceptedText}>{t('renter.CollectedInformation.acceptedType')}</div>
      {ACCEPTED_TYPES.map((type, index) => (
        <Col key={`${type}-${index}}`} xs={6} md={6} className={styles.AcceptedItemContainer} ms-auto>
          <span className={styles.AcceptedItem}>
            <CheckIcon className={styles.Icon} />
            <div className={styles.AcceptedItemTitle}>{type}</div>
          </span>
        </Col>
      ))}
    </Row>
  );
};
