import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal as BModal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Form, Formik, FormikErrors } from 'formik';
import { t } from 'i18next';
import { capitalize } from 'lodash-es';
import { renterApplication } from 'redux/selectors/renterSelector';
import { updateMinorToApplication } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterMinorSchema } from 'schema/renterSchema';

import RCButton from 'components/shared/Button/Button';
import CustomDatePicker from 'components/shared/CustomDatePicker/CustomDatePicker';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikField from 'components/shared/TextField/FormikTextField';
import { dateFormatBackend } from 'constants/calendarConstants';
import { endDate, startDate } from 'constants/renterConstants';
import { splitFullName } from 'helpers/user';
import { EditDetailsProps, RenterMinorProps } from 'shared/types/renterTypes';

import RenterModal from '../../RenterModal/RenterModal';

import styles from '../RenterAdult/RenterAdult.module.scss';
const EditDetailsMinorModal = ({
  isModalVisible,
  setIsModalVisible,
  onHideHandler,
  id,
  heading,
}: EditDetailsProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const applications = useSelector(renterApplication);
  const { minors } = applications.find((app) => app.id === Number(currentApplicationId)) || {};
  const minorInfo = useMemo(() => minors?.find((minor) => minor?.id === id), [id, minors]);
  const [formValues, setFormValues] = useState({} as RenterMinorProps);
  const onSubmitHandler = useCallback(
    (values: RenterMinorProps, setErrors: (errors: FormikErrors<RenterMinorProps>) => void) => {
      const { firstName, lastName } = splitFullName(values.fullName);

      dispatch(
        updateMinorToApplication({
          applicationId: Number(currentApplicationId),
          minors: {
            ...values,
            firstName: firstName,
            lastName: lastName ?? null,
            relationship: capitalize(values.relationship),
          },
          minorId: minorInfo?.id,
        })
      )
        .unwrap()
        .then(() => {
          setIsModalVisible(false);
        })
        .catch((errorObject) => {
          if (setErrors) {
            if (!!errorObject?.null) {
              setErrors({ fullName: errorObject?.null });
            } else if (errorObject?.lastName) {
              setErrors({ fullName: `${t('renter.relationship.fullNamePlaceHolder')} ${errorObject?.lastName}` });
            } else if (errorObject?.firstName) {
              setErrors({ fullName: `${t('renter.relationship.fullNamePlaceHolder')} ${errorObject?.firstName}` });
            } else {
              setErrors(errorObject);
            }
          }
        });
    },
    [dispatch, currentApplicationId, minorInfo, setIsModalVisible]
  );

  useEffect(() => {
    if (minorInfo) {
      setFormValues({
        ...minorInfo,
        fullName: `${minorInfo.firstName ?? ''} ${minorInfo?.lastName ?? ''}`,
        birthDate: minorInfo.birthDate ? new Date(parseISO(minorInfo?.birthDate as string)) : undefined,
      });
    }
  }, [minorInfo]);

  return (
    <Formik
      initialValues={formValues}
      validationSchema={renterMinorSchema}
      enableReinitialize
      onSubmit={(values: RenterMinorProps, { setErrors }) => {
        const payload = {
          ...values,
          birthDate: values.birthDate && format(new Date(values.birthDate), dateFormatBackend),
        };

        onSubmitHandler(payload, setErrors);
      }}
    >
      {({ isValid, dirty: isDirty }) => (
        <RenterModal show={isModalVisible} onHide={onHideHandler} isDirty={isDirty}>
          <BModal.Body>
            <div className={styles.heading}>{heading ?? t('renter.PhotoIdInformation.editDetails')}</div>
            <Form>
              <FormCloseWarning isDirty={isDirty} />
              <div className={styles.maritalContainer}>
                <div className={styles.userField}>
                  <span className={styles.userLabel}>{t('renter.relationship.minor')}</span>
                  <FormikField
                    className={styles.inputBackgroundColor}
                    name={'fullName'}
                    placeholder={t('renter.relationship.fullNamePlaceHolder')}
                  />
                </div>
                <FormikField placeholder="Relationship" name="relationship" label="Relationship" />

                <CustomDatePicker
                  name="birthDate"
                  className={styles.datePicker}
                  floatingLabel={t('renter.relationship.dateOfBirth')}
                  startDate={startDate}
                  endDate={endDate}
                />
                <div className={styles.renterButtonContainer}>
                  <RCButton className={styles.renterButton} variant="outline" disabled={!isValid} type="submit">
                    {t('renter.PhotoIdInformation.savechanges')}
                  </RCButton>
                </div>
              </div>
            </Form>
          </BModal.Body>
        </RenterModal>
      )}
    </Formik>
  );
};

export default EditDetailsMinorModal;
