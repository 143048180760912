//TODO: Temporarily disabled some non-interactive features and will re-enable later.

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { debounce, isEmpty, upperFirst } from 'lodash-es';
import { Option } from 'pages/AgentPofileForm/types';
import { isLoading, organizationMemberSelector, selectedOrganization } from 'redux/selectors/organizationSelector';
import { getOrganizationMembers, resetInviteId, setOrganizationMembers } from 'redux/slices/organizationSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as DropDownFillArrow } from 'assets/svgs/BlackDownArrow.svg';
import { ReactComponent as SortIcon } from 'assets/svgs/SortIcon.svg';
import RCButton from 'components/shared/Button/Button';
// import { CheckBox } from 'components/shared/Checkbox/CheckBox.stories';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import SearchBar from 'components/shared/SearchBar/SearchBar';
import UserInfoBox from 'components/shared/UserInfoBox/UserInfoBox';
import {
  INITIAL_USER_FILTER,
  OrganizationSortBy,
  OrganizationSortTypes,
  OrgazniationNavMenu,
  SEARCH_DEBOUNCE_TIME,
  SKELETON_ELEMENTS,
  SORT_INITIAL_VALUE,
  USERS_FILTER_OPTIONS,
  UsersControlOptions,
} from 'constants/organizationConstants';
import { parseResponseErrors } from 'helpers/helper';
import { getOrganizationGuestCount, setSortByParams } from 'helpers/organizationHelper';
import { pluralize } from 'helpers/user';
import { Notification } from 'shared/Notification/Notification';
import { hasInvitePrivilege } from 'shared/types/agentInvite';
import { UserIndexPageProps } from 'shared/types/agentTypes';
import { SortParams, UserInformation } from 'shared/types/organizationTypes';

import OrganizationModal from '../OrganizationModal/OrganizationModal';

import styles from './UserIndexPage.module.scss';

const UserIndexPage = ({
  isInviteSuccess,
  setIsInviteSuccess,
  isModalOpen,
  setIsModalOpen,
}: UserIndexPageProps): JSX.Element => {
  const currentOrganization = useSelector(selectedOrganization);
  const isPageLoading = useSelector(isLoading);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const stepName = searchParams.get('step') || OrgazniationNavMenu.USERS;
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const organizationMembers = useSelector(organizationMemberSelector);
  const [sortOptions, setSortOptions] = useState<SortParams>({
    sortBy: searchParams.get(UsersControlOptions.SORTBY) || SORT_INITIAL_VALUE.sortBy,
    sortType: searchParams.get(UsersControlOptions.SORTTYPE) || SORT_INITIAL_VALUE.sortType,
  });
  const searchValue = searchParams.get(UsersControlOptions.TARGET) || '';
  const [filterValue, setFilterValue] = useState({
    value: searchParams.get(UsersControlOptions.FILTER) || INITIAL_USER_FILTER.value,
    label: searchParams.get(UsersControlOptions.FILTER) || INITIAL_USER_FILTER.label,
  });
  const skeletonElements = useMemo(
    () =>
      Array.from({ length: SKELETON_ELEMENTS }, (_, index) => (
        <div key={`Skeleton-user-${index}`} className={styles.loadingSkeleton}>
          <Skeleton height={90} />
        </div>
      )),
    []
  );
  const onSortClickHandler = useCallback(
    (name: string) => {
      const newSortBy = setSortByParams(name, sortOptions);

      setSortOptions(newSortBy);

      setSearchParams({
        target: searchValue,
        sortBy: newSortBy.sortBy,
        sortType: newSortBy.sortType,
        filter: filterValue.value,
      });
    },
    [filterValue, searchValue, setSearchParams, sortOptions]
  );
  const handleSearch = useCallback(
    (target: string): void => {
      setSearchParams({
        target: target,
        sortBy: SORT_INITIAL_VALUE.sortBy,
        sortType: SORT_INITIAL_VALUE.sortType,
        filter: filterValue.value,
      });
      setSortOptions(SORT_INITIAL_VALUE);
    },
    [filterValue, setSearchParams]
  );
  const searchResults = useMemo(() => debounce(handleSearch, SEARCH_DEBOUNCE_TIME), [handleSearch]);
  const onClickHandler = useCallback(() => {
    setIsInviteSuccess(false);
    setIsModalOpen(true);
  }, [setIsInviteSuccess, setIsModalOpen]);
  const onHideHandler = useCallback(() => {
    setIsModalOpen(false);
    dispatch(resetInviteId());
  }, [dispatch, setIsModalOpen]);
  const onFilterChangeHandler = useCallback(
    (e: Option) => {
      setSearchParams({
        target: searchValue,
        sortBy: sortOptions.sortBy,
        sortType: sortOptions.sortType,
        filter: e?.value ?? '',
      });
      setFilterValue({ value: e?.value ?? '', label: e?.label ?? '' });
    },
    [searchValue, setSearchParams, sortOptions.sortBy, sortOptions.sortType]
  );
  //Returns A string
  //Containing Total Members and Guest in the Organization
  const getMembersCount = useMemo(
    (): string =>
      `${pluralize(
        (Array.isArray(organizationMembers) ? organizationMembers : [organizationMembers]).length ?? 0,
        t('agent.userIndexPage.members')
      )} ${pluralize(
        getOrganizationGuestCount(Array.isArray(organizationMembers) ? organizationMembers : [organizationMembers]),
        t('agent.userIndexPage.guests')
      )}`,
    [organizationMembers]
  );
  const renderSortIcon = useMemo(
    () =>
      function (name: string): JSX.Element {
        return (
          <SortIcon
            className={classNames(
              {
                [styles.upSortIcon]: !(
                  sortOptions.sortBy === name && sortOptions.sortType === OrganizationSortTypes.ASCENDING
                ),
              },
              {
                [styles.downSortIcon]: !(
                  sortOptions.sortBy === name && sortOptions.sortType === OrganizationSortTypes.DESCENDING
                ),
              }
            )}
          />
        );
      },
    [sortOptions]
  );
  const renderUserInfo = (members: UserInformation[], isDataLoading: boolean, skeleton: JSX.Element[]): JSX.Element => {
    if (isDataLoading) {
      if (isFirstTimeLoading) {
        return <div className={styles.skeletonContainer}>{skeleton}</div>;
      } else {
        return (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        );
      }
    } else if (isEmpty(members) && !isDataLoading) {
      return <div className={styles.dataNotFound}>{t('agent.userIndexPage.sorryWeCouldnt')}</div>;
    } else if (!isEmpty(members)) {
      return (
        <div className={styles.userInfoContainer}>
          <UserInfoBox members={members} />
        </div>
      );
    }

    return <div />;
  };

  useEffect(() => {
    searchResults.cancel();
  }, [searchResults]);
  useEffect(() => {
    if (currentOrganization.id) {
      dispatch(
        getOrganizationMembers({
          id: currentOrganization.id,
          target: searchValue,
          sortBy: sortOptions.sortBy,
          sortType: sortOptions.sortType,
          filter: filterValue.value,
        })
      )
        .unwrap()
        .then((response) => {
          dispatch(setOrganizationMembers(response));
          setIsFirstTimeLoading(false);
        })
        .catch((error) => {
          Notification({ message: parseResponseErrors(error) });
        });
    }
  }, [currentOrganization, dispatch, filterValue, searchParams, searchValue, sortOptions]);
  //If invite was successfull, then close the modal
  useEffect(() => {
    if (isInviteSuccess) {
      setIsModalOpen(false);
    }
  }, [isInviteSuccess, setIsModalOpen]);

  return (
    <div className={styles.userIndexContainer}>
      {isModalOpen && !isInviteSuccess && (
        <OrganizationModal
          setIsInviteSuccess={setIsInviteSuccess}
          onHideHandler={onHideHandler}
          isModalOpen={isModalOpen}
          notInvitedTitle={t('organizations.inviteAgent.modal.title')}
        />
      )}
      <div className={styles.pageTitleContent}>
        <div className={styles.organizationName}>{upperFirst(currentOrganization.name)}</div>
        <div className={styles.usersInformation}>
          <div className={styles.statsInformation}>
            <div className={styles.selectedStepName}>{stepName}</div>
            <div className={styles.userStats}>
              {isPageLoading ? <Skeleton width={100} height={20} /> : getMembersCount}
            </div>
          </div>
          {hasInvitePrivilege(currentOrganization) && (
            <RCButton className={styles.inviteButton} onClick={onClickHandler}>
              {t('agent.userIndexPage.inviteUser')}
            </RCButton>
          )}
        </div>
      </div>
      <div className={styles.searchContainer}>
        <div
          className={classNames(styles.searchContainer, {
            [styles.hideField]: !hasInvitePrivilege(currentOrganization),
          })}
        >
          <SearchBar
            className={styles.searchBar}
            value={searchValue}
            handleSearch={searchResults}
            placeholder={t('agent.userIndexPage.searchByName')}
          />
        </div>
        <div
          className={classNames(styles.statusSelectContainer, {
            [styles.hideField]: !hasInvitePrivilege(currentOrganization),
          })}
        >
          <ReactSelect
            isSearchable={false}
            value={filterValue}
            options={USERS_FILTER_OPTIONS}
            dropDownIcon={<DropDownFillArrow />}
            onChange={onFilterChangeHandler}
          />
        </div>
      </div>
      <div className={styles.userInformationContainer}>
        <div className={styles.controlBarContainer}>
          <div className={styles.optionsContainer}>
            <div className={styles.checkBoxContainer}>{/* <CheckBox /> */}</div>
            <div className={styles.Options}>
              <div className={styles.NameOptionContainer}>
                <div className={styles.FakeImage} />
                <RCButton
                  onClick={() => {
                    onSortClickHandler(OrganizationSortBy.NAME);
                  }}
                  className={classNames(styles.nameOption, styles.ButtonClass)}
                >
                  <div
                    className={classNames(styles.innerHeader, styles.controlOption, {
                      [styles.selectedOption]:
                        sortOptions.sortBy === OrganizationSortBy.NAME &&
                        sortOptions.sortType === OrganizationSortTypes.DESCENDING,
                    })}
                  >
                    <div className={styles.option}>{t('agent.userIndexPage.name')}</div>
                    <div className={styles.controlIcon}>{renderSortIcon(OrganizationSortBy.NAME)}</div>
                  </div>
                </RCButton>
              </div>

              <RCButton
                onClick={() => {
                  onSortClickHandler(OrganizationSortBy.ROLE);
                }}
                className={classNames(styles.roleOption, styles.ButtonClass)}
              >
                <div
                  className={classNames(styles.innerHeader, styles.controlOption, {
                    [styles.selectedOption]: sortOptions.sortBy === OrganizationSortBy.ROLE,
                  })}
                >
                  <div className={styles.option}>{t('agent.userIndexPage.role')}</div>
                  <div className={styles.controlIcon}>{renderSortIcon(OrganizationSortBy.ROLE)}</div>
                </div>
              </RCButton>
              <RCButton
                onClick={() => {
                  onSortClickHandler(OrganizationSortBy.LAST_ACTIVE_TIME);
                }}
                className={classNames(styles.roleOption, styles.ButtonClass)}
              >
                <div
                  className={classNames(styles.innerHeader, styles.controlOption, {
                    [styles.selectedOption]: sortOptions.sortBy === OrganizationSortBy.LAST_ACTIVE_TIME,
                  })}
                >
                  <div className={styles.option}>{t('agent.userIndexPage.lastActive')}</div>
                  <div className={styles.controlIcon}>{renderSortIcon(OrganizationSortBy.LAST_ACTIVE_TIME)}</div>
                </div>
              </RCButton>
              <div className={styles.dotsOption} />
            </div>
          </div>
        </div>
        {renderUserInfo(organizationMembers, isPageLoading, skeletonElements)}
      </div>
    </div>
  );
};

export default UserIndexPage;
