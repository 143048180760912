import { useTranslation } from 'react-i18next';

import { ReactComponent as ErrorInfo } from 'assets/svgs/ErrorInfo.svg';
import Button from 'components/shared/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import { RenterConfirmModalProps } from 'shared/types/renterTypes';

import styles from './RenterConfirmChangesModal.module.scss';

const RenterConfirmChangesModal = ({ onExit, onGoBack, shouldOpen }: RenterConfirmModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Modal show={shouldOpen} onHide={onGoBack} contentClassName={styles.contentClassName}>
      <div className={styles.container}>
        <div>
          <ErrorInfo />
        </div>
        <div className={styles.heading}>{`${t('renter.PhotoIdInformation.savechanges')}?`}</div>
        <div className={styles.subHeading}>{t('renter.relationship.discardText')}</div>
        <div className={styles.buttonsContainer}>
          <div>
            <Button variant="outline" onClick={onGoBack}>
              {t('renter.relationship.discardBackButton')}
            </Button>
          </div>
          <div>
            <Button onClick={onExit}>{t('renter.relationship.discardYesButton')}</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RenterConfirmChangesModal;
