import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { FinancialReportBodyProps } from 'shared/types/reportTypes';

import { IncomeReportItem } from '../IncomeReportItem/IncomeReportItem';

import styles from './FinancialReportBody.module.scss';

export const FinancialReportBody = ({ transactions }: FinancialReportBodyProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.Title}>{t('incomeReport.deposits')}</div>
      <div className={styles.Statement}>{t('incomeReport.aListOfAllDeposits')}</div>
      <div className={styles.Transactions}>
        {transactions?.map((singleTransaction, index) => (
          <IncomeReportItem
            key={`Parent-Transaction-${singleTransaction}-${index}`}
            month={format(new Date(singleTransaction[0].transactionDate), 'MMMM')}
            transactions={singleTransaction}
          />
        ))}
      </div>
    </div>
  );
};
