import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { renterInviteCode, selectApplicationId } from 'redux/selectors/authSelector';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { setApplicationId, setRenterInviteCode } from 'redux/slices/authSlice';
import { acceptRenterInvite } from 'redux/slices/authSlice';
import { getApplicationPropertyLink, getRenterProfileInformation, getSelectedProperty } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { RenterNavbar } from 'components/shared/Navbar';
import { renterInviteCodeIntialValues } from 'constants/authConstants';
import { ApplicationPropertyLinksResponse } from 'shared/types/renterCooccupants';

import styles from './renterLayout.module.scss';
const RenterLayout = (): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { applicationId } = useParams();
  const { t } = useTranslation();
  const cooccupantInviteCode = useSelector(renterInviteCode);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const selectedApplicationId = useSelector(selectApplicationId);
  const isCoocupantInviteAccepted = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (cooccupantInviteCode?.inviteCode && renterDetails?.userId && !isCoocupantInviteAccepted.current) {
      isCoocupantInviteAccepted.current = true;
      dispatch(acceptRenterInvite(cooccupantInviteCode))
        .unwrap()
        .then((res) => {
          dispatch(setRenterInviteCode(renterInviteCodeIntialValues));
          dispatch(setApplicationId(res?.applicationId));
          dispatch(getRenterProfileInformation());
        });
    }
  }, [cooccupantInviteCode, renterDetails, dispatch, t, navigate]);
  useEffect(() => {
    dispatch(getRenterProfileInformation());
  }, [dispatch]);
  useEffect(() => {
    if (Number(applicationId)) {
      dispatch(getApplicationPropertyLink(Number(applicationId)))
        .unwrap()
        .then((res: ApplicationPropertyLinksResponse) => {
          const propertyId: number = res.applicationProperties[0].propertyId;

          dispatch(getSelectedProperty({ propertyId }));
        });
    }
  }, [dispatch, applicationId, t]);

  useEffect(() => {
    if (Number(applicationId) && Number(applicationId) !== selectedApplicationId) {
      dispatch(setApplicationId(Number(applicationId)));
    }
  }, [applicationId, dispatch, selectedApplicationId]);

  return (
    <div className={styles.renterLayoutContainer}>
      <RenterNavbar />
      <Outlet />
    </div>
  );
};

export default RenterLayout;
