import { useCallback, useEffect, useState } from 'react';
import { Col, Container, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Formik } from 'formik';
import { t } from 'i18next';
import { isSaving, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterPhoneNumbers, deleteRenterPhoneNumber } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { NavigationKeys } from 'constants/renterConstants';
import { PhoneNumberType, RenterProfileSteps } from 'constants/renterConstants';
import { tabTitle } from 'helpers/helper';
import { formatPhoneNumber } from 'helpers/renterHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { PhoneNumberInitialValues } from 'shared/types/renterTypes';

import EditPhoneNumberModal from '../components/EditPhoneNumberModal/EditPhoneNumberModal';
import RenterContainer from '../components/RenterContainer';
import { RenterDeleteConfirmationModal } from '../components/RenterDeleteConfirmationModal/RenterDeleteConfirmationModal';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import styles from './RenterPhoneNumberCard.module.scss';
const RenterPhoneNumberCard = (): JSX.Element => {
  // HOOKS
  const navigate = useNavigate();
  const maxHeight = useDeviceHeight(220);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [searchParams] = useSearchParams();
  const { applicationId } = useParams();
  // SELECTORS
  const { renterPhoneNumbers } = useSelector(selectRenterProfileInformation);
  const isSavingInformation = useSelector(isSaving);
  // LOCAL STATES
  const renterDetails = useSelector(selectRenterProfileInformation);
  const [canAddAnother, setAddAnother] = useState(false);
  const [isPhoneEditModalOpen, setIsPhoneEditModalOpen] = useState(false);
  const [isPhoneDeleteModalOpen, setIsPhoneDeleteModalOpen] = useState(false);
  const [selectedPhoneIndex, setSelectedPhoneIndex] = useState(0);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const navigateNext = searchParams.get(NavigationKeys.NEXT);

  tabTitle('Your Application | Perosnal Profile');
  const renderTooltip = (index: number): JSX.Element => (
    <Popover id="popover-positioned-bottom">
      <Popover.Body className={styles.PopoverBody}>
        <div
          className={styles.PopoverWrapper}
          onClickCapture={() => {
            setIsOverlayVisible(false);
            setIsPhoneEditModalOpen(true);
          }}
        >{`Edit Phone ${index + 1}`}</div>
        <div
          className={classNames(styles.PopoverWrapper, styles.Bold)}
          onClickCapture={() => {
            setIsOverlayVisible(false);
            setIsPhoneDeleteModalOpen(true);
          }}
        >
          Delete
        </div>
      </Popover.Body>
    </Popover>
  );
  const onSaveAndContinueClick = useCallback(
    (nextStep?: string): void => {
      if (navigateNext) {
        navigate(navigateNext);
      } else if (nextStep) {
        renterDetails?.stripeVerified ||
        (renterDetails?.firstName && renterDetails?.lastName && renterDetails?.birthDate)
          ? navigate(renterRoutes.generatePersonalInformationUrl(nextStep, Number(applicationId)))
          : navigate(renterRoutes.generateRenterStripeIdentityUrl(`${applicationId}`));
      }
    },
    [applicationId, navigate, navigateNext, renterDetails]
  );
  const handleDelete = useCallback(() => {
    if (!renterPhoneNumbers) {
      return;
    }

    const { id } = renterPhoneNumbers[selectedPhoneIndex];

    dispatch(deleteRenterPhoneNumber(Number(id)))
      .unwrap()
      .then(() => setIsPhoneDeleteModalOpen(false));
  }, [dispatch, renterPhoneNumbers, selectedPhoneIndex]);

  useEffect(() => {
    if ((renterPhoneNumbers?.length ?? 0) <= 0) {
      setAddAnother(true);
    }
  }, [renterPhoneNumbers]);

  return (
    <RenterContainer>
      <div className={styles.container} style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
        <div className={styles.description}>
          <Formik
            enableReinitialize
            initialValues={PhoneNumberInitialValues}
            onSubmit={(values, { resetForm, setFieldError }) => {
              const { phoneNumber, type } = values;

              dispatch(
                addRenterPhoneNumbers({
                  values: { type: type, phoneNumber: `+${phoneNumber}` },
                })
              ).then((res: any) => {
                if (res?.error) {
                  setFieldError('phoneNumber', res?.payload?.errorMessage);
                } else {
                  resetForm();
                  setAddAnother(false);
                }
              });
            }}
          >
            {({ isValid, values, handleSubmit, dirty: isDirty, setFieldValue, resetForm }) => (
              <form onSubmit={handleSubmit}>
                <RenterIconInfoContainer />
                <FormCloseWarning isDirty={isDirty} />
                <div className={styles.headingContainer}>{t('renter.phone.addPhoneNum')}</div>

                <Col className={styles.PhoneContainer}>
                  {renterPhoneNumbers?.map((phoneNum, index) => (
                    <Container className={styles.PhoneComponent} key={index}>
                      <div className={styles.FloatingLabel}>{`PHONE ${index + 1}`}</div>
                      <Row className="align-items-center">
                        <Col className="p-0">
                          <div className={styles.PhoneNum}>{formatPhoneNumber(phoneNum.phoneNumber as string)}</div>
                          <div className={styles.PhoneType}>{phoneNum.type}</div>
                        </Col>
                        <Col xs="auto" onBlur={() => setIsOverlayVisible(false)} className={styles.OverlayContainer}>
                          <RCButton
                            className={styles.OverlayButton}
                            variant="outline"
                            onBlur={() => setIsOverlayVisible(false)}
                          >
                            <OverlayTrigger
                              rootClose
                              flip
                              trigger="click"
                              key="bottom"
                              placement="bottom"
                              show={isOverlayVisible && selectedPhoneIndex === index}
                              overlay={renderTooltip(index)}
                            >
                              <ThreeDotsIcon
                                onClick={() => {
                                  setSelectedPhoneIndex(index);
                                  setIsOverlayVisible(true);
                                }}
                              />
                            </OverlayTrigger>
                          </RCButton>
                        </Col>
                      </Row>
                    </Container>
                  ))}
                </Col>

                <div>
                  <div className={styles.phoneNumberSelectConatiner}>
                    {canAddAnother && (
                      <ReactSelect
                        options={PhoneNumberType.filter(
                          (type) => !renterPhoneNumbers?.map((phone) => phone.type).includes(type.label)
                        )}
                        value={PhoneNumberType.find((val) => val.name === values.type)}
                        placeholder={t('renter.phone.selectNumberType')}
                        onChange={(e) => {
                          setFieldValue('type', e?.name);
                        }}
                        blurInputOnSelect
                        isDisabled={isSavingInformation}
                      />
                    )}
                  </div>
                  {values.type && (
                    <FormikPhoneNumber
                      name={`phoneNumber`}
                      isDisabled={isSavingInformation}
                      label={values.type}
                      isCountryCodeDisabled={false}
                      onlyCountry={[]}
                      isTouchedOnChange
                      isCountryCodeEditable={false}
                      placeholder={t('renter.phone.placeHolder')}
                    />
                  )}
                </div>

                <div className={classNames(styles.renterButtonContainer, styles.buttonMargin)}>
                  {(renterPhoneNumbers?.length ?? 0) < 3 &&
                    (canAddAnother ? (
                      <Row className="m-0 gap-2">
                        <RCButton
                          disabled={!isValid || !values.phoneNumber}
                          className={classNames(styles.renterAddButton, styles.renterButton)}
                          variant="outline"
                          type="submit"
                        >
                          Save
                        </RCButton>
                        {!!renterPhoneNumbers?.length && (
                          <RCButton
                            className={classNames(styles.renterAddButton, styles.renterButton)}
                            variant="outline"
                            onClick={() => {
                              resetForm();
                              setAddAnother(false);
                            }}
                            disabled={!renterPhoneNumbers?.length}
                          >
                            Cancel
                          </RCButton>
                        )}
                      </Row>
                    ) : (
                      <RCButton
                        className={classNames(styles.renterAddButton, styles.renterButton)}
                        variant="outline"
                        onClick={() => {
                          setAddAnother(true);
                        }}
                      >
                        {t('renter.phone.anotherButtonText')}
                      </RCButton>
                    ))}

                  <RCButton
                    disabled={!renterPhoneNumbers?.length || canAddAnother}
                    className={classNames(styles.renterButton, styles.renterButtonSmall)}
                    variant="outline"
                    onClick={() => {
                      onSaveAndContinueClick(RenterProfileSteps.BASICINFO);
                    }}
                  >
                    Looks Good
                  </RCButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
        {isPhoneEditModalOpen && (
          <EditPhoneNumberModal
            onHide={() => setIsPhoneEditModalOpen(false)}
            shouldShowModal={isPhoneEditModalOpen}
            index={selectedPhoneIndex}
          />
        )}
        {isPhoneDeleteModalOpen && (
          <RenterDeleteConfirmationModal
            shouldOpen={isPhoneDeleteModalOpen}
            handleDelete={handleDelete}
            onGoBack={() => setIsPhoneDeleteModalOpen(false)}
            heading={`${t('renter.phoneNumber.deletePhone')}?`}
            validationHeading={t('renter.phoneNumber.deleteValidation')}
          />
        )}
      </div>
    </RenterContainer>
  );
};

export default RenterPhoneNumberCard;
//
