import React, { useCallback, useMemo } from 'react';
import { SingleValue } from 'react-select';
import { useField } from 'formik';
import { capitalize } from 'lodash-es';

import CustomErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import styles from 'components/shared/PhoneNoTypeSelectField/PhoneNoTypeSelectField.module.scss';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { PhoneNoTypeSelectFieldProps } from 'shared/types/agentTypes';
import { SelectOption } from 'shared/types/selectOption';
/**
 * @params take the name of the field which then bind with formik that holds all the field information of the form
 * StateSelectField component will show the list of US States to select among them or to enter one using input field
 */

const PhoneNoTypeSelectField = ({
  name,
  backgroundColor,
  placeholder,
  ariaLabel,
  hasFloatingLabel,
  statesArray,
  dropDownIcon,
}: PhoneNoTypeSelectFieldProps): JSX.Element => {
  const [field, meta, helpers] = useField({ name });
  const value = useMemo(
    () => (field.value ? statesArray.find((state) => state.value === capitalize(field.value)) : null),
    [field, statesArray]
  );
  const handleOnChange = useCallback(
    (option: SingleValue<SelectOption<string>>): void => {
      helpers.setValue(option?.value);
    },
    [helpers]
  );
  const updateTouch = (touched: boolean): void => {
    helpers.setTouched(touched);
  };
  const onBlur = async (e: React.FocusEvent): Promise<void> => {
    await field.onBlur(e);

    helpers.setTouched(true);
  };

  return (
    <>
      <ReactSelect
        options={statesArray}
        isSearchable
        value={value ? { label: value.value, value: value.value } : null}
        onChange={handleOnChange}
        aria-label={ariaLabel}
        placeholder={placeholder}
        shouldShowValueInField
        hasFloatingLabel={hasFloatingLabel}
        isCreateable
        backgroundColor={backgroundColor}
        customValueClass={styles.CustomValueClass}
        dropDownIcon={dropDownIcon}
        hasErrors={meta.touched && !!meta.error}
        onFocus={() => updateTouch(false)}
        onBlur={onBlur}
      />
      <span>{meta.touched && !!meta.error && <CustomErrorMessage message={meta.error} />}</span>
    </>
  );
};

export default PhoneNoTypeSelectField;
