import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/shared/Button/Button';
import { renterRoutes } from 'shared/routes';
import { furtherQuestions } from 'shared/types/renterFurtherInformation';

import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import QuestionContainer from './QuestionContainer/QuestionContainer';

import indexStyles from './../RenterIndex.module.scss';
import styles from './RenterFurtherInformation.module.scss';

const RenterFurtherInformation = (): JSX.Element => {
  const { applicationId } = useParams();
  const [formValues, setFormValues] = useState(furtherQuestions);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleToggleClick = useCallback(
    (index: number): void => {
      const newValues = [...formValues];

      newValues[index].answer = !formValues[index].answer;
      setFormValues(newValues);
    },
    [formValues]
  );
  const onClickHandler = useCallback(() => {
    navigate(renterRoutes.generateRenterPaymentVerification(applicationId));
  }, [applicationId, navigate]);

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div className={styles.renterFurtherInformation}>
          <div>
            <RenterIconInfoContainer />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.heading}>{t('renter.furtherInformation.heading')}</div>
            <div className={styles.questionsContainer}>
              {formValues.map(({ id, question, type, answer: willAnswer }, index) => (
                <div key={id}>
                  <QuestionContainer
                    question={question}
                    iconType={type}
                    onChange={() => handleToggleClick(index)}
                    value={willAnswer}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={(indexStyles.renterButtonContainer, styles.buttonContainer)}>
            <Button className={styles.renterButton} variant="outline" onClick={onClickHandler}>
              {t('saveAndContinue')}
            </Button>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default RenterFurtherInformation;
