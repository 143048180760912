import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { Option, PropertySelectProps } from 'pages/AgentPofileForm/types';

import { ReactComponent as InfoIcon } from 'assets/svgs/infoIconFilled.svg';
import { ReactComponent as SimpleTick } from 'assets/svgs/SimpleTick.svg';
import { ReactComponent as WarningIcon } from 'assets/svgs/YellowWarningIcon.svg';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import Tooltip from 'components/shared/Tooltip/Tooltip';

import styles from './PropertySelect.module.scss';

const PropertySelect = ({
  isEnabled,
  publishedList,
  onChangeHandler,
  tooltipText,
}: PropertySelectProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState('');
  const { t } = useTranslation();
  const onValueChangeHandler = useCallback(
    (e: Option) => {
      setSelectedValue(e?.label ?? '');

      if (onChangeHandler) {
        onChangeHandler(e);
      }
    },
    [onChangeHandler]
  );
  const getPublishedListText = useMemo(
    (): JSX.Element => (
      <>
        {`${
          publishedList?.[0]?.value
            ? t('agent.propertySelect.listOfPublished')
            : t('agent.propertySelect.nonePublished')
        }  ${t('agent.propertySelect.customAddress')}`}
        {isEnabled ? (
          <span className={styles.enabledContainer}>
            {t('agent.propertySelect.enabled')} <SimpleTick className={styles.iconContainer} />
          </span>
        ) : (
          <span className={styles.disabledContainer}>{t('agent.propertySelect.disabled')}</span>
        )}
      </>
    ),
    [isEnabled, publishedList, t]
  );
  const getPropertyFieldContent = useMemo(
    (): JSX.Element => (
      <>
        {!selectedValue && <div className={styles.textContent}>{getPublishedListText}</div>}
        {selectedValue && <span className={styles.valueContainer}> {selectedValue}</span>}
        {isEmpty(publishedList) && (
          <span>
            {isEnabled && (
              <Tooltip
                placement="top"
                text={tooltipText ?? ''}
                icon={<InfoIcon />}
                customIconClassName={styles.infoIconContainer}
              />
            )}
            {!isEnabled && <WarningIcon className={styles.infoIconContainer} />}
          </span>
        )}
      </>
    ),
    [getPublishedListText, isEnabled, publishedList, selectedValue, tooltipText]
  );

  return (
    <div className={styles.propertySelectContainer}>
      <div className={styles.textContainer}>{getPropertyFieldContent}</div>
      {!isEmpty(publishedList) && (
        <ReactSelect
          isSearchable={false}
          options={publishedList}
          isClearable
          onChange={(e) => onValueChangeHandler(e)}
          placeholder=""
        />
      )}
    </div>
  );
};

export default PropertySelect;
