import { t } from 'i18next';

import { ReactComponent as EmptyPropertyIcon } from 'assets/svgs/EmptyPropertyIcon.svg';

import styles from './PropertyIndex.module.scss';
const EmptyPropertyIndex = (): JSX.Element => (
  <div className={styles.emptyPropertyContainer}>
    <div className={styles.emptyPropertyDiv}>
      <div className={styles.iconDiv}>
        <EmptyPropertyIcon />
      </div>
      <div className={styles.textHeading}>{t('agent.properties.emptyHeading')}</div>
      <div className={styles.emptyMessageContainer}>
        <div className={styles.emptyMessage}>
          {t('agent.properties.emptyText')}
          {t('agent.properties.emptyMessage')}
        </div>
      </div>
    </div>
  </div>
);

export default EmptyPropertyIndex;
