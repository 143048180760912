import { useTranslation } from 'react-i18next';

import { ReactComponent as Linkedin } from 'assets/svgs/Linkedin.svg';
import { ReactComponent as Shared } from 'assets/svgs/shared.svg';
import Button from 'components/shared/Button/Button';

import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import indexStyles from '../RenterIndex.module.scss';
import renterConnectLinkedin from './ConnectLinkedin.module.scss';

const RenterConnectLinkedin = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div className={renterConnectLinkedin.container}>
          <RenterIconInfoContainer />
          <div className={renterConnectLinkedin.linkedinHeading}>
            {t('renter.linkedin.heading1')}
            <span className={renterConnectLinkedin.textContainer}> LinkedIn </span>
            {t('renter.linkedin.heading2')}
          </div>
          <div className={renterConnectLinkedin.buttonContainer}>
            <Button variant="outline" className={(renterConnectLinkedin.button, renterConnectLinkedin.buttonLinkedIn)}>
              <Linkedin />
              <div> {t('renter.linkedin.button')}</div>
              <Shared />
            </Button>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default RenterConnectLinkedin;
