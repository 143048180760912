import { Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ErrorIcon } from 'assets/svgs/ErrorOutlineIcon.svg';
import { ReactComponent as WarningIcon } from 'assets/svgs/WarningGreyIcon.svg';
import { ReactComponent as LoadingIcon } from 'assets/svgs/WhiteLoadingIcon.svg';
import { otpExpireyTime, otpFieldMaxDigits } from 'shared/constants';
import { OTPModalBodyProps } from 'shared/types/renterTypes';

import RCButton from '../Button/Button';
import { OtpInputField } from '../OTPInputField/OTPInputField';

import styles from './OTPModalBody.module.scss';

export const OTPModalBody = ({
  otpFailed = false,
  shouldShowExpirey = false,
  isLoading = false,
  hasErrors = false,
  onOtpChangeHandler,
  setIsSubmitClicked,
  initialOtpCode,
  isExpired,
  onResendOtpClickHandler,
}: OTPModalBodyProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.Heading}>{t('otpModal.enterCode')}</div>
      <div className={styles.TextContainer}>
        {t('otpModal.enterThe')}&nbsp;
        <span className={styles.HighlightedText}>{t('otpModal.oneTimePassword')}</span>&nbsp;{t('otpModal.generatedBy')}
      </div>
      <div className={classNames(styles.OTPContainer, { [styles.TooltipContainer]: shouldShowExpirey })}>
        {shouldShowExpirey && (
          <BootstrapTooltip placement="top" className={styles.Tooltip}>
            <WarningIcon className={styles.WarningIcon} />
            {`${t('otpModal.yourOtpExpires')} ${otpExpireyTime} ${t('otpModal.minutes')}.`}
          </BootstrapTooltip>
        )}
        <OtpInputField
          initalOtpValue={initialOtpCode}
          hasAutoFocus
          length={otpFieldMaxDigits}
          onChangeOTP={(e) => onOtpChangeHandler(e)}
        />
      </div>
      {(otpFailed || isExpired) && (
        <div className={styles.ErrorText}>
          <ErrorIcon className={styles.ErrorIcon} /> {isExpired ? t('otpModal.otpExpired') : t('otpModal.invalidOtp')}
        </div>
      )}
      <RCButton
        onClick={() => setIsSubmitClicked(true)}
        disabled={isLoading || hasErrors}
        className={styles.ButtonClass}
      >
        {t('renter.guarantorSummary.confirm')} {isLoading && <LoadingIcon className={styles.LoadingIcon} />}
      </RCButton>
      <div className={classNames(styles.FooterText, { [styles.BlurText]: isLoading })}>
        {t('otpModal.didntGetOtp')}&nbsp;
        <RCButton variant="outline" onClick={onResendOtpClickHandler} className={styles.FooterHighlightedText}>
          {t('otpModal.resendOtp')}
        </RCButton>
      </div>
    </div>
  );
};
