import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { VerificationStatus } from 'constants/agentConstants';
import { InProgressYellowSVG } from 'shared/pdf/v2/svgs/InProgressYellowSVG';
import { VerifiedGreenTickSVG } from 'shared/pdf/v2/svgs/VerifiedGreenTickSVG';

const styles = StyleSheet.create({
  WarningContainer: {
    backgroundColor: '#fcdbe3',
    color: '#f00',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
    padding: '0px 3px',
  },
  PendingContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1px',
    backgroundColor: '#fffbf2',
    color: '#eea700',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
    padding: '0px 3px',
  },
  VerifiedContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1px',
    backgroundColor: '#d0f5e0',
    color: '#00ac47',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
    padding: '0px 3px',
  },
});

export const VerificationStatusPDF = ({ status }: { status: VerificationStatus }): JSX.Element => (
  <View>
    {status === VerificationStatus.IN_PROGRESS && (
      <View style={styles.PendingContainer}>
        <Text>{VerificationStatus.IN_PROGRESS}</Text>
        <InProgressYellowSVG />
      </View>
    )}
    {status === VerificationStatus.VERIFIED && (
      <View style={styles.VerifiedContainer}>
        <Text>{VerificationStatus.VERIFIED}</Text>
        <VerifiedGreenTickSVG />
      </View>
    )}
    {status === VerificationStatus.WARNING && <Text style={styles.WarningContainer}>{VerificationStatus.WARNING}</Text>}
  </View>
);
