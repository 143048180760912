import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { deleteVisaDocuments, uploadVisaDocuments } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import CustomDropzone, { CustomPreviewFile } from 'components/shared/DropZone/CustomDropzone/CustomDropzone';
import ImageProgressBox from 'components/shared/ImageProgressBox/ImageProgressBox';

import styles from '../NoCreditHistory/NoCreditHistory.module.scss';
import indexStyles from './../RenterIndex.module.scss';

export const MoreInformation = (): JSX.Element => {
  const { t } = useTranslation();
  const { visaDocuments } = useSelector(selectRenterProfileInformation);
  const [acceptedFiles, setAcceptedFiles] = useState<CustomPreviewFile[]>([]);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { path } = useParams();
  const handleDocumentDelete = (id: number): void => {
    dispatch(deleteVisaDocuments(id));
  };

  useEffect(() => {
    if (isEmpty(acceptedFiles)) {
      return;
    }

    dispatch(uploadVisaDocuments({ document: acceptedFiles[acceptedFiles.length - 1] }));
  }, [dispatch, acceptedFiles]);

  return (
    <div>
      {path === 'other' ? (
        <>
          <span className={indexStyles.renterH2}>{t('renter.creditHistory.nocredit.other.attachDocuments')}</span>
          <span className={classNames(indexStyles.renterH2, indexStyles.highlightedText)}>
            {t('renter.noCreditHistory.other')}
          </span>
          <span className={indexStyles.renterH2}>{t('renter.creditHistory.nocredit.other.processYourFile')}</span>
        </>
      ) : (
        <>
          <span className={indexStyles.renterH2}>{t('renter.creditHistory.nocredit.attachDocuments')}</span>
          <span className={classNames(indexStyles.renterH2, indexStyles.highlightedText)}>
            {t(`renter.creditHistory.nocredit.${path}`)}
          </span>
          <span className={indexStyles.renterH2}>{t('renter.creditHistory.nocredit.processYourFile')}</span>
        </>
      )}
      <div className={styles.customOutterBox}>
        <div className={styles.dropzoneContainer}>
          <CustomDropzone
            acceptedFiles={acceptedFiles}
            setAcceptedFiles={setAcceptedFiles}
            renderInDropzone={true}
            showFiles={false}
          />
        </div>
      </div>

      <div className={styles.exampleImageContainer}>
        {visaDocuments?.map((file, _key) => (
          <ImageProgressBox
            key={_key}
            label={t('renter.fileUploadingState.uploadedFile')}
            fileName={file.fileName as string}
            onRemoveHandler={() => handleDocumentDelete(file.id as number)}
            isImageUploaded={false}
            isRemovable={true}
          />
        ))}
      </div>
    </div>
  );
};
