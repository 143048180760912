import classNames from 'classnames';
import { t } from 'i18next';

import indexStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import { getFormatedPhoneNumber } from 'helpers/guarantor';
import { GuarantorDispatchResponse } from 'shared/types/renterTypes';

import styles from '../PersonalProfile.module.scss';

type RenterGuarantorProps = {
  guarantor?: GuarantorDispatchResponse;
};

const RenterGuarantor = ({ guarantor }: RenterGuarantorProps): JSX.Element => (
  <div className={classNames(styles.container, styles.guarantorContainer)}>
    <div className={styles.guarantorPresent}>
      <div className={classNames(indexStyles.secondaryHeading, styles.guarantorHeading)}>
        <span>{t('renter.guarantorInformationForm.guarantor')}</span>
      </div>
      {guarantor ? (
        <div>
          <div className={styles.guarantorEmail}>{guarantor.emailAddress}</div>
          <div className={styles.idContainer}>
            <div className={styles.infoContainer}>
              <span className={styles.heading}>{guarantor.relationship}</span>
              <span className={styles.subHeading}>{t('renter.guarantorInformationForm.relationship')}</span>
            </div>
            <div className={styles.infoContainer}>
              <span className={styles.heading}>{getFormatedPhoneNumber(guarantor.phoneNumber)}</span>
              <span className={styles.subHeading}>{t('renter.Ownerinformation.phone')}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.noGuarantor}>{t('renter.guarantorInformationForm.noGuarantor')}</div>
      )}
    </div>
  </div>
);

export default RenterGuarantor;
