import { useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import {
  selectedRenterDetails,
  selectVerifierAddressInformation,
  selectVerifierEmploymentInformation,
} from 'redux/selectors/agentSelector';

import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import { RenterAddressTypes } from 'constants/renterConstants';
import { getEmploymentList, getTenancyList } from 'helpers/agentHelper';
import { formatRenterName } from 'helpers/renterHelper';
import { VerifierDetailsModalProps } from 'shared/types/agentTypes';
import { AddressInformation, RenterPresentEmployments } from 'shared/types/renterTypes';

import PresentEmploymentCard from '../../../RenterIncome/PresentIncome/PresentEmploymentCard/PresentEmploymentCard';
import AddressContainer from '../AddressContainer/AddressContainer';
import { ResidencyVerification } from '../ResidencyVerification/ResidencyVerification';
import { VerifierNavbar } from '../VerifierNavbar/VerifierNavbar';

import styles from './VerifierDetailsModal.module.scss';

export const VerifierDetailsModal = ({
  isModalOpen,
  onHideHandler,
  address,
  employment,
  title,
}: VerifierDetailsModalProps): JSX.Element => {
  const renterDetails = useSelector(selectedRenterDetails);
  const [selectedAddress, setSelectedAddress] = useState<AddressInformation>(address ?? {});
  const [selectedEmployment, setSelectedEmployment] = useState<RenterPresentEmployments>(employment ?? {});
  const addressVerificationInformation = useSelector(selectVerifierAddressInformation);
  const employmentVerificationInformation = useSelector(selectVerifierEmploymentInformation);
  const { t } = useTranslation();
  const currentAddressInformation = useMemo(
    () =>
      addressVerificationInformation?.find(
        (addressInfo) => addressInfo?.renterAddressInformation?.addressId === selectedAddress.id
      ),
    [addressVerificationInformation, selectedAddress.id]
  );
  const currentEmploymentInformation = useMemo(
    () =>
      employmentVerificationInformation?.find(
        (employmentInfo) => employmentInfo?.renterEmploymentInformation?.presentEmploymentId === selectedEmployment.id
      ),
    [employmentVerificationInformation, selectedEmployment.id]
  );

  return (
    <AgentModal customModalClassName={styles.CustomCLass} show={isModalOpen} onHide={onHideHandler}>
      {isEmpty(addressVerificationInformation) ? (
        <Spinner />
      ) : (
        <div className={styles.Container}>
          <div className={styles.NonScrollableContent}>
            <div className={styles.Heading}>{title}</div>
            <div className={styles.RenterName}>{formatRenterName(renterDetails)}</div>
            <VerifierNavbar
              tenancyList={
                address?.id
                  ? getTenancyList(renterDetails?.addresses ?? [])
                  : getEmploymentList(renterDetails.presentEmployments ?? [])
              }
              setSelectedAddress={address?.id ? setSelectedAddress : setSelectedEmployment}
              activeTab={address?.id ? address : employment ?? {}}
            />
          </div>
          <div className={styles.ScrollableContent}>
            {address?.id && (
              <AddressContainer
                address={selectedAddress}
                timePeriodLabel={t('application.residency')}
                isAddressInline
                isPresent={selectedAddress?.addressType === RenterAddressTypes.PRESENT}
                shouldShowHeading={false}
                isVerifierAddress
                verificationInformation={currentAddressInformation}
              />
            )}
            {employment?.id && <PresentEmploymentCard isVerifierModal employment={selectedEmployment ?? {}} />}
            <ResidencyVerification
              title={
                address?.id
                  ? t('renter.verifier.verification')
                  : t('agent.applicants.renterResume.sourceOfIncome.verification')
              }
              verificationInformation={address?.id ? currentAddressInformation : currentEmploymentInformation}
            />
          </div>
        </div>
      )}
    </AgentModal>
  );
};
