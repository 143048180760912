import { useEffect, useRef, useState } from 'react';
import { Modal as BModal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { selectedRenterDetails } from 'redux/selectors/agentSelector';
import { getRenterResume } from 'redux/slices/agentSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as ArrowIcon } from 'assets/svgs/BlackDownArrow.svg';
import { ReactComponent as DownloadIcon } from 'assets/svgs/DownloadIconFilled.svg';
import { ReactComponent as CrossIcon } from 'assets/svgs/GreyCrossIcon.svg';
import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import { IncomeReportNavbarURLParams, ReportErrorTypes } from 'constants/reportConstants';
import { retrieveRenterTransactionReport } from 'services/agentService';
import { FinancialData, IncomeReportModalProps } from 'shared/types/reportTypes';

import { ReportStatusLoader } from '../../RenterCreditAndBackground/components/ReportStatusLoader/ReportStatusLoader';
import IncomeReport from '../../RenterIncome/IncomeReport/IncomeReport';
import { DocumentDigest } from '../DocumentDigest/DocumentDigest';
import { FinancialReport } from '../FinancialReport/FinancialReport';
import { IncomeReportNavbar } from '../IncomeReportNavbar/IncomeReportNavbar';
import { RenterInformationHeader } from '../RenterInformationHeader/RenterInformationHeader';
import { SourceInformationBox } from '../SourceInformationBox/SourceInformationBox';

import styles from './IncomeReportModal.module.scss';

export const IncomeReportModal = ({
  setIsModalOpen,
  organizationId,
  renterId,
  propertyRent,
  isCustomProperty = false,
  totalOccpants,
  propertyAddress,
  status,
  applicationId,
}: IncomeReportModalProps): JSX.Element => {
  const [params] = useSearchParams();
  const [shouldShowSource, setShouldShowSource] = useState(true);
  const [areTransactionsFetched, setAreTransactionsFetched] = useState(false);
  const isApiRequested = useRef(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [transactionalInformation, setTransactionalInformation] = useState<FinancialData>({} as FinancialData);
  const dispatch = useDispatch<AppThunkDispatch>();
  const renterDetails = useSelector(selectedRenterDetails);
  const { presentEmployments } = useSelector(selectedRenterDetails);
  const { t } = useTranslation();

  if (!params.get('incomeTab')) {
    params.append('incomeTab', IncomeReportNavbarURLParams.INCOME_REPORT);
  }

  useEffect(() => {
    if (!isApiRequested.current) {
      isApiRequested.current = true;
      setAreTransactionsFetched(false);
      retrieveRenterTransactionReport({
        organizationId: organizationId,
        renterId: renterId,
        applicationId: applicationId,
      })
        .then((res: AxiosResponse) => {
          setTransactionalInformation(res.data.payload);
        })
        .catch(() => {
          setHasErrors(true);
        })
        .finally(() => {
          setAreTransactionsFetched(true);
        });

      dispatch(getRenterResume({ renterId: renterId, organizationId: organizationId }))
        .unwrap()
        .catch(() => {
          setHasErrors(true);
        });
    }
  }, [applicationId, dispatch, organizationId, renterId]);

  if (!renterDetails.firstName) {
    return <div></div>;
  }

  return (
    <AgentModal
      show
      onHide={() => setIsModalOpen(false)}
      size="lg"
      customTitleClassName={styles.modalTitle}
      customContainerClassName={styles.CustomHeader}
      customModalClassName={styles.ModalContainer}
      customCrossIcon={CrossIcon}
    >
      <>
        <BModal.Header className={styles.HeaderContainer} />
        <BModal.Body className={styles.BodyContainer}>
          {!areTransactionsFetched ||
          (areTransactionsFetched && !hasErrors && !transactionalInformation.incomeSummary.averageMonthly) ? (
            <div className={styles.LoaderIcon}>
              <Spinner />
            </div>
          ) : (
            <div className={styles.ContentContainer}>
              {!isEmpty(renterDetails?.presentEmployments) && (
                <div className={styles.UserInformationContainer}>
                  <div className={styles.IncomeHeading}>{t('agent.applications.applicant.occupant.income')}</div>
                  <RenterInformationHeader
                    renterName={`${renterDetails.firstName} ${renterDetails.lastName}`}
                    totalOccupants={totalOccpants}
                    propertyAddress={propertyAddress}
                    status={status}
                  />
                </div>
              )}
              <div className={styles.IncomeReportNavbar}>
                <IncomeReportNavbar hasReportError={hasErrors} />
              </div>

              {params.get('incomeTab') === IncomeReportNavbarURLParams.INCOME_REPORT && hasErrors
                ? null
                : presentEmployments.map((employment, index) => (
                    <div className={styles.SourceInformation} key={`emp-${employment.id}`}>
                      <div className={styles.SourceContainer}>
                        <div className={styles.SourceHeading}>
                          {`${t('agent.applicants.renterResume.sourceOfIncome.source')} ${index + 1}`}
                        </div>
                        <ArrowIcon
                          onClick={() => setShouldShowSource(!shouldShowSource)}
                          className={classNames(styles.DropDownArrow, { [styles.RotateArrow]: !shouldShowSource })}
                        />
                      </div>
                      {shouldShowSource && (
                        <SourceInformationBox
                          companyName={employment.company}
                          employmentType={employment.employmentSubType || ''}
                          jobPosition={employment.title || ''}
                          monthlyIncome={employment.monthlyGrossIncome ?? 0}
                          startDate={employment.jobStartDate || ''}
                          status={employment.status ?? null}
                          explaination={employment.explanation}
                          description={employment.jobDescription}
                        />
                      )}
                    </div>
                  ))}

              {params.get('incomeTab') === IncomeReportNavbarURLParams.INCOME_REPORT && !hasErrors && (
                <div className={styles.IncomeReportTab}>
                  <div className={styles.IncomeReportInformation}>
                    <div className={styles.IncomeHeader}>
                      <div className={styles.IncomeReportHeading}>
                        {t('agent.applicants.renterResume.sourceOfIncome.incomeReport')}
                      </div>
                      <DownloadIcon />
                    </div>
                    <div className={styles.IncomeReportText}>{t('incomeReport.aMonthSnapshot')}</div>
                    <IncomeReport
                      past6months={transactionalInformation.incomeSummary.pastSixMonths}
                      past3months={transactionalInformation.incomeSummary.pastThreeMonths}
                      averageMonthly={transactionalInformation.incomeSummary.averageMonthly}
                      projected12Months={transactionalInformation.incomeSummary.projectedTwelveMonthly}
                      propertyRent={propertyRent}
                      isCustomProperty={isCustomProperty}
                    />
                  </div>
                  <div className={styles.FinancialReports}>
                    <div className={styles.FinancialReportHeader}>
                      <div className={styles.FinancialHeading}>{t('incomeReport.financialInstitutions')} </div>
                      <div className={styles.InstitutionCount}>{transactionalInformation.accountDetails.length}</div>
                    </div>
                    {transactionalInformation.accountDetails.map((accounts, index) => (
                      <FinancialReport
                        key={`Financial-Reports-${accounts}-${index}`}
                        accountInformation={accounts.account}
                        accountTransactions={accounts.transactions}
                      />
                    ))}
                  </div>
                  <div className={styles.Divider} />
                </div>
              )}
              {params.get('incomeTab') === IncomeReportNavbarURLParams.INCOME_REPORT && hasErrors && (
                <div className={styles.ErrorContainer}>
                  <ReportStatusLoader errorType={ReportErrorTypes.ERROR} shouldShowLoaderRows={false} />
                </div>
              )}
              {params.get('incomeTab') === IncomeReportNavbarURLParams.SUPPORTING_DOCUMENTS && (
                <div className={styles.SupportingDocContainer}>
                  {presentEmployments.map((employment, index) => (
                    <div key={`present-income-${index}`}>
                      <DocumentDigest employment={employment} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </BModal.Body>
      </>
    </AgentModal>
  );
};
