//TODO: Temporarily commented some features and will re-enable later.

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectedRenterDetails } from 'redux/selectors/agentSelector';

import { ReactComponent as EmailIcon } from 'assets/svgs/EmailGrey.svg';
// import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/HorizontalDotsIcon.svg';
import { ReactComponent as PersonIcon } from 'assets/svgs/PersonIdentityIcon.svg';
import { ReactComponent as PhoneIcon } from 'assets/svgs/PhoneGrey.svg';
import { ReactComponent as Spinner } from 'assets/svgs/Spinner.svg';
import { InviteApplicantModal } from 'components/Agent/components/shared';
// import Button from 'components/shared/Button/Button';
import RCButton from 'components/shared/Button/Button';
import { ApplicationRequestValues } from 'constants/applicationConstants';
import { PLACEHOLDER_PHONE_NUM } from 'constants/renterConstants';
import { formatPhoneNumber } from 'helpers/renterHelper';
import { ApplicationDetailProps } from 'shared/types/agentTypes';

import { ReportSkeletonRow } from '../../../RenterCreditAndBackground/components/ReportLoadingSkeletonRow/ReportSkeletonRow';
import { SectionTitle } from '../../../shared';
import { ContactDetails } from '..';

import styles from '../RenterPersonalProfile.module.scss';

const RenterDummyResume = ({ invite }: ApplicationDetailProps): JSX.Element => {
  const { t } = useTranslation();
  const renterDetails = useSelector(selectedRenterDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const renderSkeleton = Array.from({ length: 3 }, (_, index) => <ReportSkeletonRow key={index} />);
  const getPhoneNumber = useMemo((): string | undefined => {
    if (invite?.inviteCode) {
      return invite?.renterPhoneNumber;
    } else if (renterDetails) {
      return renterDetails?.renterPhoneNumbers && renterDetails?.renterPhoneNumbers[0]?.phoneNumber;
    } else {
      return PLACEHOLDER_PHONE_NUM;
    }
  }, [invite, renterDetails]);

  return (
    <div className={styles.Container}>
      <SectionTitle
        mainTitle={t('imageAlt.Profile')}
        iconElement={<PersonIcon />}
        // optionElement={<ThreeDotsIcon width={24} height={24} />}
        // shouldShowOptions
      />
      <div className={styles.RenterContainer}>
        <div className={styles.InfoContainer}>{renderSkeleton}</div>
        <div className={styles.ContactContainer}>
          <div className={styles.ContactHeading}>
            <div>{t('agent.inviteApplicant.Contact')}</div>
            {/* <div>
              <Button variant="outline" className={styles.Button}>
                {t('application.message')}
              </Button>
            </div> */}
          </div>
          <ContactDetails
            heading={t('application.email')}
            data={invite?.renterEmail || renterDetails?.email}
            icon={<EmailIcon />}
            shouldShowCopy
          />
          <div className={styles.AddPhoneNumber}>
            <ContactDetails
              heading={t('application.phone')}
              data={formatPhoneNumber(getPhoneNumber ?? '')}
              icon={<PhoneIcon />}
            />
            {/* <RCButton variant="outline" className={styles.AgentButton}>
              {t('agent.applicants.renterResume.addPhoneNum')}
            </RCButton> */}
          </div>
        </div>
      </div>
      <div className={styles.ApplicationText}>
        {t(invite?.inviteCode ? 'renter.resume.applicationNotStarted' : 'renter.resume.applicationStarted')}
      </div>
      {invite?.inviteCode && (
        <RCButton variant="outline" className={styles.AgentButton} onClick={() => setIsModalOpen(true)}>
          <div className={styles.InvitesApplyContainer}>
            <Spinner /> {t('agent.applicants.renterResume.resendRequest')}
          </div>
        </RCButton>
      )}
      {/* <div className={styles.InfoText}>
        {t('renter.resume.info')}
        <span>
          <Link to="#" className={styles.LearnMoreText}>
            {t('agent.properties.learnMore')}
          </Link>
        </span>
      </div> */}
      {isModalOpen && (
        <InviteApplicantModal
          inviteType={ApplicationRequestValues.APPLICATION_AND_REPORTS}
          isModalOpen={isModalOpen}
          onHide={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default RenterDummyResume;
