import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { handleServerResponseError, parseResponseErrors } from 'helpers/helper';
import * as propertyService from 'services/propertyService';
import { Notification } from 'shared/Notification/Notification';
import { ApiUploadImage } from 'shared/types/imageUploadTypes';
import { PaginationType } from 'shared/types/paginationTypes';
import { PropertyType } from 'shared/types/propertType';
import {
  AddPropertyParams,
  ApplicationPropertiesType,
  GetAllPropertyParams,
  GetPropertyParams,
  PropertyMarketingStatusRequestProps,
  PropertyProps,
  Tag,
  UpdatePropertyType,
} from 'shared/types/propertyType';
import { ISelectObjectsList } from 'shared/types/selectObject';
import { createFormikErrorObject } from 'shared/utils/errorObject';

interface PropertyInterface {
  propertyExternalAttributes: ISelectObjectsList;
  isLoading: boolean;
  isSaving: boolean;
  propertyId: number;
  amenitiesFeature: Tag[];
  properties: PropertyProps[];
  applicationProperties: PropertyProps[];
  pageInfo: PaginationType;
  selectedProperty: UpdatePropertyType;
}

export const initialState: PropertyInterface = {
  propertyExternalAttributes: {
    propertyTypes: [],
    propertyLaundryAmenities: [],
    propertyLeaseDurations: [],
    propertyParkingOptions: [],
    propertyUtilities: [],
    propertyAllowedPets: [],
    unitAmenities: [],
    propertyAmenities: [],
  },
  applicationProperties: [],
  properties: [],
  pageInfo: {} as PaginationType,
  isLoading: false,
  isSaving: false,
  propertyId: 0,
  amenitiesFeature: [],
  selectedProperty: {} as UpdatePropertyType,
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    updateAmenitiesFeature(state, action) {
      if (!action.payload.length) {
        return;
      }

      state.amenitiesFeature = action.payload;
    },
    resetPropertyId(state) {
      state.propertyId = 0;
    },
    setIsPropertyLoading(state, action) {
      state.isLoading = action.payload;
    },
    updatePropertyDetails(state, action) {
      state.selectedProperty = action.payload;
    },
    removeSelectedPropertyDetails(state) {
      state.selectedProperty = {} as UpdatePropertyType;
    },
    updatePropertyImages(state, action) {
      if (state.selectedProperty) {
        state.selectedProperty.propertyImages = action.payload;
      }
    },
    resetApplicationProperties(state) {
      state.applicationProperties = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPropertyExternalAttributes.fulfilled, (state, { payload }) => {
        state.propertyExternalAttributes = payload;
      })
      .addCase(getPaginatedProperties.pending, (state) => {
        state.isLoading = true;
        state.properties = [];
        state.pageInfo = {};
      })
      .addCase(getPaginatedProperties.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        state.properties = payload.results;
        state.pageInfo = payload.page_info;
      })
      .addCase(getPaginatedProperties.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllProperties.pending, (state) => {
        state.isLoading = true;
        state.properties = [];
      })
      .addCase(getAllProperties.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.properties = payload;
      })
      .addCase(getAllProperties.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getApplicationProperties.pending, (state) => {
        state.isLoading = true;
        state.applicationProperties = [];
      })
      .addCase(getApplicationProperties.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.applicationProperties = payload;
      })
      .addCase(getApplicationProperties.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getProperty.pending, (state) => {
        state.isLoading = true;
        state.selectedProperty = {} as UpdatePropertyType;
      })
      .addCase(getProperty.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.selectedProperty = payload;
      })
      .addCase(getProperty.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addProperty.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addProperty.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        state.propertyId = payload.id;

        // Find the index of the property with the same ID in the properties array
        const index = state.properties.findIndex((property) => property.id === payload.id);

        if (index !== -1) {
          // Replace the existing property object with the new payload
          state.properties[index] = payload;
        } else {
          // If the property is not found, add it to the beginning of the array
          state.properties.push(payload);
        }

        // if the property has been published, reset ID
        if (payload.propertyStatus === PropertyType.PUBLISHED) {
          state.propertyId = 0;
        }
      })
      .addCase(addProperty.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(uploadPropertyImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadPropertyImage.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        if (state.selectedProperty) {
          state.selectedProperty.propertyImages = payload?.propertyImages;
        }
      })
      .addCase(uploadPropertyImage.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUploadedPropertyImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUploadedPropertyImage.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        if (state.selectedProperty) {
          state.selectedProperty.propertyImages = payload?.propertyImages;
        }
      })
      .addCase(deleteUploadedPropertyImage.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(orderPropertyImages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(orderPropertyImages.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        if (state.selectedProperty) {
          state.selectedProperty.propertyImages = payload?.propertyImages;
        }
      })
      .addCase(orderPropertyImages.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updatePropertyMarketingStatus.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updatePropertyMarketingStatus.fulfilled, (state, { payload }) => {
        state.isSaving = false;

        if (state.selectedProperty) {
          state.selectedProperty.marketingEnabled = payload?.marketingEnabled;
        }
      })
      .addCase(updatePropertyMarketingStatus.rejected, (state) => {
        state.isSaving = false;
      })
      .addDefaultCase((state) => state);
  },
});

// API call for signup that takes userName password and returns OK status if user is created.
// Additionally we can pass onSuccess function as parameter from our component

export const getPropertyExternalAttributes = createAsyncThunk(
  'property/getPropertyExternalAttributes',
  async (_, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await propertyService.getPropertyExternalAttributes();

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);
export const getPaginatedProperties = createAsyncThunk(
  'property/getPaginatedProperties',
  async (values: GetPropertyParams, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await propertyService.getProperties(values);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);

export const getAllProperties = createAsyncThunk(
  'property/getAllProperties',
  async (values: GetAllPropertyParams, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await propertyService.getAllProperties(values);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);

export const getApplicationProperties = createAsyncThunk(
  'property/getApplicationProperties',
  async (values: ApplicationPropertiesType, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await propertyService.getApplicationProperties(values);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);

export const getProperty = createAsyncThunk(
  'property/getProperty',
  async (values: { propertyId: number; organizationId: number }, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await propertyService.getProperty(values);

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });

        return Promise.reject();
      }
    }
  }
);

export interface IAddProperty {
  values: AddPropertyParams;
  organizationId: number;
  type?: string;
}
export const addProperty = createAsyncThunk(
  'property/createProperty',
  async ({ values, organizationId }: IAddProperty, { rejectWithValue, dispatch }) => {
    const { propertyId, ...createPropertyValues } = values;

    try {
      if (propertyId) {
        const {
          data: { payload },
        }: AxiosResponse = await propertyService.updateProperty({ ...values, organizationId });

        return payload;
      }

      const {
        data: { payload },
      }: AxiosResponse = await propertyService.createProperty({ ...createPropertyValues, organizationId });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        const errorObject = createFormikErrorObject(error as AxiosError);

        Notification({ message: parseResponseErrors(error) });

        return rejectWithValue({ ...errorObject });
      }

      return Promise.reject();
    }
  }
);

export interface PropertyUploadImageInterface {
  file: FormData;
  propertyId: number;
  organizationId: number;
  onUploadImage?: (file: ApiUploadImage) => void;
}
export interface PropertyDeleteImageInterface {
  imageId: number;
  propertyId: number;
  onDeleteImage?: (file: ApiUploadImage) => void;
}
export interface OrderPropertyImageInterface {
  propertyId: number;
  imagesId: (number | undefined)[];
}

export const uploadPropertyImage = createAsyncThunk(
  'property/uploadImage',
  async ({ propertyId, organizationId, file }: PropertyUploadImageInterface, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await propertyService.propertyImageUpload({ propertyId, organizationId, file });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });
      }

      return Promise.reject();
    }
  }
);
export const deleteUploadedPropertyImage = createAsyncThunk(
  'property/deleteUploadedPropertyImage',
  async ({ propertyId, imageId }: PropertyDeleteImageInterface, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await propertyService.deletePropertyImage({ propertyId, imageId });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });
      }

      return Promise.reject();
    }
  }
);

export const orderPropertyImages = createAsyncThunk(
  'property/orderPropertyImages',
  async ({ propertyId, imagesId }: OrderPropertyImageInterface, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await propertyService.orderPropertyImage({ propertyId, imagesId });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });
      }

      return Promise.reject();
    }
  }
);
export const updatePropertyMarketingStatus = createAsyncThunk(
  'property/marketingStatusUpdate',
  async ({ propertyId, organizationId, marketingEnabled }: PropertyMarketingStatusRequestProps, { dispatch }) => {
    try {
      const {
        data: { payload },
      }: AxiosResponse = await propertyService.updatePropertyMarketingStatus({
        propertyId,
        organizationId,
        marketingEnabled,
      });

      return payload;
    } catch (error) {
      handleServerResponseError({ error, dispatch });

      if (error instanceof AxiosError && error.response) {
        Notification({ message: parseResponseErrors(error) });
      }

      return Promise.reject();
    }
  }
);
export const { reducer } = slice;
export const {
  updateAmenitiesFeature,
  resetPropertyId,
  setIsPropertyLoading,
  updatePropertyDetails,
  removeSelectedPropertyDetails,
  updatePropertyImages,
  resetApplicationProperties,
} = slice.actions;
