import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { t } from 'i18next';

import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import {
  AssistiveAnimalOption,
  AssistiveAnimalType,
  FurryFriendParamTypes,
  FurryFriendTypes,
  NavigationKeys,
  RenterAnimalSteps,
} from 'constants/renterConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { AnimalInformationProps } from 'shared/types/renterTypes';

import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import styles from './AnimalIndex/AnimalIndex.module.scss';
const AssistiveAnimalCard = (): JSX.Element => {
  const { values, setFieldValue, dirty: isDirty } = useFormikContext<AnimalInformationProps>();
  const [params, setSearchParams] = useSearchParams();
  const furryFriendType = params.get(FurryFriendParamTypes.FURRY_FRIEND_TYPE);
  const navigateNext = params.get(NavigationKeys.NEXT);
  const navigateSection = params.get(NavigationKeys.SECTION);
  const maxHeight = useDeviceHeight(220);

  useEffect(() => {
    if (furryFriendType) {
      setFieldValue('furryFriendType', furryFriendType);
    }
  }, [furryFriendType, setFieldValue]);

  return (
    <RenterContainer>
      <section className={styles.renterBody}>
        <div style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
          <FormCloseWarning isDirty={isDirty} />
          <div className={styles.container}>
            <RenterIconInfoContainer />
            <div className={styles.headingLabel}>
              {t('renter.petsInformation.assistivePetDetailsLabelA')}&nbsp;
              <span className={styles.renterSpan}>{t('renter.petsInformation.assistivePetLabel')}</span>&nbsp;
              {t('renter.petsInformation.assistivePetDetailsLabelB')}
            </div>
            <div className={classNames(styles.textFieldContainer, styles.reactHeightDiv)}>
              <span className={classNames(styles.textFieldLabel, styles.assistiveAnimalFieldLabel)}>
                {t('renter.sourceOfIncome.type')}
              </span>
              <FormikSelectField
                name="assistiveAnimalType"
                placeholder={t('renter.realtionship.select')}
                options={AssistiveAnimalOption}
              />
            </div>
            <div className={classNames(styles.renterButtonContainer, styles.buttonsContainer)}>
              <RCButton
                className={classNames(styles.renterButton, styles.nextButton)}
                variant="outline"
                type="button"
                disabled={!values.assistiveAnimalType}
                onClick={() => {
                  if (values?.assistiveAnimalType) {
                    setSearchParams(
                      values.assistiveAnimalType && {
                        step: RenterAnimalSteps.FORM,
                        animalType:
                          values.assistiveAnimalType === FurryFriendTypes.SERVICE_PETS
                            ? AssistiveAnimalType.SERVICE_PETS
                            : AssistiveAnimalType.OTHER,
                        furryFriendType: FurryFriendTypes.ASSISTIVE_ANIMAL,
                        assistiveAnimalType: values.assistiveAnimalType,
                        next: navigateNext ?? '',
                        section: navigateSection ?? '',
                      }
                    );
                  }
                }}
              >
                {t('renter.vehiclesInformation.form.continue')}
              </RCButton>
            </div>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default AssistiveAnimalCard;
