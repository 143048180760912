import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'assets/svgs/ErrorInfo.svg';
import { ReactComponent as ErrorIcon } from 'assets/svgs/RedWarningIcon.svg';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import { ExperianAuthenticationStatus } from 'constants/renterConstants';

import styles from './CreditBackgroundCapsule.module.scss';
export const CreditBackgroundCapsule = ({
  creditScore,
  experianAuth,
}: {
  creditScore: string | null;
  experianAuth?: ExperianAuthenticationStatus;
}): JSX.Element => {
  const { t } = useTranslation();
  const getCreditScoreElement = useMemo((): JSX.Element => {
    if (
      experianAuth === ExperianAuthenticationStatus.IN_PROGRESS ||
      experianAuth === ExperianAuthenticationStatus.PENDING
    ) {
      return <WarningIcon height={20} width={20} />;
    }

    return <ErrorIcon height={20} width={20} />;
  }, [experianAuth]);

  return (
    <div className={styles.CapsuleContainer}>
      {creditScore ? (
        <AgentPills
          pillContainerClassName={styles.PillContainer}
          pillsData={[{ heading: `${creditScore}`, type: PillTypes.SUCCESS_LIGHT }]}
        />
      ) : (
        <div className={styles.EmptyState}>{getCreditScoreElement}</div>
      )}
      <div className={styles.LabelContainer}>
        <div className={styles.CapsuleLabel}>{t('application.creditAndBackground')}</div>
      </div>
    </div>
  );
};
