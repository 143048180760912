import { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { t } from 'i18next';
import { selectedRenterDetails } from 'redux/selectors/agentSelector';

import { ReactComponent as ArrowIcon } from 'assets/svgs/ArrowForward.svg';
import { ReactComponent as MailIcon } from 'assets/svgs/MailGreyIcon.svg';
import { DescriptionContainer } from 'components/Agent/Applications/ApplicationDetails/components/shared';
import { formatDateTime } from 'helpers/agentHelper';
import { formatPhoneNumber, formatRenterName, getPrimaryVerifier } from 'helpers/renterHelper';
import { VerifierDetailsProps } from 'shared/types/agentTypes';
import { VerifierTypes } from 'shared/types/renterTypes';

import { ViewSubmittedData } from '../AddressContainer/ViewSubmittedData';
import { VerifierDetailsModal } from '../VerifierDetailsModal/VerifierDetailsModal';
import { VerifierTooltip } from '../VerifierTooltip/VerifierTooltip';

import styles from '../AddressContainer.module.scss';
import styles2 from '../AddressContainer/VerifierAddressContainer.module.scss';
const VerifierDetails = ({
  verificationInformation,
  address,
  employment,
  isAdjacentStyled = false,
  isVerifierModal = false,
}: VerifierDetailsProps): JSX.Element => {
  const primaryAddressVerifier = getPrimaryVerifier(
    address?.id ? address?.addressVerifiers ?? [] : employment?.presentEmploymentVerifiers ?? []
  );
  const renterDetails = useSelector(selectedRenterDetails);
  const renterFullName = useMemo(() => formatRenterName(renterDetails), [renterDetails]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const VerifierFirstRow = [
    { heading: t('renter.relationship.fullNamePlaceHolder'), data: verificationInformation.submitVerifierFullName },
    {
      heading: t('renter.relationship.relationToProperty'),
      data: verificationInformation.submitVerifierRelationshipProperty,
    },
  ];
  const VerifierSecondRow = [
    { heading: t('renter.sourceOfIncome.companyName'), data: verificationInformation.submitVerifierCompany },
    { heading: t('application.email'), data: verificationInformation.submitVerifierEmail },
    { heading: t('application.phone'), data: formatPhoneNumber(verificationInformation.submitVerifierPhoneNumber) },
  ];

  return (
    <div className={classNames(styles.Container, styles2.VerifierModalContainer)}>
      <div className={styles.HeadingContainer}>
        <span className={styles.SectionHeading}>{t('renter.ConfirmAddressHistory.verifier')}</span>
        {!isVerifierModal && (
          <span className={styles.IconContainer}>
            <VerifierTooltip handleShowMore={() => setIsModalOpen(true)} />
          </span>
        )}
      </div>
      {isAdjacentStyled ? (
        <Row className={styles2.RenterInformation}>
          <Col sm={12} md={6} className={styles2.LeftInformationContainer}>
            <ViewSubmittedData
              fullName={primaryAddressVerifier?.fullName || ''}
              emailAddress={primaryAddressVerifier?.emailAddress ?? ''}
              phoneNumber={formatPhoneNumber(primaryAddressVerifier?.phoneNumber || '')}
              verifierLabel={t('agent.verifier.submittedBy')}
              customContentClassName={styles2.TopLeftRounded}
              hasPropertyRelation={false}
            />
          </Col>
          <Col sm={12} md={6} className={styles2.RightInformationContainer}>
            <ViewSubmittedData
              submittedTime={formatDateTime(verificationInformation.submittedAt ?? '')}
              fullName={verificationInformation.submitVerifierFullName}
              emailAddress={verificationInformation.submitVerifierEmail}
              phoneNumber={formatPhoneNumber(verificationInformation.submitVerifierPhoneNumber)}
              propertyRelation={verificationInformation.submitVerifierRelationshipProperty}
              verifierLabel={t('agent.verifier.completedBy')}
              customContentClassName={styles2.TopRightRounded}
              verifierType={verificationInformation.verifierType as VerifierTypes}
            />
          </Col>
        </Row>
      ) : (
        <div className={styles.AddressInformation}>
          <div>
            <Row className={styles.RowContainer}>
              {VerifierFirstRow.map(({ heading, data }, index) => (
                <Col key={`verifier-first-${data}-${index}`} sm={12} lg={4} md={4}>
                  {!!data && <DescriptionContainer heading={heading} description={data} />}
                </Col>
              ))}
              <Col sm={12} lg={4} md={4} />
            </Row>
          </div>
          <div>
            <Row className={styles.RowContainer}>
              {VerifierSecondRow.map(({ heading, data }, index) => (
                <Col key={`verifier-second-${data}-${index}`} sm={12} lg={4} md={4}>
                  {!!data && <DescriptionContainer heading={heading} description={data} />}
                </Col>
              ))}
            </Row>
          </div>
          <div className={styles.VerificationPending}>
            <span className={styles.Heading}>{t('renter.relationship.verificationOfResidency')}</span>
            <div className={styles.MessageContainer}>
              <span>
                <ArrowIcon />
              </span>
              <span>
                <MailIcon />
              </span>
              <span>{t('renter.ConfirmPresentAddress.actionRequired') + renterFullName}</span>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (address || employment) && (
        <VerifierDetailsModal
          address={address ?? {}}
          employment={employment ?? {}}
          isModalOpen={isModalOpen}
          onHideHandler={() => setIsModalOpen(false)}
          title={
            address?.id
              ? t('renter.ConfirmAddressHistory.addressHistory.')
              : t('agent.applications.applicant.occupant.income')
          }
        />
      )}
    </div>
  );
};

export default VerifierDetails;
