import classNames from 'classnames';

import { InquiryReportItem } from 'shared/types/reportTypes';

import styles from './InquiryReport.module.scss';
export const InquiryReport = ({
  hasGreyBackground = false,
  inquiryReport,
}: {
  hasGreyBackground?: boolean;
  inquiryReport: InquiryReportItem;
}): JSX.Element => {
  const getFormattedValue = (value: string | null): string => {
    if (!value || value === 'N/A' || value === 'UNK') {
      return '—';
    }

    return value;
  };
  const { Date, SubscriberDisplayName, KOB } = inquiryReport;

  return (
    <div className={classNames(styles.Container, { [styles.GreyBackground]: hasGreyBackground })}>
      <div className={styles.LeftInformation}>
        <div className={styles.Item}>{getFormattedValue(Date)}</div>
        <div className={styles.Item}>{getFormattedValue(SubscriberDisplayName)}</div>
      </div>
      <div className={styles.RightInformation}>
        <div className={styles.Item}>{getFormattedValue(KOB?.desc)}</div>
      </div>
    </div>
  );
};
