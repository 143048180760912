import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { InfoContainerProps } from 'shared/types/agentTypes';

import styles from '../AnimalDetailsInformationCard.module.scss';

export const InfoContainer = ({ label, value, shouldShowPill }: InfoContainerProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Col xs={shouldShowPill ? 4 : 3}>
      <div className={classNames(styles.valueText, shouldShowPill && styles.FontSizeContainer)}>
        <span>{value}</span>
      </div>
      <div className={classNames(styles.labelText, shouldShowPill && styles.FontSizeContainer)}>{t(label)}</div>
    </Col>
  );
};
