import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { RenterAddresses } from 'redux/selectors/renterSelector';

import { ReactComponent as LocationPinDropIcon } from 'assets/svgs/LocationPinDropGreyIcon.svg';
import indexStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import { Ladder } from 'components/Renter/IdentityVerification/RenterDetails/shared/Ladder/Ladder';
import Title from 'components/Renter/IdentityVerification/RenterDetails/shared/Title/Title';
import { RenterAddressSteps, RenterAddressTypes } from 'constants/renterConstants';
import { formatAddressForFrontEnd } from 'helpers/renterHelper';
import { renterRoutes } from 'shared/routes';
import { AddressInformation } from 'shared/types/renterTypes';

import VerifierCard from '../VerifierCard';

import DescriptionCard from './DescriptionCard/DescriptionCard';
import PresentAddressToolTip from './PresentAddressToolTip/PresentAddressToolTip';

type PresentAddressProp = {
  handleEditVerifier?: (address: AddressInformation) => void;
  handleEditAddress?: (address: AddressInformation) => void;
  next?: string;
  isReportPage?: boolean;
};

const PresentAddress = ({
  handleEditVerifier,
  handleEditAddress,
  next,
  isReportPage = false,
}: PresentAddressProp): JSX.Element => {
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const address = useSelector(RenterAddresses);
  const navigate = useNavigate();
  const currentAddress = useMemo((): AddressInformation | undefined => {
    if (address) {
      return formatAddressForFrontEnd(
        address.find(({ addressType }) => addressType === RenterAddressTypes.PRESENT) ?? {}
      );
    }

    return undefined;
  }, [address]);
  const handleAddAddress = (): void =>
    navigate(
      renterRoutes.generateAddressHistoryInformation(
        Number(currentApplicationId),
        RenterAddressSteps.PRESENT_ADDRESS,
        next ?? renterRoutes.generateRenterPaymentVerification(currentApplicationId)
      )
    );

  return (
    <div className={indexStyles.container}>
      <Title
        mainTitle={t('addressBox.present')}
        iconElement={<LocationPinDropIcon />}
        shouldShowOptions
        optionElement={
          isReportPage
            ? undefined
            : handleEditAddress &&
              handleEditVerifier && (
                <PresentAddressToolTip
                  handleAddAddress={handleAddAddress}
                  handleEditAddress={handleEditAddress}
                  handleEditVerifier={handleEditVerifier}
                  currentAddress={currentAddress}
                />
              )
        }
      />
      {currentAddress ? (
        <div className={indexStyles.bottomPadding}>
          <div className={indexStyles.borderContainer}>
            <DescriptionCard address={currentAddress} isReportPage={isReportPage} />
          </div>
          {!isReportPage && currentAddress && (currentAddress.emailAddress || !!currentAddress?.classification) && (
            <div className={indexStyles.personalInformationContainer}>
              <div className={indexStyles.fieldContainer}>
                <Ladder isBottomPadding={false} />
                <div className={classNames(indexStyles.borderContainer, indexStyles.noTopBorder)}>
                  <VerifierCard address={currentAddress} />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Title mainTitle={t('addressBox.iDoNotHaveAPresentAddress')} />
      )}
    </div>
  );
};

export default PresentAddress;
