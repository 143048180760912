import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectedRenterDetails, selectRenterApplication } from 'redux/selectors/agentSelector';

import { RenterResumeKeys, RenterResumeSteps } from 'constants/agentConstants';
import { MOBILE_VIEW_WIDTH } from 'constants/organizationConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { formatDateTime } from 'helpers/agentHelper';
import { getFormattedDate } from 'helpers/dateTimeHelper';
import { Size, useWindowSize } from 'hooks/useResize';
import { ApplicationDetailProps } from 'shared/types/agentTypes';

import RenterDummyResume from './components/RenterPersonalProfile/components/RenterDummyResume/RenterDummyResume';
import {
  RenterAddressHistory,
  RenterAnimals,
  RenterHousehold,
  RenterIncome,
  RenterPersonalProfile,
  RenterQuestions,
  RenterVehicles,
} from './components';

import styles from './ApplicationDetails.module.scss';

const ApplicationDetails = ({ invite }: ApplicationDetailProps): JSX.Element => {
  const { t } = useTranslation();
  const renterDetails = useSelector(selectedRenterDetails);
  const size: Size = useWindowSize();
  const application = useSelector(selectRenterApplication);
  const renterResumeComponents = {
    [RenterResumeKeys.PERSONAL_PROFILE]: <RenterPersonalProfile />,
    [RenterResumeKeys.ADDRESS_HISTORY]: <RenterAddressHistory />,
    [RenterResumeKeys.ANIMAL_INFORMATION]: !renterRestrictions[application.renterRole].animals && <RenterAnimals />,
    [RenterResumeKeys.BACKGROUND_QUESTIONS]: <RenterQuestions />,
    [RenterResumeKeys.HOUSEHOLD_DETAILS]: (!renterRestrictions[application.renterRole].adults ||
      !renterRestrictions[application.renterRole].minors) && <RenterHousehold />,
    [RenterResumeKeys.SOURCE_OF_INCOME]: <RenterIncome />,
    [RenterResumeKeys.VEHICLE_INFORMATION]: !renterRestrictions[application.renterRole].vehicles && <RenterVehicles />,
  };

  if (!renterDetails.firstName || !application.id) {
    return <div></div>;
  }

  return !invite?.inviteCode && application.submittedAt ? (
    <div>
      <span
        className={classNames(styles.SubmissionContainer, {
          [styles.SubmissionContainerMobile]: size.width <= MOBILE_VIEW_WIDTH,
        })}
      >
        <span className={classNames(styles.DateTag, { [styles.DateTagMobile]: size.width <= MOBILE_VIEW_WIDTH })}>
          Submitted:&nbsp;
          {application?.createdAt ? (
            <span className={styles.Date}>{formatDateTime(String(application?.submittedAt))}</span>
          ) : (
            '-----'
          )}
        </span>
        <span className={classNames(styles.DateTag, { [styles.DateTagMobile]: size.width <= MOBILE_VIEW_WIDTH })}>
          {size.width <= MOBILE_VIEW_WIDTH ? '' : ' |'}Completed:&nbsp;
          {application?.confirmedAt && application?.isConfirmed ? (
            <span className={styles.Date}>{formatDateTime(String(application?.confirmedAt))}</span>
          ) : (
            '-----'
          )}
        </span>
      </span>
      {RenterResumeSteps.map((step) => (
        <div key={step} id={step}>
          {renterResumeComponents[step]}
        </div>
      ))}
    </div>
  ) : (
    <div>
      <span className={styles.SubmissionContainer}>
        {t(`renter.resume.${invite?.inviteCode ? 'dateInvited' : 'dateStarted'}`)}:&nbsp;
        <span className={styles.Date}>{getFormattedDate(application.createdAt || new Date().toDateString())}</span>
      </span>
      <RenterDummyResume invite={invite} />
    </div>
  );
};

export default ApplicationDetails;
