import classNames from 'classnames';

import styles from './ErrorMessage.module.scss';
export interface ErrorMessageProps {
  className?: string;
  message: string;
}
const ErrorMessage = ({ className, message }: ErrorMessageProps): JSX.Element => (
  <div className={styles.container}>
    <div className={classNames(styles.errorMessage, className, styles.alignError)}>{message}</div>
  </div>
);

export default ErrorMessage;
