/* eslint-disable @typescript-eslint/naming-convention */
import { ReactNode, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { OptionProps } from 'constants/dropdownOptions';

import styles from './PopoverDropdown.module.scss';
export interface PopoverDropdownProps {
  icon: ReactNode;
  navLink: string;
  routeLink: string;
  options: OptionProps[];
}
const PopoverDropdown = ({ navLink, options, icon, routeLink }: PopoverDropdownProps): JSX.Element => {
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const activeOption = searchParams.get('option');
  const layout = searchParams.get('layout') || 'list';
  const renderTooltip = (
    <Popover
      id="popover-positioned-bottom"
      onMouseLeave={() => setIsPopoverVisible(false)}
      onMouseEnter={() => setIsPopoverVisible(true)}
    >
      <Popover.Body className={styles.popoverBody}>
        {options.map(({ name, Icon }, index) => (
          <Link
            key={name + index}
            to={`${navLink}?option=${name}&layout=${layout}`}
            className={classNames(styles.popoverBtn, { [styles.active]: activeOption === name })}
          >
            <span className={styles.dropDownIcon}>
              <Icon />
            </span>
            {name}
          </Link>
        ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger show={isPopoverVisible} key="bottom" placement="bottom-end" overlay={renderTooltip}>
      <div
        role="presentation"
        className={classNames(styles.dropdownToggle)}
        onClick={() => setIsPopoverVisible(true)}
        onMouseLeave={() => setIsPopoverVisible(false)}
      >
        <NavLink to={routeLink} className={styles.dropdownToggle}>
          {({ isActive }) =>
            isPopoverVisible || isActive ? (
              <span className={styles.linkContainerActive}>
                {icon} {navLink}
              </span>
            ) : (
              <div className={styles.linkContainer}>
                <span className={styles.dropDownIcon}>{icon}</span> {navLink}
              </div>
            )
          }
        </NavLink>
      </div>
    </OverlayTrigger>
  );
};

export default PopoverDropdown;
