import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import indexStyles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import CollapsibleComponent from 'components/Renter/components/CollapsibleComponent/CollapsibleComponent';
import { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';

import DocumentItem from './DocumentItem/DocumentItem';

import styles from './Documents.module.scss';
type DocumentType = {
  documents?: PreviewFile[];
  title?: string;
  uploadButton?: ReactNode;
  icon?: ReactNode;
  customLabel?: string;
  shouldShowCount?: boolean;
};
const Documents = ({
  documents,
  title,
  uploadButton,
  icon,
  customLabel,
  shouldShowCount = true,
}: DocumentType): JSX.Element => {
  const { t } = useTranslation();
  const shouldBeCollapsible = documents && documents.length > 3;
  const heading =
    documents?.[0].documentSubType ?? t('agent.applicants.renterResume.sourceOfIncome.incomeReport.documents');

  return (
    <div className={styles.SupportingDocuments}>
      <div className={styles.TitleDiv}>
        <span className={classNames(indexStyles.subTitle, styles.Title)}>{title}</span>
        {uploadButton}
      </div>
      {shouldBeCollapsible ? (
        <CollapsibleComponent heading={heading}>
          <div className={styles.BorderDiv}>
            {documents?.map((doc, index) => (
              <DocumentItem
                key={`document-${doc}-${index}`}
                customLabel={customLabel}
                shouldShowCount={shouldShowCount}
                documentName={doc.fileName}
                documentLink={doc.fileLink}
                icon={icon}
                index={index + 1}
              />
            ))}
          </div>
        </CollapsibleComponent>
      ) : (
        // Render the documents without the CollapsibleComponent when there are 3 or fewer documents
        <div className={styles.BorderDiv}>
          {documents?.map((doc, index) => (
            <DocumentItem
              key={`document-${doc}-${index}`}
              documentName={doc.fileName}
              documentLink={doc.fileLink}
              icon={icon}
              index={index + 1}
              customLabel={customLabel}
              shouldShowCount={shouldShowCount}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Documents;
