import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectedRenterDetails, selectRenterApplication } from 'redux/selectors/agentSelector';

import { ResumeDrawer } from 'components/Agent/components/shared/ResumeDrawer/ResumeDrawer';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import RenterResumeNavbar from 'components/shared/Navbar/RenterResumeNavbar/RenterResumeNavbar';
import { MOBILE_VIEW_WIDTH } from 'constants/organizationConstants';
import { IsMobile } from 'helpers/IsMobile';
import { formatRenterName } from 'helpers/renterHelper';
import { Size, useWindowSize } from 'hooks/useResize';
import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { Invite } from 'shared/types/organizationTypes';

import { AgentDetailsForResume } from '../AgentDetails/AgentDetailsForResume';

import styles from './RenterResumeWrapper.module.scss';

interface RenterResumeWrapperProps extends PropsWithChildren {
  invite?: Invite;
}

const RenterResumeWrapper = ({ children, invite }: RenterResumeWrapperProps): JSX.Element => {
  const renterDetails = useSelector(selectedRenterDetails);
  const application = useSelector(selectRenterApplication);
  const size: Size = useWindowSize();
  const isMobile = IsMobile();
  const applicationStatus = (): string => {
    if (invite && invite.inviteCode) {
      return PropertyApplicationStatus.NOT_STARTED;
    } else if (application.isConfirmed) {
      return PropertyApplicationStatus.COMPLETED;
    } else {
      return PropertyApplicationStatus.IN_PROGRESS;
    }
  };
  const getPillType = (): PillTypes => {
    const status = applicationStatus();

    if (status === PropertyApplicationStatus.COMPLETED) {
      return PillTypes.SUCCESS;
    } else if (status === PropertyApplicationStatus.IN_PROGRESS) {
      return PillTypes.WARNING_DARK;
    } else {
      return PillTypes.WARM_GREY;
    }
  };

  if (!invite?.inviteCode && !application.id) {
    return <div></div>;
  }

  return (
    <div className={styles.container}>
      {!invite?.inviteCode ? (
        <>
          <div className={classNames(styles.AgentDetailsContainer)}>
            <AgentDetailsForResume />
          </div>
          <div className={styles.ResumeContentContainer}>
            <div className={styles.SideMenuContainer}>
              <ResumeDrawer
                contentComponent={
                  <div
                    className={classNames(styles.ResumeContent, {
                      [styles.DrawerContainer]: !isMobile,
                      [styles.ResumeContentMobile]: size.width <= MOBILE_VIEW_WIDTH,
                    })}
                  >
                    <div className={styles.pillDiv}>
                      <h1 className={styles.nameDiv}>{formatRenterName(renterDetails)}</h1>
                      <span>
                        <AgentPills
                          pillsData={[{ isItalic: true, heading: applicationStatus(), type: getPillType() }]}
                        />
                      </span>
                    </div>
                    <RenterResumeNavbar
                      creditScore={renterDetails.creditScore ?? ''}
                      applicationStatus={applicationStatus()}
                      experianAuth={renterDetails?.experianAuth}
                    />
                    <div
                      className={classNames(styles.ChildrenContainer, {
                        [styles.ChildrenContainerMobile]: size.width <= MOBILE_VIEW_WIDTH,
                      })}
                    >
                      {children}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </>
      ) : (
        <div className={styles.ResumeContainer}>
          <div className={styles.ResumeContent}>
            <div className={styles.pillDiv}>
              <h1 className={styles.nameDiv}>{invite?.renterEmail || formatRenterName(renterDetails)}</h1>
              <span>
                <AgentPills pillsData={[{ isItalic: true, heading: applicationStatus(), type: getPillType() }]} />
              </span>
            </div>
            <RenterResumeNavbar
              creditScore={renterDetails.creditScore ?? ''}
              applicationStatus={applicationStatus()}
              experianAuth={renterDetails?.experianAuth}
            />
            <div className={styles.ChildrenContainer}>{children}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RenterResumeWrapper;
