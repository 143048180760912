import { capitalize } from 'lodash-es';

import { SideMenuLevel } from 'constants/resumeSideMenuConstants';
import { SelectedOption, SingleSideMenuItem } from 'shared/types/renterTypes';

//Function to get the selected option list
export const getItemFromList = (list: SingleSideMenuItem[], option: string): SelectedOption[] => {
  let searchedArray: SelectedOption[] = [{ option: '', level: SideMenuLevel.ONE }];
  //Fucntion that will recursively search for the matching option and add it in the appended array
  //If the option is found it will go back in the stack flow and append the correct options parents in the selected Array
  const searchTheList = (searchList: SingleSideMenuItem[], level = 1): void => {
    for (const item of searchList) {
      if (!searchedArray[0].option) {
        if (capitalize(item.heading) === capitalize(option)) {
          searchedArray = [{ option: item.heading, level: level }]; //append in array if correct option

          return;
        } else if (item.subItems) {
          searchTheList(item.subItems, level + 1);

          //if correct option is found then add its parents to the array
          if (searchedArray[0].option && level + 1 === searchedArray[searchedArray.length - 1].level) {
            searchedArray = [...searchedArray, { option: item.heading, level: level }];
          }
        }
      }
    }
  };

  searchTheList(list);

  return searchedArray;
};

//function to know if toSearch exists in the list or not
export const isOptionPresentInList = (list: SelectedOption[], toSearch: SelectedOption): boolean =>
  list.some((item) => item.level === toSearch.level && capitalize(item.option) === capitalize(toSearch.option));
