import { useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';

import { ReactComponent as GreenTick } from 'assets/svgs/GreenTick.svg';
import { ReactComponent as InProgressYellow } from 'assets/svgs/InProgessYellow.svg';
import { Ladder } from 'components/Renter/IdentityVerification/RenterDetails/shared/Ladder/Ladder';
import AgentPills, { Pill, PillTypes } from 'components/shared/AgentPills/AgentPills';
import { VerificationStatus } from 'constants/agentConstants';
import { getPrimaryVerifier } from 'helpers/renterHelper';
import { durationBetweenTwoDates, formatAmount, formatDate } from 'helpers/user';
import { AddressContainerProps, AddressVerificationResponse } from 'shared/types/agentTypes';
import { AddressVerifier } from 'shared/types/renterTypes';

import VerifierDetails from '../VerifierDetails/VerifierDetails';

import { VerifierAddressContainer } from './VerifierAddressContainer';

import styles from '../AddressContainer.module.scss';

const AddressContainer = ({
  address,
  isPresent = false,
  heading = t('application.address'),
  isAddressInline = false,
  timePeriodLabel,
  shouldShowHeading = true,
  isVerifierAddress = false,
  verificationInformation,
}: AddressContainerProps): JSX.Element => {
  const primaryAddressVerifier = getPrimaryVerifier(address?.addressVerifiers ?? []);
  const getVerificationPill = useMemo(() => {
    if (verificationInformation?.verifierStatus === VerificationStatus.VERIFIED) {
      return {
        heading: (
          <>
            {VerificationStatus.VERIFIED} <GreenTick />
          </>
        ),
        type: PillTypes.SUCCESS_LIGHT,
      };
    } else if (verificationInformation?.verifierStatus === VerificationStatus.IN_PROGRESS) {
      return {
        heading: (
          <>
            {VerificationStatus.IN_PROGRESS} <InProgressYellow />
          </>
        ),
        type: PillTypes.WARNING,
      };
    } else if (verificationInformation?.verifierStatus === VerificationStatus.WARNING) {
      return { heading: VerificationStatus.WARNING, type: PillTypes.DANGER };
    }

    return null;
  }, [verificationInformation?.verifierStatus]);
  const timeSpent = useMemo((): string => {
    if (isPresent && address?.moveInDate) {
      const currentDate = new Date();

      return durationBetweenTwoDates(String(currentDate), String(address?.moveInDate));
    } else if (address?.toDate && address?.fromDate) {
      return durationBetweenTwoDates(String(address?.toDate), String(address?.fromDate));
    }

    return '';
  }, [isPresent, address]);
  const addressPills = useMemo((): Pill[] => {
    const data: Pill[] = [];
    const { isRented } = address || {};

    if (isRented) {
      const pillHeading = isPresent
        ? t('renter.ConfirmPresentAddress.iRent')
        : t('renter.ConfirmPresentAddress.iRented');
      const type = PillTypes.SECONDARY;

      data.push({ heading: pillHeading, type });
    }

    if (getVerificationPill) {
      data.push(getVerificationPill);
    }

    return data;
  }, [address, getVerificationPill, isPresent]);
  const renderVerifierDetails = useMemo(
    () => (
      <VerifierDetails
        verificationInformation={verificationInformation ?? ({} as AddressVerificationResponse)}
        address={address ?? {}}
        isAdjacentStyled
      />
    ),
    [address, verificationInformation]
  );

  return (
    <div className={styles.Container} key={address?.id}>
      {shouldShowHeading && (
        <div className={styles.HeadingContainer}>
          <span className={styles.SectionHeading}>{heading}</span>
          {/* <ThreeDotsIcon /> */}
        </div>
      )}
      {/* Address Information */}
      <div className={classNames(styles.AddressInformation, { [styles.NoBottomMargin]: !!address?.reasonForMoving })}>
        {!isAddressInline && (
          <>
            <span className={styles.StreetAddress}>
              <span>{address?.streetAddress}</span>
              <AgentPills pillsData={addressPills} />
            </span>
            <span className={styles.States}>
              {address?.city}, {address?.state}, {address?.zipCode}
            </span>
          </>
        )}
        {isAddressInline && (
          <span className={styles.StreetAddress}>
            <span>{`${address?.streetAddress}, ${address?.city}, ${address?.state} ${address?.zipCode}`}</span>
            <AgentPills pillsData={addressPills} />
          </span>
        )}
        <span className={styles.TimePillContainer}>
          {timePeriodLabel ? <span className={styles.MonthlyRent}>{timePeriodLabel}</span> : null}
          <span className={styles.States}>
            {isPresent ? (
              <span>
                {formatDate(address?.moveInDate)} - {t('renter.AddressEditModal.present')}
              </span>
            ) : (
              <span>
                {formatDate(String(address?.fromDate))} - {formatDate(String(address?.toDate))}
              </span>
            )}
          </span>
          {timeSpent && <span className={styles.TimePill}>{timeSpent}</span>}
        </span>

        {primaryAddressVerifier?.contactRent && (
          <span>
            <span className={styles.MonthlyRent}>{t('application.monthlyRent')}</span>
            <span className={styles.States}>{` $ ${formatAmount(primaryAddressVerifier.contactRent)}`}</span>
          </span>
        )}
      </div>
      {!!address?.reasonForMoving && (
        <div className={styles.LadderContainer}>
          <div className={styles.Ladder}>
            <div className={classNames(styles.VerticalLine, styles.VerticalLineMargin)} />
            <div className={styles.HorizontalLine} />
          </div>
          <span className={styles.ReasonForMovingContainer}>
            <span className={styles.Label}>{t('agnet.renterResumePdf.reasonForMoving')}</span>
            <span className={styles.Value}>{address?.reasonForMoving}</span>
          </span>
        </div>
      )}

      {primaryAddressVerifier && !isVerifierAddress && (
        <div className={styles.LadderContainer}>
          <Ladder
            ladderContainerClassName={styles.Ladder}
            verticalBranchClassName={styles.VerticalLine}
            horizontalBranchClassName={styles.HorizontalLine}
          />
          {renderVerifierDetails}
        </div>
      )}
      {isVerifierAddress && address && (
        <VerifierAddressContainer
          primaryVerifier={primaryAddressVerifier ?? ({} as AddressVerifier)}
          verificationInformation={verificationInformation ?? ({} as AddressVerificationResponse)}
        />
      )}
    </div>
  );
};

export default AddressContainer;
