import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import DefaultPropertyImage from 'assets/images/defaultImage.svg';
import defaultAgentProfile from 'assets/images/defaultProfilePicture.png';
import { ReactComponent as InfoIcon } from 'assets/svgs/InfoIcon.svg';
import { ReactComponent as MapPinIcon } from 'assets/svgs/MapPin.svg';
import { dummyAgentImages } from 'constants/agentConstants';
import { formatAmount } from 'helpers/user';
import { PorpertyHeaderProps } from 'shared/types/applicantsType';

import { AgentDetailBreadCrumbs } from '../AgentDetailBreadCrumbs/AgentDetailBreadCrumbs';
import { PropertyInterestedTab } from '../PropertyInterestedTab/PropertyInterestedTab';

import { PropertySpecification } from './PropertySpecification';

import styles from './PropertyHeader.module.scss';

export const PropertyHeader = ({
  streetAddress1,
  streetAddress2,
  availableDate,
  propertyType,
  sqFtSize,
  rent,
  beds,
  deposit,
  baths,
  ownerName,
  totalActive,
  totalHouseHolds,
  isCustomAddress = false,
  onDropDownClick,
  isShowDropdown = true,
  isInvited = false,
  totalUnits,
  agentName,
  propertyImages,
}: PorpertyHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.InformationContainer}>
        <div className={styles.Information}>
          <img
            src={propertyImages && !propertyImages.length ? DefaultPropertyImage : propertyImages[0].fileLink}
            width={24}
            height={24}
            alt={t('renter.applicationInfoBox.agentProfile')}
            className={styles.HomeIcon}
          />
          <div className={styles.MainInformation}>
            <div className={styles.PropertyAddress}>
              <MapPinIcon />
              <div className={styles.Address}>
                <span className={styles.StreetAddress1}>{streetAddress1}</span>
                <span>{`${totalUnits ? ', ' + totalUnits + ', ' : ' '}`}</span>
                <span className={styles.StreetAddress2}>{streetAddress2}</span>
              </div>
              {isCustomAddress && (
                <div className={styles.CustomContainer}>
                  <div className={styles.Custom}>{t('application.propertyHeader.custom')}</div> <InfoIcon />
                </div>
              )}
            </div>
            <div className={styles.PropertyAttributes}>
              {!isCustomAddress && (
                <div className={styles.AvailableContainer}>
                  <div className={styles.AvailableLabel}>{t('agent.applications.property.available')}</div>

                  <div className={styles.AvailableValue}>
                    {new Date(availableDate).toLocaleDateString() === new Date().toLocaleDateString()
                      ? t('application.propertyHeader.now')
                      : availableDate}
                  </div>
                </div>
              )}
              <div className={styles.PropertyType}>{propertyType}</div>
              {!isCustomAddress && (
                <div className={styles.PropertyDimensions}>
                  <div className={styles.DimensionScale}>{t('renter.applicationInfoBox.sqFt')}</div>
                  <div className={styles.DimensionValue}>{`${formatAmount(sqFtSize ?? 0)}`}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isCustomAddress && (
          <div className={styles.PropertySpecifications}>
            <PropertySpecification
              value={rent ? `$${formatAmount(rent ?? 0)}` : '___'}
              label={t('renter.applicationInfoBox.rent')}
            />
            <PropertySpecification
              value={deposit ? `$${formatAmount(deposit ?? 0)}` : '___'}
              label={t('renter.applicationInfoBox.deposit')}
            />
            <PropertySpecification
              value={beds ? `X ${(beds ?? 0).toFixed(1)}` : '___'}
              label={t('renter.applicationInfoBox.beds')}
            />
            <PropertySpecification
              value={baths ? `X ${(baths ?? 0).toFixed(1)}` : '___'}
              label={t('renter.applicationInfoBox.baths')}
            />

            {isShowDropdown ? (
              <div className={styles.FullWidth}>
                <AgentDetailBreadCrumbs agentName={agentName} images={dummyAgentImages} />
              </div>
            ) : (
              <div className={styles.SelectedAgentInformation}>
                <img
                  src={defaultAgentProfile}
                  width={24}
                  height={24}
                  alt={t('renter.applicationInfoBox.agentProfile')}
                  className={styles.AgentProfilePic}
                />
                <div className={styles.AgentInformation}>
                  <div className={styles.AgentDetails}>
                    <p className={classNames(styles.AgentName, styles.SelectedAgentName)}>{ownerName}</p>
                  </div>
                  {/* <div className={styles.AgentIcons}>
                <DotsIcon />
              </div> */}
                </div>
              </div>
            )}
          </div>
        )}
        {isCustomAddress && (
          <div className={styles.PropertySpecifications}>
            {isShowDropdown ? (
              <div className={styles.FullWidth}>
                <AgentDetailBreadCrumbs agentName={agentName} images={dummyAgentImages} />
              </div>
            ) : (
              <div className={styles.SelectedAgentInformation}>
                <img
                  src={defaultAgentProfile}
                  width={24}
                  height={24}
                  alt={t('renter.applicationInfoBox.agentProfile')}
                  className={styles.AgentProfilePic}
                />
                <div className={styles.AgentInformation}>
                  <div className={styles.AgentDetails}>
                    <p className={classNames(styles.AgentName, styles.SelectedAgentName)}>{ownerName}</p>
                  </div>
                  {/* <div className={styles.AgentIcons}>
                <DotsIcon />
              </div> */}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {isShowDropdown && onDropDownClick && (
        <PropertyInterestedTab
          onDropDownClick={onDropDownClick}
          totalActive={totalActive}
          totalHouseHolds={totalHouseHolds}
          isInvited={isInvited}
        />
      )}
    </div>
  );
};
