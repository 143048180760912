import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs';

export const DocumentPreview = ({ link }: { link: string }): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>();
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setTotalPages(numPages);
  };

  useEffect(() => {
    const handleResize = (): void => {
      if (window?.innerWidth <= 767) {
        setWidth(window.innerWidth - 30);
      } else {
        setWidth(ref?.current?.offsetWidth || 0);
      }
    };

    if (ref.current) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div ref={ref} style={{ width: 'inherit' }}>
      <Document file={link} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(totalPages), (_el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width} />
        ))}
      </Document>
    </div>
  );
};
