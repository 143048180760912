import { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import styles from './VerifierAddressContainer.module.scss';

export const SubmittedViewTooltip = ({ value }: { value: string }): JSX.Element => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const renderTooltip = (
    <Popover
      id="popover-positioned-bottom"
      onMouseLeave={() => setIsPopoverVisible(false)}
      onMouseEnter={() => setIsPopoverVisible(true)}
    >
      <Popover.Body>
        <div className={styles.HoverContent}>{value}</div>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    function handleResize(): void {
      if (divRef.current) {
        setIsOverflowing(divRef.current.scrollWidth > divRef.current.clientWidth);
      }
    }

    handleResize();

    // Listen for resize events
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <OverlayTrigger
      show={isPopoverVisible}
      key="bottom"
      placement={'top-start'}
      overlay={isOverflowing ? renderTooltip : <div />}
    >
      <div onMouseEnter={() => setIsPopoverVisible(true)} onMouseLeave={() => setIsPopoverVisible(false)}>
        <div ref={divRef} className={styles.Content}>
          {value}
        </div>
      </div>
    </OverlayTrigger>
  );
};
