import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';

import { ReactComponent as CarIcon } from 'assets/svgs/CarIconDark.svg';
import { ReactComponent as EditIcon } from 'assets/svgs/EditPencil.svg';
import { renterRoutes } from 'shared/routes';

import styles from './NoVehicleCard.module.scss';

const VehicleDescriptionCard = (): JSX.Element => {
  const navigate = useNavigate();
  const { applicationId } = useParams();

  return (
    <div className={styles.noVehicleCard}>
      <div className={styles.infoRow}>
        <div className={styles.infoItem}>
          <CarIcon />
          {t('renter.vehiclesInformation.confirmation.noVehicles')}
        </div>
        <button
          type="button"
          className={styles.editButton}
          onClick={() => navigate(renterRoutes.generateVehiclesInformation(applicationId))}
        >
          {<EditIcon className={styles.editIcon} />}
        </button>
      </div>
    </div>
  );
};

export default VehicleDescriptionCard;
