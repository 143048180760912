import { useNavigate } from 'react-router-dom';

import { ReactComponent as ViewDetailsSvg } from 'assets/svgs/ViewDetailsSvg.svg';
import { routes } from 'shared/routes';

import styles from './../PropertyIndex.module.scss';
export interface ViewDetailsProps {
  id: number | undefined;
}

const PropertyViewDetails = ({ id }: ViewDetailsProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <button
      className={styles.propertyLinksLarge}
      onClick={() => navigate(routes.generatePropertyDetails(Number(id)), { state: { editable: true } })}
    >
      <ViewDetailsSvg /> Manage Details
    </button>
  );
};

export default PropertyViewDetails;
