import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { capitalize, isEmpty, startCase } from 'lodash-es';
import { selectedRenterDetails, selectVerifierEmploymentInformation } from 'redux/selectors/agentSelector';

import { ReactComponent as DocumentIcon } from 'assets/svgs/DocumentIcon.svg';
import { ReactComponent as GreenTick } from 'assets/svgs/GreenTick.svg';
import { ReactComponent as InProgressYellow } from 'assets/svgs/InProgessYellow.svg';
// import { ReactComponent as GreenTick } from 'assets/svgs/GreenTick.svg';
import indexStyles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import renterIncomeStyles from 'components/Agent/Applications/ApplicationDetails/components/RenterIncome/RenterIncome.module.scss';
import { Documents } from 'components/Agent/Applications/ApplicationDetails/components/shared/index';
import { Ladder } from 'components/Renter/IdentityVerification/RenterDetails/shared/Ladder/Ladder';
import AgentPills, { Pill, PillTypes } from 'components/shared/AgentPills/AgentPills';
import { VerificationStatus } from 'constants/agentConstants';
// import Button from 'components/shared/Button/Button';
import { durationBetweenTwoDates, formatAmount, formatDate } from 'helpers/user';
import { VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { AddressVerificationResponse, EmploymentProps } from 'shared/types/agentTypes';
import { EditEmploymentTypes, getEmploymentType } from 'shared/types/renterEmploymentInformation';

import { VerifierDetails } from '../../../RenterAddressHistory/components';
import { QuestionsDropDown } from '../../../RenterAddressHistory/components/QuestionsDropDown/QuestionsDropDown';

import styles from './PresentEmploymentCard.module.scss';
const PresentEmploymentCard = ({
  employment,
  indexValue = 0,
  isVerifierModal = false,
}: EmploymentProps): JSX.Element => {
  const { t } = useTranslation();
  const { employmentSubType, documents, jobStartDate, status, company, title, monthlyGrossIncome } = employment;
  const employmentVerificationInformation = useSelector(selectVerifierEmploymentInformation);
  const renterDetails = useSelector(selectedRenterDetails);
  const isTypeOther = useMemo(() => employmentSubType === EditEmploymentTypes.OTHERS, [employmentSubType]);
  const isSelfEmployed = useMemo(() => employmentSubType === EditEmploymentTypes.SELF_EMPLOYED, [employmentSubType]);
  const isEmployed = useMemo(() => employmentSubType === EditEmploymentTypes.EMPLOYED, [employmentSubType]);
  const hasDocuments = useMemo(() => !!documents?.length, [documents]);
  const currentEmploymentVerification = useMemo(
    () =>
      employmentVerificationInformation.find(
        (emp) => emp?.renterEmploymentInformation?.presentEmploymentId === employment.id
      ) ?? ({} as AddressVerificationResponse),
    [employmentVerificationInformation, employment.id]
  );
  const getVerificationPill = useMemo(() => {
    const verificationInformation = employmentVerificationInformation?.find(
      (emp) => emp.renterEmploymentInformation?.presentEmploymentId === employment.id
    );

    if (verificationInformation?.verifierStatus === VerificationStatus.VERIFIED) {
      return {
        heading: (
          <>
            {VerificationStatus.VERIFIED} <GreenTick />
          </>
        ),
        type: PillTypes.SUCCESS_LIGHT,
      };
    } else if (verificationInformation?.verifierStatus === VerificationStatus.IN_PROGRESS) {
      return {
        heading: (
          <>
            {VerificationStatus.IN_PROGRESS} <InProgressYellow />
          </>
        ),
        type: PillTypes.WARNING,
      };
    } else if (verificationInformation?.verifierStatus === VerificationStatus.WARNING) {
      return { heading: VerificationStatus.WARNING, type: PillTypes.DANGER };
    }

    return null;
  }, [employment.id, employmentVerificationInformation]);
  const employmentPills = useMemo((): Pill[] => {
    const data: Pill[] = [];
    const pillHeading = startCase(getEmploymentType(employmentSubType));

    data.push({ heading: pillHeading, type: PillTypes.SECONDARY });

    if (getVerificationPill) {
      data.push(getVerificationPill);
    }

    return data;
  }, [employmentSubType, getVerificationPill]);
  const timeSpent = useMemo((): string => {
    if (jobStartDate) {
      const currentDate = new Date();

      return durationBetweenTwoDates(String(currentDate), String(jobStartDate));
    }

    return '';
  }, [jobStartDate]);
  const durationPill = useMemo((): Pill[] => {
    const data: Pill[] = [];
    const time = timeSpent;

    data.push({ heading: time, type: PillTypes.SECONDARY });

    return data;
  }, [timeSpent]);
  const renderEmploymentVerifierDetails = useMemo(
    () => (
      <div className={styles.EmploymentCol}>
        <VerifierDetails
          verificationInformation={currentEmploymentVerification}
          employment={employment ?? {}}
          isAdjacentStyled
          isVerifierModal={isVerifierModal}
        />
        {!isEmpty(currentEmploymentVerification.submittedVerifierResponse) && (
          <QuestionsDropDown
            verificationInformation={currentEmploymentVerification}
            type={VerifierURLType.VOE}
            renterDetails={renterDetails}
          />
        )}
      </div>
    ),
    [employment, isVerifierModal, currentEmploymentVerification, renterDetails]
  );

  return (
    <div>
      <div className={styles.Container}>
        <div className={styles.Header}>
          <span className={styles.Text}>
            {t('agent.applicants.renterResume.sourceOfIncome.source')} {!isVerifierModal ? indexValue + 1 : ''}
          </span>
          {/* <ThreeDotsIcon width={24} height={24} /> */}
        </div>
        <div className={styles.Section}>
          <div className={styles.Info}>
            <div className={styles.LeftDiv}>
              <div className={styles.Data}>
                {isTypeOther && <span className={styles.Title}>{capitalize(status)}</span>}
                {!isSelfEmployed && <span className={styles.Title}>{capitalize(company)}</span>}
                <span className={styles.Text}>{capitalize(title)}</span>
                {!isTypeOther && (
                  <span className={classNames(styles.Text, styles.Date)}>
                    {`${formatDate(jobStartDate)} - ${t('addressBox.present')}`}
                    {timeSpent && <AgentPills pillsData={durationPill} pillContainerClassName={styles.Time} />}
                  </span>
                )}
              </div>
            </div>
            <div className={styles.RightDiv}>
              <AgentPills pillsData={employmentPills} />
            </div>
          </div>
          <div className={styles.MonthlyIncome}>
            <span className={indexStyles.label}>
              {t('agent.applicants.renterResume.sourceOfIncome.source.monthlyIncome')}
            </span>
            <span className={indexStyles.value}>$ {formatAmount(monthlyGrossIncome ?? 0)}</span>
          </div>
          {/* Todo make collapsible collapsed by default */}

          {hasDocuments && !isVerifierModal && (
            <Documents
              documents={documents}
              title={t('agent.applicants.renterResume.sourceOfIncome.incomeReport.supportingDocuments')}
              // uploadButton={
              //   <Button type="button" variant="outline" className={styles.SmallButton}>
              //     {t('agent.applicants.renterResume.sourceOfIncome.incomeReport.supportingDocuments.add')}
              //   </Button>
              // }
              icon={<DocumentIcon width={32} height={32} />}
            />
          )}
        </div>

        {isEmployed && (
          <div className={renterIncomeStyles.FinancialInstitutions}>
            {!isVerifierModal && (
              <Ladder
                ladderContainerClassName={renterIncomeStyles.Ladder}
                verticalBranchClassName={renterIncomeStyles.VerticalLine}
                horizontalBranchClassName={
                  (renterIncomeStyles.HorizontalLine, renterIncomeStyles.VerifierCardHorizontalLine)
                }
              />
            )}
            {renderEmploymentVerifierDetails}
          </div>
        )}
      </div>
    </div>
  );
};

export default PresentEmploymentCard;
