import { useTranslation } from 'react-i18next';

import { ReactComponent as IntellirentIcon } from 'assets/svgs/IntellirentIcon.svg';
import { ReactComponent as IntellirentLogo } from 'assets/svgs/intellirentLogo.svg';
import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';

export const RequestVerificationHeader = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.HeaderContainer}>
      <div className={styles.LogoContainer}>
        <IntellirentIcon />
        <IntellirentLogo />
      </div>
      <div className={styles.HeadingContainer}>{t('rental.historyInformation.requestVerification')}</div>
    </div>
  );
};
