import { useTranslation } from 'react-i18next';
import { Text, View } from '@react-pdf/renderer';

import { formatDateToShortFormat } from 'helpers/applicantsHelper';
import { formatAmountToUsdString } from 'helpers/helper';
import { formatPhoneNumber } from 'helpers/renterHelper';
import { sharedStyles } from 'shared/pdf/sharedStyles';
import { RenterEmploymentDetails } from 'shared/types/renterTypes';

import { PDFCheckBox } from '../PDFCheckbox/PDFCheckbox';
import { verifierStyles } from '../verifierStyles';

interface Props {
  userDetails: RenterEmploymentDetails;
  submittedAt?: string;
}

export const EmployeeInfoSectionPDF = ({ userDetails, submittedAt }: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    verifierEmail,
    verifierFullName,
    verifierPhoneNumber,
    renterEmail,
    renterFirstName,
    renterLastName,
    renterPhoneNumber,
    renterEmploymentInformation,
  } = userDetails;
  const { title, company, monthlyGrossIncome, jobStartDate } = renterEmploymentInformation || {};

  return (
    <View>
      <View style={verifierStyles.verifierHeading}>
        <Text>{'AUTHORIZATION TO RELEASE EMPLOYMENT INFORMATION'}</Text>
      </View>
      <View style={verifierStyles.subtext}>
        <Text>{t('employment.historyVerification.pdfDisclaimer.voe')}</Text>
      </View>
      <View style={verifierStyles.boldSubText}>
        <Text>{t('employment.historyVerification.immediateResponseText')}</Text>
      </View>
      <View style={verifierStyles.authorizeContainer}>
        <PDFCheckBox isChecked={true} />
        <Text>{'I hereby authorize the release of information for verification.'}</Text>
      </View>
      <View style={verifierStyles.nameContainer}>
        <Text>{`${renterFirstName} ${renterLastName}`}</Text>
        <View style={sharedStyles.line} />
        <View style={verifierStyles.signatureContainer}>
          <Text>{`Digitally signed by ${renterFirstName} ${renterLastName}`}</Text>
          {submittedAt && <Text>{`Date: ${formatDateToShortFormat(submittedAt)}`}</Text>}
        </View>
      </View>
      <View style={verifierStyles.contactInformation}>
        <Text>{'Contact Information'}</Text>
      </View>
      <View style={verifierStyles.contactContainer}>
        <View>
          <Text style={sharedStyles.greyHeading}>{'Email'}</Text>
          <Text>{renterEmail}</Text>
        </View>
        <View>
          <Text style={sharedStyles.greyHeading}>{'Mobile'}</Text>
          <Text>{formatPhoneNumber(renterPhoneNumber)}</Text>
        </View>
      </View>
      <View style={verifierStyles.greyLine} />
      <View style={verifierStyles.submittedByRenter}>
        <Text>{'Submitted by employee'}</Text>
      </View>
      <View style={verifierStyles.greyHeadingRow}>
        <Text>{'SOURCE & INCOME'}</Text>
        <Text>{'COMPANY REPRESENTATIVE'}</Text>
      </View>
      <View style={verifierStyles.renterContainer}>
        <View style={verifierStyles.rentalProperty}>
          <View>
            <Text style={verifierStyles.greyText}>{'Company'}</Text>
            <Text>{company}</Text>
          </View>
          <View>
            <Text style={verifierStyles.greyText}>{'Title'}</Text>
            <Text>{title}</Text>
          </View>
          <View>
            <Text style={verifierStyles.greyText}>{'Dates of Employment'}</Text>
            <Text>{`${jobStartDate} - Present`}</Text>
          </View>
          <View>
            <Text style={verifierStyles.greyText}>{'Monthly Rent'}</Text>
            <Text>{formatAmountToUsdString(monthlyGrossIncome)}</Text>
          </View>
        </View>
        <View style={verifierStyles.ownerContainer}>
          <View>
            <Text style={verifierStyles.greyText}>{'Full Name'}</Text>
            <Text>{verifierFullName}</Text>
          </View>
          <View>
            <Text style={verifierStyles.greyText}>{'Email'}</Text>
            <Text>{verifierEmail}</Text>
          </View>
          <View>
            <Text style={verifierStyles.greyText}>{'Phone'}</Text>
            <Text>{formatPhoneNumber(verifierPhoneNumber)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};
