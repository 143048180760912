import BootstrapButton, { ButtonProps } from 'react-bootstrap/Button';
import classnames from 'classnames';

import styles from './Button.module.scss';

const RCButton: React.FC<ButtonProps> = ({ children, className, ...props }) => (
  <BootstrapButton {...props} bsPrefix="ir-btn" className={classnames(styles.irBtn, className)}>
    {children}
  </BootstrapButton>
);

export default RCButton;
