import { useCallback, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import classNames from 'classnames';
import { getMonth, getYear, isValid, parseISO } from 'date-fns';
import { useField } from 'formik';

import { ReactComponent as CalendarIcon } from 'assets/svgs/calendarIcon.svg';
import { ReactComponent as ArrowIcon } from 'assets/svgs/leftArrowIcon.svg';
import { dateFormatFrontend, dateInputMask, getYears, months } from 'constants/calendarConstants';

import Button from '../Button/Button';
import ErrorMessage from '../CustomErrorMessage/ErrorMessage';

import MonthYearSelect from './MonthYearSelect';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './CustomDatePicker.module.scss';
export interface IDateProps {
  name: string;
  placeholder?: string;
  floatingLabel?: string;
  handleBlur?: (date: Date) => void;
  className?: string;
  isIconOnLeft?: boolean;
  isShowPresent?: boolean;
  startDate?: string;
  endDate?: string;
  defaultSelected?: Date;
  floatingLabelStyling?: string;
  isDisabled?: boolean;
}

const CustomDatePicker = ({
  defaultSelected = new Date(),
  name,
  handleBlur,
  floatingLabel = 'Available Date',
  placeholder = dateFormatFrontend,
  className,
  isShowPresent = false,
  isIconOnLeft = false,
  startDate,
  endDate,
  floatingLabelStyling,
  isDisabled,
}: IDateProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dateSetRef = useRef(false);
  const dateChangedRef = useRef(false);
  const [, { value: selectedValue, touched, error }, { setValue, setTouched }] = useField({ name });
  const myRef = useRef<DatePicker>(null);
  const onClickHandler = (): void => {
    if (!dateSetRef.current) {
      if (!selectedValue) {
        setValue(defaultSelected);
      }

      if (!error && handleBlur) {
        handleBlur(defaultSelected);
      }

      dateSetRef.current = true;
    }
  };
  const onDateChangeHandler = (date: Date): void => {
    if (!date) {
      setValue(null);

      return;
    }

    setValue(date);

    if (dateChangedRef.current && handleBlur) {
      handleBlur(date);
      dateChangedRef.current = false;
    }
  };
  const onHandlerOk = useCallback((): void => {
    if (!error && handleBlur) {
      handleBlur(selectedValue);
    }

    setIsOpen(false);
    myRef.current?.setOpen(false);
  }, [error, handleBlur, selectedValue]);
  const onResetHandler = useCallback((): void => {
    setValue(defaultSelected || startDate || null);
  }, [defaultSelected, setValue, startDate]);
  const onHandleCalenderIcon = (): void => {
    setIsOpen(!isOpen);
  };
  const onRawChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isValid(new Date(e.target.value))) {
        setTouched(true);
        setValue(new Date(e.target.value));
        dateChangedRef.current = true;
      }
    },
    [setTouched, setValue]
  );

  return (
    <div>
      <div className={classNames(styles.dateContainer, className, error && touched ? styles.dateError : '')}>
        <div
          className={classNames(floatingLabelStyling, styles.label, {
            [styles.iconLeftLabel]: isIconOnLeft || isShowPresent,
          })}
        >
          {floatingLabel}
        </div>
        {isShowPresent && <div className={styles.presentTextContainer}>Present</div>}
        <div className={styles.datePickerContainer}>
          {isIconOnLeft && !isShowPresent && (
            <CalendarIcon
              data-testid="myCalendarSvg"
              onClick={onHandleCalenderIcon}
              // eslint-disable-next-line @typescript-eslint/naming-convention
              className={classNames(styles.customCalendarIconLeft, { 'pe-none': isDisabled })}
            />
          )}

          {!isShowPresent && (
            <DatePicker
              ref={myRef}
              maxDate={endDate ? new Date(endDate) : undefined}
              placeholderText={placeholder}
              popperClassName={styles.popperCustomClass}
              className={classNames(styles.removeBorder)}
              onInputClick={onClickHandler}
              showPopperArrow={false}
              selected={selectedValue && typeof selectedValue === 'string' ? new Date(selectedValue) : selectedValue}
              onChangeRaw={onRawChangeHandler}
              onChange={onDateChangeHandler}
              shouldCloseOnSelect={false}
              minDate={startDate ? new Date(parseISO(startDate)) : new Date()}
              open={isOpen}
              onYearChange={onDateChangeHandler}
              onMonthChange={onDateChangeHandler}
              customInput={<MaskedTextInput type="text" mask={dateInputMask} />}
              popperPlacement="bottom-start"
              disabled={isDisabled}
              onClickOutside={() => {
                setIsOpen(false);
              }}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className={styles.headerContainer}>
                  <Button className={styles.customButton} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <ArrowIcon />
                  </Button>

                  <MonthYearSelect
                    className={classNames(styles.monthSelect, styles.customSelect)}
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) => {
                      changeMonth(months.indexOf(value));
                    }}
                    options={months}
                  />
                  <MonthYearSelect
                    className={classNames(styles.yearSelect, styles.customSelect)}
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(+value)}
                    options={getYears(startDate, endDate)}
                  />

                  <Button className={styles.customButton} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <ArrowIcon className={styles.iconRotate} />
                  </Button>
                </div>
              )}
            >
              <div className={classNames(styles.footerContainer)}>
                <div className={styles.horizontalLine}></div>
                <div className={styles.customFooter}>
                  <Button onClick={onResetHandler} className={styles.buttonReset}>
                    Reset
                  </Button>
                  <Button onClick={onHandlerOk} className={styles.buttonOk}>
                    Ok
                  </Button>
                </div>
              </div>
            </DatePicker>
          )}
        </div>
        {!isIconOnLeft && !isShowPresent && (
          <CalendarIcon
            data-testid="myCalendarSvg"
            onClick={onHandleCalenderIcon}
            // eslint-disable-next-line @typescript-eslint/naming-convention
            className={classNames(styles.customCalendarIcon, { 'pe-none': isDisabled })}
          />
        )}
      </div>
      <span className={styles.marginTop}>{touched && error && <ErrorMessage message={error} />}</span>
    </div>
  );
};

export default CustomDatePicker;
