import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ErrorSvg } from 'assets/svgs/InfoError.svg';
import styles from 'components/shared/ServerErrors/ServerErrors.module.scss';

import { ServerErrorCard } from '../ServerErrorCard/ServerErrorCard';

export const PageNotFoundError = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.Container}>
      <ServerErrorCard
        mainHeading={t('modal.ServerNotFoundModal.heading')}
        subHeading={t('modal.ServerNotFoundModal.subHeading')}
        description={t('modal.ServerNotFoundModal.subHeading2')}
        errorIcon={<ErrorSvg />}
        onButtonClick={() => navigate(-1)}
      />
    </div>
  );
};
