import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Formik } from 'formik';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { applicantRenterRole, renterApplication } from 'redux/selectors/renterSelector';
import { updateApplication } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RCButton from 'components/shared/Button/Button';
import { renterRestrictions } from 'constants/restrictionConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../../RenterContainer';
import RenterIconInfoContainer from '../../RenterIconInfoContainer';

import styles from '../../../RenterIndex.module.scss';
import adultStyles from './RenterAdult.module.scss';

const RelationshipIndex = (): JSX.Element => {
  const navigation = useNavigate();
  const { applicationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const renterRole = useSelector(applicantRenterRole);
  const applications = useSelector(renterApplication);
  const maxHeight = useDeviceHeight(240);
  const { hasNoAdults, adults, minors } = applications?.find((app) => app.id === Number(applicationId)) || {};

  useEffect(() => {
    if (renterRestrictions[renterRole].adults || (hasNoAdults && isEmpty(adults))) {
      navigation(renterRoutes.renterRelationMinorIndex.replace(':applicationId', `${applicationId}`));
    } else if (adults?.length) {
      navigation(renterRoutes.renterRelationAdultConfirmation.replace(':applicationId', `${applicationId}`));
    }
  }, [renterRole, navigation, applicationId, hasNoAdults, adults]);

  const onClickYes = useCallback(() => {
    if (adults?.length) {
      navigation(renterRoutes.renterRelationAdultConfirmation.replace(':applicationId', `${applicationId}`));
    } else {
      navigation(renterRoutes.renterAdults.replace(':applicationId', `${applicationId}`));
    }
  }, [adults?.length, navigation, applicationId]);
  const onClickNo = useCallback(() => {
    if (!hasNoAdults) {
      dispatch(updateApplication({ values: { hasNoAdults: true }, applicationId: Number(applicationId) }));
    }

    if (minors?.length) {
      navigation(renterRoutes.renterRelationMinorConfirmation);
    } else {
      navigation(renterRoutes.renterRelationMinorIndex.replace(':applicationId', `${applicationId}`));
    }
  }, [hasNoAdults, minors, dispatch, applicationId, navigation]);

  return (
    <Formik
      initialValues={{}}
      validationSchema={{}}
      onSubmit={() => {
        console.log('formik values');
      }}
    >
      {() => (
        <RenterContainer>
          <section className={styles.renterInvitePropertyContainer}>
            <div style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
              <RenterIconInfoContainer />
              <h2 className={styles.renterInvitePropertyH2}>
                {t('renter.relationship.headingAnother')}&nbsp;
                <span className={styles.renterSpan}>{t('renter.relationship.adultLabel')}</span>&nbsp;
                {t('renter.relationship.adultRental')}
              </h2>

              <div className={adultStyles.maritalContainer}>
                <div className={adultStyles.maritalButtonContainer}>
                  <RCButton onClick={onClickYes} className={adultStyles.renterMaritalButton} variant="outline">
                    {t('renter.sSNCheck.yes')}
                  </RCButton>
                  <RCButton
                    onClick={onClickNo}
                    className={classNames(adultStyles.renterMaritalButton)}
                    variant="outline"
                  >
                    {t('renter.relationship.discardNoButton')}
                  </RCButton>
                </div>
              </div>
            </div>
          </section>
        </RenterContainer>
      )}
    </Formik>
  );
};

export default RelationshipIndex;
