import { VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import {
  FormValues,
  RenterAddressDetails,
  RenterEmploymentDetails,
  RenterEmploymentInformation,
  RenterProfileInformationProps,
  UserDetailsOnVerificationPageProps,
} from 'shared/types/renterTypes';

interface VerificationPDFProps {
  questionnaire: FormValues;
  userEmploymentDetails?: RenterEmploymentDetails;
  userAddressDetails?: RenterAddressDetails;
}

interface Props {
  type: VerifierURLType;
  renterDetails: RenterProfileInformationProps;
  employmentResponse?: AddressVerificationResponse;
  addressResponse?: AddressVerificationResponse;
}

export const formatAddressAndEmploymentResponseToPDF = ({
  type,
  renterDetails,
  employmentResponse,
  addressResponse,
}: Props): VerificationPDFProps => {
  const data = type === VerifierURLType.VOE ? employmentResponse : addressResponse;

  if (!data) {
    return { questionnaire: {} as FormValues };
  }

  const questionnaire: FormValues = {
    verificationQuestions: data.submittedVerifierResponse?.map((question) => ({
      id: question.id,
      question: question.question,
      answer: question.answer,
      meta: question.meta,
      questionType: question.status,
      acceptedReason: '',
    })),
    submittedAt: data?.submittedAt as string | undefined,
    submitVerifier: {
      phoneNumber: data?.submitVerifierPhoneNumber,
      fullName: data?.submitVerifierFullName,
      emailAddress: data?.submitVerifierEmail,
      companyName: data?.submitVerifierCompany,
      relationshipProperty: data?.submitVerifierRelationshipProperty,
      privacyConsent: true,
      titleOfVerifier: data?.submitVerifierTitle,
    },
    verifierType: data.verifierType,
  };
  const userDetails: UserDetailsOnVerificationPageProps = {
    renterEmail: renterDetails.email ?? '',
    renterFirstName: renterDetails.firstName ?? '',
    renterLastName: renterDetails.lastName ?? '',
    renterPhoneNumber: renterDetails.renterPhoneNumbers?.[0].phoneNumber as string,
    requestedAt: data?.requestedAt as string,
    verifierEmail: data?.renterVerifierEmail,
    verifierFullName: data?.renterVerifierFullName,
    verifierPhoneNumber: data?.renterVerifierPhoneNumber,
    renterMiddleName: renterDetails.middleName ?? '',
  };
  const userEmploymentDetails: RenterEmploymentDetails | undefined = data?.renterEmploymentInformation
    ? {
        renterEmploymentInformation: data?.renterEmploymentInformation as RenterEmploymentInformation,
        ...userDetails,
      }
    : undefined;
  const userAddressDetails: RenterAddressDetails | undefined = data?.renterAddressInformation
    ? {
        renterAddressInformation: {
          addressId: data?.renterAddressInformation.addressId.toString() ?? '',
          city: data?.renterAddressInformation.city ?? '',
          state: data?.renterAddressInformation.state ?? '',
          zipCode: data?.renterAddressInformation.zipCode ?? '',
          addressType: data?.renterAddressInformation.addressType ?? '',
          streetAddress: data?.renterAddressInformation.streetAddress ?? '',
          fromDate: data?.renterAddressInformation.fromDate ?? '',
          moveInDate: data?.renterAddressInformation.moveInDate ?? '',
          rent: data?.renterAddressInformation.rent ?? 0,
          toDate: data?.renterAddressInformation.toDate ?? '',
        },
        ...userDetails,
      }
    : undefined;

  return { questionnaire, userEmploymentDetails, userAddressDetails };
};
