import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { isLoading } from 'redux/selectors/authSelector';
import { login } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';
import { LoginFormSchema } from 'schema/authSchema';

import { ReactComponent as HomeDesign } from 'assets/svgs/authBackGround.svg';
import { ReactComponent as PasswordIcon } from 'assets/svgs/PasswordIcon.svg';
import { ReactComponent as PasswordSlashIcon } from 'assets/svgs/PasswordSlashIcon.svg';
import Button from 'components/shared/Button/Button';
import CustomErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import CustomSpinner from 'components/shared/Spinner/Spinner';
import FormikField from 'components/shared/TextField/FormikField';
import { tabTitle } from 'helpers/helper';
import { renterRoutes, routes } from 'shared/routes';
import { UserType } from 'shared/types/authType';
import { AuthValues } from 'shared/types/authType';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from './Login.module.scss';

const validValues = {
  email: '',
  password: '',
};
const Login = (): JSX.Element => {
  const [errorMessages, setErrorMessages] = useState<string>('');
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const isLoadingEnabled = useSelector(isLoading);
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    tabTitle('Login');
  }, []);
  const handleSignUp = useCallback(
    (values: AuthValues): void => {
      dispatch(login({ values }))
        .unwrap()
        .then(({ userType }) => {
          if (userType === UserType.AGENT) {
            navigate(routes.applicants);
          } else {
            navigate(renterRoutes.renter);
          }
        })
        .catch(({ errorMessage }) => {
          setErrorMessages(errorMessage);
        });
    },
    [dispatch, navigate]
  );

  return (
    <div className={classnames('px-lg-4 px-sm-0 px-md-5', styles.container)}>
      <Row className={styles.containerRow}>
        <Col xl={4} lg={4} md={8} className={styles.signUpCol}>
          <HomeDesign className={classnames(styles.homeDesignTop)} />
          <Card className={styles.cardContainer}>
            <HomeDesign className={classnames(styles.homeDesignBottom)} />
            <div className="card-body py-5 px-5">
              <div className={styles.loginHeading}>{t('agent.login.button')}</div>

              <Formik initialValues={validValues} validationSchema={LoginFormSchema} onSubmit={handleSignUp}>
                {({ values, isValid, dirty: isDirty }) => (
                  <Form>
                    <FormikField
                      className="inputBackgroundColor"
                      name="email"
                      type="email"
                      label="Email Address"
                      placeholder="Email"
                    />
                    <FormikField
                      className={classNames('inputBackgroundColor', styles.PasswordField)}
                      name="password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      label="Password"
                      placeholder="Password"
                      onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      customIcon={isPasswordVisible ? <PasswordSlashIcon /> : <PasswordIcon />}
                    />

                    {errorMessages && <CustomErrorMessage message={errorMessages} />}
                    <div className={styles.rememberMePasswordContainer}>
                      {/* <CheckBox label={t('agent.login.rememberMe')} />
                      <Button variant="link-primary" className={styles.loginLink}>
                        {t('agent.login.forgotPassword')}
                      </Button> */}
                    </div>
                    <div className={styles.createBtn}>
                      <Button
                        variant="primary"
                        type="submit"
                        onClick={() => {
                          handleSignUp(values);
                        }}
                        disabled={isLoadingEnabled || !(isValid && isDirty)}
                        className={styles.btnPrimary}
                      >
                        {t('agent.login.button')} {isLoadingEnabled && <CustomSpinner />}
                      </Button>
                    </div>
                    <div className={styles.ForgotPassword}>
                      <Link className={styles.ForgotPasswordLink} to={routes.forgotPassword}>
                        {t('agent.login.forgotPassword')}
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
