import { useTranslation } from 'react-i18next';

import { BackgroundDataTypes } from 'constants/agentConstants';
import { EmptyBackgroundInfoProps } from 'shared/types/reportTypes';

import EmptyBackgroundInfo from '../EmptyBackgroundInfo/EmptyBackgroundInfo';

import styles from './EmptyBackgroundReport.module.scss';
export const EmptyBackgroundReport = ({
  page,
  dateOfBirth,
  fullName,
  reportDate,
  reportId,
  applyingZip,
}: EmptyBackgroundInfoProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      {page === BackgroundDataTypes.CRIMINAL && (
        <>
          <div>
            <div className={styles.WarningContainer}>{t('creditReport.californiaReportWarning')}</div>
            <hr />
          </div>
          <div className={styles.DescriptionContainer}>{t('creditReport.calCivCode')}</div>
        </>
      )}
      <EmptyBackgroundInfo
        dateOfBirth={dateOfBirth}
        applyingZip={applyingZip}
        reportDate={reportDate}
        reportId={reportId}
        fullName={fullName}
        page={page}
      />
    </div>
  );
};
