import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { renterVehicles } from 'redux/selectors/renterSelector';

import { ReactComponent as CarIcon } from 'assets/svgs/CarIconDark.svg';
import styles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import Title from 'components/Renter/IdentityVerification/RenterDetails/shared/Title/Title';
import VehicleDescriptionCard from 'components/shared/VehicleDescriptionCard/VehicleDescriptionCard';
import { RenterVehicleSteps, VehicleTypes } from 'constants/renterConstants';
import { closePopOverOnClick } from 'helpers/agentHelper';
import { pluralize } from 'helpers/user';
import { renterRoutes } from 'shared/routes';
import { VehiclesInformationType } from 'shared/types/renterVehicleInformation';

import VehicleInformationTooltip from '../VehicleInformationTooltip/VehicleInformationTooltip';
interface VehicleInformationProps {
  subtype: string;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setFormValues: Dispatch<SetStateAction<VehiclesInformationType>>;
  next?: string;
}
const VehiclesCard = ({ subtype, setIsModalOpen, setFormValues, next }: VehicleInformationProps): JSX.Element => {
  const { t } = useTranslation();
  const vehicles = useSelector(renterVehicles);
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const navigate = useNavigate();
  const handleAddInfo = useCallback((): void => {
    navigate(renterRoutes.generateVehiclesInformation(currentApplicationId, subtype, RenterVehicleSteps.TYPE, next));
  }, [currentApplicationId, navigate, next, subtype]);
  const handleEditInfo = useCallback(
    (selectedId: VehiclesInformationType): void => {
      closePopOverOnClick();
      setFormValues(selectedId);
      setIsModalOpen(true);
    },
    [setFormValues, setIsModalOpen]
  );
  const handleSubType = useMemo((): string => {
    if (subtype === VehicleTypes.CAR) {
      return t('renter.vehiclesInformation.vehicle.cars');
    }

    return t('renter.vehiclesInformation.vehicle.motorbike');
  }, [subtype, t]);
  const filteredInformation = useMemo(
    () => vehicles?.filter((vehicle) => vehicle?.vehicleType?.toLowerCase() === subtype),
    [subtype, vehicles]
  );

  if (!filteredInformation?.length) {
    return <div />;
  }

  return (
    <div id={subtype}>
      <Title
        mainTitle={pluralize(filteredInformation?.length ?? 0, handleSubType, 's')}
        iconElement={<CarIcon />}
        shouldShowOptions
        optionElement={
          <VehicleInformationTooltip
            handleAddInfo={handleAddInfo}
            handleEditInfo={handleEditInfo}
            vehicleInformation={filteredInformation}
            domId={subtype}
          />
        }
      />
      {filteredInformation?.map((vehicle, index) => (
        <div className={classNames(styles.borderContainer, styles.titleMargin)} key={`${vehicle.vehicleType}-${index}`}>
          <VehicleDescriptionCard subtype={handleSubType} vehicle={vehicle} customKey={index + 1} />
        </div>
      ))}
    </div>
  );
};

export default VehiclesCard;
