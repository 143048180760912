import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { applicantRenterRole, renterPriorEmployments } from 'redux/selectors/renterSelector';

import Button from 'components/shared/Button/Button';
import { renterRestrictions } from 'constants/restrictionConstants';
import { renterRoutes } from 'shared/routes';
import { PresentSourceOfIncomeTypes, TypeOfPriorSourceOfIncomeProps } from 'shared/types/renterEmploymentInformation';

import styles from '../RenterPresentSourceOfIncome.module.scss';

const TypeOfPresentSourceOfIncome = ({ handleTypeOfEmployment }: TypeOfPriorSourceOfIncomeProps): JSX.Element => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const renterRole = useSelector(applicantRenterRole);
  const navigate = useNavigate();
  const priorEmployments = useSelector(renterPriorEmployments);

  useEffect(() => {
    if (renterRestrictions[renterRole].presentIncome) {
      if (!priorEmployments?.length) {
        navigate(renterRoutes.generateRenterPriorSourceOfIncome(applicationId));
      } else {
        navigate(renterRoutes.generateRenterPriorSourceOfIncomeSummary(applicationId));
      }
    }
  }, [applicationId, navigate, priorEmployments, renterRole]);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>{t('renter.property.sourceOfIncome')}</div>
      <div className={styles.buttonContainer}>
        <Button
          variant="outline"
          onClick={() => handleTypeOfEmployment(PresentSourceOfIncomeTypes.EMPLOYED)}
          className={styles.buttonPrimary}
        >
          {t('renter.sourceOfIncome.employed')}
        </Button>

        <Button
          variant="outline"
          onClick={() => handleTypeOfEmployment(PresentSourceOfIncomeTypes.SELF_EMPLOYED)}
          className={styles.buttonPrimary}
        >
          {t('renter.sourceOfIncome.selfEmployed')}
        </Button>

        <Button
          variant="outline"
          onClick={() => handleTypeOfEmployment(PresentSourceOfIncomeTypes.OTHER)}
          className={styles.buttonPrimary}
        >
          {t('renter.sourceOfIncome.other')}
        </Button>
      </div>
    </div>
  );
};

export default TypeOfPresentSourceOfIncome;
