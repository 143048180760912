import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as DropDownArrow } from 'assets/svgs/dropDownFillArrow.svg';

import styles from './PropertyInterestedTab.module.scss';

interface Props {
  onDropDownClick: () => void;
  totalActive: number;
  isInvited?: boolean;
  totalHouseHolds?: number;
  isGeneralInvite?: boolean;
}

export const PropertyInterestedTab = ({
  onDropDownClick,
  totalActive,
  isInvited,
  totalHouseHolds,
  isGeneralInvite = false,
}: Props): JSX.Element => {
  const [isShowingFullInformation, setIsShowingFullInformation] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.InhabitantsInformation, { [styles.UnsetTopBorder]: !isGeneralInvite })}>
      <DropDownArrow
        className={classNames(styles.DropDownIcon, { [styles.ShowInformation]: isShowingFullInformation })}
        onClick={() => {
          setIsShowingFullInformation((isShowing) => !isShowing);
          onDropDownClick();
        }}
      />
      <div className={styles.InhabitantsStatus}>
        <div className={styles.ActiveStatus}>
          <div className={styles.ActiveValue}>{totalActive}</div>
          <div className={styles.ActiveLabel}>{t(isInvited ? 'agent.invited' : 'agent.Apps')}</div>
        </div>
        <div className={styles.HouseholdStatus}>
          {totalHouseHolds && <div className={styles.HouseholdValue}>{totalHouseHolds}</div>}
          <div className={styles.HouseholdLabel}>{t('application.propertyHeader.households')}</div>
        </div>
      </div>
      {isGeneralInvite && (
        <div className={styles.NoPropertyRow}>
          <p>{t('application.property.noPropertySelected')}</p>
        </div>
      )}
    </div>
  );
};
