import { loadStripe } from '@stripe/stripe-js';
import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { setServerError } from 'redux/slices/authSlice';

import { RenterAddressTypes } from 'constants/renterConstants';
import { createStripeKey } from 'services/stripeService';
import { StatusCodes } from 'shared/constants';
import { Notification } from 'shared/Notification/Notification';
import { AddressInformation, RenterProfileProps } from 'shared/types/renterTypes';
import {
  IntializeStripeType,
  StripeAddress,
  StripeButtonProps,
  StripeDateOfBirth,
  StripeErrorCode,
  StripeInformation,
} from 'shared/types/stripeType';

import { parseResponseErrors } from './helper';

const stripeKey = process.env.REACT_APP_STRIPE_APP_KEY || '';
const stripePromise = loadStripe(stripeKey);

export const intializeStripeObject = ({ setStripe }: IntializeStripeType): void => {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const loadStripeObject = async () => {
    setStripe(await stripePromise);
  };

  loadStripeObject();
};

export const handleStripeButtonClick = ({
  handleLoading,
  stripe,
  successCallBack,
  dispatch,
  propertyId,
  organizationId,
}: StripeButtonProps): void => {
  handleLoading(true);
  createStripeKey({ organizationId: organizationId, propertyId: propertyId })
    .then(async (res: AxiosResponse) => {
      const secretKey = res.data.payload.clientSecret;

      if (secretKey) {
        const response = await stripe.verifyIdentity(secretKey);

        if (response.error) {
          if (response.error.code === StripeErrorCode.CONSENT_DECLINED) {
            Notification({ message: t('renter.PhotoIdInformation.concentDeclinded') });
          } else if (response.error.code === StripeErrorCode.SESSION_CANCELLED) {
            Notification({ message: t('renter.PhotoIdInformation.sessionDeclinded') });
          }
        } else {
          successCallBack && successCallBack();
        }

        handleLoading(false);
      }
    })
    .catch((error) => {
      if (error instanceof AxiosError && error.response) {
        dispatch(setServerError(error?.response?.status));

        if (
          error.response?.status === StatusCodes.NOT_FOUND ||
          error.response?.status === StatusCodes.INTERNAL_SERVER_ERROR
        ) {
          return;
        }
      }

      Notification({ message: parseResponseErrors(error) });
    })
    .finally(() => {
      handleLoading(false);
    });
};

export const formatStripeAddress = (
  address: StripeAddress,
  addresses: AddressInformation[] | undefined
): AddressInformation => {
  const data: AddressInformation = {};
  const prevAddress = addresses?.find((obj) => obj.addressType === RenterAddressTypes.PRESENT);

  data.city = address?.city;
  data.state = address?.state;

  data.streetAddress = address?.line1;

  if (address?.line2) {
    if (address.line2?.includes('Apt')) {
      data.apartmentNumber = address.line2;
    } else {
      data.streetAddress += ` ${address?.line2}`;
    }
  }

  data.addressType = RenterAddressTypes.PRESENT;
  data.zipCode = address?.postalCode;
  data.id = prevAddress?.id;

  return data;
};

export const formatUserInformation = (stripeInfo: StripeInformation, date: StripeDateOfBirth): RenterProfileProps => {
  const data: RenterProfileProps = {};
  const { year, month, day } = date;
  const formattedMonth = month.toString().padStart(2, '0');
  const formattedDay = day.toString().padStart(2, '0');

  if (stripeInfo.firstName) {
    const [firstName, ...middleName] = stripeInfo.firstName?.split(' ');

    data.firstName = firstName;
    data.middleName = middleName.join(' ') || null;
  }

  data.lastName = stripeInfo.lastName;
  data.birthDate = `${year}-${formattedMonth}-${formattedDay}`;

  return data;
};
