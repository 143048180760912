import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Field, Form, useFormikContext } from 'formik';
import { isSaving } from 'redux/selectors/renterSelector';

import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import FormikField from 'components/shared/TextField/FormikTextField';
import { EmploymentStatusType } from 'constants/renterConstants';
import { EmploymentStatus } from 'shared/types/renterEmploymentInformation';
import { RenterEmploymentsInitialValue } from 'shared/types/renterEmploymentInformation';
import { CustomEditModalProps } from 'shared/types/renterTypes';

import indexStyles from '../../../RenterIndex.module.scss';
import renterEmployStyles from './RenterPresentForm.module.scss';

const RenterEmployedTypeOther = ({ heading }: CustomEditModalProps): JSX.Element => {
  const { t } = useTranslation();
  const isSavingInformation = useSelector(isSaving);
  const { values, setFieldValue, dirty: isDirty, isValid } = useFormikContext<typeof RenterEmploymentsInitialValue>();
  const isButtonDisabled = useMemo(
    (): boolean => (!isValid || !isDirty || isSavingInformation ? true : false),
    [isValid, isSavingInformation, isDirty]
  );
  const onChangeSelected = useCallback(
    (selectedStatus: EmploymentStatus): void => {
      setFieldValue('status', selectedStatus.name);
    },
    [setFieldValue]
  );

  return (
    <section className={renterEmployStyles.SectionContainer}>
      <Form>
        <div className={renterEmployStyles.container}>
          <div
            className={classNames(renterEmployStyles.linkedinHeading, heading ? renterEmployStyles.centeralized : '')}
          >
            {heading ? (
              <span>{heading}</span>
            ) : (
              <span>
                {t('renter.customInformation.heading1')}
                <span className={renterEmployStyles.textContainer}> {t('renter.sourceOfIncome.other')} </span>
                {t('renter.customInformation.heading2')}
              </span>
            )}
          </div>

          <div className={renterEmployStyles.userFields}>
            <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.type')}</span>
            <div className={renterEmployStyles.customSelect}>
              <Field
                name="status"
                as={ReactSelect}
                options={EmploymentStatusType}
                onChange={(selectedStatus: EmploymentStatus) => onChangeSelected(selectedStatus)}
                value={EmploymentStatusType.find((val) => val.name === values?.status)}
                isSearchable={false}
                placeholder={t('renter.sourceOfIncome.studentRetiredHomemaker')}
                backgroundColor="#f7fafa"
              />
            </div>
          </div>

          <div className={renterEmployStyles.userFields}>
            <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.description')}</span>
            <FormikField
              className={renterEmployStyles.textField}
              name="jobDescription"
              placeholder={t('renter.sourceOfIncome.jobDescription')}
              // isTextArea="textarea"
            />
          </div>

          <div className={renterEmployStyles.userFields}>
            <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.monthlyIncome')}</span>
            {!!values?.monthlyGrossIncome && <span className={renterEmployStyles.userGrossDollar}>$</span>}
            <FormikField
              className={classNames(renterEmployStyles.inputBackgroundColor, {
                [renterEmployStyles.grossIncome]: !!values?.monthlyGrossIncome,
              })}
              name="monthlyGrossIncome"
              placeholder="$ 00,000.00"
              onWheel={(event) => event.currentTarget.blur()}
            />
          </div>

          <div className={classNames(indexStyles.renterButtonContainer)}>
            <RCButton
              className={renterEmployStyles.continueButton}
              variant="outline"
              type="submit"
              disabled={isButtonDisabled}
            >
              {t('renter.ConfirmAddressHistory.looksGood')}
            </RCButton>
          </div>
        </div>
        <FormCloseWarning isDirty={isDirty} />
      </Form>
    </section>
  );
};

export default RenterEmployedTypeOther;
