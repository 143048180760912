import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import styles from 'components/Renter/IdentityVerification/RenterDetails/shared/Popover.module.scss';
import { closePopOverOnClick } from 'helpers/agentHelper';
import { AddressInformation } from 'shared/types/renterTypes';

type AdultTooltipProps = {
  handleAddAddress: () => void;
  addressInformation: AddressInformation[] | undefined;
};
const PriorAddressToolTip = ({ handleAddAddress, addressInformation }: AdultTooltipProps): JSX.Element => {
  const { t } = useTranslation();
  const renderTooltip = (
    <Popover id="popover-positioned-bottom" hasDoneInitialMeasure>
      <Popover.Body className={styles.popoverBody}>
        <div className={styles.popoverWrapper}>
          <button
            className={styles.popoverBtn}
            onClick={() => {
              handleAddAddress();
              closePopOverOnClick();
            }}
          >
            {addressInformation?.length
              ? t('renter.ConfirmAddressHistory.addAnotherPriorAddress')
              : t('renter.ConfirmAddressHistory.addPriorAddress')}
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose trigger="click" key="bottom" placement="bottom" overlay={renderTooltip}>
      <span className={styles.overlayTrigger}>
        <ThreeDotsIcon />
      </span>
    </OverlayTrigger>
  );
};

export default PriorAddressToolTip;
