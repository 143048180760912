import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { renterPresentEmployments } from 'redux/selectors/renterSelector';

import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import styles from 'components/Renter/IdentityVerification/RenterDetails/shared/Popover.module.scss';
import { scrollToViewOfGivenId } from 'helpers/agentHelper';

type PresentIncomeTooltipProps = {
  handleEditInfo: (index: number, employmentId?: number) => void;
  handleAddInfo: () => void;
};
const PresentIncomeTooltip = ({ handleAddInfo, handleEditInfo }: PresentIncomeTooltipProps): JSX.Element => {
  const { t } = useTranslation();
  const presentEmployments = useSelector(renterPresentEmployments);
  const presentEmploymentsCount = presentEmployments?.length ?? 0;
  const renderTooltip = (
    <Popover id="popover-positioned-bottom" hasDoneInitialMeasure>
      <Popover.Body className={styles.popoverBody}>
        <div className={styles.popoverWrapper}>
          {presentEmployments &&
            presentEmployments.map((employment, index) => (
              <button
                className={styles.popoverBtn}
                onClick={() => handleEditInfo(index + 1, employment.id)}
                key={`edit-present-income-${index}`}
              >
                {t('renter.paymentConfirmation.sourceOfIncome.editSource')}
                {presentEmploymentsCount === 1 ? '' : index + 1}
              </button>
            ))}
          <button className={styles.popoverBtn} onClick={handleAddInfo}>
            {presentEmploymentsCount
              ? t('renter.paymentConfirmation.sourceOfIncome.addAnother')
              : t('renter.paymentConfirmation.sourceOfIncome.addPresent')}
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      key="bottom"
      placement="bottom"
      overlay={renderTooltip}
      onToggle={() => scrollToViewOfGivenId('present-income')}
    >
      <span className={styles.overlayTrigger}>
        <ThreeDotsIcon />
      </span>
    </OverlayTrigger>
  );
};

export default PresentIncomeTooltip;
