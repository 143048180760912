import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { addProperty, updatePropertyDetails } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';
import { PropertyFormSchema } from 'schema/propertySchema';

import { ReactComponent as MapPinSvg } from 'assets/svgs/MapPinOutline.svg';
import PropertyBasicInformation from 'components/Agent/Properties/AddProperty/BasicPropertyInformation';
import RCButton from 'components/shared/Button/Button';
import { dateFormatBackend } from 'constants/calendarConstants';
import { AddPropertyParams, PropertyAddressProps } from 'shared/types/propertyType';

import { AvailableTag } from './AvailableTag';
import { PropertyEditModal } from './PropertyEditModal';

import styles from './../PropertyDetails.module.scss';

const PropertyAddress = ({
  addressObject,
  dotted = false,
  propertyType,
  dateAvailable,
  totalUnits,
  name,
}: PropertyAddressProps): JSX.Element => {
  const { t } = useTranslation();
  const activeOrganization = useSelector(selectedOrganization);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isError, setIsError] = useState(true);
  const { propertyId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const INITIAL_VALUES: AddPropertyParams = {
    propertyId: Number(propertyId),
    propertyType,
    dateAvailable: dateAvailable && new Date(dateAvailable),
    totalUnits,
    name,
    ...addressObject,
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={PropertyFormSchema[0]}
      enableReinitialize
      onSubmit={(values, { resetForm, setSubmitting }) => {
        const payload = {
          ...values,
          dateAvailable: values.dateAvailable && format(new Date(values.dateAvailable), dateFormatBackend),
        };

        dispatch(addProperty({ values: payload, organizationId: activeOrganization.id }))
          .unwrap()
          .then((updatedProperty) => {
            setIsModalVisible(false);
            resetForm();
            setSubmitting(false);
            dispatch(updatePropertyDetails(updatedProperty));
          });
      }}
    >
      {({ isValid, submitForm, isSubmitting }) => (
        <Form className="col-lg-6 col-md-12 px-0 mt-3 mb-5">
          <div className={styles.AddressHeader}>
            <div className={styles.PropertyType}>{propertyType}</div>
            <AvailableTag dateAvailable={dateAvailable?.toLocaleString() ?? ''} />
            <div className={styles.EditAddressButton}>
              <RCButton className={styles.EditButton} variant="outline" onClick={() => setIsModalVisible(true)}>
                {t('application.edit')}
              </RCButton>
            </div>
          </div>
          <div className={classNames(styles.PropertyAddress, { [styles.EllipsisText]: dotted })}>
            <MapPinSvg />
            {addressObject?.streetAddress1
              ? addressObject?.streetAddress1.split(',').slice(0, 2).join(',').trim()
              : 'Some property address'}

            {totalUnits ? ', ' + totalUnits : ''}
          </div>
          <div
            className={styles.PropertyAddress2}
          >{`${addressObject?.city}, ${addressObject?.state} ${addressObject?.zipCode}`}</div>
          <PropertyEditModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            isValid={isValid}
            title={t('agent.properties.property.address.edit')}
            submitForm={submitForm}
            isSubmitting={isSubmitting}
            content={<PropertyBasicInformation isError={isError} setIsError={setIsError} isPropertyEdit={true} />}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PropertyAddress;
