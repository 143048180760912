// import { Link } from 'react-router-dom';

import styles from './../PropertyIndex.module.scss';

export interface PropertyOwnerProps {
  propertyOwner?: string;
}

const PropertyOwner = ({ propertyOwner = '-' }: PropertyOwnerProps): JSX.Element => (
  <div className={styles.orgOwner}>
    <span className={styles.propertyLinks}>
      <span className={styles.propertyText}> {propertyOwner}</span>
    </span>
  </div>
);

export default PropertyOwner;
