import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikErrors } from 'formik';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { isSaving, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterPhoneNumbers } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as InfoIcon } from 'assets/svgs/InfoIcon.svg';
import Button from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import TextField from 'components/shared/TextField/TextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { PhoneNumberType } from 'constants/renterConstants';
import { parseResponseErrors } from 'helpers/helper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { Notification } from 'shared/Notification/Notification';
import { PhoneNumber } from 'shared/types/renterTypes';

import RenterModal from '../RenterModal/RenterModal';

import styles from './PersonalProfileEditModal.module.scss';

interface InitialValuesProps {
  phoneNumbers: PhoneNumber[];
}
type EditPhoneNumberModalProps = {
  onHide: () => void;
  shouldShowModal: boolean;
};

export const PersonalProfileEditModal = ({ onHide, shouldShowModal }: EditPhoneNumberModalProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const renterDetails = useSelector(selectRenterProfileInformation);
  const isSavingInformation = useSelector(isSaving);
  const [initialValues, setInitialValues] = useState<InitialValuesProps>({} as InitialValuesProps);
  const maxHeight = useDeviceHeight(320);
  const handleSubmit = useCallback(
    // eslint-disable-next-line no-unused-vars
    async (values: PhoneNumber[], setErrors: (errors: FormikErrors<InitialValuesProps>) => void) => {
      for (const singlePhoneNumber of values) {
        const { phoneNumber } = singlePhoneNumber;
        const formattedPhoneNumber = phoneNumber?.includes('+') ? phoneNumber : '+' + phoneNumber;

        try {
          await dispatch(
            addRenterPhoneNumbers({ values: { ...singlePhoneNumber, phoneNumber: formattedPhoneNumber } })
          ).unwrap();
          onHide();
        } catch (error) {
          Notification({ message: parseResponseErrors(error) });
        }
      }
    },
    [dispatch, onHide]
  );

  useEffect(() => {
    if (!isEmpty(renterDetails?.renterPhoneNumbers) && !!renterDetails?.email) {
      setInitialValues({ phoneNumbers: renterDetails?.renterPhoneNumbers ?? [] });
    }
  }, [renterDetails?.email, renterDetails?.renterPhoneNumbers]);

  return (
    <Formik
      onSubmit={(values, { setErrors }) => handleSubmit(values.phoneNumbers, setErrors)}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ dirty: isDirty, values, setFieldValue, isValid, errors }) => (
        <RenterModal show={shouldShowModal} onHide={onHide} isDirty={isDirty}>
          <div className={styles.phoneContainer}>
            <div className={styles.heading}>{t('renter.PhotoIdInformation.editDetails')}</div>
            <div className={styles.subHeading}>
              {t('renter.PhotoIdInformation.makeChanges')}&nbsp;
              <span className={styles.spanColor}>{t('application.contact')}</span>&nbsp;
              {t('renter.PhotoIdInformation.information')}
            </div>
            <Form style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
              <FormCloseWarning isDirty={isDirty} />
              <TextField
                value={renterDetails.email}
                placeholder="Email Address"
                label="Email"
                contentEditable="false"
                customIcon={<Tooltip icon={<InfoIcon />} text={t('renter.profileSummary.emailIsNotEditable')} />}
              />
              {values?.phoneNumbers?.map((phoneNumber, index) => (
                <React.Fragment key={`edit-phonenumber-${phoneNumber.id}`}>
                  <div className={styles.phoneNumberSelectConatiner}>
                    <ReactSelect
                      options={PhoneNumberType.filter(
                        (type) => !values?.phoneNumbers?.map((phone) => phone.type).includes(type.label)
                      )}
                      value={PhoneNumberType.find((val) => val.name === phoneNumber.type)}
                      placeholder={t('renter.phone.selectNumberType')}
                      onChange={(option) => {
                        if (option?.label) {
                          setFieldValue(`phoneNumbers[${index}].type`, option.label);
                        }
                      }}
                      blurInputOnSelect
                      isSearchable={false}
                      isDisabled={isSavingInformation}
                    />
                  </div>
                  {phoneNumber.type && (
                    <FormikPhoneNumber
                      name={`phoneNumbers[${index}].phoneNumber`}
                      isDisabled={isSavingInformation}
                      label={phoneNumber.type}
                      isCountryCodeDisabled={false}
                      isCountryCodeEditable={false}
                      onlyCountry={[]}
                      isTouchedOnChange
                      placeholder={t('renter.phone.placeHolder')}
                    />
                  )}
                </React.Fragment>
              ))}
              <div className={styles.renterButtonContainer}>
                <Button
                  className={styles.renterButton}
                  variant="outline"
                  disabled={!isEmpty(errors) || !isDirty || !isValid || isSavingInformation}
                  type="submit"
                >
                  {t('renter.PhotoIdInformation.savechanges')}
                </Button>
              </div>
            </Form>
          </div>
        </RenterModal>
      )}
    </Formik>
  );
};
