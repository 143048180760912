import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckIconSuccess } from 'assets/svgs/CheckIconSuccess.svg';
import { ReactComponent as CrossIcon } from 'assets/svgs/CrossIcon.svg';
import { passwordGuidelines } from 'shared/utils/passwordGuidelines';

import styles from './PasswordGuidelines.module.scss';

interface Props {
  password: string;
}

export const PasswordGuidelines = ({ password }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Guidelines}>
      {passwordGuidelines(password).map((guideline) => (
        <div key={`guidline-${guideline}`} className={styles.GuidelineRow}>
          {guideline.condition ? <CheckIconSuccess className={styles.Cross} /> : <CrossIcon className={styles.Cross} />}
          <div>{t(guideline.text)}</div>
        </div>
      ))}
    </div>
  );
};
