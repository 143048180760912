import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { FieldArray, Form, Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { AppThunkDispatch } from 'redux/store';
import { AddressHistoryQuestionnaireSchema } from 'schema/publicSchema';

import { ReactComponent as QuestionnaireIcon } from 'assets/svgs/NotListedLocation.svg';
import { RequestVerificationWrapper } from 'components/Agent/components/shared/RequestVerificationWrapper';
import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';
import { RentalHistoryQuestionnaire, RentalHistoryTitle } from 'components/Public/RentalHistoryInformation';
import { VerificationSupportingDocuments } from 'components/Public/RentalHistoryInformation/VerificationSupportingDocuments/VerificationSupportingDocuments';
import Button from 'components/shared/Button/Button';
import ErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import { CustomPreviewFile } from 'components/shared/DropZone/CustomDropzone/CustomDropzone';
import { fetchVerifierQuestion, handleVerifierSubmission } from 'helpers/publicHelper';
import { VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { FormValues, RentalHistoryQuestionnaireType } from 'shared/types/renterTypes';

import { EmploymentHistoryProfile } from '../EmploymentHistoryProfile/EmploymentHistoryProfile';
import { EmploymentSubmitForm } from '../EmploymentSubmitForm/EmploymentSubmitForm';

const INITIAL_DEFAULT = {
  verifierType: '',
  submitVerifier: {
    phoneNumber: '',
    fullName: '',
    emailAddress: '',
    companyName: '',
    relationshipProperty: '',
    privacyConsent: false,
    titleOfVerifier: '',
  },
  verificationQuestions: [],
};

export const EmploymentHistory = (): JSX.Element => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const [acceptedFiles, setAcceptedFiles] = useState<CustomPreviewFile[]>([]);
  const [requestedAt, setRequestedAt] = useState('');
  const [questionnaire, setQuestionnaire] = useState<FormValues>(INITIAL_DEFAULT);
  const dispatch = useDispatch<AppThunkDispatch>();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token') as string;
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    fetchVerifierQuestion({ token: token, setQuestionnaire, dispatch, setIsSubmitted });
  }, [dispatch, token]);

  return (
    <RequestVerificationWrapper
      isSubmitted={isSubmitted}
      requestedAt={requestedAt}
      infoDescription={t('rental.historyInformation.infoPanel')}
      type={VerifierURLType.VOE}
    >
      <Formik
        initialValues={questionnaire}
        onSubmit={(data) => handleVerifierSubmission({ data, token, dispatch, acceptedFiles, setIsSubmitted })}
        enableReinitialize
        validationSchema={AddressHistoryQuestionnaireSchema}
        validateOnBlur={false}
      >
        {({ values, errors, isValid }) => (
          <Form>
            <RentalHistoryTitle
              title={t('employment.historyVerification.title')}
              infoDescription="employment.historyVerification.description"
            />
            <div className={styles.RentalSubContainer}>
              <EmploymentHistoryProfile setRequestedAt={setRequestedAt} />
              <hr className={styles.HorizantalLine} />
              <section className={styles.RentalQuestionSection}>
                <div className={styles.RentalQuestionSection}>
                  <div className={classNames(styles.RentalTitle, styles.SpaceContainer)}>
                    <QuestionnaireIcon /> {t('rental.historyInformation.requestedInformation')}
                  </div>
                  <span className={styles.RentalQuestionnaireDescription}>
                    {t('employment.historyVerification.questionaireExplaination')}
                  </span>
                  <div
                    className={classNames(styles.RentalQuestionnaireContainer, styles.EmploymentQuestionnaireContainer)}
                  >
                    {questionnaire && (
                      <FieldArray name="verificationQuestions">
                        {() => (
                          <div>
                            {!isEmpty(values?.verificationQuestions) &&
                              values?.verificationQuestions?.map(
                                (question: RentalHistoryQuestionnaireType, index: number) => (
                                  <div key={`verification-question-${index}`}>
                                    <RentalHistoryQuestionnaire questionnaire={question} index={index} />
                                  </div>
                                )
                              )}
                          </div>
                        )}
                      </FieldArray>
                    )}
                  </div>
                </div>
                <div className={styles.ErrorContainer}>
                  {isError && errors && !isEmpty(errors.verificationQuestions) && (
                    <ErrorMessage message={t('employment.historyVerification.fillQuestionaireError')} />
                  )}
                </div>
              </section>
              <hr className={styles.HorizantalLine} />
              <VerificationSupportingDocuments acceptedFiles={acceptedFiles} setAcceptedFiles={setAcceptedFiles} />
              <hr className={styles.HorizantalLine} />
              <EmploymentSubmitForm
                heading="rental.historyInformation.completedBy"
                formConsent="rental.historyInformation.formConsent"
                formConsentOffer="rental.historyInformation.formConsentOffer"
                isHeadingOptional={false}
              />
            </div>
            <div role="button" className={styles.FormButtonContainer} onClickCapture={() => setIsError(true)}>
              <Button
                className={styles.renterButton}
                type="submit"
                disabled={!isEmpty(errors?.verificationQuestions) || !isValid}
              >
                {t('renter.PhotoIdInformation.submit')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </RequestVerificationWrapper>
  );
};
