import { PaginationType } from 'shared/types/paginationTypes';
import { PropertyProps, Tag, UpdatePropertyType } from 'shared/types/propertyType';
import { ISelectObject } from 'shared/types/selectObject';

import { RootState } from '../types';

export const isLoading = (state: RootState): boolean => state.propertySlice.isLoading;
export const isSaving = (state: RootState): boolean => state.propertySlice.isSaving;
export const selectPropertyId = (state: RootState): number => state.propertySlice.propertyId;
export const propertyTypes = (state: RootState): ISelectObject =>
  state.propertySlice.propertyExternalAttributes.propertyTypes?.map((el: string) => ({
    value: el,
    label: el,
  }));
export const propertyParkingOption = (state: RootState): ISelectObject =>
  state.propertySlice.propertyExternalAttributes.propertyParkingOptions?.map((el: string) => ({
    value: el,
    label: el,
  }));

export const propertyLeaseDurations = (state: RootState): ISelectObject =>
  state.propertySlice.propertyExternalAttributes.propertyLeaseDurations?.map((el: string) => ({
    value: el,
    label: el,
  }));

export const propertyLaundryAmenities = (state: RootState): ISelectObject =>
  state.propertySlice.propertyExternalAttributes.propertyLaundryAmenities?.map((el: string) => ({
    value: el,
    label: el,
  }));

export const propertyUtilities = (state: RootState): ISelectObject =>
  state.propertySlice.propertyExternalAttributes.propertyUtilities?.map((el: string) => ({
    value: el,
    label: el,
  }));

export const propertyAmenitiesFeatureInitial = (state: RootState): Tag[] => [
  ...state.propertySlice.propertyExternalAttributes.unitAmenities.map((el) => ({
    name: el,
    isSelected: false,
    type: 'unit',
    isAnimation: false,
    tempSelected: false,
  })),
  ...state.propertySlice.propertyExternalAttributes.propertyAmenities.map((el) => ({
    name: el,
    isSelected: false,
    type: 'property',
    isAnimation: false,
    tempSelected: false,
  })),
];

export const amenitiesList = (state: RootState): Tag[] => state.propertySlice.amenitiesFeature;
export const allPropertiesList = (state: RootState): PropertyProps[] => state.propertySlice.properties;
export const selectAllPropertiesLoading = ({ propertySlice }: RootState): boolean => propertySlice.isLoading;
export const selectApplicationProperties = (state: RootState): PropertyProps[] =>
  state.propertySlice.applicationProperties;
export const selectProperty = (state: RootState): UpdatePropertyType => state.propertySlice.selectedProperty;
export const amenitiesFeatureUnitList = (state: RootState): Tag[] =>
  state.propertySlice.amenitiesFeature.filter((el) => el.type === 'unit');
export const amenitiesFeaturePropertyList = (state: RootState): Tag[] =>
  state.propertySlice.amenitiesFeature.filter((el) => el.type === 'property');
export const amenitiesFeatureList = (state: RootState): Tag[] => state.propertySlice.amenitiesFeature;
export const externalAmenitiesUnitList = (state: RootState): string[] =>
  state.propertySlice.propertyExternalAttributes.unitAmenities;
export const externalAmenitiesPropertyList = (state: RootState): string[] =>
  state.propertySlice.propertyExternalAttributes.propertyAmenities;
export const paginatedValues = (
  state: RootState,
  propertyType?: string // Add the propertyType parameter
): {
  properties: PropertyProps[];
  pageInfo: PaginationType;
} => {
  // Get all properties from the state
  let filteredProperties = state.propertySlice.properties;

  // If propertyType is provided, filter properties by propertyType
  if (propertyType && filteredProperties) {
    filteredProperties = filteredProperties.filter((property) => property.propertyStatus === propertyType);
  }

  // Return the filtered properties and pageInfo
  return {
    properties: filteredProperties,
    pageInfo: state.propertySlice.pageInfo,
  };
};
