import axios, { AxiosResponse } from 'axios';
import {
  OrderPropertyImageInterface,
  PropertyDeleteImageInterface,
  PropertyUploadImageInterface,
} from 'redux/slices/propertySlice';

import { AcceptInvitationResponse, InviteProps, InviteResponse } from 'shared/types/authType';
import { AxiosPromise } from 'shared/types/axiosPromise';
import { ApiUploadImage } from 'shared/types/imageUploadTypes';
import {
  AddPropertyParams,
  ApplicationPropertiesType,
  GetAllPropertyParams,
  GetPropertyExternalAttributesType,
  GetPropertyParams,
  GetPropertyValues,
  PropertyMarketingStatusRequestProps,
} from 'shared/types/propertyType';

import baseService from './baseService';
export const createProperty = (values: AddPropertyParams): AxiosPromise =>
  baseService.post<AxiosResponse<AddPropertyParams>>('/property/create-property', values);

export const updateProperty = (values: AddPropertyParams): AxiosPromise =>
  baseService.put<AxiosResponse<AddPropertyParams>>('/property/update-property', values);

export const getPropertyExternalAttributes = (): AxiosPromise =>
  baseService.get<AxiosResponse<GetPropertyExternalAttributesType>>('/property/property-external-attributes');

export const getProperties = (values: GetPropertyParams): AxiosPromise =>
  baseService.get<AxiosResponse<GetPropertyValues>>(`/property/view-property`, {
    params: values,
  });
export const getProperty = (values: { propertyId: number; organizationId: number }): AxiosPromise =>
  baseService.get<AxiosResponse<AddPropertyParams>>(`/property/view-property`, { params: values });
export const propertyImageUpload = ({ file, propertyId, organizationId }: PropertyUploadImageInterface): AxiosPromise =>
  baseService.post<AxiosResponse<ApiUploadImage>>(
    `/property/${propertyId}/organizations/${organizationId}/upload-image`,
    file,
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const getAllProperties = (values: GetAllPropertyParams): AxiosPromise =>
  baseService.get<AxiosResponse<GetPropertyValues>>(`/property/view-property`, {
    params: values,
  });

export const deletePropertyImage = ({ imageId, propertyId }: PropertyDeleteImageInterface): AxiosPromise =>
  baseService.delete<AxiosResponse<ApiUploadImage>>(`/property/${propertyId}/delete-image/${imageId}`);

export const orderPropertyImage = ({ imagesId, propertyId }: OrderPropertyImageInterface): AxiosPromise =>
  baseService.post<AxiosResponse<ApiUploadImage>>(`/property/${propertyId}/reorder-images`, {
    imageIds: imagesId,
  });

export const getPropertyGeneralInvitationDetails = (values: InviteProps): AxiosPromise =>
  baseService.get<AxiosResponse<InviteResponse>>('/property/general-invitation-details', {
    params: values,
  });

export const getPropertySpecificInvitationDetails = (values: InviteProps): AxiosPromise =>
  baseService.get<AxiosResponse<InviteResponse>>('/property/specific-invite', {
    params: values,
  });

export const acceptPropertySpecificInvitiation = (values: InviteProps): AxiosPromise =>
  baseService.post<AxiosResponse<AcceptInvitationResponse>>('/property/accept-specific-invitation', values);

export const acceptPropertyGeneralInvitiation = (values: InviteProps): AxiosPromise =>
  baseService.post<AxiosResponse<AcceptInvitationResponse>>('/property/accept-general-invitation', values);

export const getApplicationProperties = ({
  organizationId,
  applicationStatus,
}: ApplicationPropertiesType): AxiosPromise =>
  baseService.get<AxiosResponse<GetPropertyValues>>(
    `/property/properties-applications?status=${applicationStatus.toLowerCase()}`,
    {
      headers: {
        organizationId,
      },
    }
  );

export const getLocationTimezone = async ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): Promise<string | null> => {
  const timestamp = Math.floor(Date.now() / 1000);

  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude}%2C${longitude}&timestamp=${timestamp}&key=${process.env.REACT_APP_TIMEZONE_GOOGLE_MAP_API}`
    );

    if (response?.data?.status === 'OK') {
      return response.data.timeZoneId;
    } else {
      return null;
    }
  } catch {
    return null;
  }
};
export const updatePropertyMarketingStatus = ({
  organizationId,
  propertyId,
  marketingEnabled,
}: PropertyMarketingStatusRequestProps): AxiosPromise =>
  baseService.put<AxiosResponse<GetPropertyValues>>('/property/property-listing/properties', {
    organizationId,
    propertyId,
    marketingEnabled,
  });
