import { AxiosError } from 'axios';

import {
  PHONE_FORMAT_END_INDEX,
  PHONE_FORMAT_MIDDLE_INDEX,
  PHONE_FORMAT_START_INDEX,
} from 'constants/guarantorConstants';
import { renterGuarantorInvite } from 'services/renterService';
import { Notification } from 'shared/Notification/Notification';
import { InviteType } from 'shared/types/renterCooccupants';

import { parseResponseErrors } from './helper';

export const getFormatedPhoneNumber = (phoneNumber: string): string =>
  phoneNumber
    ? `(${phoneNumber.slice(PHONE_FORMAT_START_INDEX, PHONE_FORMAT_MIDDLE_INDEX)}) ${phoneNumber.slice(
        PHONE_FORMAT_MIDDLE_INDEX,
        PHONE_FORMAT_END_INDEX
      )}-${phoneNumber.slice(PHONE_FORMAT_END_INDEX)}`
    : '---';

export const sendGuarantorInvite = async (
  guarantorId: number,
  currentApplicationId: number,
  propertyId: number
): Promise<void> => {
  await renterGuarantorInvite({
    applicationId: currentApplicationId,
    propertyId: propertyId,
    inviteType: InviteType.EMAIL,
    guarantorId: guarantorId,
  }).catch((inviteError) => {
    if (inviteError instanceof AxiosError && inviteError.response) {
      Notification({ message: parseResponseErrors(inviteError) });
    }
  });
  await renterGuarantorInvite({
    applicationId: currentApplicationId,
    propertyId: propertyId,
    inviteType: InviteType.SMS,
    guarantorId: guarantorId,
  }).catch((inviteError) => {
    if (inviteError instanceof AxiosError && inviteError.response) {
      Notification({ message: parseResponseErrors(inviteError) });
    }
  });
};
