import { Document } from '@react-pdf/renderer';
import { isEmpty } from 'lodash-es';

import {
  AddressVerificationResponse,
  AgentProfileInformationProps,
  VerifierAddressObject,
  VerifierEmploymentObject,
} from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { OrganizationProps } from 'shared/types/organizationTypes';
import { AddPropertyParams } from 'shared/types/propertyType';
import { ApplicationAdditionalInfoType, RenterProfileInformationProps } from 'shared/types/renterTypes';
import { CriminalReportItem, EvictionReportItem, FinancialData } from 'shared/types/reportTypes';

import { generateAddressVerificationPDFComponents } from './v2/components/AddressVerificationPagesPDF/AddressVerificationPagesPDF';
import { generateEmploymentVerificationPDFComponents } from './v2/components/EmploymentVerificationPagesPDF/EmploymentVerificationPagesPDF';
import { AddressesPagePDF } from './v2/pages/AddressesPagePDF/AddressesPagePDF';
import { BackgroundQuestionsPagePDF } from './v2/pages/BackgroundQuestionsPagePDF/BackgroundQuestionsPagePDF';
import { IncomeVehicleAnimalPagePDF } from './v2/pages/IncomeVehicleAnimalPagePDF/IncomeVehicleAnimalPagePDF';
import { RenterDetailPagePDF } from './v2/pages/RenterDetailPagePDF/RenterDetailPagePDF';
import { TitlePagePDF } from './v2/pages/TitlePagePDF/TitlePagePDF';

interface Props {
  renterDetails: RenterProfileInformationProps;
  agentDetails: AgentProfileInformationProps;
  currentOrganization: OrganizationProps;
  application: ApplicationType;
  criminalReport: CriminalReportItem;
  housingRecordsInformation: EvictionReportItem;
  employmentVerificationInformation: AddressVerificationResponse[];
  addressVerificationInformation: AddressVerificationResponse[];
  incomeTransactions: FinancialData;
  propertyInformation: AddPropertyParams;
  additionalInfo: ApplicationAdditionalInfoType;
  verifierAddresses: VerifierAddressObject[];
  verifierEmployments: VerifierEmploymentObject[];
}

export const RenterResumeV2 = ({
  renterDetails,
  application,
  agentDetails,
  currentOrganization,
  addressVerificationInformation,
  criminalReport,
  employmentVerificationInformation,
  housingRecordsInformation,
  incomeTransactions,
  propertyInformation,
  additionalInfo,
  verifierAddresses,
  verifierEmployments,
}: Props): JSX.Element => {
  const addressIds = new Set(verifierAddresses.map((item) => item.id));
  const filteredAddresses = addressVerificationInformation.filter((address) =>
    addressIds.has(address.renterAddressInformation?.addressId ?? 0)
  );
  const employmentIds = new Set(verifierEmployments.map((item) => item.id));
  const filteredEmployments = employmentVerificationInformation.filter((employment) =>
    employmentIds.has(employment.renterEmploymentInformation?.presentEmploymentId ?? 0)
  );

  return (
    <Document>
      <TitlePagePDF
        renterDetails={renterDetails}
        agentDetails={agentDetails}
        currentOrganization={currentOrganization}
        application={application}
        propertyInformation={propertyInformation}
      />
      <RenterDetailPagePDF
        application={application}
        renterDetails={renterDetails}
        propertyInformation={propertyInformation}
        additionalInfo={additionalInfo}
        criminalReport={criminalReport}
        housingRecordsInformation={housingRecordsInformation}
      />
      <AddressesPagePDF
        propertyInformation={propertyInformation}
        renterDetails={renterDetails}
        addressVerificationInformation={addressVerificationInformation}
        application={application}
      />
      <IncomeVehicleAnimalPagePDF
        transactions={incomeTransactions}
        employmentVerificationInformation={employmentVerificationInformation}
        propertyInformation={propertyInformation}
        renterDetails={renterDetails}
        application={application}
      />
      <BackgroundQuestionsPagePDF
        propertyInformation={propertyInformation}
        renterDetails={renterDetails}
        application={application}
        addressVerificationInformation={addressVerificationInformation}
        employmentVerificationInformation={employmentVerificationInformation}
      />
      {generateAddressVerificationPDFComponents({
        addressVerificationResponse: filteredAddresses,
        renterDetails,
        isLastPage: isEmpty(filteredEmployments),
      })}
      {generateEmploymentVerificationPDFComponents({
        employmentVerificationInformation: filteredEmployments,
        renterDetails,
      })}
    </Document>
  );
};
