//TODO: Temporarily disabled SendRequestDropDown component feature and will re-enable later.
import { Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import defaultImage from 'assets/images/EmptyPropertyRow.png';
import emptyPropertyRow from 'assets/images/EmptyPropertyRowMobile.png';
import RCButton from 'components/shared/Button/Button';
import { IsMobile } from 'helpers/IsMobile';
import { ApplicantPageEmptyStateProps } from 'shared/types/agentTypes';

// import { SendRequestDropDown } from '../SendRequestDropDown/SendRequestDropDown';
import styles from './EmptyState.module.scss';

export const EmptyState = ({
  setIsModalOpen,
  // setSelectedInviteType,
  message = 'agent.applications.emptyState.prompt',
  image = defaultImage,
  width,
}: ApplicantPageEmptyStateProps): JSX.Element => {
  const { t } = useTranslation();
  const isMobileView = IsMobile();

  return (
    <section className={classNames(styles.EmptyStateContainer, { [styles.NoRecordFound]: !width })}>
      <div className={styles.Content}>
        <Col lg={width ? width : undefined}>
          <Row className={styles.ImageContainer}>
            <Image src={image} fluid className={styles.ImageClass} />
          </Row>
          <Row className={styles.ImageContainer}>
            <div className={styles.Title}>
              <Image src={image} fluid className={styles.ImageClass} />
              <h3 className={classNames(styles.EmptyStateText, { [styles.NoRecordFound]: !width })}>{t(message)}</h3>
            </div>
          </Row>
          <Row className={styles.ImageContainer}>
            <Image src={image} fluid className={styles.ImageClass} />
          </Row>
        </Col>
        <Col className={styles.MobileViewContainer}>
          <Image src={emptyPropertyRow} fluid className={styles.ImageClassOnMobileView} />
        </Col>
      </div>
      {setIsModalOpen && (
        // setSelectedInviteType &&
        <div className={styles.ButtonContainer}>
          {!isMobileView && (
            // <SendRequestDropDown
            //   handleModalOpen={() => setIsModalOpen(true)}
            //   setSelectedInviteType={setSelectedInviteType}
            // />
            <RCButton onClick={() => setIsModalOpen(true)}>Send Request</RCButton>
          )}
          <Col lg={4}>
            <p className={styles.HelpText}>
              <span className={styles.Description}>{t('agent.applications.emptyState.helpText')} &nbsp;</span>
            </p>
          </Col>
        </div>
      )}
    </section>
  );
};
