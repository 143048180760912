import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowIcon } from 'assets/svgs/GreyDropDownIcon.svg';
import { ApplicationOptionProps } from 'shared/types/renterTypes';

import RCButton from '../Button/Button';

import styles from './ApplicationOption.module.scss';

export const ApplicationOption = ({
  isNew = false,
  isPending = false,
  label,
  onClickHandler,
}: ApplicationOptionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <RCButton className={styles.Button} onClick={onClickHandler}>
        <div className={styles.Label}>{label}</div>
        <div className={styles.IconLabels}>
          {isNew && <div className={styles.NewLabel}>{t('renter.application.labels.new')}</div>}
          {isPending && <div className={styles.PendingLabel}>{t('renter.application.labels.pending')}</div>}
          <ArrowIcon className={styles.ArrowIcon} />
        </div>
      </RCButton>
    </div>
  );
};
