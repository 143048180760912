//TODO: Temporarily disabled some non-interactive features and will re-enable later.

import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { t } from 'i18next';
import { selectAllPropertiesLoading } from 'redux/selectors/propertySelector';

// import { ReactComponent as InfoIconFilled } from 'assets/svgs/infoIconFilled.svg';
import { ReactComponent as MapIcon } from 'assets/svgs/MapPinOutline.svg';
// import Tooltip from 'components/shared/Tooltip/Tooltip';
// import { ApplicationRequestValues } from 'constants/applicationConstants';
import { retrieveAgentInviteModalPreset } from 'helpers/agentHelper';
import { PropertyTypeProps } from 'shared/types/agentTypes';

import PropertySelect from './PropertySelect/PropertySelect';

import styles from './PropertyType.module.scss';

const PropertyType = ({
  publishedList,
  onChangeHandler,
  // tooltipText,
  inviteType,
  propertyAddress,
}: PropertyTypeProps): JSX.Element => {
  const isLoading = useSelector(selectAllPropertiesLoading);

  return (
    <div className={styles.container}>
      {propertyAddress && (
        <div className={classNames(styles.typeHeading, styles.SpaceContainer)}>
          <MapIcon />
          &nbsp;
          {propertyAddress}
        </div>
      )}
      <div className={styles.typeHeading}>{t('agent.inviteApplicant.presets')}</div>
      <div className={styles.PresentDescription}>
        {t(retrieveAgentInviteModalPreset(inviteType))}&nbsp;
        {/* {inviteType !== ApplicationRequestValues.VERIFY_IDENTITY && (
        <span className={styles.HighlightedText}>{t('agent.properties.learnMore')}</span>
      )} */}
      </div>
      {!propertyAddress && (
        <div className={styles.inputContainer}>
          <div className={styles.typeSubHeading}>{t('agent.inviteApplicant.property')}</div>
          <div>
            {isLoading ? (
              <Spinner />
            ) : (
              <PropertySelect
                isEnabled={true}
                publishedList={publishedList}
                tooltipText={t('agent.inviteApplicant.youHaveNoPublished')}
                onChangeHandler={onChangeHandler}
              />
            )}
          </div>
        </div>
      )}
      {/* {inviteType === ApplicationRequestValues.APPLICATION_AND_REPORTS && (
      <div className={styles.inputContainer}>
        <div className={styles.typeSubHeading}>{t('agent.inviteApplicant.form')}</div>
        <div>
          <div className={styles.formInputContainer}>
            {t('agent.inviteApplicant.defaultFormName')}
            <Tooltip placement="top" text={tooltipText ?? ''} icon={<InfoIconFilled />} />
          </div>
        </div>
      </div>
    )} */}
    </div>
  );
};

export default PropertyType;
