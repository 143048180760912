import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ReportItem } from 'components/shared/ReportItem/ReportItem';
import {
  canidateOffenseReportBoldedLabels,
  criminalOffenseFirstColumnLabels,
  criminalOffenseSecondColumnLabels,
} from 'constants/reportConstants';
import { convertResponseToObject, getValueFormated, isEven } from 'helpers/reportsHelper';
import { CandidateOffenseReportProps } from 'shared/types/reportTypes';

import styles from './CandidateOffenseReport.module.scss';

export const CandidateOffenseReport = ({
  orderResponse,
  criminalOffense,
  isRenterFlow = false,
}: CandidateOffenseReportProps): JSX.Element => {
  const convertedOrderResponse = convertResponseToObject(orderResponse);
  const convertedcriminalOffense = convertResponseToObject(criminalOffense);
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <Row>
        <Col sm={12} md={isRenterFlow ? 12 : 6} lg={isRenterFlow ? 12 : 4} className={styles.ReportColumns}>
          <div className={styles.Heading}>{t('agent.creditReport.candidate')}</div>

          {Object.keys(convertedOrderResponse).map((label, index) => (
            <ReportItem
              key={`converted-order-item-${index}`}
              hasGreyBackground={!isEven(index)}
              label={label}
              value={getValueFormated(convertedOrderResponse[label])}
              isValueBold={canidateOffenseReportBoldedLabels.includes(label)}
            />
          ))}
        </Col>
        <Col sm={12} md={isRenterFlow ? 12 : 6} lg={isRenterFlow ? 12 : 4}>
          <div className={styles.Heading}>{t('agent.creditReport.offense')}</div>

          {Object.keys(criminalOffenseFirstColumnLabels).map((label, index) => (
            <ReportItem
              key={`criminal-offence-label-${index}`}
              hasGreyBackground={!isEven(index)}
              label={criminalOffenseFirstColumnLabels[label]}
              value={getValueFormated(convertedcriminalOffense[label])}
              isValueBold={canidateOffenseReportBoldedLabels.includes(label)}
            />
          ))}
        </Col>
        <Col sm={12} md={isRenterFlow ? 12 : 6} lg={isRenterFlow ? 12 : 4}>
          {Object.keys(criminalOffenseSecondColumnLabels).map((label, index) => (
            <ReportItem
              key={`criminal-offense-label-secondry-${index}`}
              hasGreyBackground={!isEven(index)}
              label={criminalOffenseSecondColumnLabels[label]}
              value={getValueFormated(convertedcriminalOffense[label])}
              isValueBold={canidateOffenseReportBoldedLabels.includes(label)}
            />
          ))}
        </Col>
      </Row>
    </div>
  );
};
