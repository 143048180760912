import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ErrorInfo } from 'assets/svgs/ErrorOutlineIcon.svg';
import Button from 'components/shared/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import { RenterDeletePhoneModalProps } from 'shared/types/renterTypes';

import styles from './RenterDeleteConfirmationModal.module.scss';

export const RenterDeleteConfirmationModal = ({
  handleDelete,
  onGoBack,
  shouldOpen,
  heading,
  validationHeading,
}: RenterDeletePhoneModalProps): JSX.Element => {
  const { t } = useTranslation();
  const modalRoot = document.getElementById('modal-root');

  if (!modalRoot) {
    return <div></div>;
  }

  return ReactDOM.createPortal(
    <Modal show={shouldOpen} onHide={onGoBack} contentClassName={styles.contentClassName} centered>
      <div className={styles.container}>
        <div>
          <ErrorInfo />
        </div>
        <div className={styles.heading}>{heading}</div>
        <div className={styles.subHeading1}>{t('renter.phoneNumber.confirmSelection')}</div>
        {!!validationHeading && <div className={styles.subHeading2}>{validationHeading}</div>}
        <div className={styles.buttonsContainer}>
          <div>
            <Button variant="outline" onClick={onGoBack} className={styles.ActionButton}>
              {t('renter.phoneNumber.cancel')}
            </Button>
          </div>
          <div>
            <Button onClick={handleDelete} className={styles.ActionButton}>
              {t('renter.phoneNumber.confirm')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>,
    modalRoot
  );
};
