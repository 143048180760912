import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import { AddressFormSchema } from 'schema/renterSchema';

import { stepNumber, StepRouteType } from 'helpers/address';
import { tabTitle } from 'helpers/helper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { AddressInitialValues } from 'shared/types/renterTypes';

import ConfirmAddressHistory from '../ConfirmAddressHistory/ConfirmAddressHistory';
import ConfirmPresentAddress from '../ConfirmPresentAddress/ConfirmPresentAddress';
import ConfirmPriorAddress from '../ConfirmPriorAddress/ConfirmPriorAddress';
import RenterContainer from '../RenterContainer';

import indexStyles from '../../../Renter/RenterIndex.module.scss';

const RenterAddressInformation = (): JSX.Element => {
  tabTitle('Your Application | Address History');
  const [searchParams] = useSearchParams();
  const stepName = searchParams.get('step') || 'presentAddressConfirmation';
  const [isPresentRenting, setIsPresentRenting] = useState(false);
  const [isPriorRenting, setIsPriorRenting] = useState(false);
  const maxHeight = useDeviceHeight(220);
  const stepRoute: StepRouteType = {
    presentAddress: <ConfirmPresentAddress isRenting={isPresentRenting} setIsRenting={setIsPresentRenting} />,
    presentAddressConfirmation: (
      <ConfirmPresentAddress isRenting={isPresentRenting} setIsRenting={setIsPresentRenting} />
    ),
    priorAddress: <ConfirmPriorAddress isRenting={isPriorRenting} setIsRenting={setIsPriorRenting} />,
    addressHistory: <ConfirmAddressHistory />,
  };

  return (
    <Formik
      initialValues={AddressInitialValues}
      validationSchema={AddressFormSchema.at(stepNumber[stepName])}
      onSubmit={(values) => {
        console.log('form', values);
      }}
    >
      <RenterContainer>
        <section className={indexStyles.renterBody}>
          <div style={{ paddingRight: '12px', height: maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
            {stepRoute[stepName]}
          </div>
        </section>
      </RenterContainer>
    </Formik>
  );
};

export default RenterAddressInformation;
