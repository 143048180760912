/* eslint-disable @typescript-eslint/naming-convention */
import { FormikErrors } from 'formik';

import { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';

import { EmploymentVerifier, RenterPresentEmployments, RenterPresentEmploymentsProps } from './renterTypes';

export const INITIAL_DATE_VALUE = '01,01,1980';

export const RenterEmploymentsInitialValue = {
  fullName: '' || undefined,
  emailAddress: '' || undefined,
  phoneNumber: '' || undefined,
  jobStartDate: '' || undefined,
  jobEndDate: '' || undefined,
  employmentSubType: '' || undefined,
  jobDescription: '' || undefined,
  businessName: '' || undefined,
  status: '' || undefined,
  explanation: '' || undefined,
  monthlyGrossIncome: 0 || undefined,
  title: '' || undefined,
  company: '' || undefined,
  documents: [] as PreviewFile[],
  presentEmploymentVerifiers: [] as EmploymentVerifier[],
};

export type EmploymentStatus = {
  label: string;
  name: 'student' | 'retired' | 'homemaker';
};

export interface EmploymentProp {
  onSubmit: (
    values: RenterPresentEmployments,
    setErrors: (errors: FormikErrors<RenterPresentEmployments>) => void
  ) => void;
}
export type EmploymentProps = {
  onSubmit: (data: RenterPresentEmploymentsProps) => void;
};

export type HavePriorSourceOfIncomeProps = {
  onHandleYes: () => void;
  onHandleNo: () => void;
};

export type TypeOfPriorSourceOfIncomeProps = {
  handleTypeOfEmployment: (employmentType: string) => void;
};

export enum PriorSourceOfIncomeTypes {
  EMPLOYED = 'employed',
  SELF_EMPLOYED = 'selfEmployed',
}

export enum PresentSourceOfIncomeTypes {
  EMPLOYED = 'employed',
  SELF_EMPLOYED = 'selfEmployed',
  OTHER = 'other',
}

export enum EditEmploymentTypes {
  EMPLOYED = 'current-employed',
  SELF_EMPLOYED = 'self-employed',
  OTHERS = 'others',
}

export enum EmploymentSteps {
  TYPE_OF_INCOME = 'typeOfIncome',
}

export type PrioEmploymentrCardProps = {
  data: RenterPresentEmployments;
  isSelfEmployed: boolean;
  onEditClick?: (id?: number) => void;
  shouldShowEditIcon?: boolean;
  shouldShowExtraInformation?: boolean;
};

// Define an array of objects that map enum values to display strings
const employmentTypeMappings = [
  { value: EditEmploymentTypes.EMPLOYED, label: PresentSourceOfIncomeTypes.EMPLOYED }, // Map 'current-employed' to 'employed'
  { value: EditEmploymentTypes.SELF_EMPLOYED, label: PresentSourceOfIncomeTypes.SELF_EMPLOYED }, // Map 'self-employed' to 'selfEmployed'
  { value: EditEmploymentTypes.OTHERS, label: PresentSourceOfIncomeTypes.OTHER }, // Map 'others' to 'other'
];

// Helper function to get the display string for a given enum value
export const getEmploymentType = (enumValue?: string): string => {
  // Check if enumValue is undefined
  if (enumValue === undefined) {
    return ''; // Return a default string for undefined values
  }

  // Find the mapping object that matches the provided enum value
  const mapping = employmentTypeMappings.find((item) => item.value === enumValue);

  // If a matching mapping is found, return the corresponding label; otherwise, return ''
  return mapping ? mapping.label : '';
};
