/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Formik } from 'formik';
import { renterPaymentSchema } from 'schema/renterSchema';

import { CheckoutProps, InitialValuesRenterPayment } from 'shared/types/renterTypes';

import PaymentForm from './PaymentForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_APP_PAYMENT_KEY ?? '');
const Checkout = ({ secret, setIsOtpModalOpen, setInitialOtpCode }: CheckoutProps): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!stripePromise || !secret) {
      navigate('/');
    }
  }, [secret, navigate]);

  return (
    <Formik
      initialValues={InitialValuesRenterPayment}
      validationSchema={renterPaymentSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {secret && stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: secret,

            appearance: {
              theme: 'stripe',
              variables: {
                colorPrimary: '#008b8b',
                fontFamily: 'Open sans, sans-serif',
                colorDanger: '#f3163e',
              },
              rules: {
                '.Input:focus': {
                  boxShadow: '2px',
                  borderColor: 'none',
                },
                '.Input--invalid': {
                  boxShadow: 'none',
                  border: '1px solid #f3163e',
                  color: '#303333',
                  borderColor: '#f3163e',
                },
                '.Error': {
                  fontSize: '11.2px',
                },
              },
            },
          }}
        >
          <PaymentForm
            setInitialOtpCode={setInitialOtpCode}
            setIsOtpModalOpen={setIsOtpModalOpen}
            clientSecret={secret}
          />
        </Elements>
      )}
    </Formik>
  );
};

export default Checkout;
