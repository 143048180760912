import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';

import DocumentIcon from 'assets/images/documentIcon.png';
import MinimizedDropzone, { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import ImageProgressBox from 'components/shared/ImageProgressBox/ImageProgressBox';
import FormikField from 'components/shared/TextField/FormikTextField';
import { AgeType, AssistiveAnimalOption, FurryFriendTypes, GenderType } from 'constants/renterConstants';
import { allowOnlyDigits } from 'helpers/user';
import { FileTypes } from 'shared/types/renterIncomeInformation';
import { AnimalDetailsCardProps, AnimalInformationInitialValues } from 'shared/types/renterTypes';

import styles from './AnimalDetailsCard.module.scss';

const AnimalDetailsCard = ({ isEditModal = false, animalIndex = 1 }: AnimalDetailsCardProps): JSX.Element => {
  const {
    values: { furryFriendType, images, documents },
    setFieldValue,
  } = useFormikContext<typeof AnimalInformationInitialValues>();
  const [acceptedDocuments, setAcceptedDocuments] = useState<PreviewFile[]>([]);
  const [acceptedImages, setAcceptedImages] = useState<PreviewFile[]>([]);

  useEffect(() => {
    if (!isEmpty(acceptedDocuments) && isEditModal) {
      setFieldValue('documents', acceptedDocuments);
    }
  }, [acceptedDocuments, isEditModal, setFieldValue]);

  useEffect(() => {
    if (!isEmpty(acceptedImages) && isEditModal) {
      setFieldValue('images', acceptedImages);
    }
  }, [acceptedImages, isEditModal, setFieldValue]);

  return (
    <div className={styles.container}>
      {isEditModal && (
        <span className={styles.AnimalTag}>{`${t('renter.petsInformation.animalLabel')} ${animalIndex}`}</span>
      )}
      {isEditModal && furryFriendType === FurryFriendTypes.ASSISTIVE_ANIMAL && (
        <div className={styles.SelectContainer}>
          <FormikSelectField
            name="assistiveAnimalType"
            placeholder={t('renter.realtionship.select')}
            options={AssistiveAnimalOption}
          />
        </div>
      )}
      <div className={styles.textFieldContainer}>
        <span className={styles.textFieldLabel}>{t('renter.sourceOfIncome.type')}</span>
        <FormikField
          name="furryFriendSubType"
          placeholder={t('renter.petsInformation.typePlaceholder')}
          className={styles.inputBackgroundColor}
        />
      </div>
      <div className={styles.textFieldContainer}>
        <span className={styles.textFieldLabel}>{t('renter.petsInformation.breedlabel')}</span>
        <FormikField
          name="breed"
          placeholder={t('renter.petsInformation.breedPlaceholder')}
          className={styles.inputBackgroundColor}
        />
      </div>
      <div className={styles.reactselectFieldsContainer}>
        <div className={styles.textFieldContainer}>
          <span className={styles.textFieldLabel}>{t('renter.petsInformation.weightLabel')}</span>
          <span className={classNames(styles.textFieldLabel, styles.unitFieldLabel)}>
            {t('renter.petsInformation.weightUnitlLabel')}
          </span>
          <FormikField
            name="weight"
            placeholder={t('renter.petsInformation.weightPlaceholder')}
            className={styles.inputBackgroundColor}
            type="number"
            onKeyDown={allowOnlyDigits}
            onWheel={(event) => event.currentTarget.blur()}
          />
        </div>
        <div className={classNames(styles.textFieldContainer)}>
          <span className={styles.textFieldLabel}>{t('renter.petsInformation.ageLabel')}</span>
          <span className={classNames(styles.textFieldLabel, styles.reactSelectFieldLabel, styles.reactSelectStyle)}>
            <FormikSelectField
              name="ageUnit"
              options={AgeType}
              placeholder={t('renter.realtionship.select')}
              isSearchable={false}
            />
          </span>
          <FormikField
            name="age"
            placeholder={t('renter.petsInformation.weightPlaceholder')}
            className={classNames(styles.inputBackgroundColor)}
            type="number"
            onKeyDown={allowOnlyDigits}
            onWheel={(event) => event.currentTarget.blur()}
          />
        </div>
      </div>
      <div className={classNames(styles.textFieldContainer, styles.reactSelectClasses)}>
        <span className={styles.textFieldLabel}>{t('renter.petsInformation.sex')}</span>
        <FormikSelectField
          name="genderType"
          placeholder={t('renter.realtionship.select')}
          options={GenderType}
          backgroundColor={'#f7fafa'}
          isSearchable={false}
        />
      </div>
      <div className={styles.textFieldContainer}>
        <span className={styles.textFieldLabel}>{t('renter.CollectedInformation.name')}</span>
        <FormikField
          name="name"
          placeholder={t('renter.CollectedInformation.name')}
          className={styles.inputBackgroundColor}
        />
      </div>

      {isEditModal && (
        <div className={styles.ImagesContainer}>
          <div className={styles.Label}>{t('renter.photoLabel')}</div>
          {images?.map((image, index) => {
            const { preview } = image;
            const imgSource = (): string => {
              if (image.fileType === FileTypes.PDF) {
                return DocumentIcon;
              }

              return image.fileLink ? image.fileLink : preview;
            };

            return (
              <ImageProgressBox
                key={index}
                fileType={image.fileType}
                imgSrc={imgSource()}
                fileName={(image.path || image.fileName) ?? image.name}
                label={'Uploading'}
                onRemoveHandler={() =>
                  setFieldValue(
                    'images',
                    images.filter((f) => f.id !== image.id)
                  )
                }
                isImageUploaded={false} //isLoading is set to false when image is successfully uploaded
                isRemovable={true}
              />
            );
          })}
          {isEmpty(images) && (
            <MinimizedDropzone title={''} setAcceptedFiles={setAcceptedImages} acceptedFiles={acceptedImages} enabled />
          )}
        </div>
      )}

      {isEditModal && furryFriendType === FurryFriendTypes.ASSISTIVE_ANIMAL && (
        <div className={styles.DocumentsContainer}>
          <div className={styles.Label}>{t('renter.documentationLabel')}</div>
          {documents?.map((image, index) => {
            const { preview } = image;
            const imgSource = (): string => {
              if (image.fileType === FileTypes.PDF) {
                return DocumentIcon;
              }

              return image.fileLink ? image.fileLink : preview;
            };

            return (
              <ImageProgressBox
                key={index}
                fileType={image.fileType}
                imgSrc={imgSource()}
                fileName={(image.path || image.fileName) ?? image.name}
                label={'Uploading'}
                onRemoveHandler={() =>
                  setFieldValue(
                    'documents',
                    documents.filter((f) => f.id !== image.id)
                  )
                }
                isImageUploaded={false} //isLoading is set to false when image is successfully uploaded
                isRemovable={true}
              />
            );
          })}
          {isEmpty(documents) && (
            <MinimizedDropzone
              title={''}
              setAcceptedFiles={setAcceptedDocuments}
              acceptedFiles={acceptedDocuments}
              enabled
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AnimalDetailsCard;
