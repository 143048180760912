import { ComponentType, ReactNode } from 'react';
import SimpleSelect, { components, GroupBase, MenuListProps, OptionProps, Props } from 'react-select';
import SelectCreateable from 'react-select/creatable';
import classNames from 'classnames';

import styles from 'components/shared/ReactSelect/ReactSelect.module.scss';
import { ValueContainerWithFloatingLabel } from 'components/shared/ReactSelect/ValueContainerWithFloatingLabel';

declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    shouldShowValueInField?: boolean;
    hasFloatingLabel?: boolean;
    floatingLabel?: string;
    customValueClass?: string;
    customOption?: ComponentType<OptionProps<Option, IsMulti, Group>> | undefined;
    selectMenuButton?: ComponentType<MenuListProps<Option, IsMulti, Group>> | undefined;
  }
}

export interface CustomSelectProps {
  hasErrors?: boolean;
  hasFloatingLabel?: boolean;
  shouldShowValueInField?: boolean;
  isCreateable?: boolean;
  backgroundColor?: string | undefined;
  borderStyle?: string | undefined;
  dropDownIcon?: ReactNode;
  dropDownIconContainer?: string;
  isSearchable?: boolean;
  isFocusedFontSize?: number;
}

/**
 * @params type of react select with some custom props like, isIconOnly, hasErrors
 * hasErrors define, if there any validation failed or no item selected the errors styling will apply
 */
const ReactSelect = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  hasErrors,
  placeholder,
  isCreateable = false,
  dropDownIcon,
  backgroundColor,
  borderStyle,
  floatingLabel,
  dropDownIconContainer,
  customOption,
  selectMenuButton,
  customValueClass,
  isFocusedFontSize = 500,
  isSearchable = true,
  ...restProps
}: Props<Option, IsMulti, Group> & CustomSelectProps): JSX.Element => {
  const { hasFloatingLabel } = restProps;
  const Select = isCreateable ? SelectCreateable : SimpleSelect;

  return (
    <div className={styles.container}>
      <Select
        {...restProps}
        className={classNames(styles.select, restProps.className, {
          [styles.error]: hasErrors,
          [styles.withFloatingLabel]: hasFloatingLabel,
        })}
        styles={{
          option: (base, { isFocused }) => ({
            ...base,
            backgroundColor: isFocused ? '#D1EFF2' : '#fff',
            color: isFocused ? '#343741' : '#303333',
            fontWeight: isFocused ? isFocusedFontSize : 400,
            fontSize: '14px',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '&:active': {
              background: '#D1EFF2',
            },
          }),
          control: (base) => ({
            ...base,
            border: borderStyle || '1px solid #e6eff0',
            backgroundColor: backgroundColor || '#ffffff',
            boxShadow: 'none',
            cursor: 'pointer',
          }),
          menu: (base) => ({
            ...base,
            zIndex: 1000,
            margin: 'unset',
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: '0px',
            margin: '0px',
          }),
        }}
        isSearchable={isSearchable}
        placeholder={placeholder}
        floatingLabel={floatingLabel}
        customValueClass={customValueClass}
        components={{
          Option: customOption ? customOption : components.Option,
          MenuList: selectMenuButton ? selectMenuButton : components.MenuList,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          MultiValueLabel: () => null,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          IndicatorSeparator: () => null,
          DropdownIndicator: dropDownIcon
            ? () => <div className={dropDownIconContainer}>{dropDownIcon}</div>
            : components.DropdownIndicator,
          ValueContainer: hasFloatingLabel ? ValueContainerWithFloatingLabel : components.ValueContainer,
        }}
      />
    </div>
  );
};

export default ReactSelect;
