import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export const MailGreySVG = (): JSX.Element => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <G clip-path="url(#clip0_6053_3530)">
      <Path opacity="0.3" d="M20 6H4L12 10.99L20 6ZM4 8V18H20V8L12 13L4 8Z" fill="none" />
      <Path
        d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 6L12 10.99L4 6H20ZM20 18H4V8L12 13L20 8V18Z"
        fill="#8D9899"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_6053_3530">
        <Rect width="24" height="24" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);
