import { Spinner as BootstrapSpinner } from 'react-bootstrap';

import styles from './Spinner.module.scss';
export interface ISpinnerProps {
  className?: string;
}
const Spinner = (props: ISpinnerProps): JSX.Element => (
  <div className={styles.container}>
    <BootstrapSpinner {...props} animation="border" />
  </div>
);

export default Spinner;
