import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ReportItem } from 'components/shared/ReportItem/ReportItem';
import {
  evictionCanidateLabels,
  evictionReportBoldedLabels,
  evictionReportFirstColumnLabels,
} from 'constants/reportConstants';
import { convertResponseToObject, getCodeFormated, getValueFormated, isEven } from 'helpers/reportsHelper';
import { EvictionReportProps, ReportItemObject } from 'shared/types/reportTypes';

import { SubReport } from '../SubReport/SubReport';

import styles from './EvictionReport.module.scss';

export const EvictionReport = ({
  evictionReportResponse,
  orderResponse,
  isRenterFlow,
}: EvictionReportProps): JSX.Element => {
  const [convertedCandidate, setConvertedCandidate] =
    useState<Record<string, string | number | boolean | null | ReportItemObject>>();
  const [convertedCriminalResponse, setConvertedCriminalResponse] =
    useState<Record<string, string | number | boolean | null | ReportItemObject>>();
  const [convertedCandidateActivity, setConvertedCandidateActivity] =
    useState<Record<string, string | number | boolean | null | ReportItemObject>>();
  const [convertedOrderResponse, setConvertedOrderResponse] =
    useState<Record<string, string | number | boolean | null | ReportItemObject>>();

  convertResponseToObject(orderResponse);
  const { t } = useTranslation();

  useEffect(() => {
    if (evictionReportResponse) {
      setConvertedCriminalResponse(convertResponseToObject(evictionReportResponse));

      if (evictionReportResponse?.Candidate) {
        setConvertedCandidate(convertResponseToObject(evictionReportResponse?.Candidate));

        if (evictionReportResponse?.Candidate?.Activity) {
          setConvertedCandidateActivity(convertResponseToObject(evictionReportResponse?.Candidate.Activity));
        }
      }
    }

    if (orderResponse) {
      setConvertedOrderResponse(convertResponseToObject(orderResponse));
    }
  }, [evictionReportResponse, orderResponse]);

  return (
    <div className={styles.Container}>
      <Row className={styles.EvictionNotice}>
        {(!!convertedCandidate || !!convertedCriminalResponse) && (
          <Col md={12} lg={isRenterFlow ? 12 : 6}>
            {convertedCandidate && (
              <>
                <div className={styles.Heading}>{t('agent.creditReport.candidate')}</div>
                {Object.keys(evictionCanidateLabels).map((label, index) => (
                  <ReportItem
                    key={`eviction-candidate-label-${index}`}
                    hasGreyBackground={!isEven(index)}
                    label={evictionCanidateLabels[label]}
                    value={getValueFormated(convertedCandidate[label])}
                    code={getCodeFormated(convertedCandidate[label])}
                    isValueBold={evictionReportBoldedLabels.includes(label)}
                  />
                ))}
              </>
            )}
            {convertedCriminalResponse && (
              <div className={styles.CanidateExtraInformation}>
                {Object.keys(evictionReportFirstColumnLabels).map((label, index) => (
                  <ReportItem
                    key={`eviction-report-label-${index}`}
                    hasGreyBackground={!isEven(index)}
                    value={getValueFormated(convertedCriminalResponse[label])}
                    code={getCodeFormated(convertedCriminalResponse[label])}
                    label={evictionReportFirstColumnLabels[label]}
                    isValueBold={evictionReportBoldedLabels.includes(label)}
                  />
                ))}
              </div>
            )}
          </Col>
        )}
        {convertedCandidateActivity && (
          <Col md={12} lg={isRenterFlow ? 12 : 6}>
            <SubReport
              customContainerClass={styles.InformationColumns}
              heading={t('agent.creditReport.activity')}
              isHeadingColored
              subReportItems={convertedCandidateActivity}
              toBoldItems={evictionReportBoldedLabels}
            />
          </Col>
        )}
      </Row>
      {convertedOrderResponse && (
        <Row className={styles.OrderResponse}>
          <Col sm={12}>
            {Object.keys(convertedOrderResponse).map((label, index) => (
              <ReportItem
                key={`converted-order-item-label-${index}`}
                label={label}
                value={getValueFormated(convertedOrderResponse[label])}
                code={getCodeFormated(convertedOrderResponse[label])}
                hasGreyBackground={!isEven(index)}
                isValueBold={evictionReportBoldedLabels.includes(label)}
              />
            ))}
          </Col>
        </Row>
      )}
    </div>
  );
};
