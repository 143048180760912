import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ViewSubmittedDataProps } from 'shared/types/agentTypes';
import { VerifierTypes } from 'shared/types/renterTypes';

import { ViewSubmittedItem } from './ViewSubmittedItem';

import styles from './VerifierAddressContainer.module.scss';

export const ViewSubmittedData = ({
  submittedTime,
  fullName,
  emailAddress,
  phoneNumber,
  propertyRelation,
  customContentClassName,
  verifierLabel,
  hasPropertyRelation = true,
  verifierType,
}: ViewSubmittedDataProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.VerifierInformationContainer}>
      <div className={styles.Header}>
        <div className={styles.VerifierLabel}>{verifierLabel}</div>
        <div className={styles.CompletedTime}>{submittedTime}</div>
      </div>

      <div
        className={classNames(styles.InformationContainer, customContentClassName, {
          [styles.BlurInformation]: !fullName,
        })}
      >
        <div className={styles.Row}>
          <ViewSubmittedItem
            label={t('agent.applicants.renterResume.sourceOfIncome.verifier.fullName')}
            content={fullName}
          />
        </div>
        <div className={classNames(styles.Row, styles.DoubleRow)}>
          <ViewSubmittedItem isEmail label={t('application.email')} content={emailAddress} />
          <ViewSubmittedItem label={t('application.phone')} content={phoneNumber} />
        </div>
        {hasPropertyRelation && (
          <div className={styles.Row}>
            <ViewSubmittedItem
              label={
                verifierType === VerifierTypes.ADDRESS
                  ? t('renter.relationship.relationToProperty')
                  : t('renter.relationship.relationToEmployer')
              }
              content={propertyRelation ?? ''}
            />
          </div>
        )}
      </div>
      {!fullName && <div className={styles.ResponseNotSubmitted}>{t('renter.stripeWaiting.inProgress')}</div>}
    </div>
  );
};
