import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { OTPInputProps } from 'shared/types/renterTypes';

import { SingleInputField } from '../SingleInputField/SingleInputField';

import styles from './OTPInputField.module.scss';

export const OtpInputField = ({
  length,
  hasAutoFocus,
  disabled,
  onChangeOTP,
  inputClassName,
  inputStyle,
  submitOTP,
  initalOtpValue,
  ...rest
}: OTPInputProps): JSX.Element => {
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(
    initalOtpValue ? initalOtpValue?.split('') : Array.from({ length }, () => '')
  );
  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join('');

      onChangeOTP(otpValue);
    },
    [onChangeOTP]
  );
  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback((str: string) => {
    if (!str) {
      return str;
    }

    return Number(str) >= 0 ? str : '';
  }, []);
  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];

      updatedOTPValues[activeInput] = str[0] || '';
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );
  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);

      setActiveInput(selectedIndex);
    },
    [length]
  );
  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);
  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);
  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );
  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(e.currentTarget.value);

      if (!val) {
        e.preventDefault();

        return;
      }

      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );
  // Handle onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);
  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();

          if (otpValues[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }

          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }

        case 'Enter': {
          e.preventDefault();
          const otpValue = otpValues.join('');

          onChangeOTP(otpValue);

          if (otpValue?.length === 6 && submitOTP) {
            submitOTP();
          }

          break;
        }

        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, onChangeOTP, otpValues, submitOTP]
  );
  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, length - activeInput)
        .split('');

      handleOtpChange(pastedData);

      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];

        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);

            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });

        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, handleOtpChange, length, otpValues]
  );

  useEffect(() => {
    if (initalOtpValue) {
      setOTPValues(initalOtpValue?.split(''));
    }
  }, [initalOtpValue]);

  return (
    <div className={styles.Container} {...rest}>
      {Array(length)
        .fill('')
        .map((_, index) => (
          <SingleInputField
            key={`SingleInput-${index}`}
            type="text"
            isFocus={activeInput === index}
            value={otpValues && otpValues[index]}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={hasAutoFocus}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={onBlur}
            onPaste={handleOnPaste}
            style={inputStyle}
            className={classNames(styles.InputBox, inputClassName)}
            disabled={disabled}
          />
        ))}
    </div>
  );
};
