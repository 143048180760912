import { Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { startCase, toLower } from 'lodash-es';

import openSansNormalItalic from 'assets/fonts/ttf/OpenSans-Italic.ttf';
import openSansNormal from 'assets/fonts/ttf/OpenSans-Regular.ttf';
import openSansSemiBold from 'assets/fonts/ttf/OpenSans-SemiBold.ttf';
import { formatPhoneNumber } from 'helpers/renterHelper';
import { AgentProfileInformationProps } from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { OrganizationProps } from 'shared/types/organizationTypes';
import { AddPropertyParams } from 'shared/types/propertyType';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

import { SubmittedAtPDF } from '../../components/SubmittedAtPDF/SubmittedAtPDF';
import { sharedV2Styles } from '../../shared/sharedStyles';
import { InfoIconSVG } from '../../svgs/InfoIconSVG';
import { PinDropSVG } from '../../svgs/PinDropSVG';

interface Props {
  renterDetails: RenterProfileInformationProps;
  agentDetails: AgentProfileInformationProps;
  currentOrganization: OrganizationProps;
  application: ApplicationType;
  propertyInformation: AddPropertyParams;
}

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: openSansNormal,
      fontWeight: 'normal',
    },
    {
      src: openSansNormalItalic,
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    {
      src: openSansSemiBold,
      fontWeight: 'semibold',
    },
  ],
});
export const styles = StyleSheet.create({
  pageCol: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  renterResumeHeading: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    fontSize: '32px',
    padding: '5px 0',
    fontWeight: 200,
    color: '#303333',
  },
  greyLine: {
    height: '1px',
    width: '100%',
    backgroundColor: '#E1E8EB',
  },
  submittedDate: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    fontSize: '11px',
    marginTop: '64px',
  },
  occupant: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    color: '#5E6566',
    paddingLeft: '12px',
    marginTop: '24px',
    fontSize: '16px',
    fontWeight: 'semibold',
  },
  renterName: {
    padding: '12px',
    fontSize: '58px',
    color: '#303333',
    fontFamily: 'OpenSans',
  },
  streetAddressRow: {
    width: '70%',
    flexDirection: 'row',
    gap: '5px',
    justifyContent: 'flex-start',
    fontSize: '24px',
    marginTop: '12px',
  },
  streetAddress2: {
    color: '#5E6566',
    paddingHorizontal: '30px',
  },
  footerContainer: {
    width: '100%',
    flexDirection: 'row',
    padding: '22px 12px',
  },
  footerCol: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  },
  footerDetail: {
    paddingVertical: '12px',
  },
  footerTitle: {
    fontSize: '12px',
    fontWeight: 'semibold',
    padding: '1px',
    marginVertical: '4px',
  },
  footerText: {
    // fontWeight: 300,
    fontSize: '12px',
    color: '#303333',
  },
  disclaimerContainer: {
    width: '100%',
    border: '1px solid #5E6566',
    padding: '5px',
    flexDirection: 'row',
    gap: '8px',
    borderRadius: '4px',
  },
  disclaimerIcon: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  disclaimerText: {
    color: '#303333',
    fontSize: '8px',
    width: '100%',
  },
});

export const TitlePagePDF = ({
  renterDetails,
  agentDetails,
  currentOrganization,
  application,
  propertyInformation,
}: Props): JSX.Element => (
  <Page size={'LETTER'} style={{ ...sharedV2Styles.page, ...styles.pageCol }}>
    <View>
      <View style={styles.renterResumeHeading}>
        <Text>{'Renter Resume'}</Text>
      </View>
      <View style={{ ...styles.greyLine, marginTop: '5px' }}></View>
    </View>
    <View style={{ flexGrow: 1 }}>
      <View style={styles.submittedDate}>
        {application.submittedAt && <SubmittedAtPDF submittedAt={application.submittedAt} />}
      </View>
      <View style={styles.occupant}>
        <Text>{`OCCUPANT ${startCase(toLower(application.renterRole))}`}</Text>
      </View>
      <View style={styles.renterName}>
        <Text>{`${renterDetails.firstName}`}</Text>
        <Text>{`${renterDetails.lastName}`}</Text>
      </View>
      <View>
        <View style={styles.streetAddressRow}>
          <PinDropSVG />
          <Text>{propertyInformation.streetAddress1}</Text>
        </View>
        <Text
          style={styles.streetAddress2}
        >{`${propertyInformation?.city}, ${propertyInformation?.state} ${propertyInformation?.zipCode}`}</Text>
      </View>
    </View>
    <View>
      <View style={{ ...styles.greyLine, marginTop: '50px' }} />
      <View style={styles.footerContainer}>
        <View style={styles.footerCol}>
          <Text style={{ fontSize: '18px' }}>{`Listing by`}</Text>
          <View style={styles.footerDetail}>
            <Text style={styles.footerTitle}>{`${agentDetails.firstName} ${agentDetails.lastName}`}</Text>
            <Text style={styles.footerText}>{formatPhoneNumber(agentDetails.phoneNumber)}</Text>
            <Text style={styles.footerText}>{agentDetails.email}</Text>
          </View>
        </View>
        <View style={styles.footerCol}>
          <View style={styles.footerDetail}>
            <Text style={styles.footerTitle}>{currentOrganization.name}</Text>
            <Text style={styles.footerText}>{agentDetails.mailingStreetAddress}</Text>
            <Text
              style={styles.footerText}
            >{`${agentDetails.city}, ${agentDetails.state} ${agentDetails.zipCode}`}</Text>
          </View>
        </View>
      </View>
      {currentOrganization.name === 'Hoffman Brothers' && (
        <View style={styles.disclaimerContainer}>
          <View style={styles.disclaimerIcon}>
            <InfoIconSVG />
          </View>
          <View style={styles.disclaimerText}>
            <Text>{`Hoffman Brothers Realty acts as a conduit for presenting applicant information to landlords. We do not guarantee the accuracy, completeness, or credibility of the information provided by prospective tenants. Landlords are responsible for conducting their own due diligence and verification processes regarding applicant information. Hoffman Brothers Realty bears no liability for the accuracy or validity of the information provided by prospective tenants. offman Brothers Realty bears no liability for the accuracy `}</Text>
          </View>
        </View>
      )}
    </View>
  </Page>
);
