import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { renterAnimals, renterVehicles } from 'redux/selectors/renterSelector';
import { applicantRenterRole } from 'redux/selectors/renterSelector';

import { ReactComponent as EditIcon } from 'assets/svgs/Edit.svg';
import RenterContainer from 'components/Renter/components/RenterContainer';
import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import indexStyles from 'components/Renter/RenterIndex.module.scss';
import Button from 'components/shared/Button/Button';
import { RenterVehicleSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { VehiclesInformationType } from 'shared/types/renterVehicleInformation';

import VehicleDescriptionCard from '../../../shared/VehicleDescriptionCard/VehicleDescriptionCard';
import EditVehicleInformation from '../VehiclesEditModal/VehiclesEditModal';

import NoVehicleCard from './NoVehicleCard/NoVehicleCard';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from './VehiclesConfirmation.module.scss';

export type VehiclesProps = {
  vehicles: VehiclesInformationType[];
};
const VehiclesConfirmation = (): JSX.Element => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const furryFriends = useSelector(renterAnimals);
  const [formValues, setFormValues] = useState<VehiclesInformationType>({});
  const vehicles = useSelector(renterVehicles);
  const renterRole = useSelector(applicantRenterRole);
  const maxHeight = useDeviceHeight(220);

  useEffect(() => {
    if (renterRestrictions[renterRole].vehicles) {
      navigate(renterRoutes.generateRenterPetsInformation(applicationId));
    }
  }, [applicationId, navigate, renterRole]);
  const onClickHandler = useCallback((vehicle: VehiclesInformationType): void => {
    setFormValues(vehicle);
    setIsModalOpen(true);
  }, []);
  const onHideHandler = useCallback((): void => {
    setIsModalOpen(false);
  }, []);
  const onClickNext = useCallback((): void => {
    if (!furryFriends?.length) {
      navigate(renterRoutes.generateRenterPetsInformation(applicationId));
    } else {
      navigate(`${renterRoutes.generateRenterPetsInformation(applicationId)}&step=confirmation`);
    }
  }, [applicationId, furryFriends?.length, navigate]);
  const onClickAddMore = useCallback((): void => {
    navigate(renterRoutes.generateVehiclesInformation(applicationId, undefined, RenterVehicleSteps.TYPE));
  }, [applicationId, navigate]);
  const NoVehicleCardComponent = (): JSX.Element => (
    <section className={styles.vehiclesConfirmation}>
      <h2 className={indexStyles.renterH2}>{t('renter.vehiclesInformation.confirmation.heading')}</h2>
      <NoVehicleCard />
      <div className={styles.buttonContainer}>
        <Button className={styles.buttonPrimary} variant="outline" onClick={onClickNext} type="button">
          {t('renter.ConfirmAddressHistory.looksGood')}
        </Button>
      </div>
    </section>
  );
  const VehicleCardsComponent = (): JSX.Element => (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
          <RenterIconInfoContainer />
          <div className={indexStyles.renterContentContainer}>
            <section className={styles.vehiclesConfirmation}>
              <h2 className={indexStyles.renterH2}>{t('renter.vehiclesInformation.confirmation.heading')}</h2>
              <div className={classNames(styles.outerContainer, 'documentRow')}>
                {vehicles.map((vehicle) => (
                  <div className={styles.innerContainer} key={vehicle.id}>
                    <VehicleDescriptionCard vehicle={vehicle} />
                    <div>
                      <button
                        className={styles.editButton}
                        type="button"
                        onClick={() => {
                          onClickHandler(vehicle);
                        }}
                      >
                        <EditIcon />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={classNames(styles.buttonPrimary, styles.buttonPrimarySmall)}
                  variant="outline"
                  onClick={onClickAddMore}
                  type="button"
                >
                  {t('renter.vehiclesInformation.addAnotherVehicle')}
                </Button>
                <Button className={styles.buttonPrimary} variant="outline" onClick={onClickNext} type="button">
                  {t('renter.ConfirmAddressHistory.looksGood')}
                </Button>
              </div>
              {isModalOpen && (
                <EditVehicleInformation
                  isModalVisible={isModalOpen}
                  setIsModalVisible={setIsModalOpen}
                  onHideHandler={onHideHandler}
                  data={formValues}
                />
              )}
            </section>
          </div>
        </div>
      </section>
    </RenterContainer>
  );

  return vehicles.length ? VehicleCardsComponent() : NoVehicleCardComponent();
};

export default VehiclesConfirmation;
