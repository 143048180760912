import { Col, Row } from 'react-bootstrap';
import { t } from 'i18next';

// import { ReactComponent as InProgressYellow } from 'assets/svgs/InProgessYellow.svg';
import { DescriptionContainer } from 'components/Agent/Applications/ApplicationDetails/components/shared';

// import AgentPills, { Pill, PillTypes } from 'components/shared/AgentPills/AgentPills';
import styles from './OccupantsDetails.module.scss';

type OccupantsDetailsProps = {
  isAdult?: boolean;
  fullName: string;
  relationShip: string;
  email?: string;
  phone?: string;
  dateOfBirth?: string;
};

const OccupantsDetails = ({
  fullName,
  relationShip,
  isAdult = false,
  email,
  phone,
  dateOfBirth,
}: OccupantsDetailsProps): JSX.Element => (
  // const OccupantsStatus: Pill[] = [
  //   {
  //     heading: (
  //       <>
  //         In progress <InProgressYellow />
  //       </>
  //     ),
  //     type: PillTypes.WARNING,
  //   },
  //   { heading: 'warning', type: PillTypes.DANGER },
  // ];

  <div className={styles.Container}>
    <div className={styles.PillsContainer}>{/* <AgentPills pillsData={OccupantsStatus} /> */}</div>
    <div>
      <Row className={styles.RowContainer}>
        {fullName && (
          <Col sm={12} lg={4} md={4}>
            <DescriptionContainer heading={t('renter.relationship.fullNamePlaceHolder')} description={fullName} />
          </Col>
        )}
        <Col sm={12} lg={4} md={4}>
          <DescriptionContainer
            heading={t('renter.paymentConfirmation.houseHoldDetails.relationship')}
            description={relationShip}
          />
        </Col>
      </Row>
    </div>
    {isAdult ? (
      <div>
        <Row className={styles.RowContainer}>
          <Col sm={12} lg={4} md={4}>
            <DescriptionContainer heading={t('application.email')} description={email} />
          </Col>
          <Col sm={12} lg={4} md={4}>
            <DescriptionContainer heading={t('application.phone')} description={phone} />
          </Col>
        </Row>
      </div>
    ) : (
      <div>
        <Row className={styles.RowContainer}>
          <Col sm={12} lg={4} md={4}>
            <DescriptionContainer heading={t('application.dob')} description={dateOfBirth} />
          </Col>
        </Row>
      </div>
    )}
  </div>
);

export default OccupantsDetails;
