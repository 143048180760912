//TODO: Temporarily disabled some non-interactive features and will re-enable later.

import { Navbar as BootstrapNavbar, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

// import { ReactComponent as MessageIcon } from 'assets/svgs/MessageIcon.svg';
import { ReactComponent as ProfileIcon } from 'assets/svgs/ProfileIcon.svg';
import { ReactComponent as PropertyIcon } from 'assets/svgs/PropertyIcon.svg';
// import { ReactComponent as SearchIcon } from 'assets/svgs/Search.svg';
import { urlMatcher } from 'helpers/agentHelper';
import { routes } from 'shared/routes';
import { canViewRecordsAndActivity } from 'shared/types/agentInvite';

import styles from './../AgentNavbar.module.scss';

const MobileNavigationBar = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentOrganization = useSelector(selectedOrganization);

  return (
    <BootstrapNavbar expand="md" className={classNames('navbar fixed-bottom pt-0', styles.navBarWrapper)}>
      <hr className={classNames(styles.horizantalLineDiv, styles.topLine)} />
      <div className={styles.mobileNavBar}>
        <div className={styles.bottomLinksAlignment}>
          <Link className={styles.linkStyleDiv} to={routes.applicants}>
            <Row className={styles.linkDiv}>
              <ProfileIcon
                className={classNames(styles.svgDiv, {
                  [styles.active]: urlMatcher(routes.applicants, location),
                })}
              />
              {t('agent.applicants.property')}
            </Row>
          </Link>

          {canViewRecordsAndActivity(currentOrganization) && (
            <Link className={styles.linkStyleDiv} to={routes.properties}>
              <Row className={styles.linkDiv}>
                <PropertyIcon
                  className={classNames(styles.svgDiv, {
                    [styles.active]: urlMatcher(routes.properties, location),
                  })}
                />
                {t('agent.properties.property')}
              </Row>
            </Link>
          )}

          {/* <Link className={styles.linkStyleDiv} to={'#'}>
            <Row className={styles.linkDiv}>
              <SearchIcon className={styles.svgDiv} />
              {t('application.search')}
            </Row>
          </Link>

          <Link className={styles.linkStyleDiv} to={routes.inbox}>
            <Row className={styles.linkDiv}>
              <MessageIcon
                className={classNames(styles.svgDiv, {
                  [styles.active]: urlMatcher(routes.inbox, location),
                })}
              />
              {t('application.messaging')}
            </Row>
          </Link> */}
        </div>
      </div>
    </BootstrapNavbar>
  );
};

export default MobileNavigationBar;
