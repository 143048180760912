import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Field, Form, useFormikContext } from 'formik';
import { isSaving } from 'redux/selectors/renterSelector';

import RCButton from 'components/shared/Button/Button';
import CustomRangeDatePicker from 'components/shared/CustomRangeDatePicker/CustomRangeDatePicker';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikField from 'components/shared/TextField/FormikTextField';
import { defaultDate, endDate } from 'constants/renterConstants';
import { INITIAL_DATE_VALUE, RenterEmploymentsInitialValue } from 'shared/types/renterEmploymentInformation';
import { CustomEditModalProps } from 'shared/types/renterTypes';

import { SelfEmployedConfirmation } from './SelfEmployedConfirmation';

import indexStyles from '../../../RenterIndex.module.scss';
import renterEmployStyles from './RenterPresentForm.module.scss';

const RenterSelfEmployed = ({ heading, isForEditing }: CustomEditModalProps): JSX.Element => {
  const { t } = useTranslation();
  const isSavingInformation = useSelector(isSaving);
  const [isConfirmationPage, setIsConfirmationPage] = useState(false);
  const { dirty: isDirty, errors } = useFormikContext<typeof RenterEmploymentsInitialValue>();
  const isButtonDisabled = useMemo(
    (): boolean =>
      !!errors.businessName?.length ||
      !!errors?.title?.length ||
      !!errors.jobStartDate?.length ||
      !isDirty ||
      isSavingInformation,
    [errors.businessName?.length, errors?.title?.length, errors.jobStartDate?.length, isDirty, isSavingInformation]
  );

  return (
    <section className={renterEmployStyles.SectionContainer}>
      <Form>
        {(!isConfirmationPage || isForEditing) && (
          <div className={renterEmployStyles.container}>
            <div
              className={classNames(renterEmployStyles.linkedinHeading, heading ? renterEmployStyles.centeralized : '')}
            >
              {heading ? (
                <span>{heading}</span>
              ) : (
                <span>
                  {t('renter.customInformation.heading1')}
                  <span className={renterEmployStyles.textContainer}> {t('renter.sourceOfIncome.selfEmployed')} </span>
                  {t('renter.customInformation.heading2')}
                </span>
              )}
            </div>

            <div className={renterEmployStyles.userFields}>
              <span className={renterEmployStyles.userLabel}>{t('agent.profileForm.businessName')}</span>
              <FormikField
                className={renterEmployStyles.inputBackgroundColor}
                name="businessName"
                placeholder={t('renter.sourceOfIncome.companyName')}
              />
            </div>
            <div className={renterEmployStyles.userFields}>
              <span className={renterEmployStyles.userLabel}>{t('renter.sourceOfIncome.title')}</span>
              <FormikField
                className={renterEmployStyles.inputBackgroundColor}
                name="title"
                placeholder={t('renter.sourceOfIncome.yourRole')}
              />
            </div>

            <div className={renterEmployStyles.datePicker}>
              <Field
                as={CustomRangeDatePicker}
                leftCalendarName="jobStartDate"
                rightCalendarName="jobEndDate"
                leftCalenderStartDate={INITIAL_DATE_VALUE}
                leftCalenderEndDate={endDate}
                rightCalenderStartDate={INITIAL_DATE_VALUE}
                rightCalenderEndDate={endDate}
                floatingLabelStyling={renterEmployStyles.label}
                isToPresent
                defaultSelected={defaultDate}
                leftCalanderFloatingLabel={t('employment.presentSourceOfIncome.dateLabel')}
              />
            </div>

            {!isForEditing && (
              <div className={classNames(indexStyles.renterButtonContainer)}>
                <RCButton
                  className={classNames(indexStyles.renterButton, renterEmployStyles.continueButton)}
                  variant="outline"
                  disabled={isButtonDisabled}
                  onClick={() => {
                    setIsConfirmationPage(true);
                  }}
                >
                  {t('renter.ConfirmAddressHistory.looksGood')}
                </RCButton>
              </div>
            )}
          </div>
        )}
        {(isConfirmationPage || isForEditing) && <SelfEmployedConfirmation isForEditing={isForEditing} />}
        <FormCloseWarning isDirty={isDirty} />
      </Form>
    </section>
  );
};

export default RenterSelfEmployed;
