import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { selectedAgent } from 'redux/selectors/renterSelector';
import { AppThunkDispatch } from 'redux/store';

import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import RCButton from 'components/shared/Button/Button';
import CustomDatePicker from 'components/shared/CustomDatePicker/CustomDatePicker';
import { dateFormatBackend } from 'constants/calendarConstants';
import { formatName } from 'helpers/helper';
import { InviteType } from 'shared/types/authType';
import { AddPropertyParams } from 'shared/types/propertyType';
import {
  ApplicationCreationBodyParams,
  createApplication,
  fetchPropertyAgainstAcceptedInvitation,
  InviteDataKeys,
} from 'shared/types/renterApplication';
import { DesiredMoveInDate } from 'shared/types/renterTypes';

import { PropertyCard } from '../PropertiesList';

import styles from '../RenterPropertySelection.module.scss';
export const PropertySpecific = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const [property, setProperty] = useState<AddPropertyParams>();
  const { firstName, lastName } = useSelector(selectedAgent);
  const inviteCode = searchParams.get(InviteDataKeys.INVITE_CODE) || '';
  const inviteType = searchParams.get(InviteDataKeys.INVITE_TYPE) || '';
  const { isValid, values } = useFormikContext<DesiredMoveInDate>();
  const continueWithProperty = (): void => {
    const createPropertyParams: ApplicationCreationBodyParams = {
      applicationId: null,
      propertyId: property?.id || 0,
      inviteCode: inviteType.toLowerCase().includes(InviteType.GENERAL) ? null : inviteCode,
      desiredMoveInDate: format(new Date(values.moveInDate), dateFormatBackend),
    };

    createApplication(createPropertyParams, dispatch, navigate);
  };

  useEffect(() => {
    if (inviteCode) {
      fetchPropertyAgainstAcceptedInvitation(inviteCode, dispatch, setProperty);
    }
  }, [dispatch, inviteCode]);

  if (!property) {
    return <div />;
  }

  return (
    <>
      <RenterIconInfoContainer />
      <h2 className={styles.RenterApplicationHeading}>
        {t('renter.application.propertySpecific.introduction').replace('AGENT_NAME', formatName(firstName, lastName))}
        &nbsp;
        <span className={styles.HighlightedText}>{t('renter.application.title')}</span>&nbsp;
        {t('renter.application.application.for')}
      </h2>

      <PropertyCard property={property} isShowSelectButton={false} />
      <div className={styles.MoveInDateContainer}>
        <div className={styles.MoveInDateHeading}>{t('renter.propertySelect.pleaseEnterDesired')}</div>
        <div className={styles.MoveInDate}>
          <CustomDatePicker
            placeholder={t('renter.property.datePlaceholder')}
            floatingLabel={t('renter.propertySelect.moveInDate')}
            name="moveInDate"
          />
        </div>
      </div>
      <div className={styles.ContinueButtonContainer}>
        <div className={styles.SelectProperty}>
          <RCButton
            className={classNames(styles.Button, styles.PrimaryButton)}
            variant="outline"
            onClick={continueWithProperty}
            disabled={!values.moveInDate || !isValid}
          >
            {t('renter.application.continueWithProperty')}
          </RCButton>
        </div>
      </div>
    </>
  );
};
