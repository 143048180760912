import axios, { AxiosInstance } from 'axios';
import { t } from 'i18next';
import { logoutSuccess } from 'redux/slices/authSlice';

import { getStore } from 'services/store';
import { STATUS_NETWORK_ERROR, StatusCodes } from 'shared/constants';
import { Notification } from 'shared/Notification/Notification';
const apiService = (): AxiosInstance => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
  };
  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(
    (config) => {
      config.withCredentials = true;

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      if (
        err.response &&
        err.response.status === StatusCodes.UNAUTHORIZED &&
        err.response.config.url !== '/user/log-in'
      ) {
        const store = getStore();

        store?.dispatch(logoutSuccess());
        // Notification({ message: t('application.tokenExpired'), type: NotificationType.WARNING });
      }

      if (err?.code === STATUS_NETWORK_ERROR) {
        Notification({ message: t('baseService.errors.networkError') });
      }

      return Promise.reject(err);
    }
  );

  return instance;
};

export const baseService = apiService();

export default baseService;
