export interface ApplicationProperty {
  id: number;
  propertyId: number;
  inviteCode: string | null;
  screeningRequestId: number | null;
  desiredMoveInDate: Date;
}
export interface ApplicationPropertyLinksResponse {
  applicationId: number;
  applicationProperties: ApplicationProperty[];
}

export enum InviteType {
  EMAIL = 'email',
  SMS = 'sms',
}
export interface SendAdultCooccupantsInviteParams {
  applicationId: number;
  propertyLinkId: number;
  adultId: number;
  type?: string;
}
