import { isEmpty } from 'lodash-es';

import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { AddPropertyParams } from 'shared/types/propertyType';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

import { BackgroundQuestionViewPDF } from '../../components/BackgroundQuestionViewPDF/BackgroundQuestionViewPDF';
import { CustomPDFPage } from '../../components/shared/CustomPDFPage/CustomPDFPage';

interface Props {
  propertyInformation: AddPropertyParams;
  renterDetails: RenterProfileInformationProps;
  application: ApplicationType;
  employmentVerificationInformation: AddressVerificationResponse[];
  addressVerificationInformation: AddressVerificationResponse[];
}

export const BackgroundQuestionsPagePDF = ({
  renterDetails,
  propertyInformation,
  application,
  employmentVerificationInformation,
  addressVerificationInformation,
}: Props): JSX.Element => (
  <CustomPDFPage
    propertyInformation={propertyInformation}
    renterDetails={renterDetails}
    application={application}
    isLastPage={isEmpty(employmentVerificationInformation) && isEmpty(addressVerificationInformation)}
  >
    <BackgroundQuestionViewPDF backgroundQuestions={application?.backgroundInformation ?? []} />
  </CustomPDFPage>
);
