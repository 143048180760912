import { components, GroupBase, PropsValue, ValueContainerProps } from 'react-select';

import styles from 'components/shared/ReactSelect/ReactSelect.module.scss';

const getSelectedValue = (
  value: PropsValue<unknown>,
  label?: React.ReactNode
): string | null | undefined | React.ReactNode => {
  if (value && Array.isArray(value) && value.length > 0) {
    return value[0].value;
  }

  return label;
};

export const ValueContainerWithFloatingLabel = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  ...restProps
}: ValueContainerProps<Option, IsMulti, Group>): JSX.Element => {
  const {
    selectProps: {
      placeholder,
      hasFloatingLabel,
      shouldShowValueInField,
      menuIsOpen: isMenuOpen,
      floatingLabel = '',
      customValueClass = null,
    },
    getValue,
  } = restProps;
  const value = getValue();

  if (hasFloatingLabel && value && value.length > 0 && !floatingLabel.length) {
    return (
      <components.ValueContainer className={styles.valueContainer} {...restProps}>
        {value && value.length > 0 && (
          <span className={`${customValueClass ? styles.floatingLabelNormal : styles.floatingLabel}`}>
            {placeholder}
          </span>
        )}
        <div className={`${customValueClass ? customValueClass : customValueClass}`}>
          {shouldShowValueInField && !isMenuOpen ? getSelectedValue(value) : children}
        </div>
      </components.ValueContainer>
    );
  }

  if (floatingLabel?.length) {
    return (
      <components.ValueContainer className={styles.valueContainer} {...restProps}>
        <span className={styles.floatingLabel}>{floatingLabel}</span>
        <div
          className={`${
            getSelectedValue(value, placeholder) === 'Select' ? styles.defaultSelectChild : styles.defaultChild
          }`}
        >
          {shouldShowValueInField && !isMenuOpen ? getSelectedValue(value, placeholder) : children}
        </div>
      </components.ValueContainer>
    );
  }

  return <components.ValueContainer {...restProps}>{children}</components.ValueContainer>;
};
