import { capitalize, isEmpty } from 'lodash-es';

import { FilterOptions } from 'constants/organizationConstants';
import { ApplicationType } from 'shared/types/applicantsType';
import { RenterRoles } from 'shared/types/renterTypes';

export const formatDateToShortFormat = (date: string | undefined): string => {
  if (date) {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }

  return '___';
};

//Format date to -> May 01, 8:37 PM
export const formatDateToLastActiveFormat = (date: string): string => {
  const formattedDate = new Date(date).toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return formattedDate;
};

//Format date to -> May 01, 2024, 8:37 PM
export const formatDateToSubmitFormat = (date: string): string => {
  const formattedDate = new Date(date).toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return formattedDate;
};

//Format date to -> May 01, 2024
export const formatDateToApplicantDOBFormat = (date: string): string => {
  const formattedDate = new Date(date).toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  });

  return formattedDate;
};
export const getFiltersMapping = (status: string): string => {
  if (status === FilterOptions.LEADS) {
    return 'InActive';
  } else {
    return status;
  }
};

export const isSubmittedApplicationStatus = (status: string): boolean =>
  [FilterOptions.ACTIVE, FilterOptions.DENIED, FilterOptions.APPROVED, FilterOptions.ARCHIVE].includes(
    status as FilterOptions
  );

export const getRenterRoleCount = (applications: ApplicationType[], role: string): number => {
  // Use flatMap to concatenate arrays of minors from all levels of nesting
  const allMinors = applications.flatMap((application) => {
    const currentApplicationIsMinor = application.renterRole === role ? 1 : 0;
    const minorsInAssociatedApplications = application.associatedApplications
      ? getRenterRoleCount(application.associatedApplications, role)
      : 0;

    return currentApplicationIsMinor + minorsInAssociatedApplications;
  });
  // Use reduce to sum up the counts
  const totalCount = allMinors.reduce((acc, count) => acc + count, 0);

  return totalCount;
};

//This fucntion will flatten the application array into an single array
export const flattenApplicationList = (applicationsList: ApplicationType[]): ApplicationType[] => {
  let result: ApplicationType[] = [];

  applicationsList.forEach((application) => {
    result.push(application);

    if (!isEmpty(application.associatedApplications)) {
      result = result.concat(flattenApplicationList(application.associatedApplications));
    }
  });

  return result;
};

export const sortApplicationsByRole = (applications: ApplicationType[]): ApplicationType[] => {
  const roleOrder: Record<RenterRoles, number> = {
    primary: 1,
    guarantor: 2,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'co-occupant': 3,
  };

  return applications.sort((a, b) => roleOrder[a.renterRole] - roleOrder[b.renterRole]);
};

// Function to calculate the total number of minors
export const calculateTotalMinors = (applications: ApplicationType[]): number =>
  applications.reduce((total, application) => {
    if (application.renterRole !== RenterRoles.GUARANTOR) {
      return total + application.minorsCount;
    }

    return total;
  }, 0);
// Function to calculate the total number of animals
export const calculateTotalAnimals = (applications: ApplicationType[]): number =>
  applications.reduce((total, application) => {
    if (application.renterRole !== RenterRoles.GUARANTOR) {
      return total + application.furryFriendsCount;
    }

    return total;
  }, 0);

// Function to convert array to a formatted string
export const formatArrayToString = (arr: string[]): string => {
  if (arr.length === 0) {
    return ''; // Return an empty string for an empty array
  }

  if (arr.length === 1) {
    return arr[0]; // Return the only element for an array with a single item
  }

  const lastItem = arr.pop(); // Remove and store the last item
  const joinedString = arr.join(', '); // Join the array elements with ', '

  return `${joinedString} & ${lastItem}`; // Combine the joined string with the last item using ' & '
};

// Function to return string for which renter added  minors
export const getMinorAddedByText = (applications: ApplicationType[]): string => {
  const minorAddedBy: string[] = [];
  let occupantCount = 1;

  applications.forEach((application) => {
    const { renterRole, minorsCount } = application;

    if (minorsCount > 0) {
      minorAddedBy.push(
        renterRole === RenterRoles.OCCUPANTS ? capitalize(renterRole) : `${capitalize(renterRole)} ${occupantCount++}`
      );
    }
  });

  return !isEmpty(minorAddedBy) ? `Minor added by ${formatArrayToString(minorAddedBy)}` : '';
};

// Function to return string for which renter added  animals
export const getAnimalsAddedByText = (applications: ApplicationType[]): string => {
  const animalsAddedBy: string[] = [];
  let occupantCount = 1;

  applications.forEach((application) => {
    const { renterRole, furryFriendsCount } = application;

    if (furryFriendsCount > 0) {
      animalsAddedBy.push(
        renterRole === RenterRoles.OCCUPANTS ? capitalize(renterRole) : `${capitalize(renterRole)} ${occupantCount++}`
      );
    }
  });

  return !isEmpty(animalsAddedBy) ? `Animals add by ${formatArrayToString(animalsAddedBy)}` : '';
};
