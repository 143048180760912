import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { subYears } from 'date-fns';
import { format } from 'date-fns';
import { Form, useFormikContext } from 'formik';
import { isEmpty, omitBy } from 'lodash-es';
import { renterId } from 'redux/selectors/authSelector';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import RCButton from 'components/shared/Button/Button';
import CustomDatePicker from 'components/shared/CustomDatePicker/CustomDatePicker';
import FormikField from 'components/shared/TextField/FormikTextField';
import { dateFormatBackend } from 'constants/calendarConstants';
import { RenterProfileSteps } from 'constants/renterConstants';
import { renterRoutes } from 'shared/routes';
import { RenterBasicProfileProps } from 'shared/types/renterTypes';

import styles from '../StripeManualVerification/StripeManualVerification.module.scss';
export const StripeRenterInfoForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const { values, setErrors, isValid } = useFormikContext<RenterBasicProfileProps>();
  const userId = useSelector(renterId);
  const renterProfileInformation = useSelector(selectRenterProfileInformation);

  return (
    <Form>
      <RenterIconInfoContainer />
      <div className={styles.Container}>
        <div className={styles.Heading}>{t('renter.manualVerification.idInfo.title')}</div>
        <div className={styles.FieldsContainer}>
          <FormikField className="inputBackgroundColor" name="firstName" label="First Name" />
          <FormikField className="inputBackgroundColor" name="middleName" label="Middle Name" />
          <FormikField className="inputBackgroundColor" name="lastName" label="Last Name" />
          <div>
            <CustomDatePicker
              className="inputBackgroundColor"
              name="birthDate"
              floatingLabel="DOB"
              startDate="1900-01-01"
              defaultSelected={subYears(new Date(), 18)}
              endDate={format(subYears(new Date(), 18), dateFormatBackend)}
            />
          </div>
        </div>
        <div className={styles.RenterButtonContainer}>
          <RCButton
            className={styles.RenterButton}
            disabled={!values.firstName || !values.lastName || !values.birthDate || !isValid}
            variant="outline"
            onClick={() => {
              const newValues = {
                ...values,
                birthDate: values.birthDate && format(new Date(values.birthDate), dateFormatBackend),
              };

              dispatch(
                addRenterProfileInformation({
                  values: { ...omitBy(newValues, isEmpty), id: userId || renterProfileInformation.userId },
                })
              )
                .unwrap()
                .then(() => {
                  navigate(
                    renterRoutes.generatePersonalInformationUrl(RenterProfileSteps.BASICINFO, Number(applicationId))
                  );
                })
                .catch((errorObject) => {
                  setErrors(errorObject);
                });
            }}
          >
            {t('renter.manualVerification.renterInfo.buttonText')}
          </RCButton>
        </div>
      </div>
    </Form>
  );
};
