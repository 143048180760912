import classNames from 'classnames';
import { t } from 'i18next';

import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import FormikField from 'components/shared/TextField/FormikTextField';
import { ApplicationRequestValues } from 'constants/applicationConstants';

import styles from './ContactInformation.module.scss';

const ContactInformation = ({ inviteType }: { inviteType: ApplicationRequestValues }): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.heading}>{t('agent.inviteApplicant.enterContact')}</div>
    <div className={styles.subHeading}>
      {t(
        inviteType === ApplicationRequestValues.APPLICATION_AND_REPORTS
          ? 'agent.inviteApplicant.contactInformationApplicationReport'
          : 'agent.inviteApplicant.contactInformationIdvReport'
      )}
      {/* &nbsp;
      <span className={styles.HighlightedText}>{t('agent.properties.learnMore')}</span> */}
    </div>
    <div className={styles.UserInputWrapper}>
      <div className={styles.inputContainer}>
        <FormikField name="renterFirstName" label={t('agent.invite.contactInformation.firstName')} />
      </div>
      <div className={styles.inputContainer}>
        <FormikField name="renterLastName" label={t('agent.invite.contactInformation.lastName')} />
      </div>
    </div>
    <div>
      <div className={styles.inputWrapper}>
        <div className={classNames(styles.inputContainer, styles.emailFieldContainer)}>
          <FormikField name="renterEmail" label={t('renter.Ownerinformation.email')} type="email" />
        </div>
        <div className={styles.phoneNumberContainer}>
          <FormikPhoneNumber
            label={t('agent.inviteApplicant.sms')}
            name="renterPhone"
            isCountryCodeDisabled={false}
            placeholder={t('renter.guarantorInformationForm.(000)000-0000')}
            onlyCountry={[]}
            isCountryCodeEditable={false}
            isErrorOnEmpty
          />
        </div>
      </div>
    </div>
  </div>
);

export default ContactInformation;
