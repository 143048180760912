import { Dispatch, ReactNode } from 'react';

import { PreviewFile } from 'components/shared/DropZone/Dropzone';

import { ApiErrorType } from './apiErrorType';
import { PropertyDetail, PropertySpecificInvites } from './organizationTypes';
import { PaginationType } from './paginationTypes';
import { ISelectObjectsList } from './selectObject';
export const PropertyInitialValue = {
  propertyType: '' || undefined,
  name: '' || undefined,
  streetAddress2: '' || undefined,
  streetAddress1: '' || undefined,
  dateAvailable: '' || undefined,
  totalUnits: '' || undefined,
  sharedLivingSpace: false || undefined,
  hideAddress: false || undefined,
  bedroomsCount: '' || undefined,
  bathroomsCount: '' || undefined,
  propertyParkingOption: '' || undefined,
  propertyLeaseDuration: '' || undefined,
  monthlyRentAmount: '' || undefined,
  depositAmount: '' || undefined,
  propertyLaundryAmenity: '' || undefined,
  customUtility: '' || undefined,
  propertyUtility: '' || undefined,
  squareFootage: '' || undefined,
  description: '' || undefined,
  allowedPets: [] as string[],
  propertyImages: [] as PreviewFile[],
  state: '' || undefined,
  zipCode: '' || undefined,
  urlName: [''] as string[],
  tourName: [''] as string[],
};

export const PropertyAddressInitialValue = {
  propertyType: '' || undefined,
  name: '' || undefined,
  streetAddress2: '' || undefined,
  streetAddress1: '' || undefined,
  dateAvailable: '' || undefined,
  city: '' || undefined,
  state: '' || undefined,
  zipCode: '' || undefined,
  totalUnits: '' || undefined,
};

export const PropertyMediaInitialValue = {
  propertyImages: [] as PreviewFile[],
};

export type PropertyProps = {
  id?: number;
  propertyAmenities: string[];
  unitAmenities: string[];
  propertyType: string;
  name: string;
  streetAddress2: string;
  streetAddress1: string;
  dateAvailable: Date | string;
  totalUnits?: string;
  sharedLivingSpace?: boolean;
  hideAddress?: boolean;
  monthlyRentAmount: number;
  depositAmount: number;
  bedroomsCount: number;
  bathroomsCount: number;
  propertyParkingOption: string;
  propertyLeaseDuration?: string;
  propertyLaundryAmenity?: string;
  customUtility?: string;
  propertyUtility?: string;
  squareFootage?: number;
  description: string;
  allowedPets?: string[];
  propertyImages: PreviewFile[];
  state: string;
  zipCode: string;
  city: string;
  propertyStatus: string;
  updatedAt?: string | Date;
  publishedAt?: string | Date;
  archivedAt?: string | Date;
  createdAt: string | Date;
  isCustomAddress: boolean;
  householdCount?: number;
  agentFirstName: string;
  agentLastName: string;
  marketingEnabled?: boolean;
  applicants?: PropertyApplicants;
};
export type PropertyAmenitiesProps = {
  propertyAmenities: string[] | undefined;
  unitAmenities: string[] | undefined;
  propertyParkingOption: string | undefined;
  propertyLaundryAmenity?: string | undefined;
  allowedPets?: string[] | undefined;
};
export interface PropertyRowCardProps {
  property: PropertyProps;
}
export interface ViewPropertyProps {
  invite: PropertySpecificInvites;
  property?: PropertyDetail;
}

export interface AddPropertyParams {
  id?: number;
  organizationId?: number;
  propertyId?: number;
  propertyType?: string;
  name?: string;
  streetAddress2?: string;
  streetAddress1?: string;
  dateAvailable?: Date | string;
  sharedLivingSpace?: boolean;
  hideAddress?: boolean;
  monthlyRentAmount?: number;
  depositAmount?: number;
  bedroomsCount?: number | string;
  bathroomsCount?: number | string;
  propertyParkingOption?: string;
  propertyLeaseDuration?: string;
  propertyLaundryAmenity?: string;
  customUtility?: string;
  propertyUtility?: string;
  squareFootage?: number;
  city?: string;
  state?: string;
  zipCode?: string;
  description?: string;
  totalUnits?: string;
  active?: boolean;
  propertyStatus?: string;
  customAmenities?: string;
  propertyImages?: PreviewFile[];
  allowedPets?: string[];
  propertyAmenities?: string[];
  unitAmenities?: string[];
  isCustomAddress?: boolean;
  householdCount?: number;
  agentEmail?: string;
  agentFirstName?: string;
  agentLastName?: string;
  organizationName?: string;
  timezone?: string | null;
  longitude?: number;
  latitude?: number;
  marketingEnabled?: boolean;
  applicants?: PropertyApplicants;
}
export interface AddCustomPropertyParams extends AddPropertyParams {
  isCustomAddress: boolean;
}

export type PropertyApplicants = {
  active: number | null;
  sumOfHouseholds: number | null;
  listOfApplicants: string[] | [] | null;
};

export type GetPropertyExternalAttributesType = {
  payload: ISelectObjectsList;
  errors: [] | ApiErrorType[];
};
export type GetPropertyValues = {
  payload: {
    result: AddPropertyParams[];
    pageInfo: PaginationType;
  };
  errors: [] | ApiErrorType[];
};

export type GetPropertyParams = {
  page: number;
  per_page: number;
  organizationId?: number;
  propertyId?: number;
  propertyStatus?: string;
  isCustomAddress?: boolean;
};

export type GetAllPropertyParams = {
  organizationId?: number | undefined;
  propertyId?: number | undefined;
  propertyStatus?: string;
};

export type ApplicationPropertiesType = {
  organizationId: number;
  applicationStatus: string;
};
export type PropertyApplicantType = {
  propertyId: number;
  applicationStatus: string;
};

export interface Tag {
  name: string;
  isSelected: boolean;
  type: string;
  isCustom?: boolean;
  isAnimation?: boolean;
  tempSelected?: boolean;
}
export interface TagsContainerProps {
  title: string;
  children: React.ReactNode;
}

export interface TagProps {
  tag: Tag;
  onUpdate: (tag: Tag) => void;
  id: number;
}

export interface SelectInputContainerProps {
  onCrossClick: () => void;
}

export interface RenderTagsProps {
  data: Tag[];
  title: string;
  value: string;
  onUpdate: (tag: Tag) => void;
}

export interface AmenitiesFeatureProps {
  name?: string;
  toggleShowFeature: (isShowFeature: boolean) => void;
  isFeatureClicked: boolean;
  handleBlur: (params: AddPropertyParams) => void | undefined;
  isEditable?: boolean;
}

export const perPageOptions = [
  { value: '5', label: '5 Per Page' },
  { value: '10', label: '10 Per Page' },
  { value: '15', label: '15 Per Page' },
  { value: '20', label: '20 Per Page' },
];

export interface PropertySpecificationProps {
  value: string;
  label: string;
  labelClassName?: string;
  valueClassName?: string;
}

export interface UpdatePropertyType extends AddPropertyParams {
  createdAt?: string;
}

export type AddressType = {
  streetAddress1: string | undefined;
  streetAddress2: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zipCode: string | undefined;
};

export interface PropertyAddressProps {
  addressObject: AddressType | null;
  propertyType: string | undefined;
  dateAvailable: string | undefined;
  dotted?: boolean;
  totalUnits: string | undefined;
  name: string | undefined;
}

export type PropertyEditModalProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<React.SetStateAction<boolean>>;
  title: string;
  content: ReactNode;
  isValid: boolean;
  submitForm: () => void;
  isSubmitting: boolean;
  isFooterVisible?: boolean;
  closeButtonText?: string;
};

export type PropertyImage = {
  createdAt: string;
  fileLink: string;
  fileName: string;
  fileType: string;
  height: number;
  id: number;
  position: number;
  size: number;
  updatedAt: null | string;
  width: number;
};

export interface PropertyMarketingStatusRequestProps {
  marketingEnabled: boolean;
  organizationId: number;
  propertyId: number;
}
