import { useState } from 'react';
import { isEmpty } from 'lodash-es';

import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { Invite } from 'shared/types/organizationTypes';

import { GeneralInvitedApplicantInformation } from '../GeneralInvitedApplicantInformation/GeneralInvitedApplicant';
import { PropertyInterestedTab } from '../PropertyInterestedTab/PropertyInterestedTab';

import styles from './ViewGeneralInvite.module.scss';

interface Props {
  invites: Invite[];
  totalActive: number;
}

export const ViewGeneralInvite = ({ invites, totalActive }: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div>
      {!isEmpty(invites) && (
        <PropertyInterestedTab
          onDropDownClick={() => setIsExpanded(!isExpanded)}
          totalActive={totalActive}
          totalHouseHolds={totalActive}
          isInvited={true}
          isGeneralInvite={true}
        />
      )}
      <div>
        {isExpanded && (
          <div className={styles.ApplicationContainer}>
            <div className={styles.InvitedApplicationList}>
              {invites.map((invite) => (
                <GeneralInvitedApplicantInformation
                  key={invite.inviteId}
                  invite={invite}
                  applicationStatus={PropertyApplicationStatus.NOT_STARTED}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
