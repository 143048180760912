import { t } from 'i18next';

import styles from '../PersonalProfile.module.scss';

type RenterInformationProps = {
  dateOfBirth: string;
  idType: string;
  idNumber: string;
};

const RenterInformation = ({ dateOfBirth, idNumber, idType }: RenterInformationProps): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.infoContainer}>
      <span className={styles.heading}>{dateOfBirth}</span>
      <span className={styles.subHeading}>{t('renter.CollectedInformation.dateOfBirth')}</span>
    </div>
    <div className={styles.idContainer}>
      <div className={styles.infoContainer}>
        <span className={styles.heading}>{idType}</span>
        <span className={styles.subHeading}>{t('renter.CollectedInformation.idType')}</span>
      </div>
      <div className={styles.infoContainer}>
        <span className={styles.heading}>{idNumber}</span>
        <span className={styles.subHeading}>{t('renter.CollectedInformation.idNumber')}</span>
      </div>
    </div>
  </div>
);

export default RenterInformation;
