import { useTranslation } from 'react-i18next';

import { ReactComponent as PenIcon } from 'assets/svgs/EditGrayPencil.svg';
import { ADRESS_EXTRA_INFORMATION_LABELS, createAddressFieldValuePairs } from 'helpers/address';
import { LivingSituationBoxProps } from 'shared/types/renterTypes';

import styles from './LivingSituationBox.module.scss';
export const LivingSituationBox = ({
  address,
  customHeading,
  onClickHandler,
}: LivingSituationBoxProps): JSX.Element => {
  const filteredArray = createAddressFieldValuePairs(address).filter((pair) => !!pair.value);
  const totalHorizontalLines = (filteredArray.length ?? 0) > 1 ? 2 : 1;
  const { t } = useTranslation();

  return (
    <div className={styles.LivingSituationContainer}>
      <div className={styles.VerticalLine} />
      <div className={styles.InformationContainer}>
        <div className={styles.HorizontalLinesContainer}>
          {[...Array(totalHorizontalLines)].map((_, index) => (
            <div key={`horizontal-line-${index}`} className={styles.HorizontalLine} />
          ))}
        </div>
        <div className={styles.Information}>
          <div className={styles.InformationHeader}>
            <div className={styles.MainHeading}>{customHeading || t('address.livingSituation.heading.')}</div>
            <PenIcon className={styles.PenIcon} onClick={onClickHandler} />
          </div>

          {createAddressFieldValuePairs(address)
            .filter((pair) => !!pair.value)
            .map((item) => (
              <div key={`field-${item.fieldName}`} className={styles.FieldValue}>
                <span className={styles.Value}>{item.value}</span>
                <span className={styles.Label}>{t(ADRESS_EXTRA_INFORMATION_LABELS[item.fieldName])}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
