import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import RCButton from '../Button/Button';

import styles from './InActiveButton.module.scss';

interface InactiveButtonProps {
  onClick?: () => void;
  isActive?: boolean;
}
export const InactiveButton = ({ onClick, isActive }: InactiveButtonProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <RCButton variant="outline" onClick={onClick} className={styles.InactiveButton}>
      <span className={classNames(styles.Circle, { [styles.CircleActive]: isActive })} />
      <span className={classNames(styles.Text, { [styles.TextActive]: isActive })}>
        {isActive
          ? t('agent.properties.property.applicants.active')
          : t('agent.properties.property.applicants.inActive')}
      </span>
    </RCButton>
  );
};
