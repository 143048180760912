import { AnyAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import { reducer as agentSlice } from './slices/agentSlice';
import { reducer as authSlice } from './slices/authSlice';
import { reducer as organizationSlice } from './slices/organizationSlice';
import { reducer as propertySlice } from './slices/propertySlice';
import { reducer as renterSlice } from './slices/renterSlice';

const reducers = {
  authSlice,
  agentSlice,
  propertySlice,
  renterSlice,
  organizationSlice,
};
const appReducer = combineReducers(reducers);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducer = (state: any, action: AnyAction): ReturnType<typeof appReducer> => {
  if (action.type === 'auth/logoutSuccess') {
    storage.removeItem('persist:root');
    state = undefined;
  }

  return appReducer(state, action);
};
