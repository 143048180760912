import JSEncrypt from 'jsencrypt';
export const encryptData = (data: string): string | undefined => {
  const publicKey: string = process.env.REACT_APP_PUBLIC_ENCRYPTION_KEY || '';
  const encrypt: JSEncrypt = new JSEncrypt();

  encrypt.setPublicKey(publicKey);
  const encrypted: string | false = encrypt.encrypt(data);

  if (encrypted) {
    return encrypted;
  }
};
