import { t } from 'i18next';
import * as Yup from 'yup';

import { SSN_NUMBER_VALID_LENGTH_WITH_DASHES } from 'constants/agentConstants';
import { addressRegex, emailRegex, nameRegex, phoneRegex, suiteRegex, zipRegex } from 'constants/regexConstant';
import { CITY_MAX_LENGTH, NAME_MAX_LENGTH, NAME_MIN_LENGTH, SUITE_MAX_LENGTH } from 'constants/renterConstants';
import { isPMCUser } from 'helpers/agentHelper';
import { AgentUserRoles } from 'shared/types/agentInvite';

export const AddressSchema = Yup.object().shape({
  mailingStreetAddress: Yup.string().required('Mailing Address Name is required'),
  suite: Yup.string().matches(suiteRegex, 'Suite must meet the guidelines').nullable(),
  city: Yup.string().required('City name is required'),
  state: Yup.string().required('State name is required'),
  zipCode: Yup.string().required('Zip code is required'),
});

export const AgentFormSchema = Yup.object().shape({
  housingProviderCategory: Yup.string(),
  // PMC USER
  businessName: Yup.string()
    .nullable()
    .when('housingProviderCategory', {
      is: (category: string) => isPMCUser(category),
      then: Yup.string()
        .required(t('agent.schema.buissnessNameIsRequiredForPmc'))
        .min(NAME_MIN_LENGTH)
        .max(NAME_MAX_LENGTH)
        .nullable(),
      otherwise: Yup.string().nullable().min(NAME_MIN_LENGTH).max(NAME_MAX_LENGTH),
    }),

  businessPhoneNumber: Yup.string()
    .when('housingProviderCategory', {
      is: (category: string) => isPMCUser(category),
      then: Yup.string().required('Phone number is required').nullable(),
      otherwise: Yup.string().nullable(),
    })
    .matches(phoneRegex, t('renter.addressSchema.mustContain10Digits')),
  businessPhoneType: Yup.string().when('housingProviderCategory', {
    is: (category: string) => isPMCUser(category),
    then: Yup.string().required('Phone type is required').nullable(),
    otherwise: Yup.string().nullable(),
  }),
  businessSuite: Yup.string().max(SUITE_MAX_LENGTH).nullable(),

  businessMailingAddress: Yup.string().when('housingProviderCategory', {
    is: (category: string) => isPMCUser(category),
    then: Yup.string()
      .required('Mailing Address is required')
      .matches(
        addressRegex,
        "Mailing address can only contain letters, numbers, spaces, and the following special characters: ' - . , ( ) & #"
      )
      .nullable(),
    otherwise: Yup.string().nullable(),
  }),

  businessCity: Yup.string().when('housingProviderCategory', {
    is: (category: string) => isPMCUser(category),
    then: Yup.string().required('City name is required').min(NAME_MIN_LENGTH).max(CITY_MAX_LENGTH).nullable(),
    otherwise: Yup.string().nullable(),
  }),

  businessState: Yup.string().when('housingProviderCategory', {
    is: (category: string) => isPMCUser(category),
    then: Yup.string().required('State name is required').nullable(),
    otherwise: Yup.string().nullable(),
  }),

  businessZipCode: Yup.string().when('housingProviderCategory', {
    is: (category: string) => isPMCUser(category),
    then: Yup.string()
      .required('Zip code is required')
      .matches(zipRegex, 'Must be either 5 or 9 numeric characters long')
      .nullable(),
    otherwise: Yup.string().nullable(),
  }),

  firstName: Yup.string()
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH)
    .max(NAME_MAX_LENGTH)
    .required('First Name is required')
    .nullable(),
  lastName: Yup.string()
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH)
    .max(NAME_MAX_LENGTH)
    .required('Last Name is required')
    .nullable(),
  middleName: Yup.string()
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH)
    .max(NAME_MAX_LENGTH)
    .nullable(),
  mailingStreetAddress: Yup.string()
    .required('Mailing Address is required')
    .matches(
      addressRegex,
      "Home address can only contain letters, numbers, spaces, and the following special characters: ' - . , ( ) & #"
    )
    .nullable(),
  addressLineTwo: Yup.string()
    .matches(
      addressRegex,
      "Address line 2 can only contain letters, numbers, spaces, and the following special characters: ' - . , ( ) & #"
    )
    .nullable(),
  suite: Yup.string().max(SUITE_MAX_LENGTH).nullable(),
  city: Yup.string().required('City name is required').min(NAME_MIN_LENGTH).max(CITY_MAX_LENGTH).nullable(),
  state: Yup.string().required('State name is required').nullable(),
  zipCode: Yup.string()
    .required('Zip code is required')
    .matches(zipRegex, 'Must be either 5 or 9 numeric characters long')
    .nullable(),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegex, t('renter.addressSchema.mustContain10Digits'))
    .nullable(),
  phoneType: Yup.string().required().nullable(),

  socialSecurityNumber: Yup.string()
    .min(SSN_NUMBER_VALID_LENGTH_WITH_DASHES, `${t('agent.SSNError.RequiredLength')}`)
    .required('SSN is required')
    .nullable(),

  isConsented: Yup.bool().oneOf([true], t('agent.profile.checkbox')),
});

export const agentInviteSchema = Yup.object().shape({
  roleName: Yup.string().required(t('organizations.inviteAgent.modal.field.role.validation')),
  emailAddress: Yup.string()
    .email(t('organizations.inviteAgent.modal.field.email.validation'))
    .matches(emailRegex, t('organizations.inviteAgent.modal.field.email.validation'))
    .required(t('organizations.inviteAgent.modal.field.email.validation')),
  shareLink: Yup.string().nullable(),
  permissions: Yup.array()
    .when('roleName', {
      is: AgentUserRoles.ADMIN,
      then: Yup.array().min(1, t('organizations.inviteAgent.modal.field.permissions.validations')),
    })
    .nullable(),
});

export const renterInviteSchema = Yup.object().shape({
  renterFirstName: Yup.string()
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
    .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`),
  renterLastName: Yup.string()
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
    .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`),
  renterEmail: Yup.string().email(t('organizations.inviteAgent.modal.field.email.validation')),
});

export const agentInvitedSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
    .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`)
    .required('First Name is required')
    .nullable(),
  lastName: Yup.string()
    .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
    .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
    .max(NAME_MAX_LENGTH, `${t('renter.nameError.maxLength')}`)
    .required('Last Name is required')
    .nullable(),

  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegex, t('renter.addressSchema.mustContain10Digits'))
    .nullable(),

  phoneType: Yup.string().required('Phone Number Type is required').nullable(),
});
