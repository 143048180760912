import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { applicantRenterRole, renterApplication } from 'redux/selectors/renterSelector';

import { PendingInformationBox } from 'components/shared/PendingInformationBox/PendingInformationBox';
import { renterRestrictions } from 'constants/restrictionConstants';
import { CollapsibleBadgeComponentsProps } from 'shared/types/renterTypes';

import { Adults, Minors } from './components/index';

import styles from './HouseholdDetails.module.scss';

const HouseholdDetails = ({ next, isPending }: CollapsibleBadgeComponentsProps): JSX.Element => {
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const renterRole = useSelector(applicantRenterRole);
  const { t } = useTranslation();
  const applications = useSelector(renterApplication);
  const { adults, minors } = applications.find((app) => app.id === Number(currentApplicationId)) || {};
  const adultsCount = adults?.length ?? 0;
  const minorsCount = minors?.length ?? 0;
  const totalOccupants = adultsCount + minorsCount + 1;

  if (renterRestrictions[renterRole].adults && renterRestrictions[renterRole].minors) {
    return <div />;
  }

  return (
    <div className={styles.container}>
      {isPending && <PendingInformationBox informationalText={t('renter.applicationInfoBox.houseHold.pending')} />}
      <span className={styles.titleSubtext}>
        <span className={styles.OccupantsCount}>{totalOccupants}</span>&nbsp;
        {t('renter.paymentConfirmation.houseHoldDetails.totalOccupants')}&nbsp;
        <span className={styles.lighterText}>{t('renter.paymentConfirmation.houseHoldDetails.subTitle')}</span>
      </span>
      {!renterRestrictions[renterRole].adults && <Adults next={next} />}
      {!renterRestrictions[renterRole].minors && <Minors next={next} />}
    </div>
  );
};

export default HouseholdDetails;
