import { useTranslation } from 'react-i18next';

import { ReactComponent as CrossIcon } from 'assets/svgs/CrossIcon.svg';
import { ReactComponent as ExperianLogo } from 'assets/svgs/ExperianLogoFull.svg';
import { OTPModalHeaderProps } from 'shared/types/renterTypes';

import styles from './OTPModalHeader.module.scss';

export const OTPModalHeader = ({ shouldShowCloseIcon = false }: OTPModalHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.VerificationContent}>
        <div className={styles.Heading}>{t('otpModal.verificationBy')}</div>
        <ExperianLogo />
      </div>
      {shouldShowCloseIcon && (
        <div className={styles.ExitSign}>
          <div className={styles.CloseText}>{t('otpModal.exit')}</div>
          <CrossIcon className={styles.CrossIcon} />
        </div>
      )}
    </div>
  );
};
