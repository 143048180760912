import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { BackgroundQuestionPDFProps } from 'shared/types/pdfTypes';

import { VerificationStatusPDF } from '../shared/VerificationStatusPDF/VerificationStatusPDF';

const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'column',
  },
  Header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    padding: '4px 0px',
    gap: '12px',
  },
  Heading: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
  },
  Question: {
    padding: '4px 8px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
    borderBottomRightRadius: '0px',
    border: '1px solid #E6EFF0',
    backgroundColor: '#FFF',
    color: '#303333',
    fontSize: '11px',
  },
  AnswerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  BranchContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '11px',
    marginLeft: '2px',
  },
  TopVerticalLine: {
    height: '100%',
    borderLeft: '1px solid #E6EFF0',
    marginTop: '2px',
  },
  MidVerticalLine: {
    height: '20px',
    borderLeft: '1px solid #E6EFF0',
    marginTop: '2px',
  },
  BottomVerticalLine: {
    height: '100%',
    borderLeft: '1px solid #E6EFF0',
    marginTop: '-10px',
  },
  HorizontalLine: {
    width: '100%',
    marginTop: '10px',
    height: '1px',
    borderBottom: '1px solid #E6EFF0',
  },
  Answer: {
    padding: '4px 12px',
    borderRight: '1px solid #E6EFF0',
    borderBottom: '1px solid #E6EFF0',
    borderLeft: '1px solid #E6EFF0',
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
    textTransform: 'capitalize',
    width: '100%',
  },
  Description: {
    padding: '4px 8px',
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '4px',
    borderRight: '1px solid #E6EFF0',
    borderBottom: '1px solid #E6EFF0',
    borderLeft: '1px solid #E6EFF0',
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
    width: '100%',
  },
});

export const BackgroundQuestionPDF = ({
  index,
  question,
  answer,
  description,
  verificationStatus,
}: BackgroundQuestionPDFProps): JSX.Element => (
  <View style={styles.Container} wrap={false}>
    <View style={styles.Header}>
      <Text style={styles.Heading}>{`Q&A ${index}`}</Text>
      {!!verificationStatus && <VerificationStatusPDF status={verificationStatus} />}
    </View>

    <Text style={styles.Question}>{question}</Text>
    <View style={styles.AnswerContainer}>
      <View style={styles.BranchContainer}>
        {!!description ? <View style={styles.TopVerticalLine} /> : <View style={styles.MidVerticalLine} />}
        <View style={styles.HorizontalLine} />
      </View>
      <Text style={styles.Answer}>{answer}</Text>
    </View>
    {!!description && (
      <View style={styles.AnswerContainer}>
        <View style={styles.BranchContainer}>
          <View style={styles.BottomVerticalLine} />
          <View style={styles.HorizontalLine} />
        </View>
        <Text style={styles.Description}>{description}</Text>
      </View>
    )}
  </View>
);
