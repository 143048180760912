import { differenceInDays, differenceInHours, format, formatDistanceStrict, parse } from 'date-fns';

import { dateFormatBackend } from 'constants/calendarConstants';
export const formatDate = ({ date, dateFormat }: { date: string; dateFormat: string }): string => {
  const dateObject = parse(date, 'MM/dd/yyyy', new Date());

  return format(dateObject, dateFormat);
};

/**
 * @param {string | Date} givenDate - The date to calculate the difference from. Can be a string or a Date object.
 * @returns {string} - A string representing the time difference between the given date and the current date, formatted as per the conditions below:
 * - If the timestamp is from the current day, return the formatted result in "Modified today at [time]" format
 * - If the timestamp is within the past week but not on the same day, return the formatted result in "Modified x days ago" format
 * - If the timestamp is older than a week but within the same year, return the formatted result in "Modified [Month Day, Year]" format
 * - If the timestamp is older than a year, return the formatted result in "Modified [Month Day]" format
 */
export const getTimeDifferenceString = (givenDate: string | Date): string => {
  // Convert the givenDate to a Date object if it's a string
  const date = typeof givenDate === 'string' ? new Date(givenDate) : givenDate instanceof Date ? givenDate : new Date();
  // Get the current date and time
  const currentDate = Date.now();
  // Calculate the difference in days between the two dates
  const daysDifference = Math.abs(differenceInDays(currentDate, date));
  // Calculate the difference in hours between the two dates
  const hoursDifference = Math.abs(differenceInHours(currentDate, date));
  // Check if the timestamp is from the current day
  const isSameDay =
    date.getDate() === new Date(currentDate).getDate() &&
    date.getMonth() === new Date(currentDate).getMonth() &&
    date.getFullYear() === new Date(currentDate).getFullYear();

  if (isSameDay) {
    // If the timestamp is from the current day, return the formatted result in "Modified today at [time]" format
    return `today at ${format(date, 'p')}`;
  } else if (daysDifference === 0) {
    // If the timestamp is within the past 24 hours, return the difference in hours
    return `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
  } else if (daysDifference < 7) {
    // If the timestamp is within the past week but not on the same day, return the formatted result in "Modified x days ago" format
    return `${formatDistanceStrict(date, new Date(), { addSuffix: true, unit: 'day' })}`;
  } else {
    // If the timestamp is older than a week but within the same year or older than a year return the formatted result in "Modified [Month Day, Year]" format
    return `${format(date, 'MMM d, y')}`;
  }
};

export const formatDateForBackend = (date?: string | Date): string => {
  if (date instanceof Date) {
    return format(date, dateFormatBackend);
  }

  if (typeof date === 'string') {
    return format(new Date(date), dateFormatBackend);
  }

  return '';
};
