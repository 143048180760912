import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InProgressIcon } from 'assets/svgs/EncircledThreeDotsIcon.svg';
import { ReactComponent as ErrorIcon } from 'assets/svgs/RedWarningIcon.svg';
import { ReactComponent as ExpiredIcon } from 'assets/svgs/TimerDangerIcon.svg';
import { ReportErrorTypes } from 'constants/reportConstants';
import { ReportErrorMessageProps } from 'shared/types/reportTypes';

import { ErrorSupportMessage } from './ErrorSupportMessage';

import styles from './ReportErrorMessage.module.scss';

export const ReportErrorMessage = ({ errorType, errorCode = '000' }: ReportErrorMessageProps): JSX.Element => {
  const { t } = useTranslation();
  const getErrorIcon = (): ReactNode => {
    if (errorType === ReportErrorTypes.ERROR) {
      return <ErrorIcon />;
    } else if (errorType === ReportErrorTypes.EXPIRED) {
      return <ExpiredIcon />;
    }

    return <InProgressIcon />;
  };
  const getErrorMessage = (): string => {
    if (errorType === ReportErrorTypes.ERROR) {
      return t('agent.creditReport.notFound');
    } else if (errorType === ReportErrorTypes.EXPIRED) {
      return t('agent.creditReport.expired');
    }

    return t('agent.creditReport.inProgress');
  };

  return (
    <div className={styles.Container}>
      <div className={styles.IconContainer}>{getErrorIcon()}</div>
      <div className={styles.ErrorMessage}>
        <div className={styles.Message}>{getErrorMessage()}</div>
        {!!errorType && <ErrorSupportMessage errorType={errorType} />}
      </div>
      <div className={styles.ErrorCode}>
        {t('agent.creditReport.code')}&nbsp;{errorCode}
      </div>
    </div>
  );
};
