import { useEffect, useRef } from 'react';

import { JSCPayloadType } from 'shared/types/applicantsType';

type DeviceInfoType = {
  jscInputRef: React.MutableRefObject<HTMLInputElement | null>;
  getDeviceInfo: () => JSCPayloadType | null;
};

export const useDeviceInfoCollector = (): DeviceInfoType => {
  const jscInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (window.adx) {
      window.adx.initiate();
    }
  }, []);

  const getDeviceInfo = (): JSCPayloadType | null => {
    if (!jscInputRef.current || !window.adx) {
      return null;
    }

    return {
      jscPayload: window.adx.validate('user_prefs'),
      hdimPayload: jscInputRef.current.value,
    };
  };

  return { jscInputRef, getDeviceInfo };
};
