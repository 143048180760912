import { toast, TypeOptions } from 'react-toastify';
import { capitalize } from 'lodash-es';

import { ReactComponent as DismissIcon } from 'assets/icons/cross.svg';
import Button from 'components/shared/Button/Button';

import 'react-toastify/dist/ReactToastify.css';
import styles from './Notification.module.scss';

export enum NotificationType {
  NOTIFICATION = 'notification',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

type ShowNotification = (params: { message: string | JSX.Element; type?: TypeOptions }) => void;

export const Notification: ShowNotification = ({ message, type = 'error' }) => {
  const capitalizedMessage = typeof message === 'string' ? capitalize(message) : message;

  toast(capitalizedMessage, {
    position: 'top-center',
    autoClose: 5000, //5 seconds
    hideProgressBar: true,
    pauseOnHover: true,
    bodyClassName: 'w-auto',
    type,
    className: styles.notification,
    toastId: typeof message === 'string' ? message : undefined, // To unify duplicate error messages on a page, specially Unauthorized error.
    closeButton: ({ closeToast }) => (
      <Button onClick={closeToast} variant="link p-2">
        <DismissIcon />
      </Button>
    ),
  });
};
