import { ReactNode, useCallback, useMemo } from 'react';
import { components, SingleValue } from 'react-select';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';

import CustomErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { AddPropertyParams } from 'shared/types/propertyType';
import { ISelectObject } from 'shared/types/selectObject';
import { SelectOption } from 'shared/types/selectOption';

import styles from './FormikSelectField.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomOption = (props: any): JSX.Element => {
  const { isSelected, label, data } = props;

  return (
    <components.Option {...props}>
      <div className={styles.customSelect}>
        <div className={styles.information}>
          <span className="name">{data.__isNew__ ? data.label : label || ''}</span>
        </div>
        <div className={styles.checkedOption}>{isSelected ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
      </div>
    </components.Option>
  );
};
const FormikSelectField = ({
  name,
  options,
  placeholder,
  backgroundColor,
  dropDownIcon,
  dropDownIconContainer,
  floatingLabel,
  hasFloatingLabel,
  handleBlur,
  className,
  isCustomOptions = false,
  isSearchable = true,
  isDisabled = false,
}: {
  name: string;
  options: ISelectObject;
  placeholder: string;
  backgroundColor?: string;
  dropDownIcon?: ReactNode;
  dropDownIconContainer?: string;
  handleBlur?: (params: AddPropertyParams) => void;
  floatingLabel?: string;
  hasFloatingLabel?: boolean;
  isCustomOptions?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  className?: string;
}): JSX.Element => {
  const [field, meta, helpers] = useField({ name });
  const value = useMemo(() => options?.find((option) => option.value === field.value) || null, [field, options]);
  const onChangeHandler = useCallback(
    (option: SingleValue<SelectOption<string | number>>) => {
      helpers.setValue(option?.value);
    },
    [helpers]
  );
  const onBlur = async (e: React.FocusEvent): Promise<void> => {
    await field.onBlur(e);

    helpers.setTouched(true);

    if (handleBlur && !meta.error) {
      handleBlur && handleBlur({ [name]: field.value });
    }
  };

  return (
    <div>
      <ReactSelect
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlur}
        aria-label="react-dropdown-custom"
        hasErrors={meta.touched && !!meta.error}
        backgroundColor={backgroundColor}
        dropDownIcon={dropDownIcon}
        dropDownIconContainer={dropDownIconContainer}
        floatingLabel={floatingLabel}
        hasFloatingLabel={hasFloatingLabel}
        customOption={isCustomOptions ? CustomOption : undefined}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        className={className}
      />

      <span className={styles.minHeight}>
        {meta.touched && meta.error && <CustomErrorMessage message={meta.error} />}
      </span>
    </div>
  );
};

export default FormikSelectField;
