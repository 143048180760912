export const initialStyles = {
  fontSize: '18px',
  fontFamily: 'Open sans, sans-serif',
  fontWeight: '200',
  color: '#303333',
};

export const initialPaymentAddress = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  postal_code: '',
  country: 'US',
};

export const initialFormValues = {
  cvc: false,
  expiry: false,
  number: false,
};
