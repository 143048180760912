import { ReactNode } from 'react';
import classNames from 'classnames';
import { parseISO } from 'date-fns';

import styles from './../PropertyIndex.module.scss';

export interface PropertyDateProps {
  date?: string | Date | undefined;
  label: string;
}

const PropertyDate = ({ date = 'Some property Type', label }: PropertyDateProps): JSX.Element => (
  <div className={styles.propertyText}>
    <span className={classNames(styles.titleMinWidth, styles.stackCardTitleMinWidth)}>{label}</span>
    <span className={styles.stackCardPropertyType}>
      {date &&
        (new Date(parseISO(date.toString())).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }) as ReactNode)}
    </span>
  </div>
);

export default PropertyDate;
