import { G, Path, Svg } from '@react-pdf/renderer';

export const DocumentSVG = (): JSX.Element => (
  <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <G>
      <Path
        opacity="0.3"
        d="M10.6275 3.75L14.25 7.3725V14.25H3.75V3.75H10.6275ZM5.25 11.25H12.75V12.75H5.25V11.25ZM5.25 8.25H12.75V9.75H5.25V8.25ZM5.25 5.25H10.5V6.75H5.25V5.25Z"
        fill="#8D9899"
      />
      <Path
        d="M10.6275 3.75L14.25 7.3725V14.25H3.75V3.75H10.6275ZM10.6275 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V7.3725C15.75 6.975 15.5925 6.5925 15.3075 6.315L11.685 2.6925C11.4075 2.4075 11.025 2.25 10.6275 2.25ZM5.25 11.25H12.75V12.75H5.25V11.25ZM5.25 8.25H12.75V9.75H5.25V8.25ZM5.25 5.25H10.5V6.75H5.25V5.25Z"
        fill="#8D9899"
      />
    </G>
  </Svg>
);
