import { ReactComponent as EditIcon } from 'assets/svgs/Edit.svg';
import { formatDate } from 'helpers/user';
import { PrioEmploymentrCardProps } from 'shared/types/renterEmploymentInformation';

import styles from './DescriptionCard.module.scss';
const DescriptionCard = ({ data, isSelfEmployed, onEditClick }: PrioEmploymentrCardProps): JSX.Element => (
  <div className={styles.descriptionCard}>
    <div className={styles.descriptionContainer}>
      {!isSelfEmployed && <div className={styles.company}>{data.company}</div>}
      <div className={isSelfEmployed ? styles.company : styles.title}>{data.title}</div>
      {isSelfEmployed && <div className={styles.title}>{data.jobDescription}</div>}
      <div className={styles.title}>$ {data.monthlyGrossIncome}</div>
      <div className={styles.title}>{`${formatDate(data.jobStartDate)} - ${formatDate(data.jobEndDate)}`}</div>
    </div>
    <div>
      <button
        className={styles.editButton}
        onClick={() => {
          if (onEditClick) {
            onEditClick(data.id);
          }
        }}
      >
        <EditIcon />
      </button>
    </div>
  </div>
);

export default DescriptionCard;
