import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { isLoading, renterApplication } from 'redux/selectors/renterSelector';
import { updateApplication } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RCButton from 'components/shared/Button/Button';
import { RenterGuarantorSteps } from 'constants/renterConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../../RenterContainer';
import RenterIconInfoContainer from '../../RenterIconInfoContainer';

import indexStyles from '../../../RenterIndex.module.scss';
const GuarantorInformation = (): JSX.Element => {
  const { applicationId } = useParams();
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const applications = useSelector(renterApplication);
  const { hasNoGuarantor, adults } = applications.find((app) => app.id === Number(applicationId)) || {};
  const isLoadingInformation: boolean = useSelector(isLoading);
  const dispatch = useDispatch<AppThunkDispatch>();
  const maxHeight = useDeviceHeight(220);
  const onClickHandler = useCallback(() => {
    setSearchParams({ step: RenterGuarantorSteps.FORM });
  }, [setSearchParams]);
  const noGuarantorClickHandler = useCallback(() => {
    if (!hasNoGuarantor) {
      dispatch(updateApplication({ values: { hasNoGuarantor: true }, applicationId: Number(applicationId) }));
    }

    if (adults?.length) {
      navigate(renterRoutes.renterRelationAdultConfirmation.replace(':applicationId', `${applicationId}`));
    } else {
      navigate(renterRoutes.renterRelation.replace(':applicationId', `${applicationId}`));
    }
  }, [hasNoGuarantor, adults, dispatch, applicationId, navigate]);

  if (isLoadingInformation) {
    return <div />;
  }

  return (
    <RenterContainer>
      <section className={classNames(indexStyles.renterBody)}>
        {/* icons list */}
        <div style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
          <RenterIconInfoContainer />
          <div className={indexStyles.renterContentContainer}>
            <h2 className={indexStyles.renterH2}>{t('renter.guarantorInformation.willYouBeNeedingGuarantor?')}</h2>

            <div className={indexStyles.renterButtonContainer}>
              <RCButton
                onClick={onClickHandler}
                className={classNames(indexStyles.renterButton, indexStyles.nextButton)}
                variant="outline"
              >
                {t('renter.guarantorInformation.yes')}
              </RCButton>
            </div>

            <div className={indexStyles.renterButtonContainer}>
              <RCButton
                onClick={noGuarantorClickHandler}
                className={classNames(indexStyles.renterButton, indexStyles.nextButton)}
                variant="outline"
              >
                {t('renter.guarantorInformation.noIWillNotNeedGuarantor')}
              </RCButton>
            </div>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default GuarantorInformation;
