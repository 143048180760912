import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikErrors } from 'formik';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { isSaving, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterPhoneNumbers } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import Button from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { PhoneNumberType } from 'constants/renterConstants';
import { parseResponseErrors } from 'helpers/helper';
import { Notification } from 'shared/Notification/Notification';
import { PhoneNumber } from 'shared/types/renterTypes';

import RenterModal from '../RenterModal/RenterModal';

import styles from './EditPhoneNumberModal.module.scss';

type EditPhoneNumberModalProps = {
  onHide: () => void;
  shouldShowModal: boolean;
  index?: number;
};

const EditPhoneNumberModal = ({ onHide, shouldShowModal, index = 0 }: EditPhoneNumberModalProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const renterDetails = useSelector(selectRenterProfileInformation);
  const isSavingInformation = useSelector(isSaving);
  const renterPhoneNumber = useMemo((): PhoneNumber | undefined => {
    if (renterDetails.renterPhoneNumbers?.length) {
      return renterDetails.renterPhoneNumbers[index];
    }

    return undefined;
  }, [index, renterDetails.renterPhoneNumbers]);
  const handleSubmit = useCallback(
    (values: PhoneNumber, setErrors: (errors: FormikErrors<PhoneNumber>) => void) => {
      const { phoneNumber } = values;
      const formattedPhoneNumber = phoneNumber?.includes('+') ? phoneNumber : '+' + phoneNumber;

      dispatch(addRenterPhoneNumbers({ values: { ...values, phoneNumber: formattedPhoneNumber } }))
        .unwrap()
        .then(() => onHide())
        .catch((error) => {
          setErrors(error);

          if (!error.errorOnField) {
            Notification({ message: parseResponseErrors(error) });
          }
        });
    },
    [dispatch, onHide]
  );

  return (
    <Formik
      onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
      initialValues={{
        id: renterPhoneNumber?.id,
        phoneNumber: renterPhoneNumber?.phoneNumber,
        type: renterPhoneNumber?.type,
      }}
    >
      {({ dirty: isDirty, values, setFieldValue, isValid, errors }) => (
        <RenterModal show={shouldShowModal} onHide={onHide} isDirty={isDirty}>
          <div className={styles.phoneContainer}>
            <div className={styles.heading}>{t('renter.PhotoIdInformation.editDetails')}</div>
            <div className={styles.subHeading}>
              {t('renter.PhotoIdInformation.makeChanges')}&nbsp;
              {t('agent.invitedScreen.phoneNumber')}
            </div>
            <Form>
              <FormCloseWarning isDirty={isDirty} />
              <div className={styles.FloatingLabel}>{`PHONE ${index + 1}`}</div>
              <div className={styles.phoneNumberSelectConatiner}>
                <ReactSelect
                  options={PhoneNumberType.filter(
                    (type) => !renterDetails?.renterPhoneNumbers?.map((phone) => phone.type).includes(type.label)
                  )}
                  value={PhoneNumberType.find((val) => val.name === values.type)}
                  placeholder={t('renter.phone.selectNumberType')}
                  onChange={(option) => {
                    if (option?.label) {
                      setFieldValue('type', option.label);
                    }
                  }}
                  blurInputOnSelect
                  isSearchable={false}
                  isDisabled={isSavingInformation}
                />
              </div>
              {values.type && (
                <FormikPhoneNumber
                  name="phoneNumber"
                  isDisabled={isSavingInformation}
                  label={values.type}
                  isCountryCodeDisabled={false}
                  isCountryCodeEditable={false}
                  onlyCountry={[]}
                  isTouchedOnChange
                  placeholder={t('renter.phone.placeHolder')}
                />
              )}
              <div className={styles.renterButtonContainer}>
                <Button
                  className={styles.renterButton}
                  variant="outline"
                  disabled={!isEmpty(errors) || !isDirty || !isValid || isSavingInformation}
                  type="submit"
                >
                  {t('renter.PhotoIdInformation.savechanges')}
                </Button>
              </div>
            </Form>
          </div>
        </RenterModal>
      )}
    </Formik>
  );
};

export default EditPhoneNumberModal;
