import { StyleSheet, Text } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  Container: {
    borderRadius: '2px',
    backgroundColor: '#E6EFF0',
    alignItems: 'center',
    color: '#303333',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
    textAlign: 'center',
    height: '15px',
  },
});

export const GreyBackgroundTextPDF = ({ textValue }: { textValue: string }): JSX.Element => (
  <Text style={styles.Container}>{textValue}</Text>
);
