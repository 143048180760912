import { ReactNode, useCallback } from 'react';
import classNames from 'classnames';

import styles from './AgentPills.module.scss';

export enum PillTypes {
  //Red
  DANGER = 'Danger',
  //Dark green
  SUCCESS = 'Success',
  //Light green
  SUCCESS_LIGHT = 'SuccessLight',
  //Yellow
  WARNING = 'Warning',
  // Dark Yellow with Gray Text
  WARNING_DARK = 'WarningDark',
  //Grey
  SECONDARY = 'Secondary',
  //Pink
  DANGER_LIGHT = 'DangerLight',
  //light Cyan
  LINKS = 'Links',
  // Light Cyan
  PRIMARY_CYAN_LIGHT = 'PrimaryLight',
  // Dark Gray
  MIDNIGHT_STEEL = 'MidnightSteel',
  //Warm Grey
  WARM_GREY = 'WarnGrey',
}

export type Pill = {
  heading: ReactNode;
  type: PillTypes;
  isItalic?: boolean;
};

type AgentPillsProps = {
  pillsData: Pill[];
  pillContainerClassName?: string;
  pillIcon?: ReactNode;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const AgentPills = ({ pillsData, pillContainerClassName, pillIcon: PillIcon }: AgentPillsProps): JSX.Element => {
  const getPillClassName = useCallback((type: PillTypes, isItalic?: boolean): string => {
    const baseClassName = styles[type];

    return isItalic ? classNames(baseClassName, styles.Italic) : baseClassName;
  }, []);

  return (
    <div className={styles.Container}>
      {pillsData.map(({ heading, type, isItalic }, index) => (
        <div
          className={classNames(styles.PillContainer, getPillClassName(type, isItalic), pillContainerClassName)}
          key={index}
        >
          <div className={styles.Heading}>{heading}</div>
          {PillIcon}
        </div>
      ))}
    </div>
  );
};

export default AgentPills;
