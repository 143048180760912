import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { selectPropertyApplications } from 'redux/selectors/agentSelector';
import { organizationInvites, selectedOrganization } from 'redux/selectors/organizationSelector';

import { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import { FilterOptions } from 'constants/organizationConstants';
import { getTotalApplications } from 'helpers/agentHelper';
import { IsMobileApplicantsPage } from 'helpers/IsMobile';
import { AddPropertyParams } from 'shared/types/propertyType';

import { ApplicationSkeleton } from '../ApplicationSkeleton/ApplicationSkeleton';
import { ApplicationView } from '../ApplicationView/ApplicationView';
import { PropertyHeader } from '../PropertyHeader/PropertyHeader';

import { ViewProperty } from './ViewProperty';

import styles from './ViewPropertyInformation.module.scss';
export const ViewPropertyInformation = memo(
  ({ propertyInformation, isLoading }: { propertyInformation: AddPropertyParams; isLoading: boolean }): JSX.Element => {
    const [serachParams] = useSearchParams();
    const {
      streetAddress1,
      totalUnits,
      zipCode,
      state,
      bedroomsCount,
      bathroomsCount,
      depositAmount,
      monthlyRentAmount,
      dateAvailable,
      propertyType,
      city,
      squareFootage,
      isCustomAddress,
      id,
      agentFirstName,
      agentLastName,
      propertyImages,
    } = propertyInformation;
    const [isExpanded, setIsExpanded] = useState(true);
    const activeOrganization = useSelector(selectedOrganization);
    const propertyApplications = useSelector(selectPropertyApplications);
    const applications = propertyApplications[propertyInformation.id ?? 0] || [];
    const isMobileView = IsMobileApplicantsPage();
    const invitedApplicants = useSelector(organizationInvites);
    const activeOption = serachParams.get('option') || FilterOptions.ACTIVE;

    return (
      <div className={styles.Container}>
        {!isEmpty(applications) && (
          <PropertyHeader
            streetAddress1={streetAddress1?.split(',')[0] ?? ''}
            streetAddress2={`${city} ${state} ${zipCode}`}
            availableDate={new Date().toDateString() ?? dateAvailable}
            beds={Number(bedroomsCount)}
            baths={Number(bathroomsCount)}
            deposit={depositAmount}
            rent={monthlyRentAmount}
            ownerName={`${agentFirstName ?? ''} ${agentLastName ?? ''}`}
            ownerOrganization={activeOrganization.name ?? ''}
            propertyType={propertyType ?? ''}
            sqFtSize={squareFootage ?? 0}
            totalActive={getTotalApplications(applications) ?? 0}
            totalHouseHolds={applications.length ?? 0}
            isCustomAddress={isCustomAddress}
            totalUnits={totalUnits ?? ''}
            agentName={`${agentFirstName ?? ''} ${agentLastName ?? ''}`}
            onDropDownClick={() => {
              setIsExpanded(!isExpanded);
            }}
            propertyImages={propertyImages as PreviewFile[]}
          />
        )}
        {isExpanded && !isLoading && !isEmpty(applications) && (
          <div
            className={classNames(styles.ApplicationContainer, {
              [styles.MobileApplicationContainer]: isMobileView,
            })}
          >
            {!isMobileView && <div className={styles.VerticalLine} />}
            <div className={styles.ApplicationList}>
              {applications.map((application, index) => (
                <div key={`Property-Application-${index}-${application.id}`} className={styles.Application}>
                  <ApplicationView
                    application={application}
                    desiredMoveInDate={application.desiredMoveInDate ?? ''}
                    activeDate={application.createdAt}
                    propertyRent={monthlyRentAmount ?? 0}
                    propertyId={propertyInformation?.id ?? 0}
                    isCustomAddress={isCustomAddress}
                    propertyAddress={`${streetAddress1?.split(',')[0]} ${city} ${state} ${zipCode}`}
                  />
                </div>
              ))}

              {activeOption === FilterOptions.LEADS &&
                invitedApplicants?.propertySpecificInvites &&
                invitedApplicants?.propertySpecificInvites.map((invite, index) => {
                  if (id === invite.property.propertyId) {
                    return <ViewProperty invite={invite} key={`property-specific-invite-${index}`} />;
                  }

                  return null;
                })}
            </div>
          </div>
        )}
        {isExpanded && isLoading && <ApplicationSkeleton />}
      </div>
    );
  }
);
