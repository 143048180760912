import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Form, useFormikContext } from 'formik';

import styles from 'components/Renter/components/ConfirmAddressHistory/ConfirmAddressHistory.module.scss';
import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import indexStyles from 'components/Renter/RenterPropertySelection/PropertiesList/PropertiesList.module.scss';
import AddressBox from 'components/shared/AddressBox/AddressBox';
import RCButton from 'components/shared/Button/Button';
import CustomDatePicker from 'components/shared/CustomDatePicker/CustomDatePicker';
import { RenterCustomAddressType } from 'shared/types/renterTypes';

export const RenterConfirmCustomAddress = (): JSX.Element => {
  const { values, isValid } = useFormikContext<RenterCustomAddressType>();
  const { t } = useTranslation();

  return (
    <Form>
      <div className={indexStyles.CustomPropertyHeader}>
        <RenterIconInfoContainer />
        <p className={indexStyles.RenterH2}>{t('renter.application.confirmAProperty')}</p>
        <div className={styles.historyAddressesContainer}>
          <AddressBox address={values.renterAddress} />
        </div>
      </div>
      <div className={indexStyles.PropertiesListSection}>
        <div className={indexStyles.CustomPropertyCardContainer}>
          <div className={classNames(indexStyles.MoveInDateContainer, indexStyles.CustomPropertyDateContainer)}>
            <div className={indexStyles.MoveInDateHeading}>{t('renter.propertySelect.pleaseEnterDesired')}</div>
            <div className={indexStyles.MoveInDate}>
              <CustomDatePicker
                placeholder={t('renter.property.datePlaceholder')}
                floatingLabel={t('renter.propertySelect.moveInDate')}
                name="desiredMoveInDate"
              />
            </div>
          </div>
          <div className={indexStyles.ContinueButtonContainer}>
            <RCButton
              type="submit"
              className={classNames(indexStyles.Button, indexStyles.PrimaryButton)}
              variant="outline"
              disabled={!isValid || !values.desiredMoveInDate}
            >
              {t('renter.application.continueWithProperty')}
            </RCButton>
          </div>
        </div>
      </div>
    </Form>
  );
};
