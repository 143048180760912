import React, { Dispatch } from 'react';
import Switch from 'react-switch';
import classNames from 'classnames';

import styles from './ToggleSwitch.module.scss';

export interface IToggle {
  enabledText?: string;
  disabledText?: string;
  checked: boolean;
  onChange: Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  onHandleColor?: string;
  offHandleColor?: string;
  offColor?: string;
  onColor?: string;
  width?: number;
  height?: number;
  className?: string;
  isRenter?: boolean;
}

const Toggle = ({
  enabledText = 'Yes',
  disabledText = 'No',
  offColor = '#DC950E',
  onColor = '#60E69A',
  offHandleColor = '#DCE5E6',
  onHandleColor = '#1496A2',
  onChange,
  className,
  width = 54,
  height = 22,
  checked,
  disabled = false,
  isRenter,
}: IToggle): JSX.Element => {
  const UncheckedHandleIcon = (): JSX.Element => (
    <div className={isRenter ? styles.renterCheckedOuterCircle : styles.uncheckedOuterCircle} />
  );
  const CheckedHandleIcon = (): JSX.Element => (
    <div className={isRenter ? styles.renterUncheckedOuterCircle : styles.checkedOuterCircle} />
  );

  return (
    <div>
      <div>
        <label htmlFor="small-radius-switch">
          <Switch
            checked={checked}
            onChange={onChange}
            handleDiameter={16}
            offColor={offColor}
            onColor={onColor}
            disabled={disabled}
            offHandleColor={offHandleColor}
            onHandleColor={onHandleColor}
            height={height}
            width={width}
            className={classNames(styles.checkBoxBorder, styles.reactSwitch, className)}
            activeBoxShadow="none"
            uncheckedIcon={<div className={styles.OffText}>{disabledText}</div>}
            checkedIcon={<div className={isRenter ? styles.renterOnText : styles.OnText}>{enabledText}</div>}
            uncheckedHandleIcon={<UncheckedHandleIcon />}
            checkedHandleIcon={<CheckedHandleIcon />}
            id="small-radius-switch"
            data-testid="toggleSwitch"
          />
        </label>
      </div>
    </div>
  );
};

export default Toggle;
