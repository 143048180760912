import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { selectedRenterDetails } from 'redux/selectors/agentSelector';

import { ReactComponent as DocumentIcon } from 'assets/svgs/DocumentIcon.svg';
import { ReactComponent as NotificationIcon } from 'assets/svgs/notification_importantPinkBellIcon.svg';
import { VerifierQuestionStatusTypes } from 'constants/agentConstants';
import { VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { ResidencyVerificationProps } from 'shared/types/agentTypes';

import DocumentItem from '../../../shared/Documents/DocumentItem/DocumentItem';
import { ResidencyQuestions } from '../ResidencyQuestions/ResidencyQuestions';
import { VerificationPopover } from '../VerificationPopover/VerificationPopover';

import styles from './ResidencyVerification.module.scss';

export const ResidencyVerification = ({ verificationInformation, title }: ResidencyVerificationProps): JSX.Element => {
  const { t } = useTranslation();
  const renterDetails = useSelector(selectedRenterDetails);
  const hasWarnings = verificationInformation?.submittedVerifierResponse?.some(
    (question) => question.status === VerifierQuestionStatusTypes.UNACCEPTABLE
  );

  if (isEmpty(verificationInformation)) {
    return <div></div>;
  }

  return (
    <div className={styles.ResidencyContainer}>
      <div className={styles.HeadingContainer}>
        <div className={styles.TitleContainer}>
          <div className={styles.Title}>{title}</div>
          {hasWarnings && <NotificationIcon />}
        </div>
        {verificationInformation.submittedAt && (
          <div className={styles.ThreeDots}>
            <VerificationPopover
              type={VerifierURLType.VOR}
              id={Number(verificationInformation.renterAddressInformation?.addressId)}
              renterDetails={renterDetails}
            />
          </div>
        )}
      </div>
      {!isEmpty(verificationInformation?.submitVerifierDocument) && (
        <div className={styles.AttachmentRow}>
          {verificationInformation?.submitVerifierDocument?.map((document, index) => (
            <DocumentItem
              key={`attachment-${document.fileLink}`}
              documentName={document.fileName}
              documentLink={document.fileLink}
              icon={<DocumentIcon />}
              index={index + 1}
            />
          ))}
        </div>
      )}
      {verificationInformation?.submittedAt ? (
        <ResidencyQuestions verificationInformation={verificationInformation} />
      ) : (
        <div className={styles.ResponseNotSubmitted}>{t('agent.responseNotSubmitted')}</div>
      )}
    </div>
  );
};
