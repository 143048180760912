import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Stripe } from '@stripe/stripe-js';
import { applicationProperties, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import Button from 'components/shared/Button/Button';
import Spinner from 'components/shared/Spinner/Spinner';
import { handleStripeButtonClick, intializeStripeObject } from 'helpers/stripeHelper';
import { Notification } from 'shared/Notification/Notification';
import { ScanNewModalProps } from 'shared/types/renterInformation';
import { RenterProfileProps } from 'shared/types/renterTypes';

import RenterModal from '../RenterModal/RenterModal';

import styles from './ScanNewIDModal.module.scss';

const ScanNewIDModal = ({ isOpenModal, onClose, setIsWaiting }: ScanNewModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const handleLoading = useCallback((shouldLoad: boolean) => setIsLoading(shouldLoad), []);
  const successCallBack = useCallback(() => setIsWaiting(true), [setIsWaiting]);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const property = useSelector(applicationProperties);
  const handleClick = useCallback(async () => {
    if (!stripe) {
      return;
    }

    const { userId, stripeVerified } = renterDetails;
    const data: RenterProfileProps = {};

    try {
      if (stripeVerified) {
        data.id = userId;
        data.stripeVerified = false;

        await dispatch(addRenterProfileInformation({ values: data }));
      }

      if (property.id) {
        handleStripeButtonClick({
          handleLoading,
          stripe,
          successCallBack,
          dispatch,
          propertyId: property.id,
          organizationId: null,
        });
      }
    } catch (_err) {
      Notification({ message: t('renter.customError.stripe') });
    }
  }, [stripe, renterDetails, property, dispatch, handleLoading, successCallBack, t]);

  useEffect(() => intializeStripeObject({ setStripe }), []);

  return (
    <RenterModal show={isOpenModal} onHide={onClose}>
      <div className={styles.container}>
        <div className={styles.heading}>{t('renter.PhotoIdInformation.scanID')}</div>
        <div className={styles.subHeading}>
          {t('renter.PhotoIdInformation.reScanId')}
          <span>{t('renter.PhotoIdInformation.reScanId.governmentId')}</span>
        </div>
        <div className={styles.buttonContainer}>
          <Button onClick={handleClick} disabled={isLoading}>
            <div className={styles.buttonText}>
              <span>{t('renter.PhotoIdInformation.submit')}</span>
              {isLoading && (
                <span className={styles.loader}>
                  <Spinner />
                </span>
              )}
            </div>
          </Button>
        </div>
      </div>
    </RenterModal>
  );
};

export default ScanNewIDModal;
