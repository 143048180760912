import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Field, useField } from 'formik';

import { ReactComponent as InfoIcon } from 'assets/svgs/InfoIcon.svg';
import QuillEditor from 'components/shared/QuillEditor/QuillEditor';
import { AddPropertyParams } from 'shared/types/propertyType';

import styles from './AddProperty.module.scss';
export const WORD_COUNT = 5000;
const ERROR_COUNT = 3999;
const COUNT_SHOW = 2500;

export type DescriptionProps = {
  handleBlur?: (params: AddPropertyParams) => void;
  isShowTitle?: boolean;
  isReadOnly?: boolean;
};

const Description = ({ handleBlur, isShowTitle = true, isReadOnly = false }: DescriptionProps): JSX.Element => {
  const { t } = useTranslation();
  const [field] = useField({ name: 'description' });

  return (
    <div className={styles.amenities}>
      {isShowTitle && (
        <Row>
          <h1 className={styles.formHeading}>Description</h1>
        </Row>
      )}
      <Row>
        <p className={styles.descriptionText}>{t('agent.properties.description')}</p>
      </Row>
      <Row className={styles.descriptionEditor}>
        <Col md={12} sm={12}>
          <Field name="description">
            {() => <QuillEditor handleBlur={handleBlur} isReadOnly={isReadOnly} name="description" />}
          </Field>
        </Col>
        <Col md={12} sm={12} className={styles.wordCount}>
          {field?.value?.length >= COUNT_SHOW && (
            <p className={styles.descriptionError}>
              <span className={classNames({ [styles.error]: field.value.length > ERROR_COUNT }, styles.counterValue)}>
                {field.value.length || 0}
              </span>
              <span className={styles.counterValueLast}>/{WORD_COUNT}</span>
            </p>
          )}
        </Col>
      </Row>
      {isShowTitle && (
        <Row>
          <p className={styles.descriptionInfoParagraph}>
            <InfoIcon />
            <span className={styles.descriptionInfoText}>{t('agent.properties.description.info')}</span>
          </p>
        </Row>
      )}
    </div>
  );
};

export default Description;
