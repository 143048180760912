import classNames from 'classnames';

import styles from './Ladder.module.scss';

interface LadderProps {
  isBottomPadding?: boolean;
  isTopPadding?: boolean;
  ladderContainerClassName?: string;
  verticalBranchClassName?: string;
  horizontalBranchClassName?: string;
}
export const Ladder = ({
  isBottomPadding = false,
  isTopPadding = false,
  ladderContainerClassName,
  verticalBranchClassName,
  horizontalBranchClassName,
}: LadderProps): JSX.Element => (
  <div
    className={classNames(ladderContainerClassName ?? styles.BranchContainer, {
      [styles.bottomPadding]: isBottomPadding,
    })}
  >
    <div
      className={classNames(styles.VerticalLine, verticalBranchClassName, { [styles.MarginContainer]: isTopPadding })}
    ></div>
    <div className={classNames(styles.HorizontalLine, horizontalBranchClassName)}></div>
  </div>
);
