import { Navbar as BootstrapNavbar, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { ReactComponent as InfoRenterIcon } from 'assets/svgs/infoRenterIcon.svg';
import { ReactComponent as IntellirentLogo } from 'assets/svgs/intellirentLogo.svg';
import PropertyInformation from 'components/Renter/components/PropertyInformation/PropertyInformation';
import RenterNavDropDown from 'components/shared/RenterNavDropDown/RenterNavDropDown';
import { IntellirentPublicUrls } from 'shared/constants';

import styles from './RenterNavbar.module.scss';
const RenterNavbar = (): JSX.Element => {
  const { applicationId } = useParams();

  return (
    <BootstrapNavbar expand="md" className={styles.renterNavbarContainer}>
      <Row className={styles.renterNavbarRow}>
        <div className={styles.intellirentLogo}>
          <IntellirentLogo />
        </div>
        {applicationId && <PropertyInformation />}
        <div className={styles.renterNabBarMenuIcons}>
          <a href={IntellirentPublicUrls.CONTACT_SUPPORT} target="_blank" rel="noreferrer">
            <InfoRenterIcon />
          </a>
          <RenterNavDropDown />
        </div>
      </Row>
    </BootstrapNavbar>
  );
};

export default RenterNavbar;
