import { InviteType, OrganizationProps, UserInformation } from 'shared/types/organizationTypes';

import { RootState } from '../types';

export const organizations = (state: RootState): OrganizationProps[] => state.organizationSlice.organizations;
export const isLoading = (state: RootState): boolean => state.organizationSlice.isLoading;
export const isSaving = (state: RootState): boolean => state.organizationSlice.isSaving;
export const selectedOrganization = (state: RootState): OrganizationProps =>
  state.organizationSlice.selectedOrganization;
export const currentInviteId = (state: RootState): number | null => state.organizationSlice.inviteId;
export const organizationMemberSelector = (state: RootState): UserInformation[] =>
  state.organizationSlice.organizationMembers;
export const organizationInvites = (state: RootState): InviteType => state.organizationSlice.invites;
