export enum PropertyType {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export type PropertyInviteType = {
  renterFirstName?: string;
  renterLastName?: string;
  renterPhone?: string;
  enterEmail?: string;
  propertyId?: number | string;
};
export enum ApplicantType {
  ACTIVE = 'Active',
  APPROVED = 'Approved',
  DENIED = 'Denied',
  ARCHIVED = 'Archived',
  INVITED = 'Invited',
}
