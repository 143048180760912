import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';
export const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.FooterContainer}>
      <hr className={styles.HorizantalLine} />
      <div className={styles.Footer}>{t('intellirent.footerText')}</div>
    </div>
  );
};
