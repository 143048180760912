/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import Fuse, { FuseResult } from 'fuse.js';
import {
  amenitiesFeaturePropertyList,
  amenitiesFeatureUnitList,
  amenitiesList,
  propertyAmenitiesFeatureInitial,
} from 'redux/selectors/propertySelector';
import { updateAmenitiesFeature } from 'redux/slices/propertySlice';

import { ReactComponent as AddIcon } from 'assets/svgs/AddIcon.svg';
import { ReactComponent as SelectIcons } from 'assets/svgs/SelectIcon.svg';
import FormikField from 'components/shared/TextField/FormikTextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { featureFuzzySearchObject, sortFuseData } from 'shared/Fuse/fuse';
import {
  AmenitiesFeatureProps,
  PropertyProps,
  RenderTagsProps,
  SelectInputContainerProps,
  Tag,
  TagProps,
  TagsContainerProps,
} from 'shared/types/propertyType';

import styles from './../AddProperty.module.scss';
import amenitiesStyles from './AmenitiesFeature.module.scss';

// parent that hold the title and all the custom Feature tags
export const initializeFuse = (list: Tag[]): Fuse<Tag> => new Fuse(list, featureFuzzySearchObject);

export const TagsContainer = ({ title, children }: TagsContainerProps): JSX.Element => (
  <div className={amenitiesStyles.tagsHolder}>
    <h4 className={amenitiesStyles.tagsContainerTitle}>{title}</h4>
    {children}
  </div>
);
// Render all the tags
// if the feature tag is selected make it blur otherwise active
const FeatureTag = ({ tag, onUpdate, id }: TagProps): JSX.Element => (
  <div
    className={amenitiesStyles.tagContainer}
    onClick={() => {
      onUpdate(tag);
    }}
    aria-hidden="true"
    key={id}
  >
    <div className={amenitiesStyles.tag}>
      {tag.isSelected ? <SelectIcons /> : <AddIcon />}
      <div className={classNames(amenitiesStyles.tagName, { [amenitiesStyles.selectedTagName]: tag.isSelected })}>
        {tag.name}
      </div>
    </div>
  </div>
);
// cross button at end of input filed with tooltip component, to clear the textfield and close the feature tags container
const SelectInputContainer = ({ onCrossClick }: SelectInputContainerProps): JSX.Element => (
  <div
    onClick={onCrossClick}
    className={classNames(styles.inputReactSelectCross, styles.inputReactSelectCrossAmenities)}
    aria-hidden="true"
  >
    <Tooltip
      text={'Clear & Cancel Custom option'}
      icon={
        <div className={styles.crossIconContainer}>
          <FontAwesomeIcon icon={faXmark} className={styles.crossIcon} />
        </div>
      }
      placement={'top'}
      className={styles.tooltipWidth}
    />
  </div>
);
// render the tags container for Property and Unit
// The Purpose is to render the Tags
const RenderTags = (props: RenderTagsProps): JSX.Element => {
  const { title, data, onUpdate } = props;

  return (
    <div key={title}>
      <TagsContainer title={!!data.length ? title : ''}>
        <div className={amenitiesStyles.allTags}>
          {data.map(
            (el: Tag, key: number) =>
              !el.isCustom && <FeatureTag key={`amenities-${el}-${key}`} id={key} tag={el} onUpdate={onUpdate} />
          )}
        </div>
      </TagsContainer>
    </div>
  );
};
// propertyAmenitiesFeature
const AmenitiesFeature = ({
  name = 'customFeature',
  toggleShowFeature,
  isFeatureClicked,
  handleBlur,
  isEditable = true,
}: AmenitiesFeatureProps): JSX.Element => {
  const [searchedItems, setSearchedItems] = useState<Tag[]>([]);
  const divRef = useRef<HTMLDivElement>(null);
  const amenitiesInitialObject = useSelector(propertyAmenitiesFeatureInitial);
  const amenitiesFeatureLists = useSelector(amenitiesList);
  const amenitiesUnitList = useSelector(amenitiesFeatureUnitList);
  const amenitiesPropertyList = useSelector(amenitiesFeaturePropertyList);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [{ value }, , { setValue }] = useField({ name });
  const { setFieldValue, values } = useFormikContext<PropertyProps>();
  const fuse = initializeFuse(amenitiesFeatureLists);

  useEffect(() => {
    const data = fuse.search(value || '');

    !!data.length &&
      data.sort((prevFeature: FuseResult<Tag>, newFeature: FuseResult<Tag>): number =>
        sortFuseData(prevFeature, newFeature, value)
      );
    setSearchedItems(data.filter((el) => !el.item.isCustom).map((el) => el.item));
  }, [value]);

  useEffect(() => {
    dispatch(updateAmenitiesFeature(amenitiesInitialObject));
  }, []);

  // get all the selected tags and save to the formik context
  useEffect(() => {
    const selectedFeatures = amenitiesFeatureLists.filter((el) => el.isSelected === true);
    const selectedUnit = selectedFeatures.filter((el) => el.type === 'unit').map((el) => el.name);
    const selectedProperty = selectedFeatures.filter((el) => el.type === 'property').map((el) => el.name);

    setFieldValue('unitAmenities', !!selectedUnit.length ? selectedUnit : null);
    setFieldValue('propertyAmenities', !!selectedProperty.length ? selectedProperty : null);
  }, [amenitiesFeatureLists]);

  // if user clicked outside the feature tags container then close the feature container
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (divRef.current && !divRef.current.contains(event.target as Node) && isFeatureClicked) {
        toggleShowFeature(false);
        // update the state, and set the selected values in the object, set the selected to 'true' and tempSelection to 'false'
        dispatch(
          updateAmenitiesFeature(
            amenitiesFeatureLists.map((el: Tag) =>
              el.tempSelected ? { ...el, isSelected: true, tempSelected: false } : el
            )
          )
        );
        handleBlur({
          propertyAmenities: values.propertyAmenities,
          unitAmenities: values.unitAmenities,
        });
        setValue('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef, values]);

  const onCrossClick = (): void => {
    setValue('');
    toggleShowFeature(false);
  };
  const onMouseLeave = (e: React.MouseEvent): void => {
    (e.target as HTMLElement).blur();
  };
  const onCancel = (): void => {
    dispatch(
      updateAmenitiesFeature(
        amenitiesFeatureLists.flatMap((el) =>
          el.tempSelected
            ? { ...el, tempSelected: el.isCustom ? true : false, isSelected: el.isCustom ? true : false }
            : el
        )
      )
    );
  };
  const updateCustomFeatureObject = (tag: Tag): void => {
    // only remove the tempSelection that is selected when the dropdown was open
    const isSelectedTagClicked = tag.isSelected;

    dispatch(
      updateAmenitiesFeature(
        amenitiesFeatureLists.map((el: Tag) =>
          el.name === tag.name
            ? {
                ...el,
                isSelected: !isSelectedTagClicked,
                tempSelected: !isSelectedTagClicked,
              }
            : el
        )
      )
    );
  };
  // when user enter the search query and not item is found in list so will <div>No Result found</div>
  const RenderElement = (): JSX.Element => {
    const amUnitList = !value?.length ? amenitiesUnitList : searchedItems.filter((el) => el.type === 'unit');
    const amPropertyList = !value?.length
      ? amenitiesPropertyList
      : searchedItems.filter((el) => el.type === 'property');
    // update the customFeatureList in a PropertySlice
    const updateSliceForFeature = (tag: Tag): void => {
      if (value && !!value.length) {
        // when container is open and feature tag is clicked
        setSearchedItems(
          searchedItems.map((el) =>
            el.name === tag.name ? { ...el, isSelected: !tag.isSelected, tempSelection: true } : el
          )
        );
        // maintaining the entire state
        updateCustomFeatureObject(tag);

        return;
      }

      updateCustomFeatureObject(tag);
    };

    // if no items in customFeatureList and but searched value is there show this logic
    if (!searchedItems.length && !!value?.length) {
      return (
        <div className={amenitiesStyles.notFound}>
          <div> {t('agent.addProperty.noResultFound')}</div>
        </div>
      );
    }

    return (
      <div>
        <RenderTags data={amUnitList} onUpdate={updateSliceForFeature} title="for Unit" value={value} />
        <RenderTags data={amPropertyList} onUpdate={updateSliceForFeature} title="for Property" value={value} />
      </div>
    );
  };

  return (
    <div ref={divRef} className={amenitiesStyles.amenities}>
      {/* Formik textFiled with cross button at the end */}
      <div className={styles.utilsTextField}>
        <div className={classNames(styles.customTextFieldWithLabel, amenitiesStyles.customTextFieldWithLabelAmenities)}>
          <FormikField
            className={styles.inputWidth}
            name={name}
            placeholder="Type to search recommended"
            onMouseLeave={onMouseLeave}
            disabled={!isEditable}
            onFocus={() => {
              toggleShowFeature(true);
            }}
            onClick={() => {
              toggleShowFeature(true);
            }}
          />
        </div>
        <div className={classNames(styles.label, amenitiesStyles.labelAmenities)}>
          {t('agent.addProperty.features')}
        </div>
        {isFeatureClicked && <SelectInputContainer onCrossClick={onCrossClick} />}
      </div>
      {/* Dropdown when the input field is active */}
      {isFeatureClicked && (
        <div className={amenitiesStyles.amenitiesContainer}>
          <div className={amenitiesStyles.marginTop} aria-hidden="true" onClick={onCancel}>
            Cancel
          </div>
          <div className={amenitiesStyles.container}>
            <RenderElement />
          </div>
        </div>
      )}
    </div>
  );
};

export default AmenitiesFeature;
