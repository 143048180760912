import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import Wrapper from '../../../shared/RenderConponentNTimes/RenderComponents';

import PropertyConatinerSkeleton from './PropertyContainerSkeleton';
import { CustomSkeletonProps } from './PropertySkeleton';
import { SpanCustomSkeleton } from './PropertySkeleton';

import styles from './PropertyIndex.module.scss';
export const InlineDataWithLabel = (): JSX.Element => (
  <Col className={styles.propertyText}>
    <Wrapper
      comp={<SpanCustomSkeleton parentStyle={styles.titleMinWidth} childStyle={styles.skeletonWidth80} />}
      count={2}
    />
  </Col>
);

const ColCustomSkeletonGrid = ({ parentStyle, childStyle }: CustomSkeletonProps): JSX.Element => (
  <Col className={parentStyle}>
    <Skeleton className={childStyle} />
  </Col>
);

export const BlockDataWithLabel = (): JSX.Element => (
  <Col className={styles.displayInline}>
    <span className={styles.propertyTextlarge}>
      <Skeleton circle={true} height={18} width={18} />
    </span>
    <span className={styles.propertyText}>
      <Skeleton width={30} height={10} />
    </span>
  </Col>
);
const PropertyGridSkeleton = (): JSX.Element => (
  <div className={styles.propertyIndexPageBackground}>
    <PropertyConatinerSkeleton />
    <br />
    <div className={styles.grid}>
      <div className={styles.grid_container}>
        {Array.from({ length: 5 }).map((_, index) => (
          <div key={`property-grid-${index}`} className={styles.card}>
            <div className={styles.alignWithFlexHorizontalAlign} key={index}>
              <Row className={styles.propertyRowForData}>
                <Row>
                  <Col>
                    <Skeleton height={87} width={124} />
                    <Row className={styles.stackCardToggleRow}>
                      <Wrapper comp={<ColCustomSkeletonGrid />} count={2} />
                    </Row>
                  </Col>
                  <Col>
                    <Skeleton />
                    <Row>
                      <Wrapper comp={<BlockDataWithLabel />} count={2} />
                    </Row>
                    <Row>
                      <Wrapper comp={<BlockDataWithLabel />} count={2} />
                    </Row>
                  </Col>
                </Row>
                <span className={styles.breakPadding} />
                {/* body */}
                <Col className={styles.containerStartText}>
                  <ColCustomSkeletonGrid parentStyle={styles.propertyTitle} childStyle={styles.skeletonWidth80} />
                  <Col className={styles.gapWithFlex}>
                    <ColCustomSkeletonGrid parentStyle={styles.propertyText} childStyle={styles.skeletonWidth80} />
                    <Wrapper comp={<InlineDataWithLabel />} count={3} />
                  </Col>
                </Col>
                <Row>
                  <div className={styles.divider}></div>
                </Row>
                <Row className={styles.propertyFooter}>
                  <Col className={styles.applicantsMargin}>
                    <span className={classNames(styles.propertyText, styles.addPadding)}>
                      <Skeleton width={100} height={17} />
                    </span>
                  </Col>
                  <Row>
                    <Row className={styles.displayContent}>
                      <Wrapper comp={<SpanCustomSkeleton parentStyle={styles.propertyText} />} count={2} />
                    </Row>
                  </Row>
                </Row>
              </Row>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default PropertyGridSkeleton;
