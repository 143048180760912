import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { t } from 'i18next';
import { upperFirst } from 'lodash-es';
import { isLoading } from 'redux/selectors/organizationSelector';

import defaultImage from 'assets/images/defaultProfilePicture.png';
import styles from 'components/shared/ProfileIcon/ProfileIcon.module.scss';
interface IProfileInfoProps {
  imageSrc?: string;
  name: string;
  profileImgStyling?: string;
  organizationName?: string;
}

const ProfileInfo = ({ imageSrc, organizationName, name, profileImgStyling }: IProfileInfoProps): JSX.Element => {
  const isOrganizationLoading = useSelector(isLoading);

  return (
    <div className={classNames(styles.profileContainer)}>
      <div className={classNames(classNames(styles.profileImg, profileImgStyling))}>
        <img src={imageSrc || defaultImage} alt={t('agent.photo')} />
      </div>
      <div className={styles.profileInformation}>
        <div className={classNames(styles.profileName)}>{name}</div>
        <div className={styles.profileLabel}>
          {!isOrganizationLoading ? upperFirst(organizationName) : <Skeleton width={100} />}
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
