import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AddressReportInformationProps } from 'shared/types/reportTypes';

import { ReportHeading } from '../ReportHeading/ReportHeading';
import { AddressReport } from '../SharedReports/AddressReport/AddressReport';
import { EmptyReportRecord } from '../SharedReports/EmptyReportRecord/EmptyReportRecord';

import styles from './AddressReportInformation.module.scss';

export const AddressReportInformation = ({
  addressInformation,
  isRenterFlow = false,
}: AddressReportInformationProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <ReportHeading
        heading={t('agent.creditReport.addressInformation')}
        hasCounter={!!addressInformation?.length}
        counter={addressInformation?.length ?? 0}
      />
      {!!addressInformation?.length ? (
        <div className={styles.AddressReportContainer}>
          <Row className={styles.AddressInformationContainer}>
            {addressInformation.map((addressReport, index) => (
              <Col
                className={styles.AddressInformation}
                key={`address-information-report-${addressReport}-${index}`}
                sm={12}
                md={isRenterFlow ? 12 : 6}
                lg={isRenterFlow ? 6 : 4}
              >
                <AddressReport addressReport={addressReport} />
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <EmptyReportRecord />
      )}
    </div>
  );
};
