import { Text, View } from '@react-pdf/renderer';

import { formatDateTime } from 'helpers/agentHelper';

interface Props {
  submittedAt: string;
}

export const SubmittedAtPDF = ({ submittedAt }: Props): JSX.Element => (
  <View style={{ flexDirection: 'row' }}>
    <Text>{`Submitted `}</Text>
    <Text style={{ fontWeight: 'semibold' }}>{formatDateTime(submittedAt)}</Text>
  </View>
);
