import { AxiosResponse } from 'axios';

import { AxiosPromise } from 'shared/types/axiosPromise';
import { FinicityConnectUrl } from 'shared/types/renterTypes';

import baseService from './baseService';
export const createFinicityConnectUrl = (applicationId: number): AxiosPromise =>
  baseService.get<AxiosResponse<FinicityConnectUrl>>(`/finicity/applications/${applicationId}/connect-url`);
export const retrievalOfCustomBankAccounts = (applicationId: number): AxiosPromise =>
  baseService.get<AxiosResponse>(`/finicity/applications/${applicationId}/customer-accounts`);
export const initiateTransactionReportEvent = (applicationId: number): AxiosPromise =>
  baseService.post<AxiosResponse>(`/finicity/applications/${applicationId}/transaction-report`);
