import { useTranslation } from 'react-i18next';

import { ReactComponent as SecurityIcon } from 'assets/svgs/SecurityIcon.svg';
import TextCopyButton from 'components/Agent/components/shared/TextCopyButton/TextCopyButton';

import styles from '../AgentInviteModalBody.module.scss';

interface ShareLinkProps {
  text: string;
}
const ShareLink = ({ text }: ShareLinkProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.body}>
      <span className={styles.fieldLabel}>{t('organizations.inviteAgent.modal.field.inviteLink.label')}</span>
      <div className={styles.orgTitle}>
        <SecurityIcon />
        <span className={styles.lighterText}>{t('organizations.inviteAgent.modal.field.inviteLink.securityText')}</span>
      </div>
      <div className={styles.fieldsDiv}>
        <TextCopyButton text={text} tooltipText={t('organizations.inviteAgent.modal.copyButton.tooltip')} />
      </div>
    </div>
  );
};

export default ShareLink;
