import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { applicationProperties, applicationPropertyLink, selectedAgent } from 'redux/selectors/renterSelector';

import ProfileImage from 'assets/images/defaultProfilePicture.png';
import { ReactComponent as HomeIcon } from 'assets/svgs/HomeRental.svg';
import { ContactDetails } from 'components/Agent/Applications/ApplicationDetails/components/RenterPersonalProfile/components';
import Title from 'components/Renter/IdentityVerification/RenterDetails/shared/Title/Title';
import { DateViewer } from 'components/shared/DateRangeViewer/DateViewer';
import { InitialMoveInDate } from 'constants/renterConstants';

import styles from '../RenterReport.module.scss';

const AgentDetails = (): JSX.Element => {
  const { t } = useTranslation();
  const [isInitalMoveInDate, setIsInitalMoveInDate] = useState(InitialMoveInDate);
  const { streetAddress1, city, state, zipCode } = useSelector(applicationProperties);
  const applicationPropertyDetails = useSelector(applicationPropertyLink);
  const { firstName, lastName, email } = useSelector(selectedAgent);

  useEffect(() => {
    setIsInitalMoveInDate({ moveInDate: applicationPropertyDetails.desiredMoveInDate });
  }, [applicationPropertyDetails]);

  return (
    <div>
      <Title mainTitle={t('renter.report.agentDetails.title')} iconElement={<HomeIcon />} />
      <span className={styles.AddressContainer}>
        <span className={styles.StreetAddressContainer}>{streetAddress1}&nbsp;</span>
        <span className={classNames(styles.StreetAddressContainer, styles.LocalAddressContainer)}>
          {state} {city} {zipCode}
        </span>
      </span>
      <span className={styles.DetailsContainer}>
        <ContactDetails
          heading={`${firstName} ${lastName}`}
          data={email}
          icon={<img className={styles.ProfileImage} src={ProfileImage} alt="ProfileIcon" />}
        />
      </span>
      <DateViewer date={isInitalMoveInDate.moveInDate} label={t('renter.relationship.moveInDate')} />
    </div>
  );
};

export default AgentDetails;
