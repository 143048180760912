import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash-es';

import { ReactComponent as OpenInNewTabIcon } from 'assets/svgs/ArrowInBoxIcon.svg';
import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';
import { StatusPills, StatusType } from 'components/shared/StatusPills/StatusPills';
import { useSaveVerificationPDF } from 'hooks/useSaveVerificationPDF';
import { Colors } from 'shared/constants';
import { VerificationPDF, VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { RequestVerificationDetailsProps } from 'shared/types/renterTypes';

import { RequestVerificationDate } from '../';

export const RequestVerificationDetails = ({
  isDetailsDisabled,
  requestedAt,
  isSubmitted,
  type,
}: RequestVerificationDetailsProps): JSX.Element => {
  const [params] = useSearchParams();
  const token = params.get('token') || '';
  const { t } = useTranslation();
  const { questionnaire, userAddressDetails, userEmploymentDetails } = useSaveVerificationPDF({
    token,
    type,
    isSubmitted,
  });

  return (
    <Row className={styles.RequestDetailsContainer}>
      {!isDetailsDisabled && (
        <>
          <Col xs={12} md={6} lg={6} xl={7}>
            <Row className={classNames(styles.RequestDateContainer, styles.DisplayContainer)}>
              <Col xs={12} sm={9} md={12} lg={12} xl={6} className={styles.TextSection}>
                <RequestVerificationDate requestedAt={requestedAt} />
              </Col>
              <Col xs={12} sm={3} md={12} lg={12} xl={3}>
                <StatusPills status={isSubmitted ? StatusType.COMPLETE : StatusType.PENDING} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} lg={5} xl={5} className={styles.TextRightContainer}>
            <div
              onClick={async () => {
                if (
                  !isEmpty(questionnaire.verificationQuestions) &&
                  ((type === VerifierURLType.VOE && !isEmpty(userEmploymentDetails)) ||
                    (type === VerifierURLType.VOR && !isEmpty(userAddressDetails)))
                ) {
                  const blob = await pdf(
                    type === VerifierURLType.VOE ? (
                      <VerificationPDF
                        questionnaire={questionnaire}
                        type={type}
                        userEmploymentDetails={userEmploymentDetails}
                      />
                    ) : (
                      <VerificationPDF
                        questionnaire={questionnaire}
                        type={type}
                        userAddressDetails={userAddressDetails}
                      />
                    )
                  ).toBlob();

                  saveAs(blob, type === VerifierURLType.VOE ? 'VOE' : 'VOR');
                }
              }}
              className={classNames(styles.LabelContainer, styles.RequestDetails, styles.PdfContainer, styles.Link)}
              role="presentation"
            >
              <span>{t('rental.historyInformation.viewPdf')}</span>
              <OpenInNewTabIcon fill={Colors.COLOR_SVG_NEUTRAL_BLACK_PRIMARY} />
            </div>
          </Col>
        </>
      )}
    </Row>
  );
};
