import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import {
  selectedRenterAddress,
  selectedRenterDetails,
  selectRenterApplication,
  selectVerifierAddressInformation,
} from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getAddressVerificationInformation, getVerifierAddresses } from 'redux/slices/agentSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as LocationIcon } from 'assets/svgs/LocationPinDropGreyIcon.svg';
import indexStyles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import styles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import { SectionTitle } from 'components/Agent/Applications/ApplicationDetails/components/shared';
import { renterRestrictions } from 'constants/restrictionConstants';
import { renterPresentAddress, renterPriorAddress } from 'helpers/renterHelper';
import { VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { VerifierAddressObject } from 'shared/types/agentTypes';
import { AddressInformationResponse } from 'shared/types/renterTypes';

import { QuestionsDropDown } from './components/QuestionsDropDown/QuestionsDropDown';
import { AddressContainer } from './components';

const RenterAddressHistory = (): JSX.Element => {
  const [hasVerifierAddress, setHasVerifierAddress] = useState(false);
  const currentOrganization = useSelector(selectedOrganization);
  const renterDetails = useSelector(selectedRenterDetails);
  const renterApplication = useSelector(selectRenterApplication);
  const addresses = useSelector(selectedRenterAddress);
  const verifierAddressesInformation = useSelector(selectVerifierAddressInformation);
  const dispatch = useDispatch<AppThunkDispatch>();
  const presentAddress = useMemo((): AddressInformationResponse | undefined => {
    if (addresses) {
      return renterPresentAddress(addresses);
    }

    return undefined;
  }, [addresses]);
  const verificationInformation = useMemo(
    () =>
      verifierAddressesInformation.find(
        (address) => address?.renterAddressInformation?.addressId === presentAddress?.id
      ),
    [verifierAddressesInformation, presentAddress?.id]
  );
  const priorAddress = useMemo((): AddressInformationResponse[] | undefined => {
    if (addresses) {
      return renterPriorAddress(addresses);
    }

    return undefined;
  }, [addresses]);

  useEffect(() => {
    if (currentOrganization.id && renterDetails.userId) {
      dispatch(getVerifierAddresses({ organizationId: currentOrganization.id, renterId: renterDetails.userId }))
        .unwrap()
        .then((res: VerifierAddressObject[]) => {
          if (res?.length > 0) {
            setHasVerifierAddress(true);
          }

          res?.map((verifierAddress: VerifierAddressObject) => {
            dispatch(
              getAddressVerificationInformation({
                organizationId: currentOrganization.id,
                renterId: renterDetails.userId,
                addressId: verifierAddress.id,
              })
            );
          });
        });
    }
  }, [currentOrganization.id, dispatch, renterDetails.userId]);

  if (hasVerifierAddress && isEmpty(verifierAddressesInformation)) {
    return <div />;
  }

  return (
    <div className={classNames(styles.container, styles.ApplicationDetails)}>
      <div className={styles.infoDiv}>
        <SectionTitle
          mainTitle={t('renter.ConfirmAddressHistory.addressHistory.')}
          iconElement={<LocationIcon width={20} height={20} />}
          description={t('renter.AddressEditModal.present')}
          shouldShowOptions
          shouldDrawLine
        />
        {presentAddress ? (
          <div>
            <AddressContainer verificationInformation={verificationInformation} address={presentAddress} isPresent />
            {verificationInformation?.submittedAt && (
              <QuestionsDropDown
                verificationInformation={verificationInformation}
                type={VerifierURLType.VOR}
                renterDetails={renterDetails}
              />
            )}
          </div>
        ) : (
          <div className={indexStyles.EmptySectionTitle}>{t('addressBox.iDoNotHaveAPresentAddress')}</div>
        )}
        {!renterRestrictions[renterApplication?.renterRole].priorAddress && (
          <>
            <SectionTitle description={t('renter.AddressEditModal.prior')} shouldShowOptions shouldDrawLine />
            {!!priorAddress?.length ? (
              priorAddress?.map((prior, index) => (
                <AddressContainer
                  key={`prior-address-${prior}-${index}`}
                  address={prior}
                  heading={`${t('application.address')} ${index + 1}`}
                  verificationInformation={verifierAddressesInformation?.find(
                    (address) => address?.renterAddressInformation?.addressId === prior.id
                  )}
                />
              ))
            ) : (
              <div className={indexStyles.EmptySectionTitle}>{t('renter.PriorAddress.iDoNotHave')}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RenterAddressHistory;
