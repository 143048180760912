import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { startCase } from 'lodash-es';

import indexStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import { RenterMinorProps } from 'shared/types/renterTypes';

import styles from './DescriptionCard.module.scss';

const DescriptionCard = ({ minor, customKey }: { minor: RenterMinorProps; customKey: number }): JSX.Element => {
  const { t } = useTranslation();
  const fullName = useMemo(() => startCase(`${minor.firstName ?? ''} ${minor.lastName ?? ''}`), [minor]);

  return (
    <div className={classNames(indexStyles.borderContainer, styles.container)}>
      <div className={styles.descriptionCard}>
        <span className={styles.faded}>
          {t('renter.paymentConfirmation.houseHoldDetails.minor')} {customKey + 1}
        </span>
        <div className={styles.topDiv}>
          <span className={styles.name}>{fullName}</span>
        </div>
        <div className={styles.bottomDiv}>
          <div className={styles.otherInfo}>
            <span className={styles.value}>{minor.birthDate as string}</span>
            <span className={styles.label}>{t('renter.paymentConfirmation.houseHoldDetails.minors.DOB')}</span>
          </div>
          <div className={styles.otherInfo}>
            <span className={styles.value}>{startCase(minor.relationship)}</span>
            <span className={styles.label}>{t('renter.paymentConfirmation.houseHoldDetails.relationship')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionCard;
