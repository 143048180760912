import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AbsoluteLegendsArray,
  InitialLegendsArray,
  tradePaymentIcons,
  VerticalLegendArray,
} from 'constants/reportConstants';

import { TradeIconGenerator } from '../../TradeIconGenerator/TradeIconGenerator';

import styles from './TradeIconLegend.module.scss';
export const TradeIconLegend = ({ paymentProfile }: { paymentProfile: string }): JSX.Element => {
  const { t } = useTranslation();
  const getCharCount = useMemo(
    () =>
      (char: string): number =>
        paymentProfile.split(char).length - 1,
    [paymentProfile]
  );

  return (
    <div className={styles.Container}>
      <div className={styles.HorizontalLegend}>
        <div className={styles.LegendIconContainer}>
          <span className={styles.DashIcon}>—</span>
          <span className={styles.LegendText}>{t('agent.creditReport.tradelineReport.legend.unknown')}</span>
        </div>
        {InitialLegendsArray.map(
          (char, index) =>
            (getCharCount(char) > 0 || AbsoluteLegendsArray.includes(char)) && (
              <div key={`legend-icon-${char}-${index}`} className={styles.LegendIconContainer}>
                <TradeIconGenerator
                  label={tradePaymentIcons[char].label}
                  backgroundColor={tradePaymentIcons[char].color}
                  shouldShowCount
                  count={getCharCount(char)}
                />
                <span className={styles.LegendText}>{tradePaymentIcons[char].iconInformation}</span>
              </div>
            )
        )}
      </div>

      {VerticalLegendArray.map(
        (char, index) =>
          paymentProfile.includes(char) && (
            <div key={`vertical-legend-icon-${char}-${index}`} className={styles.VerticalLegendIconContainer}>
              <TradeIconGenerator
                label={tradePaymentIcons[char].label}
                backgroundColor={tradePaymentIcons[char].color}
              />
              <span className={styles.LegendText}>{tradePaymentIcons[char].iconInformation}</span>
            </div>
          )
      )}
    </div>
  );
};
