import { ReactComponent as InfoIcon } from 'assets/svgs/InfoError.svg';

import styles from './StructurePropertiesHeader.module.scss';

export const StructuredPropertiesHeader = (): JSX.Element => (
  <div className={styles.Container}>
    <div className={styles.Heading}>Structure Properties</div>
    <div>100 Green Street San Francisco, Ca 94111</div>
    <div>info@structureproperties.com</div>
    <div>(415) 237-6240</div>
    <div>www.structureproperties.com</div>
    <div className={styles.Disclaimer}>
      <div className={styles.Icon}>
        <InfoIcon />
      </div>
      <div className={styles.Info}>
        Use the below general guidance to submit a complete file, and to prevent delays in processing. Consult with your
        housing provider for questions or concerns on specific rental properties before submitting.
      </div>
    </div>
  </div>
);
