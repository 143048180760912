import { AxiosResponse } from 'axios';

import { encryptData } from 'helpers/encrypt';
import {
  AddressVerificationInformationRequest,
  AgentProfessionalProfileRequest,
  AgentProfileInformation,
  AgentProfileInformationProps,
  EmploymentVerificationInformationRequest,
  GetLegacyApplicantsParams,
  LegacyApplicantsResponse,
  TransactionRequestProps,
  UpdateAgentProfessionalProfileResponse,
  UpdateApplicationFileStatusValues,
  VerificationInformationRequestProps,
  VerificationInformationResponse,
  VerifierAddressResponse,
  VerifierEmploymentResponse,
} from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import {
  AcceptInvitationResponse,
  InviteDetailsResponse,
  InviteProps,
  InviteResponse,
  MessageResponse,
  RentalRequest,
  SecurityAnswer,
} from 'shared/types/authType';
import { AxiosPromise } from 'shared/types/axiosPromise';
import { PropertyApplicantType } from 'shared/types/propertyType';
import { RenterProfileProps } from 'shared/types/renterTypes';

import baseService from './baseService';
export const agentServiceRoutes = {
  getRenterExperianReport: (organizationId: number, applicationId: number) =>
    `/renter/organizations/${organizationId}/applications/${applicationId}/report`,
  getRenterNovaReport: (organizationId: number, applicationId: number) =>
    `/renter/organizations/${organizationId}/applications/${applicationId}/nova-report`,
};
export const updateAgentDetails = (values: AgentProfileInformationProps): AxiosPromise => {
  if (values.socialSecurityNumber) {
    values.socialSecurityNumber = encryptData(values.socialSecurityNumber) as string | null;
  }

  return baseService.put<AxiosResponse<AgentProfileInformation>>('/agent/profile', values);
};

export const getAgentProfileInformation = (): AxiosPromise =>
  baseService.get<AxiosResponse<AgentProfileInformation>>('agent/profile');

export const getAgentGeneralInvitationDetails = (values: InviteProps): AxiosPromise =>
  baseService.get<AxiosResponse<InviteResponse>>('/agent/general-invitation-details', {
    params: values,
  });

export const getAgentSpecificInvitationDetails = (values: InviteProps): AxiosPromise =>
  baseService.get<AxiosResponse<InviteResponse>>('/agent/specific-invite', {
    params: values,
  });

export const acceptAgentSpecificInvitiation = (values: InviteProps): AxiosPromise =>
  baseService.post<AxiosResponse<AcceptInvitationResponse>>('/agent/accept-specific-invitation', values);

export const getInviteLink = (values: RentalRequest): AxiosPromise =>
  baseService.post<AxiosResponse<MessageResponse>>('/organization/invite', {
    ...values,
    renterFirstName: values.renterFirstName || null,
    renterLastName: values.renterLastName || null,
    renterEmail: values.renterEmail || null,
    renterPhone: (values.renterPhone && `+${values.renterPhone}`) || null,
    propertyId: Number(values.propertyId) || null,
  });

export const acceptAgentGeneralInvitiation = (values: InviteProps): AxiosPromise =>
  baseService.post<AxiosResponse<AcceptInvitationResponse>>('/agent/accept-general-invitation', values);

//only to be used with ngrok as ID is hard coded
export const getRenterDetails = (renterId: number, organizationId: number): AxiosPromise =>
  baseService.get<AxiosResponse<RenterProfileProps>>(
    `/renter/organizations/${organizationId}/renters/${renterId}/personal-information`
  );

export const getPropertyApplicant = ({ propertyId, applicationStatus }: PropertyApplicantType): AxiosPromise =>
  baseService.get<AxiosResponse<ApplicationType>>(
    `/renter/properties/${propertyId}/applications?status=${applicationStatus}`
  );

export const getRenterExperianReport = (organizationId: number, applicationId: number): AxiosPromise =>
  baseService.get(agentServiceRoutes.getRenterExperianReport(organizationId, applicationId));
export const getRenterNovaReport = (organizationId: number, applicationId: number): AxiosPromise =>
  baseService.get(agentServiceRoutes.getRenterNovaReport(organizationId, applicationId));

export const getRenterApplication = (organizationId: number, applicationId: number): AxiosPromise =>
  baseService.get<AxiosResponse<ApplicationType>>(
    `renter/organizations/${organizationId}/applications/${applicationId}`
  );
//To get details for a renter invite
export const getRenterInviteDetails = (inviteCode: string): AxiosPromise =>
  baseService.get<AxiosResponse<InviteDetailsResponse>>(`organization/renter/invites/${inviteCode}`);
//To accept the renter invite
export const acceptInvitation = (inviteCode: string): AxiosPromise =>
  baseService.post<AxiosResponse>(`/organization/invite/${inviteCode}/accept`);
export const submitAgentSecurityQuestion = (values: SecurityAnswer, orgId: number): AxiosPromise =>
  baseService.post<AxiosResponse<MessageResponse>>(`organization/organizations/${orgId}/answers`, values);
export const submitAgentOtp = (code: string): AxiosPromise => baseService.post<AxiosResponse>(`/agent/otp`, { code });
//To Resend Experian OTP
export const resendAgentOtp = (): AxiosPromise => baseService.get<AxiosResponse<MessageResponse>>('/agent/otp');
//To Fetch Renter Transaction/Financial Report
export const retrieveRenterTransactionReport = ({
  organizationId,
  renterId,
  applicationId,
}: TransactionRequestProps): AxiosPromise =>
  baseService.get<AxiosResponse>(
    `/finicity/organizations/${organizationId}/renters/${renterId}/applications/${applicationId}/transactions`
  );

export const getAddressVerificationInformation = ({
  organizationId,
  renterId,
  addressId,
}: AddressVerificationInformationRequest): AxiosPromise =>
  baseService.get<AxiosResponse<VerificationInformationResponse>>(
    `/renter/organizations/${organizationId}/renters/${renterId}/addresses/${addressId}`
  );

export const getVerifierAddresses = ({ organizationId, renterId }: VerificationInformationRequestProps): AxiosPromise =>
  baseService.get<AxiosResponse<VerifierAddressResponse>>(
    `/renter/organizations/${organizationId}/renters/${renterId}/addresses`
  );

export const getEmploymentVerificationInformation = ({
  organizationId,
  renterId,
  employmentId,
}: EmploymentVerificationInformationRequest): AxiosPromise =>
  baseService.get<AxiosResponse<VerificationInformationResponse>>(
    `/renter/organizations/${organizationId}/renters/${renterId}/present-employments/${employmentId}`
  );

export const getVerifierEmployments = ({
  organizationId,
  renterId,
}: VerificationInformationRequestProps): AxiosPromise =>
  baseService.get<AxiosResponse<VerifierEmploymentResponse>>(
    `/renter/organizations/${organizationId}/renters/${renterId}/present-employments`
  );

export const updateAgentProfessionalProfile = (
  values: AgentProfessionalProfileRequest,
  organizationId: number
): AxiosPromise =>
  baseService.put<AxiosResponse<UpdateAgentProfessionalProfileResponse>>(
    '/organization/update-agent-professional-profile',
    values,
    {
      headers: {
        organizationId: organizationId,
      },
    }
  );
//Get Leacy Applicants

export const getLegacyApplicants = ({ values, organizationId }: GetLegacyApplicantsParams): AxiosPromise =>
  baseService.get<AxiosResponse<LegacyApplicantsResponse>>(`/renter/legacy-applicants`, {
    params: values,
    headers: {
      organizationId: organizationId,
    },
  });
export const updateApplicationFileStatus = ({
  values,
  organizationId,
}: UpdateApplicationFileStatusValues): AxiosPromise =>
  baseService.put<AxiosResponse<UpdateAgentProfessionalProfileResponse>>('/renter/file-application', values, {
    headers: {
      organizationId: organizationId,
    },
  });
