import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';

import { ReactComponent as InfoIcon } from 'assets/svgs/InformationIcon.svg';
import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';
import { IntellirentPublicUrls } from 'shared/constants';
import { RequestVerificationInfoPanelProps } from 'shared/types/renterTypes';

export const RequestVerificationInfoPanel = ({
  infoDescription,
  isIconDisabled = false,
  isLinkDisabled = false,
  className,
}: RequestVerificationInfoPanelProps): JSX.Element => (
  <div className={classNames(styles.InfoPanel, className)}>
    {!isIconDisabled && (
      <span>
        <InfoIcon width={24} height={24} />
      </span>
    )}
    <span>
      {infoDescription}
      {!isLinkDisabled && (
        <Link
          className={styles.LinkContainer}
          target="_blank"
          rel="noopener noreferrer"
          to={IntellirentPublicUrls.CONTACT_SUPPORT}
        >
          {t('rental.historyInformation.contactSupport')}
        </Link>
      )}
    </span>
  </div>
);
