import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import styles from 'components/Renter/IdentityVerification/RenterDetails/shared/Popover.module.scss';
import { AnimalTooltipProps } from 'shared/types/renterTypes';

import tooltipStyles from './AnimalTooltip.module.scss';

export const AnimalTooltip = ({
  onDeleteHandler,
  onEditHandler,
  isShowingOverlay,
  setIsShowingOverlay,
  animalInformation,
  setAnimalToDelete,
  index,
}: AnimalTooltipProps): JSX.Element => {
  const { t } = useTranslation();
  const renderTooltip = (
    <Popover id="popover-positioned-bottom" hasDoneInitialMeasure>
      <Popover.Body className={classNames(styles.popoverBody, tooltipStyles.Container)}>
        <div className={styles.popoverWrapper}>
          <React.Fragment key={`animal-${animalInformation.id}-${index}`}>
            <button
              className={classNames(styles.popoverBtn, tooltipStyles.EditButton)}
              onClick={() => {
                onEditHandler(animalInformation.id ?? 0);
              }}
            >
              {`${t('renter.petsInformation.animalLabel.edit')} ${index}`}
            </button>
            <button
              className={classNames(styles.popoverBtn, tooltipStyles.DeleteButton)}
              onClick={() => {
                setAnimalToDelete(animalInformation.id ?? 0);
                onDeleteHandler();
              }}
            >
              {t('renter.petsInformation.delete')}
            </button>
          </React.Fragment>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger show={isShowingOverlay} trigger={['click']} key="bottom" placement="bottom" overlay={renderTooltip}>
      <span className={styles.overlayTrigger}>
        <ThreeDotsIcon
          onClick={() => {
            setIsShowingOverlay(!isShowingOverlay);
          }}
        />
      </span>
    </OverlayTrigger>
  );
};
