import classNames from 'classnames';

import { ReactComponent as TickIcon } from 'assets/svgs/GreenTick.svg';

import styles from './TradeIconGenerator.module.scss';
export const TradeIconGenerator = ({
  backgroundColor = '',
  label = '',
  shouldShowCount = false,
  count = 0,
}: {
  backgroundColor?: string;
  label?: string;
  count?: number;
  shouldShowCount?: boolean;
}): JSX.Element => (
  <>
    {label !== 'tick' && (
      <div
        className={classNames(styles.Container, { [styles.LabelContainer]: !!label })}
        style={{ background: backgroundColor }}
      >
        {label}
        {shouldShowCount && <span className={styles.Count}>{count}</span>}
      </div>
    )}
    {label === 'tick' && <TickIcon className={styles.TickIcon} />}
  </>
);
