import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsDirty } from 'redux/slices/renterSlice';

import { FormCloseWarningProps } from 'shared/types/sharedTypes';

const FormCloseWarning = ({ isDirty }: FormCloseWarningProps): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): string => {
      event.preventDefault();
      event.returnValue = '';

      return '';
    };

    if (isDirty) {
      dispatch(setIsDirty(true));
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      if (isDirty) {
        dispatch(setIsDirty(false));
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    };
  }, [isDirty, dispatch]);

  return <div />;
};

export default FormCloseWarning;
