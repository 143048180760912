import { useWindowSize } from 'hooks/useResize';
export const IsMobile = (): boolean => {
  const size = useWindowSize();

  return size.width <= 744;
};

export const IsMobileApplicantsPage = (): boolean => {
  const size = useWindowSize();

  return size.width <= 767;
};
