import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import renterIncomeStyles from 'components/Agent/Applications/ApplicationDetails/components/RenterIncome/RenterIncome.module.scss';
import { Ladder } from 'components/Renter/IdentityVerification/RenterDetails/shared/Ladder/Ladder';
import { QuestionAnswerKey } from 'constants/renterConstants';
import { BackgroundInfo } from 'shared/types/renterBackgroundInformation';

interface RenterQuestionCardProps {
  question: BackgroundInfo;
}
const RenterQuestionCard = ({ question }: RenterQuestionCardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.QuestionContainer}>{question.backgroundQuestion.question}</div>
      <div className={renterIncomeStyles.FinancialInstitutions}>
        <Ladder isTopPadding ladderContainerClassName={renterIncomeStyles.Ladder} />
        <div className={styles.ChoiceContainer}>
          <h5 className={styles.TitleContainer}>{t('renter.BackgroundInformation.responseLabel')}</h5>
          <h1 className={styles.ResponseContainer}>{question.answer ?? QuestionAnswerKey.NO}</h1>
        </div>
      </div>
      {question.answer === QuestionAnswerKey.YES && (
        <div className={renterIncomeStyles.FinancialInstitutions}>
          <Ladder ladderContainerClassName={renterIncomeStyles.Ladder} />
          <div className={classNames(styles.ChoiceContainer, styles.AnswerContainer)}>
            <div>{question.explanation} </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RenterQuestionCard;
