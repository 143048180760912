import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import { t } from 'i18next';
import { upperFirst } from 'lodash-es';
import { currentInviteId, isSaving, selectedOrganization } from 'redux/selectors/organizationSelector';
import { resetInviteId } from 'redux/slices/organizationSlice';
import { AppThunkDispatch } from 'redux/store';
import { agentInviteSchema } from 'schema/agentSchema';

import { ReactComponent as SecurityIcon } from 'assets/svgs/SecurityIcon.svg';
import TextCopyButton from 'components/Agent/components/shared/TextCopyButton/TextCopyButton';
import RCButton from 'components/shared/Button/Button';
import FormikField from 'components/shared/TextField/FormikTextField';
import { OrgazniationNavMenu } from 'constants/organizationConstants';
import { AgentInviteInitialValues, dispatchAgentInvite, generateLink } from 'shared/types/agentInvite';
import { UserInviteFormProps } from 'shared/types/organizationTypes';

import UserRoleDropdown from './UserRoleDropdown/UserRoleDropdown';

import styles from './UserInviteForm.module.scss';

const UserInviteForm = ({ setIsInviteSuccess }: UserInviteFormProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const currentOrganization = useSelector(selectedOrganization);
  const isLinkGenerating = useSelector(isSaving);
  const inviteId = useSelector(currentInviteId);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const stepName = searchParams.get('step') || OrgazniationNavMenu.USERS;
  const handleAgentInviteEmail = useCallback(
    async (
      values: typeof AgentInviteInitialValues,
      { resetForm }: FormikHelpers<typeof AgentInviteInitialValues>
    ): Promise<void> => {
      const params = {
        organizationId: currentOrganization.id,
        agentEmail: values.emailAddress,
        invitedRole: values.roleName,
        permissions: values.permissions,
        inviteId: inviteId ?? null,
        inviteLink: values.inviteLink,
      };

      dispatchAgentInvite(params, dispatch, setIsDataLoading, setIsInviteSuccess).then(() => {
        resetForm();
      });
    },
    [currentOrganization, dispatch, inviteId, setIsInviteSuccess]
  );

  useEffect(() => {
    dispatch(resetInviteId());
  }, [dispatch]);

  return (
    <div className={styles.formContainer}>
      <Formik
        initialValues={AgentInviteInitialValues}
        validationSchema={agentInviteSchema}
        onSubmit={handleAgentInviteEmail}
      >
        {({ isValid, dirty: isDirty, handleSubmit, values, setFieldValue }) => {
          const handleChange = (): void => {
            if (values.inviteLink.length) {
              setFieldValue('inviteLink', '');
            }
          };

          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <div className={styles.pageTitleContent}>
                <div className={styles.organizationName}>{upperFirst(currentOrganization.name)}</div>
                <div className={styles.selectedStepName}>{stepName}</div>
              </div>
              <div className={styles.mainContentContainer}>
                <div className={styles.leftContentContainer}>
                  <div className={styles.paragraphTitle}>{t('agent.userInviteForm.centralized')}</div>
                  <div className={styles.paragraphContent}>{t('agent.userInviteForm.connectAllStakeholders')}</div>
                  <RCButton variant="outline" className={styles.paragraphButton}>
                    {t('agent.userInviteForm.learnMore')}
                  </RCButton>
                </div>
                <div className={styles.rightContentContainer}>
                  <div className={styles.formTitle}>{t('agent.userInviteForm.inviteUser')}</div>
                  <div className={styles.roleContainer}>
                    <div className={styles.roleTitle}>{t('agent.userInviteForm.role')}</div>
                    <UserRoleDropdown isLoading={false} />
                  </div>
                  <div className={styles.emailContainer}>
                    <div className={styles.roleTitle}>{t('agent.userInviteForm.email')}</div>
                    <div className={classnames('sm-4 lg-6', styles.emailInnerContainer)}>
                      <FormikField
                        placeholder={t('agent.userInviteForm.enterEmail')}
                        name="emailAddress"
                        type="email"
                        className={styles.emailField}
                        disabled={isDataLoading}
                      />
                      <div className={styles.inviteButtonContainer}>
                        <RCButton
                          type="submit"
                          disabled={!isValid || !isDirty || isDataLoading}
                          className={styles.inviteButton}
                        >
                          {t('agent.userInviteForm.sendInvite')}
                        </RCButton>
                      </div>
                    </div>
                  </div>

                  {/* share link field */}
                  <div className={styles.shareLinkContainer}>
                    <span className={styles.roleTitle}>{t('agent.userInviteForm.shareLink')}</span>
                    <div className={styles.orgTitle}>
                      <SecurityIcon />
                      <span className={styles.lighterText}>{t('agent.userInviteForm.anOrganizationInvite')}</span>
                    </div>
                    <div className={classnames('sm-4 lg-6', styles.fieldsDiv)}>
                      <TextCopyButton
                        text={values.inviteLink}
                        tooltipText={t('organizations.inviteAgent.modal.copyButton.tooltip')}
                        buttonText=""
                      />
                      <div className={styles.inviteButtonContainer}>
                        <RCButton
                          type="button"
                          disabled={
                            !isValid ||
                            !isDirty ||
                            isDataLoading ||
                            isLinkGenerating ||
                            (!!inviteId && !!values.inviteLink.length)
                          }
                          className={styles.inviteButton}
                          onClick={() =>
                            generateLink(dispatch, values, setFieldValue, currentOrganization.id, inviteId)
                          }
                        >
                          {t('organizations.inviteAgent.generateLink')}
                        </RCButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UserInviteForm;
