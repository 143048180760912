import { t } from 'i18next';

import indexStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';

import styles from '../PersonalProfile.module.scss';

type RenterSSNProps = {
  ssn: string;
};

const RenterSSN = ({ ssn }: RenterSSNProps): JSX.Element => (
  <div className={styles.container}>
    <div className={indexStyles.secondaryHeading}>{t('application.ssn')}</div>
    <span className={`${styles.ssnNumber} fs-mask`}>{ssn}</span>
  </div>
);

export default RenterSSN;
