import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { amenitiesList } from 'redux/selectors/propertySelector';
import { updateAmenitiesFeature } from 'redux/slices/propertySlice';

import { ReactComponent as CrossIcon } from 'assets/svgs/CrossIcon.svg';
import { AddPropertyParams, Tag } from 'shared/types/propertyType';

import { TagsContainer } from './AmenitiesFeature';

import amenitiesStyles from './AmenitiesFeature.module.scss';
export type Props = {
  data: Tag[];
  title: string;
  type: string;
  handleBlur: (params: AddPropertyParams) => void | undefined;
  isPropertyEdit?: boolean;
};
const CustomChip = ({ data, title, type, handleBlur, isPropertyEdit = true }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const amenitiesCustomList = useSelector(amenitiesList);
  const hasAnySelected = data.some((el) => el.isSelected === true);
  const onClick = (feature: Tag): void => {
    const removedCrossTag = amenitiesCustomList
      .filter((el) => !(el.name === feature.name && el.type === type))
      .map((el) => ({ ...el, isAnimation: false }));
    // isAnimation false so that it not add fading effect to custom created feature, once the animation done for first time.
    const selectedTag = removedCrossTag.filter((el) => el.isSelected);
    const unitList = selectedTag.filter((el) => el.type === 'unit').map((el) => el.name);
    const propertyList = selectedTag.filter((el) => el.type === 'property').map((el) => el.name);

    handleBlur({
      propertyAmenities: propertyList,
      unitAmenities: unitList,
    });
    dispatch(updateAmenitiesFeature(removedCrossTag));
  };

  useEffect(() => {
    dispatch(
      updateAmenitiesFeature(amenitiesCustomList.map((el) => (el.isAnimation ? { ...el, isAnimation: false } : el)))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={amenitiesStyles.containerPadding}>
      {hasAnySelected && (
        <TagsContainer title={title}>
          <div className={classNames(amenitiesStyles.allTags, amenitiesStyles.allFeatureTags)}>
            {data.map((feature, key) =>
              feature?.isSelected ? (
                <div key={key} className={classNames({ [amenitiesStyles.tagContainer]: feature?.isSelected })}>
                  <div className={classNames(amenitiesStyles.tag, amenitiesStyles.selectedTags)}>
                    {isPropertyEdit && (
                      <CrossIcon onClick={() => onClick(feature)} className={amenitiesStyles.crossIcon} />
                    )}
                    <div
                      className={classNames(amenitiesStyles.tagName, {
                        [amenitiesStyles.customCreatedFeature]: feature?.isAnimation,
                      })}
                    >
                      {feature.name}
                    </div>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </TagsContainer>
      )}
    </div>
  );
};

export default CustomChip;
