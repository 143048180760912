import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { isPMCUser } from 'helpers/agentHelper';
import { AgentInviteInitialValues, AgentUserRoles, onRoleChange, visibleOptions } from 'shared/types/agentInvite';

import styles from '../AgentInviteModalBody.module.scss';

interface AgentRoleDropdownProps {
  isLoading: boolean;
}
const AgentRoleDropdown = ({ isLoading }: AgentRoleDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<typeof AgentInviteInitialValues>();
  const currentOrganization = useSelector(selectedOrganization);
  const currentAgentRole = currentOrganization.roleName || AgentUserRoles.AGENT;
  const agentRoleOptions = useMemo(
    () =>
      visibleOptions(
        currentAgentRole,
        isPMCUser(currentOrganization.housingProviderCategory) || currentOrganization.roleName !== AgentUserRoles.OWNER
      ),
    [currentOrganization.housingProviderCategory, currentAgentRole, currentOrganization.roleName]
  );
  const isDisabled = isEmpty(agentRoleOptions); // Check if agentRoleOptions is empty
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customOption = (props: any): JSX.Element => {
    const { label, data } = props;

    return (
      <components.Option {...props}>
        <div>
          <div>
            <span className={styles.label}>{label}</span>
          </div>
          <small className={styles.description}>{data.description}</small>
        </div>
      </components.Option>
    );
  };

  return (
    <div className={styles.selectContainer}>
      <ReactSelect
        placeholder={t('organizations.inviteAgent.modal.field.selectRole')}
        isSearchable={false}
        options={agentRoleOptions}
        isFocusedFontSize={600}
        isDisabled={isDisabled || isLoading}
        className={isDisabled ? styles.disabled : ''}
        onChange={async (selectedOption) => {
          onRoleChange(
            selectedOption,
            setFieldValue,
            isPMCUser(currentOrganization.housingProviderCategory) ||
              currentOrganization.roleName !== AgentUserRoles.OWNER
          );
          setFieldValue('inviteLink', '');
        }}
        customOption={customOption}
        isClearable
      />
    </div>
  );
};

export default AgentRoleDropdown;
