import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { backgroundInformation } from 'redux/selectors/renterSelector';
import { BackgroundInformationSchema } from 'schema/renterSchema';

import { ReactComponent as QuestionAnswerIcon } from 'assets/svgs/QuestionAnswer.svg';
import styles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import { BackgroundInformationValues } from 'shared/types/renterBackgroundInformation';

import Title from '../shared/Title/Title';

import BackgroundQuestionsCard from './BackgroundQuestionsCard';

const BackgroundQuestionsVerification = (): JSX.Element => {
  const { t } = useTranslation();
  const renterBackgroundQuestions = useSelector(backgroundInformation);
  const [questions, setQuestions] = useState<BackgroundInformationValues>({} as BackgroundInformationValues);

  useEffect(() => {
    setQuestions({ backgroundInformation: renterBackgroundQuestions });
  }, [renterBackgroundQuestions]);

  return (
    <div id="BackgroundQuestion">
      <Title mainTitle={t('renter.BackgroundInformation.headingTitle')} iconElement={<QuestionAnswerIcon />} />
      <Formik
        initialValues={questions}
        validationSchema={BackgroundInformationSchema}
        onSubmit={() => console.log()}
        enableReinitialize
      >
        {({ values }) =>
          values?.backgroundInformation?.map((question, index) => (
            <div key={`Background-Question-${question}-${question.id}`} className={styles.titleMargin}>
              <BackgroundQuestionsCard index={index} />
            </div>
          ))
        }
      </Formik>
    </div>
  );
};

export default BackgroundQuestionsVerification;
