import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { renterPresentEmployments } from 'redux/selectors/renterSelector';

import { ReactComponent as IncomeIcon } from 'assets/svgs/WorkIconSolid.svg';
import indexStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import styles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import Title from 'components/Renter/IdentityVerification/RenterDetails/shared/Title/Title';
import EditPresentIncome from 'components/Renter/RenterPresentSourceOfIncome/RenterPresentIncomeSummary/EditPresentIncome/EditPresentIncome';
import { SourceOfIncomeKeys } from 'constants/renterConstants';
import { closePopOverOnClick } from 'helpers/agentHelper';
import { formatEmploymentForFrontEnd } from 'helpers/renterHelper';
import { renterRoutes } from 'shared/routes';
import { EditEmploymentTypes } from 'shared/types/renterEmploymentInformation';
import { NextRouteProp } from 'shared/types/renterTypes';

import DescriptionCard from './DescriptionCard/DescriptionCard';
import PresentIncomeTooltip from './PresentIncomeTooltip/PresentIncomeTooltip';

const PresentIncome = ({ next }: NextRouteProp): JSX.Element => {
  const navigate = useNavigate();
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const { t } = useTranslation();
  const presentEmployments = useSelector(renterPresentEmployments);
  const presentEmploymentsCount = presentEmployments?.length;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customIndex, setCustomIndex] = useState(0);
  const [selectedPresent, setSelectedPresent] = useState({});
  const handleAddInfo = useCallback((): void => {
    navigate(
      renterRoutes.generateRenterPresentSourceOfIncome(
        currentApplicationId,
        next ?? renterRoutes.generateRenterPaymentVerification(currentApplicationId),
        SourceOfIncomeKeys.PRESENT
      )
    );
  }, [currentApplicationId, navigate, next]);
  const handleEditInfo = useCallback(
    (currentIndex: number, selectedId?: number): void => {
      closePopOverOnClick();
      const currentPrior = presentEmployments.find(({ id }) => id === selectedId);

      if (currentPrior) {
        setSelectedPresent(formatEmploymentForFrontEnd(currentPrior));
        setIsModalVisible(true);
        setCustomIndex(currentIndex);
      }
    },
    [presentEmployments]
  );
  const onHideHandler = useCallback((): void => {
    setIsModalVisible(false);
  }, []);

  if (!presentEmployments) {
    return (
      <div className={styles.titleMargin}>
        <Title
          mainTitle={t('addressBox.present')}
          iconElement={<IncomeIcon />}
          shouldShowOptions
          optionElement={<PresentIncomeTooltip handleAddInfo={handleAddInfo} handleEditInfo={handleEditInfo} />}
        />
        <Title mainTitle={t('renter.paymentConfirmation.sourceOfIncome.noPresent')} />
      </div>
    );
  }

  return (
    <div className={classNames(indexStyles.titleMargin, styles.container)} id="present-income">
      <Title
        mainTitle={t('addressBox.present')}
        iconElement={<IncomeIcon />}
        shouldShowOptions
        optionElement={<PresentIncomeTooltip handleAddInfo={handleAddInfo} handleEditInfo={handleEditInfo} />}
      />
      {isModalVisible && (
        <EditPresentIncome
          data={selectedPresent}
          onHide={onHideHandler}
          heading={`${t('renter.paymentConfirmation.sourceOfIncome.editSource.present')} ${customIndex}`}
        />
      )}
      {presentEmployments.map((employment, index) => (
        <Formik
          key={employment.id}
          initialValues={employment}
          onSubmit={() => {
            console.log('formValues');
          }}
        >
          <Form>
            <DescriptionCard
              employment={formatEmploymentForFrontEnd(employment)}
              indexValue={index}
              key={`present-income-${index}`}
              isSelfEmployed={employment.employmentSubType === EditEmploymentTypes.SELF_EMPLOYED}
              isTypeOther={employment.employmentSubType === EditEmploymentTypes.OTHERS}
              hasOnlyOne={presentEmploymentsCount === 1}
            />
          </Form>
        </Formik>
      ))}
    </div>
  );
};

export default PresentIncome;
