import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { selectedRenterDetails } from 'redux/selectors/agentSelector';
import { renterAnimalSchema } from 'schema/renterSchema';

import ImageIcon from 'assets/images/ImageIcon.png';
import { ReactComponent as DocumentIcon } from 'assets/svgs/DocumentIcon.svg';
import { ReactComponent as AnimalIcon } from 'assets/svgs/petsIconOneGrayPaw.svg';
import styles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';
import AnimalDetailsInformationCard from 'components/shared/AnimalDetailsInformationCard/AnimalDetailsInformationCard';
import Button from 'components/shared/Button/Button';
import { imageUrl } from 'helpers/renterHelper';
import { AnimalInformationInitialValues } from 'shared/types/renterTypes';

import { Documents, SectionTitle } from '../shared';
const RenterAnimals = (): JSX.Element => {
  const { t } = useTranslation();
  const { furryFriends } = useSelector(selectedRenterDetails);

  return (
    <Formik
      enableReinitialize
      initialValues={AnimalInformationInitialValues}
      validationSchema={renterAnimalSchema}
      onSubmit={() => {
        console.log('formik values');
      }}
    >
      <div className={classNames(styles.container, styles.ApplicationDetails)}>
        <div className={styles.infoDiv}>
          <SectionTitle
            mainTitle={t('renter.paymentConfirmation.animals.title')}
            iconElement={<AnimalIcon height={24} width={24} />}
            description={!!furryFriends && t('renter.petsInformation.renterResume.description')}
            count={furryFriends?.length}
            shouldShowCount={!isEmpty(furryFriends)}
            // optionElement={<ThreeDotsIcon width={24} height={24} />}
            shouldShowOptions
            shouldDrawLine={!!furryFriends}
          />
          {isEmpty(furryFriends) && (
            <div className={styles.EmptySectionTitle}>{t('renter.paymentConfirmation.animals.noAnimals')}</div>
          )}

          {furryFriends?.map((pet, index) => (
            <div key={`renter-resume-animal-${pet.id}`}>
              <div className={styles.infoDiv}>
                <span className={styles.subTitle}>{`${t('renter.petsInformation.animalLabel')} ${index + 1}`}</span>
                <AnimalDetailsInformationCard
                  animalInformation={pet}
                  url={imageUrl(pet) ?? ImageIcon}
                  shouldShowEditIcon={false}
                  shouldShowPill
                  index={index + 1}
                />
                {/* {!!pet?.images?.length && (
                  <Documents
                    documents={pet.images}
                    title={t('agent.applicants.renterResume.sourceOfIncome.incomeReport.supportingImages')}
                    uploadButton={
                      <Button type="button" variant="outline" className={styles.SmallButton}>
                        {t('agent.applicants.renterResume.sourceOfIncome.incomeReport.supportingDocuments.add')}
                      </Button>
                    }
                    icon={<DocumentIcon width={32} height={32} />}
                  />
                )} */}
                {!!pet?.documents?.length && (
                  <Documents
                    documents={pet.documents}
                    title={t('agent.applicants.renterResume.sourceOfIncome.incomeReport.supportingDocuments')}
                    uploadButton={
                      <Button type="button" variant="outline" className={styles.SmallButton}>
                        {t('agent.applicants.renterResume.sourceOfIncome.incomeReport.supportingDocuments.add')}
                      </Button>
                    }
                    icon={<DocumentIcon width={32} height={32} />}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Formik>
  );
};

export default RenterAnimals;
