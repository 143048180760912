import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { selectedAgent } from 'redux/selectors/renterSelector';

import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import RCButton from 'components/shared/Button/Button';
import { formatName } from 'helpers/helper';
import { renterRoutes } from 'shared/routes';
import {
  CustomPropertySteps,
  InviteDataKeys,
  InviteType,
  navigateToCreateCustomProperty,
  navigateToSelectProperty,
} from 'shared/types/renterApplication';

import styles from '../RenterPropertySelection.module.scss';
export const AgentSpecific = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { applicationId } = useParams();
  const inviteCode = searchParams.get(InviteDataKeys.INVITE_CODE) || '';
  const inviteAgent = useSelector(selectedAgent);

  useEffect(() => {
    if (applicationId) {
      navigate(renterRoutes.generateViewRenterCriteriaUrl(applicationId));
    }
  }, [applicationId, navigate]);

  return (
    <div>
      <RenterIconInfoContainer />
      <h2 className={styles.RenterApplicationHeading}>
        {t('renter.application.agentSpecific.introduction').replace(
          'AGENT_NAME',
          formatName(inviteAgent?.firstName, inviteAgent?.lastName)
        )}
        &nbsp;
        <span className={styles.HighlightedText}>{t('renter.application.title')}</span>.&nbsp;
        {t('renter.application.property.question')}
      </h2>
      <div className={styles.ContinueButtonContainer}>
        <div className={styles.SelectProperty}>
          <RCButton
            className={classNames(styles.Button, styles.PrimaryButton)}
            variant="outline"
            onClick={() => {
              navigateToSelectProperty(navigate, InviteType.AGENT_SPECIFIC, inviteCode);
            }}
          >
            {t('renter.application.agentGeneral.selectProperty')}
          </RCButton>
        </div>
        <div className={styles.SelectProperty}>
          <RCButton
            className={classNames(styles.Button, styles.PrimaryButton)}
            onClick={() =>
              navigateToCreateCustomProperty({
                navigate,
                inviteType: InviteType.AGENT_SPECIFIC,
                inviteCode,
                stepName: CustomPropertySteps.CREATE_PROPERTY,
              })
            }
            variant="outline"
          >
            {t('renter.application.customInformation')}
          </RCButton>
        </div>
      </div>
    </div>
  );
};
