import { StyleSheet, Text, View } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  Container: {
    width: '100%',
  },
  Label: {
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 500,
    marginBottom: '8px',
  },
  TextValue: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 600,
  },
  Divider: {
    width: '100%',
    marginTop: '3px',
    borderBottom: '1px solid #8D9899',
  },
});

export const PDFValueField = ({ label, value }: { label: string; value?: string }): JSX.Element => (
  <View style={styles.Container} wrap={false}>
    <Text style={styles.Label}>{label}</Text>
    {!!value && <Text style={styles.TextValue}>{value}</Text>}
    <View style={styles.Divider}></View>
  </View>
);
