import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { VerificationPageProps } from 'pages/AgentPofileForm/types';

import { ReactComponent as ErrorIcon } from 'assets/svgs/ErrorOutlineIcon.svg';
import { ReactComponent as IntellirentIcon } from 'assets/svgs/IntellirentIcon.svg';
import { ReactComponent as IntellirentLogo } from 'assets/svgs/intellirentLogo.svg';
import { ReactComponent as LockIcon } from 'assets/svgs/LockIcon.svg';
import { ReactComponent as LoadingIcon } from 'assets/svgs/WhiteLoadingIcon.svg';
import RCButton from 'components/shared/Button/Button';
import { OtpInputField } from 'components/shared/OTPInputField/OTPInputField';
import { parseResponseErrors } from 'helpers/helper';
import { submitVerificationCode } from 'services/renterService';
import { VERIFICATION_CODE_LENGTH } from 'shared/constants';

import styles from './VerificationPage.module.scss';

export const VerificationPage = ({
  setIsUserVerified,
  heading,
  subHeading,
  buttonText,
}: VerificationPageProps): JSX.Element => {
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [params] = useSearchParams();
  const token = params.get('token');
  const [isLoading, setIsLoading] = useState(false);
  const onCodeChangeHandler = (code: string): void => {
    setVerificationCode(code);
  };
  const onContinueClickHandler = useCallback(() => {
    setErrorMessage('');
    setIsLoading(true);
    submitVerificationCode(verificationCode, token || '')
      .then(() => {
        setErrorMessage('');
        setIsUserVerified(true);
      })
      .catch((error) => {
        setErrorMessage(parseResponseErrors(error));
        setVerificationCode('');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setIsUserVerified, token, verificationCode]);

  return (
    <div className={styles.Container}>
      <div className={styles.LogoContainer}>
        <IntellirentIcon />
        <IntellirentLogo />
      </div>
      <div className={styles.VerificationContainer}>
        <div className={styles.BodyContainer}>
          <div className={styles.Header}>
            <LockIcon />
            <div className={styles.Heading}>{heading}</div>
          </div>
          <div className={styles.Content}>
            <div className={styles.ContentHeading}>{subHeading}</div>
            <div className={styles.CodeContainer}>
              <OtpInputField hasAutoFocus length={VERIFICATION_CODE_LENGTH} onChangeOTP={onCodeChangeHandler} />
              {!isEmpty(errorMessage) && (
                <div className={styles.ErrorText}>
                  <ErrorIcon className={styles.ErrorIcon} />
                  {errorMessage}
                </div>
              )}
            </div>
          </div>
          <RCButton
            onClick={onContinueClickHandler}
            disabled={verificationCode.length !== VERIFICATION_CODE_LENGTH || isEmpty(token)}
            className={styles.Button}
          >
            {buttonText || t('renter.vehiclesInformation.form.continue')}
            {isLoading && <LoadingIcon className={styles.LoadingIcon} />}
          </RCButton>
        </div>
      </div>
    </div>
  );
};
