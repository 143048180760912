import { Dispatch } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { propertyLeaseDurations } from 'redux/selectors/propertySelector';

import { ReactComponent as DropDownFillArrow } from 'assets/svgs/dropDownFillArrow.svg';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import FormikField from 'components/shared/TextField/FormikTextField';
import { bathOptions, bedsInfo } from 'constants/propertyConstants';
import { AddPropertyParams } from 'shared/types/propertyType';

import UtilitySelect from './UtilsSelect';

import styles from './AddProperty.module.scss';
const UnitSpecs = ({
  setIsCustomUtilityClicked,
  isCustomUtilityClicked,
  handleBlur,
}: {
  setIsCustomUtilityClicked: Dispatch<React.SetStateAction<boolean>>;
  isCustomUtilityClicked: boolean;
  handleBlur: (params: AddPropertyParams) => void;
}): JSX.Element => {
  const propertyParkingOptionList = useSelector(propertyLeaseDurations);

  return (
    <div className={styles.unitSpecs}>
      <Row>
        <h1 className={styles.formHeading}>Unit Specs</h1>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12} className={styles.customTextFieldWithLabel}>
          <FormikField
            prefixString="$"
            type="number"
            name="monthlyRentAmount"
            label="Rent"
            handleBlur={handleBlur}
            onWheel={(event) => event.currentTarget.blur()}
          />
        </Col>
        <Col lg={6} md={12} sm={12} className={styles.customTextFieldWithLabel}>
          <FormikField
            prefixString="$"
            type="number"
            name="depositAmount"
            label="Deposit (Optional)"
            handleBlur={handleBlur}
            onWheel={(event) => event.currentTarget.blur()}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormikSelectField
            name="bedroomsCount"
            options={bedsInfo}
            placeholder="Select"
            dropDownIcon={<DropDownFillArrow />}
            dropDownIconContainer={styles.dropDownFillArrow}
            hasFloatingLabel={true}
            floatingLabel="Beds"
            isCustomOptions={true}
            handleBlur={handleBlur}
          />
        </Col>

        <Col lg={6} md={12} sm={12} className={styles.bedsContainer}>
          <FormikSelectField
            name="bathroomsCount"
            options={bathOptions}
            placeholder="Select"
            dropDownIcon={<DropDownFillArrow />}
            dropDownIconContainer={styles.dropDownFillArrow}
            hasFloatingLabel={true}
            floatingLabel="Baths"
            handleBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12} className={styles.customTextFieldWithLabel}>
          <FormikField
            name="squareFootage"
            type="number"
            label="Square Feet (Optional)"
            className={styles.squareFootageField}
            handleBlur={handleBlur}
            onWheel={(event) => event.currentTarget.blur()}
          />
        </Col>
        <Col lg={6} md={12} sm={12} className={styles.propertyName}>
          <FormikSelectField
            name="propertyLeaseDuration"
            options={propertyParkingOptionList}
            isCustomOptions={true}
            hasFloatingLabel={true}
            floatingLabel="Lease Duration (Optional)"
            placeholder="Select"
            dropDownIcon={<DropDownFillArrow />}
            dropDownIconContainer={styles.dropDownFillArrow}
            handleBlur={handleBlur}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <UtilitySelect
            name="propertyUtility"
            floatingLabel="Utilities Included (Optional)"
            placeholder="Select"
            setIsCustomUtilityClicked={setIsCustomUtilityClicked}
            isCustomUtilityClicked={isCustomUtilityClicked}
            handleBlur={handleBlur}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UnitSpecs;
