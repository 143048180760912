import { useTranslation } from 'react-i18next';

import { ReactComponent as BudgetIcon } from 'assets/svgs/BudgetIcon.svg';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import { formatAmount } from 'helpers/user';
import { BudgetCapsuleProps } from 'shared/types/applicantsType';

import styles from './BudgetCapsule.module.scss';

export const BudgetCapsule = ({ budgetAmount }: BudgetCapsuleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.CapsuleContainer}>
      <AgentPills
        pillContainerClassName={styles.PillContainer}
        pillsData={[{ heading: `$${formatAmount(budgetAmount)}`, type: PillTypes.MIDNIGHT_STEEL }]}
      />
      <div className={styles.LabelContainer}>
        <BudgetIcon />
        <div className={styles.CapsuleLabel}>{t('application.budget')}</div>
      </div>
    </div>
  );
};
