import { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useField } from 'formik';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { selectPropertyId } from 'redux/selectors/propertySelector';
import { uploadPropertyImage } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';

import Cropper from 'components/shared/Cropper/Cropper';
import Dropzone, { PreviewFile } from 'components/shared/DropZone/Dropzone';
import { ApiUploadImage, IUploadImageObject } from 'shared/types/imageUploadTypes';

// import TourForm from './MediaModal/TourForm';
import styles from './AddProperty.module.scss';
const MediaInformation = (): JSX.Element => {
  const [, value, { setValue }] = useField('propertyImages');
  const [selectedFile, setSelectedFile] = useState<PreviewFile>();
  const [acceptedFiles, setAcceptedFiles] = useState<PreviewFile[]>((value.value && value.value) || []);
  // const [urlInputArray] = useState<JSX.Element[]>([<TourForm urlName="urlName[0]" tourName="tourName[0]" />]);
  const { propertyId } = useParams();
  const currentPropertyId = useSelector(selectPropertyId) || Number(propertyId);
  const [apiImagesFile, setApiImageFile] = useState<ApiUploadImage>();
  const [isApiCalled, setIsApiCalled] = useState(false);
  const currentOrganization = useSelector(selectedOrganization);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const onToggleFile = (file: PreviewFile): void => {
    setSelectedFile(file);
  };
  const onCroppedClicked = useCallback(
    (file: PreviewFile): void => {
      if (selectedFile) {
        const data = acceptedFiles.map((currentFile: PreviewFile) =>
          selectedFile.name === currentFile.name
            ? Object.assign(currentFile, {
                preview: file.preview,
                dimension: file.dimension,
              })
            : currentFile
        );

        setAcceptedFiles(data);
        setSelectedFile(undefined);
      }
    },
    [acceptedFiles, selectedFile]
  );
  const onClose = useCallback((): void => {
    setSelectedFile(undefined);
  }, []);
  const updateAgentDetails = useCallback(
    (files: ApiUploadImage): void => {
      const isFileExist = (checkCurrentFile: string): IUploadImageObject | undefined =>
        files.propertyImages?.find((el: IUploadImageObject) => el.fileName === checkCurrentFile);
      const updatedAcceptedFileListAfterUpload = acceptedFiles.map((el) => {
        const matchedFile = isFileExist(el.name);

        if (matchedFile) {
          return Object.assign(el, {
            isLoading: false,
            imageId: (matchedFile && matchedFile.id) || 0,
          });
        }

        return el;
      });

      setValue(updatedAcceptedFileListAfterUpload);
      setAcceptedFiles(updatedAcceptedFileListAfterUpload);
      setIsApiCalled(false);
    },
    [acceptedFiles, setValue]
  );
  const onUploadImageFile = useCallback(
    (file: PreviewFile): void => {
      const currentFile = new FormData();

      currentFile.append('image', file);
      dispatch(
        uploadPropertyImage({
          file: currentFile,
          propertyId: currentPropertyId,
          organizationId: currentOrganization.id,
        })
      )
        .unwrap()
        .then((files: ApiUploadImage) => {
          setApiImageFile(files);
          setIsApiCalled(true);
        });
    },
    [currentOrganization.id, currentPropertyId, dispatch]
  );

  useEffect(() => {
    if (value.value && !!value.value.length) {
      // isApiMade
      value.value.map((el: PreviewFile) => el.isLoading && !el.isApiMade && onUploadImageFile(el));
      acceptedFiles.map((currentFile: PreviewFile) =>
        Object.assign(currentFile, {
          isApiMade: true,
          imageId: currentFile.imageId,
        })
      );
    }
  }, [acceptedFiles, onUploadImageFile, value.value]);

  useEffect(() => {
    if (isApiCalled) {
      updateAgentDetails(apiImagesFile as ApiUploadImage);
    }
  }, [apiImagesFile, isApiCalled, updateAgentDetails]);

  return (
    <div className={styles.amenities}>
      <Row>
        <h1 className={styles.formHeading}>{t('agent.addProperty.media')}</h1>
      </Row>
      <Row>
        <p className={styles.amenitiesDescription}>{t('agent.addProperty.mediaInfo')}</p>
      </Row>
      <Row className={styles.mediaContainer}>
        <h1 className={styles.formHeading}>{t('agent.addProperty.photos')}</h1>
        <Dropzone
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          onToggleFile={onToggleFile}
          acceptedFiles={acceptedFiles}
          setAcceptedFiles={setAcceptedFiles}
        />
        {selectedFile && <Cropper onClose={onClose} file={selectedFile} onCroppedClicked={onCroppedClicked} />}
        {/* <h1 className={styles.formHeading}>{t('agent.addProperty.video')}</h1>
        <p className={styles.videoText}>{t('agent.addProperty.mediaCopyPaste')}</p> */}
      </Row>
      {/* {urlInputArray.map((tourInfo, index) => (
        <div key={index}>{tourInfo}</div>
      ))} */}
    </div>
  );
};

export default MediaInformation;
