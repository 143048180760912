import classNames from 'classnames';
import { format } from 'date-fns';

import { dateFormatFrontend } from 'constants/calendarConstants';
import { formatAmountToUsdString } from 'helpers/helper';
import { ReportRowProps } from 'shared/types/reportTypes';

import styles from './ReportRow.module.scss';

export const ReportRow = ({
  transactionDate,
  description,
  amount,
  isGreyBackground = false,
}: ReportRowProps): JSX.Element => (
  <div className={classNames(styles.Container, { [styles.GreyContainer]: isGreyBackground })}>
    <div className={styles.Month}>{format(new Date(transactionDate), dateFormatFrontend)}</div>
    <div className={styles.Description}>{description}</div>
    <div className={styles.Amount}>{formatAmountToUsdString(amount)}</div>
  </div>
);
