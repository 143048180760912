import { useState } from 'react';
import { useCallback } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import styles from './SearchBar.module.scss';

export interface ISearchProps {
  className?: string;
  placeholder?: string;
  value?: string;
  handleSearch?: (target: string) => void;
}
const SearchBar = ({ className, value, handleSearch, placeholder = 'Search all...' }: ISearchProps): JSX.Element => {
  const [searchInput, setSearchInput] = useState(value ?? '');
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchInput(event.target.value);
      handleSearch && handleSearch(event.target.value);
    },
    [handleSearch]
  );

  return (
    <div className={classNames(styles.search, className)}>
      <FontAwesomeIcon icon={faSearch} data-testid="search-icon" className={classNames(styles.fasearch)} />
      <input
        type="search"
        value={searchInput}
        className="form-control"
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default SearchBar;
