import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { AddressInitialValuesProps } from 'shared/types/renterTypes';

import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

import styles from './CustomRangeDatePicker.module.scss';

interface ICustomRangeDateProps {
  leftCalendarName: string;
  rightCalendarName: string;
  leftCalenderStartDate?: string | undefined;
  rightCalenderStartDate?: string | undefined;
  leftCalenderEndDate?: string | undefined;
  rightCalenderEndDate?: string | undefined;
  isToPresent?: boolean;
  floatingLabelStyling?: string;
  defaultSelected?: Date;
  leftCalanderFloatingLabel?: string;
  rightCalanderFloatingLabel?: string;
}

const CustomRangeDatePicker = ({
  leftCalendarName,
  rightCalendarName,
  isToPresent = false,
  leftCalenderStartDate,
  leftCalenderEndDate,
  rightCalenderStartDate,
  rightCalenderEndDate,
  floatingLabelStyling,
  defaultSelected,
  leftCalanderFloatingLabel,
  rightCalanderFloatingLabel,
}: ICustomRangeDateProps): JSX.Element => {
  const { setFieldValue } = useFormikContext<AddressInitialValuesProps>();

  useEffect(() => {
    if (isToPresent) {
      setFieldValue(rightCalendarName, undefined);
    }
  }, [isToPresent, rightCalendarName, setFieldValue]);

  return (
    <div className={styles.rangeDatePickerContainer}>
      <div className={styles.leftRangeDatePicker}>
        <CustomDatePicker
          name={leftCalendarName}
          isIconOnLeft={true}
          floatingLabel={leftCalanderFloatingLabel ?? 'From'}
          placeholder="MM DD YYYY"
          startDate={leftCalenderStartDate}
          endDate={leftCalenderEndDate}
          defaultSelected={defaultSelected}
        />
      </div>

      <div className={styles.rightRangeDatePicker}>
        <span className={styles.verticalLine}></span>
        <div className={styles.rightDatePickerContainer}>
          <CustomDatePicker
            name={rightCalendarName}
            isIconOnLeft={true}
            isShowPresent={isToPresent}
            floatingLabel={rightCalanderFloatingLabel ?? 'To'}
            placeholder="MM DD YYYY"
            startDate={rightCalenderStartDate}
            endDate={rightCalenderEndDate}
            floatingLabelStyling={floatingLabelStyling}
            defaultSelected={defaultSelected}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomRangeDatePicker;
