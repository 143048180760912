import { NavigateFunction } from 'react-router-dom';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { capitalize } from 'lodash-es';
import { setInviteCode } from 'redux/slices/authSlice';
import { getAgentOrganizations, getLastVisitedOrganization } from 'redux/slices/organizationSlice';

import { AgentProfileFormSteps } from 'constants/agentConstants';
import { LastActiveStatus, OrganizationSortTypes } from 'constants/organizationConstants';
import { handleServerResponseError, parseResponseErrors } from 'helpers/helper';
import { acceptAgentOrganizationInvite } from 'services/organizationService';
import { Notification } from 'shared/Notification/Notification';
import { routes } from 'shared/routes';
import { InvitesErrorCodes } from 'shared/types/authType';
import {
  AgentOrganizationInvitesHelperProps,
  MembersRetrievalProps,
  SortParams,
  UserInformation,
} from 'shared/types/organizationTypes';

import { lastActiveFormatter } from './agentHelper';

export const getOrganizationGuestCount = (members: UserInformation[]): number => {
  let count = 0;

  members?.map((member) => {
    if (member.guests) {
      count = count + member.guests.length;
    }
  });

  return count;
};

export const getLastActiveStatus = (lastActive: string): string => {
  if (!lastActive) {
    return '';
  }

  if (lastActive === LastActiveStatus.NOW) {
    return capitalize(lastActive);
  } else {
    return lastActiveFormatter(lastActive);
  }
};
/**This function will return the new sortType option for the given sortBy field */
export const setSortByParams = (name: string, sortOptions: SortParams): SortParams => {
  if (name === sortOptions.sortBy) {
    if (sortOptions.sortType === OrganizationSortTypes.ASCENDING) {
      return { sortBy: sortOptions.sortBy, sortType: OrganizationSortTypes.DESCENDING };
    } else {
      return { sortBy: sortOptions.sortBy, sortType: OrganizationSortTypes.ASCENDING };
    }
  }

  return { sortBy: name, sortType: OrganizationSortTypes.ASCENDING };
};
//This functions return a param string that can be appending a URL
//This is for Org Member Retrieval API
//This will return the specific string based on the params provided
export const getMemberRetrievalUrl = (params: MembersRetrievalProps): string => {
  const queryString = Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
};

export const updateCurrentOrganization = (
  id: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  navigate: NavigateFunction
): void => {
  dispatch(getLastVisitedOrganization(id))
    .unwrap()
    .then(() => {
      navigate(routes.applicants);
    })
    .catch((error) => {
      Notification({ message: parseResponseErrors(error) });
    });
};

export const acceptAgentOrganizationInviteHanlder = ({
  inviteCode,
  dispatch,
  navigate,
}: AgentOrganizationInvitesHelperProps): void => {
  let errorCode = 0;

  acceptAgentOrganizationInvite(inviteCode)
    .then(() => {
      dispatch(getAgentOrganizations());
      dispatch(setInviteCode(''));
    })
    .catch((e) => {
      if (e instanceof AxiosError && e.response) {
        handleServerResponseError({ error: e, dispatch });
        errorCode = e.response.data.errors[0].errorCode;
        Notification({ message: e.response.data.errors[0].errorMessage });
      }
    })
    .finally(() => {
      if (errorCode !== InvitesErrorCodes.COMPLETE_PROFILE_BEFORE_ACCEPTTING_INVITE) {
        dispatch(setInviteCode(''));
      } else if (errorCode === InvitesErrorCodes.COMPLETE_PROFILE_BEFORE_ACCEPTTING_INVITE) {
        navigate(routes.generateAgentProfileForm(AgentProfileFormSteps.INVITED));
      }
    });
};
