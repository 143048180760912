import { StyleSheet } from '@react-pdf/renderer';

export const verifierStyles = StyleSheet.create({
  page: {
    padding: '20px 30px',
  },
  verifierHeading: {
    flexDirection: 'row',
    width: '100%',
    padding: '10px',
    justifyContent: 'center',
    fontSize: '11px',
    fontWeight: 'extrabold',
  },
  subtext: {
    flexDirection: 'row',
    width: '100%',
    padding: '5px 0px',
    justifyContent: 'center',
    color: '#5e6566',
    fontSize: '9px',
    textAlign: 'left',
  },
  boldSubText: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    fontSize: '9px',
    textAlign: 'left',
  },
  authorizeContainer: {
    marginTop: '20px',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    gap: '10px',
    fontSize: '11px',
  },
  nameContainer: {
    marginTop: '10px',
    width: '100%',
    justifyContent: 'center',
    fontSize: '14px',
  },
  signatureContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '10px',
    fontSize: '10px',
  },
  contactInformation: {
    width: '100%',
    justifyContent: 'flex-start',
    color: '#5e6566',
    textTransform: 'uppercase',
    fontSize: '14px',
    marginTop: '20px',
  },
  contactContainer: {
    marginTop: '20px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '100px',
    fontSize: '14px',
  },
  greyLine: {
    height: '1px',
    width: '100%',
    backgroundColor: 'black',
    marginVertical: '10px',
  },
  renterContainer: {
    marginVertical: '20px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '100px',
    fontSize: '14px',
  },
  rentalProperty: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
    width: '30%',
  },
  ownerContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
  },
  greyText: {
    color: '#8d9899',
  },
  submittedByRenter: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    fontSize: '14px',
  },
  greyHeadingRow: {
    marginTop: '10px',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    gap: '120px',
    fontSize: '14px',
    color: '#8d9899',
  },
  continued: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    fontSize: '10px',
    color: '#5E6566',
    paddingVertical: '8px',
    fontStyle: 'italic',
    alignItems: 'center',
    marginTop: '24px',
  },
  QuestionnairePartTwo: {
    padding: '20px 30px',
  },
});
