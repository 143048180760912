import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export const PetPawSVG = (): JSX.Element => (
  <Svg width="13" height="13" viewBox="0 0 13 13" fill="none">
    <G clip-path="url(#clip0_9997_109907)">
      <Path
        d="M2.75 6.5C3.44036 6.5 4 5.94036 4 5.25C4 4.55964 3.44036 4 2.75 4C2.05964 4 1.5 4.55964 1.5 5.25C1.5 5.94036 2.05964 6.5 2.75 6.5Z"
        fill="#8D9899"
      />
      <Path
        d="M5 4.5C5.69036 4.5 6.25 3.94036 6.25 3.25C6.25 2.55964 5.69036 2 5 2C4.30964 2 3.75 2.55964 3.75 3.25C3.75 3.94036 4.30964 4.5 5 4.5Z"
        fill="#8D9899"
      />
      <Path
        d="M8 4.5C8.69036 4.5 9.25 3.94036 9.25 3.25C9.25 2.55964 8.69036 2 8 2C7.30964 2 6.75 2.55964 6.75 3.25C6.75 3.94036 7.30964 4.5 8 4.5Z"
        fill="#8D9899"
      />
      <Path
        d="M10.25 6.5C10.9404 6.5 11.5 5.94036 11.5 5.25C11.5 4.55964 10.9404 4 10.25 4C9.55964 4 9 4.55964 9 5.25C9 5.94036 9.55964 6.5 10.25 6.5Z"
        fill="#8D9899"
      />
      <Path
        d="M9.16934 7.93C8.73434 7.42 8.36934 6.985 7.92934 6.475C7.69934 6.205 7.40434 5.935 7.05434 5.815C6.99934 5.795 6.94434 5.78 6.88934 5.77C6.76434 5.75 6.62934 5.75 6.49934 5.75C6.36934 5.75 6.23434 5.75 6.10434 5.775C6.04934 5.785 5.99434 5.8 5.93934 5.82C5.58934 5.94 5.29934 6.21 5.06434 6.48C4.62934 6.99 4.26434 7.425 3.82434 7.935C3.16934 8.59 2.36434 9.315 2.51434 10.33C2.65934 10.84 3.02434 11.345 3.67934 11.49C4.04434 11.565 5.20934 11.27 6.44934 11.27H6.53934C7.77934 11.27 8.94434 11.56 9.30934 11.49C9.96434 11.345 10.3293 10.835 10.4743 10.33C10.6293 9.31 9.82434 8.585 9.16934 7.93Z"
        fill="#8D9899"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_9997_109907">
        <Rect width="12" height="12" fill="white" transform="translate(0.5 0.5)" />
      </ClipPath>
    </Defs>
  </Svg>
);
