import { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import { useField } from 'formik';

import { WORD_COUNT } from 'components/Agent/Properties/AddProperty/Description';
import { AddPropertyParams } from 'shared/types/propertyType';

import EditorToolbar, { formats, modules } from './QuillToolbar';

import styles from './QuillEditor.module.scss';
export type QuillEditorProps = {
  handleBlur?: (params: AddPropertyParams) => void;
  name: string;
  isReadOnly?: boolean;
};

const QuillEditor = ({ name, handleBlur, isReadOnly = false }: QuillEditorProps): JSX.Element => {
  const [field, , helpers] = useField({ name });
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // WORD_COUNT  = 5000
    if (field?.value && field?.value.length > WORD_COUNT) {
      return;
    }

    const handleClickOutside = (event: MouseEvent): void => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        field.value && handleBlur && handleBlur({ [field.name]: field.value });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [field.name, field.value, handleBlur]);

  return (
    <div ref={divRef} className={styles.textEditor}>
      <EditorToolbar />
      <ReactQuill
        onChange={(newValue) => {
          if (newValue.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            return helpers.setValue(null);
          }

          helpers.setValue(newValue);
        }}
        modules={modules}
        formats={formats}
        placeholder="Start typing your description here."
        className={styles.quillContainer}
        value={field?.value}
        readOnly={isReadOnly}
      />
    </div>
  );
};

export default QuillEditor;
