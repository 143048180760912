import { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { updateBackgroundInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import styles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import { Ladder } from 'components/Renter/IdentityVerification/RenterDetails/shared/Ladder/Ladder';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { backgroundQuestionOptions, QuestionAnswerKey } from 'constants/renterConstants';
import { Notification } from 'shared/Notification/Notification';
import { BackgroundInformationValues, Option, questionOptions } from 'shared/types/renterBackgroundInformation';
import { BackgroundQuestionProps, UpdateBackgroundInfo } from 'shared/types/renterTypes';

import BackgroundAnswerCard from './BackgroundAnswer/BackgroundAnswerCard';
import BackgroundQuestionDialogBox from './BackgroundQuestionDialogBox/BackgroundQuestionDialogBox';
import BackgroundQuestionEditModal from './BackgroundQuestionEditModal/BackgroundQuestionEditModal';

const NoBackgroundInfo = {
  answer: 'No',
  explanation: null,
};
const BackgroundQuestionsCard = ({ index }: BackgroundQuestionProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<BackgroundInformationValues>();
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t } = useTranslation();
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const handleWillAnswer = useCallback(
    (value: Option, indexOfQuestion: number) => {
      if (value?.name === QuestionAnswerKey.NO) {
        setFieldValue(`backgroundInformation[${indexOfQuestion}].explanation`, null);
      }

      setFieldValue(`backgroundInformation[${indexOfQuestion}].answer`, value?.name);
    },
    [setFieldValue]
  );
  const onChangeHandler = useCallback(
    (value: Option) => {
      if (
        value?.name === QuestionAnswerKey.YES &&
        values.backgroundInformation[index].answer === QuestionAnswerKey.NO
      ) {
        setIsModalVisible(true);
      } else if (
        value?.name === QuestionAnswerKey.NO &&
        values.backgroundInformation[index].answer === QuestionAnswerKey.YES
      ) {
        setIsDialogVisible(true);
      }
    },
    [index, values]
  );
  const updateBackgroundInfoResponse = (backgroundInfoId: number, backgroundInfo: UpdateBackgroundInfo): void => {
    dispatch(
      updateBackgroundInformation({
        applicationId: Number(currentApplicationId),
        backgroundInfo,
        backgroundInfoId,
      })
    )
      .unwrap()
      .catch((error) => {
        if (Object.keys(error).includes('null')) {
          Notification({
            message: t('renter.verifier.responseAlreadySubmitted'),
          });
        }
      });
  };

  return (
    <>
      <div className={styles.borderContainer}>
        <Row className={styles.questionDiv}>{values.backgroundInformation[index].backgroundQuestion.question}</Row>
        <Row>
          <Col className={styles.questionSelectDiv}>
            <ReactSelect
              value={questionOptions[values.backgroundInformation[index]?.answer || QuestionAnswerKey.NO]}
              isSearchable={false}
              onChange={onChangeHandler}
              options={backgroundQuestionOptions}
              backgroundColor="#F7FAFA"
              className={styles.questionSelect}
            />
          </Col>
        </Row>
      </div>
      <div className={classNames(styles.personalInformationContainer, styles.marginRight)}>
        {values.backgroundInformation[index].answer === QuestionAnswerKey.YES && (
          <div className={styles.fieldContainer}>
            <Ladder isBottomPadding={false} />
            <div
              className={classNames(
                styles.borderContainer,
                styles.borderLessContainer,
                styles.paddingLessBorder,
                styles.noTopBorder
              )}
            >
              <Row className={classNames(styles.answerContainer, styles.noTopBorder)}>
                <BackgroundAnswerCard
                  answer={values.backgroundInformation[index].explanation || ''}
                  setIsModalVisible={setIsModalVisible}
                />
              </Row>
            </div>
          </div>
        )}
      </div>

      {isModalVisible && (
        <BackgroundQuestionEditModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          index={index}
          updateBackgroundInfoResponse={updateBackgroundInfoResponse}
        />
      )}
      <div className={styles.backgroundQuestions}>
        {isDialogVisible && (
          <BackgroundQuestionDialogBox
            isOpen={isDialogVisible}
            onHide={() => setIsDialogVisible(false)}
            onNo={() => setIsDialogVisible(false)}
            onYes={() => {
              handleWillAnswer(backgroundQuestionOptions[1], index);
              setIsDialogVisible(false);
              updateBackgroundInfoResponse(values.backgroundInformation[index].id, NoBackgroundInfo);
            }}
          />
        )}
      </div>
    </>
  );
};

export default BackgroundQuestionsCard;
