import { useCallback, useMemo } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { selectedRenterDetails } from 'redux/selectors/agentSelector';

import { ReactComponent as WarningIcon } from 'assets/svgs/ErrorInfo.svg';
import { ReactComponent as International } from 'assets/svgs/International.svg';
import { ReactComponent as NotificationIcon } from 'assets/svgs/notification_importantPinkBellIcon.svg';
import { RenterResumeNavbarKeys } from 'constants/agentConstants';
import { CreditCheckProvider, ExperianAuthenticationStatus } from 'constants/renterConstants';
import { CreditCheckProviders } from 'constants/resumeSideMenuConstants';
import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { RenterResumeNavbarProps } from 'shared/types/renterTypes';

import styles from './RenterResumeNavbar.module.scss';
const RenterResumeNavbar = ({ applicationStatus, creditScore, experianAuth }: RenterResumeNavbarProps): JSX.Element => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const { creditCheckProvider } = useSelector(selectedRenterDetails);
  const activeTabName = params.get('activeTab') ?? RenterResumeNavbarKeys.APPLICATION;
  const [, setSearchParams] = useSearchParams();
  const tabSwitch = useCallback(
    (activeTab: string) => {
      const searchParams = new URLSearchParams(params.toString());

      searchParams.set('activeTab', activeTab);
      setSearchParams(searchParams.toString());
    },
    [params, setSearchParams]
  );
  const getCreditBackgroungStatus = useMemo((): JSX.Element | null => {
    if (experianAuth === ExperianAuthenticationStatus.COMPLETED && creditScore === '') {
      return <NotificationIcon />;
    } else if (
      experianAuth === ExperianAuthenticationStatus.IN_PROGRESS ||
      experianAuth === ExperianAuthenticationStatus.PENDING
    ) {
      return <WarningIcon height={12} width={12} />;
    }

    return null;
  }, [creditScore, experianAuth]);

  return (
    <Navbar expand="lg" className={styles.NavBar}>
      <div className={styles.Container}>
        <Nav
          className={classNames(styles.NavLinks, {
            [styles.active]: activeTabName === RenterResumeNavbarKeys.APPLICATION,
          })}
          onClick={() => tabSwitch(RenterResumeNavbarKeys.APPLICATION)}
        >
          {t('renter.application')}
        </Nav>
        <Nav
          className={classNames(styles.NavLinks, {
            [styles.active]: activeTabName === RenterResumeNavbarKeys.CREDIT_AND_BACKGROUND,
          })}
          onClick={() => tabSwitch(RenterResumeNavbarKeys.CREDIT_AND_BACKGROUND)}
        >
          {applicationStatus === PropertyApplicationStatus.COMPLETED && (
            <div className={styles.creditConateinerDiv}>
              {creditCheckProvider === 'DOCUMENT' ? <International /> : null}
              {CreditCheckProviders[creditCheckProvider as keyof typeof CreditCheckProviders]}
              <div className={styles.textDiv}>{}</div>
              {creditCheckProvider === CreditCheckProvider.EXPERIAN && (
                <>
                  <div className={styles.CreditContainer}>
                    {!!creditScore && (
                      <div className={styles.backgroundDiv}>
                        <div className={styles.creditDiv}>{creditScore}</div>
                      </div>
                    )}
                  </div>
                  <div>{getCreditBackgroungStatus}</div>
                </>
              )}
            </div>
          )}
        </Nav>
        {/* <Nav
          className={classNames(styles.NavLinks, {
            [styles.active]: activeTabName === RenterResumeNavbarKeys.CRITERIA_AND_TERMS,
          })}Will be
          onClick={() => tabSwitch(RenterResumeNavbarKeys.CRITERIA_AND_TERMS)}
        >
          {t('application.criteriaAndTerms')}
        </Nav>
        <Nav
          className={classNames(styles.NavLinks, {
            [styles.active]: activeTabName === RenterResumeNavbarKeys.RECEIPT,
          })}
          onClick={() => tabSwitch(RenterResumeNavbarKeys.RECEIPT)}
        >
          {t('application.receipt')}
        </Nav> */}
        {/* <Nav
          className={classNames(styles.NavLinks, {
            [styles.active]: activeTabName === RenterResumeNavbarKeys.PDF_PREVIEW,
          })}
          onClick={() => tabSwitch(RenterResumeNavbarKeys.PDF_PREVIEW)}
        >
          {t('application.PDFPreview')}
        </Nav> */}
      </div>
    </Navbar>
  );
};

export default RenterResumeNavbar;
