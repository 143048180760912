import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { applicantRenterRole } from 'redux/selectors/renterSelector';

import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import styles from 'components/Renter/IdentityVerification/RenterDetails/shared/Popover.module.scss';
import { renterRestrictions } from 'constants/restrictionConstants';
import { AnimalInformationTooltipProps } from 'shared/types/renterTypes';

const AnimalInformationTooltip = ({
  animalInformation,

  handleAddInfo,
}: AnimalInformationTooltipProps): JSX.Element => {
  const animalCount = animalInformation?.length ?? 0;
  const { t } = useTranslation();
  const renterRole = useSelector(applicantRenterRole);
  const renderTooltip = (
    <Popover id="popover-positioned-bottom" hasDoneInitialMeasure>
      <Popover.Body className={styles.popoverBody}>
        <div className={styles.popoverWrapper}>
          <button className={styles.popoverBtn} onClick={handleAddInfo}>
            {animalCount
              ? t('renter.paymentConfirmation.houseHoldDetails.addAnother')
              : t('renter.petsInformation.anotherAnimalLabel')}
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  if (renterRestrictions[renterRole].animals) {
    return <div />;
  }

  return (
    <OverlayTrigger rootClose trigger="click" key="bottom" placement="bottom" overlay={renderTooltip}>
      <span className={styles.overlayTrigger}>
        <ThreeDotsIcon />
      </span>
    </OverlayTrigger>
  );
};

export default AnimalInformationTooltip;
