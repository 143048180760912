// TO-DO Add the functionality of search
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Nav, Row, Spinner } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import Cookies from 'js-cookie';
// import { debounce } from 'lodash-es';
import {
  isAgentLoading,
  paginatedLegacyValues,
  selectLegacyApplicants,
  selectLegacyApplicantsCount,
} from 'redux/selectors/agentSelector';
import { getPaginatedLegacyApplicants } from 'redux/slices/agentSlice';
import { AppThunkDispatch } from 'redux/store';

import Pagination from 'components/shared/Pagination/Pagination';
import SearchBar from 'components/shared/SearchBar/SearchBar';
import {
  // INITIAL_USER_FILTER,
  mobileScreenSize,
  // SEARCH_DEBOUNCE_TIME,
  // SORT_INITIAL_VALUE,
  // UsersControlOptions,
} from 'constants/agentConstants';
import { Size, useWindowSize } from 'hooks/useResize';
import { LegacyApplicantsProps, OrganizationProps } from 'shared/types/agentTypes';

// import { SortParams } from 'shared/types/organizationTypes';
import styles from './ViewLegacyTable.module.scss';
interface TableContentProps {
  data: LegacyApplicantsProps[];
}

interface ViewLegacyTableProps {
  legacyApplicants: LegacyApplicantsProps[];
  activeOrganization: OrganizationProps;
}
const TableContent: React.FC<TableContentProps> = ({ data }) => (
  <table className={classNames(styles.CustomTable, 'table')}>
    <thead className="gap-2">
      <tr>
        <th scope="col">SUBMITTED</th>
        <th scope="col">APPROVED</th>
        <th scope="col">PROPERTY</th>
        <th scope="col">UNIT/APT #</th>
        <th scope="col">NAME</th>
        <th scope="col">EMAIL</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index} className={styles.CursorPointer}>
          <td>{item?.submittedAt}</td>
          <td>{item?.updatedAt}</td>
          <td>{item.property}</td>
          <td>{item.unit}</td>
          <td>{item.name}</td>
          <td>{item.email}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export const ViewLegacyTable: React.FC<ViewLegacyTableProps> = ({ activeOrganization }): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const size: Size = useWindowSize();
  // const searchValue = searchParams.get(UsersControlOptions.TARGET) || '';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const statusLegacy = searchParams.get('option') || 'Legacy';
  const perPage = searchParams.get('perPage') || Cookies.get('perPage') || '10';
  const currentPage = searchParams.get('page') || '1';
  const [activeLegacyOption, setActiveLegacyOption] = useState('Approved');
  const pageInfo = useSelector(paginatedLegacyValues) || [];
  const isPropertyMobile = useMemo((): boolean => size.width > mobileScreenSize, [size]);
  const legacyApplicants = useSelector(selectLegacyApplicants) || [];
  const totalLegacyApplicants = useSelector(selectLegacyApplicantsCount);
  const isLoading = useSelector(isAgentLoading);
  const tabs = [
    { eventKey: 'approved', label: 'Approved', count: totalLegacyApplicants.approvedCount },
    { eventKey: 'denied', label: 'Denied', count: totalLegacyApplicants.deniedCount },
    { eventKey: 'archived', label: 'Archived', count: totalLegacyApplicants.archivedCount },
    { eventKey: 'invited', label: 'Invited', count: totalLegacyApplicants.invitedCount },
  ];
  // const [, setSortOptions] = useState<SortParams>({
  //   sortBy: searchParams.get(UsersControlOptions.SORTBY) || SORT_INITIAL_VALUE.sortBy,
  //   sortType: searchParams.get(UsersControlOptions.SORTTYPE) || SORT_INITIAL_VALUE.sortType,
  // });
  // const [filterValue] = useState({
  //   value: searchParams.get(UsersControlOptions.FILTER) || INITIAL_USER_FILTER.value,
  //   label: searchParams.get(UsersControlOptions.FILTER) || INITIAL_USER_FILTER.label,
  // });
  // const handleSearch = useCallback(
  //   (target: string): void => {
  //     setSearchParams({
  //       target: target,
  //       sortBy: SORT_INITIAL_VALUE.sortBy,
  //       sortType: SORT_INITIAL_VALUE.sortType,
  //       filter: filterValue.value,
  //     });
  //     setSortOptions(SORT_INITIAL_VALUE);
  //   },
  //   [filterValue, setSearchParams]
  // );
  const fetchLegacyApplicants = useCallback(() => {
    const body = {
      page: Number(currentPage),
      size: Number(perPage),
      status: String(activeLegacyOption),
    };

    if (activeOrganization.id) {
      dispatch(
        getPaginatedLegacyApplicants({
          organizationId: Number(activeOrganization.id),
          values: { ...body },
        })
      );
    }
  }, [dispatch, activeOrganization.id, activeLegacyOption, currentPage, perPage]);
  const handlePageChange = (page: number): void => {
    if (perPage) {
      searchParams.set('page', `${page || 1}`);
      searchParams.set('perPage', perPage);
      setSearchParams(searchParams);
    }
  };
  // const searchResults = useMemo(() => debounce(handleSearch, SEARCH_DEBOUNCE_TIME), [handleSearch]);

  useEffect(() => {
    fetchLegacyApplicants();
  }, [fetchLegacyApplicants]);

  const handleLegacyTableOptions = (status: string | null): void => {
    if (status) {
      setActiveLegacyOption(status[0].toUpperCase() + status.slice(1));
      searchParams.set('page', '1');
      searchParams.set('perPage', perPage);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {isPropertyMobile && (
        <Pagination pagination={pageInfo} handlePageChange={handlePageChange} status={statusLegacy} />
      )}

      <div className={styles.TableContainer}>
        <Tab.Container defaultActiveKey="approved" onSelect={handleLegacyTableOptions}>
          <div className={styles.TableTopContainer}>
            <Row>
              <Nav variant="tabs" className={classNames(styles.noBorder, 'flex-row')}>
                {tabs.map((tab) => (
                  <Col key={tab.eventKey}>
                    <Nav.Item className={styles.NavItem}>
                      <Nav.Link eventKey={tab.eventKey} className={styles.NavLink}>
                        <span>
                          {tab.label} <span className={styles.CountHeading}>({tab.count} Apps)</span>
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                ))}
              </Nav>
            </Row>
            <div className={styles.SearchContainer}></div>
            <div className={styles.SearchContainer}>
              <SearchBar className={styles.SearchBar} placeholder={t('application.search.all')} />
            </div>
          </div>

          <Row>
            <Col sm={12}>
              <Tab.Content>
                {isLoading ? (
                  <Spinner />
                ) : legacyApplicants.length === 0 ? (
                  <div className={styles.NoApplicantsMessage}>
                    <p>No result found.</p> {/* Show error message when no applicants */}
                  </div>
                ) : (
                  <>
                    <Tab.Pane eventKey="approved">
                      <TableContent data={legacyApplicants} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="denied">
                      <TableContent data={legacyApplicants} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="archived">
                      <TableContent data={legacyApplicants} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="invited">
                      <TableContent data={legacyApplicants} />
                    </Tab.Pane>
                  </>
                )}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};
