import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import defaultImage from 'assets/images/EmptyRecordRow.jpg';
import { EmptyState } from 'components/Agent/Applications/ApplicationsList/components';
import { BackgroundDataTypes } from 'constants/agentConstants';
import { EmptyBackgroundInfoProps } from 'shared/types/reportTypes';

import styles from '../EmptyBackgroundReport/EmptyBackgroundReport.module.scss';

const EmptyBackgroundInfo = ({
  page,
  fullName,
  dateOfBirth,
  applyingZip,
  reportDate,
  reportId,
}: EmptyBackgroundInfoProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.TitleContainer}>
        {page === BackgroundDataTypes.CRIMINAL
          ? t('creditReport.criminalRecordSearch')
          : t('creditReport.housingCourtRecordSearch')}
      </div>
      <div className={styles.ApplicantContainer}>
        <div className={styles.ApplicantInfoContainer}>
          <div className={styles.ApplicantInfo}>
            <div className={styles.ApplicantLabelContainer}>{t('creditReport.applicantFullName')}</div>
            <div className={styles.ApplicantValueContainer}>{fullName}</div>
          </div>
          <div className={styles.ApplicantInfo}>
            <div className={styles.ApplicantLabelContainer}>{t('creditReport.applicantDOB')}</div>
            <div className={styles.ApplicantValueContainer}>{dateOfBirth}</div>
          </div>
          <div className={styles.ApplicantInfo}>
            <div className={styles.ApplicantLabelContainer}>{t('creditReport.applyingZip')}</div>
            <div className={styles.ApplicantValueContainer}>{applyingZip}</div>
          </div>
        </div>
        <div className={styles.ApplicantInfoContainer}>
          <div className={styles.ApplicantInfo}>
            <div className={styles.ApplicantLabelContainer}>{t('creditReport.reportDate')}</div>
            <div className={styles.ApplicantValueContainer}>{reportDate}</div>
          </div>
          <div className={styles.ApplicantInfo}>
            <div className={styles.ApplicantLabelContainer}>{t('creditReport.reportId')}</div>
            <div className={styles.ApplicantValueContainer}>{reportId}</div>
          </div>
        </div>
      </div>
      <EmptyState
        image={defaultImage}
        message={
          page === BackgroundDataTypes.CRIMINAL
            ? t('creditReport.noCriminalActivity')
            : t('creditReport.noHousingRecords')
        }
      />
      {page === BackgroundDataTypes.CRIMINAL && (
        <div className={classNames(styles.DescriptionContainer, styles.Dark)}>
          {t('creditReport.theResultOfCriminalRecord')}
        </div>
      )}
      {page !== BackgroundDataTypes.CRIMINAL && (
        <>
          <div className={classNames(styles.DescriptionContainer, styles.Dark)}>
            {t('creditReport.theResultOfHousingCourtRecord')}
          </div>
          <hr />
          <div className={classNames(styles.DescriptionContainer, styles.Dark)}>
            {t('creditReport.theAgencyProviding')}
          </div>
          <div className={classNames(styles.DescriptionContainer, styles.Dark)}>
            {t('creditReport.thisReportIsConfidential')}
          </div>
          <div className={styles.FooterContainer}>
            <div>{t('creditReport.endOfHousingCourt')}</div>
            <hr />
          </div>
        </>
      )}
    </div>
  );
};

export default EmptyBackgroundInfo;
