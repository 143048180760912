import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { ReactComponent as GreenTick } from 'assets/svgs/SimpleTick.svg';
import { dateFormatFrontend } from 'constants/calendarConstants';
import { formatAmountToUsdString, getDateDifferenceFormattedString } from 'helpers/helper';
import { EditEmploymentTypes } from 'shared/types/renterEmploymentInformation';
import { SourceInformationBoxProps } from 'shared/types/reportTypes';

import styles from './SourceInformationBox.module.scss';

export const SourceInformationBox = ({
  companyName,
  companySubName,
  employmentType,
  jobPosition,
  startDate,
  monthlyIncome,
  status,
  description,
  explaination,
}: SourceInformationBoxProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.CompanyInformation}>
          {companyName && <div className={styles.CompanyFirstName}>{companyName}</div>}
          {companySubName && <div className={styles.CompanySecondName}>{companySubName}</div>}
        </div>

        <div className={styles.StatusContainer}>
          <div className={styles.EmployedStatus}>
            {employmentType === EditEmploymentTypes.EMPLOYED ? t('renter.sourceOfIncome.employed') : employmentType}
          </div>
          {status === 'VERIFIED' && (
            <div className={styles.VerifierStatus}>
              {t('agent.applicants.renterResume.sourceOfIncome.verified')}
              <GreenTick />
            </div>
          )}
        </div>
      </div>
      {jobPosition && <div className={styles.JobPosition}>{jobPosition}</div>}
      {description && (
        <div className={styles.JobDescription}>
          <div className={styles.Title}>{t('incomeReport.description')} </div>
          <div className={styles.Description}>{description}</div>
        </div>
      )}
      {explaination && (
        <div className={styles.JobDescription}>
          <div className={styles.Title}>{t('incomeReport.explaination')} </div>
          <div className={styles.Description}>{explaination}</div>
        </div>
      )}
      {startDate && (
        <div className={styles.JobDuration}>
          <div className={styles.Duration}>{`${format(new Date(startDate), dateFormatFrontend)} - ${t(
            'agent.applicants.renterResume.sourceOfIncome.present'
          )}`}</div>
          <div className={styles.TotalTime}>{getDateDifferenceFormattedString(startDate)}</div>
        </div>
      )}
      <div className={styles.MonthlyIncome}>
        <div className={styles.Title}>{t('incomeReport.statedMonthlyIncome')}</div>
        <div className={styles.Income}>{formatAmountToUsdString(monthlyIncome)}</div>
      </div>
    </div>
  );
};
