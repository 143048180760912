import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { HeadingPDFProps } from 'shared/types/reportTypes';

const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
    marginTop: '8px',
  },
  SubHeading: {
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
  Heading: {
    color: '#303333',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 'semibold',
    textTransform: 'capitalize',
  },
  Counter: {
    padding: '0px 2px',
    borderRadius: '2px',
    backgroundColor: '#E6EFF0',
    color: '#303333',
    fontSize: '11px',
    fontWeight: 'semibold',
  },

  LineDivider: {
    marginTop: '8px',
    borderTop: '1px solid #E1E8EB',
    flex: 1,
  },
});

export const HeadingPDF = ({
  isSubHeadingLeft = false,
  shouldShowCount = false,
  subHeading,
  count = 0,
  heading,
  shouldShowLine = false,
}: HeadingPDFProps): JSX.Element => (
  <View style={styles.Container} wrap={false}>
    {isSubHeadingLeft && !!subHeading && <Text style={styles.SubHeading}>{subHeading}</Text>}
    {!!heading && <Text style={styles.Heading}>{heading}</Text>}
    {!isSubHeadingLeft && !!subHeading && <Text style={styles.SubHeading}>{subHeading}</Text>}
    {shouldShowCount && <Text style={styles.Counter}>{count}</Text>}
    {shouldShowLine && <Text style={styles.LineDivider} />}
  </View>
);
