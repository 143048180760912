import { Col, Row } from 'react-bootstrap';

import { ReportItem } from 'components/shared/ReportItem/ReportItem';
import {
  canidateSubjectReportBoldedLabels,
  criminalSubjectFirstColumnLabels,
  criminalSubjectSecondColumnLabels,
} from 'constants/reportConstants';
import { convertResponseToObject, getCodeFormated, getValueFormated, isEven } from 'helpers/reportsHelper';
import { OffenseSubjectInfo } from 'shared/types/reportTypes';

import styles from './CriminalSubjectReport.module.scss';
export const CriminalSubjectReport = ({ criminalSubject }: { criminalSubject: OffenseSubjectInfo }): JSX.Element => {
  const convertedCrminalSubject = convertResponseToObject(criminalSubject);

  return (
    <div className={styles.Container}>
      <Row>
        <Col md={12} lg={6}>
          {Object.keys(criminalSubjectFirstColumnLabels).map((label, index) => (
            <ReportItem
              key={`canidate-subject-first-${label}-${index}`}
              hasGreyBackground={!isEven(index)}
              label={criminalSubjectFirstColumnLabels[label]}
              value={getValueFormated(convertedCrminalSubject[label])}
              code={getCodeFormated(convertedCrminalSubject[label])}
              isLink={label === 'Image'}
              isValueBold={canidateSubjectReportBoldedLabels.includes(label)}
            />
          ))}
        </Col>
        <Col md={12} lg={6}>
          {Object.keys(criminalSubjectSecondColumnLabels).map((label, index) => (
            <ReportItem
              key={`canidate-subject-second-${label}-${index}`}
              hasGreyBackground={!isEven(index)}
              label={criminalSubjectSecondColumnLabels[label]}
              value={getValueFormated(convertedCrminalSubject[label])}
              code={getCodeFormated(convertedCrminalSubject[label])}
              isValueBold={canidateSubjectReportBoldedLabels.includes(label)}
            />
          ))}
        </Col>
      </Row>
    </div>
  );
};
