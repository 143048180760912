import { useSelector } from 'react-redux';
import { isAgentLoading } from 'redux/selectors/agentSelector';

import { OverlayLoader } from 'components/shared/OverlayLoader/OverlayLoader';
import { SecurityQuestionnaire } from 'components/shared/SecurityQuestionnaire/SecurityQuestionnaire';
import { UserFlowTpe } from 'shared/types/agentTypes';

export const AgentSecurityQuestonWrapper = (): JSX.Element => {
  const isAgentPageLoading = useSelector(isAgentLoading);

  if (isAgentPageLoading) {
    return <OverlayLoader />;
  }

  return <SecurityQuestionnaire flowType={UserFlowTpe.AGENT} />;
};
