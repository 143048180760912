import { ReactComponent as BoatIcon } from 'assets/svgs/boatIcon.svg';
import { ReactComponent as CarIcon } from 'assets/svgs/CarIconDark.svg';
import { ReactComponent as BikeIcon } from 'assets/svgs/pedalBikeIcon.svg';
import { ReactComponent as PetsIcon } from 'assets/svgs/petsIcon.svg';
import { ReactComponent as SingleBedIcon } from 'assets/svgs/singleBedIcon.svg';
import { ReactComponent as SmokingIcon } from 'assets/svgs/smokingIcon.svg';
import ToggleSwitch from 'components/shared/ToggleSwitch/ToggleSwitch';
import { QuestionsProps } from 'shared/types/renterFurtherInformation';
import { StepProps } from 'shared/types/renterTypes';

import styles from './QuestionContainer.module.scss';

const QuestionContainer = ({ question, iconType, onChange, value: isChecked }: QuestionsProps): JSX.Element => {
  const iconsHash: StepProps = {
    boat: <BoatIcon className={styles.icon} />,
    parking: <CarIcon />,
    bike: <BikeIcon />,
    pet: <PetsIcon />,
    bed: <SingleBedIcon />,
    smoking: <SmokingIcon />,
  };

  return (
    <div className={styles.questionContainer}>
      <div className={styles.iconContainer}>{iconType && iconsHash[iconType]}</div>
      <div className={styles.questions}>{question}</div>
      <div className={styles.toggleContainer}>
        <ToggleSwitch
          checked={isChecked}
          onChange={onChange}
          enabledText="Yes"
          disabledText="No"
          onColor="#ffffff"
          offColor="#ffffff"
          isRenter={true}
        />
      </div>
    </div>
  );
};

export default QuestionContainer;
