import { useCallback, useMemo, useState } from 'react';
import { Modal as BModal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { renterAnimals } from 'redux/selectors/renterSelector';
import {
  deleteFurryFriendsDocument,
  deleteFurryFriendsImage,
  updateFurryFriendToRenter,
  uploadFurryFriendsDocuments,
  uploadFurryFriendsImages,
} from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterAnimalSchema } from 'schema/renterSchema';

import { ReactComponent as TickIcon } from 'assets/svgs/SimpleTick.svg';
import RCButton from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import { FurryFriendTypes } from 'constants/renterConstants';
import { formatAnimalEditModalValues, getAnimalFilesToDelete } from 'helpers/renterHelper';
import {
  AnimalDispatchProps,
  AnimalInformationInitialValues,
  AnimalInformationProps,
  EditDetailsProps,
} from 'shared/types/renterTypes';

import RenterModal from '../components/RenterModal/RenterModal';

import AnimalDetailsCard from './AnimalDetailsCard/AnimalDetailsCard';

import styles from './AnimalDetailsCard/AnimalDetailsCard.module.scss';

const AnimalDetailsEditModal = ({
  isModalVisible,
  setIsModalVisible,
  onHideHandler,
  id,
  animalIndex,
}: EditDetailsProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const AnimalInformation = useSelector(renterAnimals);
  const animalinformation = useMemo(
    (): AnimalInformationProps | undefined => AnimalInformation.find((animal) => animal.id === id),
    [AnimalInformation, id]
  );
  const [animalType, setAnimalType] = useState<string>(animalinformation?.furryFriendType ?? '');
  const onContinueHandler = useCallback(
    async ({ values, setErrors, setSubmitting }: AnimalDispatchProps) => {
      if (animalinformation === values) {
        setSubmitting(false);
        setIsModalVisible(false);
      } else {
        const documentsToUpload = values?.documents?.filter((file) => !file.id);
        const documentsToDelete =
          values.furryFriendType === FurryFriendTypes.ASSISTIVE_ANIMAL
            ? getAnimalFilesToDelete(animalinformation?.documents ?? [], values?.documents ?? [])
            : animalinformation?.documents;
        const imagesToUpload = values?.images?.filter((file) => !file.id);
        const imagesToDelete = getAnimalFilesToDelete(animalinformation?.images ?? [], values.images);

        Promise.allSettled([
          dispatch(
            updateFurryFriendToRenter({ id: animalinformation?.id, values: formatAnimalEditModalValues(values) })
          )
            .unwrap()
            .catch((errorObject) => {
              setErrors(errorObject);
              setSubmitting(false);
            }),
          documentsToUpload?.map((document) => {
            dispatch(
              uploadFurryFriendsDocuments({
                furryFriendId: animalinformation?.id,
                document: document,
              })
            );
          }),
          documentsToDelete?.map((document) => {
            dispatch(
              deleteFurryFriendsDocument({ furryFriendId: animalinformation?.id ?? 0, documentId: document.id })
            );
          }),
          imagesToUpload?.map((document) => {
            dispatch(
              uploadFurryFriendsImages({
                furryFriendId: animalinformation?.id,
                document: document,
              })
            );
          }),
          imagesToDelete?.map((document) => {
            dispatch(deleteFurryFriendsImage({ furryFriendId: animalinformation?.id ?? 0, documentId: document.id }));
          }),
        ]).then(() => {
          setSubmitting(false);
          setIsModalVisible(false);
        });
      }
    },
    [dispatch, animalinformation, setIsModalVisible]
  );

  return (
    <Formik
      initialValues={animalinformation || AnimalInformationInitialValues}
      validationSchema={renterAnimalSchema}
      enableReinitialize
      onSubmit={(values, { setErrors, setSubmitting }) => {
        onContinueHandler({ values, setErrors, setSubmitting });
      }}
    >
      {({ values, isValid, isSubmitting, dirty: isDirty, setFieldValue }) => (
        <RenterModal customHeaderClassName={styles.HeaderContainer} show={isModalVisible} onHide={onHideHandler}>
          <BModal.Body className={styles.AnimalModalBody}>
            <Form>
              <FormCloseWarning isDirty={isDirty} />
              <div className={styles.container}>
                <div className={styles.heading}>{t('renter.PhotoIdInformation.editDetails')}</div>
                <div className={styles.subHeading}>
                  {`${t('renter.PhotoIdInformation.makeChanges')} ${t('renter.petsInformation.animalLabel')}`}
                </div>
                <div className={styles.ButtonsContainer}>
                  <RCButton
                    variant="outline"
                    className={classNames(styles.TypeButton, {
                      [styles.TypeButtonActive]: animalType === FurryFriendTypes.PETS,
                    })}
                    onClick={() => {
                      setAnimalType(FurryFriendTypes.PETS);

                      setFieldValue('furryFriendType', FurryFriendTypes.PETS);
                    }}
                  >
                    {animalType === FurryFriendTypes.PETS && <TickIcon className={styles.TickIcon} />}
                    {t('renter.petsInformation.petlLabel')}
                  </RCButton>
                  <RCButton
                    variant="outline"
                    onClick={() => {
                      setAnimalType(FurryFriendTypes.ASSISTIVE_ANIMAL);
                      setFieldValue('furryFriendType', FurryFriendTypes.ASSISTIVE_ANIMAL);
                    }}
                    className={classNames(styles.TypeButton, {
                      [styles.TypeButtonActive]: animalType === FurryFriendTypes.ASSISTIVE_ANIMAL,
                    })}
                  >
                    {animalType === FurryFriendTypes.ASSISTIVE_ANIMAL && <TickIcon className={styles.TickIcon} />}
                    {t('renter.petsInformation.assistivePetLabel')}
                  </RCButton>
                </div>
                <AnimalDetailsCard isEditModal animalIndex={animalIndex} />

                <div className={styles.renterButtonContainer}>
                  <RCButton
                    type="submit"
                    className={styles.renterButton}
                    variant="outline"
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      !isDirty ||
                      isEmpty(values.images) ||
                      (animalType === FurryFriendTypes.ASSISTIVE_ANIMAL && isEmpty(values.documents))
                    }
                  >
                    {t('renter.PhotoIdInformation.savechanges')}
                  </RCButton>
                </div>
              </div>
            </Form>
          </BModal.Body>
        </RenterModal>
      )}
    </Formik>
  );
};

export default AnimalDetailsEditModal;
