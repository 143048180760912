import { useTranslation } from 'react-i18next';
import { subMonths } from 'date-fns';

import { tradePaymentIcons } from 'constants/reportConstants';
import { getTradeCalendar, getYearsInBetweenArray } from 'helpers/reportsHelper';

import { TradeIconGenerator } from '../../TradeIconGenerator/TradeIconGenerator';

import styles from './TradeCalendar.module.scss';
export const TradeCalendar = ({
  paymentProfile,
  balanceDate,
}: {
  paymentProfile: string;
  balanceDate: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const currentYear = Number(new Date(balanceDate).getFullYear());
  const previousYear = Number(subMonths(new Date(balanceDate), paymentProfile.length).getFullYear());
  const yearArray = getYearsInBetweenArray(currentYear, previousYear);
  const tradeCalenderArray = getTradeCalendar(yearArray, paymentProfile, new Date(balanceDate).getMonth() + 1);
  const months = ['Dec', 'Nov', 'Oct', 'Sep', 'Aug', 'Jul', 'Jun', 'May', 'Apr', 'Mar', 'Feb', 'Jan'];

  return (
    <div className={styles.Container}>
      <div className={styles.MonthHeadings}>
        <span className={styles.Month} />
        {months.map((month, index) => (
          <span className={styles.Month} key={`trade-months-${month}-${index}`}>
            {month}
          </span>
        ))}
      </div>
      {yearArray.map((year, index1) => (
        <div className={styles.MonthHeadings} key={`trade-calendar-${year}-${index1}`}>
          <span className={styles.Month}>{year}</span>
          {tradeCalenderArray[year].map((val, index) => (
            <div
              style={{ flex: 1, justifyContent: 'center', display: 'flex', alignItems: 'center' }}
              key={`visual-key-${val}-${index}`}
            >
              {val !== '-' ? (
                <TradeIconGenerator
                  label={tradePaymentIcons[val]?.label ?? ''}
                  backgroundColor={tradePaymentIcons[val]?.color ?? ''}
                />
              ) : (
                <span className={styles.DashContainer}>—</span>
              )}
            </div>
          ))}
        </div>
      ))}
      <div className={styles.TradeFooter}>{t('agent.creditReport.tradelineReport.footer')}</div>
    </div>
  );
};
