import defaultImage from 'assets/images/defaultProfilePicture.png';
import ProfileInfo from 'components/shared/ProfileInfoWithoutDropdown/ProfileIconWithoutDropdown';

import styles from './../RenterIndex.module.scss';
const RenterIconInfoContainer = (): JSX.Element => (
  <div className={styles.renterIconsContainer}>
    <ProfileInfo profileImgStyling={styles.imageSize} imageSrc={defaultImage} />
  </div>
);

export default RenterIconInfoContainer;
