import { useCallback } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import { isLoading } from 'redux/selectors/authSelector';
import { requestChangePassword } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';
import { ForgotPasswordSchema } from 'schema/authSchema';

import { ReactComponent as HomeDesign } from 'assets/svgs/authBackGround.svg';
import Button from 'components/shared/Button/Button';
import CustomSpinner from 'components/shared/Spinner/Spinner';
import FormikField from 'components/shared/TextField/FormikField';
import { Notification, NotificationType } from 'shared/Notification/Notification';
import { ForgotPasswordValues } from 'shared/types/authType';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from '../Login/Login.module.scss';

const validValues = {
  email: '',
};

export const ForgotPassword = (): JSX.Element => {
  const isLoadingEnabled = useSelector(isLoading);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t } = useTranslation();
  const handleSignup = useCallback(
    (values: ForgotPasswordValues): void => {
      dispatch(requestChangePassword(values))
        .unwrap()
        .then(() => {
          Notification({ message: t('agent.forgetPassword.pleaseCheckEmail'), type: NotificationType.SUCCESS });
        });
    },
    [dispatch, t]
  );

  return (
    <div className={classnames('px-lg-4 px-sm-0 px-md-5', styles.container)}>
      <Row className={styles.containerRow}>
        <Col xl={4} lg={4} md={8} className={styles.signUpCol}>
          <HomeDesign className={classnames(styles.homeDesignTop)} />
          <Card className={styles.cardContainer}>
            <HomeDesign className={classnames(styles.homeDesignBottom)} />
            <div className="card-body py-5 px-5">
              <div className={styles.loginHeading}>{t('agent.login.resetPassword')}</div>

              <Formik initialValues={validValues} validationSchema={ForgotPasswordSchema} onSubmit={handleSignup}>
                {({ isValid, dirty: isDirty }) => (
                  <Form>
                    <FormikField
                      className="inputBackgroundColor"
                      name="email"
                      type="email"
                      label="Email Address"
                      placeholder="Email"
                    />
                    <div className={styles.rememberMePasswordContainer}></div>
                    <div className={styles.createBtn}>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isLoadingEnabled || !(isValid && isDirty)}
                        className={styles.btnPrimary}
                      >
                        {t('agent.requestResetLink')} {isLoadingEnabled && <CustomSpinner />}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
