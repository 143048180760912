import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import { capitalize } from 'lodash-es';

import { VerificationStatus } from 'constants/agentConstants';
import { formatDateToShortFormat } from 'helpers/applicantsHelper';
import { formatPhoneNumber, formatSSN } from 'helpers/renterHelper';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

import { FileCheckSVG } from '../../svgs/FIleCheckSVG';
import { FieldViewPDF } from '../shared/FieldViewPDF/FieldViewPDF';
import { HeadingPDF } from '../shared/HeadingPDF/HeadingPDF';
import { VerificationStatusPDF } from '../shared/VerificationStatusPDF/VerificationStatusPDF';

interface Props {
  renterDetails: RenterProfileInformationProps;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingVertical: '5px',
  },
  firstCol: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
  },
  itemRow: {
    flexDirection: 'row',
    gap: '40px',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    gap: '30px',
    alignItems: 'center',
  },
  singleItemRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  driversLicenseHeading: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  licenseRow: {
    display: 'flex',
    padding: '4px 12px',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #E6EFF0',
  },
  licenseNumber: {
    flexDirection: 'row',
    width: '100%',
    gap: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  licenseState: {
    backgroundColor: '#E6EFF0',
    paddingHorizontal: '2px',
    borderRadius: '2px',
  },
  documentRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    fontSize: '12px',
    color: '#303333',
  },
  documentType: {
    fontSize: '11px',
    color: '#5E6566',
    fontWeight: 'semibold',
    flex: 1,
  },
  DocumentInformation: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    fontWeight: 'semibold',
    fontSize: '12px',
    color: '#303333',
    alignItems: 'center',
    padding: '4px 2px',
  },
  DocumentImage: {
    width: '16px',
    height: '14px',
  },
  DocumentLink: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
    textDecoration: 'none',
  },
});

export const RenterProfilePDF = ({ renterDetails }: Props): JSX.Element => {
  const { renterPhoneNumbers } = renterDetails;
  const mobileNumber = renterPhoneNumbers?.find((number) => number.type === 'Mobile');
  const homeNumber = renterPhoneNumbers?.find((number) => number.type === 'Home');

  return (
    <View>
      <HeadingPDF heading={'Profile'} />
      <View style={styles.container}>
        <View style={styles.firstCol}>
          <FieldViewPDF label={'Email'} value={renterDetails.email ?? '-'} />
          <FieldViewPDF
            label={'DOB'}
            value={formatDateToShortFormat(new Date(renterDetails.birthDate).toDateString())}
          />
        </View>
        <View style={styles.firstCol}>
          <View style={{ ...styles.row, marginRight: '30px' }}>
            <FieldViewPDF
              label={'Mobile'}
              value={mobileNumber?.phoneNumber ? formatPhoneNumber(mobileNumber.phoneNumber) : '-'}
            />
            <FieldViewPDF
              label={'Home'}
              value={homeNumber?.phoneNumber ? formatPhoneNumber(homeNumber.phoneNumber) : '-'}
            />
          </View>
          <View style={styles.singleItemRow}>
            <FieldViewPDF
              label={'Social Security Number'}
              value={formatSSN(renterDetails.ssnTailDigits as string, true)}
            />
          </View>
        </View>
      </View>

      <View style={styles.licenseRow}>
        <Text style={styles.documentType}>{capitalize(renterDetails.stripeDocument?.documentType)}</Text>

        <View style={styles.licenseNumber}>
          <View style={styles.DocumentInformation}>
            <FileCheckSVG />
            <Link
              src={renterDetails.stripeDocument?.stripeVerificationDocumentFileLink[0]?.documentFileLink}
              style={styles.DocumentLink}
            >
              {renterDetails.stripeDocument?.documentId}
            </Link>

            <View style={styles.licenseState}>
              <Text>{renterDetails.stripeDocument?.state}</Text>
            </View>
          </View>
          <VerificationStatusPDF
            status={renterDetails.stripeVerified ? VerificationStatus.VERIFIED : VerificationStatus.IN_PROGRESS}
          />
        </View>
      </View>
    </View>
  );
};
