import { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { renterId } from 'redux/selectors/authSelector';
import { RenterAddresses, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import {
  addRenterAddressInformation,
  addRenterProfileInformation,
  updateRenterAddressInformation,
} from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as CalendarIcon } from 'assets/svgs/CalenderIconGrey.svg';
import { ReactComponent as IdIcon } from 'assets/svgs/GenericIdIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/svgs/InformationIcon.svg';
import { ReactComponent as ProfileIcon } from 'assets/svgs/ProfileIcon.svg';
import { ReactComponent as ArrowLoader } from 'assets/svgs/YellowArrowLoader.svg';
import Button from 'components/shared/Button/Button';
import Spinner from 'components/shared/Spinner/Spinner';
import {
  RenterProfileSteps,
  StripeManualVerificationSteps,
  UNINITIALIZED_STRIPE_VERIFICATION_ERROR,
} from 'constants/renterConstants';
import { parseResponseErrors } from 'helpers/helper';
import { formatStripeAddress, formatUserInformation } from 'helpers/stripeHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { getStripeInformation, getStripeSensitiveInformation } from 'services/stripeService';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { RenterProfileProps } from 'shared/types/renterTypes';
import { stripeInterval, StripeResponse, StripeWaitingProps } from 'shared/types/stripeType';

import RenterContainer from '../../RenterContainer';
import { AcceptedIdType } from '../StripeDocumentUpload/AcceptedIdType';

import styles from './StripeWaiting.module.scss';

const StripeWaiting = ({ setWaitingFalse, handleClick }: StripeWaitingProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const renterAddress = useSelector(RenterAddresses);
  const renterUserID = useSelector(renterId);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const [shouldClearInterval, setClearIntervalFlag] = useState(false);
  const [hasInfoUpdated, setHasInfoUpdated] = useState(false);
  const [hasAddressUpdated, setHasAddressUpdated] = useState(false);
  const [isStripeFailed, setIsStripeFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const stripeInfo = useRef<RenterProfileProps>({});
  const maxHeight = useDeviceHeight(160);
  const STRIPE_INFO_LIST = [
    { title: t('renter.stripeWaiting.name'), icon: <ProfileIcon /> },
    { title: t('renter.stripeWaiting.idNum'), icon: <IdIcon /> },
    { title: t('renter.stripeWaiting.dob'), icon: <CalendarIcon /> },
    { title: t('renter.stripeWaiting.address'), icon: <IdIcon /> },
    { title: t('renter.stripeWaiting.idType'), icon: <IdIcon /> },
  ];
  const retryCallback = useCallback(() => {
    setHasAddressUpdated(false);
    setHasInfoUpdated(false);
    setIsStripeFailed(false);
    setClearIntervalFlag(false);
    setIsLoading(false);
  }, []);
  const handleStripeRetry = (): void => {
    handleClick && handleClick({ successCallBack: retryCallback, handleLoading: setIsLoading });
  };
  const getStripeData = useCallback(() => {
    getStripeInformation()
      .then((res: AxiosResponse) => {
        const { payload } = res.data;

        if (!payload) {
          return;
        }

        if (payload?.errors) {
          setClearIntervalFlag(true);
          setIsStripeFailed(true);

          setWaitingFalse();
          Notification({ message: payload.errors.reason });
        }

        if (payload?.status === StripeResponse.VERIFIED) {
          getStripeSensitiveInformation()
            .then((response: AxiosResponse) => {
              setClearIntervalFlag(false);

              const { payload: secretPayload } = response.data;
              const stripeAddress = formatStripeAddress(payload.document.address, renterAddress);

              stripeInfo.current = formatUserInformation(payload.document, secretPayload.dateOfBirth);

              stripeInfo.current.id = renterUserID || renterDetails.userId;

              dispatch(addRenterProfileInformation({ values: stripeInfo.current }))
                .unwrap()
                .finally(() => {
                  setHasInfoUpdated(true);
                });

              if (stripeAddress.id) {
                dispatch(
                  updateRenterAddressInformation({
                    id: stripeAddress.id,
                    address: stripeAddress,
                  })
                )
                  .unwrap()
                  .finally(() => {
                    setHasAddressUpdated(true);
                  });
              } else {
                dispatch(
                  addRenterAddressInformation({
                    address: { ...stripeAddress, isRented: false },
                  })
                )
                  .unwrap()
                  .finally(() => {
                    setHasAddressUpdated(true);
                  });
              }
            })
            .catch((error) => {
              Notification({ message: parseResponseErrors(error) });

              setIsStripeFailed(true);
              setWaitingFalse();
            });
        }
      })
      .catch((error) => {
        setIsStripeFailed(true);
        setClearIntervalFlag(true);
        setIsStripeFailed(true);

        setWaitingFalse();

        if (error?.response?.data?.errors?.[0]?.errorCode === UNINITIALIZED_STRIPE_VERIFICATION_ERROR) {
          Notification({ message: t('renter.stripeWaiting.pleaseCompleteYourStripe') });
        } else {
          Notification({ message: parseResponseErrors(error) });
        }
      });
  }, [setWaitingFalse, renterAddress, renterUserID, renterDetails.userId, dispatch, t]);

  useEffect(() => {
    const timer = setInterval(() => {
      getStripeData();
    }, stripeInterval);

    if (shouldClearInterval) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [getStripeData, shouldClearInterval]);

  useEffect(() => {
    if (hasInfoUpdated && hasAddressUpdated && !renterDetails.stripeVerified) {
      stripeInfo.current.stripeVerified = true;

      dispatch(addRenterProfileInformation({ values: stripeInfo.current }))
        .unwrap()
        .then(() => {
          setWaitingFalse();
          navigate(renterRoutes.generatePersonalInformationUrl(RenterProfileSteps.BASICINFO, Number(applicationId)));
        });
    }
  }, [
    hasInfoUpdated,
    hasAddressUpdated,
    renterDetails,
    renterUserID,
    dispatch,
    setWaitingFalse,
    navigate,
    applicationId,
  ]);

  return (
    <RenterContainer shouldShowNavigation={false}>
      <div className={styles.container} style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
        {isStripeFailed ? <InfoIcon /> : <ArrowLoader className={styles.arrowLoader} />}
        <div className={styles.heading}>
          {isStripeFailed ? t('renter.stripeFail.oops') : t('renter.stripeWaiting.inProgress')}
        </div>
        <div className={styles.subHeading}>
          {isStripeFailed ? (
            t('renter.stripeFail.didNotWork')
          ) : (
            <>
              {t('renter.stripeWaiting.doNotLeave')} <br />
              {t('renter.stripeWaiting.hold')}
            </>
          )}
        </div>
        <div className={styles.mainText}>
          {isStripeFailed ? (
            t('renter.stripeFail.dontWorry')
          ) : (
            <>
              {t('renter.stripeWaiting.weWorkingToGather')}
              <br />
              {t('renter.stripeWaiting.thankYouFor')}
            </>
          )}
        </div>
        {isStripeFailed ? (
          <>
            <AcceptedIdType />
            <div className={styles.ButtonContainer}>
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  navigate(
                    renterRoutes.generateStripeManualVerification(
                      Number(applicationId),
                      StripeManualVerificationSteps.RENTER_INFO
                    )
                  );
                }}
                className={styles.btnPrimary}
              >
                {t('renter.stripeFail.tryDifferentWay')}
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={handleStripeRetry}
                className={styles.btnPrimary}
                disabled={isLoading}
              >
                <div className={styles.ButtonText}>
                  {t('renter.stripeFail.tryAgain')}
                  {isLoading && (
                    <span className={styles.loader}>
                      <Spinner />
                    </span>
                  )}
                </div>
              </Button>
            </div>
          </>
        ) : (
          <Row className={styles.InfoContainer}>
            <div className={styles.InfoText}>{t('renter.stripeWaiting.information')}</div>
            {STRIPE_INFO_LIST.map((info, index) => (
              <Col key={`${info.title}-${index}}`} xs={6} md={6} className={styles.InfoItemContainer} ms-auto>
                <span className={styles.InfoItem}>
                  {info.icon}
                  <div className={styles.InfoItemTitle}>{info.title}</div>
                </span>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </RenterContainer>
  );
};

export default StripeWaiting;
