import classNames from 'classnames';
import { isEmpty } from 'lodash-es';

import { ReportItem } from 'components/shared/ReportItem/ReportItem';
import { getCodeFormated, getValueFormated, isEven } from 'helpers/reportsHelper';
import { SubReportProps } from 'shared/types/reportTypes';

import styles from './SubReport.module.scss';

export const SubReport = ({
  heading,
  isHeadingColored = false,
  customHeadingClass,
  subReportItems,
  notToBoldItems,
  toBoldItems,
  customContainerClass,
}: SubReportProps): JSX.Element => (
  <div className={classNames(styles.Container, customContainerClass)}>
    <div className={classNames(styles.Heading, customHeadingClass, { [styles.ColoredHeading]: isHeadingColored })}>
      {heading}
    </div>
    {Object.keys(subReportItems).map((label, index) => (
      <ReportItem
        key={`sub-report-${heading}-${label}-${getValueFormated(subReportItems[label])}-${index}`}
        hasGreyBackground={!isEven(index)}
        label={label}
        value={getValueFormated(subReportItems[label])}
        code={getCodeFormated(subReportItems[label])}
        isValueBold={isEmpty(notToBoldItems) ? toBoldItems?.includes(label) : !notToBoldItems?.includes(label)}
      />
    ))}
  </div>
);
