import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { ReactComponent as DropDownIcon } from 'assets/svgs/ArrowDownWithBackgroundColor.svg';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import FormikField from 'components/shared/TextField/FormikTextField';
import { addressClassificationOptions, OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY } from 'constants/renterConstants';
import { VerifierEditDetailsFormProps } from 'helpers/address';
import { AddressInitialValuesProps } from 'shared/types/renterTypes';

import styles from './VerifierEditDetailsForm.module.scss';

export const VerifierEditDetailsForm = ({
  ownerName,
  emailName,
  phoneName,
  reasonForMovingName,
  descriptionName,
  classificationName,
  hasClassification,
  hasReasonForMoving,
  isRenting,
}: VerifierEditDetailsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { dirty: isDirty, values } = useFormikContext<AddressInitialValuesProps>();

  return (
    <div className={styles.addressEditDetailsContainer}>
      <FormCloseWarning isDirty={isDirty} />
      {isRenting && (
        <div className={styles.textFieldContainer}>
          <span className={styles.textFieldLabel}>{t('renter.Ownerinformation.owner/Manager')}</span>
          <FormikField name={ownerName} />
        </div>
      )}
      {isRenting && (
        <div className={styles.textFieldContainer}>
          <span className={styles.textFieldLabel}>{t('application.phone')}</span>
          <FormikField name={phoneName} />
        </div>
      )}
      {isRenting && (
        <div className={styles.textFieldContainer}>
          <span className={styles.textFieldLabel}>{t('renter.Ownerinformation.email')}</span>
          <FormikField name={emailName} />
        </div>
      )}
      {hasClassification && (
        <FormikSelectField
          name={classificationName}
          placeholder={t('agent.userInviteForm.select')}
          hasFloatingLabel
          floatingLabel={t('address.livingSituation.classification.')}
          options={addressClassificationOptions}
          className={styles.SelectContainer}
          dropDownIcon={<DropDownIcon />}
          dropDownIconContainer={styles.DropDownIconContainer}
        />
      )}

      {hasClassification &&
        values.classification === addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label && (
          <div className={styles.textFieldContainer}>
            <span className={styles.textFieldLabel}>{t('agent.properties.property.description')}</span>
            <FormikField name={descriptionName} />
          </div>
        )}

      {hasReasonForMoving && (
        <div className={styles.textFieldContainer}>
          <span className={styles.textFieldLabel}>{t('agnet.renterResumePdf.reasonForMoving')}</span>
          <FormikField name={reasonForMovingName} />
        </div>
      )}
    </div>
  );
};
