import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import classNames from 'classnames';
import { Form, Formik, FormikErrors } from 'formik';
import {
  agentOrganizationInviteDetails,
  inviteDetails,
  renterInviteCode,
  selectAdultInviteDetails,
  selectGuarantorInviteDetails,
  selectInviteCode,
} from 'redux/selectors/authSelector';
import { isLoading } from 'redux/selectors/authSelector';
import { setUserType, signup } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';
import { SignupFormSchema } from 'schema/authSchema';

import { ReactComponent as HomeDesign } from 'assets/svgs/authBackGround.svg';
import { ReactComponent as PasswordIcon } from 'assets/svgs/PasswordIcon.svg';
import { ReactComponent as PasswordSlashIcon } from 'assets/svgs/PasswordSlashIcon.svg';
import Button from 'components/shared/Button/Button';
import CustomErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import { PasswordGuidelines } from 'components/shared/PasswordGuidelines/PasswordGuidelines';
import Spinner from 'components/shared/Spinner/Spinner';
import FormikField from 'components/shared/TextField/FormikField';
import { AgentProfileFormSteps } from 'constants/agentConstants';
import { encodedRenterRole, MAX_FIELD_LENGTH } from 'constants/renterConstants';
import { tabTitle } from 'helpers/helper';
import { IntellirentPublicUrls } from 'shared/constants';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes, routes } from 'shared/routes';
import { AuthValues, UserType } from 'shared/types/authType';
import { APPLICANT_FIRSTNAME_LIMIT, APPLICANT_LASTNAME_LIMIT, FieldType } from 'shared/types/renterSignup';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from './Signup.module.scss';

const Signup = (): JSX.Element => {
  const { t } = useTranslation();
  const [errorValue, setErrorValue] = useState<string>('');
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const isLoadingEnabled = useSelector(isLoading);
  const navigate = useNavigate();
  const [location] = useSearchParams();
  const inviteCode = useSelector(selectInviteCode);
  const adultGuarantorInviteCode = useSelector(renterInviteCode);
  const { invitedAgentEmail } = useSelector(agentOrganizationInviteDetails);
  const isPageRenter = location.get('r')?.toLowerCase() === encodedRenterRole.toLowerCase();
  const renterInviteDetails = useSelector(inviteDetails);
  const adultInviteDetails = useSelector(selectAdultInviteDetails);
  const guarantorInviteDetails = useSelector(selectGuarantorInviteDetails);
  const { agentFirstName, agentLastName, renterFirstName, renterLastName, propertyDetails } = renterInviteDetails;
  const { streetAddress1 = '', streetAddress2 = '' } = propertyDetails ?? {};
  const agentFullName = agentFirstName + agentLastName;
  const fullAddress = streetAddress1 && streetAddress1 + streetAddress2;
  const [searchParams] = useSearchParams();
  const renterRole = searchParams.get('r');

  if (renterRole) {
    tabTitle('Start your rental application');
  } else {
    tabTitle('Create your free account');
  }

  const validValues = {
    email: isPageRenter
      ? renterInviteDetails.renterEmail || adultInviteDetails.adultEmail || guarantorInviteDetails.guarantorEmail || ''
      : invitedAgentEmail ?? '',
    password: '',
    userType: '',
  };
  const isValidName = useCallback(
    (input?: string, type?: FieldType): boolean => Boolean(input && type && input.length <= MAX_FIELD_LENGTH[type]),
    []
  );
  const getDisplayName = useMemo(
    () =>
      (type: FieldType, fullText?: string, subtext?: string): string => {
        if (isValidName(fullText, type) && fullText && subtext) {
          if (type === 'address') {
            return `, ${subtext}`;
          }

          if (type === 'agentFullName') {
            return ` ${subtext}`;
          }
        }

        return '';
      },
    [isValidName]
  );
  const getApplicantName = useMemo(
    () =>
      (firstName: string | undefined, lastName: string | undefined): string => {
        if (firstName) {
          //check if its a valid full name
          if (isValidName(firstName + lastName, 'applicantFullName')) {
            return firstName + (lastName ? ' ' + lastName : '');
          } else {
            return firstName;
          }
        } else if (lastName && lastName.length <= APPLICANT_LASTNAME_LIMIT) {
          return lastName;
        }

        return '';
      },
    [isValidName]
  );
  const displayWelcomeText = useCallback((): ReactNode => {
    if (agentFullName) {
      return (
        <>
          {t('renter.signup.hasInvitedYou')}
          {t('renter.signup.to')} <br />
          {t('renter.signup.beginYourrenterApplication')}
        </>
      );
    } else {
      return t('renter.signup.pleaseSignupToSubmit');
    }
  }, [agentFullName, t]);
  const onSuccess = useCallback((): void => {
    dispatch(setUserType(isPageRenter ? UserType.RENTER : UserType.AGENT));

    if (isPageRenter) {
      // send the invite code and invite type to acceptInviteDetails if they are present
      navigate(renterRoutes.renter);
    } else {
      !!inviteCode
        ? navigate(routes.generateAgentProfileForm(AgentProfileFormSteps.INVITED))
        : navigate(routes.agentProfileForm);
    }
  }, [dispatch, inviteCode, isPageRenter, navigate]);
  const onError = (error: string): void => setErrorValue(error);
  const handleSubmit = (
    values: AuthValues,
    { setErrors }: { setErrors: (errors: FormikErrors<AuthValues>) => void }
  ): void => {
    values.userType = isPageRenter ? UserType.RENTER : UserType.AGENT;

    if ((isPageRenter && (!!inviteCode || !!adultGuarantorInviteCode?.inviteCode)) || !isPageRenter) {
      dispatch(signup({ values }))
        .unwrap()
        .then(() => {
          onSuccess();
        })
        .catch(({ errorOnField, errorMessage }) => {
          if (errorOnField) {
            setErrors({ email: `${errorOnField} ${errorMessage}` });
          } else {
            onError(errorMessage);
          }
        });
    } else {
      Notification({ message: t('renter.signup.infoText'), type: 'info' });
    }
  };
  const isEmailDisabled = useMemo(
    () =>
      isPageRenter
        ? Boolean(renterInviteDetails.renterEmail) ||
          Boolean(adultInviteDetails.adultEmail) ||
          Boolean(guarantorInviteDetails.guarantorEmail)
        : Boolean(invitedAgentEmail),
    [
      adultInviteDetails.adultEmail,
      guarantorInviteDetails.guarantorEmail,
      invitedAgentEmail,
      isPageRenter,
      renterInviteDetails.renterEmail,
    ]
  );

  return (
    <div className={classnames('px-lg-4 px-md-5 px-sm-0', styles.container, styles.renterContainer)}>
      <Row className={classnames(styles.containerRow, { [styles.renterContainerRow]: isPageRenter })}>
        <Col lg={4} md={6} className={classnames({ [styles.renterTextContainer]: isPageRenter })}>
          {!isPageRenter && (
            <>
              <h1 className={styles.signupTextContainer}>
                {t('agent.signup.createYourFree')} <br />
                {t('agent.signup.agentAccount')}
              </h1>
              <div className={styles.signupSubText}>
                {t('agent.signup.startScreeningNewResidentsAnd')}
                <br />
                {t('agent.signup.advertisingRentersWithAnImprovedProcess')}
                <br />
                {t('agent.signup.thatWillSaveYouHours')}.
              </div>
            </>
          )}
          {isPageRenter && (
            <div className={styles.renterSignUpTextContainer}>
              <h1
                className={classnames(styles.signupTextContainer, styles.renterSignUpText, {
                  [styles.firstNameLimiter]: renterFirstName && renterFirstName.length >= APPLICANT_FIRSTNAME_LIMIT,
                })}
              >{`${t('renter.signup.welcome')} ${getApplicantName(renterFirstName, renterLastName)}`}</h1>
              <div className={styles.renterSignUpSubText}>
                <b className={styles.inLineTextWrap}>{` ${agentFirstName || ''}${getDisplayName(
                  'agentFullName',
                  agentFullName,
                  agentLastName
                )} `}</b>
                {displayWelcomeText()}
                {streetAddress1 ? ` ${t('renter.signup.for')}` : '.'}
                <br />
                <b className={styles.inLineTextWrap}>{`${streetAddress1 || ''}${getDisplayName(
                  'address',
                  fullAddress,
                  streetAddress2
                )}`}</b>
              </div>
            </div>
          )}
        </Col>

        <Col lg={3} md={4} className={classnames(styles.signupCol, { [styles.renterSignupCol]: isPageRenter })}>
          <HomeDesign
            className={classnames('d-none d-lg-block', styles.homeDesign, {
              [styles.renterHomeDesign]: isPageRenter,
            })}
          />
          <Card className={classnames(styles.cardContainer, { [styles.renterCardContainer]: isPageRenter })}>
            <div className={classnames('card-body py-5 px-5', { [styles.renterCardBody]: isPageRenter })}>
              <Formik
                initialValues={validValues}
                enableReinitialize
                validationSchema={SignupFormSchema}
                onSubmit={(values, { setErrors }) => handleSubmit(values, { setErrors })}
              >
                {({ isValid, dirty: isDirty, values }) => (
                  <Form>
                    {!isPageRenter && (
                      <div className={styles.FormHeading}>{t('agent.signup.createYourFreeAgentAccount')}</div>
                    )}
                    <FormikField
                      className="inputBackgroundColor"
                      name="email"
                      type="email"
                      label="Email Address"
                      placeholder="Email"
                      disabled={isEmailDisabled}
                    />
                    <FormikField
                      className={classNames('inputBackgroundColor', styles.PasswordField)}
                      name="password"
                      type={isPasswordVisible ? 'text' : 'password'}
                      label="Password"
                      placeholder="Password"
                      onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      customIcon={isPasswordVisible ? <PasswordSlashIcon /> : <PasswordIcon />}
                    />
                    {errorValue && <CustomErrorMessage message={errorValue} />}

                    <PasswordGuidelines password={values.password} />

                    {!isPageRenter && (
                      <div className={styles.privacyPolicyContainer}>
                        {`${t(
                          'agent.signup.WereCommittedToMaintainingASafeOnlineCommunityForAgents&Renters.ByCreatingAnAccount,IAgreeToThe'
                        )}`}
                        &nbsp;
                        <Link
                          to={IntellirentPublicUrls.PRIVACY_POLICY}
                          className={styles.loginLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('agent.signup.PrivacyPolicy')}
                        </Link>
                        {` ${t('application.and')} `}
                        <Link
                          to={IntellirentPublicUrls.TERMS_AND_POLICY}
                          className={styles.loginLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('agent.signup.TermsAndConditions')}
                        </Link>
                      </div>
                    )}
                    {isPageRenter && (
                      <div className={styles.privacyPolicyContainer}>
                        {t('renter.signup.byCreatingAccount')}&nbsp;
                        <Link
                          to={IntellirentPublicUrls.PRIVACY_POLICY}
                          className={styles.SignupLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('renter.signup.privacyPolicy')}
                        </Link>
                        &nbsp;{t('renter.signup.and')}&nbsp;
                        <Link
                          to={IntellirentPublicUrls.RENTER_TERMS_OF_USE}
                          className={styles.SignupLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('renter.signup.termsOfUse')}
                        </Link>
                      </div>
                    )}

                    <div className={styles.createBtn}>
                      <Button
                        disabled={isLoadingEnabled || !(isValid && isDirty)}
                        variant="primary"
                        type="submit"
                        className={styles.btnPrimary}
                      >
                        {t('agent.signup.createAccount')} {isLoadingEnabled && <Spinner />}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className={styles.intellirentFooterContainer}>
              <div className={styles.intellirentFooter}>
                <NavLink to={routes.home} className={classnames('navbar-brand', styles.navHeading)}>
                  <span className={styles.navIntelli}>intelli</span>
                  <span className={styles.navRent}>rent</span>
                </NavLink>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Signup;
