import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { parseISO } from 'date-fns';
import { Formik } from 'formik';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { renterManualVerificationSchema } from 'schema/renterSchema';

import indexStyles from 'components/Renter/RenterIndex.module.scss';
import { useDeviceHeight } from 'hooks/useDeviceHeight';

import RenterContainer from '../../RenterContainer';
import { StripeRenterInfoForm } from '../StripeRenterInfoForm/StripeRenterInfoForm';

export const StripeManualVerification = (): JSX.Element => {
  const renterProfileInformation = useSelector(selectRenterProfileInformation);
  const maxHeight = useDeviceHeight(220);
  const initialValues = useMemo(
    () => ({
      ...renterProfileInformation,
      birthDate: renterProfileInformation.birthDate && new Date(parseISO(renterProfileInformation.birthDate)),
    }),
    [renterProfileInformation]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={renterManualVerificationSchema}
      enableReinitialize
      validateOnBlur
      onSubmit={(values) => {
        console.log('formik values', values);
      }}
    >
      <RenterContainer>
        <section className={indexStyles.renterBody}>
          <div style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
            <StripeRenterInfoForm />
          </div>
        </section>
      </RenterContainer>
    </Formik>
  );
};
