import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { capitalize } from 'lodash-es';

import { VehiclesInformationType } from 'shared/types/renterVehicleInformation';

import { FieldViewPDF } from '../FieldViewPDF/FieldViewPDF';

interface Props {
  vehicle: VehiclesInformationType;
}
const styles = StyleSheet.create({
  container: {
    width: '100%',
    border: '1px solid #E6EFF0',
    borderRadius: '4px',
    padding: '8px 4px',
    marginVertical: '8px',
  },
  type: {
    fontWeight: 'semibold',
    color: '#303333',
    fontSize: '12px',
  },
  details: {
    width: '100%',
    flexDirection: 'row',
    gap: '14px',
    justifyContent: 'flex-start',
  },
  fieldContainer: {
    width: '74px',
  },
});

export const VehicleCardPDF = ({ vehicle }: Props): JSX.Element => {
  const vehicleItems = [
    { label: 'License Plate', value: vehicle.licensePlate },
    { label: 'State', value: vehicle.stateIssued },
    { label: 'Make', value: vehicle.make },
    { label: 'Model', value: vehicle.model },
    { label: 'Color', value: vehicle.color },
    { label: 'Year', value: vehicle.yearOfVehicle },
  ];

  return (
    <View style={styles.container}>
      <Text style={styles.type}>{capitalize(vehicle.vehicleType)}</Text>
      <View style={styles.details}>
        {vehicleItems.map((item) => (
          <View style={styles.fieldContainer} key={item.label}>
            <FieldViewPDF label={item.label} value={item.value ?? '-'} />
          </View>
        ))}
      </View>
    </View>
  );
};
