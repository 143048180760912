import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { ReactComponent as DropDownIcon } from 'assets/svgs/ArrowDownWithBackgroundColor.svg';
import AddressBox from 'components/shared/AddressBox/AddressBox';
import RCButton from 'components/shared/Button/Button';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import FormikField from 'components/shared/TextField/FormikTextField';
import { addressClassificationOptions, OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY } from 'constants/renterConstants';
import { AddressInitialValuesProps, AddressLivingSituationProps } from 'shared/types/renterTypes';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './AddressLivingSituation.module.scss';
export const AddressLivingSituation = ({
  address,
  mainHeading,
  subHeading,
  isPrior = false,
  onClickHandler,
  customButtonText,
  isForRent = false,
}: AddressLivingSituationProps): JSX.Element => {
  const { t } = useTranslation();
  const { values, errors } = useFormikContext<AddressInitialValuesProps>();
  //Checking if all fields are filled and without error
  const isButtonVisible = useMemo((): boolean => {
    if (
      (isForRent || (!!values.renterAddress?.classification && !errors.renterAddress?.classification)) &&
      (isPrior || (!!values.renterAddress?.reasonForMoving && !errors.renterAddress?.reasonForMoving))
    ) {
      //Checking if classification is "Other" then the description is filled
      if (
        values.renterAddress?.classification ===
          addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label &&
        (!values.renterAddress?.description || !!errors.renterAddress?.description)
      ) {
        return false;
      }

      return true;
    }

    return false;
  }, [
    errors.renterAddress?.classification,
    errors.renterAddress?.description,
    errors.renterAddress?.reasonForMoving,
    isForRent,
    isPrior,
    values.renterAddress?.classification,
    values.renterAddress?.description,
    values.renterAddress?.reasonForMoving,
  ]);

  return (
    <div className={styles.Container}>
      <p className={classNames(indexStyles.renterH2, styles.MainHeading)}>
        {mainHeading}
        {!!subHeading && <span className={styles.RenterH2Color}>{subHeading}</span>}
      </p>
      <AddressBox address={address} doShowMoreData />
      {!isForRent && (
        <FormikSelectField
          name="renterAddress.classification"
          placeholder={t('agent.userInviteForm.select')}
          hasFloatingLabel
          floatingLabel={t('address.livingSituation.classification.')}
          options={addressClassificationOptions}
          className={styles.SelectContainer}
          dropDownIcon={<DropDownIcon />}
          dropDownIconContainer={styles.DropDownIconContainer}
        />
      )}
      {values?.renterAddress.classification ===
        addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label && (
        <div className={styles.TextFieldContainer}>
          <span className={styles.TextFieldLabel}>{t('agent.properties.property.description')}</span>
          <FormikField
            name="renterAddress.description"
            placeholder={t('address.livingSituation.typeMoreInformationHere')}
          />
        </div>
      )}

      {!isPrior && (
        <div className={styles.TextFieldContainer}>
          <span className={styles.TextFieldLabel}>{t('agnet.renterResumePdf.reasonForMoving')}</span>
          <FormikField
            name="renterAddress.reasonForMoving"
            placeholder={t('address.livingSituation.typeDescriptionHere')}
          />
        </div>
      )}
      <RCButton onClick={onClickHandler} className={styles.SubmitButton} disabled={!isButtonVisible}>
        {customButtonText || t('application.done')}
      </RCButton>
    </div>
  );
};
