import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';

import { ReactComponent as EditIcon } from 'assets/svgs/Edit.svg';
import { ReactComponent as TickIcon } from 'assets/svgs/SimpleTick.svg';
import { RenterInformation } from 'shared/types/renterInformation';

import styles from './RenterInfoDetails.module.scss';

const RenterInfoDetails = ({
  name,
  dateOfBirth,
  idType,
  idNumber,
  address,
  onEditPress,
  onEditAdressPress,
  hasMiddleNameCheck = false,
  customParentClass,
}: RenterInformation): JSX.Element => {
  const { t } = useTranslation();
  const renterDetails = useSelector(selectRenterProfileInformation);

  return (
    <div className={classNames(styles.RenterInfoDetails, customParentClass)}>
      {/* Renter Name */}
      <div className={styles.nameContainer}>
        <span className={styles.renterName}>{name}</span>
        <span>
          <button onClick={onEditPress} type="button" className={styles.editButton} data-testid="infoEditButton">
            {<EditIcon className={styles.editIcon} />}
          </button>
        </span>
      </div>
      {/* Address */}
      {renterDetails.stripeVerified && !!address && (
        <div className={styles.addressContainer}>
          <div className={styles.infomation}>
            <span className={styles.heading}>{address}</span>
            <span className={styles.subHeading}>{t('renter.CollectedInformation.presentAddress')}</span>
          </div>
          <div>
            <button
              onClick={onEditAdressPress}
              type="button"
              className={styles.editButton}
              data-testid="addressEditButton"
            >
              {<EditIcon className={styles.editIcon} />}
            </button>
          </div>
        </div>
      )}
      {hasMiddleNameCheck && (
        <div className={styles.MiddleNameCheckContainer}>
          <span className={styles.MiddleNameText}>{t('renter.profileSummary.iDoNotHaveAMiddleName')}</span>
          <span className={styles.MiddleNameFlag}>
            {t('renter.profileSummary.confirmed')} <TickIcon />
          </span>
        </div>
      )}
      {/* Renter DOB */}
      <div className={styles.infoContainer}>
        <span className={styles.heading}>{dateOfBirth}</span>
        <span className={styles.subHeading}>{t('renter.CollectedInformation.dateOfBirth')}</span>
      </div>
      {/* Driver’s License Details */}
      <div className={styles.idContainer}>
        <div className={styles.infoContainer}>
          <span className={styles.heading}>{idType}</span>
          <span className={styles.subHeading}>{t('renter.CollectedInformation.idType')}</span>
        </div>
        <div className={styles.infoContainer}>
          <span className={styles.heading}>{idNumber}</span>
          <span className={styles.subHeading}>{t('renter.CollectedInformation.idNumber')}</span>
        </div>
      </div>
    </div>
  );
};

export default RenterInfoDetails;
