import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import {
  applicantRenterRole,
  isLoading,
  renterPresentEmployments,
  renterPriorEmployments,
} from 'redux/selectors/renterSelector';

import RenterContainer from 'components/Renter/components/RenterContainer';
import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import Button from 'components/shared/Button/Button';
import { renterRestrictions } from 'constants/restrictionConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { EditEmploymentTypes } from 'shared/types/renterEmploymentInformation';

import DescriptionCard from './DescriptionCard/DescriptionCard';
import EditPresentIncome from './EditPresentIncome/EditPresentIncome';

import 'stylesheets/mixins/sharedStyles.scss';
import indexStyles from '../../RenterIndex.module.scss';
import styles from './RenterPresentIncomeSummary.module.scss';

const RenterPresentIncomeSummary = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const maxHeight = useDeviceHeight(220);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const presentEmployments = useSelector(renterPresentEmployments);
  const priorEmployments = useSelector(renterPriorEmployments);
  const isLoadingInformation = useSelector(isLoading);
  const onClickAddMore = useCallback(
    () => navigate(renterRoutes.generateRenterPresentSourceOfIncome(applicationId)),
    [applicationId, navigate]
  );
  const renterRole = useSelector(applicantRenterRole);
  const onClickNext = useCallback(() => {
    if (!priorEmployments?.length) {
      navigate(renterRoutes.generateRenterPriorSourceOfIncome(applicationId));
    } else {
      navigate(renterRoutes.generateRenterPriorSourceOfIncomeSummary(applicationId));
    }
  }, [applicationId, navigate, priorEmployments]);
  const onEditClick = useCallback(
    (parmas?: number): void => {
      if (parmas && presentEmployments) {
        const newFormValues = presentEmployments.find(({ id }) => id === parmas);

        if (newFormValues) {
          setIsModalOpen(true);
          setFormValues(newFormValues);
        }
      }
    },
    [presentEmployments]
  );

  useEffect(() => {
    if (renterRestrictions[renterRole].presentIncome) {
      if (!priorEmployments?.length) {
        navigate(renterRoutes.generateRenterPriorSourceOfIncome(applicationId));
      } else {
        navigate(renterRoutes.generateRenterPriorSourceOfIncomeSummary(applicationId));
      }
    }
  }, [applicationId, navigate, priorEmployments, renterRole]);

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        {isModalOpen && <EditPresentIncome data={formValues} onHide={() => setIsModalOpen(false)} />}
        <div
          className={styles.renterPresentIncomeSummary}
          style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
        >
          <RenterIconInfoContainer />
          <div className={styles.heading}>
            {t('renter.priorSourceOfIncomeSummary.heading')}
            <span>{t('renter.presentSourceOfIncomeSummary.heading')}</span>
          </div>
          <div className={classNames(styles.employmentContainer, 'documentRow')}>
            {presentEmployments?.map((employment) => {
              const { employmentSubType } = employment;

              return (
                <div className={styles.cardContainer} key={`${employment.employmentSubType}-${employment.id}`}>
                  <DescriptionCard
                    data={employment}
                    isSelfEmployed={employmentSubType === EditEmploymentTypes.SELF_EMPLOYED}
                    onEditClick={onEditClick}
                  />
                </div>
              );
            })}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              className={classNames(styles.renterAddButton, styles.buttonPrimary)}
              variant="outline"
              onClick={onClickAddMore}
              disabled={isLoadingInformation}
            >
              {t('renter.priorSourceOfIncome.addAnotherPresentEmployement')}
            </Button>
            <Button
              className={styles.buttonPrimary}
              variant="outline"
              onClick={onClickNext}
              disabled={isLoadingInformation}
            >
              {t('renter.ConfirmAddressHistory.looksGood')}
            </Button>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default RenterPresentIncomeSummary;
