import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { paginatedValues } from 'redux/selectors/propertySelector';

import { AgentMobileNavBar, AgentWebNavBar } from 'components/shared/Navbar';
import { ExperianRegistrationStatus } from 'constants/agentConstants';
import { urlMatcher } from 'helpers/agentHelper';
import { routes } from 'shared/routes';

interface AgentNavbarProps {
  shouldShowMobileNavBar: boolean;
}
const AgentNavbar = ({ shouldShowMobileNavBar }: AgentNavbarProps): JSX.Element => {
  const selectedOrg = useSelector(selectedOrganization);
  const agentProfileInformation = useSelector(selectAgentProfileInformation);
  const { pageInfo } = useSelector(paginatedValues);
  const location = useLocation();

  return shouldShowMobileNavBar ? (
    <AgentMobileNavBar
      selectedOrg={selectedOrg}
      agentProfileInformation={agentProfileInformation}
      pageInfo={pageInfo}
    />
  ) : (
    <AgentWebNavBar
      isProfileSet={
        (selectedOrg.experianVerified ||
          agentProfileInformation.experianRegistration === ExperianRegistrationStatus.SUCCEEDED) &&
        location.pathname !== routes.agentSecurityQuestionnaire &&
        !urlMatcher('agent/profile', location)
      }
      selectedOrg={selectedOrg}
      agentProfileInformation={agentProfileInformation}
    />
  );
};

export default AgentNavbar;
