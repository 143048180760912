import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RCButton from 'components/shared/Button/Button';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import indexStyles from './../RenterIndex.module.scss';
import styles from './NoCreditHistory.module.scss';
const NoCreditHistory = (): JSX.Element => {
  const { applicationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const { userId } = useSelector(selectRenterProfileInformation);
  const { t } = useTranslation();
  const maxHeight = useDeviceHeight(220);
  const handleOptionOnlick = (option: string): void => {
    const params = { visaType: option.replace('-', '_') };

    dispatch(addRenterProfileInformation({ values: { ...params, id: userId } }))
      .unwrap()
      .then(() => {
        applicationId && navigate(renterRoutes.generateCreditHistoryOthers(applicationId, option.toString()));
      });
  };

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        {/* icons list */}
        <div className={indexStyles.contentContainer}>
          <div
            className={styles.scrollContainer}
            style={{ paddingRight: '12px', maxHeight: maxHeight, overflowY: 'auto' }}
          >
            <RenterIconInfoContainer />
            <div className={indexStyles.renterContentContainer}>
              <h2 className={indexStyles.renterH2}>{t('renter.noCreditHistory.NeedHelp')}</h2>
              <div className={indexStyles.renterButtonContainer}>
                <RCButton
                  onClick={() => handleOptionOnlick('international-student')}
                  className={classNames(indexStyles.renterButton, styles.renterCreditButton)}
                  variant="outline"
                >
                  {t('renter.noCreditHistory.IAmAnInternationalStudent')}
                </RCButton>
              </div>
              <div className={indexStyles.renterButtonContainer}>
                <RCButton
                  onClick={() => handleOptionOnlick('work-visa')}
                  className={classNames(indexStyles.renterButton, styles.renterCreditButton)}
                  variant="outline"
                >
                  {t('renter.noCreditHistory.IAmInTheUSOnWorkVisa')}
                </RCButton>
              </div>
            </div>
            <div className={indexStyles.renterButtonContainer}>
              <RCButton
                onClick={() => handleOptionOnlick('other')}
                className={classNames(indexStyles.renterButton, styles.renterCreditButton)}
                variant="outline"
              >
                {t('renter.noCreditHistory.other')}
              </RCButton>
            </div>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default NoCreditHistory;
