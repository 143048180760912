import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import styles from 'components/Renter/IdentityVerification/RenterDetails/shared/Popover.module.scss';
import { closePopOverOnClick } from 'helpers/agentHelper';
import { isAddressVerifierPresent } from 'helpers/renterHelper';
import { AddressInformation } from 'shared/types/renterTypes';

type EditPriorAddressToolTipProps = {
  handleEditAddress: (address: AddressInformation) => void;
  handleEditVerifier: (address: AddressInformation) => void;
  currentAddress: AddressInformation;
};
const EditPriorAddressToolTip = ({
  handleEditAddress,
  handleEditVerifier,
  currentAddress,
}: EditPriorAddressToolTipProps): JSX.Element => {
  const { t } = useTranslation();
  const renderTooltip = (
    <Popover id="popover-positioned-bottom" hasDoneInitialMeasure>
      <Popover.Body className={styles.popoverBody}>
        <div className={styles.popoverWrapper}>
          <div>
            <button
              className={styles.popoverBtn}
              onClick={() => {
                handleEditAddress(currentAddress);
                closePopOverOnClick();
              }}
            >
              {t('addressBox.editAddress')}
            </button>
            {isAddressVerifierPresent(currentAddress) && (
              <button
                className={styles.popoverBtn}
                onClick={() => {
                  handleEditVerifier(currentAddress);
                  closePopOverOnClick();
                }}
              >
                {t('addressBox.editVerifier')}
              </button>
            )}
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose trigger="click" key="bottom" placement="bottom" overlay={renderTooltip}>
      <span className={styles.overlayTrigger}>
        <ThreeDotsIcon />
      </span>
    </OverlayTrigger>
  );
};

export default EditPriorAddressToolTip;
