import { useTranslation } from 'react-i18next';

import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import { formatDateToLastActiveFormat } from 'helpers/applicantsHelper';

import styles from './ActiveStatus.module.scss';

type Props = {
  activeDate: string;
  status: string;
};

export const ActiveStatus = ({ activeDate, status }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <AgentPills
        pillContainerClassName={styles.PillsContainer}
        pillsData={[{ heading: status, type: PillTypes.LINKS }]}
      />
      <div className={styles.Date}>{`${t('application.asOf')} ${formatDateToLastActiveFormat(activeDate)}`}</div>
    </div>
  );
};
