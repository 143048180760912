import { useCallback, useState } from 'react';
import { t } from 'i18next';

import { ReactComponent as CopyIcon } from 'assets/svgs/CopyIcon.svg';
import ButtonWithTooltip from 'components/shared/Button/ButtonWithTooltip';

type CopyIconButtonProps = {
  textToCopy: string;
  className: string;
  svgClassName?: string;
};

const CopyIconButton = ({ textToCopy, className, svgClassName }: CopyIconButtonProps): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false);
  const onButtonClick = useCallback(() => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      })
      .catch((error) => {
        console.error(t('agent.inviteApplicant.copy.error'), error);
      });
  }, [textToCopy]);

  return (
    <ButtonWithTooltip
      className={className}
      shouldShowTooltip
      variant="outline"
      tooltipText={t('agent.inviteApplicant.copy')}
      onClick={onButtonClick}
      disabled={isCopied}
    >
      <div>
        <CopyIcon className={svgClassName} />
      </div>
    </ButtonWithTooltip>
  );
};

export default CopyIconButton;
