import { useState } from 'react';
import { Popover, PopoverBody as Body } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/HorizontalDotsIcon.svg';
import styles from 'components/Renter/IdentityVerification/RenterDetails/shared/Popover.module.scss';

export const VerifierTooltip = ({ handleShowMore }: { handleShowMore: () => void }): JSX.Element => {
  const { t } = useTranslation();
  const [isShowOverlay, setIsShowOverlay] = useState(false);
  const onOptionSelect = (): void => {
    handleShowMore();
    setIsShowOverlay(false);
  };
  const renderTooltip = (
    <Popover id="popover-positioned-bottom" hasDoneInitialMeasure>
      <Body className={styles.popoverBody}>
        <div className={styles.popoverWrapper}>
          <button className={styles.popoverBtn} onClick={onOptionSelect}>
            {t('agent.showMore')}
          </button>
        </div>
      </Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      show={isShowOverlay}
      rootClose
      trigger="click"
      key="bottom"
      placement="bottom"
      overlay={renderTooltip}
    >
      <span className={styles.overlayTrigger}>
        <ThreeDotsIcon onClick={() => setIsShowOverlay(true)} />
      </span>
    </OverlayTrigger>
  );
};
