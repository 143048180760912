import { useTranslation } from 'react-i18next';

import { Ladder } from 'components/Renter/IdentityVerification/RenterDetails/shared/Ladder/Ladder';
import { DateRangeViewer } from 'components/shared/DateRangeViewer/DateRangeViewer';
import { ViewResidencyAnswerProps } from 'shared/types/agentTypes';

import styles from './ResidencyQuestions.module.scss';

export const ViewResidencyAnswer = ({ answer, dateRange, extraInformation }: ViewResidencyAnswerProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {answer && (
        <div className={styles.AnswerContainer}>
          <Ladder
            verticalBranchClassName={styles.LadderVerticalLine}
            horizontalBranchClassName={styles.LadderHorizontalLine}
          />
          <div className={styles.Answer}>
            <div className={styles.Label}>{t('renter.BackgroundInformation.responseLabel')}</div>
            <div className={styles.Response}>{answer}</div>
          </div>
        </div>
      )}
      {dateRange && (
        <div className={styles.AnswerContainer}>
          <Ladder
            verticalBranchClassName={styles.LadderVerticalLine}
            horizontalBranchClassName={styles.LadderHorizontalLine}
          />
          <div className={styles.Answer}>
            <DateRangeViewer toDate={dateRange.toDate} fromDate={dateRange.fromDate} />
          </div>
        </div>
      )}
      {extraInformation && (
        <div className={styles.AnswerContainer}>
          <Ladder
            verticalBranchClassName={styles.LadderVerticalLine}
            horizontalBranchClassName={styles.LadderHorizontalLine}
          />
          <div className={styles.Answer}>
            <div className={styles.Label}>{t('renter.residencyQuestion.answer')}</div>
            <div className={styles.Response}>{extraInformation}</div>
          </div>
        </div>
      )}
    </>
  );
};
