import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export const InProgressYellowSVG = (): JSX.Element => (
  <Svg width="14" height="15" viewBox="0 0 14 15" fill="#eea700">
    <G clip-path="url(#clip0_6137_3928)">
      <Path
        d="M7.00651 2.83203V1.08203L4.67318 3.41536L7.00651 5.7487V3.9987C8.93734 3.9987 10.5065 5.56786 10.5065 7.4987C10.5065 8.08786 10.3607 8.64787 10.0982 9.13203L10.9498 9.9837C11.4048 9.2662 11.6732 8.41453 11.6732 7.4987C11.6732 4.92036 9.58484 2.83203 7.00651 2.83203ZM7.00651 10.9987C5.07568 10.9987 3.50651 9.42953 3.50651 7.4987C3.50651 6.90953 3.65234 6.34953 3.91484 5.86537L3.06318 5.0137C2.60818 5.7312 2.33984 6.58286 2.33984 7.4987C2.33984 10.077 4.42818 12.1654 7.00651 12.1654V13.9154L9.33984 11.582L7.00651 9.2487V10.9987Z"
        fill="#EEA700"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_6137_3928">
        <Rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
      </ClipPath>
    </Defs>
  </Svg>
);
