import { useTranslation } from 'react-i18next';

import { ReactComponent as BellIcon } from 'assets/svgs/notification_importantPinkBellIcon.svg';
import { ReactComponent as QuestionIcon } from 'assets/svgs/QuestionsIcon.svg';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import { AddressCapsuleProps } from 'shared/types/applicantsType';

import styles from './AddressCapsule.module.scss';

export const AddressCapsule = ({
  hasPresentAddress = false,
  hasPriorAddress = false,
  hasWarning = false,
}: AddressCapsuleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.CapsuleContainer}>
      {hasPresentAddress || hasPriorAddress ? (
        <div className={styles.AddressInformation}>
          {hasPresentAddress && (
            <div className={styles.Address}>
              {t('addressBox.present')}
              {hasWarning && <BellIcon />}
            </div>
          )}

          {hasPriorAddress && (
            <div className={styles.Address}>
              {hasPresentAddress && '+ '} {t('agent.applicants.renterResume.sourceOfIncome.prior')}
              {hasWarning && <QuestionIcon />}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.EmptyState}>{'___'}</div>
      )}
      <div className={styles.LabelContainer}>
        <div className={styles.CapsuleLabel}>{t('renter.ConfirmAddressHistory.addressHistory.')}</div>
        {hasWarning && (
          <AgentPills
            pillContainerClassName={styles.PillContainer}
            pillsData={[{ heading: t('application.warning'), type: PillTypes.DANGER_LIGHT }]}
          />
        )}
      </div>
    </div>
  );
};
