import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import { isSaving, renterPresentEmployments } from 'redux/selectors/renterSelector';

import Button from 'components/shared/Button/Button';
import { NavigationKeys } from 'constants/renterConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { EditEmploymentTypes, RenterEmploymentsInitialValue } from 'shared/types/renterEmploymentInformation';

import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';
import RenterCriteriaModal from '../RenterCriteria/RenterCriteriaModal/RenterCriteriaModal';
import DescriptionCard from '../RenterPresentSourceOfIncome/RenterPresentIncomeSummary/DescriptionCard/DescriptionCard';
import EditPresentIncome from '../RenterPresentSourceOfIncome/RenterPresentIncomeSummary/EditPresentIncome/EditPresentIncome';

import DocumentTypes from './DocumentTypes/DocumentTypes';

import indexStyles from './../RenterIndex.module.scss';
import styles from './RenterIncomformation.module.scss';
const RenterIncomeInformationIndex = (): JSX.Element => {
  const { employmentId, applicationId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const navigateNext = searchParams.get(NavigationKeys.NEXT) ?? '';
  const navigateSection = searchParams.get(NavigationKeys.SECTION) ?? '';
  const presentEmployments = useSelector(renterPresentEmployments);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldShowRentalCriteria, setShouldShowRentalCriteria] = useState(false);
  const isSavingState = useSelector(isSaving);
  const maxHeight = useDeviceHeight(220);
  // Convert employmentId to a number if it is not undefined
  const employmentIdAsNumber = employmentId ? parseInt(employmentId) : undefined;
  const currentEmployment = presentEmployments.find((item) => item.id === employmentIdAsNumber);
  const isButtonDisabled = useMemo(
    (): boolean =>
      !currentEmployment || !currentEmployment.documents || currentEmployment.documents.length === 0 || isSavingState,
    [currentEmployment, isSavingState]
  );
  const onEditClick = useCallback((): void => {
    if (currentEmployment) {
      setIsModalOpen(true);
    }
  }, [currentEmployment]);

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
          {isModalOpen && currentEmployment && (
            <EditPresentIncome data={currentEmployment} onHide={() => setIsModalOpen(false)} />
          )}

          {shouldShowRentalCriteria && <RenterCriteriaModal onHide={() => setShouldShowRentalCriteria(false)} />}

          <RenterIconInfoContainer />
          <div className={indexStyles.questionLabel}>
            <span className={styles.heading}>{t('renter.incomeInformation.submitDocuments')}</span>
          </div>
          <Formik
            initialValues={RenterEmploymentsInitialValue}
            onSubmit={() => {
              navigateNext
                ? navigate(`${navigateNext}?section=${navigateSection}`)
                : navigate(renterRoutes.generateRenterPresentSourceOfIncomeSummary(applicationId));
            }}
          >
            <Form>
              {currentEmployment && (
                <>
                  <DescriptionCard
                    data={currentEmployment}
                    isSelfEmployed={currentEmployment.employmentSubType === EditEmploymentTypes.SELF_EMPLOYED}
                    onEditClick={onEditClick}
                  />
                  <div className={styles.renterIncomeInformation}>
                    <DocumentTypes data={currentEmployment} showModal={setShouldShowRentalCriteria} />
                  </div>
                </>
              )}
              <div className={indexStyles.renterButtonContainer}>
                <Button
                  className={classNames(indexStyles.renterButton, indexStyles.renterPrimaryButton)}
                  variant="outline"
                  type="submit"
                  disabled={isButtonDisabled}
                >
                  {t('renter.incomeInformation.saveAndContinueButton')}
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </section>
    </RenterContainer>
  );
};

export default RenterIncomeInformationIndex;
