import { Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as ErrorIcon } from 'assets/svgs/InfoErrorDanger.svg';
import RenterModal from 'components/Renter/components/RenterModal/RenterModal';
import styles from 'components/Renter/RenterBackgroundInformation/BackgroundQuestions/BackgroundQuestions.module.scss';
import RCButton from 'components/shared/Button/Button';
interface BackgroundQuestionDialogBoxProps {
  isOpen: boolean;
  onHide: () => void;
  onYes: () => void;
  onNo: () => void;
}

const BackgroundQuestionDialogBox = ({
  isOpen,
  onHide,
  onYes,
  onNo,
}: BackgroundQuestionDialogBoxProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <RenterModal show={isOpen} onHide={onHide}>
      <Modal.Body className={styles.backgroundQuestionsContainer}>
        <div>
          <Row>
            <ErrorIcon />
          </Row>
          <div className={styles.subHeading}>{t('renter.BackgroundInformation.modalHeading')}</div>
          <div className={classNames(styles.renterButtonContainer, styles.dialogDiv)}>
            <RCButton className={classNames(styles.renterButton, styles.smallDialogButton)} onClick={onYes}>
              {t('renter.BackgroundInformation.buttons.yesIamSure')}
            </RCButton>
            <RCButton className={classNames(styles.renterButton, styles.smallDialogButton)} onClick={onNo}>
              {t('renter.BackgroundInformation.buttons.cancel')}
            </RCButton>
          </div>
        </div>
      </Modal.Body>
    </RenterModal>
  );
};

export default BackgroundQuestionDialogBox;
