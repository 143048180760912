import React from 'react';
import classNames from 'classnames';

import styles from './Typography.module.scss';
export interface IText extends React.HTMLAttributes<HTMLElement> {
  variant?: keyof typeof variantsMapping;
}
/**
 * A utility code which provides an object mapping typography variants to HTML tag names
 * and defines a React component that uses this mapping to render text with specified properties.
 */
export const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subheading1: 'h6',
  subheading2: 'h6',
  body1: 'p',
  body2: 'p',
} as const;
const Typography: React.FC<IText> = ({ variant, className, color, children, ...props }) => {
  const Component = variant ? variantsMapping[variant] : 'p';

  return (
    <Component
      className={classNames(styles[`typography--variant-${variant}`], styles[`typography--color-${color}`], className)}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Typography;
