import { Dispatch, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { components, SingleValue } from 'react-select';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { propertyUtilities } from 'redux/selectors/propertySelector';

import { ReactComponent as DropDownFillArrow } from 'assets/svgs/dropDownFillArrow.svg';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import FormikField from 'components/shared/TextField/FormikTextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { AddPropertyParams, PropertyProps } from 'shared/types/propertyType';

import styles from './AddProperty.module.scss';
const UtilitySelect = ({
  name,
  placeholder,
  floatingLabel,
  backgroundColor,
  setIsCustomUtilityClicked,
  isCustomUtilityClicked,
  handleBlur,
}: {
  name: string;
  placeholder: string;
  backgroundColor?: string;
  floatingLabel?: string;
  setIsCustomUtilityClicked: Dispatch<React.SetStateAction<boolean>>;
  isCustomUtilityClicked: boolean;
  handleBlur: (params: AddPropertyParams) => void;
}): JSX.Element => {
  const propertyUtilitiesList = useSelector(propertyUtilities)?.filter((el) => el.value !== 'Custom');
  const [utilsOptions, setUtilsOptions] = useState(propertyUtilitiesList);
  const [field, meta, helpers] = useField({ name });
  const { setFieldValue, errors, values } = useFormikContext<PropertyProps>();
  const value = useMemo(() => utilsOptions?.find((hpc) => hpc.value === field.value), [field.value, utilsOptions]);
  const onChangeHandler = useCallback(
    (
      option: SingleValue<{
        value: string | number;
        label: string;
      }>
    ) => {
      helpers.setValue(option?.value);
    },
    [helpers]
  );
  const onCrossClick = (): void => {
    setFieldValue('customUtility', null);
    setIsCustomUtilityClicked(!isCustomUtilityClicked);
    setUtilsOptions(propertyUtilitiesList);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customOption = (props: any): JSX.Element => {
    const { isSelected, label, data } = props;

    return (
      <components.Option {...props}>
        <div className={styles.customSelect}>
          <div className={styles.information}>
            <span className="name">{data.__isNew__ ? data.label : label || ''}</span>
          </div>
          <div className={styles.checkedOption}>{isSelected ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
        </div>
      </components.Option>
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SelectInputContainer = (): JSX.Element => (
    <div
      className={classNames(styles.inputReactSelectCross, {
        [styles.backgroundColor]: errors?.customUtility,
      })}
    >
      <p>
        <span
          className={classNames(
            { [styles.error]: values?.customUtility && values?.customUtility.length > 47 },
            styles.counterValue
          )}
        >
          {(values?.customUtility && values?.customUtility.length) || 0}
        </span>
        <span className={styles.counterValueLast}>/48</span>
      </p>
      <Tooltip
        text={'Clear & Cancel Custom option'}
        icon={
          <div className={styles.crossIconContainer}>
            <FontAwesomeIcon icon={faXmark} onClick={onCrossClick} className={styles.crossIcon} />
          </div>
        }
        placement={'top'}
        className={styles.tooltipWidth}
      />
    </div>
  );

  return (
    <div>
      {!isCustomUtilityClicked ? (
        <ReactSelect
          options={utilsOptions}
          floatingLabel={floatingLabel}
          placeholder={placeholder}
          value={value}
          onChange={onChangeHandler}
          aria-label="react-dropdown-custom"
          hasErrors={meta.touched && !!meta.error}
          backgroundColor={backgroundColor}
          hasFloatingLabel={true}
          customOption={customOption}
          dropDownIcon={<DropDownFillArrow />}
          dropDownIconContainer={styles.dropDownFillArrow}
          onBlur={() => {
            handleBlur?.({ [name]: field.value });
          }}
        />
      ) : (
        <div className={styles.utilsTextField}>
          <div className={styles.customTextFieldWithLabel}>
            <FormikField
              name={'customUtility'}
              placeholder="Enter custom"
              handleBlur={() => {
                !!values?.customUtility?.length &&
                  handleBlur({
                    customUtility: values?.customUtility,
                    propertyUtility: values?.propertyUtility,
                  });
              }}
              onMouseLeave={(e: React.MouseEvent) => {
                (e.target as HTMLElement).blur();
              }}
            />
          </div>
          <div className={styles.label}>Utilities Included (Optional)</div>
          <SelectInputContainer />
        </div>
      )}
    </div>
  );
};

export default UtilitySelect;
