import { useTranslation } from 'react-i18next';

import RCButton from 'components/shared/Button/Button';

import styles from '../PropertyDetails.module.scss';

type Props = {
  title: string;
  handleClick: () => void;
  disabled: boolean;
};

export const PropertyEditRow = ({ title, handleClick, disabled }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.PropertySpecsRow}>
      <div className={styles.Title}>{title}</div>
      <div className={styles.EditButtonContainer}>
        <RCButton className={styles.EditButton} variant="outline" onClick={handleClick} disabled={disabled}>
          {t('application.edit')}
        </RCButton>
      </div>
    </div>
  );
};
