import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';

import CollapsibleComponent from 'components/Renter/components/CollapsibleComponent/CollapsibleComponent';
import identityVerficationStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import {
  AddressHistory,
  AnimalsInformation,
  BackgroundQuestionsVerification,
  HouseholdDetails,
  PersonalProfile,
  SourceOfIncome,
  VehiclesInformation,
} from 'components/Renter/IdentityVerification/RenterDetails/index';
import { ApplicationPillsKeys } from 'constants/applicationConstants';
import { PaymentVerificationSteps } from 'constants/renterConstants';
import { PaymentVerificationKeys } from 'constants/renterConstants';
import { findApplication } from 'helpers/agentHelper';
import { isAddressVerificationPending, isHouseHoldVerificationPending } from 'helpers/renterHelper';
import { CollapsibleComponentsProps } from 'shared/types/renterTypes';

import styles from './ApplicationInfoBox.module.scss';

const CollapsibleComponents = ({ nextRoute }: CollapsibleComponentsProps): JSX.Element => {
  const renterDetails = useSelector(selectRenterProfileInformation);
  const { appId: applicationId } = useParams();
  const currentApplication = useMemo(
    () => findApplication(renterDetails?.applications, Number(applicationId)),
    [applicationId, renterDetails]
  );
  const pendingBadges: { [key in PaymentVerificationKeys]: boolean } = useMemo(
    () => ({
      [PaymentVerificationKeys.ADDRESS_HISTORY]: isAddressVerificationPending(renterDetails?.addresses ?? []),
      [PaymentVerificationKeys.PERSONAL_PROFILE]: false,
      [PaymentVerificationKeys.ANIMAL_INFORMATION]: false,
      [PaymentVerificationKeys.BACKGROUND_QUESTIONS]: false,
      [PaymentVerificationKeys.HOUSEHOLD_DETAILS]:
        (!isEmpty(currentApplication?.adults) &&
          isHouseHoldVerificationPending(currentApplication?.associatedApplications ?? [])) ||
        (isEmpty(currentApplication?.associatedApplications) && !isEmpty(currentApplication?.adults)),
      [PaymentVerificationKeys.SOURCE_OF_INCOME]: false,
      [PaymentVerificationKeys.VEHICLE_INFORMATION]: false,
    }),
    [currentApplication, renterDetails]
  );
  const applicationComponents = useMemo(
    () => ({
      [PaymentVerificationKeys.PERSONAL_PROFILE]: <PersonalProfile />,
      [PaymentVerificationKeys.ADDRESS_HISTORY]: (
        <AddressHistory next={nextRoute} isPending={pendingBadges[PaymentVerificationKeys.ADDRESS_HISTORY]} />
      ),
      [PaymentVerificationKeys.ANIMAL_INFORMATION]: <AnimalsInformation next={nextRoute} />,
      [PaymentVerificationKeys.BACKGROUND_QUESTIONS]: <BackgroundQuestionsVerification />,
      [PaymentVerificationKeys.HOUSEHOLD_DETAILS]: (
        <HouseholdDetails next={nextRoute} isPending={pendingBadges[PaymentVerificationKeys.HOUSEHOLD_DETAILS]} />
      ),
      [PaymentVerificationKeys.SOURCE_OF_INCOME]: <SourceOfIncome next={nextRoute} />,
      [PaymentVerificationKeys.VEHICLE_INFORMATION]: <VehiclesInformation next={nextRoute} />,
    }),
    [nextRoute, pendingBadges]
  );

  return (
    <div className={styles.CompletionStatusContainer}>
      <div className={classNames(identityVerficationStyles.container)}>
        {PaymentVerificationSteps.map((step) => (
          <div key={`Application-Info-${step}}`} id={step}>
            <CollapsibleComponent
              shouldBeCollapsedByDefault={false}
              heading={step}
              badges={[`${pendingBadges[step] ? ApplicationPillsKeys.PENDING : ''}`]}
            >
              {applicationComponents[step]}
            </CollapsibleComponent>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CollapsibleComponents;
