import { StyleSheet, View } from '@react-pdf/renderer';

import { BackgroundInfo } from 'shared/types/renterBackgroundInformation';

import { BackgroundQuestionPDF } from '../BackgroundQuestionPDF/BackgroundQuestionPDF';
import { HeadingPDF } from '../shared/HeadingPDF/HeadingPDF';
const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
});

export const BackgroundQuestionViewPDF = ({
  backgroundQuestions,
}: {
  backgroundQuestions: BackgroundInfo[];
}): JSX.Element => (
  <View style={styles.Container} wrap={false}>
    <HeadingPDF heading="Additional Information" shouldShowLine />
    {backgroundQuestions.map((question, index) => (
      <BackgroundQuestionPDF
        index={index + 1}
        key={`questions-${question.id}`}
        answer={question.answer}
        question={question.backgroundQuestion.question}
        description={question?.explanation}
      />
    ))}
  </View>
);
