import { G, Path, Svg } from '@react-pdf/renderer';

export const GroupIconSVG = (): JSX.Element => (
  <Svg width="12" height="8" viewBox="0 0 12 8" fill="none">
    <G>
      <G>
        <Path
          opacity="0.3"
          d="M4.5 3C5.05228 3 5.5 2.55228 5.5 2C5.5 1.44772 5.05228 1 4.5 1C3.94772 1 3.5 1.44772 3.5 2C3.5 2.55228 3.94772 3 4.5 3Z"
          fill="#8D9899"
        />
        <Path
          opacity="0.3"
          d="M4.5 5.5C3.15 5.5 1.6 6.145 1.5 6.505V7H7.5V6.5C7.4 6.145 5.85 5.5 4.5 5.5Z"
          fill="#8D9899"
        />
        <Path
          d="M8.33594 4.56641C9.02094 5.03141 9.50094 5.66141 9.50094 6.50141V8.00141H11.5009V6.50141C11.5009 5.41141 9.71594 4.76641 8.33594 4.56641Z"
          fill="#8D9899"
        />
        <Path
          d="M7.50094 4C8.60594 4 9.50094 3.105 9.50094 2C9.50094 0.895 8.60594 0 7.50094 0C7.26594 0 7.04594 0.0499999 6.83594 0.12C7.25094 0.635 7.50094 1.29 7.50094 2C7.50094 2.71 7.25094 3.365 6.83594 3.88C7.04594 3.95 7.26594 4 7.50094 4Z"
          fill="#8D9899"
        />
        <Path
          d="M4.5 4C5.605 4 6.5 3.105 6.5 2C6.5 0.895 5.605 0 4.5 0C3.395 0 2.5 0.895 2.5 2C2.5 3.105 3.395 4 4.5 4ZM4.5 1C5.05 1 5.5 1.45 5.5 2C5.5 2.55 5.05 3 4.5 3C3.95 3 3.5 2.55 3.5 2C3.5 1.45 3.95 1 4.5 1Z"
          fill="#8D9899"
        />
        <Path
          d="M4.5 4.5C3.165 4.5 0.5 5.17 0.5 6.5V8H8.5V6.5C8.5 5.17 5.835 4.5 4.5 4.5ZM7.5 7H1.5V6.505C1.6 6.145 3.15 5.5 4.5 5.5C5.85 5.5 7.4 6.145 7.5 6.5V7Z"
          fill="#8D9899"
        />
      </G>
    </G>
  </Svg>
);
