import { RenterRoles } from 'shared/types/renterTypes';

type RestrictionType = {
  profile: boolean;
  priorAddress: boolean;
  presentAddress: boolean;
  adults: boolean;
  minors: boolean;
  maritalStatus: boolean;
  guarantor: boolean;
  priorIncome: boolean;
  presentIncome: boolean;
  vehicles: boolean;
  animals: boolean;
};

type RenterRestrictionsType = {
  [key in RenterRoles]: RestrictionType;
};

const initialValues = {
  adults: false,
  animals: false,
  guarantor: false,
  minors: false,
  maritalStatus: false,
  presentAddress: false,
  presentIncome: false,
  priorAddress: false,
  priorIncome: true,
  profile: false,
  vehicles: false,
};

export const renterRestrictions: RenterRestrictionsType = {
  [RenterRoles.PRIMARY]: initialValues,
  [RenterRoles.OCCUPANTS]: { ...initialValues, adults: true },
  [RenterRoles.GUARANTOR]: {
    ...initialValues,
    adults: true,
    animals: true,
    guarantor: true,
    maritalStatus: true,
    minors: true,
    priorAddress: true,
    priorIncome: true,
    vehicles: true,
  },
};
