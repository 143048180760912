import { useCallback, useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import classNames from 'classnames';

import { ReactComponent as CrossIcon } from 'assets/svgs/CrossIcon.svg';
import { ReactComponent as HamburgerIcon } from 'assets/svgs/humburgerIcon.svg';
import { ReactComponent as InfoRenterIcon } from 'assets/svgs/infoRenterIcon.svg';
import { ReactComponent as IntellirentLogo } from 'assets/svgs/intellirentLogo.svg';
import { ReactComponent as BackgroundArrow } from 'assets/svgs/RenterBackgroundArrow.svg';
import { RenterModalProps } from 'shared/types/renterInformation';

import 'react-modern-drawer/dist/index.css';
import styles from './RenterDrawer.module.scss';

const RenterDrawer = ({
  show: shouldShow,
  onHide,
  children,
  isDirty,
  crossButtonClassName,
}: RenterModalProps): JSX.Element => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(shouldShow);
  }, [shouldShow]);

  const handleCloseDrawer = useCallback((): void => {
    if (!isDirty) {
      setVisible(false);
    }

    setTimeout(() => {
      onHide();
    }, 1000);
  }, [onHide, isDirty]);

  return (
    <div className={styles.renterDrawer}>
      <Drawer
        open={isVisible}
        onClose={handleCloseDrawer}
        direction="bottom"
        size="91%"
        enableOverlay
        overlayOpacity={0}
        className={styles.drawerContainer}
        lockBackgroundScroll
      >
        <div className={styles.childrenWrapper}>
          <BackgroundArrow className={styles.backgroundIcon} />
          <div className={styles.childrenContainer}>
            <div className={classNames(styles.crossContainer, crossButtonClassName)}>
              <button type="button" onClick={handleCloseDrawer}>
                <CrossIcon className={styles.crossIcon} />
              </button>
            </div>
            <div className={styles.drawerBody}>{children}</div>
          </div>
          <div className={styles.orgContainer}>
            <div>
              <IntellirentLogo />
            </div>
            <div className={styles.iconsContainer}>
              <div>
                <InfoRenterIcon />
              </div>
              <div>
                <HamburgerIcon />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default RenterDrawer;
