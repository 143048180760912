import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';

import { PreviewFile } from 'components/shared/DropZone/MinimizedDropzone/MinimizedDropzone';
import { getDateDifferenceFormattedString } from 'helpers/helper';

import { DocumentSVG } from '../../svgs/DocumentSVG';
const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    padding: '4px 8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    border: '1px solid #E6EFF0',
  },
  Heading: {
    color: '#303333',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
  SubHeading: {
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
  DocumentsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    flexWrap: 'wrap',
  },
  SingleDocument: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  LinkContainer: {
    textDecoration: 'none',
    color: '#303333',
    fontSize: '12px',
  },
});

export interface SupportingDocumentsPDFProps {
  startDate?: string | Date;
  documents: PreviewFile[];
}
export const SupportingDocumentsPDF = ({ startDate, documents }: SupportingDocumentsPDFProps): JSX.Element => (
  <View style={styles.Container}>
    <Text style={styles.Heading}>Supporting documents</Text>
    <Text style={styles.SubHeading}>{`Pay stubs ${
      !!startDate ? `(Last ${getDateDifferenceFormattedString(startDate)})` : ''
    }`}</Text>
    <View style={styles.DocumentsContainer}>
      {documents.map((document) => (
        <View style={styles.SingleDocument} key={`document-${document?.id}`}>
          <DocumentSVG />
          <Link style={styles.LinkContainer} src={document?.fileLink}>
            {document?.fileName}
          </Link>
        </View>
      ))}
    </View>
  </View>
);
