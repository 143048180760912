import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { renterApplication } from 'redux/selectors/renterSelector';
import { setApplicationId } from 'redux/slices/authSlice';
import { getApplicationPropertyLink, getSelectedProperty } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import { ReactComponent as LeftArrow } from 'assets/svgs/leftArrowIcon.svg';
import ApplicationInfoBox from 'components/shared/ApplicationInfoBox/ApplicationInfoBox';
import { getApplicationById, getFormattedApplications } from 'helpers/renterHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { PropertyProps } from 'shared/types/propertyType';
import { ApplicationPropertyLinksResponse } from 'shared/types/renterCooccupants';
import { ApplicationProps } from 'shared/types/renterTypes';

import RenterContainer from '../RenterContainer';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './ViewApplication.module.scss';
const ViewApplication = (): JSX.Element => {
  const { appId: applicationId } = useParams();
  const navigate = useNavigate();
  const renterApplications = useSelector(renterApplication);
  const onBackIconClickHandler = useCallback(() => {
    navigate(renterRoutes.applications);
  }, [navigate]);
  const nextRoute = renterRoutes.generateApplicationToRent(applicationId);
  const [propertyInformation, setPropertyInformation] = useState<Record<number, PropertyProps>>({});
  const dispatch = useDispatch<AppThunkDispatch>();
  const maxHeight = useDeviceHeight(200);

  useEffect(() => {
    if (applicationId) {
      dispatch(setApplicationId(Number(applicationId)));
      dispatch(getApplicationPropertyLink(Number(applicationId)))
        .unwrap()
        .then((res: ApplicationPropertyLinksResponse) => {
          const propertyId: number = res.applicationProperties[0].propertyId;

          dispatch(getSelectedProperty({ propertyId }))
            .unwrap()
            .then((propertyResponse: PropertyProps) => {
              setPropertyInformation({ [applicationId]: propertyResponse });
            });
        });
    }
  }, [applicationId, dispatch]);

  if (!applicationId || isEmpty(Object.keys(propertyInformation)) || isEmpty(renterApplications)) {
    return <div />;
  }

  if (
    !getApplicationById(
      getFormattedApplications({
        renterApplications,
        propertiesInformation: propertyInformation,
        applicationId: Number(applicationId),
      }),
      Number(applicationId)
    )
  ) {
    return <div />;
  }

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div className={styles.HeadingContainer}>
          <LeftArrow onClick={onBackIconClickHandler} height={24} width={24} className={styles.LeftArrowIcon} />
          <div className={styles.Heading}>{t('renter.viewApplication.applicationToRent')}</div>
        </div>
        <div
          className={styles.ViewApplicationContainer}
          style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
        >
          <div className={styles.ScrollableContent}>
            <div className={styles.InformationBox}>
              <InformationIcon className={styles.InformationIcon} />
              <p className={styles.InformationText}>{t('renter.applicationsIndex.yourAgentHasBeenNotified')}</p>
            </div>
            <ApplicationInfoBox
              isCollapsedDefault={false}
              isSelectedDefault={true}
              applicationData={
                getApplicationById(
                  getFormattedApplications({
                    renterApplications,
                    propertiesInformation: propertyInformation,
                    applicationId: Number(applicationId),
                  }),
                  Number(applicationId)
                ) ?? ({} as ApplicationProps)
              }
              nextRoute={nextRoute}
            />
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default ViewApplication;
