import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash-es/isEmpty';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import { ReactComponent as AccountCircle } from 'assets/svgs/AccountCircle.svg';
import { ReactComponent as BathroomSvg } from 'assets/svgs/Bathroom.svg';
import { ReactComponent as BedroomSvg } from 'assets/svgs/Bedroom.svg';
import Avatar from 'components/shared/Avatar/Avatar';
import Toggle from 'components/shared/ToggleSwitch/ToggleSwitch';
import { ExperianRegistrationStatus } from 'constants/agentConstants';
import { PropertyUtilities } from 'constants/propertyConstants';
import { makeAddress } from 'helpers/address';
import { parseApplicantsInitials } from 'helpers/propertyHelper';
import { PropertyType as PropertyStatusType } from 'shared/types/propertType';
import { PropertyRowCardProps } from 'shared/types/propertyType';

import PropertyActiveHousehold from './components/PropertyActiveHousehold';
import PropertyAddress from './components/PropertyAddress';
import PropertyDate from './components/PropertyDate';
import PropertyGeneralInformation from './components/PropertyGeneralInformation';
import PropertyImage from './components/PropertyImage';
import PropertyInvite from './components/PropertyInvite';
import PropertyOwner from './components/PropertyOwner';
import PropertyTitle from './components/PropertyTitle';
import PropertyTransactions from './components/PropertyTransactions';
import PropertyType from './components/PropertyType';
import PropertyViewDetails from './components/ViewDetails';

import styles from './PropertyIndex.module.scss';

const PropertyStackCard = ({ property }: PropertyRowCardProps): JSX.Element => {
  const [checked, setChecked] = useState(false);
  const agent = useSelector(selectAgentProfileInformation);
  const { experianVerified } = useSelector(selectedOrganization);
  const { t } = useTranslation();

  return (
    <div className={styles.alignWithFlexHorizontalAlign} key={property.id}>
      <Row className={styles.propertyRowForData}>
        <Row>
          <Col>
            {<PropertyImage fileLink={property.propertyImages?.[0]?.fileLink} />}
            <Row className={styles.stackCardToggleRow}>
              <Col>
                <span className={styles.marketingLink}>Marketing</span>
              </Col>
              <Col>
                {(experianVerified || agent?.experianRegistration === ExperianRegistrationStatus.SUCCEEDED) &&
                property?.propertyStatus === PropertyStatusType.PUBLISHED ? (
                  <div className={styles.MarketingStatusContainer}>
                    <span className={classNames(styles.Circle, { [styles.CircleActive]: property.marketingEnabled })} />
                    <span className={classNames(styles.Text, { [styles.TextActive]: property.marketingEnabled })}>
                      {property.marketingEnabled
                        ? t('agent.properties.property.applicants.active')
                        : t('agent.properties.property.applicants.inActive')}
                    </span>
                  </div>
                ) : (
                  <Toggle
                    className={(checked && styles.widthAdjustForMarketingStatus) || ''}
                    enabledText="Enabled"
                    disabledText="Disabled"
                    onColor="#ffffff"
                    offColor="#ffffff"
                    onChange={setChecked}
                    checked={checked}
                    width={65}
                    disabled={true}
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col>
            <PropertyViewDetails id={property?.id} />
            <Row>
              <Col>
                <PropertyGeneralInformation
                  count={property.monthlyRentAmount}
                  label={PropertyUtilities.RENT}
                  icon="$"
                />
              </Col>
              <Col>
                <PropertyGeneralInformation count={property.depositAmount} label={PropertyUtilities.DEPOSIT} icon="$" />
              </Col>
            </Row>
            <Row>
              <Col>
                <PropertyGeneralInformation
                  count={property.bedroomsCount}
                  label={PropertyUtilities.BEDS}
                  icon={<BedroomSvg />}
                />
              </Col>
              <Col>
                <PropertyGeneralInformation
                  count={property.bathroomsCount}
                  label={PropertyUtilities.BATHS}
                  icon={<BathroomSvg />}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* body */}
        <Row className={styles.cardBody}>
          <PropertyTitle
            id={property.id}
            title={property.name ? property.name : makeAddress(property, 'title')}
            dotted={true}
          />

          <Col className={styles.gapWithFlex}>
            <PropertyAddress
              address={property.name ? makeAddress(property, 'full address') : makeAddress(property, 'address')}
              dotted={true}
            />
            <PropertyType propertyType={property.propertyType} label="Type" />
            <PropertyDate date={property.dateAvailable} label="Available" />
            <PropertyOwner propertyOwner={`${agent.firstName} ${agent.lastName} `} />
          </Col>
        </Row>
        <Row>
          <div className={styles.divider}></div>
        </Row>
        <Row className={styles.propertyFooter}>
          <Col className={styles.applicantsMargin}>
            <span className={classNames(styles.propertyText, styles.addPadding)}>
              <AccountCircle /> Applicants
            </span>
          </Col>
          <Row>
            <Col xs={9} className={styles.avatarContainer}>
              <Row className={styles.avatarRow}>
                {!isEmpty(property?.applicants?.listOfApplicants) &&
                  !!property?.applicants?.active &&
                  !!property?.applicants?.sumOfHouseholds &&
                  parseApplicantsInitials(property?.applicants?.listOfApplicants as []).map((el, index) => (
                    <Avatar
                      key={`avatar-${el}-${index}`}
                      firstName={el}
                      className={index === 0 ? '' : styles.overLapIcons}
                    />
                  ))}
              </Row>
              <Row>
                <PropertyActiveHousehold
                  type="col"
                  activeCount={property?.applicants?.active ?? 0}
                  houseHoldCount={property?.applicants?.sumOfHouseholds ?? 0}
                />
              </Row>
            </Col>
            <Row>
              <Col xs={9} className={styles.removePropertyTransactions}>
                {/* <span className={styles.propertyText}>No transactions </span> */}
                <PropertyTransactions status={property.propertyStatus} id={property.id} />
              </Col>

              <Col className={styles.leftPropertyInvite}>
                {property?.propertyStatus === PropertyStatusType.PUBLISHED && <PropertyInvite property={property} />}
              </Col>
            </Row>
          </Row>
        </Row>
      </Row>
    </div>
  );
};

export default PropertyStackCard;
