import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikErrors } from 'formik';
import { isEmpty } from 'lodash-es';
import { updatePriorEmployment } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterPriorEmploymentsSchema } from 'schema/renterSchema';

import RenterModal from 'components/Renter/components/RenterModal/RenterModal';
import {
  EditEmploymentTypes,
  PresentSourceOfIncomeTypes,
  RenterEmploymentsInitialValue,
} from 'shared/types/renterEmploymentInformation';
import { EditPriorIncomeProps, RenterPresentEmployments } from 'shared/types/renterTypes';

import EmployedForm from '../../AddPriorSourceOfIncome/PriorSourceOfIncomeForm/EmployedForm';
import SelfEmployedForm from '../../AddPriorSourceOfIncome/PriorSourceOfIncomeForm/SelfEmployedForm';

import styles from './EditPriorIncome.module.scss';

const EditPriorIncome = ({ data, onHide, heading }: EditPriorIncomeProps): JSX.Element => {
  const [employementType, setEmployementType] = useState('');
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    if (data && data.employmentSubType) {
      setEmployementType(data.employmentSubType);
    }
  }, [data]);
  const handleSubmit = useCallback(
    (values: RenterPresentEmployments, setErrors: (errors: FormikErrors<RenterPresentEmployments>) => void): void => {
      const newValues = values;
      const id = values.id || 0;

      newValues.employmentSubType = employementType;

      dispatch(updatePriorEmployment({ values, id }))
        .unwrap()
        .then(() => {
          onHide();
        })
        .catch((error) => {
          setErrors(error); // Sets form errors if an error occurs
        });
    },
    [dispatch, employementType, onHide]
  );
  const selectedFrom = useMemo((): JSX.Element => {
    if (employementType === EditEmploymentTypes.SELF_EMPLOYED) {
      return <SelfEmployedForm heading={heading} />;
    } else {
      return <EmployedForm heading={heading} />;
    }
  }, [employementType, heading]);
  const selectedFromType = useMemo((): string => {
    if (employementType === EditEmploymentTypes.EMPLOYED) {
      return PresentSourceOfIncomeTypes.EMPLOYED;
    } else {
      return PresentSourceOfIncomeTypes.SELF_EMPLOYED;
    }
  }, [employementType]);

  return (
    <div>
      {!isEmpty(employementType) && (
        <Formik
          initialValues={data || RenterEmploymentsInitialValue}
          enableReinitialize
          validationSchema={renterPriorEmploymentsSchema[selectedFromType]}
          onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
        >
          {({ dirty: isDirty }) => (
            <RenterModal show onHide={onHide} isDirty={isDirty}>
              <div className={styles.editPriorIncome}>{selectedFrom}</div>
            </RenterModal>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditPriorIncome;
