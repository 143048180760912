import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';

import { ReactComponent as PenIcon } from 'assets/svgs/EditGrayPencil.svg';
import { ReactComponent as PinDropIcon } from 'assets/svgs/PinDropIcon.svg';
import { ReactComponent as YellowTickIcon } from 'assets/svgs/YellowTick.svg';
import AddressEditModal from 'components/Renter/components/AddressEditModal/AddressEditModal';
import { LivingSituationEditModal } from 'components/Renter/components/LivingSituationEditModal/LivingSituationEditModal';
import { dateFormatFrontend } from 'constants/calendarConstants';
import { RenterAddressTypes } from 'constants/renterConstants';
import { formatAmountToUsdString } from 'helpers/helper';
import { IAddressBoxProps } from 'shared/types/addressBoxTypes';
import { AddressInitialValuesProps, RenterCustomAddressType } from 'shared/types/renterTypes';

import { LivingSituationBox } from './LivingSituationBox';

import styles from './AddressBox.module.scss';

const AddressBox = ({
  address,
  hasPriorAddress = true,
  doShowMoreData = false,
  shouldShowEditIcon = true,
  shouldShowLivingSituation = false,
  setIsRenting,
  setSelectedAddress,
}: IAddressBoxProps): JSX.Element => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVerifierModalOpen, setIsVerifierModalOpen] = useState(false);
  const { setFieldValue } = useFormikContext<AddressInitialValuesProps | RenterCustomAddressType>();

  return (
    <div className={styles.MainContainer}>
      <div className={styles.addressBoxContainer}>
        {hasPriorAddress ? (
          <PinDropIcon className={styles.pinDropIconContainer} />
        ) : (
          <YellowTickIcon className={styles.pinDropIconContainer} />
        )}
        <div className={styles.addressContainer}>
          {doShowMoreData && address?.addressType && (
            <div className={styles.AddressTypeContainer}>
              <span className={styles.addressLabel}>
                {address.addressType === RenterAddressTypes.PRESENT
                  ? t('addressBox.present')
                  : t('addressBox.priorAddress')}
              </span>
              {address?.isRented && <span className={styles.RentTag}>{t('renter.ConfirmPresentAddress.iRent')}</span>}
            </div>
          )}
          {!hasPriorAddress && <span className={styles.addressLabel}>{t('addressBox.priorAddress')}</span>}
          {address?.streetAddress && <span className={styles.streetAddress}> {address.streetAddress}</span>}
          {address?.city && (
            <span
              className={styles.stateAddress}
            >{`${address.apartmentNumber} ${address.city}, ${address.state} ${address.zipCode}`}</span>
          )}
          {doShowMoreData && (
            <div className={styles.Footer}>
              <div className={styles.DateRanger}>
                {address?.fromDate && address.toDate && (
                  <span className={styles.dateRangeContainer}>{`${format(
                    new Date(address.fromDate),
                    dateFormatFrontend
                  )} - ${format(new Date(address.toDate), dateFormatFrontend)}`}</span>
                )}

                {address?.moveInDate && !address.toDate && (
                  <span className={styles.dateRangeContainer}>{`${format(
                    new Date(address.moveInDate),
                    dateFormatFrontend
                  )} - Present`}</span>
                )}
                {(address?.moveInDate || address?.toDate) && (
                  <span className={styles.Label}>{t('application.residency')}</span>
                )}
              </div>
              {!!address?.contactRent && (
                <div className={styles.DateRanger}>
                  <span className={styles.Value}>{formatAmountToUsdString(address?.contactRent ?? 0)}</span>
                  <span className={styles.Label}>{t('renter.applicationInfoBox.rent')}</span>
                </div>
              )}
            </div>
          )}
          {!hasPriorAddress && <span className={styles.streetAddress}>{t('addressBox.iDoNotHaveAPriorAddress')}</span>}
        </div>
        {hasPriorAddress && shouldShowEditIcon && (
          <PenIcon
            onClick={() => {
              setFieldValue('city', '');
              setFieldValue('state', '');
              setFieldValue('zipCode', '');
              setFieldValue('streetAddress1', '');

              setIsModalOpen(true);
            }}
            className={styles.penIconContainer}
          />
        )}
        {isModalOpen && address && (
          <AddressEditModal
            setIsRenting={setIsRenting}
            address={address}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setSelectedAddress={setSelectedAddress}
          />
        )}
      </div>
      {shouldShowLivingSituation &&
        address &&
        //Dummy check to be removed after integrating the classification field
        (address.reasonForMoving || address.fullName || address.classification) && (
          <LivingSituationBox
            customHeading={!!address.fullName ? t('renter.paymentConfirmation.sourceOfIncome.verification') : ''}
            address={address}
            onClickHandler={() => {
              setIsVerifierModalOpen(true);
            }}
          />
        )}
      {isVerifierModalOpen && address && (
        <LivingSituationEditModal
          address={address}
          isModalOpen={isVerifierModalOpen}
          setIsModalOpen={setIsVerifierModalOpen}
        />
      )}
    </div>
  );
};

export default AddressBox;
