import { useTranslation } from 'react-i18next';

import { ReportHeading } from '../../ReportHeading/ReportHeading';
import { RecordReportItem } from '../RecordReportItem/RecordReportItem';

import styles from './RecordSummaryReport.module.scss';

export interface RecordSummaryReportProps {
  publicRecords: number;
  inquiries: number;
  tradelines: number;
  negativeTradelines: number;
}
export const RecordSummaryReport = ({
  publicRecords,
  tradelines,
  negativeTradelines,
  inquiries,
}: RecordSummaryReportProps): JSX.Element => {
  const { t } = useTranslation();
  const recordSummaryItems = [
    { name: 'Public Records', count: publicRecords },
    { name: 'Inquiries', count: inquiries },
    { name: 'Tradelines', count: tradelines },
    { name: 'Negative Trades', count: negativeTradelines },
  ];

  return (
    <div className={styles.Container}>
      <ReportHeading heading={t('creditReport.recordsSummary')} hasCounter={false} />
      <div className={styles.RecordItemsContainer}>
        {recordSummaryItems.map((item) => (
          <div key={`record-summary-${item.name}`} className={styles.RecordItem}>
            <RecordReportItem label={item.name} value={item.count} />
          </div>
        ))}
      </div>
    </div>
  );
};
