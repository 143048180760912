import { components } from 'react-select';
import classNames from 'classnames';

import { ReactComponent as CheckFillIcon } from 'assets/svgs/CheckIconFillPrimary.svg';
import { ReactComponent as CheckGreyIcon } from 'assets/svgs/CheckIconFillSecondary.svg';
import { ReactComponent as CheckIcon } from 'assets/svgs/CheckIconPrimary.svg';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { NavigationSelectProps } from 'shared/types/renterProgress';
import { StepProps } from 'shared/types/renterTypes';

import styles from './NavigationSelect.module.scss';

const NavigationSelect = ({ options, currentLocation, handleNavigation }: NavigationSelectProps): JSX.Element => {
  const statusIcon: StepProps = {
    completed: <CheckFillIcon />,
    current: <CheckIcon className={styles.filledSvg} />,
    pending: <CheckGreyIcon />,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customOption = (props: any): JSX.Element => {
    const { label, status, disabled } = props.data;

    if (disabled) {
      return (
        <div className={classNames(styles.container, styles.Disabled)}>
          <div>{statusIcon[status]}</div>
          <div className={styles.buttonContainer}>{label}</div>
        </div>
      );
    }

    return (
      <components.Option {...props} className={styles.CursorPointer}>
        <div className={styles.container}>
          <div>{statusIcon[status]}</div>
          <div className={styles.buttonContainer}>{label}</div>
        </div>
      </components.Option>
    );
  };

  return (
    <div className={styles.selectContainer}>
      <ReactSelect
        value={options.find(({ label }) => label === currentLocation)}
        placeholder=""
        isSearchable={false}
        options={options}
        customOption={customOption}
        onChange={(value) => handleNavigation(value?.label)}
      />
    </div>
  );
};

export default NavigationSelect;
