import { ReactNode } from 'react';
import { Page, StyleSheet, View } from '@react-pdf/renderer';

import { sharedV2Styles } from 'shared/pdf/v2/shared/sharedStyles';
import { ApplicationType } from 'shared/types/applicantsType';
import { AddPropertyParams } from 'shared/types/propertyType';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

import { FooterPDF } from '../FooterPDF/FooterPDF';
import { HeaderPDF } from '../HeaderPDF/HeaderPDF';

interface Props {
  children: ReactNode;
  propertyInformation: AddPropertyParams;
  renterDetails: RenterProfileInformationProps;
  application: ApplicationType;
  shouldShowHeader?: boolean;
  isLastPage?: boolean;
}

const styles = StyleSheet.create({
  children: {
    flexGrow: 1,
  },
});

export const CustomPDFPage = ({
  children,
  propertyInformation,
  renterDetails,
  application,
  shouldShowHeader = true,
  isLastPage = false,
}: Props): JSX.Element => (
  <Page style={sharedV2Styles.page} size="LETTER">
    {shouldShowHeader && (
      <HeaderPDF propertyInformation={propertyInformation} renterDetails={renterDetails} application={application} />
    )}
    <View style={styles.children}>{children}</View>
    <FooterPDF propertyInformation={propertyInformation} isOnLastPage={isLastPage} />
  </Page>
);
