import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { components } from 'react-select';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { Option } from 'pages/AgentPofileForm/types';
import { RentalFormSchema } from 'schema/publicSchema';

import { ReactComponent as DropDownFillArrow } from 'assets/svgs/dropDownFillArrow.svg';
import { RequestVerificationWrapper } from 'components/Agent/components/shared/RequestVerificationWrapper';
import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';
import Button from 'components/shared/Button/Button';
import CustomErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { verifierOptions } from 'constants/agentConstants';
import { handleNonVerifierSubmission } from 'helpers/publicHelper';
import { VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { VerifierDetailsOnVerificationInitialValues } from 'shared/types/renterTypes';

import { RentalHistoryTitle, RentalSubmitForm } from '../RentalHistoryInformation';
import { RentalHistoryProfile } from '../RentalHistoryInformation/RentalHistoryProfile/RentalHistoryProfile';
export const RenterAddressNonVerifierInformation = (): JSX.Element => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<Option>();
  const [params] = useSearchParams();
  const token = params.get('token') || '';
  const onChangeHandler = (data: Option, setFieldValue: (field: string, value: string) => void): void => {
    if (data) {
      if (data.value === 'Other') {
        setSelectedValue({ label: `${data.value}  ${data.label}`, value: data.value });
        setFieldValue('rejectedReason', `${data.value}  ${data.label}`);
      } else {
        setSelectedValue(data);
        setFieldValue('rejectedReason', `${data.value}  ${data.label}`);
      }
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customVerifierOptionWrapper = (props: any): JSX.Element => {
    const { label, value, data } = props;
    const isItalic = data?.isItalic;

    return (
      <components.Option {...props}>
        <div className={styles.CustomSelectContainer}>
          <span
            className={classNames(styles.CustomOption, {
              [styles.CustomText]: !!isItalic,
            })}
          >
            {isItalic && value}&nbsp;
            {isItalic ? <i className={styles.OtherText}>{label}</i> : label}
          </span>
        </div>
      </components.Option>
    );
  };

  return (
    <RequestVerificationWrapper
      isDetailsDisabled
      infoDescription={
        <>
          {`${t('rental.verifierRedirect.infoPanel.descriptionfirst')}`}
          <span className={classNames(styles.CustomOption, styles.CustomText)}>
            {t('rental.verifierRedirect.infoPanel.descriptionSecond')}
          </span>
          {`${t('rental.verifierRedirect.infoPanel.descriptionThird')}`}
        </>
      }
      type={VerifierURLType.VOR}
      isSubmitted={false}
    >
      <Formik
        initialValues={VerifierDetailsOnVerificationInitialValues}
        validationSchema={RentalFormSchema[0]}
        onSubmit={(values) => handleNonVerifierSubmission({ values, token })}
      >
        {({ setFieldValue, isValid, errors, dirty: isDirty }) => (
          <Form>
            <RentalHistoryTitle />
            <div className={styles.RentalSubContainer}>
              <RentalHistoryProfile />
              <hr className={styles.HorizantalLine} />
              <div className={styles.FormContainer}>
                <span className={classNames(styles.RentalTitle, styles.RentalTitleLarge)}>
                  {t('rental.verifierRedirect.notOwnerHeading')}
                </span>
                <span className={classNames(styles.DescriptionContainer)}>
                  {t('rental.verifierRedirect.notOwnerSubHeading')}
                </span>
                <ReactSelect
                  name="rejectedReason"
                  hasFloatingLabel
                  floatingLabel={t('rental.verifierRedirect.ownerStatus')}
                  placeholder={t('renter.vehiclesInformation.vehicleType.placeholder')}
                  options={verifierOptions}
                  dropDownIconContainer={styles.dropDownFillArrow}
                  customOption={customVerifierOptionWrapper}
                  dropDownIcon={<DropDownFillArrow />}
                  className={styles.ReactSelectContainer}
                  isSearchable={false}
                  value={selectedValue}
                  onChange={(option) => onChangeHandler(option, setFieldValue)}
                />

                <CustomErrorMessage
                  className={classNames(styles.alignError, styles.customErrorForAbsolutePosition)}
                  message={errors.rejectedReason || ''}
                />
              </div>
              <hr className={styles.HorizantalLine} />
              <RentalSubmitForm
                isRelationshopDisabled
                heading="rental.verifierRedirect.form.heading"
                subHeading="rental.verifierRedirect.form.subHeading"
                formConsent="rental.verifierRedirect.form.formConsent"
                formConsentOffer="rental.verifierRedirect.form.formConsentOffer"
              />
            </div>
            <span className={styles.FormButtonContainer}>
              <Button type="submit" className={styles.renterButton} disabled={!isValid || !isDirty}>
                {t('renter.PhotoIdInformation.submit')}
              </Button>
            </span>
          </Form>
        )}
      </Formik>
    </RequestVerificationWrapper>
  );
};
