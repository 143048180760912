import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { capitalize } from 'lodash-es';

import indexStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import { getFormatedPhoneNumber } from 'helpers/guarantor';
import { RenterAdultProps } from 'shared/types/renterTypes';

import styles from './DescriptionCard.module.scss';

const DescriptionCard = ({ adult, customKey }: { adult: RenterAdultProps; customKey: number }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={classNames(indexStyles.borderContainer, styles.container)}>
      <div className={styles.descriptionCard}>
        <div className={styles.actionDiv}>
          <span className={styles.faded}>
            {t('renter.paymentConfirmation.houseHoldDetails.adults')} {customKey + 1}
          </span>
          <span className={classNames(indexStyles.textPill, indexStyles.activePill)}>
            {t('renter.paymentConfirmation.houseHoldDetails.adults.application.notStarted')}
          </span>
        </div>
        <div className={styles.outerEmailDiv}>
          <div className={styles.emailDiv}>
            <span className={styles.email}>{adult.emailAddress}</span>
          </div>
        </div>
        <div className={styles.otherInfo}>
          <div className={styles.relationshipDiv}>
            <span className={styles.text}>{capitalize(adult.relationship)}</span>
            <span className={styles.label}>{t('renter.paymentConfirmation.houseHoldDetails.relationship')}</span>
          </div>
          <div className={styles.phoneNumberDiv}>
            <span className={styles.text}>{getFormatedPhoneNumber(adult.phoneNumber)}</span>
            <span className={styles.label}>{t('renter.paymentConfirmation.houseHoldDetails.adults.phone')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionCard;
