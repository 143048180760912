import { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash-es';
import { selectVerifierAddressInformation, selectVerifierEmploymentInformation } from 'redux/selectors/agentSelector';

import { ReactComponent as ThreeDotIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import { formatAddressAndEmploymentResponseToPDF } from 'helpers/verification';
import { VerificationPDF, VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { RenterProfileInformationProps } from 'shared/types/renterTypes';

import styles from './VerificationPopover.module.scss';

interface Props {
  type: VerifierURLType;
  id: number;
  renterDetails: RenterProfileInformationProps;
}

export const VerificationPopover = ({ type, id, renterDetails }: Props): JSX.Element => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const { t } = useTranslation();
  const employmentDetails = useSelector(selectVerifierEmploymentInformation);
  const addressDetails = useSelector(selectVerifierAddressInformation);
  const isTypeVoe = type === VerifierURLType.VOE;
  const { questionnaire, userAddressDetails, userEmploymentDetails } = formatAddressAndEmploymentResponseToPDF({
    type,
    renterDetails,
    employmentResponse: employmentDetails.find(
      (response) => response.renterEmploymentInformation?.presentEmploymentId === id
    ),
    addressResponse: addressDetails.find((response) => response.renterAddressInformation?.addressId === id),
  });
  const options = [
    { label: 'application.accept&Verify', onClick: undefined },
    {
      label: 'application.download',
      onClick: async () => {
        if (
          !isEmpty(questionnaire.verificationQuestions) &&
          ((isTypeVoe && !isEmpty(userEmploymentDetails)) || (!isTypeVoe && !isEmpty(userAddressDetails)))
        ) {
          const blob = await pdf(
            isTypeVoe ? (
              <VerificationPDF
                questionnaire={questionnaire}
                type={type}
                userEmploymentDetails={userEmploymentDetails}
              />
            ) : (
              <VerificationPDF questionnaire={questionnaire} type={type} userAddressDetails={userAddressDetails} />
            )
          ).toBlob();

          saveAs(blob, type);
        }
      },
    },
  ];
  const renderTooltip = (
    <Popover>
      <Popover.Body className={styles.PopoverBody} onMouseLeave={() => setIsPopoverVisible(false)}>
        {options.map((option) => (
          <div role="presentation" className={styles.Item} key={option.label} onClick={option.onClick}>
            {t(option.label)}
          </div>
        ))}
        <div className={styles.AttachItem}>{t('application.attachFile')}</div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger show={isPopoverVisible} key="bottom" placement="bottom-end" overlay={renderTooltip}>
      <ThreeDotIcon
        className={styles.MenuIcon}
        onClick={() => setIsPopoverVisible(!isPopoverVisible)}
        width={24}
        height={24}
      />
    </OverlayTrigger>
  );
};
