import { Dispatch } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { format as formatDate } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { FieldValuePair } from 'pages/AgentPofileForm/types';
import { addRenterAddressInformation, updateRenterAddressInformation } from 'redux/slices/renterSlice';

import { dateFormatBackend } from 'constants/calendarConstants';
import {
  addressClassificationOptions,
  OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY,
  RenterAddressTypes,
} from 'constants/renterConstants';
import { Notification } from 'shared/Notification/Notification';
import { AddPropertyParams } from 'shared/types/propertyType';
import {
  AddressInformation,
  AddressProps,
  RenterAddressInformationProps,
  StringHashType,
} from 'shared/types/renterTypes';
import { formatDateForBackend } from 'shared/utils/formatDate';

import { getPrimaryVerifier } from './renterHelper';

export const makeAddress = (property: AddPropertyParams, format: string): string => {
  if (format === 'address' && !property.name && property.streetAddress1) {
    const city = property.city ? property.city.trim() : '';
    const state = property.state ? property.state.trim() : '';
    const zipCode = property.zipCode ? property.zipCode.trim() : '';

    return `${city} ${state}, ${zipCode}`;
  }

  if (format === 'title') {
    const streetAddress1 = property.streetAddress1 ? property.streetAddress1.split(',')[0]?.trim() : '';
    const totalUnits = property.totalUnits ? `, ${property.totalUnits}` : '';

    return `${streetAddress1} ${totalUnits}`;
  }

  return property.streetAddress1 || '';
};

export const getIndexOfAddress = ({ addressArray, address }: AddressProps): number =>
  addressArray.findIndex((obj) => obj === address);
export const getFilterAddresses = (
  addressArray: AddressInformation[],
  id: string | undefined
): AddressInformation[] => {
  const filteredArray = addressArray.filter((obj) => `${obj.id}` !== id);

  return filteredArray ? filteredArray : [];
};

export const getPresentAddress = (addressArray: AddressInformation[]): AddressInformation | undefined => {
  const filteredArray = addressArray.filter((obj) => obj.addressType === RenterAddressTypes.PRESENT);

  return filteredArray[0];
};
export const getPriorAddress = (addressArray: AddressInformation[]): AddressInformation[] | undefined => {
  const filteredArray = addressArray.filter((obj) => obj.addressType === RenterAddressTypes.PRIOR);

  return filteredArray ? filteredArray : undefined;
};

export const getAddressToApiFormat = (address: AddressInformation): RenterAddressInformationProps => ({
  streetAddress: address.streetAddress,
  city: address.city,
  state: address.state,
  reasonForMoving: address?.reasonForMoving,
  zipCode: address.zipCode,
  contactRent: address.contactRent,
  phoneNumber: address.phoneNumber,
  emailAddress: address.emailAddress,
  addressType: address.addressType,
  apartmentNumber: address.apartmentNumber?.trim() === '' ? null : address.apartmentNumber,
  fromDate: address.fromDate && formatDateForBackend(address.fromDate),
  toDate: address.toDate && formatDateForBackend(address.toDate),
  moveInDate: address.moveInDate && formatDateForBackend(address.moveInDate),
  fullName: address.fullName,
  description:
    address?.classification === addressClassificationOptions[OTHER_INDEX_IN_ADDRESS_CLASSIFICATION_ARRAY].label
      ? address?.description
      : null,
  classification: address.classification,
});

export interface PresentAddressProps {
  address: AddressInformation;
  isRenting?: boolean;
  setIsRenting: Dispatch<React.SetStateAction<boolean>>;
  defaultSelected?: Date;
}
export interface PriorAddressProps extends PresentAddressProps {
  setShouldClassify: Dispatch<React.SetStateAction<boolean>>;
  shouldClassify: boolean;
}
export interface PresentAddressDateProps extends PresentAddressProps {
  setRequireClassification: Dispatch<React.SetStateAction<boolean>>;
}

export interface ConfirmAddressProps {
  isRenting?: boolean;
  setIsRenting: Dispatch<React.SetStateAction<boolean>>;
}

export type StepType = {
  [key: string]: number;
};

export const stepNumber: StepType = {
  presentAddress: 0,
  priorAddress: 1,
  addressHistory: 2,
};

export type StepRouteType = {
  [key: string]: React.ReactNode;
};

export interface AddressEditFormProps {
  apartmentName: string;
  stateName: string;
  cityName: string;
  streetName: string;
  zipCodeName: string;
  fromDateName?: string;
  toDateName?: string;
  moveInDateName?: string;
  isPrior: boolean;
  rentName: string;
  hasContactRent: boolean;
  isRenting: boolean;
}
export interface VerifierEditDetailsFormProps {
  ownerName: string;
  phoneName: string;
  emailName: string;
  reasonForMovingName: string;
  classificationName: string;
  descriptionName: string;
  hasClassification: boolean;
  hasReasonForMoving: boolean;
  isRenting: boolean;
}

export const PRESENT_ADDRESS_FROM_DATE_START = '01,01,1980';
export const PRESENT_ADDRESS_FROM_DATE_END = formatDate(new Date(), dateFormatBackend);

export const PRIOR_ADDRESS_START_DATE = '01,01,1980';
export const PRIOR_ADDRESS_END_DATE = formatDate(new Date(), dateFormatBackend);

export const ADDRESS_FORMAT_INDEX = 1;

export const getStreetAddress1 = (fullAddress: string, city: string): string | null =>
  fullAddress.substring(0, fullAddress.indexOf(city) - 2); //-2 to also lose the comma in the end

export const onUpdatePresentAddressCall = ({
  route,
  dispatch,
  address,
  renterAddress,
  onClearStates,
  navigate,
}: {
  route: string;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  renterAddress: AddressInformation;
  address: AddressInformation;
  onClearStates: () => void;
  navigate: NavigateFunction;
}): void => {
  if (address?.id && renterAddress.moveInDate) {
    dispatch(
      updateRenterAddressInformation({
        id: address.id,
        address: {
          ...getAddressToApiFormat(address),
          moveInDate: formatDateForBackend(renterAddress.moveInDate),
          reasonForMoving: renterAddress?.reasonForMoving,
          description: renterAddress?.description,
          classification: renterAddress.classification,
        },
      })
    )
      .unwrap()
      .then(() => {
        onClearStates();
        navigate(route);
      })
      .catch((error) => {
        Notification({ message: error });
      });
  } else if (!renterAddress.moveInDate && address.moveInDate) {
    navigate(route);
  }
};
export const onCreatePresentAddressCall = ({
  route,
  dispatch,
  renterAddress,
  onClearStates,
  navigate,
}: {
  route: string;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  renterAddress: AddressInformation;
  onClearStates: () => void;
  navigate: NavigateFunction;
}): void => {
  dispatch(
    addRenterAddressInformation({
      address: {
        ...getAddressToApiFormat(renterAddress),
        moveInDate: formatDateForBackend(renterAddress.moveInDate),
        isRented: renterAddress.isRented ?? false,
      },
    })
  )
    .unwrap()
    .then(() => {
      onClearStates();
      navigate(route);
    })
    .catch((error) => {
      Notification({ message: error });
    });
};

export const convertAddressToFrontEndFormat = (address: AddressInformation): AddressInformation => {
  if (!isEmpty(address?.addressVerifiers)) {
    const primaryVerifier = getPrimaryVerifier(address?.addressVerifiers ?? []);

    return {
      ...address,
      contactRent: primaryVerifier?.contactRent,
      fullName: primaryVerifier?.fullName,
      phoneNumber: primaryVerifier?.phoneNumber,
      emailAddress: primaryVerifier?.emailAddress,
    };
  }

  return address;
};

export const createAddressFieldValuePairs = (address: AddressInformation): FieldValuePair[] => {
  const fieldsToInclude = [
    'fullName',
    'phoneNumber',
    'emailAddress',
    'classification',
    'description',
    'reasonForMoving',
  ];

  return fieldsToInclude.map((field) => ({
    fieldName: field,
    value: address[field as keyof AddressInformation],
  }));
};

export const ADRESS_EXTRA_INFORMATION_LABELS: StringHashType = {
  fullName: 'renter.paymentConfirmation.sourceOfIncome.owner',
  phoneNumber: 'application.phone',
  emailAddress: 'application.email',
  classification: 'address.livingSituation.classification.',
  description: 'agent.properties.property.description',
  reasonForMoving: 'agnet.renterResumePdf.reasonForMoving',
};
