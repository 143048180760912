import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, FieldArray, useField } from 'formik';
import { camelCase } from 'lodash-es';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import Checkbox from 'components/shared/Checkbox/Checkbox';
import CustomErrorMessage from 'components/shared/CustomErrorMessage/ErrorMessage';
import { isPMCUser } from 'helpers/agentHelper';
import { AdminDefaultPermissions, AgentUserRoles, NonPMCAdminDefaultPermissions } from 'shared/types/agentInvite';

import styles from '../AgentInviteModalBody.module.scss';
interface AdminPermissionProps {
  shouldShowDescription?: boolean;
  shouldShowTitle?: boolean;
}
const AdminPermissions = ({ shouldShowDescription, shouldShowTitle }: AdminPermissionProps): JSX.Element => {
  const { t } = useTranslation();
  const [, meta] = useField({ name: 'permissions' });
  const currentOrganization = useSelector(selectedOrganization);
  const getPermissions = useMemo(() => {
    if (
      isPMCUser(currentOrganization?.housingProviderCategory) ||
      currentOrganization.roleName !== AgentUserRoles.OWNER
    ) {
      return AdminDefaultPermissions;
    }

    return NonPMCAdminDefaultPermissions;
  }, [currentOrganization?.housingProviderCategory, currentOrganization.roleName]);

  return (
    <div className={styles.body}>
      {shouldShowTitle && (
        <span className={styles.fieldLabelLighter}>{t('organizations.inviteAgent.modal.field.permissions.label')}</span>
      )}
      <FieldArray
        name="permissions"
        render={() => (
          <>
            <div className={styles.permissions}>
              {getPermissions.map((value, index) => (
                <React.Fragment key={value}>
                  <Field
                    type="checkbox"
                    value={value}
                    as={Checkbox}
                    label={t(`organizations.inviteAgent.modal.field.permissions.${camelCase(value)}`)}
                    name="permissions"
                    key={index}
                    className={styles.checkboxLabel}
                  />
                  {shouldShowDescription && (
                    <div className={styles.checboxAdditionalText}>{t('agent.userInviteForm.loremIpsum')}</div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className={styles.permissionsError}>{!!meta.error && <CustomErrorMessage message={meta.error} />}</div>
          </>
        )}
      />
    </div>
  );
};

export default AdminPermissions;
