import classNames from 'classnames';
import { t } from 'i18next';

import { ReactComponent as CloseIconMedia } from 'assets/svgs/CloseIconMedia.svg';
import { ReactComponent as ImageIcon } from 'assets/svgs/ImageIcon.svg';
import { Size, useWindowSize } from 'hooks/useResize';
import { FileTypes } from 'shared/types/renterIncomeInformation';

import ProgressBar from '../ProgressBar/ProgressBar';

import styles from './ImageProgressBox.module.scss';

interface IImageProgressProps {
  label: string;
  fileName: string;
  onRemoveHandler: () => void;
  progress?: number;
  isImageUploaded?: boolean;
  imgSrc?: string;
  fileType?: string;
  isRemovable?: boolean;
  className?: string;
}
const dotedImageNameForDesktop = (fileName: string): string => {
  if (fileName?.length <= 20) {
    return fileName;
  } else {
    return fileName.substring(0, 20) + '....' + fileName.substring(fileName.length - 4);
  }
};
const dotedImageNameForMobile = (fileName: string): string => {
  if (fileName?.length <= 6) {
    return fileName.substring(0, 1) + '..' + fileName.substring(fileName.length - 4);
  } else {
    return fileName.substring(0, 5) + '....' + fileName.substring(fileName.length - 4);
  }
};
const ImageProgressBox = ({
  label,
  fileName,
  onRemoveHandler,
  progress = 0,
  isImageUploaded = false,
  imgSrc,
  fileType,
  isRemovable,
  className,
}: IImageProgressProps): JSX.Element => {
  const size: Size = useWindowSize();

  return (
    <div className={classNames(styles.ImageProgressContainer, className)}>
      <span className={styles.imageIconContainer}>
        {/* if file is not pdf, show the image thumbnail, if pdf show the placeholder */}
        {fileType?.indexOf('pdf') || fileType === FileTypes.PDF ? (
          <img src={imgSrc} alt="" aria-hidden="true" className={styles.imageIcon} />
        ) : (
          <ImageIcon className={styles.imageIcon} />
        )}
      </span>
      <div className={styles.imageNameContainer}>
        <div className={styles.imageFileNameContainer}>
          {size.width <= 750 ? dotedImageNameForMobile(fileName) : dotedImageNameForDesktop(fileName)}
          <p className={styles.imageFileName}></p>
          <div className={styles.imageRemoveContainer}>
            {!!isImageUploaded && (
              <span className={styles.imageProgressIndicator}>
                {!progress ? t('renter.minimizedDropzone.uploadInProgress') : progress}
              </span>
            )}
            {!isImageUploaded && isRemovable && (
              <CloseIconMedia className={styles.imageRemoveButton} onClick={onRemoveHandler} />
            )}
          </div>
        </div>
        {!!isImageUploaded && (
          <span className={styles.progressBarContainer}>
            <ProgressBar progress={progress} animated={isImageUploaded} />
          </span>
        )}
      </div>
      {!!isImageUploaded && <span className={styles.imageLabelContainer}>{label}</span>}
    </div>
  );
};

export default ImageProgressBox;
