import { Outlet } from 'react-router-dom';

import { Footer } from 'components/shared/Footer/Footer';

export const PublicLayout = (): JSX.Element => (
  <div>
    <Outlet />
    <Footer />
  </div>
);
