import { useEffect, useRef } from 'react';

// Define the type for the value
type ValueType<T> = T | undefined;

// Custom hook to get the previous value
export const usePrevious = <T,>(value: ValueType<T>): ValueType<T> => {
  const ref = useRef<ValueType<T>>(undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
