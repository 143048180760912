import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Field } from 'formik';

import TextField from 'components/shared/TextField/TextField';
import { Values } from 'shared/types/renterBackgroundInformation';

import CustomErrorMessage from '../../../../../components/shared/CustomErrorMessage/ErrorMessage';

import styles from './../BackgroundQuestions.module.scss';

const BackgroundQuestionsInput: React.FC<Values> = ({ currentStep }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Col>
      <div className={styles.textContainer}>
        <Field
          name={`questions[${currentStep}].explanation`}
          as={TextField}
          label={t('application.backgroundQuestion.pleaseExplain')}
          isTextArea="textarea"
          placeholder={t('renter.BackgroundInformation.typeResponse')}
          className={styles.textField}
          labelClassName={styles.labelStyle}
        />
      </div>
      <ErrorMessage
        render={(errorMessage) => <CustomErrorMessage message={errorMessage} />}
        name={`questions[${currentStep}].explanation`}
      />
    </Col>
  );
};

export default BackgroundQuestionsInput;
