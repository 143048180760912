import { StyleSheet, Text, View } from '@react-pdf/renderer';

interface Props {
  value: string;
  label: string;
  icon?: JSX.Element;
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: '5px',
    paddingHorizontal: '15px',
    gap: '5px',
    borderRadius: '5px',
  },
  valueContainer: {
    backgroundColor: '#E6EFF0',
    fontWeight: 'semibold',
    color: '#303333',
    fontSize: '10px',
    padding: '2px',
    borderRadius: '2px',
  },
  label: {
    fontSize: '11px',
    color: '#5E6566',
    fontWeight: 'semibold',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
});

export const GridItemPDF = ({ value, label, icon }: Props): JSX.Element => (
  <View style={styles.container}>
    <View style={styles.valueContainer}>
      <Text>{value}</Text>
    </View>
    <View style={styles.label}>
      {icon && icon}
      <Text>{label}</Text>
    </View>
  </View>
);
