import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { Option } from 'pages/AgentPofileForm/types';
import { isAgentLoading } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { allPropertiesList } from 'redux/selectors/propertySelector';
import { setIsLoading } from 'redux/slices/agentSlice';
import { getAllProperties } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';

import { emailSentSuccessfullStatus, smsSentSuccessfullStatus } from 'constants/agentConstants';
import { parseResponseErrors } from 'helpers/helper';
import { formatProperties } from 'helpers/propertyHelper';
import { getInviteLink } from 'services/agentService';
import { Notification } from 'shared/Notification/Notification';
import { InviteApplicantModalProps, inviteRenterInitialValues, InviteRenterType } from 'shared/types/agentTypes';
import { RentalRequest } from 'shared/types/authType';
import { PropertyType } from 'shared/types/propertType';
import { InviteType } from 'shared/types/renterApplication';

import InviteApplicantModalView from './InviteApplicantModalView/InviteApplicantModalView';
import InviteSuccessModal from './InviteSuccessModal/InviteSuccessModal';

const InviteApplicantModal = ({
  isModalOpen,
  onHide,
  inviteType,
  invitationInitialValues,
  propertyAddress,
}: InviteApplicantModalProps): JSX.Element => {
  const { id } = useSelector(selectedOrganization);
  const [inviteLink, setInviteLink] = useState('');
  const [selectedProperty, setSelectedProperty] = useState(invitationInitialValues?.propertyId?.toString() ?? '');
  const dispatch = useDispatch<AppThunkDispatch>();
  const properties = useSelector(allPropertiesList);
  const isLoading = useSelector(isAgentLoading);
  const formattedData = useMemo(() => formatProperties(properties), [properties]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [renterValues] = useState(invitationInitialValues ?? inviteRenterInitialValues);
  const [invitePayload, setInvitePayload] = useState(
    invitationInitialValues
      ? { ...invitationInitialValues, organizationId: id }
      : ({
          type: InviteType.AGENT_GENERAL,
          organizationId: id,
          ...renterValues,
        } as RentalRequest)
  );

  useEffect(() => {
    dispatch(setIsLoading(true));

    getInviteLink(invitePayload)
      .then((res: AxiosResponse) => {
        if (
          res.data?.payload === emailSentSuccessfullStatus ||
          res.data?.emailResponse?.payload === emailSentSuccessfullStatus ||
          res.data?.payload === smsSentSuccessfullStatus ||
          res.data?.emailResponse?.payload === smsSentSuccessfullStatus
        ) {
          setIsSuccess(true);
          setSelectedProperty('');
        } else {
          setInviteLink(res.data.payload);
        }
      })
      .catch((error) => {
        Notification({ message: parseResponseErrors(error) });
        onHide();
      })
      .finally(() => dispatch(setIsLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitePayload]);

  useEffect(() => {
    if (id) {
      dispatch(
        getAllProperties({
          propertyId: undefined,
          organizationId: id,
          propertyStatus: PropertyType.PUBLISHED,
        })
      );
    }
  }, [id, dispatch]);

  const handlePropertyChange = useCallback(
    async (option: Option | null) => {
      if (option && option.value) {
        setInvitePayload({
          ...invitePayload,
          type: InviteType.PROPERTY_GENERAL,
          propertyId: Number(option.value),
        });
        setSelectedProperty(option.value);
      } else {
        setInvitePayload({
          ...renterValues,
          type: InviteType.AGENT_GENERAL,
          organizationId: id,
        });
        setSelectedProperty('');
      }
    },
    [id, invitePayload, renterValues]
  );
  const handleInviteLink = useCallback(
    async (values: InviteRenterType, propertyId?: string) => {
      setInvitePayload({
        ...values,
        type: propertyId ? InviteType.PROPERTY_SPECIFIC : InviteType.AGENT_SPECIFIC,
        organizationId: id,
        renterPhone: !values.renterPhone || values.renterPhone === '1' ? null : values.renterPhone,
        propertyId: Number(propertyId),
      });
    },
    [id]
  );
  const handleSubmit = useCallback(
    (values: InviteRenterType) => {
      handleInviteLink(values, selectedProperty);
    },
    [handleInviteLink, selectedProperty]
  );

  return (
    <>
      {!isSuccess && (
        <InviteApplicantModalView
          isModalOpen={isModalOpen}
          onHide={onHide}
          handleSubmit={handleSubmit}
          handlePropertyChange={handlePropertyChange}
          inviteLink={inviteLink}
          isLoading={isLoading}
          formattedData={formattedData}
          inviteRenterInitialValues={renterValues}
          inviteType={inviteType}
          propertyAddress={propertyAddress ? propertyAddress : ''}
        />
      )}
      {isSuccess && <InviteSuccessModal onHide={onHide} isModalOpen={isModalOpen} setIsInviteSuccess={setIsSuccess} />}
    </>
  );
};

export default InviteApplicantModal;
