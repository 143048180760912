import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import FormikField from 'components/shared/TextField/FormikTextField';
import { GuarantorDetailsFormProps } from 'shared/types/renterTypes';

import styles from './GuarantorDetailsForm.module.scss';

const GuarantorDetailsForm = ({
  relationshipName,
  emailAddressName,
  phoneNumberName,
}: GuarantorDetailsFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { dirty: isDirty } = useFormikContext();

  return (
    <div className={styles.guarantorInformationContainer}>
      <FormCloseWarning isDirty={isDirty} />
      <div className={classNames(styles.textFieldContainer)}>
        <span className={styles.textFieldLabel}>{t('renter.guarantorInformationForm.relationship')}</span>
        <FormikField
          data-testid="relationshipField"
          name={relationshipName}
          placeholder={t('renter.guarantorInformationForm.enterRelationship')}
        />
      </div>
      <div data-testid="phoneField" className={styles.phoneNumberContainer}>
        <FormikPhoneNumber
          label={t('renter.guarantorInformationForm.phone')}
          name={phoneNumberName}
          isCountryCodeDisabled={false}
          placeholder={t('renter.guarantorInformationForm.(000)000-0000')}
          onlyCountry={[]}
          isCountryCodeEditable={false}
        />
      </div>
      <div className={styles.textFieldContainer}>
        <span className={styles.textFieldLabel}>{t('renter.guarantorInformationForm.guarantor')}</span>
        <FormikField
          data-testid="guarantorField"
          name={emailAddressName}
          placeholder={t('renter.guarantorInformationForm.emailAddress')}
        />
      </div>
    </div>
  );
};

export default GuarantorDetailsForm;
