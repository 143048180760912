import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { isEmpty } from 'lodash-es';

import { dateFormatFrontend } from 'constants/calendarConstants';
import { RenterAddressTypes } from 'constants/renterConstants';
import { getDateDifferenceFormattedString } from 'helpers/helper';
import { AddressVerificationResponse } from 'shared/types/agentTypes';
import { AddPropertyParams } from 'shared/types/propertyType';
import { RenterPresentEmployments } from 'shared/types/renterTypes';
import { FinancialData } from 'shared/types/reportTypes';

import { EmploymentBoxPDF } from '../EmploymentBoxPDF/EmploymentBoxPDF';
import { IncomeReportViewPDF } from '../IncomeReportViewPDF/IncomeReportViewPDF';
import { HeadingPDF } from '../shared/HeadingPDF/HeadingPDF';
import { SupportingDocumentsPDF } from '../SupportingDocumentsPDF/SupportingDocumentsPDF';
import { VerificationBarPDF } from '../VerificationBarPDF/VerificationBarPDF';

const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  AddressContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  AddressHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  AddressTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
  },
  AddressType: {
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'capitalize',
  },
  AddressStatusContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  DateDifference: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
  },
  TotalDifference: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2px',
    backgroundColor: '#E6EFF0',
    color: '#5E6566',
    fontSize: '11px',
    fontWeight: 'semibold',
    padding: '0px 3px',
  },
  AddressTag: {
    color: '#303333',
    fontSize: '11px',
    fontWeight: 'semibold',
    textTransform: 'uppercase',
  },
  NoAddressContainer: {
    borderRadius: '4px',
    border: '1px solid #E1EAEB',
  },
  NoAddress: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 'semibold',
    paddingLeft: '8px',
  },
});

export const EmploymentHistoryViewPDF = ({
  employments,
  transactions,
  propertyInformation,
  employmentVerificationInformation,
}: {
  employments: RenterPresentEmployments[];
  transactions: FinancialData;
  propertyInformation: AddPropertyParams;
  employmentVerificationInformation: AddressVerificationResponse[];
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View style={styles.Container}>
      <HeadingPDF heading={'Source of Income'} shouldShowLine />
      {employments.map((employment, index) => (
        <View key={`employment-${employment.id}`} style={styles.AddressContainer} wrap={false}>
          <View style={styles.AddressHeader}>
            <View style={styles.AddressTypeContainer}>
              <Text style={styles.AddressType}>{'Present'}</Text>

              <Text style={styles.AddressTag}>{`Source ${index + 1}`}</Text>
            </View>
            {!!employment?.jobStartDate && (
              <View style={styles.AddressStatusContainer}>
                <Text style={styles.DateDifference}>{`${format(
                  new Date(employment?.jobStartDate),
                  dateFormatFrontend
                )} - Present`}</Text>
                <Text style={styles.TotalDifference}>
                  {getDateDifferenceFormattedString(employment?.jobStartDate ?? '')}
                </Text>
              </View>
            )}
          </View>
          <EmploymentBoxPDF
            jobDescription={employment?.jobDescription}
            jobRole={employment?.title}
            company={employment?.company}
            income={employment?.monthlyGrossIncome}
            employmentType={employment?.employmentSubType ?? ''}
            employmentId={employment.id ?? 0}
            employmentVerificationInformation={employmentVerificationInformation}
            status={employment?.status}
            businessName={employment.businessName ?? ''}
          />
          {!isEmpty(employment?.documents) && (
            <SupportingDocumentsPDF
              startDate={employment?.jobStartDate ?? ''}
              documents={employment?.documents ?? []}
            />
          )}
          <VerificationBarPDF
            verificationLabel={t('agent.applicants.renterResume.sourceOfIncome.verification')}
            verificationLink="voe_03192024.jpg"
          />
          {!!transactions?.incomeSummary?.pastSixMonths && (
            <IncomeReportViewPDF incomeTransactions={transactions} propertyInformation={propertyInformation} />
          )}
        </View>
      ))}
      {isEmpty(employments) && (
        <View style={styles.AddressContainer}>
          <View style={styles.AddressTypeContainer}>
            <Text style={styles.AddressType}>{RenterAddressTypes.PRESENT}</Text>
            <Text style={styles.AddressTag}>Employment</Text>
          </View>
          <View style={styles.NoAddressContainer}>
            <Text style={styles.NoAddress}>I do not have any employment</Text>
          </View>
        </View>
      )}
    </View>
  );
};
