import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { getPropertyApplicant } from 'redux/slices/agentSlice';
import { AppThunkDispatch } from 'redux/store';

import { FilterOptions } from 'constants/organizationConstants';
import { getFiltersMapping } from 'helpers/applicantsHelper';
import { KeyValueState, PropertyApplicationType } from 'shared/types/applicantsType';

import { ViewPropertyInformation } from '../components/ViewPropertyInformation/ViewPropertyInformation';

import styles from './PropertyApplicationList.module.scss';

export const PropertyApplicationList = ({
  propertyList,
  checkEmptyState,
}: {
  propertyList: PropertyApplicationType[];
  checkEmptyState: () => JSX.Element | null;
}): JSX.Element => {
  const [serachParams] = useSearchParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [loadingFor, setLoadingFor] = useState<KeyValueState>({});
  const activeOption = serachParams.get('option') || FilterOptions.ACTIVE;

  useEffect(() => {
    const handleObserverUpdate = (entries: IntersectionObserverEntry[]): void => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const key = entry.target.getAttribute('data-key');

          if (key) {
            observer.current?.unobserve(entry.target);
            setLoadingFor((prev) => ({ ...prev, [+key]: true }));
            dispatch(getPropertyApplicant({ propertyId: +key, applicationStatus: getFiltersMapping(activeOption) }))
              .unwrap()
              .finally(() => {
                setLoadingFor((prev) => ({ ...prev, [+key]: false }));
              });
          }
        }
      });
    };

    if (!observer.current) {
      observer.current = new IntersectionObserver(handleObserverUpdate, { threshold: 0.5 });
    }

    if (containerRef.current) {
      const divs = containerRef.current.querySelectorAll('div');

      divs.forEach((div) => {
        observer.current?.observe(div);
      });
    }

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const divs = containerRef.current.querySelectorAll(`div.${styles.ListItem}`);

        divs.forEach((div) => {
          observer.current?.unobserve(div);
        });
      }

      observer?.current?.disconnect();
    };
  }, [activeOption, dispatch]);

  return (
    <div ref={containerRef} className={styles.ListContainer}>
      {isEmpty(propertyList)
        ? checkEmptyState()
        : propertyList.map((propertyItem) => (
            <div key={propertyItem.id} data-key={propertyItem.id?.toString()} className={styles.ListItem}>
              <ViewPropertyInformation
                isLoading={loadingFor[propertyItem.id ?? 0]}
                propertyInformation={propertyItem}
              />
            </div>
          ))}
    </div>
  );
};
