import { ReactElement } from 'react';

import indexStyles from 'components/Agent/Applications/ApplicationDetails/ApplicationDetails.module.scss';

import styles from './SectionTitle.module.scss';
type SectionTitleProps = {
  mainTitle?: string;
  iconElement?: ReactElement;
  optionElement?: ReactElement;
  description?: string;
  shouldShowOptions?: boolean;
  shouldShowSubHeadingOptions?: boolean;
  count?: number;
  shouldDrawLine?: boolean;
  shouldShowMainTitle?: boolean;
  shouldShowCount?: boolean;
};
const SectionTitle = ({
  mainTitle,
  iconElement,
  description,
  shouldShowOptions,
  optionElement,
  shouldShowSubHeadingOptions,
  shouldShowMainTitle = true,
  count,
  shouldDrawLine = false, // change this to true
  shouldShowCount = true,
}: SectionTitleProps): JSX.Element => (
  <div className={styles.title}>
    {shouldShowMainTitle && (
      <div className={styles.topDiv}>
        <div className={styles.header}>
          <span> {iconElement}</span>
          <span className={styles.heading}>{mainTitle}</span>
        </div>
        {shouldShowOptions && optionElement && <div>{optionElement}</div>}
      </div>
    )}
    <div className={styles.bottomDiv}>
      {shouldShowCount && (
        <div className={styles.innerBottomDiv}>
          <span className={styles.text}>{description}</span>
          {count && <span className={indexStyles.tag}>{count}</span>}
        </div>
      )}
      {shouldDrawLine && <div className={indexStyles.horizontalLine}></div>}
      {shouldShowSubHeadingOptions && optionElement && <div>{optionElement}</div>}
    </div>
  </div>
);

export default SectionTitle;
