import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export const PinDropSVG = (): JSX.Element => (
  <Svg width="24" height="25" viewBox="0 0 24 25" fill="none">
    <G clip-path="url(#clip0_9997_104314)">
      <Path
        d="M12 3.5C9.19 3.5 6 5.61 6 9.63C6 12.31 8 15.12 12 18.07C16 15.12 18 12.3 18 9.63C18 5.61 14.81 3.5 12 3.5Z"
        fill="#8c8b8b"
        fill-opacity="0.3"
      />
      <Path
        d="M12 4.5C13.93 4.5 17 5.9 17 9.65C17 11.81 15.28 14.32 12 16.97C8.72 14.32 7 11.8 7 9.65C7 5.9 10.07 4.5 12 4.5ZM12 2.5C8.73 2.5 5 4.96 5 9.65C5 12.77 7.33 16.06 12 19.5C16.67 16.06 19 12.77 19 9.65C19 4.96 15.27 2.5 12 2.5Z"
        fill="#323232"
      />
      <Path
        d="M12 7.5C10.9 7.5 10 8.4 10 9.5C10 10.6 10.9 11.5 12 11.5C13.1 11.5 14 10.6 14 9.5C14 8.4 13.1 7.5 12 7.5ZM5 20.5H19V22.5H5V20.5Z"
        fill="#323232"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_9997_104314">
        <Rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </ClipPath>
    </Defs>
  </Svg>
);
