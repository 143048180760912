import styles from './Badge.module.scss';
type BadgeProps = {
  text: string;
};
const Badge = ({ text }: BadgeProps): JSX.Element => (
  <div className={styles.container}>
    <span className={styles.text}>{text}</span>
  </div>
);

export default Badge;
