import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { getRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as NovaCreditIcon } from 'assets/svgs/NovaCreditLogo.svg';
import RCButton from 'components/shared/Button/Button';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import NovaCreditWidget from './NovaCreditWidget';

import indexStyles from '../../Renter/RenterIndex.module.scss';
import styles from './CreditHistory.module.scss';
const CreditHistory = (): JSX.Element => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const [isCreditWidgetSuccess, setCreditWidgetSuccess] = useState(false);
  const handleCreditHistoryNext = (): void => {
    dispatch(getRenterProfileInformation())
      .unwrap()
      .then(() => {
        setCreditWidgetSuccess(true);
      });
  };

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        {/* icons list */}
        <div>
          <RenterIconInfoContainer />
          <div className={indexStyles.renterContentContainer}>
            <h2 className={indexStyles.renterH2}>{t('renter.creditHistory.doYouHaveAnyCreditHistory?')}</h2>

            <div className={styles.novaCreditContainer}>
              <div className={styles.novaCreditIcon}>
                <NovaCreditIcon />
              </div>
              <div className={styles.novaCreditLines}>
                {t('renter.creditHistory.weUseNovaCreditAsOurInternational')}
                <Link to="" className={styles.novaLink}>
                  {t('renter.creditHistory.privacyPolicy')}
                </Link>
              </div>
            </div>

            <div className={classNames(indexStyles.renterButtonContainer, styles.renterCreditButtonContainer)}>
              <NovaCreditWidget handleCreditHistoryNext={handleCreditHistoryNext} />
            </div>
            <div className={indexStyles.renterButtonContainer}>
              <RCButton
                onClick={() => navigate(renterRoutes.generateRenterNoCreditHistoryUrl(`${applicationId}`))}
                className={classNames(indexStyles.renterButton, indexStyles.nextButton)}
                variant="outline"
              >
                {t('renter.creditHistory.CheckOptions')}
              </RCButton>
            </div>

            {isCreditWidgetSuccess ? (
              <div className={indexStyles.renterButtonContainer}>
                <RCButton
                  onClick={() => navigate(renterRoutes.generateGuarantorInformation(applicationId))}
                  className={classNames(indexStyles.renterButton, indexStyles.nextButton)}
                  variant="outline"
                >
                  {t('application.next')}
                </RCButton>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default CreditHistory;
