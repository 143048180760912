import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { t } from 'i18next';
import { capitalize } from 'lodash-es';
import { applicationPropertyLink } from 'redux/selectors/renterSelector';
import { getApplicationPropertyLink, getSelectedProperty } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import defaultAgentProfile from 'assets/images/defaultProfilePicture.png';
import { ReactComponent as DropDownArrow } from 'assets/svgs/BlackDropdownArrow.svg';
import { ReactComponent as MessageIcon } from 'assets/svgs/MessageIconGray.svg';
import { ReactComponent as PhoneIcon } from 'assets/svgs/PhoneIconAllGray.svg';
import RenterCriteriaModal from 'components/Renter/RenterCriteria/RenterCriteriaModal/RenterCriteriaModal';
import { ApplicationPropertyLinksResponse } from 'shared/types/renterCooccupants';
import { ApplicationInfoBoxProps } from 'shared/types/renterTypes';

import RCButton from '../Button/Button';

import { ApplicationInfoBoxHeader } from './ApplicationInfoBoxHeader';
import ApplicationNonSelected from './ApplicationNonSelected';
import { ApplicationOptions } from './ApplicationOptions';
import ApplicationSelected from './ApplicationSelected';
import CollapsibleComponents from './CollapsibleComponents';

import styles from './ApplicationInfoBox.module.scss';

const ApplicationInfoBox = ({
  applicationData,
  isCollapsedDefault = false,
  isSelectedDefault = false,
  nextRoute,
}: ApplicationInfoBoxProps): JSX.Element => {
  const {
    id,
    city,
    state,
    submittedAt,
    applicationStatus,
    agentEmail,
    agentFirstName,
    agentLastName,
    streetAddress,
    zipCode,
    rent,
    propertyImage,
    propertyType,
    isCustomAddress,
    dateAvailable,
  } = applicationData;
  const dispatch = useDispatch<AppThunkDispatch>();
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedDefault);
  const [shouldShowRentalCriteria, setShouldShowRentalCriteria] = useState(false);
  const { desiredMoveInDate, id: propertyLinkId } = useSelector(applicationPropertyLink);
  const onDropDownClickHandler = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);
  const onRentalCriteriaClickHandler = useCallback(() => {
    setShouldShowRentalCriteria(true);
  }, []);

  useEffect(() => {
    if (!propertyLinkId) {
      dispatch(getApplicationPropertyLink(applicationData.id ?? 0))
        .unwrap()
        .then((res: ApplicationPropertyLinksResponse) => {
          const propertyId: number = res.applicationProperties[0].propertyId;

          dispatch(getSelectedProperty({ propertyId }));
        });
    }
  }, [applicationData, dispatch, propertyLinkId]);

  return (
    <div className={classNames(styles.InfoBoxContainer, { [styles.SelectedInfoBoxContainer]: isSelectedDefault })}>
      {shouldShowRentalCriteria && <RenterCriteriaModal onHide={() => setShouldShowRentalCriteria(false)} />}

      <div className={styles.UnCollapsedInfo}>
        {!isSelectedDefault && (
          <ApplicationInfoBoxHeader submittedAt={submittedAt} applicationStatus={applicationStatus} />
        )}
        {!isSelectedDefault && (
          <div className={styles.AddressContainer}>
            <div className={styles.DropDownIconContainer}>
              <DropDownArrow
                onClick={onDropDownClickHandler}
                width={10}
                height={5}
                className={classNames(styles.DropDownIcon, { [styles.UpArrow]: isCollapsed })}
              />
            </div>

            <RCButton onClick={onDropDownClickHandler} className={classNames(styles.Address, styles.ButtonReset)}>
              <span className={styles.PropertyAddress}>{streetAddress}&nbsp;</span>
              <span className={styles.CityAddress}>{`${city}, ${state} ${zipCode}`}</span>
            </RCButton>
          </div>
        )}
      </div>
      {!isCollapsed && desiredMoveInDate && (
        <div className={styles.CollapsedInfo}>
          {!isSelectedDefault && (
            <ApplicationNonSelected
              rent={rent}
              dateAvailable={dateAvailable ?? ''}
              propertyImage={propertyImage}
              propertyType={propertyType}
              isCustomAddress={isCustomAddress}
              moveInDate={desiredMoveInDate ?? ''}
            />
          )}
          {isSelectedDefault && desiredMoveInDate && (
            <ApplicationSelected
              city={city}
              dateAvailable={dateAvailable ?? ''}
              propertyImage={propertyImage}
              rent={rent}
              state={state}
              streetAddress={streetAddress}
              zipCode={zipCode}
              isCustomAddress={isCustomAddress}
              moveInDate={desiredMoveInDate}
              agentProfile={defaultAgentProfile}
              agentFirstName={agentFirstName}
              agentLastName={agentLastName}
            />
          )}
          {!isSelectedDefault && (
            <div
              className={classNames(styles.AgentDetailsContainer, {
                [styles.SelectedAgentContainer]: isSelectedDefault,
              })}
            >
              <img
                src={defaultAgentProfile}
                width={24}
                height={24}
                alt={t('renter.applicationInfoBox.agentProfile')}
                className={styles.AgentProfilePic}
              />
              <div className={styles.AgentInformation}>
                <div className={styles.AgentDetails}>
                  <p className={styles.AgentName}>
                    <span className={styles.FirstName}>{capitalize(agentFirstName)}</span>
                    <span className={styles.LastName}>{capitalize(agentLastName)}</span>
                  </p>
                  <div className={styles.AgentEmail}>{capitalize(agentEmail)}</div>
                </div>
                <div className={styles.AgentIcons}>
                  <PhoneIcon />
                  <MessageIcon />
                </div>
              </div>
            </div>
          )}
          <div className={classNames({ [styles.SelectedCollapsedInfo]: isSelectedDefault })}>
            {isSelectedDefault && (
              <ApplicationInfoBoxHeader submittedAt={submittedAt} applicationStatus={applicationStatus} />
            )}
            <div className={styles.ButtonsContainer}>
              <RCButton onClick={onRentalCriteriaClickHandler} variant="outline" className={styles.RentalButton}>
                {t('renter.applicationInfoBox.rentalCriteria')}
              </RCButton>
            </div>
            {isSelectedDefault ? (
              <CollapsibleComponents nextRoute={nextRoute ?? ''} />
            ) : (
              <ApplicationOptions applicationId={id ?? 0} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationInfoBox;
