import { getYear } from 'date-fns';
import range from 'lodash/range';
export const MAX_YEARS = 15;

export const dateFormatFrontend = 'MM/dd/yyyy';
export const dateFormatBackend = 'yyyy-MM-dd';

export const getYears = (startDate: string | undefined, endDate: string | undefined): number[] => {
  const startDateYear = startDate ? new Date(startDate).getFullYear() : new Date().getFullYear();
  const endDateYear = endDate ? getYear(new Date(endDate)) : getYear(new Date()) + MAX_YEARS;

  return range(startDateYear, endDateYear + 1, 1);
};
export const monthsPartial = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const dateInputMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
