import { Card, Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectedAgent } from 'redux/selectors/renterSelector';

import defaultPropertyImage from 'assets/images/Rectangle 1515.png';
import { ReactComponent as BathroomSvg } from 'assets/svgs/Bathroom.svg';
import { ReactComponent as BedroomSvg } from 'assets/svgs/Bedroom.svg';
import { ReactComponent as LocationPinDropIcons } from 'assets/svgs/PinDropIcon.svg';
import RCButton from 'components/shared/Button/Button';
import ProfileInfo from 'components/shared/ProfileInfoWithoutDropdown/ProfileIconWithoutDropdown';
import { makeAddress } from 'helpers/address';
import { formatAmount } from 'helpers/user';
import { AddPropertyParams } from 'shared/types/propertyType';

import styles from './PropertyCard.module.scss';

interface IPropertyCardProps {
  onClickProperty?: (id: number | undefined) => void;
  id?: number;
  selectedPropertyId?: number | undefined;
  property: AddPropertyParams;
  isShowSelectButton?: boolean;
}
export const PropertyCard = ({
  onClickProperty,
  id = 0,
  selectedPropertyId,
  property,
  isShowSelectButton = true,
}: IPropertyCardProps): JSX.Element => {
  const { t } = useTranslation();
  const { firstName, lastName, email } = useSelector(selectedAgent);

  return (
    <Card className={styles.RenterPropertyCard}>
      <div
        className={classNames(styles.RenterPropertyCardBody, {
          [styles.SelectedPropertyCard]: selectedPropertyId === id,
        })}
      >
        <Row>
          <div className={styles.PropertyImageContainer}>
            <div className={styles.renterPropertyCardImage}>
              <Image
                src={property.propertyImages?.[0]?.fileLink || defaultPropertyImage}
                className={styles.PropertyImage}
              />
            </div>
            <Row className={classNames(styles.PropertyRentContainer, styles.PropertyDescription)}>
              {`$${formatAmount(property.monthlyRentAmount ?? 0)}/mth`}
            </Row>
          </div>
          <Col className={styles.PropertyDetailsContainer}>
            <div className={styles.StreetAddressContainer}>
              <LocationPinDropIcons width={20} height={20} className={styles.LocationSvg} />
              <div className={styles.AddressContainer}>
                <span className={classNames(styles.PropertyDescription, styles.PropertyTitle)}>
                  {property.name ?? makeAddress(property, 'title')}
                </span>
                <span className={classNames(styles.PropertyDescription, styles.LighterText)}>
                  {property.name ? makeAddress(property, 'full address') : makeAddress(property, 'address')}
                </span>
              </div>
            </div>
            <Row className={classNames(styles.PaddingLeft, styles.MarginTop)}>
              <Col className={classNames(styles.PropertyDescription, styles.PaddingNone)}>
                <BedroomSvg height={22} width={22} />
                <div className={classNames(styles.MarginTop, styles.PropertyDescription)}>
                  x{property?.bedroomsCount}
                </div>
                <div className={styles.itemsName}>{t('renter.applicationInfoBox.beds')}</div>
              </Col>
              <Col className={classNames(styles.PropertyDescription, styles.PaddingNone)}>
                <BathroomSvg height={22} width={22} />
                <div className={classNames(styles.MarginTop, styles.PropertyDescription)}>
                  x{property?.bathroomsCount}
                </div>
                <div className={styles.itemsName}>{t('renter.applicationInfoBox.baths')}</div>
              </Col>
            </Row>
            {isShowSelectButton && (
              <Row className={styles.ButtonContainer}>
                <RCButton
                  onClick={() => onClickProperty && onClickProperty(id)}
                  className={classNames(styles.SelectButton, {
                    [styles.SelectButtonActive]: selectedPropertyId === id,
                  })}
                  variant="outline"
                >
                  {selectedPropertyId === id ? t('renter.application.selected') : t('renter.application.select')}
                </RCButton>
              </Row>
            )}
          </Col>
        </Row>
      </div>
      <div className={styles.PropertyAgentInfoContainer}>
        <ProfileInfo role={email} name={`${firstName} ${lastName}`} />
      </div>
    </Card>
  );
};
