import axios, { AxiosError } from 'axios';

import { ApiErrorType } from 'shared/types/apiErrorType';

/**
 * A TypeScript function that converts an Axios error object returned from an
 * API call to a key-value object of error messages associated with form fields.
 * It gets the client id and redirect url from the environment variables.
 * @param error Object of type AxiosError
 * @returns void or Record<string, string> object
 */

export const createFormikErrorObject = (error: AxiosError): Record<string, string> | null | void => {
  if (axios.isAxiosError(error) && error.response) {
    const {
      response: {
        data: { errors },
      },
    } = error as { response: { data: { errors: ApiErrorType[] } } };
    const object = errors
      ? errors.reduce(
          (obj: Record<string, never>, item: ApiErrorType) =>
            Object.assign(obj, { [item.errorOnField]: item.errorMessage }),
          {}
        )
      : null;

    return object;
  }
};
