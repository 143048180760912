import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { FormControlProps } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';

import Button from '../Button/Button';

import styles from './TextField.module.scss';

export type TextFieldType = 'textarea' | 'input';

export type IInputProps = FormControlProps & {
  label?: string;
  type?: string;
  isError?: boolean;
  isTextArea?: TextFieldType;
  prefixString?: string;
  labelClassName?: string;
  onIconClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customIcon?: ReactNode;
};

const FormControl = ({
  label,
  isError,
  className,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  customIcon: CustomIcon,
  prefixString,
  isTextArea,
  onIconClick,
  ...restProps
}: IInputProps): JSX.Element => {
  const [value, setValue] = useState<string>((restProps?.value as string) ?? '');
  const handleInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value;
    // Regular expression to match valid numeric input
    const numericRegex = /^-?\d*\.?\d*$/;

    if (numericRegex.test(inputValue)) {
      setValue(inputValue);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    // Allow only numeric keys, decimal point, minus sign, backspace, and tab
    // eslint-disable-next-line no-useless-escape
    if (!/[\d.\-]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (!!restProps.value) {
      setValue(restProps.value as string);
    }
  }, [restProps.value]);

  return (
    <>
      {restProps?.type === 'number' ? (
        <Form.Control
          className={classNames(className, { [styles.error]: isError }, { [styles.prefixStringPadding]: prefixString })}
          as={isTextArea}
          {...restProps}
          onChangeCapture={handleInput}
          onKeyDown={handleKeyDown}
          value={value}
        />
      ) : (
        <Form.Control
          className={classNames(className, { [styles.error]: isError }, { [styles.prefixStringPadding]: prefixString })}
          as={isTextArea}
          {...restProps}
        />
      )}
      {CustomIcon && (
        <Button
          style={{ cursor: `${onIconClick ? 'pointer' : 'auto'}` }}
          type="button"
          variant="link"
          data-testid="icon-button"
          className={classNames(styles.icon, styles.textFieldIcon, label && styles.iconPosition)}
          onClick={onIconClick}
        >
          {CustomIcon}
        </Button>
      )}
      {prefixString && <span className={styles.prefixString}>{prefixString}</span>}
    </>
  );
};
// if the label is provided it will use floating label InputField else normal TextField
const TextField = ({ label, labelClassName, ...restProps }: IInputProps): JSX.Element => (
  <div className={styles.container}>
    {label ? (
      <FloatingLabel controlId={label} label={label} className={classNames(styles.container, labelClassName)}>
        <FormControl {...restProps} />
      </FloatingLabel>
    ) : (
      <FormControl {...restProps} />
    )}
  </div>
);

export default TextField;
