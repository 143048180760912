import { PropsWithChildren, ReactNode } from 'react';

import { VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';

import { RequestVerificationDetails, RequestVerificationHeader, RequestVerificationInfoPanel } from './';

import styles from './RequestVerificationWrapper.module.scss';
interface RequestVerificationWrapperProps extends PropsWithChildren {
  isDetailsDisabled?: boolean;
  infoDescription?: ReactNode;
  requestedAt?: string;
  isSubmitted: boolean;
  type: VerifierURLType;
}
export const RequestVerificationWrapper = ({
  children,
  isDetailsDisabled = false,
  infoDescription,
  requestedAt,
  isSubmitted,
  type,
}: RequestVerificationWrapperProps): JSX.Element => (
  <div>
    <RequestVerificationHeader />
    <div className={styles.BodyContainer}>
      <RequestVerificationInfoPanel infoDescription={infoDescription} />
      <RequestVerificationDetails
        isSubmitted={isSubmitted}
        requestedAt={requestedAt}
        isDetailsDisabled={isDetailsDisabled}
        type={type}
      />
      <div className={styles.RentalContainer}>{children}</div>
    </div>
  </div>
);
