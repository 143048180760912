import classNames from 'classnames';

import defaultImage from 'assets/images/defaultProfilePicture.png';

import styles from './ProfileIconWithoutDropdown.module.scss';

export interface IProfileProps {
  imageSrc?: string;
  role?: string;
  name?: string;
  className?: string;
  profileImgStyling?: string;
}

const ProfileInfo = ({ imageSrc, role, name, className, profileImgStyling }: IProfileProps): JSX.Element => (
  <div className={classNames(styles.profileInfoContainer, className)}>
    <div className={classNames(styles.profileImg, profileImgStyling)}>
      <img src={imageSrc || defaultImage} alt="Profile Pic" />
    </div>
    <div>
      <div className={classNames(styles.profileName)}>{name}</div>
      <div className={classNames(styles.profileLabel)}>{role}</div>
    </div>
  </div>
);

export default ProfileInfo;
