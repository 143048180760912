import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Formik, FormikErrors } from 'formik';
import { applicantRenterRole, renterVehicles, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation, createPriorEmployment } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterPriorEmploymentsSchema } from 'schema/renterSchema';

import { EmploymentSubTypes, NavigationKeys } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { renterRoutes } from 'shared/routes';
import { EmploymentSteps, RenterEmploymentsInitialValue } from 'shared/types/renterEmploymentInformation';
import { StepProps } from 'shared/types/renterTypes';
import { RenterPresentEmployments } from 'shared/types/renterTypes';

import RenterContainer from '../../components/RenterContainer';
import RenterIconInfoContainer from '../../components/RenterIconInfoContainer';

import HavePriorSourceOfIncome from './HavePriorSourceOfIncome/HavePriorSourceOfIncome';
import EmployedForm from './PriorSourceOfIncomeForm/EmployedForm';
import SelfEmployedForm from './PriorSourceOfIncomeForm/SelfEmployedForm';
import TypeOfPriorSourceOfIncome from './TypeOfPriorSourceOfIncome/TypeOfPriorSourceOfIncome';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './RenterPriorSourceOfIncome.module.scss';

const RenterPriorSourceOfIncome = (): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const vehicles = useSelector(renterVehicles);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const stepName = searchParams.get('step') || 'havePrior';
  const navigateNext = searchParams.get(NavigationKeys.NEXT);
  const navigateSection = searchParams.get(NavigationKeys.SECTION);
  const renterRole = useSelector(applicantRenterRole);
  const onHandleNo = useCallback((): void => {
    const params = { hasNoPriorEmployments: true, id: renterDetails.userId };

    if (!renterDetails?.hasNoPriorEmployments) {
      dispatch(addRenterProfileInformation({ values: params }));
    }

    if (vehicles?.length) {
      navigate(renterRoutes.generateVehiclesInformationSummary(applicationId));
    } else {
      navigate(renterRoutes.generateVehiclesInformation(applicationId));
    }
  }, [applicationId, dispatch, navigate, renterDetails, vehicles]);

  useEffect(() => {
    if (renterRestrictions[renterRole].priorIncome) {
      onHandleNo();
    }
  }, [navigate, onHandleNo, renterRole]);

  const handleSubmit = useCallback(
    (values: RenterPresentEmployments, setErrors: (errors: FormikErrors<RenterPresentEmployments>) => void): void => {
      const newValues = values;

      newValues.employmentSubType = EmploymentSubTypes[stepName];

      dispatch(createPriorEmployment({ values }))
        .unwrap()
        .then(() => {
          navigateNext
            ? navigate(`${navigateNext}?section=${navigateSection}`)
            : navigate(renterRoutes.generateRenterPriorSourceOfIncomeSummary(applicationId));
        })
        .catch((error) => {
          setErrors(error); // Sets form errors if an error occurs
        });
    },
    [applicationId, dispatch, navigate, navigateNext, navigateSection, stepName]
  );
  const currentStepComponent: StepProps = {
    havePrior: (
      <HavePriorSourceOfIncome
        onHandleYes={() => setSearchParams({ step: EmploymentSteps.TYPE_OF_INCOME })}
        onHandleNo={onHandleNo}
      />
    ),
    typeOfIncome: (
      <TypeOfPriorSourceOfIncome handleTypeOfEmployment={(params: string) => setSearchParams({ step: params })} />
    ),
    employed: <EmployedForm />,
    selfEmployed: <SelfEmployedForm />,
  };

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div className={styles.renterPriorSourceOfIncome}>
          <div>
            <RenterIconInfoContainer />
          </div>
          <Formik
            initialValues={RenterEmploymentsInitialValue}
            enableReinitialize
            validationSchema={renterPriorEmploymentsSchema[stepName]}
            onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
          >
            {currentStepComponent[stepName]}
          </Formik>
        </div>
      </section>
    </RenterContainer>
  );
};

export default RenterPriorSourceOfIncome;
