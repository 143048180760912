import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterProfileInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RenterContainer from 'components/Renter/components/RenterContainer';
import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import Button from 'components/shared/Button/Button';
import { AnimalType, FurryFriendTypes, NavigationKeys, RenterAnimalSteps } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { AnimalInformationProps } from 'shared/types/renterTypes';

import styles from './AnimalIndex.module.scss';
const AnimalIndex = (): JSX.Element => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigateNext = searchParams.get(NavigationKeys.NEXT);
  const navigateSection = searchParams.get(NavigationKeys.SECTION);
  const { setFieldValue } = useFormikContext<AnimalInformationProps>();
  const renterRole = useSelector(applicantRenterRole);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const dispatch = useDispatch<AppThunkDispatch>();
  const maxHeight = useDeviceHeight(220);

  useEffect(() => {
    if (renterRestrictions[renterRole].animals) {
      navigate(renterRoutes.generateViewRenterBackgroundInformation(applicationId));
    }
  }, [applicationId, navigate, renterRole]);
  const onPetClickHandler = useCallback((): void => {
    setFieldValue('furryFriendType', FurryFriendTypes.PETS);
    setSearchParams({
      animalType: AnimalType.PETS,
      step: RenterAnimalSteps.FORM,
      furryFriendType: FurryFriendTypes.PETS,
      next: navigateNext ?? '',
      section: navigateSection ?? '',
    });
  }, [navigateNext, navigateSection, setFieldValue, setSearchParams]);
  const onAssistiveClickHandler = useCallback((): void => {
    setFieldValue('furryFriendType', FurryFriendTypes.ASSISTIVE_ANIMAL);
    setSearchParams({
      animalType: AnimalType.ASSISTIVE_ANIMAL,
      step: RenterAnimalSteps.SELECTION,
      furryFriendType: FurryFriendTypes.ASSISTIVE_ANIMAL,
      next: navigateNext ?? '',
      section: navigateSection ?? '',
    });
  }, [navigateNext, navigateSection, setFieldValue, setSearchParams]);

  return (
    <RenterContainer>
      <section className={styles.renterBody}>
        <div
          className={styles.container}
          style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
        >
          <RenterIconInfoContainer />
          <div>
            <div className={styles.headingLabel}>{t('renter.petsInformation.petQuestionLabel')}</div>

            <div className={styles.renterButtonContainer}>
              <Button className={styles.renterButton} variant="outline" onClick={onPetClickHandler}>
                {t('renter.petsInformation.petText')}
              </Button>
              <Button className={styles.renterButton} variant="outline" onClick={onAssistiveClickHandler}>
                {t('renter.petsInformation.assistiveAnimal')}
              </Button>
              <Button
                className={styles.renterButton}
                variant="outline"
                onClick={() => {
                  const params = { hasNoFurryFriends: true, id: renterDetails.userId };

                  if (!renterDetails?.hasNoFurryFriends) {
                    dispatch(addRenterProfileInformation({ values: params }));
                  }

                  navigate(renterRoutes.generateViewRenterBackgroundInformation(applicationId));
                }}
              >
                {t('renter.petsInformation.NoAnimal')}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default AnimalIndex;
