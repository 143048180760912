/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { addProperty } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';
import { PropertyFormSchema } from 'schema/propertySchema';

import { ReactComponent as DropDownFillArrow } from 'assets/svgs/ArrowDownWithBackgroundColor.svg';
import FormikSelectField from 'components/shared/FormikSelectField/FormikSelectField';
import FormikField from 'components/shared/TextField/FormikTextField';
import { bathOptions, bedsInfo, leaseDurationInfo, utilityInfo } from 'constants/propertyConstants';
import { UpdatePropertyType } from 'shared/types/propertyType';

import { PropertyEditRow } from './PropertyEditRow';
import { PropertySaveRow } from './PropertySaveRow';

import styles from '../PropertyDetails.module.scss';

type Params = {
  propertyDetails: UpdatePropertyType;
  isEditableSpecs: boolean;
};

export const PropertySpecsForm = ({ propertyDetails, isEditableSpecs }: Params): JSX.Element => {
  const { t } = useTranslation();
  const [isEditable, setIsEditable] = useState(isEditableSpecs);
  const dispatch = useDispatch<AppThunkDispatch>();
  const { propertyId } = useParams();
  const activeOrganization = useSelector(selectedOrganization);
  const {
    bedroomsCount,
    bathroomsCount,
    squareFootage,
    monthlyRentAmount,
    depositAmount,
    propertyLeaseDuration,
    propertyUtility,
  } = propertyDetails;
  const initialValues = {
    bedroomsCount,
    bathroomsCount,
    squareFootage,
    monthlyRentAmount,
    depositAmount,
    propertyLeaseDuration,
    propertyUtility,
    propertyId: Number(propertyId),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PropertyFormSchema[1]}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(addProperty({ values, organizationId: activeOrganization.id })).then(() => {
          setIsEditable(false);
          setSubmitting(false);
        });
      }}
    >
      {({ handleSubmit, isSubmitting, isValid, resetForm }) => (
        <Form>
          <PropertyEditRow
            title={t('agent.properties.property.specs')}
            handleClick={() => setIsEditable(true)}
            disabled={isEditable}
          />

          <div className={styles.unitSpecs}>
            <Row>
              <Col lg={3} md={4} sm={6} className={styles.CustomTextFieldWithLabel}>
                <FormikField
                  prefixString="$"
                  type="number"
                  name="monthlyRentAmount"
                  label="Rent"
                  disabled={!isEditable}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </Col>
              <Col lg={3} md={4} sm={6} className={styles.CustomTextFieldWithLabel}>
                <FormikField
                  prefixString="$"
                  type="number"
                  name="depositAmount"
                  label="Deposit"
                  disabled={!isEditable}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </Col>
              <Col lg={3} md={4} sm={6} className={styles.CustomTextFieldWithLabel}>
                <FormikSelectField
                  name="bedroomsCount"
                  options={bedsInfo}
                  placeholder="Select"
                  dropDownIcon={isEditable ? <DropDownFillArrow /> : <></>}
                  dropDownIconContainer={styles.dropDownFillArrow}
                  hasFloatingLabel={true}
                  floatingLabel="Beds"
                  isCustomOptions={true}
                  isDisabled={!isEditable}
                />
              </Col>

              <Col lg={3} md={4} sm={6} className={styles.CustomTextFieldWithLabel}>
                <FormikSelectField
                  name="bathroomsCount"
                  options={bathOptions}
                  placeholder="Select"
                  dropDownIcon={isEditable ? <DropDownFillArrow /> : <></>}
                  dropDownIconContainer={styles.dropDownFillArrow}
                  hasFloatingLabel={true}
                  floatingLabel="Baths"
                  isDisabled={!isEditable}
                />
              </Col>

              <Col lg={3} md={4} sm={6} className={styles.CustomTextFieldWithLabel}>
                <FormikField
                  name="squareFootage"
                  type="number"
                  label="Square Feet"
                  disabled={!isEditable}
                  className={styles.squareFootageField}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </Col>
              <Col lg={3} md={4} sm={6} className={styles.CustomTextFieldWithLabel}>
                <FormikSelectField
                  name="propertyLeaseDuration"
                  options={leaseDurationInfo}
                  placeholder="Select"
                  floatingLabel="Lease Duration"
                  hasFloatingLabel={true}
                  dropDownIcon={isEditable ? <DropDownFillArrow /> : <></>}
                  dropDownIconContainer={styles.dropDownFillArrow}
                  isDisabled={!isEditable}
                />
              </Col>
              <Col lg={3} md={4} sm={6} className={styles.CustomTextFieldWithLabel}>
                <FormikSelectField
                  name="propertyUtility"
                  options={utilityInfo}
                  placeholder="Select"
                  floatingLabel="Utilities Included"
                  hasFloatingLabel={true}
                  dropDownIcon={isEditable ? <DropDownFillArrow /> : <></>}
                  dropDownIconContainer={styles.dropDownFillArrow}
                  isDisabled={!isEditable}
                />
              </Col>
            </Row>
          </div>
          {isEditable && (
            <PropertySaveRow
              handleSaveClick={handleSubmit}
              handleCancelClick={() => {
                setIsEditable(false);
                resetForm();
              }}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
