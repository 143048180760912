import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Stripe } from '@stripe/stripe-js';

export const stripeSupportLink = 'https://support.stripe.com/questions/common-questions-about-stripe-identity';
export const stripeInterval = 2000;

export type StripeClientKeyType = {
  clientSecret: string;
};

export type StripeWaitingProps = {
  setWaitingFalse: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick?: ({ successCallBack }: any) => void;
};

export type StripeClientDocuments = {
  documents: StripeInformation;
  renterId: number | string;
  status: string;
};

export type StripeDateOfBirth = {
  month: number;
  year: number;
  day: number;
};

export type StripeClientSensitiveDocuments = {
  documentType: string;
  documentNumber: string;
  dateOfBirth: StripeDateOfBirth;
};

export type IntializeStripeType = {
  setStripe: (stripe: Stripe | null) => void;
};

export enum StripeResponse {
  VERIFIED = 'verified',
  DECLINED = 'declined',
  WAITING = 'waiting',
}

export enum StripeErrorCode {
  CONSENT_DECLINED = 'consent_declined',
  SESSION_CANCELLED = 'session_cancelled',
  DEVICE_UNSUPPORTED = 'device_unsupported',
  UNDER_SUPPORTED_AGE = 'under_supported_age',
  PHONE_OTP_DECLINED = 'phone_otp_declined',
  REQUIRES_INPUT = 'requires_input',
  EMAIL_VERIFICATION_DECLINED = 'email_verification_declined',
  DOCUMENT_UNVERIFIED = 'document_unverified_other',
}

export type StripeButtonProps = {
  handleLoading: (isLoading: boolean) => void;
  successCallBack: () => void;
  stripe: Stripe;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  propertyId: number | null;
  organizationId: number | null;
};

export type StripeAddress = {
  lastResponse?: string | null;
  rawJsonObject?: string | null;
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
};

export type RenterStripInfo = {
  firstName?: string | undefined;
  lastName?: string | undefined;
  birthDate?: string | Date | undefined;
};

export type StripeIssuedDate = {
  lastResponse?: string | null;
  rawJsonObject?: string | null;
  day: number;
  month: number;
  year: number;
};

export type StripeInformation = {
  lastResponse?: string | undefined;
  rawJsonObject?: string | undefined;
  address: StripeAddress | undefined;
  dob: string | undefined;
  error: string | undefined;
  expirationDate: string | Date | undefined;
  issuedDate: StripeIssuedDate | undefined;
  files: string[] | undefined;
  firstName: string | undefined;
  issuingCountry: string | undefined;
  lastName: string | undefined;
  number: string | undefined;
  status: string | undefined;
  type: string | undefined;
};

export interface CreateStripeKeyServiceProps {
  organizationId: number | null;
  propertyId: number | null;
}
