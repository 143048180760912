import { add, isAfter, isToday, isValid } from 'date-fns';
import { t } from 'i18next';
import * as Yup from 'yup';

import { nameRegex, unitRegex, urlPattern, utilsRegex } from 'constants/regexConstant';
import { NAME_MIN_LENGTH, PROPERTY_NAME_MAX_LENGTH } from 'constants/renterConstants';

const allowedUrls = [
  'www.atlasbayvr.com',
  'www.cloudpano.com',
  'helixmedia360.com',
  'www.lcpmedia.com',
  'matterport.com',
  'www.peek.us',
  'www.realync.com',
];

export const PropertyFormSchema = [
  // yup step 1
  Yup.object().shape({
    propertyType: Yup.string().required('Property is required'),
    name: Yup.string()
      .nullable()
      .matches(nameRegex, `${t('renter.nameError.invalidFormat')}`)
      .min(NAME_MIN_LENGTH, `${t('renter.nameError.minLength')}`)
      .max(PROPERTY_NAME_MAX_LENGTH, `${t('renter.nameError.maxLength100')}`),
    streetAddress1: Yup.string().required('Mailing Address Name is required'),
    streetAddress2: Yup.string().matches(unitRegex, ' Unit# must meet proper guidlines').nullable(),
    dateAvailable: Yup.string()
      .required('Property Date Required')
      .test('dateAvailable', t('renter.addressSchema.shouldBeValidDate'), (date) => {
        if (!date) {
          return true;
        }

        return isValid(new Date(date));
      })
      .test('dateAvailable', 'Date should be in future', (date) => {
        if (!date) {
          return true;
        }

        return isToday(new Date(date)) || isAfter(new Date(date), new Date());
      })
      .test('dateAvailable', "Shouldn't be greater than max range", (date) => {
        if (!date) {
          return true;
        }

        return isAfter(add(new Date(), { years: 15 }), new Date(date));
      }),
    totalUnits: Yup.string().when('propertyType', (value) => {
      if (value === 'Apartment/Community') {
        return Yup.string().required('Please enter unit number');
      } else {
        return Yup.string().notRequired().nullable();
      }
    }),
  }),
  // yup step 2
  Yup.object().shape({
    bedroomsCount: Yup.number().required('Beds number is required'),
    bathroomsCount: Yup.number().required('Bathrooms number is required'),
    depositAmount: Yup.number()
      .nullable()
      .typeError('Deposit Amount must be a number')
      .min(1, 'Deposit Amount must be greater than 1$')
      .max(10000, 'Deposit Amount must be equal or less than $10,000'),
    monthlyRentAmount: Yup.number()
      .typeError('Monthly Amount must be a number')
      .integer()
      .min(1, 'Monthly rent amount must be greater than $1')
      .max(10000, 'Monthly rent amount must be equal or less than $10,000')
      .required('Monthly rent Amount is required '),
    propertyUtility: Yup.string().nullable(),
    customUtility: Yup.string()
      .nullable()
      .min(2, 'Custom Utility must be at least two characters')
      .matches(utilsRegex, 'Invalid custom utility'),
    squareFootage: Yup.number()
      .nullable()
      .typeError('Square footage must be a number')
      .integer('Square footage must be an integer')
      .min(10, 'Square footage must be greater than 10 feet')
      .max(99999, 'Square footage must be less than 99999 feet'),
  }),

  // yup step-3
  Yup.object().shape({
    propertyParkingOption: Yup.string().nullable(),
    propertyLaundryAmenity: Yup.string().nullable(),
    customFeatureFiled: Yup.string().nullable().max(32, 'Name should not longer then 32 character'),
  }),
  // yup step-4
  Yup.object().shape({
    description: Yup.string().nullable().max(5000, 'Description must be less than or equal to 5000 characters'),
  }),
  // yup step 5
  Yup.object().shape(
    {
      urlName: Yup.array()
        .when('tourName[0]', {
          is: (tourName: string[] | undefined) => tourName && tourName[0]?.length > 0,
          then: Yup.array().of(Yup.string().required('Tour URL is required when tourname is present')),
        })
        .of(
          Yup.string()
            .test('url', 'URL is not allowed or valid', (url) => {
              if (url) {
                // Check if URL has proper format
                if (!urlPattern.test(url)) {
                  return false;
                }

                // Extract hostname from URL
                const hostname = new URL(url).hostname;

                // Check if hostname is in allowedUrls
                return allowedUrls.includes(hostname);
              }

              return true; // Allow empty or undefined value
            })
            .url('Invalid URL')
        ),
      tourName: Yup.array()
        .when('urlName[0]', {
          is: (urlName: string[] | undefined) => urlName && urlName[0]?.length > 0,
          then: Yup.array().of(Yup.string().required('Tourname is required when URL is present')),
        })
        .of(
          Yup.string()
            .min(2, 'Tour name must be at least 2 characters long')
            .max(50, 'Tour name must be at most 50 characters long')
        ),
    },
    [['urlName', 'tourName']]
  ),
];
