import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikErrors } from 'formik';
import { isEmpty } from 'lodash-es';
import { updatePresentEmployment } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { renterPresentEmploymentsSchema } from 'schema/renterSchema';

import RenterModal from 'components/Renter/components/RenterModal/RenterModal';
import {
  EditEmploymentTypes,
  PresentSourceOfIncomeTypes,
  RenterEmploymentsInitialValue,
} from 'shared/types/renterEmploymentInformation';
import { EditPriorIncomeProps, RenterPresentEmployments } from 'shared/types/renterTypes';
import { formatDateForBackend } from 'shared/utils/formatDate';

import RenterEmployed from '../../AddPresentSourceOfIncome/PresentSourceOfIncomeForm/Employed';
import RenterEmployedTypeOther from '../../AddPresentSourceOfIncome/PresentSourceOfIncomeForm/EmploymentTypeOther';
import RenterSelfEmployed from '../../AddPresentSourceOfIncome/PresentSourceOfIncomeForm/SelfEmployed';

import styles from './EditPresentIncome.module.scss';

const EditPresentIncome = ({ data, onHide, heading }: EditPriorIncomeProps): JSX.Element => {
  const [employmentType, setEmploymentType] = useState('');
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    if (data && data.employmentSubType) {
      setEmploymentType(data.employmentSubType);
    }
  }, [data]);

  const initialData = useMemo(() => {
    if (!data) {
      return RenterEmploymentsInitialValue;
    }

    return { ...data, jobStartDate: data.jobStartDate && new Date(data.jobStartDate) };
  }, [data]);
  const handleSubmit = useCallback(
    (values: RenterPresentEmployments, setErrors: (errors: FormikErrors<RenterPresentEmployments>) => void): void => {
      const newValues = values;
      const id = values.id || 0;

      newValues.employmentSubType = employmentType;
      const employmentInformation: RenterPresentEmployments = values.presentEmploymentVerifiers?.length
        ? {
            ...values,
            fullName: values.presentEmploymentVerifiers[0].fullName,
            emailAddress: values.presentEmploymentVerifiers[0].emailAddress,
            phoneNumber: values.presentEmploymentVerifiers[0].phoneNumber,
          }
        : values;

      if (employmentType !== EditEmploymentTypes.OTHERS) {
        employmentInformation.jobStartDate = formatDateForBackend(values.jobStartDate);
      }

      dispatch(updatePresentEmployment({ values: employmentInformation, id }))
        .unwrap()
        .then(() => {
          onHide();
        })
        .catch((error) => {
          setErrors(error); // Sets form errors if an error occurs
        });
    },
    [dispatch, employmentType, onHide]
  );
  const selectedFrom = useMemo((): JSX.Element => {
    if (employmentType === EditEmploymentTypes.SELF_EMPLOYED) {
      return <RenterSelfEmployed isForEditing heading={heading} />;
    } else if (employmentType === EditEmploymentTypes.EMPLOYED) {
      return <RenterEmployed heading={heading} isForEditing />;
    } else {
      return <RenterEmployedTypeOther heading={heading} />;
    }
  }, [employmentType, heading]);
  const selectedFromType = useMemo((): string => {
    if (employmentType === EditEmploymentTypes.SELF_EMPLOYED) {
      return PresentSourceOfIncomeTypes.SELF_EMPLOYED;
    } else if (employmentType === EditEmploymentTypes.EMPLOYED) {
      return PresentSourceOfIncomeTypes.EMPLOYED;
    } else {
      return PresentSourceOfIncomeTypes.OTHER;
    }
  }, [employmentType]);

  return (
    <div>
      {!isEmpty(employmentType) && (
        <Formik
          initialValues={initialData}
          enableReinitialize
          validationSchema={renterPresentEmploymentsSchema[selectedFromType]}
          onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
        >
          {({ dirty: isDirty }) => (
            <RenterModal show onHide={onHide} isDirty={isDirty}>
              <div className={styles.editPresentIncome}>{selectedFrom}</div>
            </RenterModal>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditPresentIncome;
