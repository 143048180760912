import { useTranslation } from 'react-i18next';

// import { ReactComponent as ThreeDotIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import styles from './AgentDetailBreadCrumbs.module.scss';

interface Props {
  agentName: string;
  images: { src: string; alt: string }[];
}

export const AgentDetailBreadCrumbs = ({ agentName, images }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.MainRow}>
      <div className={styles.ImageSection}>
        {images.map((image) => (
          <img key={`img-${image.alt}`} src={image.src} alt={image.alt} />
        ))}
      </div>
      <div className={styles.NameRow}>
        <p>{agentName}</p>
        <p>{t('agent')}</p>
      </div>
      {/* <ThreeDotIcon className={styles.ThreeDotIcon} /> */}
    </div>
  );
};
