import { ClipPath, Defs, G, Path, Rect, Svg } from '@react-pdf/renderer';

export const VerifiedGreenTickSVG = (): JSX.Element => (
  <Svg width="14" height="15" viewBox="0 0 14 15" fill="#00ac47">
    <G clip-path="url(#clip0_6137_2952)">
      <Path
        d="M5.24911 9.93339L2.81661 7.50089L1.98828 8.32339L5.24911 11.5842L12.2491 4.58422L11.4266 3.76172L5.24911 9.93339Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_6137_2952">
        <Rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
      </ClipPath>
    </Defs>
  </Svg>
);
