import { ReactNode } from 'react';

import { PropertyUtilities } from 'constants/propertyConstants';

import styles from './../PropertyIndex.module.scss';
export interface PropertyGeneralInformationProps {
  count?: string | number;
  label: string;
  icon?: ReactNode | string;
}

const PropertyGeneralInformation = ({ count = -1, label, icon }: PropertyGeneralInformationProps): JSX.Element => (
  <div className={styles.displayInline}>
    {count || (count === 0 && label === PropertyUtilities.BEDS) ? (
      <>
        <span className={styles.dollarIcon}>{icon}</span>
        <span className={styles.propertyTextlarge}>{`${icon === '$' ? '' : ' x'}${count}`}</span>
      </>
    ) : (
      <span className={styles.dollarIcon}>____</span>
    )}
    <br />
    <span className={styles.propertyText}>{label}</span>
  </div>
);

export default PropertyGeneralInformation;
