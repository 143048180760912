import classNames from 'classnames';

import { DateRangeViewerProps } from 'shared/types/agentTypes';

import { DateViewer } from './DateViewer';

import styles from './DateRangeViewer.module.scss';

export const DateRangeViewer = ({
  fromDate,
  toDate,
  fromLabel = 'From',
  toLabel = 'To',
}: DateRangeViewerProps): JSX.Element => (
  <div className={classNames(styles.Container, styles.RangeContainer)}>
    <div className={styles.DateMainContainer}>
      <DateViewer isRangeViewer date={fromDate} label={fromLabel} />
    </div>
    <div className={styles.VerticalDivider}></div>
    <div className={styles.DateMainContainer}>
      <DateViewer isRangeViewer date={toDate} label={toLabel} />
    </div>
  </div>
);
