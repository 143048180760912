import classNames from 'classnames';
import { t } from 'i18next';

import { ReactComponent as HomeIcon } from 'assets/svgs/HomeIconGrayBackground.svg';
import { ReactComponent as InfoIcon } from 'assets/svgs/InfoIcon.svg';
import { ApplicationNonSelectedProps } from 'shared/types/renterTypes';

import styles from './ApplicationInfoBox.module.scss';

const ApplicationNonSelected = ({
  dateAvailable,
  propertyImage,
  rent,
  propertyType,
  isCustomAddress,
  moveInDate,
}: ApplicationNonSelectedProps): JSX.Element => (
  <div className={styles.ExtraInfoContainer}>
    <div className={styles.AvailableStatusContainer}>
      <p className={styles.MoveInTag}>
        {t('renter.applicationInfoBox.moveInDate')}&nbsp;
        <span className={styles.AvailableStatus}>{moveInDate.toUpperCase()}</span>
      </p>
      {!isCustomAddress && (
        <p className={styles.AvailableTag}>
          {`${t('agent.applications.property.available')}:`}&nbsp;
          <span className={styles.AvailableStatus}>
            {new Date(dateAvailable).toLocaleDateString() === new Date().toLocaleDateString()
              ? t('application.propertyHeader.now')
              : dateAvailable.toUpperCase()}
          </span>
        </p>
      )}
    </div>
    <div
      className={classNames(styles.PropertyInfoContainer, { [styles.CustomPropertyInfoContainer]: isCustomAddress })}
    >
      {!isCustomAddress ? (
        <img
          height={81}
          width={81}
          src={propertyImage}
          alt={t('renter.applicationInfoBox.propertyImage')}
          className={styles.PropertyImgae}
        />
      ) : (
        <HomeIcon className={styles.PropertyImgae} />
      )}
      {!isCustomAddress && (
        <div className={styles.DetailsContainer}>
          <div className={styles.RentDetailsContainer}>
            <div className={styles.Value}>{`$${rent}/mo`}</div>
          </div>

          <div className={styles.Property}>{propertyType?.toUpperCase()}</div>
        </div>
      )}
      {isCustomAddress && (
        <div className={styles.CustomLabelContainer}>
          <div className={styles.CustomLabel}>{t('renter.AddressEditModal.custom')}</div>
          <InfoIcon width={18} height={18} />
        </div>
      )}
    </div>
  </div>
);

export default ApplicationNonSelected;
