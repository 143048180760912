import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';

import { IsMobile } from '../../../helpers/IsMobile';

import styles from './Card.module.scss';

export type ICardProps = {
  className?: string;
  xl?: number;
  lg?: number;
  md?: number;
  sm?: number;
  children: React.ReactNode;
  parentClassName?: string;
  rowClassName?: string;
};

const AgentProfileForm = ({
  className,
  parentClassName,
  rowClassName,
  children,
  ...restProps
}: ICardProps): JSX.Element => (
  <div className={classnames('px-4 px-md-5', styles.agentProfileContainer, parentClassName)}>
    <Row className={classnames(styles.containerRow, rowClassName, { [styles.OverflowScroll]: !IsMobile() })}>
      <Col {...restProps} className={classnames(styles.card, className)}>
        {children}
      </Col>
    </Row>
  </div>
);

export default AgentProfileForm;
