// a name must contain 2-50 characters that only contain letters, spaces, and certain punctuation marks.
export const nameRegex = /^[a-zA-Z.'-\s]/;
// business name should match alphanumeric strings with some special characters that are between 2 to 50 characters long
export const businessRegex = /^(?=[^?$;|%<>`=^{}[\]:\\&*]{2,50}$)[a-zA-Z0-9 ',.-]+$/;
// suit input should matches strings containing alphanumeric characters and certain symbols, with a length of 0 to 10 characters.
export const suiteRegex = /^[0-9a-zA-Z\s!@#$%^&*()_+=-]{0,10}$/;
// unit#  field should have 55 chararcters which  only include  a-zA-Z0-9'-.,()&#space
export const unitRegex = /^[a-zA-Z0-9'\-.,()&# ]{1,55}$/;
// must be a whole number with 5 characters limit
export const buildingUnitCount = /^\d{1,5}$/;
// must be int minimum length 1 and range from 0 to 9
export const numericRegex = /^[1-9][0-9]*$/;
// includes the number text and defined characters
export const utilsRegex = /^[0-9a-zA-Z#(),.'/-]+$/;
/* validates URLs with "http://" or "https://" at the beginning, followed by one or more occurrences of
 word characters and a dot, ending with a TLD of at least two letters (case-insensitive). */
export const urlPattern = /^https?:\/\/(?:[\w-]+\.)+[a-z]{2,}(\/[\w#=?&-]+)*$/i;
export const ssnPattern = /^(\d{0,3})(\d{0,2})(\d{0,4})$/;
export const emailRegex =
  /^(?!.*\.{2})[^.](?!.*\.\.)[a-zA-Z0-9_.+-]+(?<!\.)@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*(\.[a-zA-Z]{2,6})+$/;
export const phoneRegex = /^(?!1).{10}$/;
export const zipRegex = /^\d{5}$|^\d{9}$/;
export const cityRegex = /^[a-zA-Z'-.,() ]+$/;
export const stateRegex = /^[a-zA-Z]+$/;
export const fullNameRegex = /^[A-Za-z' -]+$/;
export const onlyUpperLowerCase = /^[A-Za-z ]*$/;
export const lisencePlateRegex = /^[A-Za-z0-9 -]*$/;
export const modelRegex = /^[A-Za-z0-9 -]*$/;
export const animalRegex = /^[A-Za-z\s]*$/;
export const addressRegex = /^[a-zA-Z0-9'-.,()&# ]+$/;
export const cardNameRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*\s*$/;
// digits between 0 to 9
export const digitsRegex = /^[0-9]$/;
export const timePeriodRegex = /(\d{2})\s(AM|PM)/;
//password must be at least 10 characters long 1 integer 1 special character 1 lowercase and 1 uppercase character.
export const passwordRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9\s]).{8,}$/;

//Contains atleast 1 alphabet
export const reasonForMovingRegex = /(?=.*[A-Za-z])/;
