import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { IconType, ILabelProps } from 'shared/types/agentTypes';

import styles from './LabelIcon.module.scss';

// eslint-disable-next-line @typescript-eslint/naming-convention
const Icon = ({ label, customIcon: CustomIcon, shouldStackIconAndLabel }: IconType): JSX.Element => (
  <div className={classNames(styles.icon, { [styles.StackIconLabel]: shouldStackIconAndLabel })}>
    <CustomIcon data-testid="my-svg" />
    {label && <span className={classNames(styles.label)}>{label}</span>}
  </div>
);
const LabelIcon = ({ url, className, shouldStackIconAndLabel = false, ...props }: ILabelProps): JSX.Element => {
  const location = useLocation();
  const isLinkActive = url ? location.pathname === url : false;

  return (
    <div data-testid="my-label-icon" className={classNames(styles.container, className)}>
      {url ? (
        <Link to={url} className={classNames(styles.link, { [styles.activeLink]: isLinkActive })}>
          <Icon shouldStackIconAndLabel={shouldStackIconAndLabel} {...props} />
        </Link>
      ) : (
        <Icon shouldStackIconAndLabel={shouldStackIconAndLabel} {...props} />
      )}
    </div>
  );
};

export default LabelIcon;
