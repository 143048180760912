import { Quill } from 'react-quill';
import classNames from 'classnames';

import { ReactComponent as InfoIcon } from 'assets/svgs/InfoIcon.svg';

import 'react-quill/dist/quill.snow.css';
import styles from './QuillEditor.module.scss';
const Size = Quill.import('formats/size');
const icons = Quill.import('ui/icons');

icons['clean'] =
  '<svg width="15" height="15" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.6667 2.66663V0.916628H2.7275L4.47751 2.66663H5.545L5.22417 3.41329L6.44334 4.63829L7.28917 2.66663H10.6667ZM0.989172 0.834961L0.166672 1.65746L4.23251 5.72329L2.79167 9.08329H4.54167L5.4575 6.94829L8.75917 10.25L9.58167 9.42746L0.989172 0.834961Z" fill="#444"/></svg>';

Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

const Font = Quill.import('attributors/style/font');

Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'Open Sans', 'sans-serif', 'lucida'];
Quill.register(Font, true);

export const modules = {
  toolbar: {
    container: '#toolbar',
  },
  clipboard: { matchVisual: false },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

export const formats = ['header', 'font', 'size', 'bold', 'underline', 'align', 'list', 'bullet', 'indent'];

export const QuillToolbar = (): JSX.Element => {
  const id = 'toolbar';

  return (
    <div id={id}>
      <span className="ql-formats">
        <select className="ql-header" defaultValue="5">
          <InfoIcon />
          <option value="1">Huge</option>
          <option value="2">Large</option>
          <option value="3">Normal</option>
          <option value="4">Small</option>
        </select>

        <button className="ql-bold" />
        <button className="ql-underline" />

        <button className="ql-list" value="bullet" />
        <button className="ql-list" value="ordered" />
        <select className={classNames('ql-align', styles.qlAlign)} />
        <button className="ql-clean" />
      </span>
    </div>
  );
};

export default QuillToolbar;
