import { InviteType as InvitationType } from 'shared/types/renterApplication';

import { ApiErrorType } from './apiErrorType';

export interface AuthValues {
  email: string;
  password: string;
  userType?: string;
}

export interface ForgotPasswordValues {
  email: string;
}
export interface ResetPassworValues {
  password: string;
  token: string;
}

export interface GoogleAuthValues {
  code: string;
  userType: string;
}
export enum UserType {
  AGENT = 'AGENT',
  RENTER = 'RENTER',
}

export enum InviteType {
  GENERAL = 'general',
  SPECIFIC = 'specific',
  AGENT = 'agent',
  PROPERTY = 'property',
}

export type InviteParams = {
  inviteCode: string;
  url?: string;
  type?: string;
};

export const InviteInitialValues = {
  agentFirstName: '',
  agentLastName: '',
  renterFirstName: '',
  renterLastName: '',
  renterEmail: '',
  renterPhoneNumber: '',
  renterExists: undefined,
  propertyDetails: {} as PropertyInviteDetails,
  status: '',
  type: '',
  inviteCode: '',
};
export const AdultInviteInitialValues = {
  adultEmail: '',
  adultPhoneNumber: '',
  renterFirstName: '',
  renterLastName: '',
  renterEmail: '',
  applicationId: 0,
  streetAddress: '',
  invitedAdultExists: undefined,
};
export const GuarantorInviteInitialValues: GuarantorInviteDetails = {
  guarantorEmail: '',
  guarantorPhoneNumber: '',
  renterFirstName: '',
  renterLastName: '',
  renterEmail: '',
  applicationId: 0,
  streetAddress: '',
  invitedGuarantorExists: undefined,
};
export type InviteInformationProps = {
  agentFirstName: string;
  agentLastName: string;
  renterFirstName?: string;
  renterLastName?: string;
  renterEmail?: string;
  renterPhoneNumber?: string;
  renterExists?: boolean;
  inviteCode: string;
  type: string;
  status: string;
  createdAt?: string;
  propertyDetails?: PropertyInviteDetails;
};

export type InviteProps = {
  inviteCode: string;
};

export type RentalRequest = {
  type: InvitationType;
  organizationId: number;
  propertyId?: number | null;
  renterFirstName?: string | null;
  renterLastName?: string | null;
  renterEmail?: string | null;
  renterPhone?: string | null;
};

export type InviteResponse = {
  payload: InviteInformationProps;
  errors: [] | ApiErrorType[];
};

export type AcceptInvitationProps = {
  agentFirstName?: string;
  agentLastName?: string;
  renterEmail?: string;
  renterPhone?: string;
  renterExists?: boolean;
};

export type AcceptInvitationResponse = {
  payload: AcceptInvitationProps;
  errors: [] | ApiErrorType[];
};

export type MessageResponse = {
  payload: string;
  errors: [] | ApiErrorType[];
};

export type AcceptInvitationParams = {
  inviteCode: string;
  type?: string;
  subType?: string;
};

export const AcceptedInviteInitialValues = {
  agentFirstName: '',
  agentLastName: '',
  renterEmail: '',
  renterPhone: '',
  renterExists: undefined,
};

export enum InvitesErrorCodes {
  INVALID_INVITE_ERROR_CODE = 172,
  ALREADY_ACCEPTED_ERROR_CODE = 176,
  COMPLETE_PROFILE_BEFORE_ACCEPTTING_INVITE = 183,
}

export type RenterInviteParams = {
  inviteCode: string;
  url: string;
};

export interface InviteDetails {
  inviteCode: string;
  agentFirstName: string;
  agentLastName: string;
  renterFirstName?: string;
  renterLastName?: string;
  city?: string;
  state?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  renterEmail?: string;
  renterPhoneNumber?: string;
  renterExists?: boolean;
  type: string; // Make enum for it
  status: string; //Make enum for it
  createdAt?: string;
}
export interface AdultInviteDetails {
  adultEmail: string;
  adultPhoneNumber?: string;
  renterFirstName?: string;
  renterLastName?: string;
  renterEmail?: string;
  applicationId: number;
  streetAddress: string;
  invitedAdultExists?: boolean;
}
export interface GuarantorInviteDetails {
  guarantorEmail: string;
  guarantorPhoneNumber?: string;
  renterFirstName?: string;
  renterLastName?: string;
  renterEmail: string;
  applicationId: number;
  streetAddress: string;
  invitedGuarantorExists?: boolean;
}
export interface InviteDetailsResponse {
  payload: InviteDetails;
  errors: [] | ApiErrorType[];
}
export interface AdultInviteDetailsResponse {
  payload: AdultInviteDetails;
  errors: [] | ApiErrorType[];
}
export interface GuarantorInviteDetailsResponse {
  payload: GuarantorInviteDetails;
  errors: [] | ApiErrorType[];
}

export interface RenterInviteDetailsProps {
  inviteCode: string;
  inviteType?: string;
}

export interface PropertyInviteDetails {
  propertyId: number;
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  zipCode: string;
  state: string;
  monthlyRentAmount?: number;
  depositAmount?: number;
  bedroomsCount?: number;
  bathroomsCount?: number;
  active: boolean;
  isCustomAddress: boolean;
  createdAt?: string;
}
export interface SecurityQuestionsProps {
  QuestionType: number;
  QuestionText: string;
  QuestionSelect: {
    QuestionChoice: string[];
  };
}
export interface SecurityQuestionsPropsRenter {
  questionType: number;
  questionText: string;
  questionSelect: {
    questionChoice: string[];
  };
}
export interface FormattedAnswers {
  answer: number;
}
export interface SecurityAnswer {
  kbaAnswers: FormattedAnswers[];
}
