import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { applicationProperties } from 'redux/selectors/renterSelector';

import defaultImage from 'assets/images/Rectangle 1515.png';
import { ReactComponent as HomeIcon } from 'assets/svgs/HomeIconGrayBackground.svg';
import { formatAddressZipCode } from 'helpers/renterHelper';

import { CustomPropertyTooltip } from './CustomPropertyTooltip';

import styles from './PropertyInformation.module.scss';
const PropertyInformation = (): JSX.Element | null => {
  const property = useSelector(applicationProperties);
  const { t } = useTranslation();
  const isCustom = useMemo(() => property?.isCustomAddress, [property]);

  if (isEmpty(property)) {
    return null;
  }

  const propertyImg = property.propertyImages?.length ? property.propertyImages[0].fileLink : defaultImage;

  return (
    <Row className={styles.propertyInformation}>
      <Col xs={2} className={styles.alignLeft}>
        <div className={styles.propertyThumbnail}>
          {property?.isCustomAddress ? (
            <HomeIcon />
          ) : (
            <img src={propertyImg} alt={t('application.propertyThumnbnail')} />
          )}
        </div>
      </Col>
      {!isCustom && (
        <Col xs={2} className={styles.alignLeft}>
          <span>${property.monthlyRentAmount}/mo</span>
        </Col>
      )}
      <Col xs={8}>
        {property.streetAddress1?.substring(0, 20)}&nbsp;
        {!isCustom && <br />}
        <span className={styles.propertyAddress}>
          {formatAddressZipCode({ state: property.state, city: property.city, zipCode: property.zipCode })}
        </span>
      </Col>
      {isCustom && (
        <Col xs={1}>
          <CustomPropertyTooltip />
        </Col>
      )}
    </Row>
  );
};

export default PropertyInformation;
