/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { renterAnimals } from 'redux/selectors/renterSelector';

import RenterContainer from 'components/Renter/components/RenterContainer';
import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import RCButton from 'components/shared/Button/Button';
import { AnimalType, FurryFriendTypes, RenterAnimalSteps } from 'constants/renterConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { AnimalInformationProps } from 'shared/types/renterTypes';

import { AnimalHeader } from './AnimalHeader/AnimalHeader';
import AnimalDisplayCard from './AnimalDisplayCard';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from './AnimalDocumentation/AnimalDocumentation.module.scss';

const AnimalConfirmation = (): JSX.Element => {
  const { applicationId } = useParams();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const animalInformation = useSelector(renterAnimals);
  const getAnimalByType = (
    animalInfo: AnimalInformationProps[]
  ): { assistiveAnimals: AnimalInformationProps[]; petAnimals: AnimalInformationProps[] } =>
    animalInfo.reduce(
      (acc, animal) => {
        if (animal.furryFriendType === FurryFriendTypes.ASSISTIVE_ANIMAL) {
          acc.assistiveAnimals.push(animal);
        } else if (animal.furryFriendType === FurryFriendTypes.PETS) {
          acc.petAnimals.push(animal);
        }

        return acc;
      },
      { assistiveAnimals: [] as AnimalInformationProps[], petAnimals: [] as AnimalInformationProps[] }
    );
  const { assistiveAnimals, petAnimals } = useMemo(() => getAnimalByType(animalInformation ?? []), [animalInformation]);
  const maxHeight = useDeviceHeight(220);

  return (
    <RenterContainer>
      <section className={styles.renterBody}>
        <div
          className={styles.container}
          style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
        >
          <RenterIconInfoContainer />
          <div className={styles.headingLabel}>{t('renter.petsInformation.documentationAssistiveLabelB')}</div>
          {!isEmpty(petAnimals) && <AnimalHeader animalType={FurryFriendTypes.PETS} animalInformation={petAnimals} />}
          {petAnimals.map((animal, index) => (
            <div className={styles.SingleAnimal} key={`pet-animal-${animal.id}`}>
              <AnimalDisplayCard index={index + 1} animalInformation={animal} />
            </div>
          ))}
          {!isEmpty(assistiveAnimals) && (
            <AnimalHeader animalType={FurryFriendTypes.ASSISTIVE_ANIMAL} animalInformation={assistiveAnimals} />
          )}
          {assistiveAnimals.map((animal, index) => (
            <div className={styles.SingleAnimal} key={`assistive-animal-${animal.id}`}>
              <AnimalDisplayCard index={index + 1} animalInformation={animal} />
            </div>
          ))}
          <div className={styles.renterButtonContainer}>
            <RCButton
              className={classNames(styles.buttonPrimary, styles.buttonPrimarySmall)}
              variant="outline"
              onClick={() =>
                setSearchParams({
                  animalType: AnimalType.PETS,
                  step: RenterAnimalSteps.FORM,
                  furryFriendType: FurryFriendTypes.PETS,
                })
              }
            >
              {t('renter.petsInformation.addPetLabel')}
            </RCButton>
            <RCButton
              className={classNames(styles.buttonPrimary, styles.buttonPrimarySmall)}
              variant="outline"
              onClick={() =>
                setSearchParams({
                  animalType: AnimalType.ASSISTIVE_ANIMAL,
                  step: RenterAnimalSteps.SELECTION,
                  furryFriendType: FurryFriendTypes.ASSISTIVE_ANIMAL,
                })
              }
            >
              {t('renter.petsInformation.addAssistivePetLabel')}
            </RCButton>
            <RCButton
              className={styles.renterButton}
              variant="outline"
              onClick={() => {
                navigate(renterRoutes.generateViewRenterBackgroundInformation(applicationId));
              }}
            >
              {t('renter.petsInformation.lookGoodLabel')}
            </RCButton>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default AnimalConfirmation;
