import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import { AppThunkDispatch } from 'redux/store';

import { INITIAL_DEFAULT } from 'constants/publicConstants';
import { fetchVerifierQuestion } from 'helpers/publicHelper';
import { VerificationPDF, VerifierURLType } from 'shared/pdf/verifier/voe/VerifierPDF';
import { FormValues } from 'shared/types/renterTypes';

export const VerificationPDFPage = (): JSX.Element => {
  const [params] = useSearchParams();
  const token = params.get('token') || '';
  const [, setIsSubmitted] = useState(false);
  const [questionnaire, setQuestionnaire] = useState<FormValues>(INITIAL_DEFAULT);
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    fetchVerifierQuestion({ token: token, setQuestionnaire, dispatch, setIsSubmitted });
  }, [dispatch, token]);

  return (
    <PDFViewer height={'800px'} width={'80%'}>
      <VerificationPDF type={params.get('type') as VerifierURLType} questionnaire={questionnaire} />
    </PDFViewer>
  );
};
