import { Dispatch } from 'react';
import { IOptionProps } from 'pages/AgentPofileForm/types';

import styles from '../ProfileScreen.module.scss';

type Props = {
  category: IOptionProps;
  setProfileType: Dispatch<React.SetStateAction<string>>;
  description: string;
};

export const ProfileType = ({ category, setProfileType, description }: Props): JSX.Element => (
  <div className={styles.ProfileTypeBox} onClickCapture={() => setProfileType(category.value)}>
    <div className={styles.ProfileTypeHeading}>{category.label}</div>
    <div className={styles.ProfileTypeDescription}>{description}</div>
  </div>
);
