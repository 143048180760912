import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { addDays, differenceInDays, format } from 'date-fns';
import { isEmpty } from 'lodash-es';
import {
  applicationPropertyLink,
  renterApplication,
  selectApplicationReportConsent,
  selectRenterCreditProfile,
  selectRenterNovaReport,
  selectRenterProfileInformation,
} from 'redux/selectors/renterSelector';
import { setApplicationId } from 'redux/slices/authSlice';
import { getRenterExperianReportInformation, getRenterNovaReport, getSelectedProperty } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import defaultAgentProfile from 'assets/images/defaultProfilePicture.png';
import { ReactComponent as DocumentIcon } from 'assets/svgs/DocumentIcon.svg';
import { ReactComponent as ExperianIcon } from 'assets/svgs/ExperianLogoFull.svg';
import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import { ReactComponent as LeftArrow } from 'assets/svgs/leftArrowIcon.svg';
import { CreditReport } from 'components/Agent/Applications/ApplicationDetails/components/RenterCreditAndBackground/components/CreditReport/CreditReport';
import { CriminalReport } from 'components/Agent/Applications/ApplicationDetails/components/RenterCreditAndBackground/components/CriminalReport/CriminalReport';
import { HousingCourtRecords } from 'components/Agent/Applications/ApplicationDetails/components/RenterCreditAndBackground/components/HousingCourtRecords/HousingCourtRecords';
import { ReportStatusLoader } from 'components/Agent/Applications/ApplicationDetails/components/RenterCreditAndBackground/components/ReportStatusLoader/ReportStatusLoader';
import DocumentItem from 'components/Agent/Applications/ApplicationDetails/components/shared/Documents/DocumentItem/DocumentItem';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import infoStyles from 'components/shared/ApplicationInfoBox/ApplicationInfoBox.module.scss';
import ApplicationSelected from 'components/shared/ApplicationInfoBox/ApplicationSelected';
import { DocumentPreview } from 'components/shared/DocumentPreview/DocumentPreview';
import { dateFormatFrontend } from 'constants/calendarConstants';
import { CreditBackgroundKeys, CreditCheckProvider, RenterCreditBackgroundSteps } from 'constants/renterConstants';
import { ReportErrorTypes, reportExpireyErrorCode } from 'constants/reportConstants';
import { CreditCheckProviders } from 'constants/resumeSideMenuConstants';
import { getApplicationById, getFormattedApplications } from 'helpers/renterHelper';
import { getRemainingDaysPillType } from 'helpers/reportsHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { DOCUMENT_FORMATS, IMAGE_FORMATS } from 'shared/constants';
import { renterRoutes } from 'shared/routes';
import { PropertyProps } from 'shared/types/propertyType';
import { ApplicationProps, VisaDocument } from 'shared/types/renterTypes';

import CollapsibleComponent from '../CollapsibleComponent/CollapsibleComponent';
import RenterContainer from '../RenterContainer';

import { ActionTooltip } from './ActionTooltip';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './ApplicationCreditBackground.module.scss';
export const ApplicationCreditBackground = (): JSX.Element => {
  const { appId: applicationId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { creditCheckProvider, visaDocuments } = useSelector(selectRenterProfileInformation);
  const novaReport = useSelector(selectRenterNovaReport);
  const renterApplications = useSelector(renterApplication);
  const isRenterReportConsented = useSelector(selectApplicationReportConsent);
  const [isReportLoaded, setIsReportLoaded] = useState(false);
  const [isReportExpired, setIsReportExpired] = useState(false);
  const [isReportError, setIsReportError] = useState(false);
  const [isCreditReportError, setIsCreditReportError] = useState(false);
  const [hasShareConsent, setHasShareConsent] = useState(false);
  const renterCreditProfile = useSelector(selectRenterCreditProfile);
  const { propertyId, desiredMoveInDate } = useSelector(applicationPropertyLink);
  const onBackIconClickHandler = useCallback(() => {
    navigate(renterRoutes.applications);
  }, [navigate]);
  const dispatch = useDispatch<AppThunkDispatch>();
  const [propertyInformation, setPropertyInformation] = useState<Record<number, PropertyProps>>({});
  const [applicationPropertyInformation, setApplicationPropertyInformation] = useState<ApplicationProps | null>(null);
  const [isShowingOverlay, setIsShowingOverlay] = useState(false);
  const maxHeight = useDeviceHeight(200);
  const [selectedDocument, setSelectedDocument] = useState<undefined | VisaDocument>(
    visaDocuments ? visaDocuments[0] : undefined
  );
  const getDate30DaysAhead = useCallback((date: string): string => {
    if (!date) {
      return '—';
    }

    return format(addDays(new Date(date), 30), dateFormatFrontend);
  }, []);
  const getRemainingDays = useMemo((): number | string => {
    if (
      creditCheckProvider !== CreditCheckProvider.EXPERIAN ||
      !renterCreditProfile.report ||
      !renterCreditProfile.success
    ) {
      return '–';
    }

    const difference =
      differenceInDays(
        new Date(getDate30DaysAhead(renterCreditProfile?.report?.Header?.ReportDate ?? '')),
        new Date()
      ) + 1;

    return difference > 0 ? difference : 0;
  }, [creditCheckProvider, getDate30DaysAhead, renterCreditProfile]);
  const hasReportErrors = useMemo(
    (): boolean => isReportError || isReportExpired || !isReportLoaded,
    [isReportError, isReportExpired, isReportLoaded]
  );
  const isReportValid = useMemo(
    (): boolean => hasReportErrors || isCreditReportError,
    [hasReportErrors, isCreditReportError]
  );
  const getReportStatusInfo = useMemo((): { heading: string; pillType: PillTypes; texts: string[] } => {
    if (isReportExpired) {
      return {
        heading: t('agent.creditReport.expiredReport'),
        pillType: PillTypes.SECONDARY,
        texts: [t('agent.creditBackground.expiredText'), ` ${t('agent.properties.learnMore')}`],
      };
    }

    if (isReportError || isCreditReportError) {
      return {
        heading: t('application.warning'),
        pillType: PillTypes.DANGER,
        texts: [t('agent.creditBackground.warningText'), ` ${t('rental.historyInformation.contactSupport')}`],
      };
    }

    return {
      heading: t('agent.creditReport.inProgressReport'),
      pillType: PillTypes.PRIMARY_CYAN_LIGHT,
      texts: [t('agent.creditBackground.inProgressText'), ` ${t('agent.properties.learnMore')}`],
    };
  }, [isCreditReportError, isReportError, isReportExpired, t]);
  const getCreditReportErrorLoaderType = useMemo((): JSX.Element => {
    let errorType = ReportErrorTypes.IN_PROGRESS;

    if (isReportExpired && isReportLoaded) {
      errorType = ReportErrorTypes.EXPIRED;
    } else if (isReportError && !isReportExpired && isReportLoaded) {
      errorType = ReportErrorTypes.ERROR;
    }

    return (
      <div className={styles.ErrorContainer}>
        <ReportStatusLoader errorType={errorType} />
      </div>
    );
  }, [isReportError, isReportExpired, isReportLoaded]);
  const { heading, pillType, texts } = useMemo(() => getReportStatusInfo, [getReportStatusInfo]);
  const creditBackgroundComponents = useMemo(
    () => ({
      [CreditBackgroundKeys.CREDIT_REPORTS]: isReportValid ? (
        <div className={styles.ErrorContainer}>{getCreditReportErrorLoaderType}</div>
      ) : (
        <CreditReport isRenterFlow={true} />
      ),
      [CreditBackgroundKeys.CRIMINAL_RECORDS]: hasReportErrors ? (
        <div className={styles.ErrorContainer}>
          <ReportStatusLoader />
        </div>
      ) : (
        <CriminalReport isRenterFlow={true} />
      ),
      [CreditBackgroundKeys.HOUSING_COURT_RECORDS]: hasReportErrors ? (
        <div className={styles.ErrorContainer}>
          <ReportStatusLoader />
        </div>
      ) : (
        <HousingCourtRecords isRenterFlow={true} />
      ),
    }),
    [isReportValid, getCreditReportErrorLoaderType, hasReportErrors]
  );
  const RenderDocumentPreview = useCallback(() => {
    if (selectedDocument && selectedDocument?.fileType) {
      if (DOCUMENT_FORMATS.includes(selectedDocument.fileType.toUpperCase())) {
        return <DocumentPreview link={selectedDocument.fileLink as string} />;
      }

      if (IMAGE_FORMATS.includes(selectedDocument.fileType.toUpperCase())) {
        return (
          <img
            src={selectedDocument.fileLink}
            alt={`visa-document-${selectedDocument.fileName}`}
            className={styles.DocumentImage}
          />
        );
      }

      return <div className={styles.PreviewText}>No preview available for this file</div>;
    }

    return <div className={styles.PreviewText}>Select file to preview</div>;
  }, [selectedDocument]);

  useEffect(() => {
    if (isRenterReportConsented !== undefined) {
      setHasShareConsent(isRenterReportConsented);
    }
  }, [isRenterReportConsented]);
  useEffect(() => {
    if (applicationId) {
      dispatch(setApplicationId(Number(applicationId)));

      if (applicationPropertyInformation?.id && propertyId) {
        dispatch(getSelectedProperty({ propertyId }))
          .unwrap()
          .then((propertyResponse: PropertyProps) => {
            setPropertyInformation({ [applicationId]: propertyResponse });
          });
      }
    }
  }, [applicationId, applicationPropertyInformation, dispatch, propertyId]);

  useEffect(() => {
    if (applicationId && !isEmpty(renterApplications) && propertyInformation?.[Number(applicationId)]?.streetAddress1) {
      setApplicationPropertyInformation(
        getApplicationById(
          getFormattedApplications({
            renterApplications,
            propertiesInformation: propertyInformation,
            applicationId: Number(applicationId),
          }),
          Number(applicationId)
        )
      );
    }
  }, [applicationId, propertyInformation, renterApplications]);

  //Fetching credit profile report
  useEffect(() => {
    if (creditCheckProvider === CreditCheckProvider.EXPERIAN && !isReportLoaded) {
      dispatch(getRenterExperianReportInformation())
        .unwrap()
        .then()
        .catch((errorCode) => {
          setIsReportExpired(errorCode.message === reportExpireyErrorCode);
          setIsReportError(true);
        })
        .finally(() => {
          setIsReportLoaded(true);
        });
    }
  }, [creditCheckProvider, dispatch, isReportLoaded]);

  useEffect(() => {
    if (creditCheckProvider === CreditCheckProvider.NOVA) {
      dispatch(getRenterNovaReport());
    }
  }, [creditCheckProvider, dispatch]);

  useEffect(() => {
    if (renterCreditProfile?.success === false) {
      setIsCreditReportError(true);
    }
  }, [renterCreditProfile]);

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div className={styles.HeadingContainer}>
          <LeftArrow onClick={onBackIconClickHandler} height={24} width={24} className={styles.LeftArrowIcon} />
          <div className={styles.Heading}>
            {CreditCheckProviders[creditCheckProvider as keyof typeof CreditCheckProviders]}
          </div>
        </div>
        <div
          className={styles.ViewApplicationContainer}
          style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
        >
          <div className={styles.ScrollableContent}>
            <div className={styles.InformationBox}>
              <InformationIcon className={styles.InformationIcon} />
              <p className={styles.InformationText}>{t('renter.applicationsIndex.yourAgentHasBeenNotified')}</p>
            </div>
          </div>
          {applicationPropertyInformation && (
            <div className={infoStyles.InfoBoxContainer}>
              <ApplicationSelected
                city={applicationPropertyInformation.city}
                dateAvailable={applicationPropertyInformation.dateAvailable ?? ''}
                propertyImage={applicationPropertyInformation.propertyImage}
                rent={applicationPropertyInformation.rent}
                state={applicationPropertyInformation.state}
                streetAddress={applicationPropertyInformation.streetAddress}
                zipCode={applicationPropertyInformation.zipCode}
                isCustomAddress={applicationPropertyInformation.isCustomAddress}
                moveInDate={desiredMoveInDate}
                agentProfile={defaultAgentProfile}
                agentFirstName={applicationPropertyInformation.agentFirstName}
                agentLastName={applicationPropertyInformation.agentLastName}
              />
            </div>
          )}
          {creditCheckProvider === CreditCheckProvider.EXPERIAN && (
            <div className={styles.CreditbackgrounfInformation}>
              <div className={styles.RemainingDays}>
                <AgentPills
                  pillContainerClassName={styles.PillContainer}
                  pillsData={[
                    {
                      heading: `${isReportExpired ? 0 : getRemainingDays} ${t('agent.creditReport.days')}`,
                      type: getRemainingDaysPillType(isReportExpired ? 0 : getRemainingDays),
                    },
                  ]}
                />
              </div>
              <div className={styles.ReportDatesContainer}>
                <div className={styles.DateContainer}>
                  <div className={styles.DateLabel}>{t('applicationCreditBackground.returned')}</div>
                  <div className={styles.Date}>{renterCreditProfile?.report?.Header?.ReportDate ?? '—'}</div>
                </div>
                <div className={styles.DateContainer}>
                  <div className={styles.DateLabel}>{t('applicationCreditBackground.expires')}</div>
                  <div className={styles.Date}>
                    {getDate30DaysAhead(renterCreditProfile?.report?.Header?.ReportDate ?? '')}
                  </div>
                </div>
              </div>
              <div className={styles.LogoContainer}>
                <div className={styles.ExperianLogo}>
                  <ExperianIcon className={styles.ExperianIcon} />
                  {isReportValid && (
                    <AgentPills
                      pillContainerClassName={styles.PillContainer}
                      pillsData={[
                        {
                          heading: heading,
                          type: pillType,
                        },
                      ]}
                    />
                  )}
                </div>
                <ActionTooltip
                  hasShareConsent={hasShareConsent}
                  setHasShareConsent={setHasShareConsent}
                  isShowingOverlay={isShowingOverlay}
                  setIsShowingOverlay={setIsShowingOverlay}
                />
              </div>

              {isReportValid && ( //Error information text
                <div className={styles.ReportStatusText}>
                  {texts[0]}
                  <Link to="#" className={styles.LinkText}>
                    {texts[1]}
                  </Link>
                </div>
              )}
              <div className={styles.CollapsibleComponents}>
                {RenterCreditBackgroundSteps.map((step) => (
                  <CollapsibleComponent key={`Credit-Background-${step}}`} shouldBeCollapsedByDefault heading={step}>
                    {creditBackgroundComponents[step]}
                  </CollapsibleComponent>
                ))}
              </div>
            </div>
          )}

          {creditCheckProvider === CreditCheckProvider.NOVA && novaReport?.pdfReportUrl && (
            <DocumentPreview link={novaReport.pdfReportUrl} />
          )}

          {creditCheckProvider === CreditCheckProvider.DOCUMENT && (
            <div>
              {visaDocuments?.map((document, index) => (
                <div
                  key={`visaDocument-${document.fileName}`}
                  onClickCapture={() => setSelectedDocument(document)}
                  className={classNames(styles.DocumentItem, {
                    [styles.SelectedDocument]: selectedDocument?.fileName === document.fileName,
                  })}
                >
                  <DocumentItem
                    key={`attachment-${document.fileLink}`}
                    documentName={document.fileName}
                    icon={<DocumentIcon />}
                    index={index + 1}
                  />
                </div>
              ))}
              <RenderDocumentPreview />
            </div>
          )}
        </div>
      </section>
    </RenterContainer>
  );
};
