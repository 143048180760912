import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectApplicationId } from 'redux/selectors/authSelector';
import { selectRenterProfileInformation } from 'redux/selectors/renterSelector';

import { renterRoutes } from 'shared/routes';
import { RenterRoles } from 'shared/types/renterTypes';

const RenterRedirection = (): JSX.Element => {
  const navigate = useNavigate();
  const applicationId = useSelector(selectApplicationId);
  const renterDetails = useSelector(selectRenterProfileInformation);
  const role = renterDetails?.applications?.find((app) => app.id === applicationId)?.renterRole;

  useEffect(() => {
    if (role === RenterRoles.PRIMARY) {
      navigate(renterRoutes.startApplication);
    } else if (applicationId) {
      navigate(renterRoutes.generateViewRenterCriteriaUrl(applicationId.toString()));
    }
  }, [applicationId, navigate, role]);

  return <div />;
};

export default RenterRedirection;
