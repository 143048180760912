import { useLayoutEffect, useRef } from 'react';

import { usePrevious } from 'hooks/usePrevious';
import { SingleOTPInputProps } from 'shared/types/renterTypes';

export const SingleInputField = (props: SingleOTPInputProps): JSX.Element => {
  const { isFocus, autoFocus: hasAutoFocus, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const isPrevFocus = usePrevious(!!isFocus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (isFocus && hasAutoFocus) {
        inputRef.current.focus();
      }

      if (isFocus && hasAutoFocus && isFocus !== isPrevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [hasAutoFocus, isFocus, isPrevFocus]);

  return <input ref={inputRef} {...rest} />;
};
