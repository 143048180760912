import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Formik, FormikErrors } from 'formik';
import { isEmpty } from 'lodash-es';
import { applicantRenterRole, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addVehicleInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { vehiclesInformationSchema } from 'schema/renterSchema';

import indexStyles from 'components/Renter/RenterIndex.module.scss';
import { NavigationKeys, RenterVehicleSteps, VehicleTypes } from 'constants/renterConstants';
import { renterRestrictions } from 'constants/restrictionConstants';
import { StepRouteType } from 'helpers/address';
import { tabTitle } from 'helpers/helper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { vehicleInitialValue, VehiclesInformationType } from 'shared/types/renterVehicleInformation';

import RenterContainer from '../components/RenterContainer';
import RenterIconInfoContainer from '../components/RenterIconInfoContainer';

import VehiclesCheck from './VehiclesCheck/VehiclesCheck';
import VehiclesForm from './VehiclesForm/VehiclesForm';
import VehicleType from './VehicleType/VehicleType';

const RenterVehiclesInformation = (): JSX.Element => {
  tabTitle('Your Application | Vehicle Information');
  const { applicationId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();
  const maxHeight = useDeviceHeight(220);
  const stepName = searchParams.get('step') || RenterVehicleSteps.CHECK;
  const vehicleType = searchParams.get('type') || VehicleTypes.CAR;
  const [nextRoute] = useState(searchParams.get(NavigationKeys.NEXT));
  const [section] = useState(searchParams.get(NavigationKeys.SECTION));
  const renterRole = useSelector(applicantRenterRole);
  const { vehicles, hasNoVehicles } = useSelector(selectRenterProfileInformation);
  const stepRoute: StepRouteType = {
    check: <VehiclesCheck />,
    selectType: <VehicleType />,
    form: <VehiclesForm />,
  };
  const handleNext = useCallback(() => {
    navigate(renterRoutes.generateVehiclesInformationSummary(applicationId));
  }, [applicationId, navigate]);
  const handleSubmit = useCallback(
    (
      values: VehiclesInformationType,
      resetForm: () => void,
      setErrors: (errors: FormikErrors<VehiclesInformationType>) => void
    ): void => {
      const id = values.id || 0;

      values.vehicleType = vehicleType;

      dispatch(addVehicleInformation({ values, id }))
        .unwrap()
        .then(() => {
          if (section) {
            navigate(
              nextRoute ?? `${renterRoutes.generateVehiclesInformationSummary(applicationId)}?section=${section}`
            );
          } else {
            handleNext();
            resetForm();
          }
        })
        .catch((error) => {
          setErrors(error);
        });
    },
    [applicationId, dispatch, handleNext, navigate, nextRoute, section, vehicleType]
  );

  useEffect(() => {
    if (renterRestrictions[renterRole].vehicles || (hasNoVehicles && isEmpty(vehicles) && !nextRoute)) {
      navigate(renterRoutes.generateRenterPetsInformation(applicationId));
    }
  }, [applicationId, hasNoVehicles, navigate, nextRoute, renterRole, vehicles]);

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
          <RenterIconInfoContainer />
          <div className={indexStyles.renterContentContainer}>
            <Formik
              enableReinitialize
              initialValues={vehicleInitialValue}
              validationSchema={vehiclesInformationSchema}
              onSubmit={(values, { resetForm, setErrors }) => {
                handleSubmit(values, resetForm, setErrors);
              }}
            >
              {stepRoute[stepName]}
            </Formik>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default RenterVehiclesInformation;
