import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { selectInviteCode } from 'redux/selectors/authSelector';
import {
  renterApplication,
  selectRenterApplicationProperties,
  selectRenterProfileInformation,
} from 'redux/selectors/renterSelector';
import {
  getAgentDetails,
  getApplicationPropertyLink,
  getSelectedProperty,
  setAgentDetails,
  setApplicationProperties,
} from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import { ReactComponent as WarningGreyIcon } from 'assets/svgs/WarningGreyIcon.svg';
import ApplicationInfoBox from 'components/shared/ApplicationInfoBox/ApplicationInfoBox';
import RCButton from 'components/shared/Button/Button';
import { CreditCheckProvider, ExperianAuthenticationStatus } from 'constants/renterConstants';
import { tabTitle } from 'helpers/helper';
import { getFormattedApplications } from 'helpers/renterHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { PropertyProps } from 'shared/types/propertyType';
import { AgentDetailsResponse } from 'shared/types/renterApplication';
import { ApplicationPropertyLinksResponse } from 'shared/types/renterCooccupants';

import RenterContainer from '../RenterContainer';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './ApplicationIndex.module.scss';
const ApplicationIndex = (): JSX.Element => {
  const [submittedFilter, setSubmittedFilter] = useState(true);
  const { t } = useTranslation();
  const onButtonClickHandler = useCallback((isNewSubmitValue: boolean) => {
    setSubmittedFilter(isNewSubmitValue);
  }, []);
  const nextRoute = renterRoutes.applications;
  const dispatch = useDispatch<AppThunkDispatch>();
  const renterApplications = useSelector(renterApplication);
  const renterInformations = useSelector(selectRenterProfileInformation);
  const [arePropertiesFetched, setArePropertiesFetched] = useState(false);
  const applicationPropertiesInformation = useSelector(selectRenterApplicationProperties);
  const inviteCode = useSelector(selectInviteCode);
  const { applications } = renterInformations;
  const navigate = useNavigate();
  const maxHeight = useDeviceHeight(167);

  tabTitle(`${renterInformations.firstName} ${renterInformations.lastName}`);

  useEffect(() => {
    if (!isEmpty(renterApplications) && !arePropertiesFetched && !inviteCode) {
      renterApplications?.forEach((application, index) => {
        dispatch(getApplicationPropertyLink(application.id))
          .unwrap()
          .then((res: ApplicationPropertyLinksResponse) => {
            const propertyId: number = res.applicationProperties[0].propertyId;

            dispatch(getSelectedProperty({ propertyId }))
              .unwrap()
              .then((propertyResponse: PropertyProps) => {
                const propertyObject: Record<number, PropertyProps> = { [application.id]: propertyResponse };

                dispatch(setApplicationProperties(propertyObject));
              });

            dispatch(getAgentDetails({ applicationId: application?.id }))
              .unwrap()
              .then((agentDetails: AgentDetailsResponse) => {
                dispatch(setAgentDetails({ applicationId: application.id, agentDetails }));
              });
          })
          .finally(() => {
            if (index === renterApplications.length - 1) {
              setArePropertiesFetched(true);
            }
          });
      });
    }
  }, [arePropertiesFetched, dispatch, inviteCode, renterApplications, t]);

  const handlePassiveAuth = (): void => {
    const applicationConfirmed = applications.find((item) => !item.isConfirmed);

    navigate(renterRoutes.generateRenterReportUrl(applicationConfirmed?.id));
  };

  if (
    Object.keys(applicationPropertiesInformation).length !== renterApplications.length ||
    isEmpty(renterApplications) ||
    !arePropertiesFetched
  ) {
    return <div />;
  }

  return (
    <RenterContainer>
      <section className={indexStyles.renterBody}>
        <div className={styles.ApplicationIndexContainer}>
          <div
            className={indexStyles.renterContentContainer}
            style={{ paddingRight: '10px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
          >
            {CreditCheckProvider.EXPERIAN === renterInformations.creditCheckProvider &&
              renterInformations?.experianAuth !== ExperianAuthenticationStatus.COMPLETED && (
                <div className={styles.WarningBox}>
                  <WarningGreyIcon className={styles.WarningIcon} />
                  <p className={styles.WarningText}>
                    {
                      <RCButton variant="outline" className={styles.NavigationButton} onClick={handlePassiveAuth}>
                        {t('renter.application.clickHere')}
                      </RCButton>
                    }
                    &nbsp;
                    {t('renter.applicationsIndex.yourPassiveAuthIsPending')}
                  </p>
                </div>
              )}
            <div className={styles.MainHeading}>{t('renter.applicationsIndex.applications')}</div>
            <div className={styles.ScrollableContent}>
              <div className={styles.FilterButtonsContainer}>
                <RCButton
                  onClick={() => onButtonClickHandler(true)}
                  variant="outline"
                  className={classNames(styles.SubmittedButton, { [styles.SelectedButton]: submittedFilter })}
                >
                  {t('renter.applicationsIndex.submitted')}
                </RCButton>
                <RCButton
                  onClick={() => onButtonClickHandler(false)}
                  variant="outline"
                  className={classNames(styles.ProgressButton, { [styles.SelectedButton]: !submittedFilter })}
                >
                  {t('renter.applicationsIndex.inProgress')}
                </RCButton>
              </div>
              <div className={styles.InformationBox}>
                <InformationIcon className={styles.InformationIcon} />
                <p className={styles.InformationText}>{t('renter.applicationsIndex.yourAgentHasBeenNotified')}</p>
              </div>
              <div className={styles.ApplicationsContainer}>
                {getFormattedApplications({
                  renterApplications,
                  propertiesInformation: applicationPropertiesInformation,
                }).map((application) => (
                  <ApplicationInfoBox
                    key={`Application-${application.agentFirstName}-${application.id}`}
                    applicationData={application}
                    nextRoute={nextRoute}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default ApplicationIndex;
