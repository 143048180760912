import * as Yup from 'yup';

import { passwordRegex } from 'constants/regexConstant';

export const LoginFormSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
});

export const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(passwordRegex, 'Password must meet the guidelines. ')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .matches(passwordRegex, 'Password must meet the guidelines. ')
    .required('Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

export const SignupFormSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
  password: Yup.string().matches(passwordRegex, 'Password must meet the guidelines. ').required('Password is required'),
});
