import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import { isSaving } from 'redux/selectors/renterSelector';

import RCButton from 'components/shared/Button/Button';
import CustomRangeDatePicker from 'components/shared/CustomRangeDatePicker/CustomRangeDatePicker';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import { defaultDate, endDate } from 'constants/renterConstants';
import { PRESENT_ADDRESS_FROM_DATE_START, PresentAddressDateProps } from 'helpers/address';
import { AddressInitialValuesProps } from 'shared/types/renterTypes';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './PresentAddressDate.module.scss';

const PresentAddressDate = ({
  address,
  setIsRenting,

  setRequireClassification,
}: PresentAddressDateProps): JSX.Element => {
  const { t } = useTranslation();
  const { values, errors, setFieldTouched, dirty: isDirty } = useFormikContext<AddressInitialValuesProps>();
  const isSavingState = useSelector(isSaving);

  useEffect(() => {
    if (
      errors.renterAddress?.moveInDate &&
      errors.renterAddress?.moveInDate !== t('renter.addressSchema.fromDateIsRequired')
    ) {
      setFieldTouched('renterAddress.moveInDate', true);
    }
  }, [errors.renterAddress?.moveInDate, setFieldTouched, t]);

  const onIRentClickerHandler = useCallback(() => {
    setIsRenting(true);
  }, [setIsRenting]);

  return (
    <div className={styles.presentAddressContainer}>
      <FormCloseWarning isDirty={isDirty} />
      <div
        data-testid="presentAddressDateRange"
        className={classNames(styles.rangeDatePickerContainer, {
          [styles.errorDatePickerContainer]: !isEmpty(errors),
        })}
      >
        <CustomRangeDatePicker
          data-testid="presentAddressDateRange"
          leftCalendarName={`renterAddress.moveInDate`}
          leftCalenderStartDate={PRESENT_ADDRESS_FROM_DATE_START}
          leftCalenderEndDate={endDate}
          rightCalendarName={`renterAddress.toDate`}
          isToPresent={true}
          defaultSelected={defaultDate}
        />
      </div>
      {(values.renterAddress?.moveInDate || address.moveInDate) && (
        <>
          <div className={classNames(indexStyles.renterButtonContainer, styles.addressButtonConatiner)}>
            <RCButton
              className={classNames(indexStyles.renterButton, styles.presentAddressButton)}
              variant="outline"
              disabled={!!errors.renterAddress?.moveInDate || isSavingState}
              onClick={onIRentClickerHandler}
            >
              {t('renter.ConfirmPresentAddress.iRent')}
            </RCButton>
          </div>
          <div className={classNames(indexStyles.renterButtonContainer, styles.addressButtonConatiner)}>
            <RCButton
              onClick={() => {
                setRequireClassification(true);
              }}
              disabled={!!errors.renterAddress?.moveInDate || isSavingState}
              className={classNames(indexStyles.renterButton, styles.presentAddressButton)}
              variant="outline"
            >
              {t('renter.ConfirmPresentAddress.iDoNotRent')}
            </RCButton>
          </div>
        </>
      )}
    </div>
  );
};

export default PresentAddressDate;
