import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as EmailIcon } from 'assets/svgs/EmailGrey.svg';
import { ReactComponent as MapPinSvg } from 'assets/svgs/MapPinOutline.svg';
import { ReactComponent as MapPinUserSvg } from 'assets/svgs/MapPinUser.svg';
import { ReactComponent as PhoneIcon } from 'assets/svgs/PhoneGrey.svg';
import { ContactDetails } from 'components/Agent/Applications/ApplicationDetails/components/RenterPersonalProfile/components';
import { RequestVerificationDate } from 'components/Agent/components/shared/RequestVerificationWrapper';
import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';
import AgentPills, { Pill, PillTypes } from 'components/shared/AgentPills/AgentPills';
import { RenterAddressTypes } from 'constants/renterConstants';
import { formatName } from 'helpers/helper';
import { getUserDetails } from 'helpers/publicHelper';
import { formatAddressZipCode, formatPhoneNumber } from 'helpers/renterHelper';
import { RenterAddressDetails } from 'shared/types/renterTypes';

export const RentalHistoryProfile: FC<{ setRequestedAt?: Dispatch<SetStateAction<string>> }> = ({
  setRequestedAt,
}): JSX.Element => {
  const { t } = useTranslation();
  const statusPills: Pill[] = [{ heading: t('application.today'), type: PillTypes.MIDNIGHT_STEEL }];
  const [params] = useSearchParams();
  const token = params.get('token') || '';
  const [userDetails, setUserDetails] = useState<RenterAddressDetails>({} as RenterAddressDetails);

  useEffect(() => {
    getUserDetails(token, setUserDetails, setRequestedAt);
  }, [setRequestedAt, token]);

  const {
    renterFirstName,
    renterAddressInformation,
    renterEmail,
    renterMiddleName,
    renterLastName,
    renterPhoneNumber,
    verifierEmail,
    verifierFullName,
    verifierPhoneNumber,
    requestedAt,
  } = userDetails || {};
  const { city, state, zipCode, streetAddress, fromDate, toDate, rent, addressType, moveInDate } =
    renterAddressInformation || {};
  const getResidencyDates = (): string => {
    if (addressType === RenterAddressTypes.PRESENT) {
      return `${moveInDate ?? '---'} - ${RenterAddressTypes.PRESENT}`;
    } else {
      return `${fromDate ?? '---'} - ${toDate ?? '---'}`;
    }
  };

  return (
    <section className={styles.RentalAddressInfo}>
      {/*Renter Profile*/}
      <section>
        <div className={styles.PillContainer}>
          <AgentPills pillsData={statusPills} pillContainerClassName={styles.PillHeading} />
        </div>
        <Row className={styles.RentalTitle}>
          <Col sm={12} md={4} className={styles.TextSection}>
            {t('rental.historyInformation.renter')}
          </Col>
          <Col sm={12} md={6} className={styles.TextRightContainer}>
            <RequestVerificationDate requestedAt={requestedAt} />
          </Col>
        </Row>
        <div className={styles.RentalContactCard}>
          <div>{formatName(renterFirstName, renterMiddleName, renterLastName)}</div>
          <Row className={styles.ContactDetailsContainer}>
            <Col sm={12}>
              <ContactDetails heading={t('application.email')} data={renterEmail} icon={<EmailIcon />} />
            </Col>
            <Col sm={12}>
              <ContactDetails
                heading={t('application.mobile')}
                data={formatPhoneNumber(renterPhoneNumber)}
                icon={<PhoneIcon />}
              />
            </Col>
          </Row>
        </div>
      </section>
      {/* Renter Address and Renter Owner Details */}
      <section className={styles.RentalSectionContainer}>
        <span>{t('rental.historyInformation.submition')}</span>
        <Row className={styles.RentalLayoutContainer}>
          <Col xs={12} className={styles.RentalSectionContainer}>
            <div className={classNames(styles.RentalTitle, styles.SpaceContainer)}>
              <MapPinSvg /> {t('rental.historyInformation.rentalProperty')}
            </div>
            <div className={styles.RentalContactCard}>
              <div className={styles.RentalAddressContainer}>
                <span>{streetAddress}</span>
                <span>{formatAddressZipCode({ city, state, zipCode })}</span>
              </div>
              <ContactDetails heading={t('application.monthlyRent')} data={`$${rent}`} />
              <ContactDetails heading={t('application.residency')} data={getResidencyDates()} />
            </div>
          </Col>
          <Col xs={12} className={styles.RentalSectionContainer}>
            <div className={classNames(styles.RentalTitle, styles.SpaceContainer)}>
              <MapPinUserSvg /> {t('renter.Ownerinformation.owner/ManagerCapital')}
            </div>
            <div className={styles.RentalContactCard}>
              <span className={styles.RenterNameContainer}>
                <ContactDetails
                  heading={t('agent.applicants.renterResume.sourceOfIncome.verifier.fullName')}
                  data={verifierFullName}
                />
              </span>
              <ContactDetails heading={t('application.email')} data={verifierEmail} />
              <ContactDetails heading={t('application.mobile')} data={formatPhoneNumber(verifierPhoneNumber)} />
            </div>
          </Col>
        </Row>
      </section>
    </section>
  );
};
