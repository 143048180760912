import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { applicantRenterRole, applicationPropertyLink, renterApplication } from 'redux/selectors/renterSelector';

import RenterContainer from 'components/Renter/components/RenterContainer';
import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import { renterRestrictions } from 'constants/restrictionConstants';
import { sendInvitationsToAdults } from 'helpers/cooccupantsHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { renterRoutes } from 'shared/routes';
import { SendAdultCooccupantsInviteParams } from 'shared/types/renterCooccupants';

import { AdultsOccupantListCard } from './AdultsOccupantListCard';

import styles from '../RenterMinor/RelationMinorConfirmation.module.scss';

const RelationAdultConfirmation = (): JSX.Element => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const maxHeight = useDeviceHeight(220);
  const applications = useSelector(renterApplication);
  const { adults, minors } = applications.find((app) => app.id === Number(applicationId)) || {};
  const renterRole = useSelector(applicantRenterRole);
  const { propertyId } = useSelector(applicationPropertyLink);
  const onClickApplicationMinor = useCallback(async () => {
    const params: SendAdultCooccupantsInviteParams = {
      applicationId: Number(applicationId),
      propertyLinkId: propertyId,
      adultId: 0,
    };

    // Use Promise.all to await all invitations
    adults?.map((adult) => {
      params.adultId = adult.id ?? 0;

      return sendInvitationsToAdults(params);
    });

    if (!renterRestrictions[renterRole].minors && !minors?.length) {
      navigate(renterRoutes.renterRelationMinorIndex.replace(':applicationId', `${applicationId}`));
    } else {
      navigate(renterRoutes.generateRenterRelationMinorConfirmationUrl(applicationId));
    }
  }, [applicationId, propertyId, adults, renterRole, minors?.length, navigate]);

  return (
    <RenterContainer>
      <div className={styles.container} style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto' }}>
        <RenterIconInfoContainer />
        <AdultsOccupantListCard
          attributeLabel={t('renter.relationship.relationshipLabel')}
          label={t('renter.relationship.adultCapital')}
          addAnotherButtonLabel={t('renter.relationship.addAnotherAdult')}
          confirmButtonLabel={t('renter.relationship.adultConfirmButton')}
          informationText={t('renter.relationship.adultInformation')}
          floatingLabel={t('renter.relationship.adultAgeWarning')}
          headerText={t('renter.relationship.adultHeaderText')}
          continueButtonOnClick={onClickApplicationMinor}
          addAnotherButtonOnClick={() =>
            navigate(renterRoutes.renterAdults.replace(':applicationId', `${applicationId}`))
          }
        />
      </div>
    </RenterContainer>
  );
};

export default RelationAdultConfirmation;
