import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { AppThunkDispatch } from 'redux/store';

import RenterIconInfoContainer from 'components/Renter/components/RenterIconInfoContainer';
import RCButton from 'components/shared/Button/Button';
import { renterRoutes } from 'shared/routes';
import {
  CustomPropertySteps,
  InviteDataKeys,
  InviteType,
  navigateToCreateCustomProperty,
  navigateToSelectProperty,
} from 'shared/types/renterApplication';

import styles from '../RenterPropertySelection.module.scss';
export const AgentGeneral = (): JSX.Element => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get(InviteDataKeys.INVITE_CODE) || '';
  const navigate = useNavigate();
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    if (applicationId) {
      navigate(renterRoutes.generateViewRenterCriteriaUrl(applicationId));
    }
  }, [dispatch, inviteCode, navigate, applicationId]);

  return (
    <div>
      <RenterIconInfoContainer />
      <div className={styles.AlignTextStart}>
        <h2 className={styles.RenterApplicationHeading}>
          {t('renter.application.agentGeneral.introduction')}&nbsp;
          <span className={styles.HighlightedText}>{t('renter.application.title')}&nbsp;</span>
        </h2>
        <div className={styles.ContinueButtonContainer}>
          <RCButton
            onClick={() => {
              navigateToSelectProperty(navigate, InviteType.AGENT_GENERAL, inviteCode);
            }}
            className={classNames(styles.Button, styles.PrimaryButton)}
            variant="outline"
          >
            {t('renter.application.agentGeneral.selectProperty')}
          </RCButton>
          <RCButton
            className={classNames(styles.Button, styles.PrimaryButton)}
            onClick={() =>
              navigateToCreateCustomProperty({
                navigate,
                inviteType: InviteType.AGENT_GENERAL,
                inviteCode,
                stepName: CustomPropertySteps.CREATE_PROPERTY,
              })
            }
            variant="outline"
          >
            {t('renter.application.customInformation')}
          </RCButton>
        </div>
      </div>
    </div>
  );
};
