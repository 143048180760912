import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { VerificationBarPDFProps } from 'shared/types/pdfTypes';

import { MailGreySVG } from '../../svgs/MailGreySVG';
const styles = StyleSheet.create({
  Container: {
    display: 'flex',
    padding: '4px 12px',
    alignItems: 'center',
    gap: '20px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    border: '1px solid #E6EFF0',
    flexDirection: 'row',
  },
  VerificationLabel: {
    color: '#303333',
    fontSize: '11px',
    fontWeight: 'semibold',
  },
  LinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
  Link: {
    overflow: 'hidden',
    color: '#303333',
    textOverflow: 'ellipsis',
    fontSize: '12px',
  },
});

export const VerificationBarPDF = ({
  verificationLabel,
  verificationLink,
  customIcon,
}: VerificationBarPDFProps): JSX.Element => (
  <View style={styles.Container} wrap={false}>
    <Text style={styles.VerificationLabel}>{verificationLabel}</Text>
    <View style={styles.LinkContainer}>
      {customIcon || <MailGreySVG />}
      <Text style={styles.Link}>{verificationLink}</Text>
    </View>
  </View>
);
