import { format } from 'date-fns';
import { capitalize, isEmpty, startCase } from 'lodash-es';

import { dateFormatFrontend, monthsPartial } from 'constants/calendarConstants';
import { digitsRegex } from 'constants/regexConstant';
import { KeyboadKeys } from 'shared/constants';
import { NameParts } from 'shared/types/renterTypes';

export const userInfo = (email: string, firstName: string, lastName: string): string => {
  if (firstName && lastName) {
    return startCase(`${firstName} ${lastName}`);
  }

  return email;
};

export const splitFullName = (fullName: string): NameParts => {
  // Splitting full name into individual parts
  const [firstPart, ...remainingParts] = fullName.split(' ');
  // Joining remaining parts to form the last name
  const lastName = remainingParts.join(' ');

  // Returning an object with first name and last name
  return {
    firstName: isEmpty(firstPart) ? undefined : firstPart,
    lastName: isEmpty(lastName) ? undefined : lastName,
  };
};

export const formatDate = (params?: string | Date | undefined): string => {
  if (params) {
    return format(new Date(String(params)), dateFormatFrontend);
  }

  return '---';
};

export const formattedDateString = (params?: string | Date): string => {
  const date = String(params);

  if (params) {
    const parts = date.split('-');
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const day = parseInt(parts[2]);
    const formattedMonth = monthsPartial[month - 1];
    const formattedDay = day.toString().padStart(2, '0');

    return `${formattedMonth ?? ''} ${formattedDay ?? ''}, ${year ?? ''}`;
  }

  return 'MM/DD/YYYY';
};

export const kebabCaseToTitle = (inputString: string | undefined): string => {
  if (inputString) {
    return capitalize(startCase(inputString).replace(/\s/g, ''));
  }

  return '';
};

export const pluralize = (value: number | undefined, unit: string, suffix = 's'): string => {
  const pluralizedUnit = unit + (value !== 1 ? suffix : '');

  return `${value} ${pluralizedUnit}`;
};

export const allowOnlyDigits = (e: React.KeyboardEvent<HTMLInputElement>): void => {
  if (!digitsRegex.test(e.key) && e.key !== KeyboadKeys.BACKSPACE && e.key !== KeyboadKeys.TAB) {
    e.preventDefault(); // Prevent entering character
  }
};

/**
 * Calculate the duration between two dates in years and months.
 * @param {string} endDate - The end date in string format (e.g., "YYYY-MM-DD").
 * @param {string} startDate - The start date in string format (e.g., "YYYY-MM-DD").
 * @returns {string} - A string representing the duration in the format "X yrs, Y mos" or "X yrs" or "Y mos" or an empty string if there are no years or months.
 */
export const durationBetweenTwoDates = (endDate: string, startDate: string): string => {
  const date1: Date = new Date(endDate);
  const date2: Date = new Date(startDate);
  const yearDiff: number = date1.getFullYear() - date2.getFullYear();
  const monthDiff: number = date1.getMonth() - date2.getMonth();
  const totalMonths: number = yearDiff * 12 + monthDiff;
  const years: number = Math.floor(totalMonths / 12);
  const months: number = totalMonths % 12;

  if (years && months) {
    return `${years} yrs, ${months} mos`;
  } else if (years) {
    return `${years} yrs`;
  } else if (months) {
    return `${months} mos`;
  } else {
    return ''; // Return '' if there are no years or months.
  }
};

export const formatAmount = (amount: number): string => amount?.toLocaleString();
