import { useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { applicantRenterRole, renterApplication } from 'redux/selectors/renterSelector';

import { ReactComponent as ThreeDotsIcon } from 'assets/svgs/ThreeDotsIcon.svg';
import styles from 'components/Renter/IdentityVerification/RenterDetails/shared/Popover.module.scss';
import { renterRestrictions } from 'constants/restrictionConstants';
import { scrollToViewOfGivenId } from 'helpers/agentHelper';

type MinorTooltipProps = {
  handleEditInfo: (index: number, minorId?: number) => void;
  handleAddInfo: () => void;
};
const MinorsTooltip = ({ handleAddInfo, handleEditInfo }: MinorTooltipProps): JSX.Element => {
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const { t } = useTranslation();
  const renterRole = useSelector(applicantRenterRole);
  const applications = useSelector(renterApplication);
  const { minors } = applications.find((app) => app.id === Number(currentApplicationId)) || {};
  const minorsCount = minors?.length ?? 0;
  const renderTooltip = (
    <Popover id="popover-positioned-bottom">
      <Popover.Body className={styles.popoverBody}>
        <div className={styles.popoverWrapper}>
          {minors &&
            minors.map((minor, index) => (
              <button
                className={styles.popoverBtn}
                onClick={() => handleEditInfo(index + 1, minor.id)}
                key={`edit-minor-${index}`}
              >
                {t('renter.paymentConfirmation.houseHoldDetails.editMinor')} {index + 1}
              </button>
            ))}
          <button className={styles.popoverBtn} onClick={handleAddInfo}>
            {minorsCount
              ? t('renter.paymentConfirmation.houseHoldDetails.addAnother')
              : t('renter.paymentConfirmation.houseHoldDetails.addAMinor')}
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  if (renterRestrictions[renterRole].minors) {
    return <div />;
  }

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      key="bottom"
      placement="bottom"
      overlay={renderTooltip}
      onToggle={() => scrollToViewOfGivenId('minors')}
    >
      <span className={styles.overlayTrigger}>
        <ThreeDotsIcon />
      </span>
    </OverlayTrigger>
  );
};

export default MinorsTooltip;
