import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Form, Formik, FormikErrors } from 'formik';
import { t } from 'i18next';
import { isSaving } from 'redux/selectors/renterSelector';
import { updateRenterAddressInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';
import { AddressFormSchema } from 'schema/renterSchema';

import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import AddressBox from 'components/shared/AddressBox/AddressBox';
import Button from 'components/shared/Button/Button';
import FormCloseWarning from 'components/shared/FormCloseWarning/FormCloseWarning';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import FormikField from 'components/shared/TextField/FormikTextField';
import { formatRentPrefixString } from 'helpers/renterHelper';
import { AddressInformation } from 'shared/types/renterTypes';

import RenterModal from '../RenterModal/RenterModal';

import styles from './AddressVerifierEditModal.module.scss';

type AddressVerifierEditModalProps = { onHide: () => void; shouldShowModal: boolean; address: AddressInformation };

const AddressVerifierEditModal = ({ onHide, shouldShowModal, address }: AddressVerifierEditModalProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const isSavingInformation = useSelector(isSaving);
  const handleSubmit = useCallback(
    (values: AddressInformation, setErrors: (errors: FormikErrors<AddressInformation>) => void) => {
      const id = values.id;

      if (id) {
        dispatch(updateRenterAddressInformation({ id: id, address: values }))
          .unwrap()
          .catch((error) => setErrors(error))
          .finally(() => onHide());
      }
    },
    [dispatch, onHide]
  );
  const isValid = useCallback((values: AddressInformation) => {
    if (values.emailAddress && values.fullName && values.phoneNumber && values.contactRent) {
      return true;
    }

    return false;
  }, []);

  return (
    <Formik
      onSubmit={(values, { setErrors }) => handleSubmit(values, setErrors)}
      initialValues={address}
      enableReinitialize
      validationSchema={AddressFormSchema[0]}
    >
      {({ dirty: isDirty, values, touched, errors, setErrors }) => (
        <RenterModal show={shouldShowModal} onHide={onHide} isDirty={isDirty}>
          <Form>
            <FormCloseWarning isDirty={isDirty} />
            <div className={styles.container}>
              <div className={styles.heading}>{t('renter.PhotoIdInformation.editDetails')}</div>
              <div className={styles.subHeading}>
                {t('renter.PhotoIdInformation.makeChanges')}&nbsp;
                <span className={styles.spanColor}>{t('application.verifier')}</span>
              </div>
              <div data-testid="ownerAddressBox" className={styles.addresBoxContainer}>
                <AddressBox address={address} shouldShowEditIcon={false} />
              </div>

              <div className={styles.textFieldContainer}>
                <span className={styles.textFieldLabel}>{t('renter.Ownerinformation.owner/Manager')}</span>
                <FormikField name={`fullName`} placeholder={t('renter.relationship.fullNamePlaceHolder')} />
              </div>

              <div
                className={classNames(styles.textFieldContainer, {
                  [styles.contactRentFieldContainer]: values.contactRent,
                  [styles.rentErrorFieldContainer]: !!errors?.contactRent?.length && touched?.contactRent,
                })}
              >
                <span className={styles.textFieldLabel}>{t('renter.Ownerinformation.rentAmount')}</span>
                <FormikField
                  prefixString={formatRentPrefixString(values.contactRent)}
                  name={`contactRent`}
                  placeholder="$ 0,000"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </div>

              <div className={styles.textFieldContainer}>
                <FormikPhoneNumber
                  name={`phoneNumber`}
                  placeholder="(000) 000-0000"
                  label={t('renter.Ownerinformation.phone')}
                />
              </div>
              <div className={styles.textFieldContainer}>
                <span className={styles.textFieldLabel}>{t('renter.Ownerinformation.email')}</span>
                <FormikField name={`emailAddress`} placeholder="ex@manager.com" />
              </div>

              <div className={styles.ownerInformationContainer}>
                <InformationIcon className={styles.ownerInformationIcon} />
                <p className={styles.ownerInformationText}>{t('renter.OwnerInformation.aRequestForVerification')}</p>
              </div>

              <div className={styles.buttonContainer}>
                <Button
                  variant="outline"
                  className={styles.renterButton}
                  type="submit"
                  disabled={isSavingInformation || !isValid(values)}
                  onClick={() => handleSubmit(values, setErrors)}
                >
                  {t('renter.PhotoIdInformation.savechanges')}
                </Button>
              </div>
            </div>
          </Form>
        </RenterModal>
      )}
    </Formik>
  );
};

export default AddressVerifierEditModal;
