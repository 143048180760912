import classNames from 'classnames';

import { CountCardProps } from 'shared/types/agentTypes';

import styles from './CountCard.module.scss';
export const CountCard = ({ count, customContainerClassName, customCountClassName }: CountCardProps): JSX.Element => (
  <div className={classNames(styles.Container, customContainerClassName)}>
    <div className={classNames(styles.Count, customCountClassName)}>{count}</div>
  </div>
);
