import { FuseResult } from 'fuse.js';

import { Tag } from 'shared/types/propertyType';

export const featureFuzzySearchObject = {
  shouldSort: true,
  threshold: 0.6,
  distance: 100,
  location: 0,
  includeScore: true,
  keys: ['name'],
};

export const sortFuseData = (a: FuseResult<Tag>, b: FuseResult<Tag>, searchTerm: string): number => {
  const aIndex = a.item.name.indexOf(searchTerm);
  const bIndex = b.item.name.indexOf(searchTerm);
  const aSpaceIndex = a.item.name.indexOf(' ' + searchTerm);
  const bSpaceIndex = b.item.name.indexOf(' ' + searchTerm);
  const getScore = (item: FuseResult<Tag>): number => item.score || -1;

  if (aIndex === 0 && bIndex === 0) {
    return getScore(a) - getScore(b);
  } else if (aIndex === 0 && bIndex > 0) {
    return -1;
  } else if (aIndex > 0 && bIndex === 0) {
    return 1;
  } else if (aSpaceIndex === -1 && bSpaceIndex > 0) {
    return 1;
  } else if (bSpaceIndex === -1 && aSpaceIndex > 0) {
    return -1;
  } else if (aSpaceIndex > 0 && bSpaceIndex > 0) {
    if (aIndex > 0 && bIndex > 0) {
      return getScore(a) - getScore(b);
    } else if (aIndex > 0 && bIndex === -1) {
      return 1;
    } else if (aIndex === -1 && bIndex > 0) {
      return -1;
    }
  }

  return getScore(a) - getScore(b);
};
