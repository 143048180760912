import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import CopyIconButton from 'components/shared/CopyIconButton/CopyIconButton';

import styles from './ContactDetails.module.scss';

type ContactDetailsProps = {
  heading: string;
  data?: string;
  icon?: ReactNode;
  shouldShowCopy?: boolean;
};

const ContactDetails = ({ heading, data, icon, shouldShowCopy = false }: ContactDetailsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      <div className={styles.InfoContainer}>
        {icon && <div>{icon}</div>}
        <div className={styles.Details}>
          <span className={styles.Heading}>{heading}</span>
          <a
            href={`${heading.toLowerCase().includes(t('application.email').toLowerCase()) ? 'mailto:' : 'tel:'}${data}`}
            className={styles.Data}
          >
            {data}
          </a>
        </div>
      </div>
      {shouldShowCopy && <CopyIconButton textToCopy={data ?? ''} className={styles.Button} />}
    </div>
  );
};

export default ContactDetails;
