import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { QuestionnaireAnswerOptions } from 'constants/agentConstants';

import { PDFCheckBox } from '../PDFCheckbox/PDFCheckbox';

const styles = StyleSheet.create({
  Container: {
    flexDirection: 'column',
    gap: '8px',
  },
  QuestionContainer: {
    flexDirection: 'row',
    gap: '10px',
  },
  QuestionNumber: {
    color: '#303333',
    fontSize: '12px',
  },
  Question: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 500,
  },
  AnswerContainer: {
    paddingLeft: '20px',
    flexDirection: 'column',
  },
  OptionsContainer: {
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '6px',
  },
  Option: {
    flexDirection: 'row',
    gap: '5px',
  },
  OptionText: {
    color: '#303333',
    fontSize: '12px',
    fontWeight: 500,
  },
  InfoTextContainer: {
    paddingTop: '2px',
  },
  InfoText: {
    color: '#5E6566',
    fontSize: '10px',
  },
  Divider: {
    width: '100%',
    marginTop: '3px',
    borderBottom: '1px solid #8D9899',
  },
  ExtraInformation: {
    color: '#303333',
    fontSize: '10px',
    fontWeight: 600,
    paddingLeft: '3px',
  },
  DateRangeContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingLeft: '3px',
  },
  DateLabel: {
    color: '#5E6566',
    fontSize: '11px',
  },
  DateValue: {
    color: '#303333',
    fontSize: '11px',
    fontWeight: 600,
  },
  FromDate: {
    flexDirection: 'row',
    gap: '8px',
    flex: 1,
  },
  ToDate: {
    flexDirection: 'row',
    gap: '8px',
    paddingLeft: '8px',
    borderLeft: '1px solid #D2D6D6',
    flex: 1,
  },
});

export const QuestionPDF = ({
  index,
  question,
  answer,
  dateRange,
  extraInformation,
  numberDetails,
}: {
  index: number;
  question: string;
  answer?: QuestionnaireAnswerOptions;
  dateRange?: { fromDate?: string; toDate?: string };
  extraInformation?: string;
  numberDetails?: number;
}): JSX.Element => (
  <View wrap={false} style={styles.Container}>
    <View style={styles.QuestionContainer}>
      <Text style={styles.QuestionNumber}>{`${index}.)`}</Text>
      <Text style={styles.Question}>{question}</Text>
    </View>
    <View style={styles.AnswerContainer}>
      <View style={styles.OptionsContainer}>
        <View style={styles.Option}>
          <PDFCheckBox isChecked={!!answer && answer === QuestionnaireAnswerOptions.YES} />
          <Text style={styles.OptionText}>YES</Text>
        </View>
        <View style={styles.Option}>
          <PDFCheckBox isChecked={!!answer && answer === QuestionnaireAnswerOptions.NO} />
          <Text style={styles.OptionText}>NO</Text>
        </View>
        <View style={styles.Option}>
          <PDFCheckBox isChecked={!!answer && answer === QuestionnaireAnswerOptions.NOT_AVAILABLE} />
          <Text style={styles.OptionText}>NA</Text>
        </View>
        <View style={styles.InfoTextContainer}>
          <Text style={styles.InfoText}>Please explain discrepancies:</Text>
        </View>
      </View>
      {!!extraInformation && <Text style={styles.ExtraInformation}>{extraInformation}</Text>}
      {!!numberDetails && <Text style={styles.ExtraInformation}>{`${numberDetails} times`}</Text>}
      {!!dateRange?.fromDate && (
        <View style={styles.DateRangeContainer}>
          <View style={styles.FromDate}>
            <Text style={styles.DateLabel}>From</Text>
            <Text style={styles.DateValue}>{dateRange.fromDate}</Text>
          </View>
          <View style={styles.ToDate}>
            <Text style={styles.DateLabel}>To</Text>
            <Text style={styles.DateValue}>{dateRange.toDate}</Text>
          </View>
        </View>
      )}

      <View style={styles.Divider} />
    </View>
  </View>
);
