import classNames from 'classnames';

import { ReactComponent as BlackArrow } from 'assets/svgs/BlackDropdownArrow.svg';
import ProfileDropdown from 'components/shared/ProfileDropdown/ProfileDropdown';

import ProfileInfo from '../ProfileInfo/ProfileInfo';

import styles from './ProfileIcon.module.scss';
export interface IProfileProps {
  imageSrc?: string;
  name: string;
  organizationName?: string;
  className?: string;
  profileImgStyling?: string;
  isProfileSet?: boolean;
  isNonProfilePage?: boolean;
}

const Profile = ({
  imageSrc,
  name,
  className,
  profileImgStyling,
  organizationName,
  isProfileSet = true,
  isNonProfilePage = true,
}: IProfileProps): JSX.Element => (
  <ProfileDropdown isProfileSet={isProfileSet}>
    <div className={classNames(styles.profileContainer, className)}>
      {isNonProfilePage ? (
        <ProfileInfo
          imageSrc={imageSrc}
          organizationName={organizationName}
          name={name}
          profileImgStyling={profileImgStyling}
        />
      ) : (
        <BlackArrow className={styles.WebDropDownArrow} />
      )}
    </div>
  </ProfileDropdown>
);

export default Profile;
