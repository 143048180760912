import { sendAdultCooccupantsInvite } from 'services/renterService';
import { InviteType, SendAdultCooccupantsInviteParams } from 'shared/types/renterCooccupants';
/**
 * Retrieves property links for a given application and updates the property ID in the state of first property.
 *
 * @param {number} applicationId - The ID of the application for which property links are to be fetched.
 * @param {Dispatch<SetStateAction<number>>} setPropertyId - A function to update the property ID in the state.
 * @param {ThunkDispatch<unknown, unknown, AnyAction>} dispatch - A Redux dispatch function for handling actions.
 * @returns {Promise<void>} A promise that resolves when the property links are fetched and processed.
 */

export const sendInvitationsToAdults = async ({
  applicationId,
  propertyLinkId,
  adultId,
}: SendAdultCooccupantsInviteParams): Promise<void> => {
  try {
    await sendAdultCooccupantsInvite({
      applicationId,
      propertyLinkId,
      adultId,
      type: InviteType.EMAIL,
    });
    await sendAdultCooccupantsInvite({
      applicationId,
      propertyLinkId,
      adultId,
      type: InviteType.SMS,
    });
  } catch (error) {
    console.error(error);
  }
};
