import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { t } from 'i18next';
import { RenterAddresses } from 'redux/selectors/renterSelector';

import { ReactComponent as LocationPinDropIcon } from 'assets/svgs/LocationPinDropGreyIcon.svg';
import indexStyles from 'components/Renter/IdentityVerification/IdentityVerification.module.scss';
import Badge from 'components/Renter/IdentityVerification/RenterDetails/shared/Badge/Badge';
import { Ladder } from 'components/Renter/IdentityVerification/RenterDetails/shared/Ladder/Ladder';
import Title from 'components/Renter/IdentityVerification/RenterDetails/shared/Title/Title';
import { RenterAddressSteps, RenterAddressTypes } from 'constants/renterConstants';
import { formatAddressForFrontEnd } from 'helpers/renterHelper';
import { renterRoutes } from 'shared/routes';
import { AddressInformation } from 'shared/types/renterTypes';

import VerifierCard from '../VerifierCard';

import PriorAddressToolTip from './AddPriorAddressToolTip/AddPriorAddressToolTip';
import DescriptionCard from './DescriptionCard/DescriptionCard';

type PriorAddressProps = {
  handleEditVerifier: (address: AddressInformation) => void;
  handleEditAddress: (address: AddressInformation) => void;
  next?: string;
};

const PriorAddress = ({ handleEditVerifier, handleEditAddress, next }: PriorAddressProps): JSX.Element => {
  const { applicationId, appId } = useParams();
  const currentApplicationId = useMemo(() => applicationId || appId, [appId, applicationId]);
  const address = useSelector(RenterAddresses);
  const navigate = useNavigate();
  const currentAddress = useMemo((): AddressInformation[] | undefined => {
    if (address) {
      return address.filter(({ addressType }) => addressType === RenterAddressTypes.PRIOR);
    }

    return undefined;
  }, [address]);
  const handleAddAddress = (): void => {
    navigate(
      renterRoutes.generateAddressHistoryInformation(
        Number(currentApplicationId),
        RenterAddressSteps.PRIOR_ADDRESS,
        next ?? renterRoutes.generateRenterPaymentVerification(currentApplicationId)
      )
    );
  };

  return (
    <div className={indexStyles.container}>
      <Title
        mainTitle={t('renter.AddressEditModal.prior')}
        iconElement={<LocationPinDropIcon />}
        shouldShowOptions
        optionElement={<PriorAddressToolTip handleAddAddress={handleAddAddress} addressInformation={currentAddress} />}
      />
      {currentAddress?.length ? (
        <div>
          {currentAddress.map((priorAddress, indexValue) => {
            const formattedPriorAddress = formatAddressForFrontEnd(priorAddress);
            const isVerifierPresent =
              formattedPriorAddress?.emailAddress &&
              formattedPriorAddress?.fullName &&
              formattedPriorAddress?.phoneNumber;

            return (
              <div key={`${priorAddress.streetAddress}+ ${indexValue}`}>
                <Badge text={`Address ${indexValue + 1}`} />
                <div className={indexStyles.bottomPadding} key={formattedPriorAddress.id}>
                  <div className={indexStyles.borderContainer}>
                    <DescriptionCard
                      address={formattedPriorAddress}
                      handleEditAddress={handleEditAddress}
                      handleEditVerifier={handleEditVerifier}
                    />
                  </div>
                  {isVerifierPresent && (
                    <div className={indexStyles.personalInformationContainer}>
                      <div className={indexStyles.fieldContainer}>
                        <Ladder isBottomPadding={false} />
                        <div className={classNames(indexStyles.borderContainer, indexStyles.noTopBorder)}>
                          <VerifierCard address={formattedPriorAddress} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Title mainTitle={t('addressBox.iDoNotHaveAPriorAddress')} />
      )}
    </div>
  );
};

export default PriorAddress;
