import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { Form, useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import { isLoading } from 'redux/selectors/renterSelector';
import { addRenterGuarantorInformation, updateRenterGuarantorInformation } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import RCButton from 'components/shared/Button/Button';
import { NavigationKeys } from 'constants/renterConstants';
import { RenterGuarantorSteps } from 'constants/renterConstants';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { GuarantorDispatchProps, GuarantorInitialValuesProps } from 'shared/types/renterTypes';

import RenterContainer from '../../RenterContainer';
import RenterIconInfoContainer from '../../RenterIconInfoContainer';
import GuarantorDetailsForm from '../GuarantorDetailsForm/GuarantorDetailsForm';

import indexStyles from '../../../RenterIndex.module.scss';
import styles from './GuarantorInformationForm.module.scss';
const GuarantorInformationForm = (): JSX.Element => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const { values, dirty: isDirty, errors, setErrors } = useFormikContext<GuarantorInitialValuesProps>();
  const isLoadingInformation = useSelector(isLoading);
  const [searchParams] = useSearchParams();
  const navigateNext = searchParams.get(NavigationKeys.NEXT);
  const maxHeight = useDeviceHeight(220);
  const onAddGuarantor = useCallback(
    (guarantorDispatchInformation: GuarantorDispatchProps) => {
      dispatch(
        addRenterGuarantorInformation({
          applicationId: Number(applicationId),
          values: guarantorDispatchInformation,
        })
      )
        .unwrap()
        .then(() => {
          if (navigateNext) {
            navigate(navigateNext);
          } else {
            setSearchParams({ step: RenterGuarantorSteps.SUMMARY });
          }
        })
        .catch((errorObject) => {
          setErrors(errorObject);
        });
    },
    [dispatch, applicationId, navigateNext, navigate, setSearchParams, setErrors]
  );
  const onUpdateGuarantor = useCallback(
    (guarantorDispatchInformation: GuarantorDispatchProps, id: number) => {
      dispatch(
        updateRenterGuarantorInformation({
          applicationId: Number(applicationId),
          values: guarantorDispatchInformation,
          id: id,
        })
      )
        .unwrap()
        .then(() => {
          setSearchParams({ step: RenterGuarantorSteps.SUMMARY });
        })
        .catch((errorObject) => {
          setErrors(errorObject);
        });
    },
    [applicationId, dispatch, setErrors, setSearchParams]
  );
  const onClickHandler = useCallback(() => {
    const guarantorDispatchInformation: GuarantorDispatchProps = {
      relationship: values.relationship ?? '',
      phoneNumber: values.phoneNumber?.includes('+') ? values.phoneNumber : '+' + values.phoneNumber,
      emailAddress: values.emailAddress ?? '',
    };

    if (!values?.relation?.id) {
      onAddGuarantor(guarantorDispatchInformation);
    } else if (values?.relation.id && isDirty) {
      onUpdateGuarantor(guarantorDispatchInformation, values.relation.id);
    } else {
      setSearchParams({ step: RenterGuarantorSteps.SUMMARY });
    }
  }, [isDirty, onAddGuarantor, onUpdateGuarantor, setSearchParams, values]);
  const areAllValuesFilled = useMemo(
    (): boolean => Boolean(values.emailAddress && values.phoneNumber && values.relationship),
    [values]
  );

  if (isLoadingInformation) {
    return <div />;
  }

  return (
    <RenterContainer>
      <section className={classNames(indexStyles.renterBody, styles.informationContainer)}>
        {/* icons list */}
        <div style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflow: 'auto' }}>
          <RenterIconInfoContainer />
          <div className={indexStyles.renterContentContainer}>
            <h2 className={indexStyles.renterH2}>
              {t('renter.guarantorInformationForm.enterYourGuarantorContactInformation')}
            </h2>
            <Form>
              <GuarantorDetailsForm
                emailAddressName="emailAddress"
                relationshipName="relationship"
                phoneNumberName="phoneNumber"
              />
              <div className={indexStyles.renterButtonContainer}>
                <RCButton
                  type="submit"
                  onClick={onClickHandler}
                  className={classNames(indexStyles.renterButton, indexStyles.nextButton, styles.guarantorButton)}
                  variant="outline"
                  disabled={!isEmpty(errors) || !areAllValuesFilled}
                >
                  {t('renter.guarantorInformationForm.continue')}
                </RCButton>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </RenterContainer>
  );
};

export default GuarantorInformationForm;
