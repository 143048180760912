import { useCallback, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classnames from 'classnames';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { isLoading } from 'redux/selectors/authSelector';
import { resetPassword } from 'redux/slices/authSlice';
import { AppThunkDispatch } from 'redux/store';
import { ResetPasswordSchema } from 'schema/authSchema';

import { ReactComponent as HomeDesign } from 'assets/svgs/authBackGround.svg';
import { ReactComponent as PasswordIcon } from 'assets/svgs/PasswordIcon.svg';
import { ReactComponent as PasswordSlashIcon } from 'assets/svgs/PasswordSlashIcon.svg';
import Button from 'components/shared/Button/Button';
import { PasswordGuidelines } from 'components/shared/PasswordGuidelines/PasswordGuidelines';
import CustomSpinner from 'components/shared/Spinner/Spinner';
import FormikField from 'components/shared/TextField/FormikField';
import { Notification, NotificationType } from 'shared/Notification/Notification';
import { routes } from 'shared/routes';

import 'stylesheets/mixins/sharedStyles.scss';
import styles from '../Login/Login.module.scss';
import newStyles from './ResetPassword.module.scss';

interface ResetPasswordValues {
  newPassword: string;
  confirmPassword: string;
}

const validValues = {
  newPassword: '',
  confirmPassword: '',
};

export const ResetPassword = (): JSX.Element => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const isLoadingEnabled = useSelector(isLoading);
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const token = params.get('token');
  const handleResetPassword = useCallback(
    (values: ResetPasswordValues): void => {
      if (token) {
        dispatch(
          resetPassword({
            password: values.newPassword,
            token,
          })
        )
          .unwrap()
          .then(() => {
            Notification({ message: t('agent.passwordChangeSuccessful'), type: NotificationType.SUCCESS });
            navigate(routes.login);
          });
      } else {
        Notification({ message: t('application.tokenNotFound') });
      }
    },
    [dispatch, t, token, navigate]
  );

  return (
    <div className={classnames('px-lg-4 px-sm-0 px-md-5', styles.container)}>
      <Row className={styles.containerRow}>
        <Col xl={4} lg={4} md={8} className={styles.signUpCol}>
          <HomeDesign className={classnames(styles.homeDesignTop)} />
          <Card className={styles.cardContainer}>
            <HomeDesign className={classnames(styles.homeDesignBottom)} />
            <div className="card-body py-5 px-5">
              <div className={newStyles.Heading}>{t('agent.createNewPassword')}</div>

              <Formik initialValues={validValues} validationSchema={ResetPasswordSchema} onSubmit={handleResetPassword}>
                {({ values, isValid, dirty: isDirty }) => (
                  <Form>
                    <FormikField
                      className={classNames('inputBackgroundColor', styles.PasswordField)}
                      name="newPassword"
                      type={isPasswordVisible ? 'text' : 'password'}
                      label="New Password"
                      placeholder="New Password"
                      onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      customIcon={isPasswordVisible ? <PasswordSlashIcon /> : <PasswordIcon />}
                    />
                    <FormikField
                      className={classNames('inputBackgroundColor', styles.PasswordField, newStyles.VerticalMargin)}
                      name="confirmPassword"
                      type={isConfirmPasswordVisible ? 'text' : 'password'}
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      onIconClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                      customIcon={isPasswordVisible ? <PasswordSlashIcon /> : <PasswordIcon />}
                    />

                    <PasswordGuidelines password={values.newPassword} />

                    <div className={classnames(styles.createBtn, newStyles.VerticalMargin)}>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isLoadingEnabled || !(isValid && isDirty)}
                        className={styles.btnPrimary}
                      >
                        {t('agent.changePassword')} {isLoadingEnabled && <CustomSpinner />}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
