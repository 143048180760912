import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { components } from 'react-select';
import classNames from 'classnames';
import { ErrorMessage, Field, FieldProps, useFormikContext } from 'formik';

import { ReactComponent as DropDownFillArrow } from 'assets/svgs/dropDownFillArrow.svg';
import styles from 'components/Agent/components/shared/RequestVerificationWrapper/RequestVerificationWrapper.module.scss';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import FormikPhoneNumber from 'components/shared/PhoneNumber/FormikPhoneNumber';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import FormikField from 'components/shared/TextField/FormikTextField';
import { relationshipOptions } from 'constants/agentConstants';
import { IntellirentPublicUrls } from 'shared/constants';
import { CustomSelectOption, QuestionFormValues, RentalSubmitFormProps } from 'shared/types/renterTypes';

export const RentalSubmitForm = ({
  heading,
  subHeading,
  isRelationshopDisabled = false,
  formConsent,
  formConsentOffer,
  isHeadingOptional = true,
}: RentalSubmitFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<QuestionFormValues>();
  const [selectedValue, setSelectedValue] = useState<CustomSelectOption>();
  const [isNewsChecked, setIsNewsChecked] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomOptionWrapper = (props: any): JSX.Element => {
    const { label, value, data } = props;

    return (
      <components.Option {...props}>
        <div className={styles.CustomSelectContainer}>
          <span className={styles.CustomOption}>{label}</span>
          <span
            className={classNames(styles.CustomOption, styles.TextContainer, { [styles.CustomText]: !!data?.isItalic })}
          >
            {value}
          </span>
        </div>
      </components.Option>
    );
  };
  const handleSelectChange = (option: CustomSelectOption): void => {
    setFieldValue(`submitVerifier.relationshipProperty`, option?.label);
    setSelectedValue({ value: option?.label ?? '', label: `${option?.label}${option?.value}` });
  };

  useEffect(() => {
    if (values?.submitVerifier) {
      const relationship = relationshipOptions.find(
        (option: CustomSelectOption) => option?.label === values?.submitVerifier?.relationshipProperty
      );

      if (relationship) {
        setFieldValue(`submitVerifier.relationshipProperty`, relationship?.label);
        setSelectedValue({ value: relationship?.label ?? '', label: `${relationship?.label}${relationship?.value}` });
      }
    }
  }, [setFieldValue, values?.submitVerifier]);

  return (
    <div className={styles.RentalFormContainer}>
      <div className={styles.FormContainer}>
        {heading && (
          <span className={classNames(styles.RentalTitle, styles.RentalTitleLarge)}>
            <div>
              {t(heading)}&nbsp;
              {isHeadingOptional ? (
                <span className={styles.OptionalField}>{t('rental.verifierRedirect.form.optional')}</span>
              ) : (
                ''
              )}
            </div>
          </span>
        )}
        {subHeading && <span className={classNames(styles.DescriptionContainer)}>{t(subHeading)}</span>}
        <FormikField
          name={isRelationshopDisabled ? 'fullName' : 'submitVerifier.fullName'}
          label={t('renter.sourceOfIncome.fullName')}
          labelClassName={classNames(styles.RequestDetails, styles.FormField)}
        />
        <FormikField
          name={isRelationshopDisabled ? 'companyName' : 'submitVerifier.companyName'}
          label={t('rental.historyInformation.companyNameOptional')}
          labelClassName={classNames(styles.RequestDetails, styles.FormField)}
        />
        <div className={styles.RentalFormFieldContainer}>
          <FormikField
            name={isRelationshopDisabled ? 'emailAddress' : 'submitVerifier.emailAddress'}
            label={t('application.email')}
            labelClassName={classNames(styles.RequestDetails, styles.FormField)}
          />
          <FormikPhoneNumber
            name={isRelationshopDisabled ? 'phoneNumber' : 'submitVerifier.phoneNumber'}
            label={t('application.phone')}
            placeholder="(  )  -"
            isCountryCodeDisabled
            isShowCountryCode={false}
          />
        </div>
        {!isRelationshopDisabled && (
          <>
            <ReactSelect
              hasFloatingLabel
              floatingLabel={t('renter.relationship.relationToProperty')}
              placeholder={t('renter.vehiclesInformation.vehicleType.placeholder')}
              options={relationshipOptions}
              dropDownIconContainer={styles.dropDownFillArrow}
              dropDownIcon={<DropDownFillArrow />}
              customOption={CustomOptionWrapper}
              className={styles.ReactSelectContainer}
              value={selectedValue}
              onChange={handleSelectChange}
            />
            <ErrorMessage name="submitVerifier.relationshipProperty" />
          </>
        )}
      </div>
      <div className={styles.ConsentContainer}>
        <span>
          <Field name={isRelationshopDisabled ? 'privacyConsent' : 'submitVerifier.privacyConsent'}>
            {({ field }: FieldProps) => (
              <Checkbox
                label={
                  <span>
                    {t(formConsent)}
                    <Link
                      to={IntellirentPublicUrls.PRIVACY_POLICY}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.LinkContainer}
                    >
                      {t('rental.historyInformation.privacyPolicy')}
                    </Link>
                  </span>
                }
                className={styles.Consent}
                {...field}
                checked={field.value}
              />
            )}
          </Field>
        </span>
        <Checkbox
          label={t(formConsentOffer)}
          onChange={() => setIsNewsChecked(!isNewsChecked)}
          checked={isNewsChecked}
          className={styles.Consent}
        />
      </div>
    </div>
  );
};
