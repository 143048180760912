import { AxiosResponse } from 'axios';

import { createFinicityConnectUrl } from 'services/finicityService';
import { FinicityConnectUrl } from 'shared/types/renterTypes';

export const getFinicityConnectUrl = async (applicationId: number): Promise<FinicityConnectUrl> => {
  try {
    const {
      data: { payload },
    }: AxiosResponse = await createFinicityConnectUrl(applicationId);

    return payload;
  } catch (error) {
    throw error;
  }
};
