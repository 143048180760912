import { t } from 'i18next';

import { DocumentTypesProps } from 'shared/types/renterIncomeInformation';

import DocumentTypesDropzones from './DocumentTypesDropzones';

import styles from './DocumentTypes.module.scss';

const DocumentTypes = ({ data, showModal }: DocumentTypesProps): JSX.Element => (
  <div className={styles.documentTypesContainer}>
    <span className={styles.title}>
      {t('renter.incomeInformation.documentType.renterCriteria.title')}
      <text
        className={styles.actionText}
        onClick={() => {
          showModal(true);
        }}
      >
        {t('renter.incomeInformation.documentType.renterCriteria.actionText')}
      </text>
    </span>
    <br />
    <small className={styles.lightText}>
      {t('renter.incomeInformation.documentType.renterCriteria.lightText.pdfs')}
      <span className={styles.italic}>
        {t('renter.incomeInformation.documentType.renterCriteria.lightText.italic')}
      </span>
        {t('renter.incomeInformation.documentType.renterCriteria.lightText.text')}
    </small>
    <DocumentTypesDropzones data={data} />
  </div>
);

export default DocumentTypes;
