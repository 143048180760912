import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { selectSecurityQuestionsRenter } from 'redux/selectors/authSelector';
import { getRenterProfileInformation, setIsRenterLoading } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as ExperianIcon } from 'assets/svgs/ExperianLogoFull.svg';
import RCButton from 'components/shared/Button/Button';
import { RadioButton } from 'components/shared/RadioButton/RadioButton';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { submitRenterSecurityQuestion } from 'services/renterService';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';
import { SecurityAnswer } from 'shared/types/authType';

import styles from './SecurityQuestionnaire.module.scss';

export const SecurityQuestionnaireRenter: React.FC = () => {
  const { applicationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const securityQuestions = useSelector(selectSecurityQuestionsRenter);
  const dispatch = useDispatch<AppThunkDispatch>();
  const maxHeight = useDeviceHeight(147);
  const initialSelectedAnswers = Object.fromEntries(securityQuestions.map((question) => [question.questionType, null]));
  const [selectedAnswers, setSelectedAnswers] = useState(initialSelectedAnswers);
  const handleRadioButtonChange = (questionType: number, value: string): void => {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionType]: value,
    }));
  };
  const generateFormattedAnswers = (): SecurityAnswer => ({
    kbaAnswers: securityQuestions.map((question) => ({
      answer: question.questionSelect.questionChoice.indexOf(selectedAnswers[question.questionType] || '') + 1,
    })),
  });

  return (
    <div
      className={styles.Container}
      style={{ maxHeight, paddingBottom: '20px', overflowY: 'auto', paddingInline: '35px' }}
    >
      <span className={styles.Title}>{t('renter.securityQuestionnaire.title')}</span>
      <Row className={styles.ExperianContainer}>
        <span className={styles.ExperianLogo}>
          <ExperianIcon />
        </span>
        <span className={styles.ExperianText}>{t('renter.securityQuestionnaire.experianText')}</span>
      </Row>
      <div className={classNames(styles.QuestionnaireContainer)}>
        {securityQuestions.map((question) => (
          <div className={styles.QuestionContainer} key={question.questionText}>
            <span key={`security-question-${question.questionType}`} className={styles.Question}>
              {t(question.questionText)}
            </span>
            <div className={styles.AnswerContainer}>
              {question.questionSelect.questionChoice.map((answer) => (
                <div key={`security-answer-${question.questionType}`} className={styles.AnswerContainer}>
                  <RadioButton
                    name={`security-question-${question.questionType}`}
                    className={styles.Question}
                    label={t(answer)}
                    value={answer}
                    required
                    checked={selectedAnswers[question.questionType] === answer}
                    onChange={() => handleRadioButtonChange(question.questionType, answer)}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.RenterButtonContainer}>
        <RCButton
          variant="primary"
          disabled={Object.values(selectedAnswers).filter(Boolean).length !== Object.keys(securityQuestions).length}
          onClick={() => {
            const values = generateFormattedAnswers();

            dispatch(setIsRenterLoading(true));
            submitRenterSecurityQuestion(values, Number(applicationId))
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .catch((res: any) => {
                const data = res?.response?.data;
                const message = data?.errors[0]?.errorMessage ?? data?.payload?.error?.Message;

                Notification({
                  message: message.includes('Failed Authentication') ? 'Failed Authentication' : message,
                });
              })
              .finally(() => {
                dispatch(getRenterProfileInformation());
                navigate(renterRoutes.applications);
                dispatch(setIsRenterLoading(false));
              });
          }}
          className={styles.RenterButton}
        >
          {t('renter.incomeInformation.saveAndContinueButton')}
        </RCButton>
      </div>
    </div>
  );
};
