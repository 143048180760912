import { useCallback } from 'react';
import React from 'react';
import classNames from 'classnames';
import { FieldArray, useFormikContext } from 'formik';
import { t } from 'i18next';

import indexStyles from 'components/Renter/RenterIndex.module.scss';
import Button from 'components/shared/Button/Button';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { backgroundQuestionOptions, QuestionAnswerKey } from 'constants/renterConstants';
import { MyFormValues, Option, questionOptions } from 'shared/types/renterBackgroundInformation';

import BackgroundQuestionsInput from './BackgroundQuestionsInput/BackgroundQuestionsInput';

import styles from './BackgroundQuestions.module.scss';

const BackgroundQuestions = (): JSX.Element => {
  const { values, setFieldValue, isValid } = useFormikContext<MyFormValues>();
  const handleWillAnswer = useCallback(
    (value: Option, indexOfQuestion: number) => {
      if (value?.name === QuestionAnswerKey.NO) {
        setFieldValue(`questions[${indexOfQuestion}].explanation`, null);
      }

      setFieldValue(`questions[${indexOfQuestion}].answer`, value?.name);
    },
    [setFieldValue]
  );

  return (
    <div className={styles.backgroundQuestionsContainer}>
      <div className={styles.heading}>{t('renter.BackgroundInformation.heading')}</div>
      <FieldArray
        name="questions"
        render={() => (
          <div className={styles.questionContainer}>
            {values.questions.map((question, index) => (
              <React.Fragment key={question.id}>
                <div className={styles.borderContainer} key={question.id}>
                  {t(`${question.question}`)}
                  <div className={styles.dropDownContainer}>
                    <ReactSelect
                      value={questionOptions[question?.answer ?? QuestionAnswerKey.SELECT]}
                      isSearchable={false}
                      onChange={(value) => handleWillAnswer(value, index)}
                      options={backgroundQuestionOptions}
                      menuPosition="fixed"
                    />
                  </div>
                </div>
                {question.answer === QuestionAnswerKey.YES && (
                  <div className={styles.answerContainer}>
                    <BackgroundQuestionsInput currentStep={index} />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      />
      <div className={styles.buttonContainer}>
        <Button
          variant="outline"
          className={classNames(indexStyles.renterButton, styles.button)}
          disabled={!isValid}
          type="submit"
        >
          {t('confirm and continue')}
        </Button>
      </div>
    </div>
  );
};

export default BackgroundQuestions;
