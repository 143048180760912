import { Dispatch, SetStateAction } from 'react';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import {
  setAdultInviteDetails,
  setAgentOrganizationInviteDetails,
  setGuarantorInviteDetails,
  setInviteCode,
  setInviteDetails,
  setInviteSubType,
  setInviteType,
  setRenterInviteCode,
} from 'redux/slices/authSlice';
import { createVerificationInformation, getAddressHistoryQuestionnaire } from 'redux/slices/renterSlice';

import { renterInviteCodeIntialValues } from 'constants/authConstants';
import { SubmittionStatus } from 'constants/publicConstants';
import { getUserDetailsOnVerification, updateVerifierDetailsVerification } from 'services/renterService';
import { Notification, NotificationType } from 'shared/Notification/Notification';
import { AdultInviteInitialValues, GuarantorInviteInitialValues, InviteInitialValues } from 'shared/types/authType';
import { AgentOrganizationInvitesProps } from 'shared/types/organizationTypes';
import { HandleNonVerifierSubmissionProps, HandleSubmission } from 'shared/types/publicTypes';
import {
  FormValues,
  RentalHistoryQuestionnaireType,
  RenterAddressDetails,
  RenterEmploymentDetails,
} from 'shared/types/renterTypes';
import { formatDateForBackend } from 'shared/utils/formatDate';

import { parseResponseErrors } from './helper';

export const getUserDetails = (
  token: string,
  setUserDetails: Dispatch<SetStateAction<RenterAddressDetails>> | Dispatch<SetStateAction<RenterEmploymentDetails>>,
  setRequestedAt?: Dispatch<SetStateAction<string>>
): void => {
  getUserDetailsOnVerification(token)
    .then((res: AxiosResponse) => {
      if (setRequestedAt) {
        setRequestedAt(res.data.payload.requestedAt);
      }

      setUserDetails(res.data.payload);
    })
    .catch((error) => {
      Notification({ message: parseResponseErrors(error) });
    });
};

export const fetchVerifierQuestion = ({
  token,
  setQuestionnaire,
  dispatch,
  setIsSubmitted,
}: GetAddressHistoryQuestionnaireProps): void => {
  dispatch(getAddressHistoryQuestionnaire(token))
    .unwrap()
    .then((res) => {
      setIsSubmitted(!!res.submittedAt);

      if (res.verificationQuestions) {
        setQuestionnaire((prev) => ({
          ...prev,
          verificationQuestions: res.verificationQuestions,
          submittedAt: res.submittedAt,
          verifierType: res.verifierType,
        }));
      } else if (res.submittedVerifierResponse) {
        setQuestionnaire((prev) => ({
          ...prev,
          verificationQuestions: res.submittedVerifierResponse,
          submitVerifier: res.submitVerifierDto,
          submittedAt: res.submittedAt,
          verifierType: res.verifierType,
        }));
      }
    });
};

export type GetAddressHistoryQuestionnaireProps = {
  token: string;
  setQuestionnaire: Dispatch<SetStateAction<FormValues>>;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
};

export const handleVerifierSubmission = ({
  data,
  token,
  dispatch,
  acceptedFiles,
  setIsSubmitted,
}: HandleSubmission): void => {
  const verificationInformation = data.verificationQuestions.map((response: RentalHistoryQuestionnaireType) => {
    let meta = response?.meta;

    if (meta && meta.fromDate) {
      meta = {
        fromDate: formatDateForBackend(meta.fromDate),
        toDate: formatDateForBackend(meta.toDate),
      };
    }

    return {
      questionId: response.id,
      answer: response.answer,
      meta,
    };
  });
  const values = { verificationInformation, submitVerifier: data.submitVerifier };

  dispatch(createVerificationInformation({ token, values, acceptedFiles }))
    .unwrap()
    .then((res) => {
      setIsSubmitted(res === SubmittionStatus.SUCCESS);
      Notification({
        message: t('renter.verifier.responseSubmitted'),
        type: NotificationType.SUCCESS,
      });
    })
    .catch((error) => {
      if (Object.keys(error).includes('null')) {
        Notification({
          message: t('renter.verifier.responseAlreadySubmitted'),
        });
      } else {
        Notification({
          message: error['submitVerifier.relationshipProperty'],
        });
      }
    });
};

export const handleNonVerifierSubmission = ({
  values,
  token,
  isEmploymentVerifier = false,
}: HandleNonVerifierSubmissionProps): void => {
  if (isEmploymentVerifier) {
    delete values.companyName;
  } else {
    delete values.titleOfVerifier;
  }

  updateVerifierDetailsVerification(token, values)
    .then(() => {
      Notification({
        message: t('renter.verifier.responseSubmitted'),
        type: NotificationType.SUCCESS,
      });
    })
    .catch((errors) => {
      Notification({ message: parseResponseErrors(errors) });
    });
};

export const clearAllInvitesDetails = ({
  dispatch,
}: {
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
}): void => {
  dispatch(setInviteType(''));
  dispatch(setInviteCode(''));
  dispatch(setInviteSubType(''));
  dispatch(setInviteDetails(InviteInitialValues));
  dispatch(setRenterInviteCode(renterInviteCodeIntialValues));
  dispatch(setAdultInviteDetails(AdultInviteInitialValues));
  dispatch(setGuarantorInviteDetails(GuarantorInviteInitialValues));
  dispatch(setAgentOrganizationInviteDetails({} as AgentOrganizationInvitesProps));
};
