import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { applicationProperties, selectRenterProfileInformation } from 'redux/selectors/renterSelector';
import { addRenterPayment } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import defaultImage from 'assets/images/defaultProfilePicture.png';
import { OTPModal } from 'components/shared/OTPModal/OTPModal';
import ProfileInfo from 'components/shared/ProfileInfoWithoutDropdown/ProfileIconWithoutDropdown';
import { APPLICATION_REGISTRATION_AMOUNT } from 'constants/renterConstants';
import { tabTitle } from 'helpers/helper';
import { renterResendOtpRequest, submitRenterOtpRequest } from 'helpers/renterHelper';
import { useDeviceHeight } from 'hooks/useDeviceHeight';
import { Notification } from 'shared/Notification/Notification';
import { renterRoutes } from 'shared/routes';

import RenterContainer from '../RenterContainer';

import Checkout from './Checkout';

import indexStyles from '../../RenterIndex.module.scss';
import styles from './PaymentForm.module.scss';
const CheckoutCard = (): JSX.Element => {
  tabTitle('Your Application | Payment');
  const { applicationId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState('');
  const { t } = useTranslation();
  const renterDetails = useSelector(selectRenterProfileInformation);
  const property = useSelector(applicationProperties);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [initialOtpCode, setInitialOtpCode] = useState('');
  const [hasOtpExpired, setHasOtpExpired] = useState(false);
  const [isOtpRequestLaoding, setIsOtpRequestLoading] = useState(false);
  const maxHeight = useDeviceHeight(200);
  //Handles submitting the otp and redirection on success
  const onSubmitClickHandler = useCallback(
    (code: string) => {
      submitRenterOtpRequest({
        code: code,
        setHasOtpExpired: setHasOtpExpired,
        setIsOtpRequestLoading: setIsOtpRequestLoading,
        dispatch: dispatch,
        navigate: navigate,
        applicationId: applicationId || '',
        setInitialOtpCode: setInitialOtpCode,
      });
    },
    [applicationId, dispatch, navigate]
  );
  const onResendOtpClickHandler = (): void => {
    renterResendOtpRequest({ setInitialOtpCode: setInitialOtpCode, setHasOtpExpired: setHasOtpExpired });
  };

  useEffect(() => {
    if (renterDetails?.isPaymentSucceeded && !clientSecret) {
      navigate(renterRoutes.applications);
    } else if (!clientSecret && !isEmpty(renterDetails.applications) && property?.id) {
      dispatch(
        addRenterPayment({
          organizationName: property?.organizationName || '',
          applicationId: Number(applicationId),
          amount: APPLICATION_REGISTRATION_AMOUNT,
          propertyAddress: property?.streetAddress1 || '',
        })
      )
        .unwrap()
        .then((response) => {
          setClientSecret(response.clientSecret);
        })
        .catch((error) => {
          Notification({ message: error?.null });
        });
    }
  }, [
    applicationId,
    clientSecret,
    dispatch,
    navigate,
    property.city,
    property?.id,
    property.organizationName,
    property?.streetAddress1,
    property.streetAddress2,
    renterDetails,
  ]);

  return (
    <RenterContainer>
      <section className={classNames(indexStyles.renterBody)}>
        <div
          className={indexStyles.renterContentContainer}
          style={{ paddingRight: '12px', maxHeight, paddingBottom: '20px', overflowY: 'auto' }}
        >
          <div>
            <ProfileInfo profileImgStyling={styles.imageSize} imageSrc={defaultImage} />
            <h2 className={indexStyles.renterInvitePropertyH2}>
              {t('renter.paymentInformation.almostDoneEnter')}&nbsp;
              <span className={indexStyles.renterSpan}>{t('renter.paymentInformation.paymentDetails')}</span>&nbsp;
              {t('renter.paymentInformation.toSubmitAndFinalize')}
            </h2>
            <div className={indexStyles.nonRefundableContainer}>
              <div className={indexStyles.nonRefundable}>{t('renter.paymentInformation.nonRefundable')}</div>
              <div className={indexStyles.nonRefundableFee}>{t('renter.paymentInformation.nonRefundableFee')}</div>
            </div>
          </div>
          <div className={styles.checkoutContainer}>
            {clientSecret && (
              <Checkout
                setInitialOtpCode={setInitialOtpCode}
                setIsOtpModalOpen={setIsOtpModalOpen}
                secret={clientSecret}
              />
            )}
          </div>
        </div>
        {isOtpModalOpen && (
          <OTPModal
            isLoading={isOtpRequestLaoding}
            isExpired={hasOtpExpired}
            onSubmitHandler={onSubmitClickHandler}
            initialOtpValue={initialOtpCode}
            onResendOtpClickHandler={onResendOtpClickHandler}
          />
        )}
      </section>
    </RenterContainer>
  );
};

export default CheckoutCard;
