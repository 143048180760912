import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { selectPropertyId } from 'redux/selectors/propertySelector';
import { addProperty, getPaginatedProperties } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';
import { PropertyFormSchema } from 'schema/propertySchema';

import { dateFormatBackend } from 'constants/calendarConstants';
import { PropertySteps } from 'constants/propertyConstants';
import { PropertyType } from 'shared/types/propertType';
import { AddPropertyParams, PropertyInitialValue } from 'shared/types/propertyType';

import AddPropertyModal from './AddPropertyModal';
import Amenities from './Amenities';
import PropertyBasicInformation from './BasicPropertyInformation';
import Description from './Description';
import MediaInformation from './MediaInformation';
import UnitSpecs from './UnitSpecs';

export type IAddPropertyProps = {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  currentPage: string;
  perPage: string;
};

const PropertyForm = ({ isModalVisible, setIsModalVisible, currentPage, perPage }: IAddPropertyProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const propertyID = useSelector(selectPropertyId);
  const [searchParams] = useSearchParams();
  const activeOrganization = useSelector(selectedOrganization);
  const activeOption = searchParams.get('option') || 'Published';
  const [isError, setIsError] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const fetchProperties = useCallback(
    (property: AddPropertyParams) => {
      if (activeOrganization.id) {
        if (!propertyID || property.propertyStatus === PropertyType.PUBLISHED) {
          dispatch(
            getPaginatedProperties({
              page: Number(currentPage),
              propertyStatus: activeOption.toLowerCase(),
              per_page: Number(perPage),
              organizationId: activeOrganization.id,
              isCustomAddress: false,
            })
          );
        }
      }
    },
    [activeOrganization, propertyID, dispatch, currentPage, activeOption, perPage]
  );
  const handleMediaCloseModal = (values: AddPropertyParams): void => {
    if (propertyID) {
      values.propertyId = propertyID;
      values.propertyStatus = 'published';
      values.dateAvailable = values.dateAvailable
        ? format(new Date(values.dateAvailable), dateFormatBackend)
        : undefined;
      dispatch(addProperty({ values: values, organizationId: activeOrganization.id })).then(() =>
        fetchProperties(values)
      );
    }

    setIsModalVisible(false);
  };
  const handleCloseModal = (values: AddPropertyParams): void => {
    if (propertyID) {
      values.propertyId = propertyID;
      values.dateAvailable = values.dateAvailable && format(new Date(values.dateAvailable), dateFormatBackend);

      dispatch(addProperty({ values: values, organizationId: activeOrganization.id })).then(() =>
        fetchProperties(values)
      );
    }

    setIsModalVisible(false);
  };
  const handleBlur = (params: AddPropertyParams): void => {
    params.propertyId = propertyID;
    dispatch(addProperty({ values: params, organizationId: activeOrganization.id })).then(() =>
      fetchProperties(params)
    );
  };
  const [isCustomUtilityClicked, setIsCustomUtilityClicked] = useState(false);

  return (
    <Formik
      initialValues={PropertyInitialValue}
      validationSchema={PropertyFormSchema.at(currentStep - 1)}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ values }) => (
        <AddPropertyModal
          title="Add Property"
          isError={isError}
          show={isModalVisible}
          onHide={() => handleCloseModal(values)}
          onMediaHide={() => handleMediaCloseModal(values)}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setIsError={setIsError}
          setIsCustomUtilityClicked={setIsCustomUtilityClicked}
        >
          <Form>
            {currentStep === PropertySteps.basicInfo && (
              <PropertyBasicInformation isError={isError} setIsError={setIsError} handleBlur={handleBlur} />
            )}
            {currentStep === PropertySteps.unitSpecs && (
              <UnitSpecs
                setIsCustomUtilityClicked={setIsCustomUtilityClicked}
                isCustomUtilityClicked={isCustomUtilityClicked}
                handleBlur={handleBlur}
              />
            )}
            {currentStep === PropertySteps.amentites && <Amenities handleBlur={handleBlur} />}
            {currentStep === PropertySteps.description && <Description handleBlur={handleBlur} />}
            {currentStep === PropertySteps.media && <MediaInformation />}
          </Form>
        </AddPropertyModal>
      )}
    </Formik>
  );
};

export default PropertyForm;
