import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import Wrapper from 'components/shared/RenderConponentNTimes/RenderComponents';

import PropertyConatinerSkeleton from './PropertyContainerSkeleton';
import { InlineDataWithLabel } from './PropertyGridSkeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import styles from './PropertyIndex.module.scss';
export const BlockDataWithLabelListView = (): JSX.Element => (
  <Col xs={3} sm={5} md={3} lg={3}>
    <div className={styles.displayInline}>
      <span className={styles.propertyTextlarge}>
        <Skeleton circle={true} height={30} width={30} />
      </span>
      <SpanCustomSkeleton parentStyle={styles.propertyText} />
    </div>
  </Col>
);
export interface CustomSkeletonProps {
  parentStyle?: string;
  childStyle?: string;
}
export const SpanCustomSkeleton = ({ parentStyle, childStyle }: CustomSkeletonProps): JSX.Element => (
  <span className={parentStyle}>
    <Skeleton className={childStyle} />
  </span>
);
const ColCustomSkeletonList = (): JSX.Element => (
  <Col xs={12} md={12} lg={6}>
    <Skeleton width={60} />
  </Col>
);
const PropertySkeleton = (): JSX.Element => (
  <div className={styles.propertyIndexPageBackground}>
    <PropertyConatinerSkeleton />
    {Array.from({ length: 3 }).map((_, index) => (
      <div className={styles.alignWithFlexHorizontalAlign} key={index}>
        <Row className={styles.propertyRowForData}>
          {/* first Column*/}
          <Col sm={5}>
            <Row>
              <Col xs={12} sm={3} md={3} lg={3}>
                <Col>
                  <Skeleton height={87} width={124} />
                </Col>
                <Row>
                  <Wrapper comp={<ColCustomSkeletonList />} count={2} />
                </Row>
              </Col>
              <Col sm={9} md={9} lg={9} className={styles.PropertyDetailPadding}>
                <Col className={styles.propertyTitle}>
                  <Skeleton className={styles.skeletonWidth80} />
                </Col>

                <Col className={styles.gapWithFlex}>
                  <Col className={styles.propertyText}>
                    <Skeleton className={styles.skeletonWidth80} />
                  </Col>
                  <Wrapper comp={<InlineDataWithLabel />} count={2} />
                  <Row>
                    <Wrapper comp={<InlineDataWithLabel />} count={1} />
                  </Row>
                </Col>
              </Col>
            </Row>
          </Col>
          {/* Second Column*/}
          <Col sm={4} md={4}>
            <Row>
              <Row className={styles.propertyPadding}>
                <Skeleton className={styles.skeletonWidth30} />
                <Skeleton className={styles.skeletonWidth30} />
              </Row>
              <Row className={classNames(styles.horizontalAlign, styles.textAlignEnd)}>
                <Wrapper comp={<BlockDataWithLabelListView />} count={4} />
              </Row>
            </Row>
          </Col>
          <Col sm={1} className={styles.alignWithFlexHorizontalAlign}>
            <Skeleton containerClassName={styles.verticleLine} />
          </Col>
          {/* Third Column*/}
          <Col sm={2}>
            <Row className={styles.applicants}>
              <SpanCustomSkeleton parentStyle={styles.addPadding} childStyle={styles.skeletonWidth60} />
              <SpanCustomSkeleton childStyle={styles.skeletonWidth60} />
              <Row className={styles.displayContent}>
                <SpanCustomSkeleton parentStyle={styles.propertyText} childStyle={styles.skeletonWidth60} />
                <SpanCustomSkeleton parentStyle={styles.applicantTitle} childStyle={styles.skeletonWidth60} />
              </Row>
            </Row>
          </Col>
        </Row>
      </div>
    ))}
  </div>
);

export default PropertySkeleton;
