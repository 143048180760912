import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash-es';

import { AnimalInformationProps } from 'shared/types/renterTypes';

import { AnimalCardPDF } from '../shared/AnimalCardPDF/AnimalCardPDF';
import { HeadingPDF } from '../shared/HeadingPDF/HeadingPDF';

interface Props {
  animals: AnimalInformationProps[];
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  emptyContainer: {
    paddingHorizontal: '8px',
    paddingVertical: '4px',
    marginTop: '8px',
    borderRadius: '4px',
    border: '1px solid #E1EAEB',
    fontSize: '12px',
    fontWeight: 'semibold',
    color: '#303333',
  },
});

export const AnimalSectionPDF = ({ animals }: Props): JSX.Element => (
  <View style={styles.container} wrap={false}>
    <HeadingPDF heading="Animals" count={animals.length} shouldShowCount shouldShowLine />
    {!isEmpty(animals) ? (
      animals.map((animal) => <AnimalCardPDF animal={animal} key={`animal-${animal.id}`} />)
    ) : (
      <View style={styles.emptyContainer}>
        <Text>{`I do not have animals`}</Text>
      </View>
    )}
  </View>
);
