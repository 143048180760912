import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { upperFirst } from 'lodash-es';
import { selectedOrganization } from 'redux/selectors/organizationSelector';

import { ReactComponent as OranizationIcon } from 'assets/svgs/SupervisorAccountIcon.svg';
import { InviteAgentModalProps } from 'shared/types/agentInvite';

import InviteForm from './InviteForm/InviteForm';

import styles from './AgentInviteModalBody.module.scss';

const AgentInviteModalBody = ({ setInviteSent }: InviteAgentModalProps): JSX.Element => {
  const currentOrganization = useSelector(selectedOrganization);

  return (
    <div className={styles.container}>
      <Modal.Body>
        <div className={styles.orgTitle}>
          <OranizationIcon />
          <span className={styles.text}>{upperFirst(currentOrganization.name)}</span>
        </div>
        <InviteForm setInviteSent={setInviteSent} />
      </Modal.Body>
    </div>
  );
};

export default AgentInviteModalBody;
